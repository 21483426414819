type MaritalStatusTypes = { id: number; name: string }

const maritalStatusTypes: Array<MaritalStatusTypes> = [
  { id: 1, name: "Casado" },
  { id: 2, name: "Separado" },
  { id: 3, name: "Solteiro" },
  { id: 4, name: "União Estável" },
  { id: 5, name: "Viúvo" },
];

export default maritalStatusTypes;