type HousingSituation = { id: number; name: string }

const housingSituations: Array<HousingSituation> = [
  { id: 1, name: "Próprio" },
  { id: 2, name: "Financiado" },
  { id: 3, name: "Alugado" },
  { id: 4, name: "Arrendado" },
  { id: 5, name: "Cedido" },
  { id: 6, name: "Ocupação" },
  { id: 7, name: "Situação de Rua" },
  { id: 8, name: "Outra" },
];

export default housingSituations;