import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    Grid,
    InputAdornment,
    withStyles,
    Typography,
    IconButton,
    Tooltip,
    CircularProgress,
    Button,
    Box
} from '@material-ui/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { messages } from '../../../../constants/messages';
import { RecipientsBenefitsEntity } from '../../../../interfaces/entities/recipients-benefits.entity';
import { AuthContext } from '../../../../providers/Auth';
import BenefitService from '../../../../services/BenefitService';
import MyTextInput from '../../../MyTextInput';

import styles from './styles';
import { ArrowBack } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import withSnackbar from '../../../templates/WithSnackbar';
import CitizenService from '../../../../services/CitizenService';
import Loading from '../../public/Loading';

interface Props {
    classes: any;
    openSnackbar: any;
}

interface FormData {
    record: string;
}

const inputLabelProps: any = { style: { color: '#707070', fontWeight: 'bold' } };

const SocialRecordRegister: React.FC<Props> = (props) => {
    const { classes, openSnackbar } = props;
    const { accessToken } = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBenefitiary, setIsLoadingBenefitiary] = useState(false);
    const [benefitiary, setBenefitiary] = useState<RecipientsBenefitsEntity>();
    const [benefitGrantedId, setBenefitGrantedId] = useState<number>();

    const history = useHistory();

    const formRef = useRef<FormHandles>(null);

    function useQueryParams() {
        const { search } = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    }
    const queryParams = useQueryParams();

    useEffect(() => {
        getBenefitiary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBenefitiary = async () => {
        setIsLoadingBenefitiary(true);
        try {
            const citizenId = queryParams.get('citizenId');
            const benefitGrantedId = queryParams.get('benefitGrantedId');

            const citizen = await CitizenService.getCitizen(
                Number(citizenId),
                accessToken,
            );

            const benefit = await BenefitService.getBeneficioConcedido(Number(benefitGrantedId), accessToken);
            if (citizen && benefit.benefit_grant_status) {
                setBenefitGrantedId(Number(benefitGrantedId));
                setBenefitiary({
                    id: citizen.id,
                    name: citizen.name,
                    cpf: citizen.cpf,
                    nis: citizen.nis,
                    telephone: citizen.telephone,
                    benefit: {
                        id: Number(benefit.id),
                        citizen_id: Number(benefit.citizen_id),
                        benefit_id: benefit.benefit_id,
                        benefit_grant_status_id: benefit.benefit_grant_status_id,
                        started_at: benefit.started_at,
                        nib: benefit.nib,
                        social_record: benefit.social_record,
                        benefit_grant_status: benefit.benefit_grant_status
                    }
                });
            }
        } catch (error: any) {
            return openSnackbar('error', error.message);
        } finally {
            setIsLoadingBenefitiary(false);
        }
    }

    async function onSubmit(data: FormData) {
        setIsLoading(true);
        const schema = Yup.object().shape({
            record: Yup.string().required(messages.emptyField).typeError('Tipo inválido'),
        });

        try {
            formRef.current?.setErrors({});
            await schema.validate(data, { abortEarly: false });
            if (benefitGrantedId) {
                await BenefitService.updateProntuario(accessToken, benefitGrantedId, data.record)
            }

            const returnPath = queryParams.get('returnPath');
            if (returnPath) {
                history.replace(returnPath);
            }

            history.goBack();
        } catch (error: any) {
            if (error instanceof Yup.ValidationError) {
                const errorMessages: Record<string, string> = {};

                error.inner.forEach((error: Yup.ValidationError) => {
                    if (error.path != null) {
                        errorMessages[error.path] = error.message;
                    }
                });
                formRef.current?.setErrors(errorMessages);
            } else {
                openSnackbar('error', error?.message);
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            {!isLoadingBenefitiary ? (
                <Grid container style={{ margin: '30px 0px 30px 40px', width: 'calc(100% - 112px)' }}>
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Voltar para página anterior">
                            <IconButton onClick={() => history.goBack()}>
                                <ArrowBack />
                            </IconButton>
                        </Tooltip>
                        <span className={classes.pageTitle}>Prontuário social</span>
                        <div className={classes.pageLine}></div>
                    </Grid>

                    <Grid item xs={12} style={{ marginTop: 40 }}>
                        <Grid container direction="column" spacing={4}>
                            <Grid container spacing={2} className={classes.containerForm}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" color="textPrimary">Nome completo</Typography>
                                    <Typography variant="subtitle2" color="textSecondary">{benefitiary?.name}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" color="textPrimary">CPF</Typography>
                                    <Typography variant="subtitle2" color="textSecondary">{benefitiary?.cpf}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" color="textPrimary">NIS</Typography>
                                    <Typography variant="subtitle2" color="textSecondary">{benefitiary?.nis}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" color="textPrimary">NIB</Typography>
                                    <Typography variant="subtitle2" color="textSecondary">{benefitiary?.benefit?.nib || 'Sem registro'}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Form
                                        ref={formRef}
                                        onSubmit={onSubmit}
                                        initialData={{
                                            record: benefitiary?.benefit.social_record,
                                        }}
                                        className={classes.dialogContent}
                                    >
                                        <Grid container spacing={4}>
                                            <Grid item xs={12}>
                                                <MyTextInput
                                                    name="record"
                                                    placeholder="Digite o prontuário social"
                                                    label="Prontuário social"
                                                    InputLabelProps={inputLabelProps}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start" style={{ color: "#707070" }} />
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} style={{ marginTop: 10 }}>
                                            <Box
                                                display="flex"
                                                justifyContent="flex-end"
                                            >

                                                <Button color="secondary" onClick={() => history.goBack()}>
                                                    Cancelar
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    disabled={isLoading}
                                                    onClick={() => formRef.current?.submitForm()}
                                                    endIcon={isLoading ? <CircularProgress size={25} style={{ color: '#a11908' }}/> : null}
                                                >
                                                    {!!benefitiary && !!benefitiary?.benefit?.social_record ? 'Editar' : 'Salvar'}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Form>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) :
                <Loading />
            }
        </>
    );
}

export default withStyles(styles)(withSnackbar(SocialRecordRegister));
