interface TypeObject {
  id: number;
  name: string;
}

export default function formatType(value: number | string[], typeArray: TypeObject[]) {
  if(Array.isArray(value)) {
    return value.map((item) => {
      return typeArray.find((type) => type.id === Number(item))?.name || 'Não informado';
    })
  }
  return typeArray.find((type) => type.id === value)?.name || 'Não informado';
}
