import React from 'react';
import { useHistory } from 'react-router-dom';
import { Schedule, Undo } from '@material-ui/icons';
import { withStyles, Grid, ButtonBase, Collapse } from '@material-ui/core';

import styles from './styles';

type Props = {
  classes: any,
  benefit: {
    id: number;
    name: string;
  },
  managing: boolean,
};

const BenefitCard: React.FC<Props> = (props) => {
  const { classes, benefit, managing } = props;
  const history = useHistory();

  const suspendBenefit = () => {
  }

  function goToBeneficiariosList() {
    // if (benefit?.name.includes('FISH')) {
    //   history.push(`/app/beneficios/cesta-santa`);
    // }
    if (benefit?.name.includes('Cesta Santa')) {
      history.push(`/app/beneficios/cesta-santa`);
    }
    if (benefit?.name.includes('PAS')) {
      history.push(`/app/beneficios/pas`);
    }
    if (benefit?.name.includes('Pão')) {
      history.push(`/app/beneficios/paonamesa`);
    }
    if(benefit?.name.includes('Cesta Junina')) {
      history.push(`/app/beneficios/cesta-junina`);
    }
    if(benefit?.name.includes('Cesta Natalina')) {
      history.push(`/app/beneficios/cesta-natalina`);
    }
  }

  return (
    <Grid container item xs={4} className={classes.cardsContainer}>
      <Grid item className={classes.card}>
        <Grid item xs={12} className={classes.content}>
          <Grid item xs={12} className={classes.buttonWrapper}>
            <ButtonBase
              focusRipple
              className={classes.buttonBase}
              onClick={goToBeneficiariosList}
              disabled={managing}
            >
              <Grid container item xs={12}>
                <Grid item md={4} xs={12} className={classes.cardIcon}>
                  <img alt="Benefit" src="/assets/gift-card.svg" className={classes.cardImg} />
                </Grid>
                <Grid item md={8} xs={12} className={classes.cardInfo}>
                  <span className={classes.cardInfoTitle}>
                    {benefit.name}
                  </span>
                </Grid>
              </Grid>
            </ButtonBase>
            <Collapse in={managing} style={{ width: '100%' }}>
              <Grid container item xs={12} className={classes.cardFooter}>
                <Grid item xs={12}>
                  <ButtonBase className={classes.cardButtonSuspend} onClick={suspendBenefit}>
                    {!false ?
                      <><Schedule className={classes.footerButtonIcon} /> Suspender</> :
                      <div className={classes.undoSuspend}>
                        <Undo className={classes.footerButtonIcon} />
                        <span>Desfazer suspensão</span>
                      </div>
                    }
                  </ButtonBase>
                </Grid>
              </Grid>
            </Collapse>
          </Grid>
        </Grid>
      </Grid>
    </Grid >
  );
}

export default withStyles(styles)(BenefitCard);