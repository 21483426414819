const styles: any = {
    containerForm: {
        padding: 50,
        flexDirection: 'column',
        minHeight: '100vh',
        justifyContent: 'center',
    },
    subTitle: {
        fontSize: "14px",
        fontWeight: 300,
        fontFamily: 'Roboto',
    },
    button: {
        fontSize: '14px',
        fontWeight: '400',
    },
    buttonClean: {
        borderColor: '#878787',
        color: '#878787',
        "&:hover": {
            backgroundColor: '#878787',
            color: '#fff',
        },
    },
    buttonCancel: {
        marginLeft: '15px',
        borderColor: '#D4586D',
        color: '#D4586D',
        "&:hover": {
            backgroundColor: '#D4586D',
            color: '#fff',
        },
    },
    buttonRegister: {
        marginLeft: '15px',
        backgroundColor: '#004692',
        color: '#fff',
        "&:hover": {
            backgroundColor: '#408BCC'
        }
    },
    select: {
        width: '100%',
    },
    divCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    pageTitle: {
        color: '#0A0A0A59',
        fontWeight: 'bold',
        marginRight: '10px',
    },
    pageLine: {
        backgroundColor: '#0A0A0A59',
        flex: 1,
        height: 1,
    },
    btnFilter: {
        color: '#177AFC',
        borderColor: '#177AFC',
        margin: '0 10px',
    },
    btnAdd: {
        backgroundColor: '#a11908',
        color: '#FFFFFF',
        margin: '0 10px',
    },
    containerForm1: {
        padding: 30,
        // flexDirection: 'column',
        // minHeight: '100vh',
        backgroundColor: '#EEEEEE',
        border: '1px solid #0A0A0A59',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        margin: '30px 0',
    },
    subTitle1: {
        fontSize: "14px",
        fontWeight: 300,
        fontFamily: 'Roboto',
    },
    button1: {
        fontSize: '14px',
        fontWeight: '400',
    },
    buttonClean1: {
        borderColor: '#878787',
        color: '#878787',
        "&:hover": {
            backgroundColor: '#878787',
            color: '#fff',
        },
    },
    buttonCancel1: {
        // height: '56px',
        // fontWeight: 'bold',
        // fontSize: '1.1rem',
        marginLeft: '15px',
        borderColor: '#D4586D',
        color: '#707070',
        textTransform: 'capitalize',
        flex: 1,
        "&:hover": {
            backgroundColor: '#D4586D',
            color: '#fff',
        },
    },
    buttonRegister1: {
        // height: '56px',
        // fontWeight: 'bold',
        // fontSize: '1.1rem',
        marginLeft: '15px',
        backgroundColor: '#004692',
        color: '#fff',
        textTransform: 'capitalize',
        flex: 1,
        "&:hover": {
            backgroundColor: '#408BCC'
        }
    },
    buttonUpdater: {
        marginLeft: '15px',
        backgroundColor: '#004692',
        color: '#fff',
        textTransform: 'capitalize',
        flex: 1,
        "&:hover": {
            backgroundColor: '#408BCC'
        }
    },
    select1: {
        width: '100%',
        marginTop: '10px',
    },
    divCenter1: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        marginBottom: '8px',
    },
    divModal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        margin: '15px',
        marginLeft: '60%'
    },
    birthDatePicker: {
        margin: '0px',
    },
    filledInput: {
        marginTop: '10px',
    },
    avatarContainer: {
        lineHeight: 0,
    },
    avatarRounded: {
        width: 'auto',
        maxWidth: '256px',
        height: '96px',
        color: '#707070',
        backgroundColor: '#E6E6E6',
        border: '1px solid #707070',
        marginTop: '16px',
    },
    customLabel: {
        color: 'rgb(112, 112, 112)',
        fontWeight: 'bold',
        fontSize: '0.75rem',
    },
    label: {
        color: '#707070',
        fontWeight: 'bold',
    },
    switchLabel: {
        fontSize: '0.8rem',
        color: '#707070',
        fontWeight: 'bold',
    },
    link: {
        color: '#177AFC',
        fontSize: 14,
        padding: 0,
        textDecoration: 'underline',
        textTransform: 'capitalize',
        display: 'flex',
        justifyContent: 'flex-start',
    },
    imageUpload: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
    modalBg: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        left: 0,
        zIndex: 1,
    },
    backgroundMdl: {
        backgroundColor: '#0e0e0e80',
        position: 'absolute',
        width: '100%',
        height: '100%',
    },
    btnUpdate: {
        marginLeft: '50px',
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-around',
    },
    alertRegistration: {
        padding: 8,
        backgroundColor: '#CC3300',
        color: '#FBFCFC',
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        margin: 15,
    },
    infoTitle: {
        color: '#707070',
        fontWeight: 'bold'
    },
};

export default styles;