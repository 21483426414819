import axios, { AxiosRequestConfig } from 'axios';

const config: AxiosRequestConfig = {
  baseURL: process.env[`REACT_APP_API_URL`],
  headers: {
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Origin': "*",
    'Access-Control-Allow-Headers': 'Origin,X-Requested-With,Content-Type,Accept,content-type,application/json,multipart/form-data',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
  }
};

const instanceBuilder = (path: string) => axios.create({
  ...config,
  baseURL: `${config.baseURL}/${path}`,
});

export default instanceBuilder;
