import React, {
  useRef,
  useState,
  useContext,
} from 'react';
import {
  Box,
  Grid,
  Menu,
  Button,
  MenuItem,
  Typography,
  withStyles,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import {
  ColDef,
  DataGrid,
  CellParams,
} from '@material-ui/data-grid';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { GetApp } from '@material-ui/icons';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';

import MySelect from '../../../../../MySelect';
import MyTextInput from '../../../../../MyTextInput';

import { AuthContext } from '../../../../../../providers/Auth';
import homeService from '../../../../../../services/HomeService';
import { HomeEntity } from '../../../../../../interfaces/entities/home.entity';
import { CitizenEntity } from '../../../../../../interfaces/entities/citizen.entity';

import styles from './styles';
import waterSupplies from '../../../../../../constants/waterSupplies';
import bathroomDrains from '../../../../../../constants/bathroomDrains';
import housingSituations from '../../../../../../constants/housingSituations';
import trashDestinations from '../../../../../../constants/trashDestinations';
import ExcelExportReport, { ExportExcelRefProps } from '../ExcelExport';
import { StreetTypeEntity } from '../../../../../../interfaces/entities/street-type.entity';

type Props = {
  classes: any;
  openSnackbar: any;
};

interface FormData {
  complement: string;
  waterSupply: number;
  district: string;
  sewage: number;
  garbageDisposal: number;
  powerSupply: boolean;
  residentName: string;
  housingSituation: number;
}

type HomeReportEntity = HomeEntity & { responsible: CitizenEntity };

const DomiciliesReport: React.FC<Props> = (props) => {
  const { classes, openSnackbar } = props;
  const { accessToken } = useContext(AuthContext);

  const [showTable, setShowTable] = useState<boolean>(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [homeReport, setHomeReport] = useState<HomeReportEntity[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const formRef = useRef<FormHandles>(null);
  const exportExcelRef = useRef<ExportExcelRefProps>(null);

  const columnWidth = 170;
  const reportColumns: ColDef[] = [
    {
      field: 'district',
      headerName: 'Bairro',
      width: columnWidth,
    },
    {
      field: 'street_type',
      headerName: 'Tipo do logradouro',
      width: columnWidth + 30,
      valueFormatter: ({ value }: CellParams) => (value as StreetTypeEntity)?.description || 'Sem registro',
    },
    {
      field: 'street_name',
      headerName: 'Logradouro',
      width: columnWidth,
    },
    {
      field: 'complement',
      headerName: 'Complemento',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => (value as string) || 'Sem registro',
    },
    {
      field: 'responsible',
      headerName: 'Responsável',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => (value as any)?.name || 'Não possui',
    },
    {
      field: 'electric_power_supply',
      headerName: 'Energia elétrica',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => (value as boolean) ? 'Sim' : 'Não',
    },
    {
      field: 'sewage',
      headerName: 'Forma de escoamento do banheiro',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => (value as any)?.description || 'Sem registro',
    },
    {
      field: 'water_supply',
      headerName: 'Abastecimento de água',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => (value as any)?.description || 'Sem registro',
    },
    {
      field: 'garbage_disposal',
      headerName: 'Destino do lixo',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => (value as any)?.description || 'Sem registro',
    },
    {
      field: 'housing_situation',
      headerName: 'Situação de moradia',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => (value as any)?.description || 'Sem registro',
    },
  ];

  const handleError = (e: any) => {
    openSnackbar('error', e);
  };

  const onSubmit = async (data: FormData) => {
    setIsLoadingReport(true);
    try {
      if (
        (data.complement && data.complement.trim().length <= 3) ||
        (data.district && data.district.trim().length <= 3) ||
        (data.residentName && data.residentName.trim().length <= 3)
      ) {
        return handleError('Preencha mais que 3 caracters!');
      }

      const response = await homeService.getHomeReport(
        accessToken,
        data.district,
        data.complement,
        data.residentName,
        data.powerSupply,
        data.sewage,
        data.waterSupply,
        data.garbageDisposal,
        data.housingSituation,
      );

      setHomeReport(response.results);
      setShowTable(true);
    } catch (error: any) {
      handleError(error.message);
    } finally {
      setIsLoadingReport(false)
    }
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function formatExcelExport() {
    return homeReport?.map((report) => ({
      ...report,
      street_type: report.street_type?.description || 'Sem registro',
      responsible: report?.responsible?.name || 'Não possui',
      electric_power_supply: report.electric_power_supply ? 'Sim' : 'Não',
      housing_situation: report.housing_situation?.description || 'Sem registro',
      garbage_disposal: report.garbage_disposal?.description || 'Sem registro',
      water_supply: report.water_supply?.description || 'Sem registro',
      sewage: report.sewage?.description || 'Sem registro',
      complement: report.complement || 'Sem registro',
    }));
  }

  return (
    <Grid item xs={12} style={{ display: 'flex' }}>
      <Grid item xs={12} className={classes.main}>
        <Grid item xs={12} className={classes.filterDiv}>
          <Grid item xs={12} className={classes.filterTitle}>
            <span>Filtrar</span>
            <FilterListOutlinedIcon style={{ marginLeft: 10 }} />
          </Grid>

          <Form ref={formRef} onSubmit={onSubmit}>
            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Grid container spacing={2} item xs={12} style={{ alignItems: 'flex-end' }}>
                <Grid item xs={6} sm={4}>
                  <MyTextInput
                    name="district"
                    label="Bairro"
                    placeholder="Nome do bairro"
                    InputLabelProps={{
                      style: { color: '#707070', fontWeight: 'bold' },
                    }}
                    InputProps={{
                      style: { fontSize: 14 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmojiTransportationIcon style={{ color: '#707070', fontSize: 16 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <MyTextInput
                    label="Complemento"
                    name="complement"
                    placeholder="Nome do complemento"
                    InputLabelProps={{
                      style: { color: '#707070', fontWeight: 'bold' },
                    }}
                    InputProps={{
                      style: { fontSize: 14 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmojiTransportationIcon style={{ color: '#707070', fontSize: 16 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <MyTextInput
                    label="Morador"
                    name="residentName"
                    placeholder="Nome de um morador"
                    InputLabelProps={{
                      style: { color: '#707070', fontWeight: 'bold' },
                    }}
                    InputProps={{
                      style: { fontSize: 14 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlinedIcon style={{ color: '#707070', fontSize: 16 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Grid container spacing={2} item xs={12} style={{ alignItems: 'flex-end' }}>
                <Grid item xs={6} sm={4}>
                  <MySelect
                    label="Energia elétrica"
                    enableAllSelected
                    name="powerSupply"
                    options={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <MySelect
                    name="sewage"
                    enableAllSelected
                    label="Forma de escoamento do banheiro"
                    options={bathroomDrains.map((type) => ({ value: type.id, label: type.name }))}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <MySelect
                    label="Abastecimento de água"
                    enableAllSelected
                    name="waterSupply"
                    options={waterSupplies.map((type) => ({ value: type.id, label: type.name }))}
                  />
                </Grid>
                <Grid item xs={6} sm={4} style={{ marginTop: 20 }}>
                  <MySelect
                    label="Destino do lixo"
                    enableAllSelected
                    name="garbageDisposal"
                    options={trashDestinations.map((type) => ({ value: type.id, label: type.name }))}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <MySelect
                    label="Situação de moradia"
                    enableAllSelected
                    name="housingSituation"
                    options={housingSituations.map((type) => ({ value: type.id, label: type.name }))}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isLoadingReport}
                      className={classes.buttonRegister}
                    >
                      Consultar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
        {showTable ? (
          <Grid item xs={12} style={{ marginTop: 20 }}>
            {
              !homeReport.length ? (
                <Grid item xs={12} className={classes.divFlex1}>
                  <span className={classes.noResultsSpan}>Não possui registros com os filtros selecionados</span>
                </Grid>
              ) : (
                <>
                  <Box className={classes.tableToolBar} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">Resultados</Typography>
                    <Button
                      color="primary"
                      variant="outlined"
                      aria-haspopup="true"
                      endIcon={<GetApp />}
                      aria-controls="simple-menu"
                      onClick={handleOpenMenu}>
                      Exportar
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={handleCloseMenu}>Gerar pdf</MenuItem>
                      <MenuItem onClick={() => exportExcelRef.current?.generateExcel()}>Gerar excel</MenuItem>
                    </Menu>
                  </Box>
                  <Box className={classes.tableReport} boxShadow={3}>
                    <DataGrid
                      columns={reportColumns}
                      rows={homeReport}
                      autoHeight
                      localeText={{
                        footerTotalRows: 'de',
                        footerPaginationRowsPerPage: 'Domicílios por página',
                      }}
                    />
                  </Box>
                </>
              )
            }
          </Grid>
        ) : (
          <Grid item xs={12} className={classes.divCenterText}>
            <Grid item xs={12} className={classes.divFlex1}>
              {
                isLoadingReport ? (
                  <Box className={classes.loadingContainer}>
                    <CircularProgress size={55} />
                  </Box>
                ) : (
                  <>
                    <img alt="Sem resultados" src="/assets/no_results.png" className={classes.image} />
                    <Typography className={classes.noResultsSpan}>Selecione os filtros para obter o relatório</Typography>
                  </>
                )
              }
            </Grid>
          </Grid>
        )}
      </Grid>
      <ExcelExportReport
        ref={exportExcelRef}
        columns={reportColumns}
        data={formatExcelExport()}
        fileName="relatório-de-domicílios.xls"
      />
    </Grid>
  );
}

export default withStyles(styles)(DomiciliesReport);
