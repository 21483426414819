type Status = { id: number; name: string }

export const ATIVO: Status = { id: 1, name: "Ativo" };
export const SUSPENSO: Status = { id: 2, name: "Suspenso" };
export const CANCELADO: Status = { id: 3, name: "Cancelado" };
export const DESLIGADO: Status = { id: 4, name: "Desligado" };
export const DESLIGAMENTO_VOLUNTARIO: Status = { id: 5, name: "Desligamento Voluntário" };
export const SOLICITACAO_INSCRICAO: Status = { id: 6, name: "Soicitação de Inscrição" };
export const BLOQUEADO: Status = { id: 7, name: "Bloqueado" };

const status: Array<Status> = [
  ATIVO,
  SUSPENSO,
  CANCELADO,
  DESLIGADO,
  DESLIGAMENTO_VOLUNTARIO,
  SOLICITACAO_INSCRICAO,
  BLOQUEADO,
];

export default status;