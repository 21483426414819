import React, { forwardRef, useRef, useImperativeHandle } from 'react';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';

interface Props {
  fileName: string;
  data: any[];
  columns: {
    field: string;
    title?: string;
    headerName?: string;
  }[];
}

export interface ExportExcelRefProps {
  generateExcel(): void;
}

const ExcelExportReport: React.ForwardRefRenderFunction<ExportExcelRefProps, Props> = (props, ref) => {
  const { data, columns, fileName } = props;

  const exportRef = useRef<ExcelExport>(null);

  function generateExcel() {
    exportRef.current?.save();
  }

  useImperativeHandle(ref, () => ({
    generateExcel,
  }));

  return (
    <>
      <ExcelExport
        ref={exportRef}
        data={data}
        fileName={fileName}
      >
        {
          columns.map((column) => (
            <ExcelExportColumn
              field={column.field}
              title={column?.title || column?.headerName}
            />
          ))
        }
      </ExcelExport>
    </>
  );
}

export default forwardRef(ExcelExportReport);
