import React from 'react';

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from 'chart.js';

import {
  Pie
} from 'react-chartjs-2';

interface PieChartProps {
  labels: string[];
  data: number[];
  title: string;
  indexAxis?: 'x' | 'y';
  titleAlign?: 'center' | 'end' | 'start';
  colors?: string[];
}

ChartJS.register(
  ArcElement,
  Legend,
  ArcElement,
  Title,
  Tooltip,
);

const PieChart: React.FC<PieChartProps> = (props) => {
  const {
    data,
    title,
    labels,
    colors,
    indexAxis,
    titleAlign,
  } = props;

  return (
    <Pie
      width="100%"
      height={290}
      data={{
        labels,
        datasets: [{
          data,
          backgroundColor: colors || ["#177AFC"],
        }]
      }}
      options={{
        indexAxis,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: title,
            font: {
              size: 15,
              lineHeight: 3,
            },
            align: titleAlign || 'start',
          },

        }
      }}
    />
  );
}

export default PieChart;
