import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import './styles.css';

export default function Toast(props: any) {
  const {
    open, close, message, type,
  } = props;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      onClose={close}
      autoHideDuration={5000}
    >
      <SnackbarContent
        className={`snackbar ${type}`}
        aria-describedby="snackbar"
        message={message}
      />
    </Snackbar>
  );
};
