import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';

import FishSplash from '../../components/pages/public/FishSplash';
import FishManager from '../../components/pages/public/FishManager';

const PeixeRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/splash`}>
        <FishSplash />
      </Route>
      <Route path={`${path}/`}>
        <FishManager />
      </Route>
      <Redirect to={`${path}/splash`} />
    </Switch>
  );
}

export default PeixeRoutes;
