import React from 'react';
import {
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';
import DeliveryPostsList from '../../../components/pages/private/DeliveryPostsList';
import DeliveryPostsRegister from '../../../components/pages/private/DeliveryPostsRegister';

const PostosRoutes: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <DeliveryPostsList />
            </Route>
            <Route path={`${path}/cadastrar`}>
                <DeliveryPostsRegister />
            </Route>
        </Switch>
    );
}

export default PostosRoutes;
