import React, { useState } from 'react';
import { withStyles, Tabs, Tab } from '@material-ui/core';

import { TabType } from '../../../../../../utils/enum';
import styles from './styles';

type Props = { classes: any, onFilter?: Function, onChange: Function };

const Header: React.FC<Props> = (props) => {
  const { classes, onChange } = props;
  const [value, setValue] = useState<any>(TabType.Domicilios);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    onChange(newValue);
  };

  return (
    <div className={classes.flexRow}>
      <span className={classes.title}>Painel</span>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
      >
        <Tab className={classes.tab} label="Domicílios" />
        <Tab className={classes.tab} label="Indivíduos" />
        <Tab className={classes.tab} label="Benefícios" />
      </Tabs>
    </div>
  );
}

export default withStyles(styles)(Header);