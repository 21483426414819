import { GetUsersResponse } from '../interfaces/responses/get-users.response';

import errorService from './ErrorService';
import Client from './api/api';
import { CommonResultsResponse } from '../interfaces/responses/common-results.response';
import { DeliveryPostEntity } from '../interfaces/entities/delivery-post.entity';


interface CreateRequestBody {
  description: string;
}

class DeliveryPostService {
  private apiInstance = Client('api');
  private config = (token: string) => ({ headers: { Authorization: `Bearer ${token}` } });

  public async create(token: string, body: CreateRequestBody): Promise<any> {
    try {
      const { data } = await this.apiInstance.post('/delivery-posts', body, this.config(token));

      return data;
    } catch (error) {
      throw errorService.handleError(error);
    }
  }

  public async getAllPosts(token: string): Promise<CommonResultsResponse<DeliveryPostEntity>> {
    try {
      const response = await this.apiInstance.get<CommonResultsResponse<DeliveryPostEntity>>('/delivery-posts', this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async delete(token: string, postId: number): Promise<void> {
    try {
      await this.apiInstance.delete<void>(`/delivery-posts/${postId}/delete`, this.config(token));

    } catch (error) {
      throw errorService.handleError(error);
    }
  }
};

export default new DeliveryPostService();
