import React from 'react';
import './App.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import AuthProvider from './providers/Auth';
import MainRoute from './Router/Main';

function App() {
  return (
    <AuthProvider>
      <MainRoute />
    </AuthProvider>
  );
}

export default App;
