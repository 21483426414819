type BathroomDrain = { id: number; name: string }

const bathroomDrains: Array<BathroomDrain> = [
  { id: 1, name: "Rede Coletora de Esgoto/Pluvial" },
  { id: 2, name: "Fossa Séptica" },
  { id: 3, name: "Fossa Rudimentar" },
  { id: 4, name: "Direto para um Rio/Lago/Mar" },
  { id: 5, name: "Céu Aberto" },
  { id: 6, name: "Outra Forma" },
];

export default bathroomDrains;