import React from 'react';
import {
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';
import SocialRecordRegister from '../../../components/pages/private/SocialRecordRegister';

const ProntuarioRoutes: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/cadastrar`}>
                <SocialRecordRegister />
            </Route>
        </Switch>
    );
}

export default ProntuarioRoutes;
