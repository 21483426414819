import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import CitizenInfo from '../../../components/pages/private/CitizenInfo';
import CitizenRegister from '../../../components/pages/private/CitizenRegister';

const CidadaoRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:cidadaoId/visualizar`}>
        <CitizenInfo />
      </Route>
      <Route path={`${path}/:cidadaoId/editar`}>
        <CitizenRegister />
      </Route>
      <Route path={`${path}/cadastrar`}>
        <CitizenRegister />
      </Route>
    </Switch>
  );
}

export default CidadaoRoutes;
