import Client from './api/api';
import errorService from './ErrorService';
import { CitizenEntity } from '../interfaces/entities/citizen.entity';
import formatChartLabels from '../utils/formatChartLabels';
import races from '../constants/races';
import religions from '../constants/religions';
import schoolings from '../constants/schoolings';
import situationsJobMarket from '../constants/situationsJobMarket';
import deficiencies from '../constants/deficiencies';
import { CitizenFormEntity } from '../interfaces/entities/citizen-form.entity';
import { CommonResultsResponse } from '../interfaces/responses/common-results.response';
import sexes from '../constants/sexes';
import { CommonObjectType } from '../interfaces/entities/common-object-type.entity';
import { SimulatePasResponse } from '../interfaces/responses/simulate-pas.response';
import { CitizenBenefitEntity } from '../interfaces/entities/citizen-benefit.entity';
import { HomeEntity } from '../interfaces/entities/home.entity';

interface ResponseResults<Type> {
  results: Type[];
}
interface ResponseChartData {
  labels: string[];
  data: number[];
}

interface ArrayType {
  [key: string]: string | number | boolean;
  total: number;
}

interface ReportQueryRequest {
  cpf?: string;
  nis?: string;
  name?: string;
  gender?: number;
  religion?: number;
  schooling?: number;
  complexion?: number;
  job_situation?: number;
  has_deficiency?: boolean;
}

interface FamilyReportRequest {
  name: string;
  cpf: string;
  nib: string;
  nis: string;
  benefit_id: number;
  min_gross_income: number;
  max_gross_income: number;
  min_income_per_capta: number;
  max_income_per_capta: number;
  min_benefit_value: number;
  max_benefit_value: number;
  min_age: number;
  max_age: number;
}

interface FamilyReportResponse extends CitizenEntity {
  dependents: CitizenEntity[];
  total_income: string;
}

interface UpdateRendaBody {
  ammout?: number;
  description?: string;
}

class CitizenService {
  private apiInstance = Client('api');
  private config = (token: string) => ({ headers: { Authorization: `Bearer ${token}` } });
  private DEFAULT_REF = 'citizen';
  private route = `${process.env[`REACT_APP_API_URL`]}/documents`;

  public async getCitizen(citizenId: number, token: string): Promise<CitizenEntity> {
    try {
      const response = await this.apiInstance.get(`/citizens/${citizenId}`, this.config(token));

      return response?.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async createCitizen(
    token: string,
    citizenForm: CitizenFormEntity
  ): Promise<any> {
    try {
      const response = await this.apiInstance.post('/citizens', citizenForm, this.config(token));

      return response;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async updateCitizen(
    citizenId: string,
    token: string,
    citizenForm: CitizenFormEntity
  ): Promise<{ citizen: CitizenEntity, benefit_status: CommonObjectType }> {
    try {
      const { data } = await this.apiInstance.put(`/citizens/${citizenId}/edit`, citizenForm, this.config(token));

      return data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async createRenda(
    citizenId: number,
    income: { description: string, ammount: number },
    token: string,
  ): Promise<any> {
    try {
      const response = this.apiInstance.post(`/citizens/${citizenId}/incomes`, income, this.config(token));

      return response;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async updateRenda(
    token: string,
    citizenId: number,
    incomeId: number,
    body: UpdateRendaBody,
  ): Promise<{ description: string, ammount: number }> {
    try {
      const { data } = await this.apiInstance.put(
        `/citizens/${citizenId}/incomes/${incomeId}/edit`,
        body,
        this.config(token),
      );

      return data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  private sortAlphabetic(a: any, b: any) {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  }

  private sortByAge(a: string, b: string) {
    const A = a.length <= 3 ? Number(a.slice(1)) : Number(a.split('-')[0]);
    const B = b.length <= 3 ? Number(b.slice(1)) : Number(b.split('-')[0]);

    if (A > B) {
      return 1;
    }
    if (A < B) {
      return -1;
    }
    // a must be equal to b
    return 0;
  }

  private formatBarChartData(scope: string, array: ArrayType[], constArray?: any[]) {
    let labels: string[] = [];
    let data: number[] = [];
    array.forEach((item) => {
      const attrType = item[scope];
      if (typeof attrType === 'string') {
        if (scope === 'range') {
          const faixa = attrType.length <= 3 ? Number(attrType.slice(1)) : Number(attrType.split('-')[0]);
          switch (faixa) {
            case 0:
              data[0] = item.total;
              break;
            case 19:
              data[1] = item.total;
              break;
            case 29:
              data[2] = item.total;
              break;
            case 39:
              data[3] = item.total;
              data[4] = item.total;
              break;
            case 49:
              data[5] = item.total;
              break;
            default:
              data[6] = item.total;
          }
          const label = attrType;
          labels.push(label);
          labels = labels.sort(this.sortByAge);
        } else {
          data.push(item.total);
        }
      }

      if (typeof attrType === 'number' && !!constArray) {
        // eslint-disable-next-line array-callback-return
        const newConstArray = constArray.filter((element, i) => {
          if (constArray.indexOf(element) === i) return element.name;
        });
        labels = formatChartLabels(newConstArray);
        constArray.sort(this.sortAlphabetic).forEach((type, index) => {
          if (type.id === attrType) {
            data[index] = item.total;
          }
        });
      }

      if (typeof attrType === 'boolean' && !!constArray) {
        const label = attrType ? 'Sim' : 'Não';
        labels.push(label);
        data.push(item.total);
      }
    });

    return {
      labels,
      data,
    };
  }

  public async getTotalCitizens(token: string): Promise<{ total: number }> {
    try {
      const response = await this.apiInstance.get('/panels/citizens/counts/registered', this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalCitizensImcomplete(token: string): Promise<{ total: number }> {
    try {
      const response = await this.apiInstance.get('/panels/citizens/counts/incomplete', this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalCitizensByRace(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const response = await this.apiInstance.get<ResponseResults<{ complexion_id: number; total: number }>>(
        '/panels/citizens/complexion/counts',
        this.config(token),
      );

      return this.formatBarChartData('complexion_id', response.data.results, races);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalCitizensByGender(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const response = await this.apiInstance.get<ResponseResults<{ gender_id: number; total: number }>>(
        '/panels/citizens/gender/counts',
        this.config(token),
      );

      return this.formatBarChartData('gender_id', response.data.results, sexes);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalCitizensByReligion(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const response = await this.apiInstance.get<ResponseResults<{ religion_id: number; total: number }>>(
        '/panels/citizens/religion/counts',
        this.config(token),
      );

      return this.formatBarChartData('religion_id', response.data.results, religions);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalCitizensBySchooling(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const response = await this.apiInstance.get<ResponseResults<{ schooling_id: number; total: number }>>(
        '/panels/citizens/schooling/counts',
        this.config(token),
      );

      return this.formatBarChartData('schooling_id', response.data.results, schoolings);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalCitizensByJobSituation(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const response = await this.apiInstance.get<ResponseResults<{ job_situation_id: number; total: number }>>(
        '/panels/citizens/job-situation/counts',
        this.config(token),
      );

      return this.formatBarChartData('job_situation_id', response.data.results, situationsJobMarket);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalCitizensByAge(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const response = await this.apiInstance.get<ResponseResults<{ range: string; total: number }>>(
        '/panels/citizens/age/counts',
        this.config(token),
      );

      const data = this.formatBarChartData('range', response.data.results);
      return {
        ...data,
        data: data.data.filter(item => item),
      };
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalCitizensByDeficiency(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const response = await this.apiInstance.get(
        '/panels/citizens/deficiency/counts',
        this.config(token),
      );

      return this.formatBarChartData('deficiency_id', response.data.result, deficiencies);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getCitizenReport(
    token: string,
    query: ReportQueryRequest,
  ): Promise<CommonResultsResponse<CitizenEntity>> {
    try {
      const response = await this.apiInstance.get('/reports/individual', {
        ...this.config(token),
        params: {
          ...query,
          name: query.name || undefined,
          cpf: query.cpf || undefined,
          nis: query.nis || undefined,
        },
      });

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getFamilyreport(
    token: string,
    query: FamilyReportRequest,
  ): Promise<FamilyReportResponse[]> {
    try {
      const response = await this.apiInstance.get('/reports/family', {
        ...this.config(token),
        params: {
          name: query.name || undefined,
          cpf: query.cpf || undefined,
          nib: query.nib || undefined,
          nis: query.nis || undefined,
          benefit_id: query.benefit_id,
          min_gross_income: query.min_gross_income || undefined,
          max_gross_income: query.max_gross_income || undefined,
          min_income_per_capta: query.min_income_per_capta || undefined,
          max_income_per_capta: query.max_income_per_capta || undefined,
          min_benefit_value: query.min_benefit_value || undefined,
          max_benefit_value: query.max_benefit_value || undefined,
          min_age: query.min_age || undefined,
          max_age: query.max_age || undefined,
        },
      });

      return response.data.results;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async moveCitizenHome(
    token: string,
    citizenId: number,
    newHomeId: number,
  ): Promise<{ citizen: CitizenEntity, benefit: CitizenBenefitEntity, newHome: HomeEntity }> {
    try {
      const { data } = await this.apiInstance
        .put<{ citizen: CitizenEntity, benefit: CitizenBenefitEntity, newHome: HomeEntity }>(
          `/citizens/${citizenId}/move/home/${newHomeId}`, undefined,
          this.config(token),
        );

      return data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public generateFicha(citizenId: number) {
    try {
      return `${this.route}/citizens/${citizenId}/ficha`;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public generateCommitmentTerm(citizenId: number) {
    try {
      return `${this.route}/citizens/${citizenId}/termo/compromisso`;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public generateCooperationTerm(citizenId: number) {
    try {
      return `${this.route}/citizens/${citizenId}/termo/cooperacao`;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public generateAccompanimentTerm(citizenId: number) {
    try {
      return `${this.route}/citizens/${citizenId}/termo/acompanhamento`;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public generateSegundaVia(
    citizenId: number,
    viaReason: string,
    bankManager: string,
  ) {
    try {
      return `${this.route}/citizens/${citizenId}/via/segunda?via_reason=${viaReason}&bank_manager=${bankManager}`;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public generateFichaForBreadBenefit(citizenId: number) {
    try {
      return `${this.route}/citizens/${citizenId}/bread/ficha`;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public generateCommitmentTermForBreadBenefit(citizenId: number) {
    try {
      return `${this.route}/citizens/${citizenId}/bread/termo/compromisso`;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public generateCooperationTermForBreadBenefit(citizenId: number) {
    try {
      return `${this.route}/citizens/${citizenId}/bread/termo/cooperacao`;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public generateAccompanimentTermForBreadBenefit(citizenId: number) {
    try {
      return `${this.route}/citizens/${citizenId}/bread/termo/acompanhamento`;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public generateSegundaViaForBreadBenefit(
    citizenId: number,
    viaReason: string,
    bankManager: string,
  ) {
    try {
      return `${this.route}/citizens/${citizenId}/bread/via/segunda?via_reason=${viaReason}&bank_manager=${bankManager}`;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async importIncomes(
    token: string,
    companyId: number,
    citizensIncomes: { cpf: string; income: number }[],
  ): Promise<{
    citizen: CitizenEntity;
    pas_situation: SimulatePasResponse;
    import_income: number;
  }[]> {
    try {
      const { data } = await this.apiInstance.post(
        '/imports/incomes',
        { company_id: companyId, citizens_incomes: citizensIncomes },
        this.config(token),
      );

      return data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }
};

export default new CitizenService();
