import ReactPDF, { pdf } from "@react-pdf/renderer";
import { JSXElementConstructor, ReactElement } from "react";

const useDownloadPdf = () => {

    const download = async (pdfDoc: ReactElement<ReactPDF.DocumentProps, string | JSXElementConstructor<any>>, fileName: string) => {
        const blob = await pdf(pdfDoc).toBlob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
    }

    return { download };
}

export default useDownloadPdf;