type Deficiency = { id: number; name: string }

const deficiencies: Array<Deficiency> = [
  { id: 1, name: "Auditiva" },
  { id: 2, name: "Física" },
  { id: 3, name: "Visual" },
  { id: 4, name: "Intelectual/Cognitiva" },
  { id: 5, name: "Outra" },
];

export default deficiencies;