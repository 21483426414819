const styles: any = {
    cardContainer: {
        backgroundColor: '#FFFFFF',
        padding: 20,
        boxShadow: '0px 0px 8px #0000001F',
        borderRadius: 10,
        flex: 1,
        display: 'flex',
        alignItems: 'center'
    },
};

export default styles;