import React, {
  useContext,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Menu,
  Grid,
  Button,
  MenuItem,
  Typography,
  withStyles,
  LinearProgress,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { GetApp } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import { CellParams, ColDef, DataGrid, GridOverlay } from '@material-ui/data-grid';

import MySelect from '../../../../../MySelect';
import MyTextInput from '../../../../../MyTextInput';
import MyInputMask from '../../../../../MyInputMask';

import { AuthContext } from '../../../../../../providers/Auth';
import BenefitService from '../../../../../../services/BenefitService';
import ExcelExportReport, { ExportExcelRefProps } from '../ExcelExport';
import { CitizenEntity } from '../../../../../../interfaces/entities/citizen.entity';
import { CitizenBenefitEntity } from '../../../../../../interfaces/entities/citizen-benefit.entity';

import styles from './styles';
import formatType from '../../../../../../utils/formatType';
import situationsJobMarket from '../../../../../../constants/situationsJobMarket';
import registerStatusTypes from '../../../../../../constants/registerStatusTypes';
import useDownloadPdf from '../../../../../hooks/useDownloadPdf';
import PdfBenefitiary from '../../pdf_components/PdfBenefitiary';
import { Benefits } from '../../../../../../enums/Benefits';

interface BenefitiaryProps {
  classes: any;
  openSnackbar: any;
}

interface FormData {
  name: string;
  cpf: string;
  nib: string;
  nis: string;
  benefit_id: number;
  min_gross_income: number;
  max_gross_income: number;
  min_income_per_capta: number;
  max_income_per_capta: number;
  min_benefit_value: number;
  max_benefit_value: number;
  min_age: number;
  max_age: number;
}

interface BenefitiaryReport extends CitizenEntity {
  benefit_concessions: CitizenBenefitEntity[];
  residence: {
    district: string;
    residents_number: number;
    income_per_capta: number;
  },
}

const inputLabelProps = {
  style: { color: '#707070', fontWeight: 'bold' },
};
const inputProps = {
  style: { fontSize: 14 },
  startAdornment: (
    <InputAdornment position="start">
      {/* <PersonOutlinedIcon style={{ color: '#707070', fontSize: 16 }} /> */}
    </InputAdornment>
  ),
};

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const BenefitiaryTab: React.FC<BenefitiaryProps> = (props) => {
  const { classes, openSnackbar } = props;
  const { accessToken } = useContext(AuthContext);

  const dateFns = new DateFnsUtils();
  const formRef = useRef<FormHandles>(null);
  const exportExcelRef = useRef<ExportExcelRefProps>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { download } = useDownloadPdf();

  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [benefitiaryReport, setBenefitiaryReport] = useState<BenefitiaryReport[]>([]);
  const [selectedBenefit, setSelectedBenefit] = useState<string>('');

  const columnWidth = 170;
  const reportColumns: ColDef[] = [
    {
      field: 'name',
      headerName: 'Nome do beneficiário',
      width: columnWidth + 30,
    },
    {
      field: 'age',
      headerName: 'Idade',
      width: columnWidth,
    },
    {
      field: 'nis',
      headerName: 'NIS',
      width: columnWidth,
    },
    {
      field: 'pis',
      headerName: 'PIS',
      width: columnWidth,
    },
    {
      field: 'rg',
      headerName: 'RG',
      width: columnWidth,
    },
    {
      field: 'cpf',
      headerName: 'CPF',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => (value as string).replace(/(d{3})(d{3})(d{3})(d{2})/, '$1.$2.$3-$4'),
    },
    {
      field: 'job_situation_id',
      headerName: 'Situação de trabalho',
      width: columnWidth + 30,
      valueFormatter: ({ value }: CellParams) => formatType(value as number, situationsJobMarket),
    },
    {
      field: 'total_income',
      headerName: 'Renda total',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        }).format(value as number);
      },
    },
    {
      field: 'benefit_concessions',
      headerName: 'Status atual',
      width: columnWidth + 20,
      valueFormatter: ({ value }: CellParams) => {
        const concessions: CitizenBenefitEntity[] = value as CitizenBenefitEntity[];
        if (!concessions?.length) {
          return 'Sem registro';
        }
        return formatType(concessions[0].benefit_grant_status_id, registerStatusTypes);
      },
    },
  ];

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  async function onSubmit(data: FormData) {
    formRef.current?.setErrors({});
    setIsLoadingReport(true);

    const schema = Yup.object().shape({
      benefit_id: Yup.number().required('Você deve selecionar um benefício').typeError('Valor inválido'),
      name: Yup.mixed().test({
        message: 'O nome não pode contér números',
        test: (value) => {
          if (!!value) {
            return /^[A-Za-z]+$/.test(value);
          }

          return true;
        },
      }),
      nib: Yup.mixed().test({
        message: 'O NIB não pode contér letras',
        test: (value) => {
          if (!!value) {
            return /^[0-9]+$/.test(value);
          }

          return true;
        },
      }),
      nis: Yup.mixed().test({
        message: 'O NIS não pode contér letras',
        test: (value) => {
          if (!!value) {
            return /^[0-9]+$/.test(value);
          }

          return true;
        },
      }),
      min_gross_income: Yup.mixed(),
      max_gross_income: Yup.mixed(),
      min_income_per_capta: Yup.mixed(),
      max_income_per_capta: Yup.mixed(),
      min_benefit_value: Yup.mixed(),
      max_benefit_value: Yup.mixed(),
      min_age: Yup.mixed(),
      max_age: Yup.mixed(),
    });

    try {
      await schema.validate(data, { abortEarly: false });
      await handleChangeBenefit(data.benefit_id);
      const response = await BenefitService.getBenefitiaryReport(accessToken, data);
      setBenefitiaryReport(response);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};
        let emptyFieldsError = '';

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
          if (error.path === '') {
            emptyFieldsError = error.message;
          }
        });
        if (emptyFieldsError) {
          return openSnackbar('error', emptyFieldsError);
        }
        formRef.current?.setErrors(errorMessages);
      } else {
        openSnackbar('error', error?.message);
      }
    } finally {
      setIsLoadingReport(false);
    }
  }

  function formatExcelExportData() {
    return benefitiaryReport?.map((report) => ({
      ...report,
      job_situation_id: report.job_situation_id ? formatType(report.job_situation_id as number, situationsJobMarket) : 'Sem registro',
      total_income: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
      }).format(Number(report.total_income) as number),
      benefit_concessions: () => {
        const concessions: CitizenBenefitEntity[] = report.benefit_concessions;
        if (!concessions?.length) {
          return 'Sem registro';
        }
        return formatType(concessions[0].benefit_grant_status_id, registerStatusTypes);
      }
    }));
  }

  async function generatePdf() {
    let totalValue = 0;
    const date = new Date();

    const formatMoney = (num: number) => new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(num);

    const pdfData = benefitiaryReport.map(data => {
      totalValue += data.benefit_concessions[0].value;
      return {
        name: data?.name || 'Sem registro',
        cpf: data?.cpf || 'Sem registro',
        nib: data?.nib || 'Sem registro',
        district: data.residence.district,
        telephone: data?.telephone || 'Sem registro',
        bpc: data.bpc ? 'Sim' : 'Não',
        total_income: formatMoney(Number(data?.total_income) || 0),
        members_length: data.residence.residents_number,
        income_capta: formatMoney(data.residence.income_per_capta),
        benefit_value: formatMoney(data.benefit_concessions[0].value),
      };
    });

    await download(
      <PdfBenefitiary
        benefitiaries={pdfData}
        totalValue={totalValue}
        generatedDate={dateFns.format(date, 'dd/MM/yyyy')}
        benefit={selectedBenefit}
      />,
      `relatório_beneficiários_${selectedBenefit + '_' + dateFns.format(date, 'dd_MM_yyyy')}`,
    );
  }

  async function handleChangeBenefit(benefit: number) {
    if (benefit == Benefits.PAS) {
      setSelectedBenefit('PAS');
    } else if (benefit == Benefits.BREAD) {
      setSelectedBenefit('pao_na_mesa');
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} className={classes.filterDiv}>
        <Grid container className={classes.filterContainer}>
          <Grid item xs={12} className={classes.filterTitle}>
            <Typography>Filtrar</Typography>
            <FilterListOutlinedIcon style={{ marginLeft: 10 }} />
          </Grid>
          <Grid item xs={12}>
            <Form ref={formRef} onSubmit={onSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MySelect
                    label="Beneficio"
                    name="benefit_id"
                    options={[
                      { label: 'Programa de Assistência Social (PAS)', value: Benefits.PAS },
                      { label: 'Vale Semana Santa (PEIXE)', value: Benefits.FISH },
                      { label: 'Pão na mesa', value: Benefits.BREAD },
                    ]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    name="name"
                    label="Nome"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Nome do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyInputMask
                    name="cpf"
                    type="cpf"
                    label="CPF"
                    mask="999.999.999-99"
                    InputProps={inputProps}
                    placeholder="000.000.000-00"
                    InputLabelProps={inputLabelProps}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    name="nib"
                    label="NIB"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="NIB do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    name="nis"
                    label="NIS"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="NIS do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="min_gross_income"
                    label="Renda bruta mínima"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Renda bruta mínima do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="max_gross_income"
                    label="Renda bruta máxima"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Renda bruta máxima do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="min_income_per_capta"
                    label="Renda per capita mínima"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Renda per capita mínima do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="max_income_per_capta"
                    label="Renda per capita máxima"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Renda per capita máxima do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="min_benefit_value"
                    label="Valor do benefício mínimo"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Valor do benefício mínimo do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="max_benefit_value"
                    label="Valor do benefício mínimo"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Valor do benefício mínimo do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="min_age"
                    label="Idade mínima"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Idade mínima do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="max_age"
                    label="Idade máxima"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Idade máxima do beneficiário..."
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isLoadingReport}
                      className={classes.buttonRegister}
                    >
                      Consultar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.reportContainer}>
        {
          isLoadingReport && !benefitiaryReport.length ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress size={55} />
            </Box>
          ) : null
        }
        {
          benefitiaryReport.length ? (
            <>
              <Box className={classes.tableToolBar} display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">Resultados</Typography>
                <Button
                  color="primary"
                  variant="outlined"
                  aria-haspopup="true"
                  endIcon={<GetApp />}
                  aria-controls="simple-menu"
                  onClick={handleOpenMenu}>
                  Exportar
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={generatePdf}>Gerar pdf</MenuItem>
                  <MenuItem onClick={() => exportExcelRef.current?.generateExcel()}>Gerar excel</MenuItem>
                </Menu>
              </Box>
              <Box boxShadow={3}>
                <DataGrid
                  columns={reportColumns}
                  disableSelectionOnClick
                  rows={benefitiaryReport}
                  loading={isLoadingReport}
                  autoHeight
                  className={classes.reportHeight}
                  localeText={{
                    footerTotalRows: 'de',
                    footerPaginationRowsPerPage: 'Beneficiários por página'
                  }}
                  components={{
                    LoadingOverlay: CustomLoadingOverlay,
                  }}
                />
              </Box>
            </>
          ) : null
        }
      </Grid>
      <ExcelExportReport
        ref={exportExcelRef}
        columns={reportColumns}
        data={formatExcelExportData()}
        fileName={`relatório-de-beneficiários-${dateFns.format(new Date(), 'dd-MM-yyyy')}.xls`}
      />
    </Grid>
  );
}

export default withStyles(styles)(BenefitiaryTab);