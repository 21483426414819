interface Company {
  id: number;
  name: string;
}

const companiesList: Company[] = [
  { id: 1, name: 'PREFEITURA' },
  { id: 2, name: 'FABAMED (SAÚDE)' },
  { id: 3, name: 'IPM' },
  { id: 4, name: 'PENSÃO ALIMENTÍCIA' },
  { id: 5, name: 'GTS' },
  { id: 6, name: 'MM' },
  { id: 7, name: 'ARQUITEC' },
  { id: 8, name: 'CRISLEY' },
  { id: 9, name: 'CAMARA VEREADORES' },
];

export default companiesList;
