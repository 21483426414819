import { Box, Button, Dialog, DialogContent, Grid, Typography, withStyles } from '@material-ui/core';
import { Check, CloseRounded, InfoOutlined } from '@material-ui/icons';
import React, { useImperativeHandle, useState } from 'react';
import Beneficiary from '../../../model/entities/Beneficiary';
import styles from './styles';

type FishAlertType = 'error' | 'success' | 'info';

interface Props {
  beneficiary: Beneficiary | null;
  setBeneficiary(beneficiary: Beneficiary): void
  classes: any;
}

interface FishAlertShape {
  message: string;
  type: FishAlertType;
}

export interface FishAlertRef {
  openAlert(shape: FishAlertShape, callback?: () => void): void;
  closeAlert(): void;
}

const initialShape: FishAlertShape = {
  message: 'Base message',
  type: 'info',
}

const FishAlert: React.ForwardRefRenderFunction<FishAlertRef, Props> = (props, ref) => {
  const { classes } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [alertShape, setAlertShape] = useState<FishAlertShape>(initialShape);

  function openAlert(shape: FishAlertShape, callback?: () => void) {
    setAlertShape(shape);
    if (callback) callback();
    setIsOpen(true);
  }

  function closeAlert() {
    setIsOpen(false);
    setTimeout(() => {
      setAlertShape(initialShape);
    }, 500);
  }

  useImperativeHandle(ref, () => ({
    openAlert,
    closeAlert,
  }));

  return (
    <Dialog maxWidth="md" fullWidth open={isOpen} onClose={closeAlert}>
      <DialogContent style={{ height: 250 }} className={classes.centerContent}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.centerContent}>
            {alertShape.type === 'error' ? (
              <Box className={classes.circleError}>
                <CloseRounded color="error" style={{ fontSize: 60 }} />
              </Box>
            ) : null}
            {alertShape.type === 'success' ? (
              <Box className={classes.circleSuccess}>
                <Check style={{ fontSize: 60 }} />
              </Box>
            ) : null}
            {alertShape.type === 'info' ? (
              <Box className={classes.circleInfo}>
                <InfoOutlined style={{ fontSize: 80 }} />
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12} className={classes.centerContent}>
            <Typography variant="h5" align="center">
              {alertShape.message}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            onClick={closeAlert}
            className={classes.centerContent}
          >
            <Button variant="contained" color="primary">Ok</Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(React.forwardRef(FishAlert));
