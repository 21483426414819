import React, {
  useRef,
  useState,
  useEffect,
  useContext,
} from 'react';
import {
  Grow,
  Grid,
  Menu,
  Paper,
  Popper,
  Button,
  MenuItem,
  MenuList,
  IconButton,
  ClickAwayListener,
} from '@material-ui/core';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { withStyles } from '@material-ui/core/styles';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';

import UserService from '../../../../services/UserService';

import typesUser from '../../../../constants/typesUser';
import withSnackbar from '../../../templates/WithSnackbar';
import TableMain from '../../../templates/TableMain';

import styles from './styles';
import { MoreVert } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import MyTextInput from '../../../MyTextInput';
import MySelect from '../../../MySelect';
import { AuthContext } from '../../../../providers/Auth';
import { UserEntity } from '../../../../interfaces/entities/user.entity';
import ConfirmDeleteDialog from './components/ConfirmDeleteDialog';

type Props = { classes: any, login: Function, openSnackbar: any, auth: any };

interface Column {
  id: 'id' | 'name' | 'email' | 'type' | 'cpf' | 'login' | 'actions'
  label: string;
  minWidth?: number;
  align?: string;
  format?: (value: number) => string;
  render?: (rowData: any) => any;
}

const UserList: React.FC<Props> = (props) => {
  const { classes, openSnackbar } = props;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [choosedUser, setChoosedUser] = useState<UserEntity>();
  const [users, setUsers] = useState<UserEntity[]>();
  const [openFilterOptions, setOpenFilterOptions] = React.useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { accessToken, user } = useContext(AuthContext);

  const columns: Column[] = [
    { id: 'name', label: 'Nome', minWidth: 170 },
    { id: 'login', label: 'Login', minWidth: 170 },
    { id: 'email', label: 'Email', minWidth: 170 },
    { id: 'type', label: 'Tipo', minWidth: 100, render: (rowData: UserEntity) => (rowData['user_type'].description) },
    {
      id: 'actions', label: 'Ações', align: 'center', minWidth: 100, render: (rowData: UserEntity) => {
        if (user?.user_type_id === 1) {
          return (
            <IconButton
              style={{ padding: 0 }}
              aria-label="Menu de ações"
              onClick={(event) => handleClick(event, rowData)}
            >
              <MoreVert />
            </IconButton>
          );
        }

        if (user.user_type_id !== 1 && rowData.id === user.id) {
          return (
            <IconButton
              style={{ padding: 0 }}
              aria-label="Menu de ações"
              onClick={(event) => handleClick(event, rowData)}
            >
              <MoreVert />
            </IconButton>
          );
        }
        return null;
      }
    },
  ];

  useEffect(() => {
    if (!users) getAllUsers();
  })

  const getAllUsers = async () => {
    try {
      const response = await UserService.getAllUsers(accessToken);

      setUsers(response.results);
    } catch (error: any) {
      handleError(error.message);
    };
  };

  function handleError(e: any) {
    openSnackbar('error', e);
  };


  const openEditForm = (userToEdit?: UserEntity) => {
    let userId = userToEdit?.id;

    history.push(`/app/usuarios/${userId}/editar`);
  };

  const openChangePassword = (userToEdit?: UserEntity) => {
    let userId = userToEdit?.id;

    history.push(`/app/usuarios/${userId}/editar/senha`);
  };

  const openCreateForm = () => {
    history.push('/app/usuarios/cadastrar');
  };

  const openSyncPostsRegister = (userToEdit?: UserEntity) => {
    let userId = userToEdit?.id;

    history.push(`/app/usuarios/${userId}/editar/postos`);
  };

  const openConfirmDialog = () => {
    setShowConfirmDialog(true);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, rowData: UserEntity) => {
    setAnchorEl(event.currentTarget);
    setChoosedUser(rowData);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = () => {
    setOpenFilterOptions((prevOpen) => !prevOpen);
  };

  const orderBy = (option: number) => {
    handleFilter();
  };

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem onClick={() => openEditForm(choosedUser)}>Ver/Editar</MenuItem>
        <MenuItem onClick={() => openChangePassword(choosedUser)}>Alterar Senha</MenuItem>
        {user?.user_type_id === 1 && choosedUser?.user_type_id === 2
          && <MenuItem onClick={() => openSyncPostsRegister(choosedUser)}>Gerenciar Postos de Entrega</MenuItem>}
        {user?.user_type_id === 1
          && <MenuItem className={classes.buttonCancel} onClick={() => openConfirmDialog()}>Inativar</MenuItem>}
      </Menu>

      <Grid container>
        <Grid item xs={12} style={{ margin: '30px 35px' }}>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <span className={classes.pageTitle}>Usuários</span>
            <div className={classes.pageLine}></div>
            <div style={{ position: 'relative' }}>
              <Button className={classes.btnFilter} color="primary" variant="outlined" onClick={handleFilter} endIcon={<FilterListOutlinedIcon />}>
                Filtrar
              </Button>
              <Popper open={openFilterOptions} role={undefined} transition disablePortal className={classes.popper}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleFilter}>
                        <MenuList autoFocusItem={openFilterOptions} id="menu-list-grow" onKeyDown={handleFilter}>
                          <MenuItem onClick={() => orderBy(1)}>Ordem Alfabética</MenuItem>
                          <MenuItem onClick={() => orderBy(2)}>Mais Recentes</MenuItem>
                          <MenuItem onClick={() => orderBy(3)}>Mais Antigos</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
            <Button className={classes.btnAdd} variant="contained" color="primary" onClick={openCreateForm} endIcon={<AddOutlinedIcon />}>
              Cadastrar Novo Usuário
            </Button>
          </Grid>

          <Form ref={formRef} onSubmit={() => { }}>
            <Grid container spacing={2} item xs={12} style={{ display: 'flex', alignItems: 'flex-end', paddingTop: 30, paddingBottom: 20 }}>
              <Grid item xs={4}>
                <MyTextInput
                  name="name"
                  label="Nome"
                  placeholder="Nome do usuário"
                />
              </Grid>
              <Grid item xs={4}>
                <MyTextInput
                  name="cpf"
                  label="CPF"
                  placeholder="000.000.000-00"
                />
              </Grid>
              <Grid item xs={4}>
                <MySelect
                  label="Tipo"
                  name="type"
                  options={typesUser.map((type) => ({ label: type.name, value: type.id }))}
                />
              </Grid>
              <Grid item xs={12} direction="row" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button type="submit" color="primary" variant="contained" className={classes.buttonConsult}>
                  Consultar
                </Button>
              </Grid>
            </Grid>
          </Form>

          <TableMain array={users} columns={columns} />
        </Grid>
      </Grid>
      <ConfirmDeleteDialog
        userId={Number(choosedUser?.id)}
        showDialog={showConfirmDialog}
        toggleDialog={() => setShowConfirmDialog(prevState => !prevState)}
        openSnackbar={openSnackbar}
      />
    </>
  );
}

export default withStyles(styles)(withSnackbar(UserList));
