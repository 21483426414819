import Client from './api/api';
import errorService from './ErrorService';
import { SearchStreetsResponse } from '../interfaces/responses/search-streets.response';
import { StreetEntity } from '../interfaces/entities/street.entity';
import { CommonResultsResponse } from '../interfaces/responses/common-results.response';
import { StreetTypeEntity } from '../interfaces/entities/street-type.entity';

class StreetService {
  private DEFAULT_REF = 'street';
  private apiInstance = Client('api');
  private config = (token: string) => ({ headers: { Authorization: `Bearer ${token}` } });

  public async createLogradouro(token: string, body: StreetEntity): Promise<any> {
    try {
      const response = await this.apiInstance.post('/streets', body, this.config(token));

      return response;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getLogradouro(token: string, streetId: number): Promise<StreetEntity> {
    try {
      const response = await this.apiInstance.get<StreetEntity>(`/streets/${streetId}`, this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getAll(token: string): Promise<StreetEntity[]> {
    try {
      const response = await this.apiInstance.get<StreetEntity[]>(`/streets`, this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getByAddress(
    token: string,
    streetTypeId: number,
    streetName: string,
    district: string,
  ): Promise<StreetEntity> {
    try {
      const response = await this.apiInstance.get<StreetEntity>(
        `/districts/${district}/street/${streetName}/street-type/${streetTypeId}/streets`,
        this.config(token),
      );

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async updateLogradouro(token: string, streetId: number, body: StreetEntity): Promise<any> {
    try {
      const response = await this.apiInstance.put(`/streets/${streetId}/edit`, body, this.config(token));

      return response;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async searchLogradouros(term: string, token: string): Promise<SearchStreetsResponse> {
    try {
      const response = await this.apiInstance.get('/streets/search', {
        params: { term },
        ...this.config(token),
      });

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getStreetTypes(
    token: string
  ): Promise<CommonResultsResponse<StreetTypeEntity>> {
    try {
      const response = await this.apiInstance.get('/streets/types', this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }
}

const streetService = new StreetService();

export default streetService;
