import React, { useContext } from 'react';
import { withStyles, Grid, Typography, Divider } from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';
import { HomeOutlined, DescriptionOutlined, InsertDriveFileOutlined, CardGiftcardOutlined, PersonOutline, DockOutlined } from '@material-ui/icons';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PerfectScrollbar from 'react-perfect-scrollbar'
import styles from './styles';
import { AuthContext } from '../../../providers/Auth';

type Props = { classes: any, children: any, logout: Function, user: any };

const Menu: React.FC<Props> = (props) => {
  const { classes, children, logout } = props;
  const { user } = useContext(AuthContext);


  function OldSchoolMenuLink({ label, to, activeOnlyWhenExact, icon, onClick }: any) {
    let match = useRouteMatch({
      path: to,
      exact: activeOnlyWhenExact
    });

    return (
      <Link onClick={onClick} to={to} className={match ? classes.linkButtonActive : classes.linkButton}>
        {icon(match)}
        <Typography className={match ? classes.linkTitleActive : classes.linkTitle}>{label}</Typography>
      </Link>
    );
  }

  const renderMenuManager = () => {
    // if (user?.type !== UserType.Gestor) {
    //     return null
    // };
    return (
      <Grid
        container
        className={classes.sideContainer}
      >
        <Grid item xs={12} className={classes.sideLogoContainer}>
          <img alt="Logo SFC" src="/assets/logo-prefeitura-madre-large.png" className={classes.logoSfc} />
          <Divider className={classes.divisor} />
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.menuItemsContainer}
        >
          <PerfectScrollbar>
            <OldSchoolMenuLink
              to="/app/inicio"
              label="Início"
              activeOnlyWhenExact={true}
              icon={(match: any) => <HomeOutlined className={match ? classes.linkIconActive : classes.linkIcon} />}
            />
            {/* <OldSchoolMenuLink
                      to="/paineis"
                      label="Painéis"
                      activeOnlyWhenExact={true}
                      icon={(match: any) => <ViewCompactOutlined className={match ? classes.linkIconActive : classes.linkIcon} />}
                  /> */}
            <OldSchoolMenuLink
              to="/app/relatorios"
              label="Relatórios"
              activeOnlyWhenExact={true}
              icon={(match: any) => <DescriptionOutlined className={match ? classes.linkIconActive : classes.linkIcon} />}
            />
            <OldSchoolMenuLink
              to="/app/cadastro"
              label="Cadastro"
              activeOnlyWhenExact={true}
              icon={(match: any) => <InsertDriveFileOutlined className={match ? classes.linkIconActive : classes.linkIcon} />}
            />
            <OldSchoolMenuLink
              to="/app/beneficios"
              label="Benefícios"
              activeOnlyWhenExact={true}
              icon={(match: any) => <CardGiftcardOutlined className={match ? classes.linkIconActive : classes.linkIcon} />}
            />
            <OldSchoolMenuLink
              to="/app/usuarios"
              label="Usuários"
              activeOnlyWhenExact={true}
              icon={(match: any) => <PersonOutline className={match ? classes.linkIconActive : classes.linkIcon} />}
            />
            <OldSchoolMenuLink
              to="/app/importacao/rendas"
              label="Importar Renda"
              activeOnlyWhenExact={true}
              icon={(match: any) => <MonetizationOnIcon className={match ? classes.linkIconActive : classes.linkIcon} />}
            />

        {user?.user_type_id === 1
          && <OldSchoolMenuLink
            to="/app/postos"
            label="Postos de Entrega"
            activeOnlyWhenExact={true}
            icon={(match: any) => <DockOutlined className={match ? classes.linkIconActive : classes.linkIcon} />}
          />}
          </PerfectScrollbar>
        </Grid>
        <Grid item xs={12} className={classes.sideLogoutContainer}>
          <Divider className={classes.divisor} />
          <Link onClick={(e: any) => { e.preventDefault(); logout(); }} to="/app/usuarios/cadastrar" className={classes.linkButtonUnderline}>
            <Typography className={classes.linkTitle}>SAIR</Typography>
          </Link>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={1} className={classes.containerMenu}>
        {renderMenuManager()}
      </Grid>
      <Grid item xs={11} className={classes.containerApp}>
        <PerfectScrollbar>
          {children}
        </PerfectScrollbar>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(Menu);