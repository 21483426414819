import { AxiosResponse } from "axios";
import { messages } from "../constants/messages";
import { ErrorResponse } from "../interfaces/responses/error.response";

class ErrorService {
  private handleUnauthorizedError() {
    return {
      message: messages.authenticationFailure,
    };
  }

  private handlePasswordIncompatible() {
    return {
      message: 'Senha atual incompatível',
    };
  }

  private handleCurrentPasswordIncompatibleNew() {
    return {
      message: 'Confirmação de senha incompatível com a nova',
    };
  }

  private handleTokenExpiredError() {
    localStorage.setItem('access_token', '');
    localStorage.setItem('expires_in', '');
    localStorage.setItem('isLogged_in', '');
    window.location.href = '/';

    return {
      message: messages.sessionExpired,
    };
  }

  private handleInvalidTokenError() {
    return {
      message: 'Token fornecido é inválido',
    };
  }

  private handleTokenNotProvidedError() {
    return {
      message: 'O token não foi fornecido',
    };
  }

  private handleInvalidRequestError() {
    return {
      message: 'Dados envidados estão mal formatados',
    };
  }

  private handleResourceNotFoundError() {
    return {
      message: 'O recurso solicitado não foi encontrado',
    };
  }

  private handleResourceNotFoundAlreadyExistsError() {
    return {
      message: 'O recurso já existe',
    };
  }

  private handleRendaBPCInvalidError() {
    return {
      message: 'Renda per capta para BPC é incompatível',
    };
  }

  private handleRendaPerCapitaInvalidError() {
    return {
      message: 'Renda per capta é incompatível',
    };
  }

  private handleValueWrongSuggestedError() {
    return {
      message: 'Valor divergente ao previsto para a concessão',
    };
  }

  private handleUnexpectedError() {
    return {
      message: 'Um erro não esperado ocorreu',
    };
  }

  private handleUserNotOperator() {
    return {
      message: 'Apenas usuários operadores podem ter postos de entrega atribuídos a si',
    };
  }

  public handleError(error: any) {
    if (!error?.response) {
      return this.handleUnexpectedError();
    }
    const response: AxiosResponse<ErrorResponse> = error?.response;
    const code = response.data.error;
    switch (code) {
      case 'AUTH001':
        return this.handleUnauthorizedError();
      case 'AUTH002':
        return this.handleTokenNotProvidedError();
      case 'AUTH003':
        return this.handleInvalidTokenError();
      case 'AUTH004':
        return this.handleTokenExpiredError();
      case 'REQ001':
        return this.handleInvalidRequestError();
      case 'RES001':
        return this.handleResourceNotFoundError();
      case 'RES002':
        return this.handleResourceNotFoundAlreadyExistsError();
      case 'PAS001':
        return this.handleRendaBPCInvalidError();
      case 'PAS002':
        return this.handleRendaPerCapitaInvalidError();
      case 'PAS003':
        return this.handleValueWrongSuggestedError();
      case 'SEN001':
        return this.handlePasswordIncompatible();
      case 'SEN002':
        return this.handleCurrentPasswordIncompatibleNew();
      case 'SEM003':
        return this.handleUserNotOperator();
      default:
        return this.handleUnexpectedError();
    }
  }
}

const errorService = new ErrorService();

export default errorService;