import React, { useRef, useState } from 'react';
import * as Yup from 'yup';

import { Grid, Typography, withStyles } from '@material-ui/core';

import withSnackbar from '../../../templates/WithSnackbar';

import BenefitChristmasBasketService from '../../../../services/BenefitChristmasBasketService';

import { messages } from '../../../../constants/messages';

import ButtonMain from '../../../templates/ButtonMain';
import styles from './styles';
import Beneficiary from '../../../../model/entities/Beneficiary';
import { Form } from '@unform/web';
import MyTextInput from '../../../MyTextInput';
import { FormHandles } from '@unform/core';

interface FormData {
  cpf: string;
  nis: string;
}

type Props = {
  auth: any,
  classes: any,
  history: any,
  login: Function,
  openSnackbar: any,
  onConsultBeneficiary(beneficiary: Beneficiary | null): void;
  handleNotFoundBeneficiary(): void;
};

const ChristmasBasketConsult: React.FC<Props> = (props) => {
  const { classes, openSnackbar, onConsultBeneficiary, handleNotFoundBeneficiary } = props;

  const [term, setTerm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const formSearchRef = useRef<FormHandles>(null);

  function handleError(e: any) {
    openSnackbar('error', e);
  }

  const getCitizen = async (cpf: string, nis: string) => {
    const benefitData = await BenefitChristmasBasketService.consult(cpf, nis);
    const citizen = {
      ...benefitData.citizen,
      benefit: { ...benefitData.benefit }
    };
    if (citizen) {
      return citizen as unknown as Beneficiary;
    }

    return null;
  }

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      const schema = Yup.object().shape({ cpf: Yup.string().required(messages.emptyFields), nis: Yup.string().required(messages.emptyFields) });
      await schema.validate({ cpf: data.cpf, nis: data.nis });

      const beneficiary = await getCitizen(data.cpf, data.nis);

      onConsultBeneficiary(beneficiary);
    } catch (err: any) {
      console.log(err);
      if (err instanceof Yup.ValidationError) {
        handleError(err.message);
      } else if (err.message === 'O recurso solicitado não foi encontrado') {
        handleNotFoundBeneficiary();
      } else {
        handleError('Um erro não esperado ocorreu. Verifique a conexão com a internet');
      }
    }
    setLoading(false);
  };

  return (
    <Grid container item lg={3} md={4} sm={6} xs={11} spacing={2} className={classes.cardLogin}>
      <Grid item xs={12} className={classes.titleContainer}>
        <Typography className={classes.title18}>Bem Vindo!</Typography>
      </Grid>
      <Grid item xs={12} className={classes.titleContainer}>
        <Typography className={classes.title16}>Identificação através do CPF e NIS</Typography>
      </Grid>
      <Form
        ref={formSearchRef}
        onSubmit={onSubmit}
      >
        <Grid item xs={12}>
          <MyTextInput
            name="cpf"
            label="CPF"
            placeholder="Digite o CPF"
          />
          <MyTextInput
            name="nis"
            label="NIS"
            placeholder="Digite o NIS"
          />
        </Grid>
        <Grid item xs={12} className={classes.divButton}>
          <ButtonMain type='submit' loading={loading} title="Consultar" />
        </Grid>
      </Form>
    </Grid>
  );
}

export default withStyles(styles)(withSnackbar(ChristmasBasketConsult));