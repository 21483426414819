const styles: any = {
  dialogContent: {
    padding: '1rem 0rem',
  },
  buttonCancel: {
    borderColor: '#D4586D',
    color: '#707070',
    textTransform: 'capitalize',
    "&:hover": {
      backgroundColor: '#D4586D',
      color: '#fff',
    },
  },
  buttonRegister: {
    marginLeft: '15px',
    color: '#fff',
    textTransform: 'capitalize',
  },
};

export default styles;
