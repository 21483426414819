const styles: any = {
  pdfMain: {
    padding: 5,
  },
  documentTitle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    marginBottom: '30px',
    padding: '10pt 10pt',
  },
  generatedDateText: {
    fontSize: 13,
    color: '#AAAAAA',
  },
  mainGridContainer: {
    width: '100%',
    border: '2pt solid #DDD',
  },
  mainGridHeader: {
    width: '100%',
    borderBottom: '1pt solid #DDD',
    backgroundColor: '#EEEEEE',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  mainGridBody: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  mainGridCell: {
    width: '12%',
    fontSize: 10,
    padding: 10,
    textAlign: 'center',
  },
  resumeContainer: {
    width: '100%',
    border: '2pt solid #DDD',
    backgroundColor: '#EEEEEE',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: 10,
    fontSize: 10,
    padding: 10,
    marginTop: 20,
  }
};

export default styles;
