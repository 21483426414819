import React, { useState, useRef } from 'react';
import ConfirmDialog from '../ConfirmDialog';

interface ActionsRef {
  cancelAction(): void;
  confirmAction(): void;
  dismiss(): void;
}

interface FunctionOpenParams {
  title: string,
  message: string,
  confirmText?: string,
  cancelText?: string,
  cancelAction?: () => void,
  confirmAction?: () => void,
}

export interface WrappedType {
  openConfirmDialog(params: FunctionOpenParams): void;
}

const withConfirmDialog = <P extends WrappedType>(
  WrappedComponent: React.ComponentType<P>
): React.FC<Omit<P, "openConfirmDialog">> => (props) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [confirmText, setConfirmText] = useState('Sim');
  const [cancelText, setCancelText] = useState('Não');
  const actionsRef = useRef<ActionsRef>({
    dismiss() {
      setOpenDialog(false);
    },
    cancelAction() {
      this.dismiss();
    },
    confirmAction() {
      this.dismiss();
    },
  });

  function openConfirmDialog({
    title,
    message,
    confirmText,
    cancelText,
    cancelAction,
    confirmAction,
  }: FunctionOpenParams) {
    setTitle(title)
    setMessage(message);
    setConfirmText((prevState) => confirmText || prevState);
    setCancelText((prevState) => cancelText || prevState);
    actionsRef.current = {
      ...actionsRef.current,
      cancelAction() {
        if (cancelAction) cancelAction();
        actionsRef.current.dismiss();
      },
      confirmAction() {
        if (confirmAction) confirmAction();
        actionsRef.current.dismiss();
      },
    }
    setOpenDialog(true);
  }

  return (
    <>
      <WrappedComponent
        {...(props as P)}
        openConfirmDialog={openConfirmDialog}
      />
      <ConfirmDialog
        open={openDialog}
        title={title}
        message={message}
        cancelText={cancelText}
        cancelAction={actionsRef.current.cancelAction}
        confirmAction={actionsRef.current.confirmAction}
        confirmText={confirmText}
        onClose={() => setOpenDialog(false)}
      />
    </>
  );
}

export default withConfirmDialog;