import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import withSnackbar from '../../../templates/WithSnackbar';

import styles from './styles';
import { useHistory } from 'react-router-dom';
import BenefitListFish from './BenefitListFish';
import { Benefits } from '../../../../enums/Benefits';
import BenefitListChristmasBasket from "./BenefitListChristmasBasket";
import BenefitListSemanaSanta from './BenefitListSemanaSanta';

type Props = { classes: any; openSnackbar: any; benefit: number; };

const BenefitList: React.FC<Props> = ({ benefit }) => {
  const history = useHistory();

  const renderBenefitList = () => {
    if (benefit === Benefits.JUNEBASKET) {
      return (
        <BenefitListFish history={history} enableRegister={true} />
      );
    }

    if (benefit === Benefits.CHRISTMASBASKET) {
      return (
          <BenefitListChristmasBasket history={history} enableRegister={true} />
      );
    }

    if (benefit === Benefits.FISH) {
      return (
          <BenefitListSemanaSanta history={history} enableRegister={true} />
      );
    }

    return null;
  };

  return <>{renderBenefitList()}</>;
}

export default withStyles(styles)(withSnackbar(BenefitList));
