const styles: any = {
    container: {
        paddingTop: '50px',
        backgroundImage: 'url(\'/assets/bg_home.png\')',
        backgroundSize: 'cover',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& img': {
            width: '90%',
        },
        '& > img': {
            width: '300px',
        }
    },
    grid: {
        marginTop: '50px',
    },
    title: {
        fontSize: '50px',
    },
    subTitle: {
        fontSize: '25px',
    },
};

export default styles;