import errorService from './ErrorService';
import Client from './api/api';
import { HomeEntity } from '../interfaces/entities/home.entity';
import { OmitedHomeAttributes, SearchHomeResponse } from '../interfaces/responses/search-home.response';
import { GetResidentsResponse } from '../interfaces/responses/get-residents';
import formatChartLabels from '../utils/formatChartLabels';
import bathroomDrains from '../constants/bathroomDrains';
import waterSupplies from '../constants/waterSupplies';
import trashDestinations from '../constants/trashDestinations';
import { HomeReportResponse } from '../interfaces/responses/home-report.response';

interface ResponseResults<Type> {
  results: Type[];
}

interface ResponseChartData {
  labels: string[];
  data: number[];
}

interface ArrayType {
  [key: string]: string | number | boolean;
  total: number;
}

interface CreateHomeRequest {
  description?: string;
  cep: string;
  district: string;
  street_name: string;
  street_number?: string;
  complement?: string;
  reference_point?: string;
  rural_zone: boolean;
  quilombola: boolean;
  telephone: string;
  latitude: number;
  longitude: number;
  rent_value?: number;
  paved_access: boolean;
  electric_power_supply: boolean;
  housing_situation_id: number;
  street_type_id: number;
  water_supply_id: number;
  sewage_id: number;
  garbage_disposal_id: number;
}

class HomeService {
  private DEFAULT_REF = 'home';
  private apiInstance = Client('api');
  private config = (token: string) => ({ headers: { Authorization: `Bearer ${token}` } });

  private sortAlphabetic(a: any, b: any) {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  }

  private formatBarChartData(scope: string, array: ArrayType[], constArray?: any[]) {
    let labels: string[] = [];
    let data: number[] = [];
    array.forEach((item) => {
      const attrType = item[scope];
      if (typeof attrType === 'string') {
        if (scope === 'range') {
          const label = attrType.includes('+') ?
            `Mais de ${attrType.slice(-1)} salário(s) mínimo(s)` :
            `Até ${attrType} salário(s) mínimo(s)`
          labels.push(label);
        } else {
          labels.push(attrType);
        }
        data.push(item.total);
      }
      if (typeof attrType === 'number' && !!constArray) {
        // eslint-disable-next-line array-callback-return
        const newConstArray = constArray.filter((element, i) => {
          if (constArray.indexOf(element) === i) return element.name;
        });
        labels = formatChartLabels(newConstArray);
        constArray.sort(this.sortAlphabetic).forEach((type, index) => {
          if (type.id === attrType) {
            data[index] = item.total;
          }
        });
      }

      if (typeof attrType === 'boolean') {
        const label = attrType ? 'Sim' : 'Não';
        labels.push(label);
        data.push(item.total);
      }
    })

    return {
      labels: labels,
      data,
    };
  }

  public async getHome(homeId: number, token: string): Promise<HomeEntity> {
    try {
      const response = await this.apiInstance.get(`/homes/${homeId}`, this.config(token));
      return response?.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async createHome(token: string, body: CreateHomeRequest): Promise<any> {
    try {
      const response = await this.apiInstance.post('/homes', body, this.config(token));

      return response;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async updateHome(token: string, homeId: number, body: any): Promise<any> {
    try {
      const response = await this.apiInstance.put(`/homes/${homeId}/edit`, body, this.config(token));

      return response;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async searchHome(
    district: string,
    street_name: string,
    token: string,
    term: string | null = null,
  ): Promise<SearchHomeResponse> {
    try {
      const response = await this.apiInstance.get('/homes/search', {
        ...this.config(token),
        params: { district, street_name, term },
      });
      return response?.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }
  public async searchHomeByDistrictAndStreet(
    district: string,
    street_name: string,
    token: string,
  ): Promise<{ results: Omit<HomeEntity, OmitedHomeAttributes>[] }> {
    try {
      const response = await this.apiInstance.get<SearchHomeResponse>(
        '/homes/search',
        {
          ...this.config(token),
          params: { district, street_name, term: null },
        }
      );
      const homes: Omit<HomeEntity, OmitedHomeAttributes>[] = [];
      response?.data.results.forEach((groupedHome) => {
        groupedHome.street_types.forEach((streetTypes) => {
          streetTypes.streets.forEach((street) => {
            street.homes.forEach((home) => {
              homes.push(home);
            });
          });
        });
      })
      return { results: homes };
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getResidentes(
    homeId: number,
    token: string,
    onlyInTerritory?: boolean,
    onlyAlive?: boolean,
  ): Promise<GetResidentsResponse> {
    try {
      const response = await this.apiInstance.get(`/homes/${homeId}/residents`, {
        ...this.config(token),
        params: {
          only_in_territory: onlyInTerritory,
          only_alive: onlyAlive,
        }
      });

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalHomes(token: string): Promise<{ total: number }> {
    try {
      const response = await this.apiInstance.get('/panels/homes/counts/registered', this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalHomesImcomple(token: string): Promise<{ total: number }> {
    try {
      const response = await this.apiInstance.get('/panels/homes/counts/incomplete', this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalHomesByDistrict(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const response = await this.apiInstance
        .get<ResponseResults<{ district: string; total: number }>>(
          '/panels/homes/district/counts',
          this.config(token)
        );

      return this.formatBarChartData('district', response.data.results);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalHomesByPowerSupply(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const response = await this.apiInstance
        .get<ResponseResults<{ electric_power_supply: boolean; total: number }>>(
          '/panels/homes/power-supply/counts',
          this.config(token),
        );

      return this.formatBarChartData('electric_power_supply', response.data.results);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalHomesBySewage(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const response = await this.apiInstance
        .get<ResponseResults<{ sewage_id: number; total: number }>>(
          '/panels/homes/sewage/counts',
          this.config(token),
        );

      return this.formatBarChartData('sewage_id', response.data.results, bathroomDrains);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalHomesByWaterSupply(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const response = await this.apiInstance
        .get<ResponseResults<{ water_supply_id: number; total: number }>>(
          '/panels/homes/water-supply/counts',
          this.config(token),
        );

      return this.formatBarChartData('water_supply_id', response.data.results, waterSupplies);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalHomesByGarbageDisposal(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const response = await this.apiInstance
        .get<ResponseResults<{ garbage_disposal_id: number; total: number }>>(
          '/panels/homes/garbage-disposal/counts',
          this.config(token),
        );

      return this.formatBarChartData('garbage_disposal_id', response.data.results, trashDestinations);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalHomesByIncome(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const response = await this.apiInstance
        .get<ResponseResults<{ range: string; total: number }>>(
          '/panels/homes/income/counts',
          this.config(token),
        );

      return this.formatBarChartData('range', response.data.results);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getHomeReport(
    token: string,
    district?: string,
    complement?: string,
    residentName?: string,
    energySupply?: boolean,
    sewageId?: number,
    waterSupplyId?: number,
    garbageDisposalId?: number,
    housingSituationId?: number,
  ): Promise<HomeReportResponse> {
    try {
      const response = await this.apiInstance.get<HomeReportResponse>('/reports/home', {
        ...this.config(token),
        params: {
          district: district || undefined,
          complement: complement || undefined,
          resident_name: residentName || undefined,
          energy_supply: energySupply !== undefined ? Number(energySupply) : undefined,
          sewage: sewageId,
          water_supply: waterSupplyId,
          garbage_disposal: garbageDisposalId,
          housing_situation: housingSituationId,
        },
      });

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }
};

const homeService = new HomeService();

export default homeService;
