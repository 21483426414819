import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  withStyles,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { WarningOutlined } from '@material-ui/icons';

import styles from './styles';
import { Benefits } from '../../../../../../enums/Benefits';

interface Props {
  cidadaoId: number;
  benefitId: number;
  benefitGrantedId: number;
  updateChangeBenefit: boolean;
  showDialog: boolean;
  toggleDialog(): void;
  classes: any;
}

const BenefitChangeDialog: React.FC<Props> = (props) => {
  const { showDialog, toggleDialog, classes, cidadaoId, benefitGrantedId, benefitId, updateChangeBenefit } = props;
  const history = useHistory();
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    if (showDialog) {
      counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
      if (counter === 0) {
        if (benefitId === Benefits.BREAD && benefitGrantedId) {
          history.push(`/app/beneficios/paonamesa/cidadao/${cidadaoId}/editar?benefitGrantedId=${benefitGrantedId}`)

        } else {
          history.goBack();
        }
      }
    } else {
      setCounter(5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, showDialog]);

  return (
    <Dialog open={showDialog} onClose={toggleDialog} aria-labelledby="alert-dialog-title">
      <DialogContent className={classes.alertContent}>
        <WarningOutlined className={classes.icon} fontSize="large" color="primary" />
        <Typography className={classes.text} variant="h6" color="primary">
          {updateChangeBenefit ? 'O benefício do indivíduo será DESLIGADO! '
            : benefitGrantedId ? 'Será necessario uma revisão do benefício!'
              : 'O Domicílio de destino já possui um responsavel. O benefício do indivíduo migrado será DESLIGADO! '}
          Estamos redirecionando você em {counter}s...
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(BenefitChangeDialog);
