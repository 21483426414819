type Religion = { id: number; name: string }

const religions: Array<Religion> = [
  { id: 1, name: "Cristianismo" },
  { id: 2, name: "Catolicismo" },
  { id: 3, name: "Ortodoxo" },
  { id: 4, name: "Protestante" },
  { id: 5, name: "Islamismo" },
  { id: 6, name: "Judaísmo" },
  { id: 7, name: "Candomblé" },
  { id: 8, name: "Espiritismo" },
  { id: 9, name: "Evangélico" },
  { id: 10, name: "Testemunha de Jeová" },
  { id: 11, name: "Outra" },
  { id: 12, name: "Não possui" },
];

export default religions;