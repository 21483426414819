
import Client from './api/api';
import errorService from './ErrorService';

import formatChartLabels from '../utils/formatChartLabels';
import benefitStatus from '../constants/status';

import { GetBenefitsResponse } from '../interfaces/responses/get-benefits.response';
import { CitizenBenefitEntity } from '../interfaces/entities/citizen-benefit.entity';
import { SearchBeneficiariosResponse } from '../interfaces/responses/search-beneficiarios.response';
import { SearchCandidatesBenefits } from '../interfaces/responses/search-candidates-benefits.response';
import races from '../constants/races';
import sexes from '../constants/sexes';
import BenefitPeixeStatus from '../constants/benefitPeixeStatus';
interface ArrayType {
  [key: string]: string | number;
  total: number;
}

interface ResponseResults<Type> {
  results: Type[];
}

interface ResponseChartData {
  labels: string[];
  data: number[];
}

interface BenefitiaryRequest {
  name: string;
  cpf: string;
  nib: string;
  nis: string;
  benefit_id: number;
  min_gross_income: number;
  max_gross_income: number;
  min_income_per_capta: number;
  max_income_per_capta: number;
  min_benefit_value: number;
  max_benefit_value: number;
  min_age: number;
  max_age: number;
}

class BenefitService {
  private apiInstance = Client('api');
  private config = (token: string) => ({ headers: { Authorization: `Bearer ${token}` } });
  private DEFAULT_REF = 'benefit';

  public async getBenefits(token: string): Promise<GetBenefitsResponse> {
    try {
      const response = await this.apiInstance.get('/benefits', this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async searchBeneficiarios(
    benefitId: number,
    token: string,
    term?: string,
    status?: number,
    year?: number,
  ): Promise<SearchBeneficiariosResponse> {
    try {
      const response = await this.apiInstance.get(`/benefits/${benefitId}/citizens/search`, {
        ...this.config(token),
        params: {
          term,
          status,
          year,
        },
      });

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getBeneficioConcedido(
    benefitGrantedId: number,
    token: string
  ): Promise<CitizenBenefitEntity> {
    try {
      const { data } = await this.apiInstance.get<CitizenBenefitEntity>(`/benefits/granted/${benefitGrantedId}`, this.config(token));

      return data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async searchCandidatesBenefits(
    benefitId: number,
    token: string,
    term?: string,
  ): Promise<SearchCandidatesBenefits> {
    try {
      const response = await this.apiInstance.get(`/benefits/${benefitId}/citizens/candidates/search`,
        {
          ...this.config(token),
          params: { term },
        }
      );

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async registerObervationFromBenefit(
    benefitGrantedId: number,
    token: string,
    description?: string,
  ): Promise<any> {
    try {
      const response = await this.apiInstance.post(`citizens/benefits/${benefitGrantedId}/observations`, { description }, this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async registerObervationFromCitizen(
    citizenId: number,
    token: string,
    description?: string,
  ): Promise<any> {
    try {
      const response = await this.apiInstance.post(`citizens/${citizenId}/observations`, { description }, this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  private sortAlphabetic(a: any, b: any) {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  }

  private sortByYear(a: string, b: string) {

    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  }

  private formatBarChartData(scope: string, array: ArrayType[], constArray?: any[]) {
    let labels: string[] = [];
    let data: number[] = [];
    if (scope === 'benefit_year') {
      array.sort((a, b) => {
        return Number(a['benefit_year']) - Number(b['benefit_year']);
      });
    }
    array.forEach((item) => {
      const attrType = item[scope];
      if (typeof attrType === 'string' || scope === 'benefit_year') {
        labels.push(attrType as string);
        data.push(item.total);
      }

      if (typeof attrType === 'number' && !!constArray) {
        // eslint-disable-next-line array-callback-return
        const newConstArray = constArray.filter((element, i) => {
          if (constArray.indexOf(element) === i) return element.name;
        });
        labels = formatChartLabels(newConstArray);
        constArray.sort(this.sortAlphabetic).forEach((type, index) => {
          if (type.id === attrType) {
            data[index] = item.total;
          }
        });
      }
    });

    return {
      labels,
      data,
    };
  }

  public async getTotalBeneficiaryByStatus(
    token: string,
    benefitId: number,
  ): Promise<ResponseChartData> {
    try {
      const { data } = await this.apiInstance
        .get<ResponseResults<{ benefit_grant_status_id: number, total: number }>>(
          `/panels/benefits/${benefitId}/status/counts`, this.config(token)
        );

      return this.formatBarChartData(
        'benefit_grant_status_id',
        data.results,
        benefitId === 2 ? BenefitPeixeStatus : benefitStatus,
      );
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryByYear(
    token: string,
    benefitId: number,
  ): Promise<ResponseChartData> {
    try {
      const { data } = await this.apiInstance
        .get<ResponseResults<{ benefit_year: string, total: number }>>(
          `/panels/benefits/${benefitId}/year/counts`,
          this.config(token)
        );

      return this.formatBarChartData('benefit_year', data.results);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryByGender(
    token: string,
    benefitId: number,
  ): Promise<ResponseChartData> {
    try {
      const { data } = await this.apiInstance
        .get<ResponseResults<{ gender_id: number, total: number }>>(
          `/panels/benefits/${benefitId}/gender/counts`,
          this.config(token)
        );

      return this.formatBarChartData('gender_id', data.results, sexes);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryByComplexion(
    token: string,
    benefitId: number,
  ): Promise<ResponseChartData> {
    try {
      const { data } = await this.apiInstance
        .get<ResponseResults<{ complexion_id: number, total: number }>>(
          `/panels/benefits/${benefitId}/complexion/counts`,
          this.config(token)
        );

      return this.formatBarChartData('complexion_id', data.results, races);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryRegistered(
    token: string,
    benefitId: number,
  ): Promise<{ total: number }> {
    try {
      const { data } = await this.apiInstance
        .get(
          `/panels/benefits/${benefitId}/registered/counts`,
          this.config(token)
        );

      return data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryIncomplete(
    token: string,
    benefitId: number,
  ): Promise<{ total: number }> {
    try {
      const { data } = await this.apiInstance
        .get(
          `/panels/benefits/${benefitId}/incomplete/counts`,
          this.config(token)
        );

      return data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalConcessions(
    token: string,
    benefitId: number,
  ): Promise<{ total: number }> {
    try {
      const { data } = await this.apiInstance
        .get(
          `/panels/benefits/${benefitId}/concession/counts`,
          this.config(token)
        );

      return data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getBenefitiaryReport(
    token: string,
    query: BenefitiaryRequest,
  ): Promise<any[]> {
    try {
      const response = await this.apiInstance.get('/reports/benefitiary', {
        ...this.config(token),
        params: {
          name: query.name || undefined,
          cpf: query.cpf || undefined,
          nib: query.nib || undefined,
          nis: query.nis || undefined,
          benefit_id: query.benefit_id,
          min_gross_income: query.min_gross_income || undefined,
          max_gross_income: query.max_gross_income || undefined,
          min_income_per_capta: query.min_income_per_capta || undefined,
          max_income_per_capta: query.max_income_per_capta || undefined,
          min_benefit_value: query.min_benefit_value || undefined,
          max_benefit_value: query.max_benefit_value || undefined,
          min_age: query.min_age || undefined,
          max_age: query.max_age || undefined,
        },
      });

      return response.data.results;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async updateProntuario(token: string, benefitGrantedId: number, record?: string): Promise<any> {
    try {
      const response = await this.apiInstance.put(
        `/benefits/granted/${benefitGrantedId}/edit`,
        {
          social_record: record,
        },
        this.config(token),
      );

      return response;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async updateNib(token: string, benefitGrantedId: number, nib?: string): Promise<any> {
    try {
      const response = await this.apiInstance.put(
        `/benefits/granted/${benefitGrantedId}/edit`,
        {
          nib,
        },
        this.config(token),
      );

      return response;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }
}

export default new BenefitService();
