import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useContext,
} from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  InputAdornment,
  withStyles,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import MySelect from '../../../../../MySelect';
import MyTextInput from '../../../../../MyTextInput';

import { AuthContext } from '../../../../../../providers/Auth';
import CitizenService from '../../../../../../services/CitizenService';

import styles from './styles';
import incomeOrigins from '../../../../../../constants/incomeOrigins';
import { messages } from '../../../../../../constants/messages';

export interface RefProps {
  toggleDialog(): void;
}

interface Props {
  classes: any;
  citizenId: number;
  selectedIncome?: {
    id: number;
    ammount: number;
    description: string;
  };
  reloadMembers(): void;
  onSuccess(): void;
}

interface FormRendaData {
  description: string;
  ammount: number;
}


const inputLabelProps: any = { style: { color: '#707070', fontWeight: 'bold' } };

const AddIncomeDialog: React.ForwardRefRenderFunction<RefProps, Props> = (props, ref) => {
  const {
    classes,
    selectedIncome,
    citizenId,
    onSuccess,
  } = props;

  const [showDialog, setShowDialog] = useState(false);
  const [isLoadingRenda, setIsLodingRenda] = useState(false);
  const { accessToken } = useContext(AuthContext);
  const [inputIncomeValueIsDisabled, setInputIncomeValueIsDisabled] = useState<boolean>(false);

  const formRef = useRef<FormHandles>(null);

  function toggleDialog() {

    setShowDialog(prevState => !prevState);
  }

  useImperativeHandle(ref, () => ({
    toggleDialog,
  }));

  const checkValue = (description: string) => {
    if (description) {
      const income = incomeOrigins[9];
      if (description === 'Nenhuma') {
        setInputIncomeValueIsDisabled(true);
        formRef.current?.setFieldValue('ammount', '0.00');
        return;
      }
      if (income?.name === description) {
        setInputIncomeValueIsDisabled(true);
        formRef.current?.setFieldValue('ammount', income.value);
      } else {
        setInputIncomeValueIsDisabled(false);
        formRef.current?.clearField('ammount');
      }
    } else {
      setInputIncomeValueIsDisabled(false);
    };
  };

  const addIncome = async (data: FormRendaData) => {
    await CitizenService.createRenda(
      citizenId,
      data,
      accessToken,
    );

    onSuccess();
  }

  async function editIncome(data: FormRendaData) {
    if (!!selectedIncome) {
      await CitizenService.updateRenda(
        accessToken,
        citizenId,
        selectedIncome.id,
        data,
      );

      onSuccess();
    }
  }

  async function handleSubmit(data: FormRendaData) {
    setIsLodingRenda(true);
    const schema = Yup.object().shape({
      ammount: Yup.number().nullable().required(messages.emptyField).typeError('Tipo inválido'),
      description: Yup.string().required(messages.emptyField).typeError('Tipo inválido'),
    });
    try {
      await schema.validate(data, { abortEarly: false });
      if (!!selectedIncome && !!selectedIncome?.id) {
        await editIncome(data);
      } else {
        await addIncome(data);
      }

      formRef.current?.reset();
      toggleDialog();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        formRef.current?.setErrors(errorMessages);
      }
    } finally {
      setIsLodingRenda(false);
    }
  }

  return (
    <Dialog open={showDialog} onClose={toggleDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {!selectedIncome?.id ? 'Adicionar' : 'Editar'} renda
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Selecione abaixo a origem da renda e digite o valor.
        </DialogContentText>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={{
            ...selectedIncome,
          }}
          className={classes.dialogContent}
        >
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <MySelect
                name="description"
                label="Origem da renda"
                onChange={(event) => checkValue(event?.target.value)}
                options={incomeOrigins.map((incomeOrigin) => ({ label: incomeOrigin.name, value: incomeOrigin.name }))}
              />
            </Grid>
            <Grid item xs={6}>
              <MyTextInput
                type="number"
                name="ammount"
                placeholder="R$"
                label="Valor da renda"
                InputLabelProps={inputLabelProps}
                disabled={inputIncomeValueIsDisabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" style={{ color: "#707070" }} />
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleDialog} variant='contained' className={classes.buttonCancel}>
          Cancelar
        </Button>
        <Button variant="contained"
          className={classes.buttonRegister}
          onClick={() => formRef.current?.submitForm()} color="primary">
          {isLoadingRenda ? <CircularProgress size={25} style={{ color: '#a11908' }}/> : "Adicionar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withStyles(styles)(forwardRef(
  AddIncomeDialog
));
