
import { useState } from 'react';
import { Box, Collapse, createStyles, IconButton, TableCell, TableRow, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import styles from './styles';

type Props = {
  row: any,
  index: any,
  classes: any,
  expand?: boolean,
  componentExpand?: any,
  columns?: any,
}

const TableMainRow = (props: Props) => {
  const { row, index, classes, expand, componentExpand, columns } = props;

  const [open, setOpen] = useState<boolean>(false);

  const renderExpandRow = () => {
    if (expand) {
      return <TableCell className={classes.cell}>
        <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>

    }

    return null;
  }

  const renderComponentExpandRow = () => {
    if (expand && componentExpand) {
      return <TableRow>
        <TableCell className={classes.cellExpand} colSpan={6}>
          <Collapse className={classes.collapse} in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {componentExpand(index, row)}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    }

    return null;
  }

  return (
    <>
      <StyledTableRow hover role="checkbox" tabIndex={-1} key={index}>
        {columns.map((column: any) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} align={column.align} className={classes.cell}>
              {column?.render ? column?.render(row) : (column.format && typeof value === 'number' ? column.format(value) : value)}
            </TableCell>
          );
        })}
        {renderExpandRow()}
      </StyledTableRow>
      {renderComponentExpandRow()}
    </>
  );
}

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#FFFFFF',
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
)(TableRow);

export default withStyles(styles)(TableMainRow);