import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import styles from './styles';

interface PDFData {
  name: string;
  cpf: string;
  nib: string;
  district: string;
  telephone: string;
  bpc: string;
  total_income: string;
  members_length: number;
  income_capta: string;
  benefit_value: string;
}
interface PDFCol {
  label: string;
  field: 'name' | 'cpf' | 'nib' | 'district' | 'telephone' | 'bpc' | 'total_income' | 'members_length' | 'income_capta' | 'benefit_value';
}

interface Props {
  benefitiaries: PDFData[];
  totalValue: number;
  generatedDate: string;
  benefit: string;
}

const PdfBenefitiary: React.FC<Props> = (props) => {
  const { benefitiaries, totalValue, generatedDate, benefit } = props;

  const cols: PDFCol[] = [
    {
      label: 'Nome Completo',
      field: 'name',
    },
    {
      label: 'CPF',
      field: 'cpf',
    },
    {
      label: 'NIB',
      field: 'nib',
    },
    {
      label: 'Bairro',
      field: 'district',
    },
    {
      label: 'Telefone',
      field: 'telephone',
    },
    {
      label: 'BPC',
      field: 'bpc',
    },
    {
      label: 'Renda Bruta',
      field: 'total_income',
    },
    {
      label: 'Membros na Família',
      field: 'members_length',
    },
    {
      label: 'Renda per Capta',
      field: 'income_capta',
    },
    {
      label: 'Valor do benefício',
      field: 'benefit_value',
    },
  ];

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.pdfMain}>
        <View style={styles.documentTitle}>
          <Text>Relatório dos beneficiários do {(benefit.toUpperCase().replace(/_/g, " "))}</Text>
          <Text style={styles.generatedDateText}>Gerado em {generatedDate}</Text>
        </View>
        <View style={styles.mainGridContainer}>
          <View style={styles.mainGridHeader}>
            {
              cols.map((col) => (
                <Text style={styles.mainGridCell}>{col.label}</Text>
              ))
            }
          </View>
          <View style={styles.mainGridBody}>
            {
              cols.map((col) => {
                return benefitiaries.map((data) => (
                  <Text style={styles.mainGridCell}>{data[col.field]}</Text>
                ))
              })
            }
          </View>
        </View>
        <View style={styles.resumeContainer}>
          <Text>Total de Beneficiários: {benefitiaries.length}</Text>
          <Text>Valor total: {
            new Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
              minimumFractionDigits: 2,
            }).format(totalValue || 0)
          }</Text>
        </View>
      </Page>
    </Document>
  );
}
export default PdfBenefitiary;