const styles: any = {
  cell: {
    padding: '13px 16px',
    fontSize: '0.775rem',
  },
  cellExpand: {
    paddingBottom: 0,
    paddingTop: 0
  },
  collapse: {
    width: '100%'
  }
};

export default styles;