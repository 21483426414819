import { useState } from 'react';
import { withStyles, Tabs, Tab, Button, Menu, MenuItem } from '@material-ui/core';
import ReactPDF from "@react-pdf/renderer";
import { JSXElementConstructor, ReactElement } from "react";
import { ExcelExport, ExcelExportColumn, ExcelExportColumnProps } from '@progress/kendo-react-excel-export';

import useDownloadPdf from '../../../../../hooks/useDownloadPdf';

import styles from './styles';

type Props = {
  classes: any,
  onFilter?: Function,
  onChange: Function,
  documentPdf?: ReactElement<ReactPDF.DocumentProps, string | JSXElementConstructor<any>>,
  filenamePdf?: string,
  filenameExcel?: string,
  columnsExcel?: Array<ExcelExportColumnProps>,
  dataExcel?: any
};

let exporterRef: ExcelExport | null = null;

function Header(props: Props) {
  const { classes, onChange, documentPdf, filenamePdf, filenameExcel, columnsExcel, dataExcel } = props;

  const { download } = useDownloadPdf();

  const [value, setValue] = useState<any>(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChange = (event: any, newValue: any) => {
    setValue(newValue);
    onChange(newValue);
  };

  const onGetPdf = () => {
    if (documentPdf && filenamePdf) {
      download(documentPdf, filenamePdf);
      handleClose();
    }
  }

  const onDownloadExcel = () => {
    exporterRef?.save();
  }

  const handleClick = (event: any) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = () => {
    return <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
    >
      {(documentPdf && filenamePdf) ? <MenuItem disabled={!documentPdf || !filenamePdf} onClick={onGetPdf}>Gerar PDF</MenuItem> : null}
      {(filenameExcel && columnsExcel && dataExcel) ? <MenuItem disabled={!filenameExcel || !columnsExcel || !dataExcel} onClick={onDownloadExcel}>Gerar Excel</MenuItem> : null}
    </Menu>
  }

  const renderExcelExportColumns = () => {
    return columnsExcel?.map((column) => {
      return <ExcelExportColumn
        field={column.field}
        title={column.title}
      />
    })
  }

  const renderExcelExport = () => {
    return <ExcelExport
      data={dataExcel}
      fileName={filenameExcel}
      ref={(exporter) => {
        exporterRef = exporter;
      }}
    >
      {renderExcelExportColumns()}
    </ExcelExport>
  }

  const renderButton = () => {
    if ((documentPdf && filenamePdf) || (filenameExcel && columnsExcel && dataExcel)) {
      return <Button className={classes.btnBlue} variant="outlined" onClick={(event) => handleClick(event)} >
        EXPORTAR
      </Button>
    }

    return null;
  }

  return (
    <div className={classes.flexRow}>
      <span className={classes.title}>Relatórios</span>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        className={classes.tabs}
      >
        {/* <Tab className={classes.tab} label="Produtividade de cadastros" />
                <Tab className={classes.tab} label="Auxílio Emergencial" /> */}
        <Tab className={classes.tab} label="Domicílio" />
        <Tab className={classes.tab} label="Indivíduo" />
        <Tab className={classes.tab} label="Beneficiários" />
        <Tab className={classes.tab} label="Composição familiar" />
      </Tabs>
      {renderButton()}
      {renderMenu()}
      {renderExcelExport()}
    </div>
  );
}

export default withStyles(styles)(Header);