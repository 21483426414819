import React, {
  useRef,
  useState,
  useContext,
} from 'react';
import {
  Fab,
  Box,
  Grid,
  ButtonBase,
  IconButton,
  withStyles,
  InputAdornment,
  CircularProgress,
  Typography,
  Tooltip,
  Button,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';

import Home from '../../../../model/entities/Home';
import { AuthContext } from '../../../../providers/Auth';
import { messages } from '../../../../constants/messages';
import homeService from '../../../../services/HomeService';
import streetService from '../../../../services/StreetService';
import withSnackbar from '../../../templates/WithSnackbar';
import { HomeEntity } from '../../../../interfaces/entities/home.entity';
import { GroupedHomes } from '../../../../interfaces/responses/search-home.response';

import HomeCard from './components/HomeCard';
import MyTextInput from '../../../MyTextInput';

import styles from './styles';

type Props = {
  classes: any;
  login: Function;
  openSnackbar: any;
};

interface FormData {
  term: string;
}

const Register: React.FC<Props> = (props) => {
  const { classes, openSnackbar } = props;
  const history = useHistory();
  const { accessToken } = useContext(AuthContext);
  const [selectedLogradouro, setSelectedLogradouro] = useState<number>();
  const [loadingStreet, setLoadingStreet] = useState<boolean>(false);
  const [groupedHomes, setGroupedHomes] = useState<GroupedHomes[]>([]);
  const [domicilios, setDomicilios] = useState<number>(0);
  const [requestTimes, setRequestTimes] = useState(0);
  const formSearchRef = useRef<FormHandles>(null);
  const gridRef = useRef<HTMLDivElement>(null);

  async function onSelectLogradouro(
    district: string,
    districtIndex: number,
    streetTypeId: number,
    streetTypeIndex: number,
    streetName: string,
    streetIndex: number
  ) {
    const elementId = [districtIndex, streetTypeIndex, streetIndex].join('.');

    const card = document.getElementById(elementId);

    if (card) {
      gridRef.current?.scrollTo({
        behavior: 'smooth',
        top: card?.offsetTop,
      });
    }

    const street = await streetService.getByAddress(accessToken, streetTypeId, streetName, district);

    setSelectedLogradouro(street.id);
  }

  const searchHomes = async (data: FormData) => {
    try {
      formSearchRef.current?.setErrors({});
      setLoadingStreet(true);
      const shema = Yup.object().shape({
        term: Yup.string().required(messages.emptyField).min(5, 'Este campo necessita de pelo menos 5 dígitos'),
      });

      await shema.validate(data, { abortEarly: false });

      const response = await homeService.searchHome(data.term, data.term, accessToken, data.term);

      setRequestTimes((prevState) => prevState + 1);
      setGroupedHomes(response.results);
      let count = 0;
      response.results.forEach((groupedHome) => {
        groupedHome.street_types.forEach((streetTypes) => {
          streetTypes.streets.forEach((street) => {
            count += street.homes.length;
          });
        });
      });
      setDomicilios(count);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        formSearchRef.current?.setErrors(errorMessages);
      } else {
        handleError(error?.message);
      }
    } finally {
      setLoadingStreet(false);
    }
  };

  function handleError(e: any) {
    openSnackbar('error', e);
  };

  function onShowDetail(house: Home) {
    history.push(`/app/imoveis/${house.id}/visualizar`);
  };

  function handleCreateLogradouro() {
    history.push('/app/logradouro/cadastrar');
  };

  async function handleEditLogradouro() {
    if (selectedLogradouro) {
      history.push(`/app/logradouro/${selectedLogradouro}/editar`);
    } else {
      openSnackbar('Não foi possível permitir a edição do logradouro.');
    }
  };

  function handleCreateHouse() {
    if (selectedLogradouro) {
      history.push(`/app/imoveis/cadastrar?logradouroId=${selectedLogradouro}`);
    } else {
      history.push('/app/imoveis/cadastrar');
    }
  };
  const renderHouses = () => {
    if (loadingStreet) {
      return (
        <div style={{ margin: '20px auto' }}>
          <CircularProgress style={{ color: '#a11908' }}/>
        </div>
      );
    }
    if (requestTimes && !groupedHomes.length) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <img alt="Sem resultados" src="/assets/no_results.png" className={classes.image} />
          <Typography variant="subtitle1" color="textSecondary">
            Nenhum domicílio encontrado com o termo informado
          </Typography>
        </Box>
      );
    }
    if (groupedHomes.length) {
      return (
        <Grid item xs={12}>
          <Grid container spacing={4} className={classes.housesList}>
            {
              groupedHomes.map((groupedHome, districtIndex) => {
                return groupedHome.street_types.map((streetTypes, streetTypeIndex) => {
                  return streetTypes.streets.map((street, streetIndex) => {
                    return street.homes.map((home, homeIndex) => (
                      <HomeCard
                        isConsult
                        indentifier={homeIndex === 0 ?
                          [districtIndex, streetTypeIndex, streetIndex].join('.')
                          : undefined}
                        key={home.id}
                        home={home as HomeEntity}
                        onShowDetail={onShowDetail}
                      />
                    ));
                  });
                });
              })
            }
          </Grid>
        </Grid>
      );
    }
  };

  function renderGroupedStreets() {
    if (requestTimes && !groupedHomes.length) {
      return (
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography variant="subtitle1" color="textSecondary">
            Nenhum resultado encontrado
          </Typography>
        </Box>
      );
    }
    if (groupedHomes.length) {
      return groupedHomes.map((groupedHome, districtIndex) => {
        return groupedHome.street_types.map((streetTypes, streetTypeIndex) => {
          return streetTypes.streets.map((street, streetIndex) => {
            return (
              <ButtonBase
                onClick={() => onSelectLogradouro(
                  groupedHome.district,
                  districtIndex,
                  streetTypes.street_type_id,
                  streetTypeIndex,
                  street.street_name,
                  streetIndex,
                )}
                className={classes.logrItem}
              >
                <Tooltip title={`Selecionar ${street.homes[0].street_type?.description} ${street.street_name}`}>
                  <Box className={classes.logrItemText}>
                    <span className={classes.logrItemNameBold}>
                      {street.homes[0].street_type?.description} {street.street_name}
                    </span>
                    <span className={classes.logrItemName}>{groupedHome?.district}</span>
                  </Box>
                </Tooltip>
              </ButtonBase>
            );
          });
        });
      });
    }
  }

  return (
    <Grid container className={classes.containerForm}>
      <Grid item xs={4} className={classes.divListLogr}>
        <span className={classes.spanTitle}>Perquisar domicílios</span>
        <div className={classes.divisor}></div>
        <div className={classes.logradouroAction}>
          <Button variant="contained" color="primary" style={{ backgroundColor: '#a11908' }} onClick={handleCreateLogradouro}>
            Cadastrar novo logradouro
          </Button>
          {selectedLogradouro ? (
            <Button variant="outlined" onClick={handleEditLogradouro}>
              Editar logradouro selecionado
            </Button>
          ) : null}
        </div>
        <div className={classes.divisor}></div>
        <Form
          ref={formSearchRef}
          onSubmit={searchHomes}
          style={{ marginTop: 15, display: 'flex', alignItems: 'center', alignSelf: 'stretch', padding: '0 20px' }}
        >
          <MyTextInput
            name="term"
            label="Pesquisar"
            placeholder="Pesquise por bairo, logradouro ou morador"
            InputLabelProps={{
              style: { color: '#707070', fontWeight: 'bold' },
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start" style={{ color: '#707070' }} />,
              style: { fontSize: 14 },
            }}
          />
          <IconButton type="submit" aria-label="pesquisar">
            <SearchIcon style={{ color: '#707070', transform: 'scale(-1, 1)' }} />
          </IconButton>
        </Form>
        <div className={classes.listOfLogr}>
          {loadingStreet ? (
            <div style={{ margin: '20px auto', textAlign: 'center' }}>
              <CircularProgress style={{ color: '#a11908' }}/>
            </div>
          ) : (
            <Grid container direction="column">
              <Grid xs={12}>
                <Box padding={1}>
                  {renderGroupedStreets()}
                </Box>
              </Grid>
            </Grid>
          )}
        </div>
      </Grid>
      <Grid ref={gridRef} item xs={8} className={classes.divListHouses}>
        <div className={classes.flexRow}>
          <Grid item xs={6}>
            <div className={classes.flexColumn}>
              <span className={classes.titleListHousesBold}>
                {
                  domicilios ? `${domicilios} domicílios encontrados` : null
                }
              </span>
            </div>
          </Grid>
        </div>

        {renderHouses()}

        <Fab color="primary" aria-label="add" className={classes.fab} onClick={handleCreateHouse}>
          <AddIcon />
        </Fab>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(withSnackbar(Register));
