import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';

import JuneBasketSplash from '../../components/pages/public/JuneBasketSplash';
import JuneBasketManager from '../../components/pages/public/JuneBasketManager';


const CestaJuninaRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/splash`}>
        <JuneBasketSplash />
      </Route>
      <Route path={`${path}/`}>
        <JuneBasketManager />
      </Route>
      <Redirect to={`${path}/splash`} />
    </Switch>
  );
}

export default CestaJuninaRoutes;
