type KinshipResponsibleFamily = { id: number; name: string }

const kinshipsResponsibleFamily: Array<KinshipResponsibleFamily> = [
  { id: 1, name: "Cônjuge/Companheiro(a)" },
  { id: 2, name: "Filho(a)/Enteado(a)" },
  { id: 3, name: "Neto(a)/Bisneto(a)" },
  { id: 4, name: "Pai/Mãe" },
  { id: 5, name: "Sogro(a)" },
  { id: 6, name: "Irmão/Irmã" },
  { id: 7, name: "Genro/Nora" },
  { id: 8, name: "Outro parente" },
  { id: 9, name: "Não parente" },
];

export default kinshipsResponsibleFamily;