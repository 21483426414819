type County = { name: string, initials: string, code: string }

const counties: Array<County> = [
  {
    name: 'ABADIA DE GOIÁS',
    initials: 'GO',
    code: '5200050',
  },
  {
    name: 'ABADIA DOS DOURADOS',
    initials: 'MG',
    code: '3100104',
  },
  {
    name: 'ABADIÂNIA',
    initials: 'GO',
    code: '5200100',
  },
  {
    name: 'ABAETÉ',
    initials: 'MG',
    code: '3100203',
  },
  {
    name: 'ABAETETUBA',
    initials: 'PA',
    code: '1500107',
  },
  {
    name: 'ABAIARA',
    initials: 'CE',
    code: '2300101',
  },
  {
    name: 'ABAÍRA',
    initials: 'BA',
    code: '2900108',
  },
  {
    name: 'ABARÉ',
    initials: 'BA',
    code: '2900207',
  },
  {
    name: 'ABATIÁ',
    initials: 'PR',
    code: '4100103',
  },
  {
    name: 'ABDON BATISTA',
    initials: 'SC',
    code: '4200051',
  },
  {
    name: 'ABELARDO LUZ',
    initials: 'SC',
    code: '4200101',
  },
  {
    name: 'ABEL FIGUEIREDO',
    initials: 'PA',
    code: '1500131',
  },
  {
    name: 'ABRE CAMPO',
    initials: 'MG',
    code: '3100302',
  },
  {
    name: 'ABREU E LIMA',
    initials: 'PE',
    code: '2600054',
  },
  {
    name: 'ABREULÂNDIA',
    initials: 'TO',
    code: '1700251',
  },
  {
    name: 'ACAIACA',
    initials: 'MG',
    code: '3100401',
  },
  {
    name: 'AÇAILÂNDIA',
    initials: 'MA',
    code: '2100055',
  },
  {
    name: 'ACAJUTIBA',
    initials: 'BA',
    code: '2900306',
  },
  {
    name: 'ACARÁ',
    initials: 'PA',
    code: '1500206',
  },
  {
    name: 'ACARAPE',
    initials: 'CE',
    code: '2300150',
  },
  {
    name: 'ACARAÚ',
    initials: 'CE',
    code: '2300200',
  },
  {
    name: 'ACARI',
    initials: 'RN',
    code: '2400109',
  },
  {
    name: 'ACAUÃ',
    initials: 'PI',
    code: '2200053',
  },
  {
    name: 'ACEGUÁ',
    initials: 'RS',
    code: '4300034',
  },
  {
    name: 'ACOPIARA',
    initials: 'CE',
    code: '2300309',
  },
  {
    name: 'ACORIZAL',
    initials: 'MT',
    code: '5100102',
  },
  {
    name: 'ACRELÂNDIA',
    initials: 'AC',
    code: '1200013',
  },
  {
    name: 'ACREÚNA',
    initials: 'GO',
    code: '5200134',
  },
  {
    name: 'AÇU',
    initials: 'RN',
    code: '2400208',
  },
  {
    name: 'AÇUCENA',
    initials: 'MG',
    code: '3100500',
  },
  {
    name: 'ADAMANTINA',
    initials: 'SP',
    code: '3500105',
  },
  {
    name: 'ADELÂNDIA',
    initials: 'GO',
    code: '5200159',
  },
  {
    name: 'ADOLFO',
    initials: 'SP',
    code: '3500204',
  },
  {
    name: 'ADRIANÓPOLIS',
    initials: 'PR',
    code: '4100202',
  },
  {
    name: 'ADUSTINA',
    initials: 'BA',
    code: '2900355',
  },
  {
    name: 'AFOGADOS DA INGAZEIRA',
    initials: 'PE',
    code: '2600104',
  },
  {
    name: 'AFONSO BEZERRA',
    initials: 'RN',
    code: '2400307',
  },
  {
    name: 'AFONSO CLÁUDIO',
    initials: 'ES',
    code: '3200102',
  },
  {
    name: 'AFONSO CUNHA',
    initials: 'MA',
    code: '2100105',
  },
  {
    name: 'AFRÂNIO',
    initials: 'PE',
    code: '2600203',
  },
  {
    name: 'AFUÁ',
    initials: 'PA',
    code: '1500305',
  },
  {
    name: 'AGRESTINA',
    initials: 'PE',
    code: '2600302',
  },
  {
    name: 'AGRICOLÂNDIA',
    initials: 'PI',
    code: '2200103',
  },
  {
    name: 'AGROLÂNDIA',
    initials: 'SC',
    code: '4200200',
  },
  {
    name: 'AGRONÔMICA',
    initials: 'SC',
    code: '4200309',
  },
  {
    name: 'ÁGUA AZUL DO NORTE',
    initials: 'PA',
    code: '1500347',
  },
  {
    name: 'ÁGUA BOA',
    initials: 'MT',
    code: '5100201',
  },
  {
    name: 'ÁGUA BOA',
    initials: 'MG',
    code: '3100609',
  },
  {
    name: 'ÁGUA BRANCA',
    initials: 'PI',
    code: '2200202',
  },
  {
    name: 'ÁGUA BRANCA',
    initials: 'AL',
    code: '2700102',
  },
  {
    name: 'ÁGUA BRANCA',
    initials: 'PB',
    code: '2500106',
  },
  {
    name: 'ÁGUA CLARA',
    initials: 'MS',
    code: '5000203',
  },
  {
    name: 'ÁGUA COMPRIDA',
    initials: 'MG',
    code: '3100708',
  },
  {
    name: 'ÁGUA DOCE',
    initials: 'SC',
    code: '4200408',
  },
  {
    name: 'ÁGUA DOCE DO MARANHÃO',
    initials: 'MA',
    code: '2100154',
  },
  {
    name: 'ÁGUA DOCE DO NORTE',
    initials: 'ES',
    code: '3200169',
  },
  {
    name: 'ÁGUA FRIA',
    initials: 'BA',
    code: '2900405',
  },
  {
    name: 'ÁGUA FRIA DE GOIÁS',
    initials: 'GO',
    code: '5200175',
  },
  {
    name: 'AGUAÍ',
    initials: 'SP',
    code: '3500303',
  },
  {
    name: 'ÁGUA LIMPA',
    initials: 'GO',
    code: '5200209',
  },
  {
    name: 'AGUANIL',
    initials: 'MG',
    code: '3100807',
  },
  {
    name: 'ÁGUA NOVA',
    initials: 'RN',
    code: '2400406',
  },
  {
    name: 'ÁGUA PRETA',
    initials: 'PE',
    code: '2600401',
  },
  {
    name: 'ÁGUA SANTA',
    initials: 'RS',
    code: '4300059',
  },
  {
    name: 'ÁGUAS BELAS',
    initials: 'PE',
    code: '2600500',
  },
  {
    name: 'ÁGUAS DA PRATA',
    initials: 'SP',
    code: '3500402',
  },
  {
    name: 'ÁGUAS DE CHAPECÓ',
    initials: 'SC',
    code: '4200507',
  },
  {
    name: 'ÁGUAS DE LINDÓIA',
    initials: 'SP',
    code: '3500501',
  },
  {
    name: 'ÁGUAS DE SANTA BÁRBARA',
    initials: 'SP',
    code: '3500550',
  },
  {
    name: 'ÁGUAS DE SÃO PEDRO',
    initials: 'SP',
    code: '3500600',
  },
  {
    name: 'ÁGUAS FORMOSAS',
    initials: 'MG',
    code: '3100906',
  },
  {
    name: 'ÁGUAS FRIAS',
    initials: 'SC',
    code: '4200556',
  },
  {
    name: 'ÁGUAS LINDAS DE GOIÁS',
    initials: 'GO',
    code: '5200258',
  },
  {
    name: 'ÁGUAS MORNAS',
    initials: 'SC',
    code: '4200606',
  },
  {
    name: 'ÁGUAS VERMELHAS',
    initials: 'MG',
    code: '3101003',
  },
  {
    name: 'AGUDO',
    initials: 'RS',
    code: '4300109',
  },
  {
    name: 'AGUDOS',
    initials: 'SP',
    code: '3500709',
  },
  {
    name: 'AGUDOS DO SUL',
    initials: 'PR',
    code: '4100301',
  },
  {
    name: 'ÁGUIA BRANCA',
    initials: 'ES',
    code: '3200136',
  },
  {
    name: 'AGUIAR',
    initials: 'PB',
    code: '2500205',
  },
  {
    name: 'AGUIARNÓPOLIS',
    initials: 'TO',
    code: '1700301',
  },
  {
    name: 'AIMORÉS',
    initials: 'MG',
    code: '3101102',
  },
  {
    name: 'AIQUARA',
    initials: 'BA',
    code: '2900603',
  },
  {
    name: 'AIUABA',
    initials: 'CE',
    code: '2300408',
  },
  {
    name: 'AIURUOCA',
    initials: 'MG',
    code: '3101201',
  },
  {
    name: 'AJURICABA',
    initials: 'RS',
    code: '4300208',
  },
  {
    name: 'ALAGOA',
    initials: 'MG',
    code: '3101300',
  },
  {
    name: 'ALAGOA GRANDE',
    initials: 'PB',
    code: '2500304',
  },
  {
    name: 'ALAGOA NOVA',
    initials: 'PB',
    code: '2500403',
  },
  {
    name: 'ALAGOINHA',
    initials: 'PB',
    code: '2500502',
  },
  {
    name: 'ALAGOINHA',
    initials: 'PE',
    code: '2600609',
  },
  {
    name: 'ALAGOINHA DO PIAUÍ',
    initials: 'PI',
    code: '2200251',
  },
  {
    name: 'ALAGOINHAS',
    initials: 'BA',
    code: '2900702',
  },
  {
    name: 'ALAMBARI',
    initials: 'SP',
    code: '3500758',
  },
  {
    name: 'ALBERTINA',
    initials: 'MG',
    code: '3101409',
  },
  {
    name: 'ALCÂNTARA',
    initials: 'MA',
    code: '2100204',
  },
  {
    name: 'ALCÂNTARAS',
    initials: 'CE',
    code: '2300507',
  },
  {
    name: 'ALCANTIL',
    initials: 'PB',
    code: '2500536',
  },
  {
    name: 'ALCINÓPOLIS',
    initials: 'MS',
    code: '5000252',
  },
  {
    name: 'ALCOBAÇA',
    initials: 'BA',
    code: '2900801',
  },
  {
    name: 'ALDEIAS ALTAS',
    initials: 'MA',
    code: '2100303',
  },
  {
    name: 'ALECRIM',
    initials: 'RS',
    code: '4300307',
  },
  {
    name: 'ALEGRE',
    initials: 'ES',
    code: '3200201',
  },
  {
    name: 'ALEGRETE',
    initials: 'RS',
    code: '4300406',
  },
  {
    name: 'ALEGRETE DO PIAUÍ',
    initials: 'PI',
    code: '2200277',
  },
  {
    name: 'ALEGRIA',
    initials: 'RS',
    code: '4300455',
  },
  {
    name: 'ALÉM PARAÍBA',
    initials: 'MG',
    code: '3101508',
  },
  {
    name: 'ALENQUER',
    initials: 'PA',
    code: '1500404',
  },
  {
    name: 'ALEXANDRIA',
    initials: 'RN',
    code: '2400505',
  },
  {
    name: 'ALEXÂNIA',
    initials: 'GO',
    code: '5200308',
  },
  {
    name: 'ALFENAS',
    initials: 'MG',
    code: '3101607',
  },
  {
    name: 'ALFREDO CHAVES',
    initials: 'ES',
    code: '3200300',
  },
  {
    name: 'ALFREDO MARCONDES',
    initials: 'SP',
    code: '3500808',
  },
  {
    name: 'ALFREDO VASCONCELOS',
    initials: 'MG',
    code: '3101631',
  },
  {
    name: 'ALFREDO WAGNER',
    initials: 'SC',
    code: '4200705',
  },
  {
    name: 'ALGODÃO DE JANDAÍRA',
    initials: 'PB',
    code: '2500577',
  },
  {
    name: 'ALHANDRA',
    initials: 'PB',
    code: '2500601',
  },
  {
    name: 'ALIANÇA',
    initials: 'PE',
    code: '2600708',
  },
  {
    name: 'ALIANÇA DO TOCANTINS',
    initials: 'TO',
    code: '1700350',
  },
  {
    name: 'ALMADINA',
    initials: 'BA',
    code: '2900900',
  },
  {
    name: 'ALMAS',
    initials: 'TO',
    code: '1700400',
  },
  {
    name: 'ALMEIRIM',
    initials: 'PA',
    code: '1500503',
  },
  {
    name: 'ALMENARA',
    initials: 'MG',
    code: '3101706',
  },
  {
    name: 'ALMINO AFONSO',
    initials: 'RN',
    code: '2400604',
  },
  {
    name: 'ALMIRANTE TAMANDARÉ',
    initials: 'PR',
    code: '4100400',
  },
  {
    name: 'ALMIRANTE TAMANDARÉ DO SUL',
    initials: 'RS',
    code: '4300471',
  },
  {
    name: 'ALOÂNDIA',
    initials: 'GO',
    code: '5200506',
  },
  {
    name: 'ALPERCATA',
    initials: 'MG',
    code: '3101805',
  },
  {
    name: 'ALPESTRE',
    initials: 'RS',
    code: '4300505',
  },
  {
    name: 'ALPINÓPOLIS',
    initials: 'MG',
    code: '3101904',
  },
  {
    name: 'ALTA FLORESTA',
    initials: 'MT',
    code: '5100250',
  },
  {
    name: 'ALTA FLORESTA D\'OESTE',
    initials: 'RO',
    code: '1100015',
  },
  {
    name: 'ALTAIR',
    initials: 'SP',
    code: '3500907',
  },
  {
    name: 'ALTAMIRA',
    initials: 'PA',
    code: '1500602',
  },
  {
    name: 'ALTAMIRA DO MARANHÃO',
    initials: 'MA',
    code: '2100402',
  },
  {
    name: 'ALTAMIRA DO PARANÁ',
    initials: 'PR',
    code: '4100459',
  },
  {
    name: 'ALTANEIRA',
    initials: 'CE',
    code: '2300606',
  },
  {
    name: 'ALTEROSA',
    initials: 'MG',
    code: '3102001',
  },
  {
    name: 'ALTINHO',
    initials: 'PE',
    code: '2600807',
  },
  {
    name: 'ALTINÓPOLIS',
    initials: 'SP',
    code: '3501004',
  },
  {
    name: 'ALTO ALEGRE',
    initials: 'SP',
    code: '3501103',
  },
  {
    name: 'ALTO ALEGRE',
    initials: 'RS',
    code: '4300554',
  },
  {
    name: 'ALTO ALEGRE',
    initials: 'RR',
    code: '1400050',
  },
  {
    name: 'ALTO ALEGRE DO MARANHÃO',
    initials: 'MA',
    code: '2100436',
  },
  {
    name: 'ALTO ALEGRE DO PINDARÉ',
    initials: 'MA',
    code: '2100477',
  },
  {
    name: 'ALTO ALEGRE DOS PARECIS',
    initials: 'RO',
    code: '1100379',
  },
  {
    name: 'ALTO ARAGUAIA',
    initials: 'MT',
    code: '5100300',
  },
  {
    name: 'ALTO BELA VISTA',
    initials: 'SC',
    code: '4200754',
  },
  {
    name: 'ALTO BOA VISTA',
    initials: 'MT',
    code: '5100359',
  },
  {
    name: 'ALTO CAPARAÓ',
    initials: 'MG',
    code: '3102050',
  },
  {
    name: 'ALTO DO RODRIGUES',
    initials: 'RN',
    code: '2400703',
  },
  {
    name: 'ALTO FELIZ',
    initials: 'RS',
    code: '4300570',
  },
  {
    name: 'ALTO GARÇAS',
    initials: 'MT',
    code: '5100409',
  },
  {
    name: 'ALTO HORIZONTE',
    initials: 'GO',
    code: '5200555',
  },
  {
    name: 'ALTO JEQUITIBÁ',
    initials: 'MG',
    code: '3153509',
  },
  {
    name: 'ALTO LONGÁ',
    initials: 'PI',
    code: '2200301',
  },
  {
    name: 'ALTÔNIA',
    initials: 'PR',
    code: '4100509',
  },
  {
    name: 'ALTO PARAGUAI',
    initials: 'MT',
    code: '5100508',
  },
  {
    name: 'ALTO PARAÍSO',
    initials: 'RO',
    code: '1100403',
  },
  {
    name: 'ALTO PARAÍSO',
    initials: 'PR',
    code: '4128625',
  },
  {
    name: 'ALTO PARAÍSO DE GOIÁS',
    initials: 'GO',
    code: '5200605',
  },
  {
    name: 'ALTO PARANÁ',
    initials: 'PR',
    code: '4100608',
  },
  {
    name: 'ALTO PARNAÍBA',
    initials: 'MA',
    code: '2100501',
  },
  {
    name: 'ALTO PIQUIRI',
    initials: 'PR',
    code: '4100707',
  },
  {
    name: 'ALTO RIO DOCE',
    initials: 'MG',
    code: '3102100',
  },
  {
    name: 'ALTO RIO NOVO',
    initials: 'ES',
    code: '3200359',
  },
  {
    name: 'ALTOS',
    initials: 'PI',
    code: '2200400',
  },
  {
    name: 'ALTO SANTO',
    initials: 'CE',
    code: '2300705',
  },
  {
    name: 'ALTO TAQUARI',
    initials: 'MT',
    code: '5100607',
  },
  {
    name: 'ALUMÍNIO',
    initials: 'SP',
    code: '3501152',
  },
  {
    name: 'ALVARÃES',
    initials: 'AM',
    code: '1300029',
  },
  {
    name: 'ALVARENGA',
    initials: 'MG',
    code: '3102209',
  },
  {
    name: 'ÁLVARES FLORENCE',
    initials: 'SP',
    code: '3501202',
  },
  {
    name: 'ÁLVARES MACHADO',
    initials: 'SP',
    code: '3501301',
  },
  {
    name: 'ÁLVARO DE CARVALHO',
    initials: 'SP',
    code: '3501400',
  },
  {
    name: 'ALVINLÂNDIA',
    initials: 'SP',
    code: '3501509',
  },
  {
    name: 'ALVINÓPOLIS',
    initials: 'MG',
    code: '3102308',
  },
  {
    name: 'ALVORADA',
    initials: 'TO',
    code: '1700707',
  },
  {
    name: 'ALVORADA',
    initials: 'RS',
    code: '4300604',
  },
  {
    name: 'ALVORADA DE MINAS',
    initials: 'MG',
    code: '3102407',
  },
  {
    name: 'ALVORADA D\'OESTE',
    initials: 'RO',
    code: '1100346',
  },
  {
    name: 'ALVORADA DO GURGUÉIA',
    initials: 'PI',
    code: '2200459',
  },
  {
    name: 'ALVORADA DO NORTE',
    initials: 'GO',
    code: '5200803',
  },
  {
    name: 'ALVORADA DO SUL',
    initials: 'PR',
    code: '4100806',
  },
  {
    name: 'AMAJARI',
    initials: 'RR',
    code: '1400027',
  },
  {
    name: 'AMAMBAÍ',
    initials: 'MS',
    code: '5000609',
  },
  {
    name: 'AMAPÁ',
    initials: 'AP',
    code: '1600105',
  },
  {
    name: 'AMAPÁ DO MARANHÃO',
    initials: 'MA',
    code: '2100550',
  },
  {
    name: 'AMAPORÃ',
    initials: 'PR',
    code: '4100905',
  },
  {
    name: 'AMARAJI',
    initials: 'PE',
    code: '2600906',
  },
  {
    name: 'AMARAL FERRADOR',
    initials: 'RS',
    code: '4300638',
  },
  {
    name: 'AMARALINA',
    initials: 'GO',
    code: '5200829',
  },
  {
    name: 'AMARANTE',
    initials: 'PI',
    code: '2200509',
  },
  {
    name: 'AMARANTE DO MARANHÃO',
    initials: 'MA',
    code: '2100600',
  },
  {
    name: 'AMARGOSA',
    initials: 'BA',
    code: '2901007',
  },
  {
    name: 'AMATURÁ',
    initials: 'AM',
    code: '1300060',
  },
  {
    name: 'AMÉLIA RODRIGUES',
    initials: 'BA',
    code: '2901106',
  },
  {
    name: 'AMÉRICA DOURADA',
    initials: 'BA',
    code: '2901155',
  },
  {
    name: 'AMERICANA',
    initials: 'SP',
    code: '3501608',
  },
  {
    name: 'AMERICANO DO BRASIL',
    initials: 'GO',
    code: '5200852',
  },
  {
    name: 'AMÉRICO BRASILIENSE',
    initials: 'SP',
    code: '3501707',
  },
  {
    name: 'AMÉRICO DE CAMPOS',
    initials: 'SP',
    code: '3501806',
  },
  {
    name: 'AMETISTA DO SUL',
    initials: 'RS',
    code: '4300646',
  },
  {
    name: 'AMONTADA',
    initials: 'CE',
    code: '2300754',
  },
  {
    name: 'AMORINÓPOLIS',
    initials: 'GO',
    code: '5200902',
  },
  {
    name: 'AMPARO',
    initials: 'SP',
    code: '3501905',
  },
  {
    name: 'AMPARO',
    initials: 'PB',
    code: '2500734',
  },
  {
    name: 'AMPARO DA SERRA',
    initials: 'MG',
    code: '3102506',
  },
  {
    name: 'AMPARO DE SÃO FRANCISCO',
    initials: 'SE',
    code: '2800100',
  },
  {
    name: 'AMPÉRE',
    initials: 'PR',
    code: '4101002',
  },
  {
    name: 'ANADIA',
    initials: 'AL',
    code: '2700201',
  },
  {
    name: 'ANAGÉ',
    initials: 'BA',
    code: '2901205',
  },
  {
    name: 'ANAHY',
    initials: 'PR',
    code: '4101051',
  },
  {
    name: 'ANAJÁS',
    initials: 'PA',
    code: '1500701',
  },
  {
    name: 'ANAJATUBA',
    initials: 'MA',
    code: '2100709',
  },
  {
    name: 'ANALÂNDIA',
    initials: 'SP',
    code: '3502002',
  },
  {
    name: 'ANAMÃ',
    initials: 'AM',
    code: '1300086',
  },
  {
    name: 'ANANÁS',
    initials: 'TO',
    code: '1701002',
  },
  {
    name: 'ANANINDEUA',
    initials: 'PA',
    code: '1500800',
  },
  {
    name: 'ANÁPOLIS',
    initials: 'GO',
    code: '5201108',
  },
  {
    name: 'ANAPU',
    initials: 'PA',
    code: '1500859',
  },
  {
    name: 'ANAPURUS',
    initials: 'MA',
    code: '2100808',
  },
  {
    name: 'ANASTÁCIO',
    initials: 'MS',
    code: '5000708',
  },
  {
    name: 'ANAURILÂNDIA',
    initials: 'MS',
    code: '5000807',
  },
  {
    name: 'ANCHIETA',
    initials: 'ES',
    code: '3200409',
  },
  {
    name: 'ANCHIETA',
    initials: 'SC',
    code: '4200804',
  },
  {
    name: 'ANDARAÍ',
    initials: 'BA',
    code: '2901304',
  },
  {
    name: 'ANDIRÁ',
    initials: 'PR',
    code: '4101101',
  },
  {
    name: 'ANDORINHA',
    initials: 'BA',
    code: '2901353',
  },
  {
    name: 'ANDRADAS',
    initials: 'MG',
    code: '3102605',
  },
  {
    name: 'ANDRADINA',
    initials: 'SP',
    code: '3502101',
  },
  {
    name: 'ANDRÉ DA ROCHA',
    initials: 'RS',
    code: '4300661',
  },
  {
    name: 'ANDRELÂNDIA',
    initials: 'MG',
    code: '3102803',
  },
  {
    name: 'ANGATUBA',
    initials: 'SP',
    code: '3502200',
  },
  {
    name: 'ANGELÂNDIA',
    initials: 'MG',
    code: '3102852',
  },
  {
    name: 'ANGÉLICA',
    initials: 'MS',
    code: '5000856',
  },
  {
    name: 'ANGELIM',
    initials: 'PE',
    code: '2601003',
  },
  {
    name: 'ANGELINA',
    initials: 'SC',
    code: '4200903',
  },
  {
    name: 'ANGICAL',
    initials: 'BA',
    code: '2901403',
  },
  {
    name: 'ANGICAL DO PIAUÍ',
    initials: 'PI',
    code: '2200608',
  },
  {
    name: 'ANGICO',
    initials: 'TO',
    code: '1701051',
  },
  {
    name: 'ANGICOS',
    initials: 'RN',
    code: '2400802',
  },
  {
    name: 'ANGRA DOS REIS',
    initials: 'RJ',
    code: '3300100',
  },
  {
    name: 'ANGUERA',
    initials: 'BA',
    code: '2901502',
  },
  {
    name: 'ÂNGULO',
    initials: 'PR',
    code: '4101150',
  },
  {
    name: 'ANHANGÜERA',
    initials: 'GO',
    code: '5201207',
  },
  {
    name: 'ANHEMBI',
    initials: 'SP',
    code: '3502309',
  },
  {
    name: 'ANHUMAS',
    initials: 'SP',
    code: '3502408',
  },
  {
    name: 'ANICUNS',
    initials: 'GO',
    code: '5201306',
  },
  {
    name: 'ANÍSIO DE ABREU',
    initials: 'PI',
    code: '2200707',
  },
  {
    name: 'ANITA GARIBALDI',
    initials: 'SC',
    code: '4201000',
  },
  {
    name: 'ANITÁPOLIS',
    initials: 'SC',
    code: '4201109',
  },
  {
    name: 'ANORI',
    initials: 'AM',
    code: '1300102',
  },
  {
    name: 'ANTA GORDA',
    initials: 'RS',
    code: '4300703',
  },
  {
    name: 'ANTAS',
    initials: 'BA',
    code: '2901601',
  },
  {
    name: 'ANTONINA',
    initials: 'PR',
    code: '4101200',
  },
  {
    name: 'ANTONINA DO NORTE',
    initials: 'CE',
    code: '2300804',
  },
  {
    name: 'ANTÔNIO ALMEIDA',
    initials: 'PI',
    code: '2200806',
  },
  {
    name: 'ANTÔNIO CARDOSO',
    initials: 'BA',
    code: '2901700',
  },
  {
    name: 'ANTÔNIO CARLOS',
    initials: 'MG',
    code: '3102902',
  },
  {
    name: 'ANTÔNIO CARLOS',
    initials: 'SC',
    code: '4201208',
  },
  {
    name: 'ANTÔNIO DIAS',
    initials: 'MG',
    code: '3103009',
  },
  {
    name: 'ANTÔNIO GONÇALVES',
    initials: 'BA',
    code: '2901809',
  },
  {
    name: 'ANTÔNIO JOÃO',
    initials: 'MS',
    code: '5000906',
  },
  {
    name: 'ANTÔNIO MARTINS',
    initials: 'RN',
    code: '2400901',
  },
  {
    name: 'ANTÔNIO OLINTO',
    initials: 'PR',
    code: '4101309',
  },
  {
    name: 'ANTÔNIO PRADO',
    initials: 'RS',
    code: '4300802',
  },
  {
    name: 'ANTÔNIO PRADO DE MINAS',
    initials: 'MG',
    code: '3103108',
  },
  {
    name: 'APARECIDA',
    initials: 'PB',
    code: '2500775',
  },
  {
    name: 'APARECIDA',
    initials: 'SP',
    code: '3502507',
  },
  {
    name: 'APARECIDA DE GOIÂNIA',
    initials: 'GO',
    code: '5201405',
  },
  {
    name: 'APARECIDA D\'OESTE',
    initials: 'SP',
    code: '3502606',
  },
  {
    name: 'APARECIDA DO RIO DOCE',
    initials: 'GO',
    code: '5201454',
  },
  {
    name: 'APARECIDA DO RIO NEGRO',
    initials: 'TO',
    code: '1701101',
  },
  {
    name: 'APARECIDA DO TABOADO',
    initials: 'MS',
    code: '5001003',
  },
  {
    name: 'APERIBÉ',
    initials: 'RJ',
    code: '3300159',
  },
  {
    name: 'APIACÁ',
    initials: 'ES',
    code: '3200508',
  },
  {
    name: 'APIACÁS',
    initials: 'MT',
    code: '5100805',
  },
  {
    name: 'APIAÍ',
    initials: 'SP',
    code: '3502705',
  },
  {
    name: 'APICUM-AÇU',
    initials: 'MA',
    code: '2100832',
  },
  {
    name: 'APIÚNA',
    initials: 'SC',
    code: '4201257',
  },
  {
    name: 'APODI',
    initials: 'RN',
    code: '2401008',
  },
  {
    name: 'APORÁ',
    initials: 'BA',
    code: '2901908',
  },
  {
    name: 'APORÉ',
    initials: 'GO',
    code: '5201504',
  },
  {
    name: 'APUAREMA',
    initials: 'BA',
    code: '2901957',
  },
  {
    name: 'APUCARANA',
    initials: 'PR',
    code: '4101408',
  },
  {
    name: 'APUÍ',
    initials: 'AM',
    code: '1300144',
  },
  {
    name: 'APUIARÉS',
    initials: 'CE',
    code: '2300903',
  },
  {
    name: 'AQUIDABÃ',
    initials: 'SE',
    code: '2800209',
  },
  {
    name: 'AQUIDAUANA',
    initials: 'MS',
    code: '5001102',
  },
  {
    name: 'AQUIRAZ',
    initials: 'CE',
    code: '2301000',
  },
  {
    name: 'ARABUTÃ',
    initials: 'SC',
    code: '4201273',
  },
  {
    name: 'ARAÇAGI',
    initials: 'PB',
    code: '2500809',
  },
  {
    name: 'ARAÇAÍ',
    initials: 'MG',
    code: '3103207',
  },
  {
    name: 'ARACAJU',
    initials: 'SE',
    code: '2800308',
  },
  {
    name: 'ARAÇARIGUAMA',
    initials: 'SP',
    code: '3502754',
  },
  {
    name: 'ARAÇÁS',
    initials: 'BA',
    code: '2902054',
  },
  {
    name: 'ARACATI',
    initials: 'CE',
    code: '2301109',
  },
  {
    name: 'ARACATU',
    initials: 'BA',
    code: '2902005',
  },
  {
    name: 'ARAÇATUBA',
    initials: 'SP',
    code: '3502804',
  },
  {
    name: 'ARACI',
    initials: 'BA',
    code: '2902104',
  },
  {
    name: 'ARACITABA',
    initials: 'MG',
    code: '3103306',
  },
  {
    name: 'ARACOIABA',
    initials: 'CE',
    code: '2301208',
  },
  {
    name: 'ARAÇOIABA',
    initials: 'PE',
    code: '2601052',
  },
  {
    name: 'ARAÇOIABA DA SERRA',
    initials: 'SP',
    code: '3502903',
  },
  {
    name: 'ARACRUZ',
    initials: 'ES',
    code: '3200607',
  },
  {
    name: 'ARAÇU',
    initials: 'GO',
    code: '5201603',
  },
  {
    name: 'ARAÇUAÍ',
    initials: 'MG',
    code: '3103405',
  },
  {
    name: 'ARAGARÇAS',
    initials: 'GO',
    code: '5201702',
  },
  {
    name: 'ARAGOIÂNIA',
    initials: 'GO',
    code: '5201801',
  },
  {
    name: 'ARAGOMINAS',
    initials: 'TO',
    code: '1701309',
  },
  {
    name: 'ARAGUACEMA',
    initials: 'TO',
    code: '1701903',
  },
  {
    name: 'ARAGUAÇU',
    initials: 'TO',
    code: '1702000',
  },
  {
    name: 'ARAGUAIANA',
    initials: 'MT',
    code: '5101001',
  },
  {
    name: 'ARAGUAÍNA',
    initials: 'TO',
    code: '1702109',
  },
  {
    name: 'ARAGUAINHA',
    initials: 'MT',
    code: '5101209',
  },
  {
    name: 'ARAGUANÃ',
    initials: 'MA',
    code: '2100873',
  },
  {
    name: 'ARAGUANÃ',
    initials: 'TO',
    code: '1702158',
  },
  {
    name: 'ARAGUAPAZ',
    initials: 'GO',
    code: '5202155',
  },
  {
    name: 'ARAGUARI',
    initials: 'MG',
    code: '3103504',
  },
  {
    name: 'ARAGUATINS',
    initials: 'TO',
    code: '1702208',
  },
  {
    name: 'ARAIÓSES',
    initials: 'MA',
    code: '2100907',
  },
  {
    name: 'ARAL MOREIRA',
    initials: 'MS',
    code: '5001243',
  },
  {
    name: 'ARAMARI',
    initials: 'BA',
    code: '2902203',
  },
  {
    name: 'ARAMBARÉ',
    initials: 'RS',
    code: '4300851',
  },
  {
    name: 'ARAME',
    initials: 'MA',
    code: '2100956',
  },
  {
    name: 'ARAMINA',
    initials: 'SP',
    code: '3503000',
  },
  {
    name: 'ARANDU',
    initials: 'SP',
    code: '3503109',
  },
  {
    name: 'ARANTINA',
    initials: 'MG',
    code: '3103603',
  },
  {
    name: 'ARAPEÍ',
    initials: 'SP',
    code: '3503158',
  },
  {
    name: 'ARAPIRACA',
    initials: 'AL',
    code: '2700300',
  },
  {
    name: 'ARAPOEMA',
    initials: 'TO',
    code: '1702307',
  },
  {
    name: 'ARAPONGA',
    initials: 'MG',
    code: '3103702',
  },
  {
    name: 'ARAPONGAS',
    initials: 'PR',
    code: '4101507',
  },
  {
    name: 'ARAPORÃ',
    initials: 'MG',
    code: '3103751',
  },
  {
    name: 'ARAPOTI',
    initials: 'PR',
    code: '4101606',
  },
  {
    name: 'ARAPUÁ',
    initials: 'MG',
    code: '3103801',
  },
  {
    name: 'ARAPUÃ',
    initials: 'PR',
    code: '4101655',
  },
  {
    name: 'ARAPUTANGA',
    initials: 'MT',
    code: '5101258',
  },
  {
    name: 'ARAQUARI',
    initials: 'SC',
    code: '4201307',
  },
  {
    name: 'ARARA',
    initials: 'PB',
    code: '2500908',
  },
  {
    name: 'ARARANGUÁ',
    initials: 'SC',
    code: '4201406',
  },
  {
    name: 'ARARAQUARA',
    initials: 'SP',
    code: '3503208',
  },
  {
    name: 'ARARAS',
    initials: 'SP',
    code: '3503307',
  },
  {
    name: 'ARARENDÁ',
    initials: 'CE',
    code: '2301257',
  },
  {
    name: 'ARARI',
    initials: 'MA',
    code: '2101004',
  },
  {
    name: 'ARARICÁ',
    initials: 'RS',
    code: '4300877',
  },
  {
    name: 'ARARIPE',
    initials: 'CE',
    code: '2301307',
  },
  {
    name: 'ARARIPINA',
    initials: 'PE',
    code: '2601102',
  },
  {
    name: 'ARARUAMA',
    initials: 'RJ',
    code: '3300209',
  },
  {
    name: 'ARARUNA',
    initials: 'PB',
    code: '2501005',
  },
  {
    name: 'ARARUNA',
    initials: 'PR',
    code: '4101705',
  },
  {
    name: 'ARATACA',
    initials: 'BA',
    code: '2902252',
  },
  {
    name: 'ARATIBA',
    initials: 'RS',
    code: '4300901',
  },
  {
    name: 'ARATUBA',
    initials: 'CE',
    code: '2301406',
  },
  {
    name: 'ARATUÍPE',
    initials: 'BA',
    code: '2902302',
  },
  {
    name: 'ARAUÁ',
    initials: 'SE',
    code: '2800407',
  },
  {
    name: 'ARAUCÁRIA',
    initials: 'PR',
    code: '4101804',
  },
  {
    name: 'ARAÚJOS',
    initials: 'MG',
    code: '3103900',
  },
  {
    name: 'ARAXÁ',
    initials: 'MG',
    code: '3104007',
  },
  {
    name: 'ARCEBURGO',
    initials: 'MG',
    code: '3104106',
  },
  {
    name: 'ARCO-ÍRIS',
    initials: 'SP',
    code: '3503356',
  },
  {
    name: 'ARCOS',
    initials: 'MG',
    code: '3104205',
  },
  {
    name: 'ARCOVERDE',
    initials: 'PE',
    code: '2601201',
  },
  {
    name: 'AREADO',
    initials: 'MG',
    code: '3104304',
  },
  {
    name: 'AREAL',
    initials: 'RJ',
    code: '3300225',
  },
  {
    name: 'AREALVA',
    initials: 'SP',
    code: '3503406',
  },
  {
    name: 'AREIA',
    initials: 'PB',
    code: '2501104',
  },
  {
    name: 'AREIA BRANCA',
    initials: 'RN',
    code: '2401107',
  },
  {
    name: 'AREIA BRANCA',
    initials: 'SE',
    code: '2800506',
  },
  {
    name: 'AREIA DE BARAÚNAS',
    initials: 'PB',
    code: '2501153',
  },
  {
    name: 'AREIAL',
    initials: 'PB',
    code: '2501203',
  },
  {
    name: 'AREIAS',
    initials: 'SP',
    code: '3503505',
  },
  {
    name: 'AREIÓPOLIS',
    initials: 'SP',
    code: '3503604',
  },
  {
    name: 'ARENÁPOLIS',
    initials: 'MT',
    code: '5101308',
  },
  {
    name: 'ARENÓPOLIS',
    initials: 'GO',
    code: '5202353',
  },
  {
    name: 'AREZ',
    initials: 'RN',
    code: '2401206',
  },
  {
    name: 'ARGIRITA',
    initials: 'MG',
    code: '3104403',
  },
  {
    name: 'ARICANDUVA',
    initials: 'MG',
    code: '3104452',
  },
  {
    name: 'ARINOS',
    initials: 'MG',
    code: '3104502',
  },
  {
    name: 'ARIPUANÃ',
    initials: 'MT',
    code: '5101407',
  },
  {
    name: 'ARIQUEMES',
    initials: 'RO',
    code: '1100023',
  },
  {
    name: 'ARIRANHA',
    initials: 'SP',
    code: '3503703',
  },
  {
    name: 'ARIRANHA DO IVAÍ',
    initials: 'PR',
    code: '4101853',
  },
  {
    name: 'ARMAÇÃO DOS BÚZIOS',
    initials: 'RJ',
    code: '3300233',
  },
  {
    name: 'ARMAZÉM',
    initials: 'SC',
    code: '4201505',
  },
  {
    name: 'ARNEIROZ',
    initials: 'CE',
    code: '2301505',
  },
  {
    name: 'AROAZES',
    initials: 'PI',
    code: '2200905',
  },
  {
    name: 'AROEIRAS',
    initials: 'PB',
    code: '2501302',
  },
  {
    name: 'AROEIRAS DO ITAIM',
    initials: 'PI',
    code: '2200954',
  },
  {
    name: 'ARRAIAL',
    initials: 'PI',
    code: '2201002',
  },
  {
    name: 'ARRAIAL DO CABO',
    initials: 'RJ',
    code: '3300258',
  },
  {
    name: 'ARRAIAS',
    initials: 'TO',
    code: '1702406',
  },
  {
    name: 'ARROIO DO MEIO',
    initials: 'RS',
    code: '4301008',
  },
  {
    name: 'ARROIO DO PADRE',
    initials: 'RS',
    code: '4301073',
  },
  {
    name: 'ARROIO DO SAL',
    initials: 'RS',
    code: '4301057',
  },
  {
    name: 'ARROIO DOS RATOS',
    initials: 'RS',
    code: '4301107',
  },
  {
    name: 'ARROIO DO TIGRE',
    initials: 'RS',
    code: '4301206',
  },
  {
    name: 'ARROIO GRANDE',
    initials: 'RS',
    code: '4301305',
  },
  {
    name: 'ARROIO TRINTA',
    initials: 'SC',
    code: '4201604',
  },
  {
    name: 'ARTUR NOGUEIRA',
    initials: 'SP',
    code: '3503802',
  },
  {
    name: 'ARUANÃ',
    initials: 'GO',
    code: '5202502',
  },
  {
    name: 'ARUJÁ',
    initials: 'SP',
    code: '3503901',
  },
  {
    name: 'ARVOREDO',
    initials: 'SC',
    code: '4201653',
  },
  {
    name: 'ARVOREZINHA',
    initials: 'RS',
    code: '4301404',
  },
  {
    name: 'ASCURRA',
    initials: 'SC',
    code: '4201703',
  },
  {
    name: 'ASPÁSIA',
    initials: 'SP',
    code: '3503950',
  },
  {
    name: 'ASSAÍ',
    initials: 'PR',
    code: '4101903',
  },
  {
    name: 'ASSARÉ',
    initials: 'CE',
    code: '2301604',
  },
  {
    name: 'ASSIS',
    initials: 'SP',
    code: '3504008',
  },
  {
    name: 'ASSIS BRASIL',
    initials: 'AC',
    code: '1200054',
  },
  {
    name: 'ASSIS CHATEAUBRIAND',
    initials: 'PR',
    code: '4102000',
  },
  {
    name: 'ASSUNÇÃO',
    initials: 'PB',
    code: '2501351',
  },
  {
    name: 'ASSUNÇÃO DO PIAUÍ',
    initials: 'PI',
    code: '2201051',
  },
  {
    name: 'ASTOLFO DUTRA',
    initials: 'MG',
    code: '3104601',
  },
  {
    name: 'ASTORGA',
    initials: 'PR',
    code: '4102109',
  },
  {
    name: 'ATALAIA',
    initials: 'AL',
    code: '2700409',
  },
  {
    name: 'ATALAIA',
    initials: 'PR',
    code: '4102208',
  },
  {
    name: 'ATALAIA DO NORTE',
    initials: 'AM',
    code: '1300201',
  },
  {
    name: 'ATALANTA',
    initials: 'SC',
    code: '4201802',
  },
  {
    name: 'ATALÉIA',
    initials: 'MG',
    code: '3104700',
  },
  {
    name: 'ATIBAIA',
    initials: 'SP',
    code: '3504107',
  },
  {
    name: 'ATÍLIO VIVÁCQUA',
    initials: 'ES',
    code: '3200706',
  },
  {
    name: 'AUGUSTINÓPOLIS',
    initials: 'TO',
    code: '1702554',
  },
  {
    name: 'AUGUSTO CORRÊA',
    initials: 'PA',
    code: '1500909',
  },
  {
    name: 'AUGUSTO DE LIMA',
    initials: 'MG',
    code: '3104809',
  },
  {
    name: 'AUGUSTO PESTANA',
    initials: 'RS',
    code: '4301503',
  },
  {
    name: 'ÁUREA',
    initials: 'RS',
    code: '4301552',
  },
  {
    name: 'AURELINO LEAL',
    initials: 'BA',
    code: '2902401',
  },
  {
    name: 'AURIFLAMA',
    initials: 'SP',
    code: '3504206',
  },
  {
    name: 'AURILÂNDIA',
    initials: 'GO',
    code: '5202601',
  },
  {
    name: 'AURORA',
    initials: 'CE',
    code: '2301703',
  },
  {
    name: 'AURORA',
    initials: 'SC',
    code: '4201901',
  },
  {
    name: 'AURORA DO PARÁ',
    initials: 'PA',
    code: '1500958',
  },
  {
    name: 'AURORA DO TOCANTINS',
    initials: 'TO',
    code: '1702703',
  },
  {
    name: 'AUTAZES',
    initials: 'AM',
    code: '1300300',
  },
  {
    name: 'AVAÍ',
    initials: 'SP',
    code: '3504305',
  },
  {
    name: 'AVANHANDAVA',
    initials: 'SP',
    code: '3504404',
  },
  {
    name: 'AVARÉ',
    initials: 'SP',
    code: '3504503',
  },
  {
    name: 'AVEIRO',
    initials: 'PA',
    code: '1501006',
  },
  {
    name: 'AVELINO LOPES',
    initials: 'PI',
    code: '2201101',
  },
  {
    name: 'AVELINÓPOLIS',
    initials: 'GO',
    code: '5202809',
  },
  {
    name: 'AXIXÁ',
    initials: 'MA',
    code: '2101103',
  },
  {
    name: 'AXIXÁ DO TOCANTINS',
    initials: 'TO',
    code: '1702901',
  },
  {
    name: 'BABAÇULÂNDIA',
    initials: 'TO',
    code: '1703008',
  },
  {
    name: 'BACABAL',
    initials: 'MA',
    code: '2101202',
  },
  {
    name: 'BACABEIRA',
    initials: 'MA',
    code: '2101251',
  },
  {
    name: 'BACURI',
    initials: 'MA',
    code: '2101301',
  },
  {
    name: 'BACURITUBA',
    initials: 'MA',
    code: '2101350',
  },
  {
    name: 'BADY BASSITT',
    initials: 'SP',
    code: '3504602',
  },
  {
    name: 'BAEPENDI',
    initials: 'MG',
    code: '3104908',
  },
  {
    name: 'BAGÉ',
    initials: 'RS',
    code: '4301602',
  },
  {
    name: 'BAGRE',
    initials: 'PA',
    code: '1501105',
  },
  {
    name: 'BAÍA DA TRAIÇÃO',
    initials: 'PB',
    code: '2501401',
  },
  {
    name: 'BAÍA FORMOSA',
    initials: 'RN',
    code: '2401404',
  },
  {
    name: 'BAIANÓPOLIS',
    initials: 'BA',
    code: '2902500',
  },
  {
    name: 'BAIÃO',
    initials: 'PA',
    code: '1501204',
  },
  {
    name: 'BAIXA GRANDE',
    initials: 'BA',
    code: '2902609',
  },
  {
    name: 'BAIXA GRANDE DO RIBEIRO',
    initials: 'PI',
    code: '2201150',
  },
  {
    name: 'BAIXIO',
    initials: 'CE',
    code: '2301802',
  },
  {
    name: 'BAIXO GUANDU',
    initials: 'ES',
    code: '3200805',
  },
  {
    name: 'BALBINOS',
    initials: 'SP',
    code: '3504701',
  },
  {
    name: 'BALDIM',
    initials: 'MG',
    code: '3105004',
  },
  {
    name: 'BALIZA',
    initials: 'GO',
    code: '5203104',
  },
  {
    name: 'BALNEÁRIO ARROIO DO SILVA',
    initials: 'SC',
    code: '4201950',
  },
  {
    name: 'BALNEÁRIO BARRA DO SUL',
    initials: 'SC',
    code: '4202057',
  },
  {
    name: 'BALNEÁRIO CAMBORIÚ',
    initials: 'SC',
    code: '4202008',
  },
  {
    name: 'BALNEÁRIO GAIVOTA',
    initials: 'SC',
    code: '4202073',
  },
  {
    name: 'BALNEÁRIO PIÇARRAS',
    initials: 'SC',
    code: '4212809',
  },
  {
    name: 'BALNEÁRIO PINHAL',
    initials: 'RS',
    code: '4301636',
  },
  {
    name: 'BALNEÁRIO RINCÃO',
    initials: 'SC',
    code: '4220000',
  },
  {
    name: 'BÁLSAMO',
    initials: 'SP',
    code: '3504800',
  },
  {
    name: 'BALSA NOVA',
    initials: 'PR',
    code: '4102307',
  },
  {
    name: 'BALSAS',
    initials: 'MA',
    code: '2101400',
  },
  {
    name: 'BAMBUÍ',
    initials: 'MG',
    code: '3105103',
  },
  {
    name: 'BANABUIÚ',
    initials: 'CE',
    code: '2301851',
  },
  {
    name: 'BANANAL',
    initials: 'SP',
    code: '3504909',
  },
  {
    name: 'BANANEIRAS',
    initials: 'PB',
    code: '2501500',
  },
  {
    name: 'BANDEIRA',
    initials: 'MG',
    code: '3105202',
  },
  {
    name: 'BANDEIRA DO SUL',
    initials: 'MG',
    code: '3105301',
  },
  {
    name: 'BANDEIRANTE',
    initials: 'SC',
    code: '4202081',
  },
  {
    name: 'BANDEIRANTES',
    initials: 'PR',
    code: '4102406',
  },
  {
    name: 'BANDEIRANTES',
    initials: 'MS',
    code: '5001508',
  },
  {
    name: 'BANDEIRANTES DO TOCANTINS',
    initials: 'TO',
    code: '1703057',
  },
  {
    name: 'BANNACH',
    initials: 'PA',
    code: '1501253',
  },
  {
    name: 'BANZAÊ',
    initials: 'BA',
    code: '2902658',
  },
  {
    name: 'BARÃO',
    initials: 'RS',
    code: '4301651',
  },
  {
    name: 'BARÃO DE ANTONINA',
    initials: 'SP',
    code: '3505005',
  },
  {
    name: 'BARÃO DE COCAIS',
    initials: 'MG',
    code: '3105400',
  },
  {
    name: 'BARÃO DE COTEGIPE',
    initials: 'RS',
    code: '4301701',
  },
  {
    name: 'BARÃO DE GRAJAÚ',
    initials: 'MA',
    code: '2101509',
  },
  {
    name: 'BARÃO DE MELGAÇO',
    initials: 'MT',
    code: '5101605',
  },
  {
    name: 'BARÃO DE MONTE ALTO',
    initials: 'MG',
    code: '3105509',
  },
  {
    name: 'BARÃO DO TRIUNFO',
    initials: 'RS',
    code: '4301750',
  },
  {
    name: 'BARAÚNA',
    initials: 'PB',
    code: '2501534',
  },
  {
    name: 'BARAÚNA',
    initials: 'RN',
    code: '2401453',
  },
  {
    name: 'BARBACENA',
    initials: 'MG',
    code: '3105608',
  },
  {
    name: 'BARBALHA',
    initials: 'CE',
    code: '2301901',
  },
  {
    name: 'BARBOSA',
    initials: 'SP',
    code: '3505104',
  },
  {
    name: 'BARBOSA FERRAZ',
    initials: 'PR',
    code: '4102505',
  },
  {
    name: 'BARCARENA',
    initials: 'PA',
    code: '1501303',
  },
  {
    name: 'BARCELONA',
    initials: 'RN',
    code: '2401503',
  },
  {
    name: 'BARCELOS',
    initials: 'AM',
    code: '1300409',
  },
  {
    name: 'BARIRI',
    initials: 'SP',
    code: '3505203',
  },
  {
    name: 'BARRA',
    initials: 'BA',
    code: '2902708',
  },
  {
    name: 'BARRA BONITA',
    initials: 'SC',
    code: '4202099',
  },
  {
    name: 'BARRA BONITA',
    initials: 'SP',
    code: '3505302',
  },
  {
    name: 'BARRACÃO',
    initials: 'PR',
    code: '4102604',
  },
  {
    name: 'BARRACÃO',
    initials: 'RS',
    code: '4301800',
  },
  {
    name: 'BARRA DA ESTIVA',
    initials: 'BA',
    code: '2902807',
  },
  {
    name: 'BARRA D\'ALCÂNTARA',
    initials: 'PI',
    code: '2201176',
  },
  {
    name: 'BARRA DE GUABIRABA',
    initials: 'PE',
    code: '2601300',
  },
  {
    name: 'BARRA DE SANTANA',
    initials: 'PB',
    code: '2501575',
  },
  {
    name: 'BARRA DE SANTA ROSA',
    initials: 'PB',
    code: '2501609',
  },
  {
    name: 'BARRA DE SANTO ANTÔNIO',
    initials: 'AL',
    code: '2700508',
  },
  {
    name: 'BARRA DE SÃO FRANCISCO',
    initials: 'ES',
    code: '3200904',
  },
  {
    name: 'BARRA DE SÃO MIGUEL',
    initials: 'PB',
    code: '2501708',
  },
  {
    name: 'BARRA DE SÃO MIGUEL',
    initials: 'AL',
    code: '2700607',
  },
  {
    name: 'BARRA DO BUGRES',
    initials: 'MT',
    code: '5101704',
  },
  {
    name: 'BARRA DO CHAPÉU',
    initials: 'SP',
    code: '3505351',
  },
  {
    name: 'BARRA DO CHOÇA',
    initials: 'BA',
    code: '2902906',
  },
  {
    name: 'BARRA DO CORDA',
    initials: 'MA',
    code: '2101608',
  },
  {
    name: 'BARRA DO GARÇAS',
    initials: 'MT',
    code: '5101803',
  },
  {
    name: 'BARRA DO GUARITA',
    initials: 'RS',
    code: '4301859',
  },
  {
    name: 'BARRA DO JACARÉ',
    initials: 'PR',
    code: '4102703',
  },
  {
    name: 'BARRA DO MENDES',
    initials: 'BA',
    code: '2903003',
  },
  {
    name: 'BARRA DO OURO',
    initials: 'TO',
    code: '1703073',
  },
  {
    name: 'BARRA DO PIRAÍ',
    initials: 'RJ',
    code: '3300308',
  },
  {
    name: 'BARRA DO QUARAÍ',
    initials: 'RS',
    code: '4301875',
  },
  {
    name: 'BARRA DO RIBEIRO',
    initials: 'RS',
    code: '4301909',
  },
  {
    name: 'BARRA DO RIO AZUL',
    initials: 'RS',
    code: '4301925',
  },
  {
    name: 'BARRA DO ROCHA',
    initials: 'BA',
    code: '2903102',
  },
  {
    name: 'BARRA DOS COQUEIROS',
    initials: 'SE',
    code: '2800605',
  },
  {
    name: 'BARRA DO TURVO',
    initials: 'SP',
    code: '3505401',
  },
  {
    name: 'BARRA FUNDA',
    initials: 'RS',
    code: '4301958',
  },
  {
    name: 'BARRA LONGA',
    initials: 'MG',
    code: '3105707',
  },
  {
    name: 'BARRA MANSA',
    initials: 'RJ',
    code: '3300407',
  },
  {
    name: 'BARRAS',
    initials: 'PI',
    code: '2201200',
  },
  {
    name: 'BARRA VELHA',
    initials: 'SC',
    code: '4202107',
  },
  {
    name: 'BARREIRA',
    initials: 'CE',
    code: '2301950',
  },
  {
    name: 'BARREIRAS',
    initials: 'BA',
    code: '2903201',
  },
  {
    name: 'BARREIRAS DO PIAUÍ',
    initials: 'PI',
    code: '2201309',
  },
  {
    name: 'BARREIRINHA',
    initials: 'AM',
    code: '1300508',
  },
  {
    name: 'BARREIRINHAS',
    initials: 'MA',
    code: '2101707',
  },
  {
    name: 'BARREIROS',
    initials: 'PE',
    code: '2601409',
  },
  {
    name: 'BARRETOS',
    initials: 'SP',
    code: '3505500',
  },
  {
    name: 'BARRINHA',
    initials: 'SP',
    code: '3505609',
  },
  {
    name: 'BARRO',
    initials: 'CE',
    code: '2302008',
  },
  {
    name: 'BARRO ALTO',
    initials: 'GO',
    code: '5203203',
  },
  {
    name: 'BARRO ALTO',
    initials: 'BA',
    code: '2903235',
  },
  {
    name: 'BARROCAS',
    initials: 'BA',
    code: '2903276',
  },
  {
    name: 'BARRO DURO',
    initials: 'PI',
    code: '2201408',
  },
  {
    name: 'BARROLÂNDIA',
    initials: 'TO',
    code: '1703107',
  },
  {
    name: 'BARRO PRETO',
    initials: 'BA',
    code: '2903300',
  },
  {
    name: 'BARROQUINHA',
    initials: 'CE',
    code: '2302057',
  },
  {
    name: 'BARROS CASSAL',
    initials: 'RS',
    code: '4302006',
  },
  {
    name: 'BARROSO',
    initials: 'MG',
    code: '3105905',
  },
  {
    name: 'BARUERI',
    initials: 'SP',
    code: '3505708',
  },
  {
    name: 'BASTOS',
    initials: 'SP',
    code: '3505807',
  },
  {
    name: 'BATAGUASSU',
    initials: 'MS',
    code: '5001904',
  },
  {
    name: 'BATALHA',
    initials: 'PI',
    code: '2201507',
  },
  {
    name: 'BATALHA',
    initials: 'AL',
    code: '2700706',
  },
  {
    name: 'BATATAIS',
    initials: 'SP',
    code: '3505906',
  },
  {
    name: 'BATAYPORÃ',
    initials: 'MS',
    code: '5002001',
  },
  {
    name: 'BATURITÉ',
    initials: 'CE',
    code: '2302107',
  },
  {
    name: 'BAURU',
    initials: 'SP',
    code: '3506003',
  },
  {
    name: 'BAYEUX',
    initials: 'PB',
    code: '2501807',
  },
  {
    name: 'BEBEDOURO',
    initials: 'SP',
    code: '3506102',
  },
  {
    name: 'BEBERIBE',
    initials: 'CE',
    code: '2302206',
  },
  {
    name: 'BELA CRUZ',
    initials: 'CE',
    code: '2302305',
  },
  {
    name: 'BELÁGUA',
    initials: 'MA',
    code: '2101731',
  },
  {
    name: 'BELA VISTA',
    initials: 'MS',
    code: '5002100',
  },
  {
    name: 'BELA VISTA DA CAROBA',
    initials: 'PR',
    code: '4102752',
  },
  {
    name: 'BELA VISTA DE GOIÁS',
    initials: 'GO',
    code: '5203302',
  },
  {
    name: 'BELA VISTA DE MINAS',
    initials: 'MG',
    code: '3106002',
  },
  {
    name: 'BELA VISTA DO MARANHÃO',
    initials: 'MA',
    code: '2101772',
  },
  {
    name: 'BELA VISTA DO PARAÍSO',
    initials: 'PR',
    code: '4102802',
  },
  {
    name: 'BELA VISTA DO PIAUÍ',
    initials: 'PI',
    code: '2201556',
  },
  {
    name: 'BELA VISTA DO TOLDO',
    initials: 'SC',
    code: '4202131',
  },
  {
    name: 'BELÉM',
    initials: 'AL',
    code: '2700805',
  },
  {
    name: 'BELÉM',
    initials: 'PA',
    code: '1501402',
  },
  {
    name: 'BELÉM',
    initials: 'PB',
    code: '2501906',
  },
  {
    name: 'BELÉM DE MARIA',
    initials: 'PE',
    code: '2601508',
  },
  {
    name: 'BELÉM DO BREJO DO CRUZ',
    initials: 'PB',
    code: '2502003',
  },
  {
    name: 'BELÉM DO PIAUÍ',
    initials: 'PI',
    code: '2201572',
  },
  {
    name: 'BELÉM DO SÃO FRANCISCO',
    initials: 'PE',
    code: '2601607',
  },
  {
    name: 'BELFORD ROXO',
    initials: 'RJ',
    code: '3300456',
  },
  {
    name: 'BELMIRO BRAGA',
    initials: 'MG',
    code: '3106101',
  },
  {
    name: 'BELMONTE',
    initials: 'BA',
    code: '2903409',
  },
  {
    name: 'BELMONTE',
    initials: 'SC',
    code: '4202156',
  },
  {
    name: 'BELO CAMPO',
    initials: 'BA',
    code: '2903508',
  },
  {
    name: 'BELO HORIZONTE',
    initials: 'MG',
    code: '3106200',
  },
  {
    name: 'BELO JARDIM',
    initials: 'PE',
    code: '2601706',
  },
  {
    name: 'BELO MONTE',
    initials: 'AL',
    code: '2700904',
  },
  {
    name: 'BELO ORIENTE',
    initials: 'MG',
    code: '3106309',
  },
  {
    name: 'BELO VALE',
    initials: 'MG',
    code: '3106408',
  },
  {
    name: 'BELTERRA',
    initials: 'PA',
    code: '1501451',
  },
  {
    name: 'BENEDITINOS',
    initials: 'PI',
    code: '2201606',
  },
  {
    name: 'BENEDITO LEITE',
    initials: 'MA',
    code: '2101806',
  },
  {
    name: 'BENEDITO NOVO',
    initials: 'SC',
    code: '4202206',
  },
  {
    name: 'BENEVIDES',
    initials: 'PA',
    code: '1501501',
  },
  {
    name: 'BENJAMIN CONSTANT',
    initials: 'AM',
    code: '1300607',
  },
  {
    name: 'BENJAMIN CONSTANT DO SUL',
    initials: 'RS',
    code: '4302055',
  },
  {
    name: 'BENTO DE ABREU',
    initials: 'SP',
    code: '3506201',
  },
  {
    name: 'BENTO FERNANDES',
    initials: 'RN',
    code: '2401602',
  },
  {
    name: 'BENTO GONÇALVES',
    initials: 'RS',
    code: '4302105',
  },
  {
    name: 'BEQUIMÃO',
    initials: 'MA',
    code: '2101905',
  },
  {
    name: 'BERILO',
    initials: 'MG',
    code: '3106507',
  },
  {
    name: 'BERIZAL',
    initials: 'MG',
    code: '3106655',
  },
  {
    name: 'BERNARDINO BATISTA',
    initials: 'PB',
    code: '2502052',
  },
  {
    name: 'BERNARDINO DE CAMPOS',
    initials: 'SP',
    code: '3506300',
  },
  {
    name: 'BERNARDO DO MEARIM',
    initials: 'MA',
    code: '2101939',
  },
  {
    name: 'BERNARDO SAYÃO',
    initials: 'TO',
    code: '1703206',
  },
  {
    name: 'BERTIOGA',
    initials: 'SP',
    code: '3506359',
  },
  {
    name: 'BERTOLÍNIA',
    initials: 'PI',
    code: '2201705',
  },
  {
    name: 'BERTÓPOLIS',
    initials: 'MG',
    code: '3106606',
  },
  {
    name: 'BERURI',
    initials: 'AM',
    code: '1300631',
  },
  {
    name: 'BETÂNIA',
    initials: 'PE',
    code: '2601805',
  },
  {
    name: 'BETÂNIA DO PIAUÍ',
    initials: 'PI',
    code: '2201739',
  },
  {
    name: 'BETIM',
    initials: 'MG',
    code: '3106705',
  },
  {
    name: 'BEZERROS',
    initials: 'PE',
    code: '2601904',
  },
  {
    name: 'BIAS FORTES',
    initials: 'MG',
    code: '3106804',
  },
  {
    name: 'BICAS',
    initials: 'MG',
    code: '3106903',
  },
  {
    name: 'BIGUAÇU',
    initials: 'SC',
    code: '4202305',
  },
  {
    name: 'BILAC',
    initials: 'SP',
    code: '3506409',
  },
  {
    name: 'BIQUINHAS',
    initials: 'MG',
    code: '3107000',
  },
  {
    name: 'BIRIGÜI',
    initials: 'SP',
    code: '3506508',
  },
  {
    name: 'BIRITIBA-MIRIM',
    initials: 'SP',
    code: '3506607',
  },
  {
    name: 'BIRITINGA',
    initials: 'BA',
    code: '2903607',
  },
  {
    name: 'BITURUNA',
    initials: 'PR',
    code: '4102901',
  },
  {
    name: 'BLUMENAU',
    initials: 'SC',
    code: '4202404',
  },
  {
    name: 'BOA ESPERANÇA',
    initials: 'ES',
    code: '3201001',
  },
  {
    name: 'BOA ESPERANÇA',
    initials: 'MG',
    code: '3107109',
  },
  {
    name: 'BOA ESPERANÇA',
    initials: 'PR',
    code: '4103008',
  },
  {
    name: 'BOA ESPERANÇA DO IGUAÇU',
    initials: 'PR',
    code: '4103024',
  },
  {
    name: 'BOA ESPERANÇA DO SUL',
    initials: 'SP',
    code: '3506706',
  },
  {
    name: 'BOA HORA',
    initials: 'PI',
    code: '2201770',
  },
  {
    name: 'BOA NOVA',
    initials: 'BA',
    code: '2903706',
  },
  {
    name: 'BOA SAÚDE',
    initials: 'RN',
    code: '2405306',
  },
  {
    name: 'BOA VENTURA',
    initials: 'PB',
    code: '2502102',
  },
  {
    name: 'BOA VENTURA DE SÃO ROQUE',
    initials: 'PR',
    code: '4103040',
  },
  {
    name: 'BOA VIAGEM',
    initials: 'CE',
    code: '2302404',
  },
  {
    name: 'BOA VISTA',
    initials: 'RR',
    code: '1400100',
  },
  {
    name: 'BOA VISTA',
    initials: 'PB',
    code: '2502151',
  },
  {
    name: 'BOA VISTA DA APARECIDA',
    initials: 'PR',
    code: '4103057',
  },
  {
    name: 'BOA VISTA DAS MISSÕES',
    initials: 'RS',
    code: '4302154',
  },
  {
    name: 'BOA VISTA DO BURICÁ',
    initials: 'RS',
    code: '4302204',
  },
  {
    name: 'BOA VISTA DO CADEADO',
    initials: 'RS',
    code: '4302220',
  },
  {
    name: 'BOA VISTA DO GURUPI',
    initials: 'MA',
    code: '2101970',
  },
  {
    name: 'BOA VISTA DO INCRA',
    initials: 'RS',
    code: '4302238',
  },
  {
    name: 'BOA VISTA DO RAMOS',
    initials: 'AM',
    code: '1300680',
  },
  {
    name: 'BOA VISTA DO SUL',
    initials: 'RS',
    code: '4302253',
  },
  {
    name: 'BOA VISTA DO TUPIM',
    initials: 'BA',
    code: '2903805',
  },
  {
    name: 'BOCA DA MATA',
    initials: 'AL',
    code: '2701001',
  },
  {
    name: 'BOCA DO ACRE',
    initials: 'AM',
    code: '1300706',
  },
  {
    name: 'BOCAINA',
    initials: 'PI',
    code: '2201804',
  },
  {
    name: 'BOCAINA',
    initials: 'SP',
    code: '3506805',
  },
  {
    name: 'BOCAINA DE MINAS',
    initials: 'MG',
    code: '3107208',
  },
  {
    name: 'BOCAÍNA DO SUL',
    initials: 'SC',
    code: '4202438',
  },
  {
    name: 'BOCAIÚVA',
    initials: 'MG',
    code: '3107307',
  },
  {
    name: 'BOCAIÚVA DO SUL',
    initials: 'PR',
    code: '4103107',
  },
  {
    name: 'BODÓ',
    initials: 'RN',
    code: '2401651',
  },
  {
    name: 'BODOCÓ',
    initials: 'PE',
    code: '2602001',
  },
  {
    name: 'BODOQUENA',
    initials: 'MS',
    code: '5002159',
  },
  {
    name: 'BOFETE',
    initials: 'SP',
    code: '3506904',
  },
  {
    name: 'BOITUVA',
    initials: 'SP',
    code: '3507001',
  },
  {
    name: 'BOMBINHAS',
    initials: 'SC',
    code: '4202453',
  },
  {
    name: 'BOM CONSELHO',
    initials: 'PE',
    code: '2602100',
  },
  {
    name: 'BOM DESPACHO',
    initials: 'MG',
    code: '3107406',
  },
  {
    name: 'BOM JARDIM',
    initials: 'RJ',
    code: '3300506',
  },
  {
    name: 'BOM JARDIM',
    initials: 'MA',
    code: '2102002',
  },
  {
    name: 'BOM JARDIM',
    initials: 'PE',
    code: '2602209',
  },
  {
    name: 'BOM JARDIM DA SERRA',
    initials: 'SC',
    code: '4202503',
  },
  {
    name: 'BOM JARDIM DE GOIÁS',
    initials: 'GO',
    code: '5203401',
  },
  {
    name: 'BOM JARDIM DE MINAS',
    initials: 'MG',
    code: '3107505',
  },
  {
    name: 'BOM JESUS',
    initials: 'SC',
    code: '4202537',
  },
  {
    name: 'BOM JESUS',
    initials: 'RS',
    code: '4302303',
  },
  {
    name: 'BOM JESUS',
    initials: 'PB',
    code: '2502201',
  },
  {
    name: 'BOM JESUS',
    initials: 'PI',
    code: '2201903',
  },
  {
    name: 'BOM JESUS',
    initials: 'RN',
    code: '2401701',
  },
  {
    name: 'BOM JESUS',
    initials: 'GO',
    code: '5203500',
  },
  {
    name: 'BOM JESUS DA LAPA',
    initials: 'BA',
    code: '2903904',
  },
  {
    name: 'BOM JESUS DA PENHA',
    initials: 'MG',
    code: '3107604',
  },
  {
    name: 'BOM JESUS DA SERRA',
    initials: 'BA',
    code: '2903953',
  },
  {
    name: 'BOM JESUS DAS SELVAS',
    initials: 'MA',
    code: '2102036',
  },
  {
    name: 'BOM JESUS DO AMPARO',
    initials: 'MG',
    code: '3107703',
  },
  {
    name: 'BOM JESUS DO ARAGUAIA',
    initials: 'MT',
    code: '5101852',
  },
  {
    name: 'BOM JESUS DO GALHO',
    initials: 'MG',
    code: '3107802',
  },
  {
    name: 'BOM JESUS DO ITABAPOANA',
    initials: 'RJ',
    code: '3300605',
  },
  {
    name: 'BOM JESUS DO NORTE',
    initials: 'ES',
    code: '3201100',
  },
  {
    name: 'BOM JESUS DO OESTE',
    initials: 'SC',
    code: '4202578',
  },
  {
    name: 'BOM JESUS DOS PERDÕES',
    initials: 'SP',
    code: '3507100',
  },
  {
    name: 'BOM JESUS DO SUL',
    initials: 'PR',
    code: '4103156',
  },
  {
    name: 'BOM JESUS DO TOCANTINS',
    initials: 'TO',
    code: '1703305',
  },
  {
    name: 'BOM JESUS DO TOCANTINS',
    initials: 'PA',
    code: '1501576',
  },
  {
    name: 'BOM LUGAR',
    initials: 'MA',
    code: '2102077',
  },
  {
    name: 'BOM PRINCÍPIO',
    initials: 'RS',
    code: '4302352',
  },
  {
    name: 'BOM PRINCÍPIO DO PIAUÍ',
    initials: 'PI',
    code: '2201919',
  },
  {
    name: 'BOM PROGRESSO',
    initials: 'RS',
    code: '4302378',
  },
  {
    name: 'BOM REPOUSO',
    initials: 'MG',
    code: '3107901',
  },
  {
    name: 'BOM RETIRO',
    initials: 'SC',
    code: '4202602',
  },
  {
    name: 'BOM RETIRO DO SUL',
    initials: 'RS',
    code: '4302402',
  },
  {
    name: 'BOM SUCESSO',
    initials: 'PR',
    code: '4103206',
  },
  {
    name: 'BOM SUCESSO',
    initials: 'PB',
    code: '2502300',
  },
  {
    name: 'BOM SUCESSO',
    initials: 'MG',
    code: '3108008',
  },
  {
    name: 'BOM SUCESSO DE ITARARÉ',
    initials: 'SP',
    code: '3507159',
  },
  {
    name: 'BOM SUCESSO DO SUL',
    initials: 'PR',
    code: '4103222',
  },
  {
    name: 'BONFIM',
    initials: 'RR',
    code: '1400159',
  },
  {
    name: 'BONFIM',
    initials: 'MG',
    code: '3108107',
  },
  {
    name: 'BONFIM DO PIAUÍ',
    initials: 'PI',
    code: '2201929',
  },
  {
    name: 'BONFINÓPOLIS',
    initials: 'GO',
    code: '5203559',
  },
  {
    name: 'BONFINÓPOLIS DE MINAS',
    initials: 'MG',
    code: '3108206',
  },
  {
    name: 'BONINAL',
    initials: 'BA',
    code: '2904001',
  },
  {
    name: 'BONITO',
    initials: 'MS',
    code: '5002209',
  },
  {
    name: 'BONITO',
    initials: 'BA',
    code: '2904050',
  },
  {
    name: 'BONITO',
    initials: 'PA',
    code: '1501600',
  },
  {
    name: 'BONITO',
    initials: 'PE',
    code: '2602308',
  },
  {
    name: 'BONITO DE MINAS',
    initials: 'MG',
    code: '3108255',
  },
  {
    name: 'BONITO DE SANTA FÉ',
    initials: 'PB',
    code: '2502409',
  },
  {
    name: 'BONÓPOLIS',
    initials: 'GO',
    code: '5203575',
  },
  {
    name: 'BOQUEIRÃO',
    initials: 'PB',
    code: '2502508',
  },
  {
    name: 'BOQUEIRÃO DO LEÃO',
    initials: 'RS',
    code: '4302451',
  },
  {
    name: 'BOQUEIRÃO DO PIAUÍ',
    initials: 'PI',
    code: '2201945',
  },
  {
    name: 'BOQUIM',
    initials: 'SE',
    code: '2800670',
  },
  {
    name: 'BOQUIRA',
    initials: 'BA',
    code: '2904100',
  },
  {
    name: 'BORÁ',
    initials: 'SP',
    code: '3507209',
  },
  {
    name: 'BORACÉIA',
    initials: 'SP',
    code: '3507308',
  },
  {
    name: 'BORBA',
    initials: 'AM',
    code: '1300805',
  },
  {
    name: 'BORBOREMA',
    initials: 'SP',
    code: '3507407',
  },
  {
    name: 'BORBOREMA',
    initials: 'PB',
    code: '2502706',
  },
  {
    name: 'BORDA DA MATA',
    initials: 'MG',
    code: '3108305',
  },
  {
    name: 'BOREBI',
    initials: 'SP',
    code: '3507456',
  },
  {
    name: 'BORRAZÓPOLIS',
    initials: 'PR',
    code: '4103305',
  },
  {
    name: 'BOSSOROCA',
    initials: 'RS',
    code: '4302501',
  },
  {
    name: 'BOTELHOS',
    initials: 'MG',
    code: '3108404',
  },
  {
    name: 'BOTUCATU',
    initials: 'SP',
    code: '3507506',
  },
  {
    name: 'BOTUMIRIM',
    initials: 'MG',
    code: '3108503',
  },
  {
    name: 'BOTUPORÃ',
    initials: 'BA',
    code: '2904209',
  },
  {
    name: 'BOTUVERÁ',
    initials: 'SC',
    code: '4202701',
  },
  {
    name: 'BOZANO',
    initials: 'RS',
    code: '4302584',
  },
  {
    name: 'BRAÇO DO NORTE',
    initials: 'SC',
    code: '4202800',
  },
  {
    name: 'BRAÇO DO TROMBUDO',
    initials: 'SC',
    code: '4202859',
  },
  {
    name: 'BRAGA',
    initials: 'RS',
    code: '4302600',
  },
  {
    name: 'BRAGANÇA',
    initials: 'PA',
    code: '1501709',
  },
  {
    name: 'BRAGANÇA PAULISTA',
    initials: 'SP',
    code: '3507605',
  },
  {
    name: 'BRAGANEY',
    initials: 'PR',
    code: '4103354',
  },
  {
    name: 'BRANQUINHA',
    initials: 'AL',
    code: '2701100',
  },
  {
    name: 'BRASILÂNDIA',
    initials: 'MS',
    code: '5002308',
  },
  {
    name: 'BRASILÂNDIA DE MINAS',
    initials: 'MG',
    code: '3108552',
  },
  {
    name: 'BRASILÂNDIA DO SUL',
    initials: 'PR',
    code: '4103370',
  },
  {
    name: 'BRASILÂNDIA DO TOCANTINS',
    initials: 'TO',
    code: '1703602',
  },
  {
    name: 'BRASILÉIA',
    initials: 'AC',
    code: '1200104',
  },
  {
    name: 'BRASILEIRA',
    initials: 'PI',
    code: '2201960',
  },
  {
    name: 'BRASÍLIA',
    initials: 'DF',
    code: '5300108',
  },
  {
    name: 'BRASÍLIA DE MINAS',
    initials: 'MG',
    code: '3108602',
  },
  {
    name: 'BRASIL NOVO',
    initials: 'PA',
    code: '1501725',
  },
  {
    name: 'BRASNORTE',
    initials: 'MT',
    code: '5101902',
  },
  {
    name: 'BRASÓPOLIS',
    initials: 'MG',
    code: '3108909',
  },
  {
    name: 'BRÁS PIRES',
    initials: 'MG',
    code: '3108701',
  },
  {
    name: 'BRAÚNA',
    initials: 'SP',
    code: '3507704',
  },
  {
    name: 'BRAÚNAS',
    initials: 'MG',
    code: '3108800',
  },
  {
    name: 'BRAZABRANTES',
    initials: 'GO',
    code: '5203609',
  },
  {
    name: 'BREJÃO',
    initials: 'PE',
    code: '2602407',
  },
  {
    name: 'BREJETUBA',
    initials: 'ES',
    code: '3201159',
  },
  {
    name: 'BREJINHO',
    initials: 'RN',
    code: '2401800',
  },
  {
    name: 'BREJINHO',
    initials: 'PE',
    code: '2602506',
  },
  {
    name: 'BREJINHO DE NAZARÉ',
    initials: 'TO',
    code: '1703701',
  },
  {
    name: 'BREJO',
    initials: 'MA',
    code: '2102101',
  },
  {
    name: 'BREJO ALEGRE',
    initials: 'SP',
    code: '3507753',
  },
  {
    name: 'BREJO DA MADRE DE DEUS',
    initials: 'PE',
    code: '2602605',
  },
  {
    name: 'BREJO DE AREIA',
    initials: 'MA',
    code: '2102150',
  },
  {
    name: 'BREJO DO CRUZ',
    initials: 'PB',
    code: '2502805',
  },
  {
    name: 'BREJO DO PIAUÍ',
    initials: 'PI',
    code: '2201988',
  },
  {
    name: 'BREJO DOS SANTOS',
    initials: 'PB',
    code: '2502904',
  },
  {
    name: 'BREJÕES',
    initials: 'BA',
    code: '2904308',
  },
  {
    name: 'BREJO GRANDE',
    initials: 'SE',
    code: '2800704',
  },
  {
    name: 'BREJO GRANDE DO ARAGUAIA',
    initials: 'PA',
    code: '1501758',
  },
  {
    name: 'BREJOLÂNDIA',
    initials: 'BA',
    code: '2904407',
  },
  {
    name: 'BREJO SANTO',
    initials: 'CE',
    code: '2302503',
  },
  {
    name: 'BREU BRANCO',
    initials: 'PA',
    code: '1501782',
  },
  {
    name: 'BREVES',
    initials: 'PA',
    code: '1501808',
  },
  {
    name: 'BRITÂNIA',
    initials: 'GO',
    code: '5203807',
  },
  {
    name: 'BROCHIER',
    initials: 'RS',
    code: '4302659',
  },
  {
    name: 'BRODOWSKI',
    initials: 'SP',
    code: '3507803',
  },
  {
    name: 'BROTAS',
    initials: 'SP',
    code: '3507902',
  },
  {
    name: 'BROTAS DE MACAÚBAS',
    initials: 'BA',
    code: '2904506',
  },
  {
    name: 'BRUMADINHO',
    initials: 'MG',
    code: '3109006',
  },
  {
    name: 'BRUMADO',
    initials: 'BA',
    code: '2904605',
  },
  {
    name: 'BRUNÓPOLIS',
    initials: 'SC',
    code: '4202875',
  },
  {
    name: 'BRUSQUE',
    initials: 'SC',
    code: '4202909',
  },
  {
    name: 'BUENO BRANDÃO',
    initials: 'MG',
    code: '3109105',
  },
  {
    name: 'BUENÓPOLIS',
    initials: 'MG',
    code: '3109204',
  },
  {
    name: 'BUENOS AIRES',
    initials: 'PE',
    code: '2602704',
  },
  {
    name: 'BUERAREMA',
    initials: 'BA',
    code: '2904704',
  },
  {
    name: 'BUGRE',
    initials: 'MG',
    code: '3109253',
  },
  {
    name: 'BUÍQUE',
    initials: 'PE',
    code: '2602803',
  },
  {
    name: 'BUJARI',
    initials: 'AC',
    code: '1200138',
  },
  {
    name: 'BUJARU',
    initials: 'PA',
    code: '1501907',
  },
  {
    name: 'BURI',
    initials: 'SP',
    code: '3508009',
  },
  {
    name: 'BURITAMA',
    initials: 'SP',
    code: '3508108',
  },
  {
    name: 'BURITI',
    initials: 'MA',
    code: '2102200',
  },
  {
    name: 'BURITI ALEGRE',
    initials: 'GO',
    code: '5203906',
  },
  {
    name: 'BURITI BRAVO',
    initials: 'MA',
    code: '2102309',
  },
  {
    name: 'BURITICUPU',
    initials: 'MA',
    code: '2102325',
  },
  {
    name: 'BURITI DE GOIÁS',
    initials: 'GO',
    code: '5203939',
  },
  {
    name: 'BURITI DOS LOPES',
    initials: 'PI',
    code: '2202000',
  },
  {
    name: 'BURITI DOS MONTES',
    initials: 'PI',
    code: '2202026',
  },
  {
    name: 'BURITI DO TOCANTINS',
    initials: 'TO',
    code: '1703800',
  },
  {
    name: 'BURITINÓPOLIS',
    initials: 'GO',
    code: '5203962',
  },
  {
    name: 'BURITIRAMA',
    initials: 'BA',
    code: '2904753',
  },
  {
    name: 'BURITIRANA',
    initials: 'MA',
    code: '2102358',
  },
  {
    name: 'BURITIS',
    initials: 'RO',
    code: '1100452',
  },
  {
    name: 'BURITIS',
    initials: 'MG',
    code: '3109303',
  },
  {
    name: 'BURITIZAL',
    initials: 'SP',
    code: '3508207',
  },
  {
    name: 'BURITIZEIRO',
    initials: 'MG',
    code: '3109402',
  },
  {
    name: 'BUTIÁ',
    initials: 'RS',
    code: '4302709',
  },
  {
    name: 'CAAPIRANGA',
    initials: 'AM',
    code: '1300839',
  },
  {
    name: 'CAAPORÃ',
    initials: 'PB',
    code: '2503001',
  },
  {
    name: 'CAARAPÓ',
    initials: 'MS',
    code: '5002407',
  },
  {
    name: 'CAATIBA',
    initials: 'BA',
    code: '2904803',
  },
  {
    name: 'CABACEIRAS',
    initials: 'PB',
    code: '2503100',
  },
  {
    name: 'CABACEIRAS DO PARAGUAÇU',
    initials: 'BA',
    code: '2904852',
  },
  {
    name: 'CABECEIRA GRANDE',
    initials: 'MG',
    code: '3109451',
  },
  {
    name: 'CABECEIRAS',
    initials: 'GO',
    code: '5204003',
  },
  {
    name: 'CABECEIRAS DO PIAUÍ',
    initials: 'PI',
    code: '2202059',
  },
  {
    name: 'CABEDELO',
    initials: 'PB',
    code: '2503209',
  },
  {
    name: 'CABIXI',
    initials: 'RO',
    code: '1100031',
  },
  {
    name: 'CABO DE SANTO AGOSTINHO',
    initials: 'PE',
    code: '2602902',
  },
  {
    name: 'CABO FRIO',
    initials: 'RJ',
    code: '3300704',
  },
  {
    name: 'CABO VERDE',
    initials: 'MG',
    code: '3109501',
  },
  {
    name: 'CABRÁLIA PAULISTA',
    initials: 'SP',
    code: '3508306',
  },
  {
    name: 'CABREÚVA',
    initials: 'SP',
    code: '3508405',
  },
  {
    name: 'CABROBÓ',
    initials: 'PE',
    code: '2603009',
  },
  {
    name: 'CAÇADOR',
    initials: 'SC',
    code: '4203006',
  },
  {
    name: 'CAÇAPAVA',
    initials: 'SP',
    code: '3508504',
  },
  {
    name: 'CAÇAPAVA DO SUL',
    initials: 'RS',
    code: '4302808',
  },
  {
    name: 'CACAULÂNDIA',
    initials: 'RO',
    code: '1100601',
  },
  {
    name: 'CACEQUI',
    initials: 'RS',
    code: '4302907',
  },
  {
    name: 'CÁCERES',
    initials: 'MT',
    code: '5102504',
  },
  {
    name: 'CACHOEIRA',
    initials: 'BA',
    code: '2904902',
  },
  {
    name: 'CACHOEIRA ALTA',
    initials: 'GO',
    code: '5204102',
  },
  {
    name: 'CACHOEIRA DA PRATA',
    initials: 'MG',
    code: '3109600',
  },
  {
    name: 'CACHOEIRA DE GOIÁS',
    initials: 'GO',
    code: '5204201',
  },
  {
    name: 'CACHOEIRA DE MINAS',
    initials: 'MG',
    code: '3109709',
  },
  {
    name: 'CACHOEIRA DE PAJEÚ',
    initials: 'MG',
    code: '3102704',
  },
  {
    name: 'CACHOEIRA DO ARARI',
    initials: 'PA',
    code: '1502004',
  },
  {
    name: 'CACHOEIRA DO PIRIÁ',
    initials: 'PA',
    code: '1501956',
  },
  {
    name: 'CACHOEIRA DOS ÍNDIOS',
    initials: 'PB',
    code: '2503308',
  },
  {
    name: 'CACHOEIRA DO SUL',
    initials: 'RS',
    code: '4303004',
  },
  {
    name: 'CACHOEIRA DOURADA',
    initials: 'MG',
    code: '3109808',
  },
  {
    name: 'CACHOEIRA DOURADA',
    initials: 'GO',
    code: '5204250',
  },
  {
    name: 'CACHOEIRA GRANDE',
    initials: 'MA',
    code: '2102374',
  },
  {
    name: 'CACHOEIRA PAULISTA',
    initials: 'SP',
    code: '3508603',
  },
  {
    name: 'CACHOEIRAS DE MACACU',
    initials: 'RJ',
    code: '3300803',
  },
  {
    name: 'CACHOEIRINHA',
    initials: 'TO',
    code: '1703826',
  },
  {
    name: 'CACHOEIRINHA',
    initials: 'RS',
    code: '4303103',
  },
  {
    name: 'CACHOEIRINHA',
    initials: 'PE',
    code: '2603108',
  },
  {
    name: 'CACHOEIRO DE ITAPEMIRIM',
    initials: 'ES',
    code: '3201209',
  },
  {
    name: 'CACIMBA DE AREIA',
    initials: 'PB',
    code: '2503407',
  },
  {
    name: 'CACIMBA DE DENTRO',
    initials: 'PB',
    code: '2503506',
  },
  {
    name: 'CACIMBAS',
    initials: 'PB',
    code: '2503555',
  },
  {
    name: 'CACIMBINHAS',
    initials: 'AL',
    code: '2701209',
  },
  {
    name: 'CACIQUE DOBLE',
    initials: 'RS',
    code: '4303202',
  },
  {
    name: 'CACOAL',
    initials: 'RO',
    code: '1100049',
  },
  {
    name: 'CACONDE',
    initials: 'SP',
    code: '3508702',
  },
  {
    name: 'CAÇU',
    initials: 'GO',
    code: '5204300',
  },
  {
    name: 'CACULÉ',
    initials: 'BA',
    code: '2905008',
  },
  {
    name: 'CAÉM',
    initials: 'BA',
    code: '2905107',
  },
  {
    name: 'CAETANÓPOLIS',
    initials: 'MG',
    code: '3109907',
  },
  {
    name: 'CAETANOS',
    initials: 'BA',
    code: '2905156',
  },
  {
    name: 'CAETÉ',
    initials: 'MG',
    code: '3110004',
  },
  {
    name: 'CAETÉS',
    initials: 'PE',
    code: '2603207',
  },
  {
    name: 'CAETITÉ',
    initials: 'BA',
    code: '2905206',
  },
  {
    name: 'CAFARNAUM',
    initials: 'BA',
    code: '2905305',
  },
  {
    name: 'CAFEARA',
    initials: 'PR',
    code: '4103404',
  },
  {
    name: 'CAFELÂNDIA',
    initials: 'PR',
    code: '4103453',
  },
  {
    name: 'CAFELÂNDIA',
    initials: 'SP',
    code: '3508801',
  },
  {
    name: 'CAFEZAL DO SUL',
    initials: 'PR',
    code: '4103479',
  },
  {
    name: 'CAIABU',
    initials: 'SP',
    code: '3508900',
  },
  {
    name: 'CAIANA',
    initials: 'MG',
    code: '3110103',
  },
  {
    name: 'CAIAPÔNIA',
    initials: 'GO',
    code: '5204409',
  },
  {
    name: 'CAIBATÉ',
    initials: 'RS',
    code: '4303301',
  },
  {
    name: 'CAIBI',
    initials: 'SC',
    code: '4203105',
  },
  {
    name: 'CAIÇARA',
    initials: 'RS',
    code: '4303400',
  },
  {
    name: 'CAIÇARA',
    initials: 'PB',
    code: '2503605',
  },
  {
    name: 'CAIÇARA DO NORTE',
    initials: 'RN',
    code: '2401859',
  },
  {
    name: 'CAIÇARA DO RIO DO VENTO',
    initials: 'RN',
    code: '2401909',
  },
  {
    name: 'CAICÓ',
    initials: 'RN',
    code: '2402006',
  },
  {
    name: 'CAIEIRAS',
    initials: 'SP',
    code: '3509007',
  },
  {
    name: 'CAIRU',
    initials: 'BA',
    code: '2905404',
  },
  {
    name: 'CAIUÁ',
    initials: 'SP',
    code: '3509106',
  },
  {
    name: 'CAJAMAR',
    initials: 'SP',
    code: '3509205',
  },
  {
    name: 'CAJAPIÓ',
    initials: 'MA',
    code: '2102408',
  },
  {
    name: 'CAJARI',
    initials: 'MA',
    code: '2102507',
  },
  {
    name: 'CAJATI',
    initials: 'SP',
    code: '3509254',
  },
  {
    name: 'CAJAZEIRAS',
    initials: 'PB',
    code: '2503704',
  },
  {
    name: 'CAJAZEIRAS DO PIAUÍ',
    initials: 'PI',
    code: '2202075',
  },
  {
    name: 'CAJAZEIRINHAS',
    initials: 'PB',
    code: '2503753',
  },
  {
    name: 'CAJOBI',
    initials: 'SP',
    code: '3509304',
  },
  {
    name: 'CAJUEIRO',
    initials: 'AL',
    code: '2701308',
  },
  {
    name: 'CAJUEIRO DA PRAIA',
    initials: 'PI',
    code: '2202083',
  },
  {
    name: 'CAJURI',
    initials: 'MG',
    code: '3110202',
  },
  {
    name: 'CAJURU',
    initials: 'SP',
    code: '3509403',
  },
  {
    name: 'CALÇADO',
    initials: 'PE',
    code: '2603306',
  },
  {
    name: 'CALÇOENE',
    initials: 'AP',
    code: '1600204',
  },
  {
    name: 'CALDAS',
    initials: 'MG',
    code: '3110301',
  },
  {
    name: 'CALDAS BRANDÃO',
    initials: 'PB',
    code: '2503803',
  },
  {
    name: 'CALDAS NOVAS',
    initials: 'GO',
    code: '5204508',
  },
  {
    name: 'CALDAZINHA',
    initials: 'GO',
    code: '5204557',
  },
  {
    name: 'CALDEIRÃO GRANDE',
    initials: 'BA',
    code: '2905503',
  },
  {
    name: 'CALDEIRÃO GRANDE DO PIAUÍ',
    initials: 'PI',
    code: '2202091',
  },
  {
    name: 'CALIFÓRNIA',
    initials: 'PR',
    code: '4103503',
  },
  {
    name: 'CALMON',
    initials: 'SC',
    code: '4203154',
  },
  {
    name: 'CALUMBI',
    initials: 'PE',
    code: '2603405',
  },
  {
    name: 'CAMACAN',
    initials: 'BA',
    code: '2905602',
  },
  {
    name: 'CAMAÇARI',
    initials: 'BA',
    code: '2905701',
  },
  {
    name: 'CAMACHO',
    initials: 'MG',
    code: '3110400',
  },
  {
    name: 'CAMALAÚ',
    initials: 'PB',
    code: '2503902',
  },
  {
    name: 'CAMAMU',
    initials: 'BA',
    code: '2905800',
  },
  {
    name: 'CAMANDUCAIA',
    initials: 'MG',
    code: '3110509',
  },
  {
    name: 'CAMAPUÃ',
    initials: 'MS',
    code: '5002605',
  },
  {
    name: 'CAMAQUÃ',
    initials: 'RS',
    code: '4303509',
  },
  {
    name: 'CAMARAGIBE',
    initials: 'PE',
    code: '2603454',
  },
  {
    name: 'CAMARGO',
    initials: 'RS',
    code: '4303558',
  },
  {
    name: 'CAMBARÁ',
    initials: 'PR',
    code: '4103602',
  },
  {
    name: 'CAMBARÁ DO SUL',
    initials: 'RS',
    code: '4303608',
  },
  {
    name: 'CAMBÉ',
    initials: 'PR',
    code: '4103701',
  },
  {
    name: 'CAMBIRA',
    initials: 'PR',
    code: '4103800',
  },
  {
    name: 'CAMBORIÚ',
    initials: 'SC',
    code: '4203204',
  },
  {
    name: 'CAMBUCI',
    initials: 'RJ',
    code: '3300902',
  },
  {
    name: 'CAMBUÍ',
    initials: 'MG',
    code: '3110608',
  },
  {
    name: 'CAMBUQUIRA',
    initials: 'MG',
    code: '3110707',
  },
  {
    name: 'CAMETÁ',
    initials: 'PA',
    code: '1502103',
  },
  {
    name: 'CAMOCIM',
    initials: 'CE',
    code: '2302602',
  },
  {
    name: 'CAMOCIM DE SÃO FÉLIX',
    initials: 'PE',
    code: '2603504',
  },
  {
    name: 'CAMPANÁRIO',
    initials: 'MG',
    code: '3110806',
  },
  {
    name: 'CAMPANHA',
    initials: 'MG',
    code: '3110905',
  },
  {
    name: 'CAMPESTRE',
    initials: 'MG',
    code: '3111002',
  },
  {
    name: 'CAMPESTRE',
    initials: 'AL',
    code: '2701357',
  },
  {
    name: 'CAMPESTRE DA SERRA',
    initials: 'RS',
    code: '4303673',
  },
  {
    name: 'CAMPESTRE DE GOIÁS',
    initials: 'GO',
    code: '5204607',
  },
  {
    name: 'CAMPESTRE DO MARANHÃO',
    initials: 'MA',
    code: '2102556',
  },
  {
    name: 'CAMPINAÇU',
    initials: 'GO',
    code: '5204656',
  },
  {
    name: 'CAMPINA DA LAGOA',
    initials: 'PR',
    code: '4103909',
  },
  {
    name: 'CAMPINA DAS MISSÕES',
    initials: 'RS',
    code: '4303707',
  },
  {
    name: 'CAMPINA DO MONTE ALEGRE',
    initials: 'SP',
    code: '3509452',
  },
  {
    name: 'CAMPINA DO SIMÃO',
    initials: 'PR',
    code: '4103958',
  },
  {
    name: 'CAMPINA GRANDE',
    initials: 'PB',
    code: '2504009',
  },
  {
    name: 'CAMPINA GRANDE DO SUL',
    initials: 'PR',
    code: '4104006',
  },
  {
    name: 'CAMPINÁPOLIS',
    initials: 'MT',
    code: '5102603',
  },
  {
    name: 'CAMPINAS',
    initials: 'SP',
    code: '3509502',
  },
  {
    name: 'CAMPINAS DO PIAUÍ',
    initials: 'PI',
    code: '2202109',
  },
  {
    name: 'CAMPINAS DO SUL',
    initials: 'RS',
    code: '4303806',
  },
  {
    name: 'CAMPINA VERDE',
    initials: 'MG',
    code: '3111101',
  },
  {
    name: 'CAMPINORTE',
    initials: 'GO',
    code: '5204706',
  },
  {
    name: 'CAMPO ALEGRE',
    initials: 'AL',
    code: '2701407',
  },
  {
    name: 'CAMPO ALEGRE',
    initials: 'SC',
    code: '4203303',
  },
  {
    name: 'CAMPO ALEGRE DE GOIÁS',
    initials: 'GO',
    code: '5204805',
  },
  {
    name: 'CAMPO ALEGRE DE LOURDES',
    initials: 'BA',
    code: '2905909',
  },
  {
    name: 'CAMPO ALEGRE DO FIDALGO',
    initials: 'PI',
    code: '2202117',
  },
  {
    name: 'CAMPO AZUL',
    initials: 'MG',
    code: '3111150',
  },
  {
    name: 'CAMPO BELO',
    initials: 'MG',
    code: '3111200',
  },
  {
    name: 'CAMPO BELO DO SUL',
    initials: 'SC',
    code: '4203402',
  },
  {
    name: 'CAMPO BOM',
    initials: 'RS',
    code: '4303905',
  },
  {
    name: 'CAMPO BONITO',
    initials: 'PR',
    code: '4104055',
  },
  {
    name: 'CAMPO DO BRITO',
    initials: 'SE',
    code: '2801009',
  },
  {
    name: 'CAMPO DO MEIO',
    initials: 'MG',
    code: '3111309',
  },
  {
    name: 'CAMPO DO TENENTE',
    initials: 'PR',
    code: '4104105',
  },
  {
    name: 'CAMPO ERÊ',
    initials: 'SC',
    code: '4203501',
  },
  {
    name: 'CAMPO FLORIDO',
    initials: 'MG',
    code: '3111408',
  },
  {
    name: 'CAMPO FORMOSO',
    initials: 'BA',
    code: '2906006',
  },
  {
    name: 'CAMPO GRANDE',
    initials: 'RN',
    code: '2401305',
  },
  {
    name: 'CAMPO GRANDE',
    initials: 'MS',
    code: '5002704',
  },
  {
    name: 'CAMPO GRANDE',
    initials: 'AL',
    code: '2701506',
  },
  {
    name: 'CAMPO GRANDE DO PIAUÍ',
    initials: 'PI',
    code: '2202133',
  },
  {
    name: 'CAMPO LARGO',
    initials: 'PR',
    code: '4104204',
  },
  {
    name: 'CAMPO LARGO DO PIAUÍ',
    initials: 'PI',
    code: '2202174',
  },
  {
    name: 'CAMPO LIMPO DE GOIÁS',
    initials: 'GO',
    code: '5204854',
  },
  {
    name: 'CAMPO LIMPO PAULISTA',
    initials: 'SP',
    code: '3509601',
  },
  {
    name: 'CAMPO MAGRO',
    initials: 'PR',
    code: '4104253',
  },
  {
    name: 'CAMPO MAIOR',
    initials: 'PI',
    code: '2202208',
  },
  {
    name: 'CAMPO MOURÃO',
    initials: 'PR',
    code: '4104303',
  },
  {
    name: 'CAMPO NOVO',
    initials: 'RS',
    code: '4304002',
  },
  {
    name: 'CAMPO NOVO DE RONDÔNIA',
    initials: 'RO',
    code: '1100700',
  },
  {
    name: 'CAMPO NOVO DO PARECIS',
    initials: 'MT',
    code: '5102637',
  },
  {
    name: 'CAMPO REDONDO',
    initials: 'RN',
    code: '2402105',
  },
  {
    name: 'CAMPOS ALTOS',
    initials: 'MG',
    code: '3111507',
  },
  {
    name: 'CAMPOS BELOS',
    initials: 'GO',
    code: '5204904',
  },
  {
    name: 'CAMPOS BORGES',
    initials: 'RS',
    code: '4304101',
  },
  {
    name: 'CAMPOS DE JÚLIO',
    initials: 'MT',
    code: '5102686',
  },
  {
    name: 'CAMPOS DO JORDÃO',
    initials: 'SP',
    code: '3509700',
  },
  {
    name: 'CAMPOS DOS GOYTACAZES',
    initials: 'RJ',
    code: '3301009',
  },
  {
    name: 'CAMPOS GERAIS',
    initials: 'MG',
    code: '3111606',
  },
  {
    name: 'CAMPOS LINDOS',
    initials: 'TO',
    code: '1703842',
  },
  {
    name: 'CAMPOS NOVOS',
    initials: 'SC',
    code: '4203600',
  },
  {
    name: 'CAMPOS NOVOS PAULISTA',
    initials: 'SP',
    code: '3509809',
  },
  {
    name: 'CAMPOS SALES',
    initials: 'CE',
    code: '2302701',
  },
  {
    name: 'CAMPOS VERDES',
    initials: 'GO',
    code: '5204953',
  },
  {
    name: 'CAMPO VERDE',
    initials: 'MT',
    code: '5102678',
  },
  {
    name: 'CAMUTANGA',
    initials: 'PE',
    code: '2603603',
  },
  {
    name: 'CANAÃ',
    initials: 'MG',
    code: '3111705',
  },
  {
    name: 'CANAÃ DOS CARAJÁS',
    initials: 'PA',
    code: '1502152',
  },
  {
    name: 'CANABRAVA DO NORTE',
    initials: 'MT',
    code: '5102694',
  },
  {
    name: 'CANANÉIA',
    initials: 'SP',
    code: '3509908',
  },
  {
    name: 'CANAPI',
    initials: 'AL',
    code: '2701605',
  },
  {
    name: 'CANÁPOLIS',
    initials: 'BA',
    code: '2906105',
  },
  {
    name: 'CANÁPOLIS',
    initials: 'MG',
    code: '3111804',
  },
  {
    name: 'CANARANA',
    initials: 'BA',
    code: '2906204',
  },
  {
    name: 'CANARANA',
    initials: 'MT',
    code: '5102702',
  },
  {
    name: 'CANAS',
    initials: 'SP',
    code: '3509957',
  },
  {
    name: 'CANA VERDE',
    initials: 'MG',
    code: '3111903',
  },
  {
    name: 'CANAVIEIRA',
    initials: 'PI',
    code: '2202251',
  },
  {
    name: 'CANAVIEIRAS',
    initials: 'BA',
    code: '2906303',
  },
  {
    name: 'CANDEAL',
    initials: 'BA',
    code: '2906402',
  },
  {
    name: 'CANDEIAS',
    initials: 'BA',
    code: '2906501',
  },
  {
    name: 'CANDEIAS',
    initials: 'MG',
    code: '3112000',
  },
  {
    name: 'CANDEIAS DO JAMARI',
    initials: 'RO',
    code: '1100809',
  },
  {
    name: 'CANDELÁRIA',
    initials: 'RS',
    code: '4304200',
  },
  {
    name: 'CANDIBA',
    initials: 'BA',
    code: '2906600',
  },
  {
    name: 'CÂNDIDO DE ABREU',
    initials: 'PR',
    code: '4104402',
  },
  {
    name: 'CÂNDIDO GODÓI',
    initials: 'RS',
    code: '4304309',
  },
  {
    name: 'CÂNDIDO MENDES',
    initials: 'MA',
    code: '2102606',
  },
  {
    name: 'CÂNDIDO MOTA',
    initials: 'SP',
    code: '3510005',
  },
  {
    name: 'CÂNDIDO RODRIGUES',
    initials: 'SP',
    code: '3510104',
  },
  {
    name: 'CÂNDIDO SALES',
    initials: 'BA',
    code: '2906709',
  },
  {
    name: 'CANDIOTA',
    initials: 'RS',
    code: '4304358',
  },
  {
    name: 'CANDÓI',
    initials: 'PR',
    code: '4104428',
  },
  {
    name: 'CANELA',
    initials: 'RS',
    code: '4304408',
  },
  {
    name: 'CANELINHA',
    initials: 'SC',
    code: '4203709',
  },
  {
    name: 'CANGUARETAMA',
    initials: 'RN',
    code: '2402204',
  },
  {
    name: 'CANGUÇU',
    initials: 'RS',
    code: '4304507',
  },
  {
    name: 'CANHOBA',
    initials: 'SE',
    code: '2801108',
  },
  {
    name: 'CANHOTINHO',
    initials: 'PE',
    code: '2603702',
  },
  {
    name: 'CANINDÉ',
    initials: 'CE',
    code: '2302800',
  },
  {
    name: 'CANINDÉ DE SÃO FRANCISCO',
    initials: 'SE',
    code: '2801207',
  },
  {
    name: 'CANITAR',
    initials: 'SP',
    code: '3510153',
  },
  {
    name: 'CANOAS',
    initials: 'RS',
    code: '4304606',
  },
  {
    name: 'CANOINHAS',
    initials: 'SC',
    code: '4203808',
  },
  {
    name: 'CANSANÇÃO',
    initials: 'BA',
    code: '2906808',
  },
  {
    name: 'CANTÁ',
    initials: 'RR',
    code: '1400175',
  },
  {
    name: 'CANTAGALO',
    initials: 'PR',
    code: '4104451',
  },
  {
    name: 'CANTAGALO',
    initials: 'MG',
    code: '3112059',
  },
  {
    name: 'CANTAGALO',
    initials: 'RJ',
    code: '3301108',
  },
  {
    name: 'CANTANHEDE',
    initials: 'MA',
    code: '2102705',
  },
  {
    name: 'CANTO DO BURITI',
    initials: 'PI',
    code: '2202307',
  },
  {
    name: 'CANUDOS',
    initials: 'BA',
    code: '2906824',
  },
  {
    name: 'CANUDOS DO VALE',
    initials: 'RS',
    code: '4304614',
  },
  {
    name: 'CANUTAMA',
    initials: 'AM',
    code: '1300904',
  },
  {
    name: 'CAPANEMA',
    initials: 'PR',
    code: '4104501',
  },
  {
    name: 'CAPANEMA',
    initials: 'PA',
    code: '1502202',
  },
  {
    name: 'CAPÃO ALTO',
    initials: 'SC',
    code: '4203253',
  },
  {
    name: 'CAPÃO BONITO',
    initials: 'SP',
    code: '3510203',
  },
  {
    name: 'CAPÃO BONITO DO SUL',
    initials: 'RS',
    code: '4304622',
  },
  {
    name: 'CAPÃO DA CANOA',
    initials: 'RS',
    code: '4304630',
  },
  {
    name: 'CAPÃO DO CIPÓ',
    initials: 'RS',
    code: '4304655',
  },
  {
    name: 'CAPÃO DO LEÃO',
    initials: 'RS',
    code: '4304663',
  },
  {
    name: 'CAPARAÓ',
    initials: 'MG',
    code: '3112109',
  },
  {
    name: 'CAPELA',
    initials: 'SE',
    code: '2801306',
  },
  {
    name: 'CAPELA',
    initials: 'AL',
    code: '2701704',
  },
  {
    name: 'CAPELA DE SANTANA',
    initials: 'RS',
    code: '4304689',
  },
  {
    name: 'CAPELA DO ALTO',
    initials: 'SP',
    code: '3510302',
  },
  {
    name: 'CAPELA DO ALTO ALEGRE',
    initials: 'BA',
    code: '2906857',
  },
  {
    name: 'CAPELA NOVA',
    initials: 'MG',
    code: '3112208',
  },
  {
    name: 'CAPELINHA',
    initials: 'MG',
    code: '3112307',
  },
  {
    name: 'CAPETINGA',
    initials: 'MG',
    code: '3112406',
  },
  {
    name: 'CAPIM',
    initials: 'PB',
    code: '2504033',
  },
  {
    name: 'CAPIM BRANCO',
    initials: 'MG',
    code: '3112505',
  },
  {
    name: 'CAPIM GROSSO',
    initials: 'BA',
    code: '2906873',
  },
  {
    name: 'CAPINÓPOLIS',
    initials: 'MG',
    code: '3112604',
  },
  {
    name: 'CAPINZAL',
    initials: 'SC',
    code: '4203907',
  },
  {
    name: 'CAPINZAL DO NORTE',
    initials: 'MA',
    code: '2102754',
  },
  {
    name: 'CAPISTRANO',
    initials: 'CE',
    code: '2302909',
  },
  {
    name: 'CAPITÃO',
    initials: 'RS',
    code: '4304697',
  },
  {
    name: 'CAPITÃO ANDRADE',
    initials: 'MG',
    code: '3112653',
  },
  {
    name: 'CAPITÃO DE CAMPOS',
    initials: 'PI',
    code: '2202406',
  },
  {
    name: 'CAPITÃO ENÉAS',
    initials: 'MG',
    code: '3112703',
  },
  {
    name: 'CAPITÃO GERVÁSIO OLIVEIRA',
    initials: 'PI',
    code: '2202455',
  },
  {
    name: 'CAPITÃO LEÔNIDAS MARQUES',
    initials: 'PR',
    code: '4104600',
  },
  {
    name: 'CAPITÃO POÇO',
    initials: 'PA',
    code: '1502301',
  },
  {
    name: 'CAPITÓLIO',
    initials: 'MG',
    code: '3112802',
  },
  {
    name: 'CAPIVARI',
    initials: 'SP',
    code: '3510401',
  },
  {
    name: 'CAPIVARI DE BAIXO',
    initials: 'SC',
    code: '4203956',
  },
  {
    name: 'CAPIVARI DO SUL',
    initials: 'RS',
    code: '4304671',
  },
  {
    name: 'CAPIXABA',
    initials: 'AC',
    code: '1200179',
  },
  {
    name: 'CAPOEIRAS',
    initials: 'PE',
    code: '2603801',
  },
  {
    name: 'CAPUTIRA',
    initials: 'MG',
    code: '3112901',
  },
  {
    name: 'CARAÁ',
    initials: 'RS',
    code: '4304713',
  },
  {
    name: 'CARACARAÍ',
    initials: 'RR',
    code: '1400209',
  },
  {
    name: 'CARACOL',
    initials: 'MS',
    code: '5002803',
  },
  {
    name: 'CARACOL',
    initials: 'PI',
    code: '2202505',
  },
  {
    name: 'CARAGUATATUBA',
    initials: 'SP',
    code: '3510500',
  },
  {
    name: 'CARAÍ',
    initials: 'MG',
    code: '3113008',
  },
  {
    name: 'CARAÍBAS',
    initials: 'BA',
    code: '2906899',
  },
  {
    name: 'CARAMBEÍ',
    initials: 'PR',
    code: '4104659',
  },
  {
    name: 'CARANAÍBA',
    initials: 'MG',
    code: '3113107',
  },
  {
    name: 'CARANDAÍ',
    initials: 'MG',
    code: '3113206',
  },
  {
    name: 'CARANGOLA',
    initials: 'MG',
    code: '3113305',
  },
  {
    name: 'CARAPEBUS',
    initials: 'RJ',
    code: '3300936',
  },
  {
    name: 'CARAPICUÍBA',
    initials: 'SP',
    code: '3510609',
  },
  {
    name: 'CARATINGA',
    initials: 'MG',
    code: '3113404',
  },
  {
    name: 'CARAUARI',
    initials: 'AM',
    code: '1301001',
  },
  {
    name: 'CARAÚBAS',
    initials: 'PB',
    code: '2504074',
  },
  {
    name: 'CARAÚBAS',
    initials: 'RN',
    code: '2402303',
  },
  {
    name: 'CARAÚBAS DO PIAUÍ',
    initials: 'PI',
    code: '2202539',
  },
  {
    name: 'CARAVELAS',
    initials: 'BA',
    code: '2906907',
  },
  {
    name: 'CARAZINHO',
    initials: 'RS',
    code: '4304705',
  },
  {
    name: 'CARBONITA',
    initials: 'MG',
    code: '3113503',
  },
  {
    name: 'CARDEAL DA SILVA',
    initials: 'BA',
    code: '2907004',
  },
  {
    name: 'CARDOSO',
    initials: 'SP',
    code: '3510708',
  },
  {
    name: 'CARDOSO MOREIRA',
    initials: 'RJ',
    code: '3301157',
  },
  {
    name: 'CAREAÇU',
    initials: 'MG',
    code: '3113602',
  },
  {
    name: 'CAREIRO',
    initials: 'AM',
    code: '1301100',
  },
  {
    name: 'CAREIRO DA VÁRZEA',
    initials: 'AM',
    code: '1301159',
  },
  {
    name: 'CARIACICA',
    initials: 'ES',
    code: '3201308',
  },
  {
    name: 'CARIDADE',
    initials: 'CE',
    code: '2303006',
  },
  {
    name: 'CARIDADE DO PIAUÍ',
    initials: 'PI',
    code: '2202554',
  },
  {
    name: 'CARINHANHA',
    initials: 'BA',
    code: '2907103',
  },
  {
    name: 'CARIRA',
    initials: 'SE',
    code: '2801405',
  },
  {
    name: 'CARIRÉ',
    initials: 'CE',
    code: '2303105',
  },
  {
    name: 'CARIRIAÇU',
    initials: 'CE',
    code: '2303204',
  },
  {
    name: 'CARIRI DO TOCANTINS',
    initials: 'TO',
    code: '1703867',
  },
  {
    name: 'CARIÚS',
    initials: 'CE',
    code: '2303303',
  },
  {
    name: 'CARLINDA',
    initials: 'MT',
    code: '5102793',
  },
  {
    name: 'CARLÓPOLIS',
    initials: 'PR',
    code: '4104709',
  },
  {
    name: 'CARLOS BARBOSA',
    initials: 'RS',
    code: '4304804',
  },
  {
    name: 'CARLOS CHAGAS',
    initials: 'MG',
    code: '3113701',
  },
  {
    name: 'CARLOS GOMES',
    initials: 'RS',
    code: '4304853',
  },
  {
    name: 'CARMÉSIA',
    initials: 'MG',
    code: '3113800',
  },
  {
    name: 'CARMO',
    initials: 'RJ',
    code: '3301207',
  },
  {
    name: 'CARMO DA CACHOEIRA',
    initials: 'MG',
    code: '3113909',
  },
  {
    name: 'CARMO DA MATA',
    initials: 'MG',
    code: '3114006',
  },
  {
    name: 'CARMO DE MINAS',
    initials: 'MG',
    code: '3114105',
  },
  {
    name: 'CARMO DO CAJURU',
    initials: 'MG',
    code: '3114204',
  },
  {
    name: 'CARMO DO PARANAÍBA',
    initials: 'MG',
    code: '3114303',
  },
  {
    name: 'CARMO DO RIO CLARO',
    initials: 'MG',
    code: '3114402',
  },
  {
    name: 'CARMO DO RIO VERDE',
    initials: 'GO',
    code: '5205000',
  },
  {
    name: 'CARMOLÂNDIA',
    initials: 'TO',
    code: '1703883',
  },
  {
    name: 'CARMÓPOLIS',
    initials: 'SE',
    code: '2801504',
  },
  {
    name: 'CARMÓPOLIS DE MINAS',
    initials: 'MG',
    code: '3114501',
  },
  {
    name: 'CARNAÍBA',
    initials: 'PE',
    code: '2603900',
  },
  {
    name: 'CARNAÚBA DOS DANTAS',
    initials: 'RN',
    code: '2402402',
  },
  {
    name: 'CARNAUBAIS',
    initials: 'RN',
    code: '2402501',
  },
  {
    name: 'CARNAUBAL',
    initials: 'CE',
    code: '2303402',
  },
  {
    name: 'CARNAUBEIRA DA PENHA',
    initials: 'PE',
    code: '2603926',
  },
  {
    name: 'CARNEIRINHO',
    initials: 'MG',
    code: '3114550',
  },
  {
    name: 'CARNEIROS',
    initials: 'AL',
    code: '2701803',
  },
  {
    name: 'CAROEBE',
    initials: 'RR',
    code: '1400233',
  },
  {
    name: 'CAROLINA',
    initials: 'MA',
    code: '2102804',
  },
  {
    name: 'CARPINA',
    initials: 'PE',
    code: '2604007',
  },
  {
    name: 'CARRANCAS',
    initials: 'MG',
    code: '3114600',
  },
  {
    name: 'CARRAPATEIRA',
    initials: 'PB',
    code: '2504108',
  },
  {
    name: 'CARRASCO BONITO',
    initials: 'TO',
    code: '1703891',
  },
  {
    name: 'CARUARU',
    initials: 'PE',
    code: '2604106',
  },
  {
    name: 'CARUTAPERA',
    initials: 'MA',
    code: '2102903',
  },
  {
    name: 'CARVALHÓPOLIS',
    initials: 'MG',
    code: '3114709',
  },
  {
    name: 'CARVALHOS',
    initials: 'MG',
    code: '3114808',
  },
  {
    name: 'CASA BRANCA',
    initials: 'SP',
    code: '3510807',
  },
  {
    name: 'CASA GRANDE',
    initials: 'MG',
    code: '3114907',
  },
  {
    name: 'CASA NOVA',
    initials: 'BA',
    code: '2907202',
  },
  {
    name: 'CASCA',
    initials: 'RS',
    code: '4304903',
  },
  {
    name: 'CASCALHO RICO',
    initials: 'MG',
    code: '3115003',
  },
  {
    name: 'CASCAVEL',
    initials: 'CE',
    code: '2303501',
  },
  {
    name: 'CASCAVEL',
    initials: 'PR',
    code: '4104808',
  },
  {
    name: 'CASEARA',
    initials: 'TO',
    code: '1703909',
  },
  {
    name: 'CASEIROS',
    initials: 'RS',
    code: '4304952',
  },
  {
    name: 'CASIMIRO DE ABREU',
    initials: 'RJ',
    code: '3301306',
  },
  {
    name: 'CASINHAS',
    initials: 'PE',
    code: '2604155',
  },
  {
    name: 'CASSERENGUE',
    initials: 'PB',
    code: '2504157',
  },
  {
    name: 'CÁSSIA',
    initials: 'MG',
    code: '3115102',
  },
  {
    name: 'CÁSSIA DOS COQUEIROS',
    initials: 'SP',
    code: '3510906',
  },
  {
    name: 'CASSILÂNDIA',
    initials: 'MS',
    code: '5002902',
  },
  {
    name: 'CASTANHAL',
    initials: 'PA',
    code: '1502400',
  },
  {
    name: 'CASTANHEIRA',
    initials: 'MT',
    code: '5102850',
  },
  {
    name: 'CASTANHEIRAS',
    initials: 'RO',
    code: '1100908',
  },
  {
    name: 'CASTELÂNDIA',
    initials: 'GO',
    code: '5205059',
  },
  {
    name: 'CASTELO',
    initials: 'ES',
    code: '3201407',
  },
  {
    name: 'CASTELO DO PIAUÍ',
    initials: 'PI',
    code: '2202604',
  },
  {
    name: 'CASTILHO',
    initials: 'SP',
    code: '3511003',
  },
  {
    name: 'CASTRO',
    initials: 'PR',
    code: '4104907',
  },
  {
    name: 'CASTRO ALVES',
    initials: 'BA',
    code: '2907301',
  },
  {
    name: 'CATAGUASES',
    initials: 'MG',
    code: '3115300',
  },
  {
    name: 'CATALÃO',
    initials: 'GO',
    code: '5205109',
  },
  {
    name: 'CATANDUVA',
    initials: 'SP',
    code: '3511102',
  },
  {
    name: 'CATANDUVAS',
    initials: 'SC',
    code: '4204004',
  },
  {
    name: 'CATANDUVAS',
    initials: 'PR',
    code: '4105003',
  },
  {
    name: 'CATARINA',
    initials: 'CE',
    code: '2303600',
  },
  {
    name: 'CATAS ALTAS',
    initials: 'MG',
    code: '3115359',
  },
  {
    name: 'CATAS ALTAS DA NORUEGA',
    initials: 'MG',
    code: '3115409',
  },
  {
    name: 'CATENDE',
    initials: 'PE',
    code: '2604205',
  },
  {
    name: 'CATIGUÁ',
    initials: 'SP',
    code: '3511201',
  },
  {
    name: 'CATINGUEIRA',
    initials: 'PB',
    code: '2504207',
  },
  {
    name: 'CATOLÂNDIA',
    initials: 'BA',
    code: '2907400',
  },
  {
    name: 'CATOLÉ DO ROCHA',
    initials: 'PB',
    code: '2504306',
  },
  {
    name: 'CATU',
    initials: 'BA',
    code: '2907509',
  },
  {
    name: 'CATUÍPE',
    initials: 'RS',
    code: '4305009',
  },
  {
    name: 'CATUJI',
    initials: 'MG',
    code: '3115458',
  },
  {
    name: 'CATUNDA',
    initials: 'CE',
    code: '2303659',
  },
  {
    name: 'CATURAÍ',
    initials: 'GO',
    code: '5205208',
  },
  {
    name: 'CATURAMA',
    initials: 'BA',
    code: '2907558',
  },
  {
    name: 'CATURITÉ',
    initials: 'PB',
    code: '2504355',
  },
  {
    name: 'CATUTI',
    initials: 'MG',
    code: '3115474',
  },
  {
    name: 'CAUCAIA',
    initials: 'CE',
    code: '2303709',
  },
  {
    name: 'CAVALCANTE',
    initials: 'GO',
    code: '5205307',
  },
  {
    name: 'CAXAMBU',
    initials: 'MG',
    code: '3115508',
  },
  {
    name: 'CAXAMBU DO SUL',
    initials: 'SC',
    code: '4204103',
  },
  {
    name: 'CAXIAS',
    initials: 'MA',
    code: '2103000',
  },
  {
    name: 'CAXIAS DO SUL',
    initials: 'RS',
    code: '4305108',
  },
  {
    name: 'CAXINGÓ',
    initials: 'PI',
    code: '2202653',
  },
  {
    name: 'CEARÁ-MIRIM',
    initials: 'RN',
    code: '2402600',
  },
  {
    name: 'CEDRAL',
    initials: 'MA',
    code: '2103109',
  },
  {
    name: 'CEDRAL',
    initials: 'SP',
    code: '3511300',
  },
  {
    name: 'CEDRO',
    initials: 'CE',
    code: '2303808',
  },
  {
    name: 'CEDRO',
    initials: 'PE',
    code: '2604304',
  },
  {
    name: 'CEDRO DE SÃO JOÃO',
    initials: 'SE',
    code: '2801603',
  },
  {
    name: 'CEDRO DO ABAETÉ',
    initials: 'MG',
    code: '3115607',
  },
  {
    name: 'CELSO RAMOS',
    initials: 'SC',
    code: '4204152',
  },
  {
    name: 'CENTENÁRIO',
    initials: 'RS',
    code: '4305116',
  },
  {
    name: 'CENTENÁRIO',
    initials: 'TO',
    code: '1704105',
  },
  {
    name: 'CENTENÁRIO DO SUL',
    initials: 'PR',
    code: '4105102',
  },
  {
    name: 'CENTRAL',
    initials: 'BA',
    code: '2907608',
  },
  {
    name: 'CENTRAL DE MINAS',
    initials: 'MG',
    code: '3115706',
  },
  {
    name: 'CENTRAL DO MARANHÃO',
    initials: 'MA',
    code: '2103125',
  },
  {
    name: 'CENTRALINA',
    initials: 'MG',
    code: '3115805',
  },
  {
    name: 'CENTRO DO GUILHERME',
    initials: 'MA',
    code: '2103158',
  },
  {
    name: 'CENTRO NOVO DO MARANHÃO',
    initials: 'MA',
    code: '2103174',
  },
  {
    name: 'CEREJEIRAS',
    initials: 'RO',
    code: '1100056',
  },
  {
    name: 'CERES',
    initials: 'GO',
    code: '5205406',
  },
  {
    name: 'CERQUEIRA CÉSAR',
    initials: 'SP',
    code: '3511409',
  },
  {
    name: 'CERQUILHO',
    initials: 'SP',
    code: '3511508',
  },
  {
    name: 'CERRITO',
    initials: 'RS',
    code: '4305124',
  },
  {
    name: 'CERRO AZUL',
    initials: 'PR',
    code: '4105201',
  },
  {
    name: 'CERRO BRANCO',
    initials: 'RS',
    code: '4305132',
  },
  {
    name: 'CERRO CORÁ',
    initials: 'RN',
    code: '2402709',
  },
  {
    name: 'CERRO GRANDE',
    initials: 'RS',
    code: '4305157',
  },
  {
    name: 'CERRO GRANDE DO SUL',
    initials: 'RS',
    code: '4305173',
  },
  {
    name: 'CERRO LARGO',
    initials: 'RS',
    code: '4305207',
  },
  {
    name: 'CERRO NEGRO',
    initials: 'SC',
    code: '4204178',
  },
  {
    name: 'CESÁRIO LANGE',
    initials: 'SP',
    code: '3511607',
  },
  {
    name: 'CÉU AZUL',
    initials: 'PR',
    code: '4105300',
  },
  {
    name: 'CEZARINA',
    initials: 'GO',
    code: '5205455',
  },
  {
    name: 'CHÁCARA',
    initials: 'MG',
    code: '3115904',
  },
  {
    name: 'CHÃ DE ALEGRIA',
    initials: 'PE',
    code: '2604403',
  },
  {
    name: 'CHÃ GRANDE',
    initials: 'PE',
    code: '2604502',
  },
  {
    name: 'CHALÉ',
    initials: 'MG',
    code: '3116001',
  },
  {
    name: 'CHAPADA',
    initials: 'RS',
    code: '4305306',
  },
  {
    name: 'CHAPADA DA NATIVIDADE',
    initials: 'TO',
    code: '1705102',
  },
  {
    name: 'CHAPADA DE AREIA',
    initials: 'TO',
    code: '1704600',
  },
  {
    name: 'CHAPADA DO NORTE',
    initials: 'MG',
    code: '3116100',
  },
  {
    name: 'CHAPADA DOS GUIMARÃES',
    initials: 'MT',
    code: '5103007',
  },
  {
    name: 'CHAPADA GAÚCHA',
    initials: 'MG',
    code: '3116159',
  },
  {
    name: 'CHAPADÃO DO CÉU',
    initials: 'GO',
    code: '5205471',
  },
  {
    name: 'CHAPADÃO DO LAGEADO',
    initials: 'SC',
    code: '4204194',
  },
  {
    name: 'CHAPADÃO DO SUL',
    initials: 'MS',
    code: '5002951',
  },
  {
    name: 'CHAPADINHA',
    initials: 'MA',
    code: '2103208',
  },
  {
    name: 'CHAPECÓ',
    initials: 'SC',
    code: '4204202',
  },
  {
    name: 'CHÃ PRETA',
    initials: 'AL',
    code: '2701902',
  },
  {
    name: 'CHARQUEADA',
    initials: 'SP',
    code: '3511706',
  },
  {
    name: 'CHARQUEADAS',
    initials: 'RS',
    code: '4305355',
  },
  {
    name: 'CHARRUA',
    initials: 'RS',
    code: '4305371',
  },
  {
    name: 'CHAVAL',
    initials: 'CE',
    code: '2303907',
  },
  {
    name: 'CHAVANTES',
    initials: 'SP',
    code: '3557204',
  },
  {
    name: 'CHAVES',
    initials: 'PA',
    code: '1502509',
  },
  {
    name: 'CHIADOR',
    initials: 'MG',
    code: '3116209',
  },
  {
    name: 'CHIAPETTA',
    initials: 'RS',
    code: '4305405',
  },
  {
    name: 'CHOPINZINHO',
    initials: 'PR',
    code: '4105409',
  },
  {
    name: 'CHORÓ',
    initials: 'CE',
    code: '2303931',
  },
  {
    name: 'CHOROZINHO',
    initials: 'CE',
    code: '2303956',
  },
  {
    name: 'CHORROCHÓ',
    initials: 'BA',
    code: '2907707',
  },
  {
    name: 'CHUÍ',
    initials: 'RS',
    code: '4305439',
  },
  {
    name: 'CHUPINGUAIA',
    initials: 'RO',
    code: '1100924',
  },
  {
    name: 'CHUVISCA',
    initials: 'RS',
    code: '4305447',
  },
  {
    name: 'CIANORTE',
    initials: 'PR',
    code: '4105508',
  },
  {
    name: 'CÍCERO DANTAS',
    initials: 'BA',
    code: '2907806',
  },
  {
    name: 'CIDADE GAÚCHA',
    initials: 'PR',
    code: '4105607',
  },
  {
    name: 'CIDADE OCIDENTAL',
    initials: 'GO',
    code: '5205497',
  },
  {
    name: 'CIDELÂNDIA',
    initials: 'MA',
    code: '2103257',
  },
  {
    name: 'CIDREIRA',
    initials: 'RS',
    code: '4305454',
  },
  {
    name: 'CIPÓ',
    initials: 'BA',
    code: '2907905',
  },
  {
    name: 'CIPOTÂNEA',
    initials: 'MG',
    code: '3116308',
  },
  {
    name: 'CIRÍACO',
    initials: 'RS',
    code: '4305504',
  },
  {
    name: 'CLARAVAL',
    initials: 'MG',
    code: '3116407',
  },
  {
    name: 'CLARO DOS POÇÕES',
    initials: 'MG',
    code: '3116506',
  },
  {
    name: 'CLÁUDIA',
    initials: 'MT',
    code: '5103056',
  },
  {
    name: 'CLÁUDIO',
    initials: 'MG',
    code: '3116605',
  },
  {
    name: 'CLEMENTINA',
    initials: 'SP',
    code: '3511904',
  },
  {
    name: 'CLEVELÂNDIA',
    initials: 'PR',
    code: '4105706',
  },
  {
    name: 'COARACI',
    initials: 'BA',
    code: '2908002',
  },
  {
    name: 'COARI',
    initials: 'AM',
    code: '1301209',
  },
  {
    name: 'COCAL',
    initials: 'PI',
    code: '2202703',
  },
  {
    name: 'COCAL DE TELHA',
    initials: 'PI',
    code: '2202711',
  },
  {
    name: 'COCAL DOS ALVES',
    initials: 'PI',
    code: '2202729',
  },
  {
    name: 'COCAL DO SUL',
    initials: 'SC',
    code: '4204251',
  },
  {
    name: 'COCALINHO',
    initials: 'MT',
    code: '5103106',
  },
  {
    name: 'COCALZINHO DE GOIÁS',
    initials: 'GO',
    code: '5205513',
  },
  {
    name: 'COCOS',
    initials: 'BA',
    code: '2908101',
  },
  {
    name: 'CODAJÁS',
    initials: 'AM',
    code: '1301308',
  },
  {
    name: 'CODÓ',
    initials: 'MA',
    code: '2103307',
  },
  {
    name: 'COELHO NETO',
    initials: 'MA',
    code: '2103406',
  },
  {
    name: 'COIMBRA',
    initials: 'MG',
    code: '3116704',
  },
  {
    name: 'COITÉ DO NÓIA',
    initials: 'AL',
    code: '2702009',
  },
  {
    name: 'COIVARAS',
    initials: 'PI',
    code: '2202737',
  },
  {
    name: 'COLARES',
    initials: 'PA',
    code: '1502608',
  },
  {
    name: 'COLATINA',
    initials: 'ES',
    code: '3201506',
  },
  {
    name: 'COLÍDER',
    initials: 'MT',
    code: '5103205',
  },
  {
    name: 'COLINA',
    initials: 'SP',
    code: '3512001',
  },
  {
    name: 'COLINAS',
    initials: 'RS',
    code: '4305587',
  },
  {
    name: 'COLINAS',
    initials: 'MA',
    code: '2103505',
  },
  {
    name: 'COLINAS DO SUL',
    initials: 'GO',
    code: '5205521',
  },
  {
    name: 'COLINAS DO TOCANTINS',
    initials: 'TO',
    code: '1705508',
  },
  {
    name: 'COLMÉIA',
    initials: 'TO',
    code: '1716703',
  },
  {
    name: 'COLNIZA',
    initials: 'MT',
    code: '5103254',
  },
  {
    name: 'COLÔMBIA',
    initials: 'SP',
    code: '3512100',
  },
  {
    name: 'COLOMBO',
    initials: 'PR',
    code: '4105805',
  },
  {
    name: 'COLÔNIA DO GURGUÉIA',
    initials: 'PI',
    code: '2202752',
  },
  {
    name: 'COLÔNIA DO PIAUÍ',
    initials: 'PI',
    code: '2202778',
  },
  {
    name: 'COLÔNIA LEOPOLDINA',
    initials: 'AL',
    code: '2702108',
  },
  {
    name: 'COLORADO',
    initials: 'RS',
    code: '4305603',
  },
  {
    name: 'COLORADO',
    initials: 'PR',
    code: '4105904',
  },
  {
    name: 'COLORADO DO OESTE',
    initials: 'RO',
    code: '1100064',
  },
  {
    name: 'COLUNA',
    initials: 'MG',
    code: '3116803',
  },
  {
    name: 'COMBINADO',
    initials: 'TO',
    code: '1705557',
  },
  {
    name: 'COMENDADOR GOMES',
    initials: 'MG',
    code: '3116902',
  },
  {
    name: 'COMENDADOR LEVY GASPARIAN',
    initials: 'RJ',
    code: '3300951',
  },
  {
    name: 'COMERCINHO',
    initials: 'MG',
    code: '3117009',
  },
  {
    name: 'COMODORO',
    initials: 'MT',
    code: '5103304',
  },
  {
    name: 'CONCEIÇÃO',
    initials: 'PB',
    code: '2504405',
  },
  {
    name: 'CONCEIÇÃO DA APARECIDA',
    initials: 'MG',
    code: '3117108',
  },
  {
    name: 'CONCEIÇÃO DA BARRA',
    initials: 'ES',
    code: '3201605',
  },
  {
    name: 'CONCEIÇÃO DA BARRA DE MINAS',
    initials: 'MG',
    code: '3115201',
  },
  {
    name: 'CONCEIÇÃO DA FEIRA',
    initials: 'BA',
    code: '2908200',
  },
  {
    name: 'CONCEIÇÃO DAS ALAGOAS',
    initials: 'MG',
    code: '3117306',
  },
  {
    name: 'CONCEIÇÃO DAS PEDRAS',
    initials: 'MG',
    code: '3117207',
  },
  {
    name: 'CONCEIÇÃO DE IPANEMA',
    initials: 'MG',
    code: '3117405',
  },
  {
    name: 'CONCEIÇÃO DE MACABU',
    initials: 'RJ',
    code: '3301405',
  },
  {
    name: 'CONCEIÇÃO DO ALMEIDA',
    initials: 'BA',
    code: '2908309',
  },
  {
    name: 'CONCEIÇÃO DO ARAGUAIA',
    initials: 'PA',
    code: '1502707',
  },
  {
    name: 'CONCEIÇÃO DO CANINDÉ',
    initials: 'PI',
    code: '2202802',
  },
  {
    name: 'CONCEIÇÃO DO CASTELO',
    initials: 'ES',
    code: '3201704',
  },
  {
    name: 'CONCEIÇÃO DO COITÉ',
    initials: 'BA',
    code: '2908408',
  },
  {
    name: 'CONCEIÇÃO DO JACUÍPE',
    initials: 'BA',
    code: '2908507',
  },
  {
    name: 'CONCEIÇÃO DO LAGO-AÇU',
    initials: 'MA',
    code: '2103554',
  },
  {
    name: 'CONCEIÇÃO DO MATO DENTRO',
    initials: 'MG',
    code: '3117504',
  },
  {
    name: 'CONCEIÇÃO DO PARÁ',
    initials: 'MG',
    code: '3117603',
  },
  {
    name: 'CONCEIÇÃO DO RIO VERDE',
    initials: 'MG',
    code: '3117702',
  },
  {
    name: 'CONCEIÇÃO DOS OUROS',
    initials: 'MG',
    code: '3117801',
  },
  {
    name: 'CONCEIÇÃO DO TOCANTINS',
    initials: 'TO',
    code: '1705607',
  },
  {
    name: 'CONCHAL',
    initials: 'SP',
    code: '3512209',
  },
  {
    name: 'CONCHAS',
    initials: 'SP',
    code: '3512308',
  },
  {
    name: 'CONCÓRDIA',
    initials: 'SC',
    code: '4204301',
  },
  {
    name: 'CONCÓRDIA DO PARÁ',
    initials: 'PA',
    code: '1502756',
  },
  {
    name: 'CONDADO',
    initials: 'PE',
    code: '2604601',
  },
  {
    name: 'CONDADO',
    initials: 'PB',
    code: '2504504',
  },
  {
    name: 'CONDE',
    initials: 'PB',
    code: '2504603',
  },
  {
    name: 'CONDE',
    initials: 'BA',
    code: '2908606',
  },
  {
    name: 'CONDEÚBA',
    initials: 'BA',
    code: '2908705',
  },
  {
    name: 'CONDOR',
    initials: 'RS',
    code: '4305702',
  },
  {
    name: 'CÔNEGO MARINHO',
    initials: 'MG',
    code: '3117836',
  },
  {
    name: 'CONFINS',
    initials: 'MG',
    code: '3117876',
  },
  {
    name: 'CONFRESA',
    initials: 'MT',
    code: '5103353',
  },
  {
    name: 'CONGO',
    initials: 'PB',
    code: '2504702',
  },
  {
    name: 'CONGONHAL',
    initials: 'MG',
    code: '3117900',
  },
  {
    name: 'CONGONHAS',
    initials: 'MG',
    code: '3118007',
  },
  {
    name: 'CONGONHAS DO NORTE',
    initials: 'MG',
    code: '3118106',
  },
  {
    name: 'CONGONHINHAS',
    initials: 'PR',
    code: '4106001',
  },
  {
    name: 'CONQUISTA',
    initials: 'MG',
    code: '3118205',
  },
  {
    name: 'CONQUISTA D\'OESTE',
    initials: 'MT',
    code: '5103361',
  },
  {
    name: 'CONSELHEIRO LAFAIETE',
    initials: 'MG',
    code: '3118304',
  },
  {
    name: 'CONSELHEIRO MAIRINCK',
    initials: 'PR',
    code: '4106100',
  },
  {
    name: 'CONSELHEIRO PENA',
    initials: 'MG',
    code: '3118403',
  },
  {
    name: 'CONSOLAÇÃO',
    initials: 'MG',
    code: '3118502',
  },
  {
    name: 'CONSTANTINA',
    initials: 'RS',
    code: '4305801',
  },
  {
    name: 'CONTAGEM',
    initials: 'MG',
    code: '3118601',
  },
  {
    name: 'CONTENDA',
    initials: 'PR',
    code: '4106209',
  },
  {
    name: 'CONTENDAS DO SINCORÁ',
    initials: 'BA',
    code: '2908804',
  },
  {
    name: 'COQUEIRAL',
    initials: 'MG',
    code: '3118700',
  },
  {
    name: 'COQUEIRO BAIXO',
    initials: 'RS',
    code: '4305835',
  },
  {
    name: 'COQUEIROS DO SUL',
    initials: 'RS',
    code: '4305850',
  },
  {
    name: 'COQUEIRO SECO',
    initials: 'AL',
    code: '2702207',
  },
  {
    name: 'CORAÇÃO DE JESUS',
    initials: 'MG',
    code: '3118809',
  },
  {
    name: 'CORAÇÃO DE MARIA',
    initials: 'BA',
    code: '2908903',
  },
  {
    name: 'CORBÉLIA',
    initials: 'PR',
    code: '4106308',
  },
  {
    name: 'CORDEIRO',
    initials: 'RJ',
    code: '3301504',
  },
  {
    name: 'CORDEIRÓPOLIS',
    initials: 'SP',
    code: '3512407',
  },
  {
    name: 'CORDEIROS',
    initials: 'BA',
    code: '2909000',
  },
  {
    name: 'CORDILHEIRA ALTA',
    initials: 'SC',
    code: '4204350',
  },
  {
    name: 'CORDISBURGO',
    initials: 'MG',
    code: '3118908',
  },
  {
    name: 'CORDISLÂNDIA',
    initials: 'MG',
    code: '3119005',
  },
  {
    name: 'COREAÚ',
    initials: 'CE',
    code: '2304004',
  },
  {
    name: 'COREMAS',
    initials: 'PB',
    code: '2504801',
  },
  {
    name: 'CORGUINHO',
    initials: 'MS',
    code: '5003108',
  },
  {
    name: 'CORIBE',
    initials: 'BA',
    code: '2909109',
  },
  {
    name: 'CORINTO',
    initials: 'MG',
    code: '3119104',
  },
  {
    name: 'CORNÉLIO PROCÓPIO',
    initials: 'PR',
    code: '4106407',
  },
  {
    name: 'COROACI',
    initials: 'MG',
    code: '3119203',
  },
  {
    name: 'COROADOS',
    initials: 'SP',
    code: '3512506',
  },
  {
    name: 'COROATÁ',
    initials: 'MA',
    code: '2103604',
  },
  {
    name: 'COROMANDEL',
    initials: 'MG',
    code: '3119302',
  },
  {
    name: 'CORONEL BARROS',
    initials: 'RS',
    code: '4305871',
  },
  {
    name: 'CORONEL BICACO',
    initials: 'RS',
    code: '4305900',
  },
  {
    name: 'CORONEL DOMINGOS SOARES',
    initials: 'PR',
    code: '4106456',
  },
  {
    name: 'CORONEL EZEQUIEL',
    initials: 'RN',
    code: '2402808',
  },
  {
    name: 'CORONEL FABRICIANO',
    initials: 'MG',
    code: '3119401',
  },
  {
    name: 'CORONEL FREITAS',
    initials: 'SC',
    code: '4204400',
  },
  {
    name: 'CORONEL JOÃO PESSOA',
    initials: 'RN',
    code: '2402907',
  },
  {
    name: 'CORONEL JOÃO SÁ',
    initials: 'BA',
    code: '2909208',
  },
  {
    name: 'CORONEL JOSÉ DIAS',
    initials: 'PI',
    code: '2202851',
  },
  {
    name: 'CORONEL MACEDO',
    initials: 'SP',
    code: '3512605',
  },
  {
    name: 'CORONEL MARTINS',
    initials: 'SC',
    code: '4204459',
  },
  {
    name: 'CORONEL MURTA',
    initials: 'MG',
    code: '3119500',
  },
  {
    name: 'CORONEL PACHECO',
    initials: 'MG',
    code: '3119609',
  },
  {
    name: 'CORONEL PILAR',
    initials: 'RS',
    code: '4305934',
  },
  {
    name: 'CORONEL SAPUCAIA',
    initials: 'MS',
    code: '5003157',
  },
  {
    name: 'CORONEL VIVIDA',
    initials: 'PR',
    code: '4106506',
  },
  {
    name: 'CORONEL XAVIER CHAVES',
    initials: 'MG',
    code: '3119708',
  },
  {
    name: 'CÓRREGO DANTA',
    initials: 'MG',
    code: '3119807',
  },
  {
    name: 'CÓRREGO DO BOM JESUS',
    initials: 'MG',
    code: '3119906',
  },
  {
    name: 'CÓRREGO DO OURO',
    initials: 'GO',
    code: '5205703',
  },
  {
    name: 'CÓRREGO FUNDO',
    initials: 'MG',
    code: '3119955',
  },
  {
    name: 'CÓRREGO NOVO',
    initials: 'MG',
    code: '3120003',
  },
  {
    name: 'CORREIA PINTO',
    initials: 'SC',
    code: '4204558',
  },
  {
    name: 'CORRENTE',
    initials: 'PI',
    code: '2202901',
  },
  {
    name: 'CORRENTES',
    initials: 'PE',
    code: '2604700',
  },
  {
    name: 'CORRENTINA',
    initials: 'BA',
    code: '2909307',
  },
  {
    name: 'CORTÊS',
    initials: 'PE',
    code: '2604809',
  },
  {
    name: 'CORUMBÁ',
    initials: 'MS',
    code: '5003207',
  },
  {
    name: 'CORUMBÁ DE GOIÁS',
    initials: 'GO',
    code: '5205802',
  },
  {
    name: 'CORUMBAÍBA',
    initials: 'GO',
    code: '5205901',
  },
  {
    name: 'CORUMBATAÍ',
    initials: 'SP',
    code: '3512704',
  },
  {
    name: 'CORUMBATAÍ DO SUL',
    initials: 'PR',
    code: '4106555',
  },
  {
    name: 'CORUMBIARA',
    initials: 'RO',
    code: '1100072',
  },
  {
    name: 'CORUPÁ',
    initials: 'SC',
    code: '4204509',
  },
  {
    name: 'CORURIPE',
    initials: 'AL',
    code: '2702306',
  },
  {
    name: 'COSMÓPOLIS',
    initials: 'SP',
    code: '3512803',
  },
  {
    name: 'COSMORAMA',
    initials: 'SP',
    code: '3512902',
  },
  {
    name: 'COSTA MARQUES',
    initials: 'RO',
    code: '1100080',
  },
  {
    name: 'COSTA RICA',
    initials: 'MS',
    code: '5003256',
  },
  {
    name: 'COTEGIPE',
    initials: 'BA',
    code: '2909406',
  },
  {
    name: 'COTIA',
    initials: 'SP',
    code: '3513009',
  },
  {
    name: 'COTIPORÃ',
    initials: 'RS',
    code: '4305959',
  },
  {
    name: 'COTRIGUAÇU',
    initials: 'MT',
    code: '5103379',
  },
  {
    name: 'COUTO DE MAGALHÃES',
    initials: 'TO',
    code: '1706001',
  },
  {
    name: 'COUTO DE MAGALHÃES DE MINAS',
    initials: 'MG',
    code: '3120102',
  },
  {
    name: 'COXILHA',
    initials: 'RS',
    code: '4305975',
  },
  {
    name: 'COXIM',
    initials: 'MS',
    code: '5003306',
  },
  {
    name: 'COXIXOLA',
    initials: 'PB',
    code: '2504850',
  },
  {
    name: 'CRAÍBAS',
    initials: 'AL',
    code: '2702355',
  },
  {
    name: 'CRATEÚS',
    initials: 'CE',
    code: '2304103',
  },
  {
    name: 'CRATO',
    initials: 'CE',
    code: '2304202',
  },
  {
    name: 'CRAVINHOS',
    initials: 'SP',
    code: '3513108',
  },
  {
    name: 'CRAVOLÂNDIA',
    initials: 'BA',
    code: '2909505',
  },
  {
    name: 'CRICIÚMA',
    initials: 'SC',
    code: '4204608',
  },
  {
    name: 'CRISÓLITA',
    initials: 'MG',
    code: '3120151',
  },
  {
    name: 'CRISÓPOLIS',
    initials: 'BA',
    code: '2909604',
  },
  {
    name: 'CRISSIUMAL',
    initials: 'RS',
    code: '4306007',
  },
  {
    name: 'CRISTAIS',
    initials: 'MG',
    code: '3120201',
  },
  {
    name: 'CRISTAIS PAULISTA',
    initials: 'SP',
    code: '3513207',
  },
  {
    name: 'CRISTAL',
    initials: 'RS',
    code: '4306056',
  },
  {
    name: 'CRISTALÂNDIA',
    initials: 'TO',
    code: '1706100',
  },
  {
    name: 'CRISTALÂNDIA DO PIAUÍ',
    initials: 'PI',
    code: '2203008',
  },
  {
    name: 'CRISTAL DO SUL',
    initials: 'RS',
    code: '4306072',
  },
  {
    name: 'CRISTÁLIA',
    initials: 'MG',
    code: '3120300',
  },
  {
    name: 'CRISTALINA',
    initials: 'GO',
    code: '5206206',
  },
  {
    name: 'CRISTIANO OTONI',
    initials: 'MG',
    code: '3120409',
  },
  {
    name: 'CRISTIANÓPOLIS',
    initials: 'GO',
    code: '5206305',
  },
  {
    name: 'CRISTINA',
    initials: 'MG',
    code: '3120508',
  },
  {
    name: 'CRISTINÁPOLIS',
    initials: 'SE',
    code: '2801702',
  },
  {
    name: 'CRISTINO CASTRO',
    initials: 'PI',
    code: '2203107',
  },
  {
    name: 'CRISTÓPOLIS',
    initials: 'BA',
    code: '2909703',
  },
  {
    name: 'CRIXÁS',
    initials: 'GO',
    code: '5206404',
  },
  {
    name: 'CRIXÁS DO TOCANTINS',
    initials: 'TO',
    code: '1706258',
  },
  {
    name: 'CROATÁ',
    initials: 'CE',
    code: '2304236',
  },
  {
    name: 'CROMÍNIA',
    initials: 'GO',
    code: '5206503',
  },
  {
    name: 'CRUCILÂNDIA',
    initials: 'MG',
    code: '3120607',
  },
  {
    name: 'CRUZ',
    initials: 'CE',
    code: '2304251',
  },
  {
    name: 'CRUZÁLIA',
    initials: 'SP',
    code: '3513306',
  },
  {
    name: 'CRUZ ALTA',
    initials: 'RS',
    code: '4306106',
  },
  {
    name: 'CRUZALTENSE',
    initials: 'RS',
    code: '4306130',
  },
  {
    name: 'CRUZ DAS ALMAS',
    initials: 'BA',
    code: '2909802',
  },
  {
    name: 'CRUZ DO ESPÍRITO SANTO',
    initials: 'PB',
    code: '2504900',
  },
  {
    name: 'CRUZEIRO',
    initials: 'SP',
    code: '3513405',
  },
  {
    name: 'CRUZEIRO DA FORTALEZA',
    initials: 'MG',
    code: '3120706',
  },
  {
    name: 'CRUZEIRO DO IGUAÇU',
    initials: 'PR',
    code: '4106571',
  },
  {
    name: 'CRUZEIRO DO OESTE',
    initials: 'PR',
    code: '4106605',
  },
  {
    name: 'CRUZEIRO DO SUL',
    initials: 'AC',
    code: '1200203',
  },
  {
    name: 'CRUZEIRO DO SUL',
    initials: 'PR',
    code: '4106704',
  },
  {
    name: 'CRUZEIRO DO SUL',
    initials: 'RS',
    code: '4306205',
  },
  {
    name: 'CRUZETA',
    initials: 'RN',
    code: '2403004',
  },
  {
    name: 'CRUZÍLIA',
    initials: 'MG',
    code: '3120805',
  },
  {
    name: 'CRUZ MACHADO',
    initials: 'PR',
    code: '4106803',
  },
  {
    name: 'CRUZMALTINA',
    initials: 'PR',
    code: '4106852',
  },
  {
    name: 'CUBATÃO',
    initials: 'SP',
    code: '3513504',
  },
  {
    name: 'CUBATI',
    initials: 'PB',
    code: '2505006',
  },
  {
    name: 'CUIABÁ',
    initials: 'MT',
    code: '5103403',
  },
  {
    name: 'CUITÉ',
    initials: 'PB',
    code: '2505105',
  },
  {
    name: 'CUITÉ DE MAMANGUAPE',
    initials: 'PB',
    code: '2505238',
  },
  {
    name: 'CUITEGI',
    initials: 'PB',
    code: '2505204',
  },
  {
    name: 'CUJUBIM',
    initials: 'RO',
    code: '1100940',
  },
  {
    name: 'CUMARI',
    initials: 'GO',
    code: '5206602',
  },
  {
    name: 'CUMARU',
    initials: 'PE',
    code: '2604908',
  },
  {
    name: 'CUMARU DO NORTE',
    initials: 'PA',
    code: '1502764',
  },
  {
    name: 'CUMBE',
    initials: 'SE',
    code: '2801900',
  },
  {
    name: 'CUNHA',
    initials: 'SP',
    code: '3513603',
  },
  {
    name: 'CUNHA PORÃ',
    initials: 'SC',
    code: '4204707',
  },
  {
    name: 'CUNHATAÍ',
    initials: 'SC',
    code: '4204756',
  },
  {
    name: 'CUPARAQUE',
    initials: 'MG',
    code: '3120839',
  },
  {
    name: 'CUPIRA',
    initials: 'PE',
    code: '2605004',
  },
  {
    name: 'CURAÇÁ',
    initials: 'BA',
    code: '2909901',
  },
  {
    name: 'CURIMATÁ',
    initials: 'PI',
    code: '2203206',
  },
  {
    name: 'CURIONÓPOLIS',
    initials: 'PA',
    code: '1502772',
  },
  {
    name: 'CURITIBA',
    initials: 'PR',
    code: '4106902',
  },
  {
    name: 'CURITIBANOS',
    initials: 'SC',
    code: '4204806',
  },
  {
    name: 'CURIÚVA',
    initials: 'PR',
    code: '4107009',
  },
  {
    name: 'CURRAIS',
    initials: 'PI',
    code: '2203230',
  },
  {
    name: 'CURRAIS NOVOS',
    initials: 'RN',
    code: '2403103',
  },
  {
    name: 'CURRAL DE CIMA',
    initials: 'PB',
    code: '2505279',
  },
  {
    name: 'CURRAL DE DENTRO',
    initials: 'MG',
    code: '3120870',
  },
  {
    name: 'CURRALINHO',
    initials: 'PA',
    code: '1502806',
  },
  {
    name: 'CURRALINHOS',
    initials: 'PI',
    code: '2203255',
  },
  {
    name: 'CURRAL NOVO DO PIAUÍ',
    initials: 'PI',
    code: '2203271',
  },
  {
    name: 'CURRAL VELHO',
    initials: 'PB',
    code: '2505303',
  },
  {
    name: 'CURUÁ',
    initials: 'PA',
    code: '1502855',
  },
  {
    name: 'CURUÇÁ',
    initials: 'PA',
    code: '1502905',
  },
  {
    name: 'CURURUPU',
    initials: 'MA',
    code: '2103703',
  },
  {
    name: 'CURVELÂNDIA',
    initials: 'MT',
    code: '5103437',
  },
  {
    name: 'CURVELO',
    initials: 'MG',
    code: '3120904',
  },
  {
    name: 'CUSTÓDIA',
    initials: 'PE',
    code: '2605103',
  },
  {
    name: 'CUTIAS',
    initials: 'AP',
    code: '1600212',
  },
  {
    name: 'DAMIANÓPOLIS',
    initials: 'GO',
    code: '5206701',
  },
  {
    name: 'DAMIÃO',
    initials: 'PB',
    code: '2505352',
  },
  {
    name: 'DAMOLÂNDIA',
    initials: 'GO',
    code: '5206800',
  },
  {
    name: 'DARCINÓPOLIS',
    initials: 'TO',
    code: '1706506',
  },
  {
    name: 'DÁRIO MEIRA',
    initials: 'BA',
    code: '2910008',
  },
  {
    name: 'DATAS',
    initials: 'MG',
    code: '3121001',
  },
  {
    name: 'DAVID CANABARRO',
    initials: 'RS',
    code: '4306304',
  },
  {
    name: 'DAVINÓPOLIS',
    initials: 'GO',
    code: '5206909',
  },
  {
    name: 'DAVINÓPOLIS',
    initials: 'MA',
    code: '2103752',
  },
  {
    name: 'DELFIM MOREIRA',
    initials: 'MG',
    code: '3121100',
  },
  {
    name: 'DELFINÓPOLIS',
    initials: 'MG',
    code: '3121209',
  },
  {
    name: 'DELMIRO GOUVEIA',
    initials: 'AL',
    code: '2702405',
  },
  {
    name: 'DELTA',
    initials: 'MG',
    code: '3121258',
  },
  {
    name: 'DEMERVAL LOBÃO',
    initials: 'PI',
    code: '2203305',
  },
  {
    name: 'DENISE',
    initials: 'MT',
    code: '5103452',
  },
  {
    name: 'DEODÁPOLIS',
    initials: 'MS',
    code: '5003454',
  },
  {
    name: 'DEPUTADO IRAPUAN PINHEIRO',
    initials: 'CE',
    code: '2304269',
  },
  {
    name: 'DERRUBADAS',
    initials: 'RS',
    code: '4306320',
  },
  {
    name: 'DESCALVADO',
    initials: 'SP',
    code: '3513702',
  },
  {
    name: 'DESCANSO',
    initials: 'SC',
    code: '4204905',
  },
  {
    name: 'DESCOBERTO',
    initials: 'MG',
    code: '3121308',
  },
  {
    name: 'DESTERRO',
    initials: 'PB',
    code: '2505402',
  },
  {
    name: 'DESTERRO DE ENTRE RIOS',
    initials: 'MG',
    code: '3121407',
  },
  {
    name: 'DESTERRO DO MELO',
    initials: 'MG',
    code: '3121506',
  },
  {
    name: 'DEZESSEIS DE NOVEMBRO',
    initials: 'RS',
    code: '4306353',
  },
  {
    name: 'DIADEMA',
    initials: 'SP',
    code: '3513801',
  },
  {
    name: 'DIAMANTE',
    initials: 'PB',
    code: '2505600',
  },
  {
    name: 'DIAMANTE D\'OESTE',
    initials: 'PR',
    code: '4107157',
  },
  {
    name: 'DIAMANTE DO NORTE',
    initials: 'PR',
    code: '4107108',
  },
  {
    name: 'DIAMANTE DO SUL',
    initials: 'PR',
    code: '4107124',
  },
  {
    name: 'DIAMANTINA',
    initials: 'MG',
    code: '3121605',
  },
  {
    name: 'DIAMANTINO',
    initials: 'MT',
    code: '5103502',
  },
  {
    name: 'DIANÓPOLIS',
    initials: 'TO',
    code: '1707009',
  },
  {
    name: 'DIAS D\'ÁVILA',
    initials: 'BA',
    code: '2910057',
  },
  {
    name: 'DILERMANDO DE AGUIAR',
    initials: 'RS',
    code: '4306379',
  },
  {
    name: 'DIOGO DE VASCONCELOS',
    initials: 'MG',
    code: '3121704',
  },
  {
    name: 'DIONÍSIO',
    initials: 'MG',
    code: '3121803',
  },
  {
    name: 'DIONÍSIO CERQUEIRA',
    initials: 'SC',
    code: '4205001',
  },
  {
    name: 'DIORAMA',
    initials: 'GO',
    code: '5207105',
  },
  {
    name: 'DIRCE REIS',
    initials: 'SP',
    code: '3513850',
  },
  {
    name: 'DIRCEU ARCOVERDE',
    initials: 'PI',
    code: '2203354',
  },
  {
    name: 'DIVINA PASTORA',
    initials: 'SE',
    code: '2802007',
  },
  {
    name: 'DIVINÉSIA',
    initials: 'MG',
    code: '3121902',
  },
  {
    name: 'DIVINO',
    initials: 'MG',
    code: '3122009',
  },
  {
    name: 'DIVINO DAS LARANJEIRAS',
    initials: 'MG',
    code: '3122108',
  },
  {
    name: 'DIVINO DE SÃO LOURENÇO',
    initials: 'ES',
    code: '3201803',
  },
  {
    name: 'DIVINOLÂNDIA',
    initials: 'SP',
    code: '3513900',
  },
  {
    name: 'DIVINOLÂNDIA DE MINAS',
    initials: 'MG',
    code: '3122207',
  },
  {
    name: 'DIVINÓPOLIS',
    initials: 'MG',
    code: '3122306',
  },
  {
    name: 'DIVINÓPOLIS DE GOIÁS',
    initials: 'GO',
    code: '5208301',
  },
  {
    name: 'DIVINÓPOLIS DO TOCANTINS',
    initials: 'TO',
    code: '1707108',
  },
  {
    name: 'DIVISA ALEGRE',
    initials: 'MG',
    code: '3122355',
  },
  {
    name: 'DIVISA NOVA',
    initials: 'MG',
    code: '3122405',
  },
  {
    name: 'DIVISÓPOLIS',
    initials: 'MG',
    code: '3122454',
  },
  {
    name: 'DOBRADA',
    initials: 'SP',
    code: '3514007',
  },
  {
    name: 'DOIS CÓRREGOS',
    initials: 'SP',
    code: '3514106',
  },
  {
    name: 'DOIS IRMÃOS',
    initials: 'RS',
    code: '4306403',
  },
  {
    name: 'DOIS IRMÃOS DAS MISSÕES',
    initials: 'RS',
    code: '4306429',
  },
  {
    name: 'DOIS IRMÃOS DO BURITI',
    initials: 'MS',
    code: '5003488',
  },
  {
    name: 'DOIS IRMÃOS DO TOCANTINS',
    initials: 'TO',
    code: '1707207',
  },
  {
    name: 'DOIS LAJEADOS',
    initials: 'RS',
    code: '4306452',
  },
  {
    name: 'DOIS RIACHOS',
    initials: 'AL',
    code: '2702504',
  },
  {
    name: 'DOIS VIZINHOS',
    initials: 'PR',
    code: '4107207',
  },
  {
    name: 'DOLCINÓPOLIS',
    initials: 'SP',
    code: '3514205',
  },
  {
    name: 'DOM AQUINO',
    initials: 'MT',
    code: '5103601',
  },
  {
    name: 'DOM BASÍLIO',
    initials: 'BA',
    code: '2910107',
  },
  {
    name: 'DOM BOSCO',
    initials: 'MG',
    code: '3122470',
  },
  {
    name: 'DOM CAVATI',
    initials: 'MG',
    code: '3122504',
  },
  {
    name: 'DOM ELISEU',
    initials: 'PA',
    code: '1502939',
  },
  {
    name: 'DOM EXPEDITO LOPES',
    initials: 'PI',
    code: '2203404',
  },
  {
    name: 'DOM FELICIANO',
    initials: 'RS',
    code: '4306502',
  },
  {
    name: 'DOMINGOS MARTINS',
    initials: 'ES',
    code: '3201902',
  },
  {
    name: 'DOMINGOS MOURÃO',
    initials: 'PI',
    code: '2203420',
  },
  {
    name: 'DOM INOCÊNCIO',
    initials: 'PI',
    code: '2203453',
  },
  {
    name: 'DOM JOAQUIM',
    initials: 'MG',
    code: '3122603',
  },
  {
    name: 'DOM MACEDO COSTA',
    initials: 'BA',
    code: '2910206',
  },
  {
    name: 'DOM PEDRITO',
    initials: 'RS',
    code: '4306601',
  },
  {
    name: 'DOM PEDRO',
    initials: 'MA',
    code: '2103802',
  },
  {
    name: 'DOM PEDRO DE ALCÂNTARA',
    initials: 'RS',
    code: '4306551',
  },
  {
    name: 'DOM SILVÉRIO',
    initials: 'MG',
    code: '3122702',
  },
  {
    name: 'DOM VIÇOSO',
    initials: 'MG',
    code: '3122801',
  },
  {
    name: 'DONA EMMA',
    initials: 'SC',
    code: '4205100',
  },
  {
    name: 'DONA EUZÉBIA',
    initials: 'MG',
    code: '3122900',
  },
  {
    name: 'DONA FRANCISCA',
    initials: 'RS',
    code: '4306700',
  },
  {
    name: 'DONA INÊS',
    initials: 'PB',
    code: '2505709',
  },
  {
    name: 'DORES DE CAMPOS',
    initials: 'MG',
    code: '3123007',
  },
  {
    name: 'DORES DE GUANHÃES',
    initials: 'MG',
    code: '3123106',
  },
  {
    name: 'DORES DO INDAIÁ',
    initials: 'MG',
    code: '3123205',
  },
  {
    name: 'DORES DO RIO PRETO',
    initials: 'ES',
    code: '3202009',
  },
  {
    name: 'DORES DO TURVO',
    initials: 'MG',
    code: '3123304',
  },
  {
    name: 'DORESÓPOLIS',
    initials: 'MG',
    code: '3123403',
  },
  {
    name: 'DORMENTES',
    initials: 'PE',
    code: '2605152',
  },
  {
    name: 'DOURADINA',
    initials: 'MS',
    code: '5003504',
  },
  {
    name: 'DOURADINA',
    initials: 'PR',
    code: '4107256',
  },
  {
    name: 'DOURADO',
    initials: 'SP',
    code: '3514304',
  },
  {
    name: 'DOURADOQUARA',
    initials: 'MG',
    code: '3123502',
  },
  {
    name: 'DOURADOS',
    initials: 'MS',
    code: '5003702',
  },
  {
    name: 'DOUTOR CAMARGO',
    initials: 'PR',
    code: '4107306',
  },
  {
    name: 'DOUTOR MAURÍCIO CARDOSO',
    initials: 'RS',
    code: '4306734',
  },
  {
    name: 'DOUTOR PEDRINHO',
    initials: 'SC',
    code: '4205159',
  },
  {
    name: 'DOUTOR RICARDO',
    initials: 'RS',
    code: '4306759',
  },
  {
    name: 'DOUTOR SEVERIANO',
    initials: 'RN',
    code: '2403202',
  },
  {
    name: 'DOUTOR ULYSSES',
    initials: 'PR',
    code: '4128633',
  },
  {
    name: 'DOVERLÂNDIA',
    initials: 'GO',
    code: '5207253',
  },
  {
    name: 'DRACENA',
    initials: 'SP',
    code: '3514403',
  },
  {
    name: 'DUARTINA',
    initials: 'SP',
    code: '3514502',
  },
  {
    name: 'DUAS BARRAS',
    initials: 'RJ',
    code: '3301603',
  },
  {
    name: 'DUAS ESTRADAS',
    initials: 'PB',
    code: '2505808',
  },
  {
    name: 'DUERÊ',
    initials: 'TO',
    code: '1707306',
  },
  {
    name: 'DUMONT',
    initials: 'SP',
    code: '3514601',
  },
  {
    name: 'DUQUE BACELAR',
    initials: 'MA',
    code: '2103901',
  },
  {
    name: 'DUQUE DE CAXIAS',
    initials: 'RJ',
    code: '3301702',
  },
  {
    name: 'DURANDÉ',
    initials: 'MG',
    code: '3123528',
  },
  {
    name: 'ECHAPORÃ',
    initials: 'SP',
    code: '3514700',
  },
  {
    name: 'ECOPORANGA',
    initials: 'ES',
    code: '3202108',
  },
  {
    name: 'EDEALINA',
    initials: 'GO',
    code: '5207352',
  },
  {
    name: 'EDÉIA',
    initials: 'GO',
    code: '5207402',
  },
  {
    name: 'EIRUNEPÉ',
    initials: 'AM',
    code: '1301407',
  },
  {
    name: 'ELDORADO',
    initials: 'SP',
    code: '3514809',
  },
  {
    name: 'ELDORADO',
    initials: 'MS',
    code: '5003751',
  },
  {
    name: 'ELDORADO DOS CARAJÁS',
    initials: 'PA',
    code: '1502954',
  },
  {
    name: 'ELDORADO DO SUL',
    initials: 'RS',
    code: '4306767',
  },
  {
    name: 'ELESBÃO VELOSO',
    initials: 'PI',
    code: '2203503',
  },
  {
    name: 'ELIAS FAUSTO',
    initials: 'SP',
    code: '3514908',
  },
  {
    name: 'ELISEU MARTINS',
    initials: 'PI',
    code: '2203602',
  },
  {
    name: 'ELISIÁRIO',
    initials: 'SP',
    code: '3514924',
  },
  {
    name: 'ELÍSIO MEDRADO',
    initials: 'BA',
    code: '2910305',
  },
  {
    name: 'ELÓI MENDES',
    initials: 'MG',
    code: '3123601',
  },
  {
    name: 'EMAS',
    initials: 'PB',
    code: '2505907',
  },
  {
    name: 'EMBAÚBA',
    initials: 'SP',
    code: '3514957',
  },
  {
    name: 'EMBU DAS ARTES',
    initials: 'SP',
    code: '3515004',
  },
  {
    name: 'EMBU-GUAÇU',
    initials: 'SP',
    code: '3515103',
  },
  {
    name: 'EMILIANÓPOLIS',
    initials: 'SP',
    code: '3515129',
  },
  {
    name: 'ENCANTADO',
    initials: 'RS',
    code: '4306809',
  },
  {
    name: 'ENCANTO',
    initials: 'RN',
    code: '2403301',
  },
  {
    name: 'ENCRUZILHADA',
    initials: 'BA',
    code: '2910404',
  },
  {
    name: 'ENCRUZILHADA DO SUL',
    initials: 'RS',
    code: '4306908',
  },
  {
    name: 'ENÉAS MARQUES',
    initials: 'PR',
    code: '4107405',
  },
  {
    name: 'ENGENHEIRO BELTRÃO',
    initials: 'PR',
    code: '4107504',
  },
  {
    name: 'ENGENHEIRO CALDAS',
    initials: 'MG',
    code: '3123700',
  },
  {
    name: 'ENGENHEIRO COELHO',
    initials: 'SP',
    code: '3515152',
  },
  {
    name: 'ENGENHEIRO NAVARRO',
    initials: 'MG',
    code: '3123809',
  },
  {
    name: 'ENGENHEIRO PAULO DE FRONTIN',
    initials: 'RJ',
    code: '3301801',
  },
  {
    name: 'ENGENHO VELHO',
    initials: 'RS',
    code: '4306924',
  },
  {
    name: 'ENTRE FOLHAS',
    initials: 'MG',
    code: '3123858',
  },
  {
    name: 'ENTRE-IJUÍS',
    initials: 'RS',
    code: '4306932',
  },
  {
    name: 'ENTRE RIOS',
    initials: 'BA',
    code: '2910503',
  },
  {
    name: 'ENTRE RIOS',
    initials: 'SC',
    code: '4205175',
  },
  {
    name: 'ENTRE RIOS DE MINAS',
    initials: 'MG',
    code: '3123908',
  },
  {
    name: 'ENTRE RIOS DO OESTE',
    initials: 'PR',
    code: '4107538',
  },
  {
    name: 'ENTRE RIOS DO SUL',
    initials: 'RS',
    code: '4306957',
  },
  {
    name: 'ENVIRA',
    initials: 'AM',
    code: '1301506',
  },
  {
    name: 'EPITACIOLÂNDIA',
    initials: 'AC',
    code: '1200252',
  },
  {
    name: 'EQUADOR',
    initials: 'RN',
    code: '2403400',
  },
  {
    name: 'EREBANGO',
    initials: 'RS',
    code: '4306973',
  },
  {
    name: 'ERECHIM',
    initials: 'RS',
    code: '4307005',
  },
  {
    name: 'ERERÊ',
    initials: 'CE',
    code: '2304277',
  },
  {
    name: 'ÉRICO CARDOSO',
    initials: 'BA',
    code: '2900504',
  },
  {
    name: 'ERMO',
    initials: 'SC',
    code: '4205191',
  },
  {
    name: 'ERNESTINA',
    initials: 'RS',
    code: '4307054',
  },
  {
    name: 'ERVAL GRANDE',
    initials: 'RS',
    code: '4307203',
  },
  {
    name: 'ERVÁLIA',
    initials: 'MG',
    code: '3124005',
  },
  {
    name: 'ERVAL SECO',
    initials: 'RS',
    code: '4307302',
  },
  {
    name: 'ERVAL VELHO',
    initials: 'SC',
    code: '4205209',
  },
  {
    name: 'ESCADA',
    initials: 'PE',
    code: '2605202',
  },
  {
    name: 'ESMERALDA',
    initials: 'RS',
    code: '4307401',
  },
  {
    name: 'ESMERALDAS',
    initials: 'MG',
    code: '3124104',
  },
  {
    name: 'ESPERA FELIZ',
    initials: 'MG',
    code: '3124203',
  },
  {
    name: 'ESPERANÇA',
    initials: 'PB',
    code: '2506004',
  },
  {
    name: 'ESPERANÇA DO SUL',
    initials: 'RS',
    code: '4307450',
  },
  {
    name: 'ESPERANÇA NOVA',
    initials: 'PR',
    code: '4107520',
  },
  {
    name: 'ESPERANTINA',
    initials: 'TO',
    code: '1707405',
  },
  {
    name: 'ESPERANTINA',
    initials: 'PI',
    code: '2203701',
  },
  {
    name: 'ESPERANTINÓPOLIS',
    initials: 'MA',
    code: '2104008',
  },
  {
    name: 'ESPIGÃO ALTO DO IGUAÇU',
    initials: 'PR',
    code: '4107546',
  },
  {
    name: 'ESPIGÃO DO OESTE',
    initials: 'RO',
    code: '1100098',
  },
  {
    name: 'ESPINOSA',
    initials: 'MG',
    code: '3124302',
  },
  {
    name: 'ESPÍRITO SANTO',
    initials: 'RN',
    code: '2403509',
  },
  {
    name: 'ESPÍRITO SANTO DO DOURADO',
    initials: 'MG',
    code: '3124401',
  },
  {
    name: 'ESPÍRITO SANTO DO PINHAL',
    initials: 'SP',
    code: '3515186',
  },
  {
    name: 'ESPÍRITO SANTO DO TURVO',
    initials: 'SP',
    code: '3515194',
  },
  {
    name: 'ESPLANADA',
    initials: 'BA',
    code: '2910602',
  },
  {
    name: 'ESPUMOSO',
    initials: 'RS',
    code: '4307500',
  },
  {
    name: 'ESTAÇÃO',
    initials: 'RS',
    code: '4307559',
  },
  {
    name: 'ESTÂNCIA',
    initials: 'SE',
    code: '2802106',
  },
  {
    name: 'ESTÂNCIA VELHA',
    initials: 'RS',
    code: '4307609',
  },
  {
    name: 'ESTEIO',
    initials: 'RS',
    code: '4307708',
  },
  {
    name: 'ESTIVA',
    initials: 'MG',
    code: '3124500',
  },
  {
    name: 'ESTIVA GERBI',
    initials: 'SP',
    code: '3557303',
  },
  {
    name: 'ESTREITO',
    initials: 'MA',
    code: '2104057',
  },
  {
    name: 'ESTRELA',
    initials: 'RS',
    code: '4307807',
  },
  {
    name: 'ESTRELA DALVA',
    initials: 'MG',
    code: '3124609',
  },
  {
    name: 'ESTRELA DE ALAGOAS',
    initials: 'AL',
    code: '2702553',
  },
  {
    name: 'ESTRELA D\'OESTE',
    initials: 'SP',
    code: '3515202',
  },
  {
    name: 'ESTRELA DO INDAIÁ',
    initials: 'MG',
    code: '3124708',
  },
  {
    name: 'ESTRELA DO NORTE',
    initials: 'GO',
    code: '5207501',
  },
  {
    name: 'ESTRELA DO NORTE',
    initials: 'SP',
    code: '3515301',
  },
  {
    name: 'ESTRELA DO SUL',
    initials: 'MG',
    code: '3124807',
  },
  {
    name: 'ESTRELA VELHA',
    initials: 'RS',
    code: '4307815',
  },
  {
    name: 'EUCLIDES DA CUNHA',
    initials: 'BA',
    code: '2910701',
  },
  {
    name: 'EUCLIDES DA CUNHA PAULISTA',
    initials: 'SP',
    code: '3515350',
  },
  {
    name: 'EUGÊNIO DE CASTRO',
    initials: 'RS',
    code: '4307831',
  },
  {
    name: 'EUGENÓPOLIS',
    initials: 'MG',
    code: '3124906',
  },
  {
    name: 'EUNÁPOLIS',
    initials: 'BA',
    code: '2910727',
  },
  {
    name: 'EUSÉBIO',
    initials: 'CE',
    code: '2304285',
  },
  {
    name: 'EWBANK DA CÂMARA',
    initials: 'MG',
    code: '3125002',
  },
  {
    name: 'EXTREMA',
    initials: 'MG',
    code: '3125101',
  },
  {
    name: 'EXTREMOZ',
    initials: 'RN',
    code: '2403608',
  },
  {
    name: 'EXU',
    initials: 'PE',
    code: '2605301',
  },
  {
    name: 'FAGUNDES',
    initials: 'PB',
    code: '2506103',
  },
  {
    name: 'FAGUNDES VARELA',
    initials: 'RS',
    code: '4307864',
  },
  {
    name: 'FAINA',
    initials: 'GO',
    code: '5207535',
  },
  {
    name: 'FAMA',
    initials: 'MG',
    code: '3125200',
  },
  {
    name: 'FARIA LEMOS',
    initials: 'MG',
    code: '3125309',
  },
  {
    name: 'FARIAS BRITO',
    initials: 'CE',
    code: '2304301',
  },
  {
    name: 'FARO',
    initials: 'PA',
    code: '1503002',
  },
  {
    name: 'FAROL',
    initials: 'PR',
    code: '4107553',
  },
  {
    name: 'FARROUPILHA',
    initials: 'RS',
    code: '4307906',
  },
  {
    name: 'FARTURA',
    initials: 'SP',
    code: '3515400',
  },
  {
    name: 'FARTURA DO PIAUÍ',
    initials: 'PI',
    code: '2203750',
  },
  {
    name: 'FÁTIMA',
    initials: 'BA',
    code: '2910750',
  },
  {
    name: 'FÁTIMA',
    initials: 'TO',
    code: '1707553',
  },
  {
    name: 'FÁTIMA DO SUL',
    initials: 'MS',
    code: '5003801',
  },
  {
    name: 'FAXINAL',
    initials: 'PR',
    code: '4107603',
  },
  {
    name: 'FAXINAL DOS GUEDES',
    initials: 'SC',
    code: '4205308',
  },
  {
    name: 'FAXINAL DO SOTURNO',
    initials: 'RS',
    code: '4308003',
  },
  {
    name: 'FAXINALZINHO',
    initials: 'RS',
    code: '4308052',
  },
  {
    name: 'FAZENDA NOVA',
    initials: 'GO',
    code: '5207600',
  },
  {
    name: 'FAZENDA RIO GRANDE',
    initials: 'PR',
    code: '4107652',
  },
  {
    name: 'FAZENDA VILANOVA',
    initials: 'RS',
    code: '4308078',
  },
  {
    name: 'FEIJÓ',
    initials: 'AC',
    code: '1200302',
  },
  {
    name: 'FEIRA DA MATA',
    initials: 'BA',
    code: '2910776',
  },
  {
    name: 'FEIRA DE SANTANA',
    initials: 'BA',
    code: '2910800',
  },
  {
    name: 'FEIRA GRANDE',
    initials: 'AL',
    code: '2702603',
  },
  {
    name: 'FEIRA NOVA',
    initials: 'SE',
    code: '2802205',
  },
  {
    name: 'FEIRA NOVA',
    initials: 'PE',
    code: '2605400',
  },
  {
    name: 'FEIRA NOVA DO MARANHÃO',
    initials: 'MA',
    code: '2104073',
  },
  {
    name: 'FELÍCIO DOS SANTOS',
    initials: 'MG',
    code: '3125408',
  },
  {
    name: 'FELIPE GUERRA',
    initials: 'RN',
    code: '2403707',
  },
  {
    name: 'FELISBURGO',
    initials: 'MG',
    code: '3125606',
  },
  {
    name: 'FELIXLÂNDIA',
    initials: 'MG',
    code: '3125705',
  },
  {
    name: 'FELIZ',
    initials: 'RS',
    code: '4308102',
  },
  {
    name: 'FELIZ DESERTO',
    initials: 'AL',
    code: '2702702',
  },
  {
    name: 'FELIZ NATAL',
    initials: 'MT',
    code: '5103700',
  },
  {
    name: 'FÊNIX',
    initials: 'PR',
    code: '4107702',
  },
  {
    name: 'FERNANDES PINHEIRO',
    initials: 'PR',
    code: '4107736',
  },
  {
    name: 'FERNANDES TOURINHO',
    initials: 'MG',
    code: '3125804',
  },
  {
    name: 'FERNANDO DE NORONHA',
    initials: 'PE',
    code: '2605459',
  },
  {
    name: 'FERNANDO FALCÃO',
    initials: 'MA',
    code: '2104081',
  },
  {
    name: 'FERNANDO PEDROZA',
    initials: 'RN',
    code: '2403756',
  },
  {
    name: 'FERNANDÓPOLIS',
    initials: 'SP',
    code: '3515509',
  },
  {
    name: 'FERNANDO PRESTES',
    initials: 'SP',
    code: '3515608',
  },
  {
    name: 'FERNÃO',
    initials: 'SP',
    code: '3515657',
  },
  {
    name: 'FERRAZ DE VASCONCELOS',
    initials: 'SP',
    code: '3515707',
  },
  {
    name: 'FERREIRA GOMES',
    initials: 'AP',
    code: '1600238',
  },
  {
    name: 'FERREIROS',
    initials: 'PE',
    code: '2605509',
  },
  {
    name: 'FERROS',
    initials: 'MG',
    code: '3125903',
  },
  {
    name: 'FERVEDOURO',
    initials: 'MG',
    code: '3125952',
  },
  {
    name: 'FIGUEIRA',
    initials: 'PR',
    code: '4107751',
  },
  {
    name: 'FIGUEIRÃO',
    initials: 'MS',
    code: '5003900',
  },
  {
    name: 'FIGUEIRÓPOLIS',
    initials: 'TO',
    code: '1707652',
  },
  {
    name: 'FIGUEIRÓPOLIS D\'OESTE',
    initials: 'MT',
    code: '5103809',
  },
  {
    name: 'FILADÉLFIA',
    initials: 'BA',
    code: '2910859',
  },
  {
    name: 'FILADÉLFIA',
    initials: 'TO',
    code: '1707702',
  },
  {
    name: 'FIRMINO ALVES',
    initials: 'BA',
    code: '2910909',
  },
  {
    name: 'FIRMINÓPOLIS',
    initials: 'GO',
    code: '5207808',
  },
  {
    name: 'FLEXEIRAS',
    initials: 'AL',
    code: '2702801',
  },
  {
    name: 'FLORAÍ',
    initials: 'PR',
    code: '4107801',
  },
  {
    name: 'FLORÂNIA',
    initials: 'RN',
    code: '2403806',
  },
  {
    name: 'FLORA RICA',
    initials: 'SP',
    code: '3515806',
  },
  {
    name: 'FLOR DA SERRA DO SUL',
    initials: 'PR',
    code: '4107850',
  },
  {
    name: 'FLOR DO SERTÃO',
    initials: 'SC',
    code: '4205357',
  },
  {
    name: 'FLOREAL',
    initials: 'SP',
    code: '3515905',
  },
  {
    name: 'FLORES',
    initials: 'PE',
    code: '2605608',
  },
  {
    name: 'FLORES DA CUNHA',
    initials: 'RS',
    code: '4308201',
  },
  {
    name: 'FLORES DE GOIÁS',
    initials: 'GO',
    code: '5207907',
  },
  {
    name: 'FLORES DO PIAUÍ',
    initials: 'PI',
    code: '2203800',
  },
  {
    name: 'FLORESTA',
    initials: 'PE',
    code: '2605707',
  },
  {
    name: 'FLORESTA',
    initials: 'PR',
    code: '4107900',
  },
  {
    name: 'FLORESTA AZUL',
    initials: 'BA',
    code: '2911006',
  },
  {
    name: 'FLORESTA DO ARAGUAIA',
    initials: 'PA',
    code: '1503044',
  },
  {
    name: 'FLORESTA DO PIAUÍ',
    initials: 'PI',
    code: '2203859',
  },
  {
    name: 'FLORESTAL',
    initials: 'MG',
    code: '3126000',
  },
  {
    name: 'FLORESTÓPOLIS',
    initials: 'PR',
    code: '4108007',
  },
  {
    name: 'FLORIANO',
    initials: 'PI',
    code: '2203909',
  },
  {
    name: 'FLORIANO PEIXOTO',
    initials: 'RS',
    code: '4308250',
  },
  {
    name: 'FLORIANÓPOLIS',
    initials: 'SC',
    code: '4205407',
  },
  {
    name: 'FLÓRIDA',
    initials: 'PR',
    code: '4108106',
  },
  {
    name: 'FLÓRIDA PAULISTA',
    initials: 'SP',
    code: '3516002',
  },
  {
    name: 'FLORÍNIA',
    initials: 'SP',
    code: '3516101',
  },
  {
    name: 'FONTE BOA',
    initials: 'AM',
    code: '1301605',
  },
  {
    name: 'FONTOURA XAVIER',
    initials: 'RS',
    code: '4308300',
  },
  {
    name: 'FORMIGA',
    initials: 'MG',
    code: '3126109',
  },
  {
    name: 'FORMIGUEIRO',
    initials: 'RS',
    code: '4308409',
  },
  {
    name: 'FORMOSA',
    initials: 'GO',
    code: '5208004',
  },
  {
    name: 'FORMOSA DA SERRA NEGRA',
    initials: 'MA',
    code: '2104099',
  },
  {
    name: 'FORMOSA DO OESTE',
    initials: 'PR',
    code: '4108205',
  },
  {
    name: 'FORMOSA DO RIO PRETO',
    initials: 'BA',
    code: '2911105',
  },
  {
    name: 'FORMOSA DO SUL',
    initials: 'SC',
    code: '4205431',
  },
  {
    name: 'FORMOSO',
    initials: 'MG',
    code: '3126208',
  },
  {
    name: 'FORMOSO',
    initials: 'GO',
    code: '5208103',
  },
  {
    name: 'FORMOSO DO ARAGUAIA',
    initials: 'TO',
    code: '1708205',
  },
  {
    name: 'FORQUETINHA',
    initials: 'RS',
    code: '4308433',
  },
  {
    name: 'FORQUILHA',
    initials: 'CE',
    code: '2304350',
  },
  {
    name: 'FORQUILHINHA',
    initials: 'SC',
    code: '4205456',
  },
  {
    name: 'FORTALEZA',
    initials: 'CE',
    code: '2304400',
  },
  {
    name: 'FORTALEZA DE MINAS',
    initials: 'MG',
    code: '3126307',
  },
  {
    name: 'FORTALEZA DOS NOGUEIRAS',
    initials: 'MA',
    code: '2104107',
  },
  {
    name: 'FORTALEZA DOS VALOS',
    initials: 'RS',
    code: '4308458',
  },
  {
    name: 'FORTALEZA DO TABOCÃO',
    initials: 'TO',
    code: '1708254',
  },
  {
    name: 'FORTIM',
    initials: 'CE',
    code: '2304459',
  },
  {
    name: 'FORTUNA',
    initials: 'MA',
    code: '2104206',
  },
  {
    name: 'FORTUNA DE MINAS',
    initials: 'MG',
    code: '3126406',
  },
  {
    name: 'FOZ DO IGUAÇU',
    initials: 'PR',
    code: '4108304',
  },
  {
    name: 'FOZ DO JORDÃO',
    initials: 'PR',
    code: '4108452',
  },
  {
    name: 'FRAIBURGO',
    initials: 'SC',
    code: '4205506',
  },
  {
    name: 'FRANCA',
    initials: 'SP',
    code: '3516200',
  },
  {
    name: 'FRANCINÓPOLIS',
    initials: 'PI',
    code: '2204006',
  },
  {
    name: 'FRANCISCO ALVES',
    initials: 'PR',
    code: '4108320',
  },
  {
    name: 'FRANCISCO AYRES',
    initials: 'PI',
    code: '2204105',
  },
  {
    name: 'FRANCISCO BADARÓ',
    initials: 'MG',
    code: '3126505',
  },
  {
    name: 'FRANCISCO BELTRÃO',
    initials: 'PR',
    code: '4108403',
  },
  {
    name: 'FRANCISCO DANTAS',
    initials: 'RN',
    code: '2403905',
  },
  {
    name: 'FRANCISCO DUMONT',
    initials: 'MG',
    code: '3126604',
  },
  {
    name: 'FRANCISCO MACEDO',
    initials: 'PI',
    code: '2204154',
  },
  {
    name: 'FRANCISCO MORATO',
    initials: 'SP',
    code: '3516309',
  },
  {
    name: 'FRANCISCÓPOLIS',
    initials: 'MG',
    code: '3126752',
  },
  {
    name: 'FRANCISCO SÁ',
    initials: 'MG',
    code: '3126703',
  },
  {
    name: 'FRANCISCO SANTOS',
    initials: 'PI',
    code: '2204204',
  },
  {
    name: 'FRANCO DA ROCHA',
    initials: 'SP',
    code: '3516408',
  },
  {
    name: 'FRECHEIRINHA',
    initials: 'CE',
    code: '2304509',
  },
  {
    name: 'FREDERICO WESTPHALEN',
    initials: 'RS',
    code: '4308508',
  },
  {
    name: 'FREI GASPAR',
    initials: 'MG',
    code: '3126802',
  },
  {
    name: 'FREI INOCÊNCIO',
    initials: 'MG',
    code: '3126901',
  },
  {
    name: 'FREI LAGONEGRO',
    initials: 'MG',
    code: '3126950',
  },
  {
    name: 'FREI MARTINHO',
    initials: 'PB',
    code: '2506202',
  },
  {
    name: 'FREI MIGUELINHO',
    initials: 'PE',
    code: '2605806',
  },
  {
    name: 'FREI PAULO',
    initials: 'SE',
    code: '2802304',
  },
  {
    name: 'FREI ROGÉRIO',
    initials: 'SC',
    code: '4205555',
  },
  {
    name: 'FRONTEIRA',
    initials: 'MG',
    code: '3127008',
  },
  {
    name: 'FRONTEIRA DOS VALES',
    initials: 'MG',
    code: '3127057',
  },
  {
    name: 'FRONTEIRAS',
    initials: 'PI',
    code: '2204303',
  },
  {
    name: 'FRUTA DE LEITE',
    initials: 'MG',
    code: '3127073',
  },
  {
    name: 'FRUTAL',
    initials: 'MG',
    code: '3127107',
  },
  {
    name: 'FRUTUOSO GOMES',
    initials: 'RN',
    code: '2404002',
  },
  {
    name: 'FUNDÃO',
    initials: 'ES',
    code: '3202207',
  },
  {
    name: 'FUNILÂNDIA',
    initials: 'MG',
    code: '3127206',
  },
  {
    name: 'GABRIEL MONTEIRO',
    initials: 'SP',
    code: '3516507',
  },
  {
    name: 'GADO BRAVO',
    initials: 'PB',
    code: '2506251',
  },
  {
    name: 'GÁLIA',
    initials: 'SP',
    code: '3516606',
  },
  {
    name: 'GALILÉIA',
    initials: 'MG',
    code: '3127305',
  },
  {
    name: 'GALINHOS',
    initials: 'RN',
    code: '2404101',
  },
  {
    name: 'GALVÃO',
    initials: 'SC',
    code: '4205605',
  },
  {
    name: 'GAMELEIRA',
    initials: 'PE',
    code: '2605905',
  },
  {
    name: 'GAMELEIRA DE GOIÁS',
    initials: 'GO',
    code: '5208152',
  },
  {
    name: 'GAMELEIRAS',
    initials: 'MG',
    code: '3127339',
  },
  {
    name: 'GANDU',
    initials: 'BA',
    code: '2911204',
  },
  {
    name: 'GARANHUNS',
    initials: 'PE',
    code: '2606002',
  },
  {
    name: 'GARARU',
    initials: 'SE',
    code: '2802403',
  },
  {
    name: 'GARÇA',
    initials: 'SP',
    code: '3516705',
  },
  {
    name: 'GARIBALDI',
    initials: 'RS',
    code: '4308607',
  },
  {
    name: 'GAROPABA',
    initials: 'SC',
    code: '4205704',
  },
  {
    name: 'GARRAFÃO DO NORTE',
    initials: 'PA',
    code: '1503077',
  },
  {
    name: 'GARRUCHOS',
    initials: 'RS',
    code: '4308656',
  },
  {
    name: 'GARUVA',
    initials: 'SC',
    code: '4205803',
  },
  {
    name: 'GASPAR',
    initials: 'SC',
    code: '4205902',
  },
  {
    name: 'GASTÃO VIDIGAL',
    initials: 'SP',
    code: '3516804',
  },
  {
    name: 'GAÚCHA DO NORTE',
    initials: 'MT',
    code: '5103858',
  },
  {
    name: 'GAURAMA',
    initials: 'RS',
    code: '4308706',
  },
  {
    name: 'GAVIÃO',
    initials: 'BA',
    code: '2911253',
  },
  {
    name: 'GAVIÃO PEIXOTO',
    initials: 'SP',
    code: '3516853',
  },
  {
    name: 'GEMINIANO',
    initials: 'PI',
    code: '2204352',
  },
  {
    name: 'GENERAL CÂMARA',
    initials: 'RS',
    code: '4308805',
  },
  {
    name: 'GENERAL CARNEIRO',
    initials: 'MT',
    code: '5103908',
  },
  {
    name: 'GENERAL CARNEIRO',
    initials: 'PR',
    code: '4108502',
  },
  {
    name: 'GENERAL MAYNARD',
    initials: 'SE',
    code: '2802502',
  },
  {
    name: 'GENERAL SALGADO',
    initials: 'SP',
    code: '3516903',
  },
  {
    name: 'GENERAL SAMPAIO',
    initials: 'CE',
    code: '2304608',
  },
  {
    name: 'GENTIL',
    initials: 'RS',
    code: '4308854',
  },
  {
    name: 'GENTIO DO OURO',
    initials: 'BA',
    code: '2911303',
  },
  {
    name: 'GETULINA',
    initials: 'SP',
    code: '3517000',
  },
  {
    name: 'GETÚLIO VARGAS',
    initials: 'RS',
    code: '4308904',
  },
  {
    name: 'GILBUÉS',
    initials: 'PI',
    code: '2204402',
  },
  {
    name: 'GIRAU DO PONCIANO',
    initials: 'AL',
    code: '2702900',
  },
  {
    name: 'GIRUÁ',
    initials: 'RS',
    code: '4309001',
  },
  {
    name: 'GLAUCILÂNDIA',
    initials: 'MG',
    code: '3127354',
  },
  {
    name: 'GLICÉRIO',
    initials: 'SP',
    code: '3517109',
  },
  {
    name: 'GLÓRIA',
    initials: 'BA',
    code: '2911402',
  },
  {
    name: 'GLÓRIA DE DOURADOS',
    initials: 'MS',
    code: '5004007',
  },
  {
    name: 'GLÓRIA D\'OESTE',
    initials: 'MT',
    code: '5103957',
  },
  {
    name: 'GLÓRIA DO GOITÁ',
    initials: 'PE',
    code: '2606101',
  },
  {
    name: 'GLORINHA',
    initials: 'RS',
    code: '4309050',
  },
  {
    name: 'GODOFREDO VIANA',
    initials: 'MA',
    code: '2104305',
  },
  {
    name: 'GODOY MOREIRA',
    initials: 'PR',
    code: '4108551',
  },
  {
    name: 'GOIABEIRA',
    initials: 'MG',
    code: '3127370',
  },
  {
    name: 'GOIANA',
    initials: 'PE',
    code: '2606200',
  },
  {
    name: 'GOIANÁ',
    initials: 'MG',
    code: '3127388',
  },
  {
    name: 'GOIANÁPOLIS',
    initials: 'GO',
    code: '5208400',
  },
  {
    name: 'GOIANDIRA',
    initials: 'GO',
    code: '5208509',
  },
  {
    name: 'GOIANÉSIA',
    initials: 'GO',
    code: '5208608',
  },
  {
    name: 'GOIANÉSIA DO PARÁ',
    initials: 'PA',
    code: '1503093',
  },
  {
    name: 'GOIÂNIA',
    initials: 'GO',
    code: '5208707',
  },
  {
    name: 'GOIANINHA',
    initials: 'RN',
    code: '2404200',
  },
  {
    name: 'GOIANIRA',
    initials: 'GO',
    code: '5208806',
  },
  {
    name: 'GOIANORTE',
    initials: 'TO',
    code: '1708304',
  },
  {
    name: 'GOIÁS',
    initials: 'GO',
    code: '5208905',
  },
  {
    name: 'GOIATINS',
    initials: 'TO',
    code: '1709005',
  },
  {
    name: 'GOIATUBA',
    initials: 'GO',
    code: '5209101',
  },
  {
    name: 'GOIOERÊ',
    initials: 'PR',
    code: '4108601',
  },
  {
    name: 'GOIOXIM',
    initials: 'PR',
    code: '4108650',
  },
  {
    name: 'GONÇALVES',
    initials: 'MG',
    code: '3127404',
  },
  {
    name: 'GONÇALVES DIAS',
    initials: 'MA',
    code: '2104404',
  },
  {
    name: 'GONGOGI',
    initials: 'BA',
    code: '2911501',
  },
  {
    name: 'GONZAGA',
    initials: 'MG',
    code: '3127503',
  },
  {
    name: 'GOUVEIA',
    initials: 'MG',
    code: '3127602',
  },
  {
    name: 'GOUVELÂNDIA',
    initials: 'GO',
    code: '5209150',
  },
  {
    name: 'GOVERNADOR ARCHER',
    initials: 'MA',
    code: '2104503',
  },
  {
    name: 'GOVERNADOR CELSO RAMOS',
    initials: 'SC',
    code: '4206009',
  },
  {
    name: 'GOVERNADOR DIX-SEPT ROSADO',
    initials: 'RN',
    code: '2404309',
  },
  {
    name: 'GOVERNADOR EDISON LOBÃO',
    initials: 'MA',
    code: '2104552',
  },
  {
    name: 'GOVERNADOR EUGÊNIO BARROS',
    initials: 'MA',
    code: '2104602',
  },
  {
    name: 'GOVERNADOR JORGE TEIXEIRA',
    initials: 'RO',
    code: '1101005',
  },
  {
    name: 'GOVERNADOR LINDENBERG',
    initials: 'ES',
    code: '3202256',
  },
  {
    name: 'GOVERNADOR LUIZ ROCHA',
    initials: 'MA',
    code: '2104628',
  },
  {
    name: 'GOVERNADOR MANGABEIRA',
    initials: 'BA',
    code: '2911600',
  },
  {
    name: 'GOVERNADOR NEWTON BELLO',
    initials: 'MA',
    code: '2104651',
  },
  {
    name: 'GOVERNADOR NUNES FREIRE',
    initials: 'MA',
    code: '2104677',
  },
  {
    name: 'GOVERNADOR VALADARES',
    initials: 'MG',
    code: '3127701',
  },
  {
    name: 'GRAÇA',
    initials: 'CE',
    code: '2304657',
  },
  {
    name: 'GRAÇA ARANHA',
    initials: 'MA',
    code: '2104701',
  },
  {
    name: 'GRACCHO CARDOSO',
    initials: 'SE',
    code: '2802601',
  },
  {
    name: 'GRAJAÚ',
    initials: 'MA',
    code: '2104800',
  },
  {
    name: 'GRAMADO',
    initials: 'RS',
    code: '4309100',
  },
  {
    name: 'GRAMADO DOS LOUREIROS',
    initials: 'RS',
    code: '4309126',
  },
  {
    name: 'GRAMADO XAVIER',
    initials: 'RS',
    code: '4309159',
  },
  {
    name: 'GRANDES RIOS',
    initials: 'PR',
    code: '4108700',
  },
  {
    name: 'GRANITO',
    initials: 'PE',
    code: '2606309',
  },
  {
    name: 'GRANJA',
    initials: 'CE',
    code: '2304707',
  },
  {
    name: 'GRANJEIRO',
    initials: 'CE',
    code: '2304806',
  },
  {
    name: 'GRÃO MOGOL',
    initials: 'MG',
    code: '3127800',
  },
  {
    name: 'GRÃO PARÁ',
    initials: 'SC',
    code: '4206108',
  },
  {
    name: 'GRAVATÁ',
    initials: 'PE',
    code: '2606408',
  },
  {
    name: 'GRAVATAÍ',
    initials: 'RS',
    code: '4309209',
  },
  {
    name: 'GRAVATAL',
    initials: 'SC',
    code: '4206207',
  },
  {
    name: 'GROAIRAS',
    initials: 'CE',
    code: '2304905',
  },
  {
    name: 'GROSSOS',
    initials: 'RN',
    code: '2404408',
  },
  {
    name: 'GRUPIARA',
    initials: 'MG',
    code: '3127909',
  },
  {
    name: 'GUABIJU',
    initials: 'RS',
    code: '4309258',
  },
  {
    name: 'GUABIRUBA',
    initials: 'SC',
    code: '4206306',
  },
  {
    name: 'GUAÇUÍ',
    initials: 'ES',
    code: '3202306',
  },
  {
    name: 'GUADALUPE',
    initials: 'PI',
    code: '2204501',
  },
  {
    name: 'GUAÍBA',
    initials: 'RS',
    code: '4309308',
  },
  {
    name: 'GUAIÇARA',
    initials: 'SP',
    code: '3517208',
  },
  {
    name: 'GUAIMBÊ',
    initials: 'SP',
    code: '3517307',
  },
  {
    name: 'GUAÍRA',
    initials: 'PR',
    code: '4108809',
  },
  {
    name: 'GUAÍRA',
    initials: 'SP',
    code: '3517406',
  },
  {
    name: 'GUAIRAÇÁ',
    initials: 'PR',
    code: '4108908',
  },
  {
    name: 'GUAIÚBA',
    initials: 'CE',
    code: '2304954',
  },
  {
    name: 'GUAJARÁ',
    initials: 'AM',
    code: '1301654',
  },
  {
    name: 'GUAJARÁ-MIRIM',
    initials: 'RO',
    code: '1100106',
  },
  {
    name: 'GUAJERU',
    initials: 'BA',
    code: '2911659',
  },
  {
    name: 'GUAMARÉ',
    initials: 'RN',
    code: '2404507',
  },
  {
    name: 'GUAMIRANGA',
    initials: 'PR',
    code: '4108957',
  },
  {
    name: 'GUANAMBI',
    initials: 'BA',
    code: '2911709',
  },
  {
    name: 'GUANHÃES',
    initials: 'MG',
    code: '3128006',
  },
  {
    name: 'GUAPÉ',
    initials: 'MG',
    code: '3128105',
  },
  {
    name: 'GUAPIAÇU',
    initials: 'SP',
    code: '3517505',
  },
  {
    name: 'GUAPIARA',
    initials: 'SP',
    code: '3517604',
  },
  {
    name: 'GUAPIMIRIM',
    initials: 'RJ',
    code: '3301850',
  },
  {
    name: 'GUAPIRAMA',
    initials: 'PR',
    code: '4109005',
  },
  {
    name: 'GUAPÓ',
    initials: 'GO',
    code: '5209200',
  },
  {
    name: 'GUAPORÉ',
    initials: 'RS',
    code: '4309407',
  },
  {
    name: 'GUAPOREMA',
    initials: 'PR',
    code: '4109104',
  },
  {
    name: 'GUARÁ',
    initials: 'SP',
    code: '3517703',
  },
  {
    name: 'GUARABIRA',
    initials: 'PB',
    code: '2506301',
  },
  {
    name: 'GUARAÇAÍ',
    initials: 'SP',
    code: '3517802',
  },
  {
    name: 'GUARACI',
    initials: 'SP',
    code: '3517901',
  },
  {
    name: 'GUARACI',
    initials: 'PR',
    code: '4109203',
  },
  {
    name: 'GUARACIABA',
    initials: 'SC',
    code: '4206405',
  },
  {
    name: 'GUARACIABA',
    initials: 'MG',
    code: '3128204',
  },
  {
    name: 'GUARACIABA DO NORTE',
    initials: 'CE',
    code: '2305001',
  },
  {
    name: 'GUARACIAMA',
    initials: 'MG',
    code: '3128253',
  },
  {
    name: 'GUARAÍ',
    initials: 'TO',
    code: '1709302',
  },
  {
    name: 'GUARAÍTA',
    initials: 'GO',
    code: '5209291',
  },
  {
    name: 'GUARAMIRANGA',
    initials: 'CE',
    code: '2305100',
  },
  {
    name: 'GUARAMIRIM',
    initials: 'SC',
    code: '4206504',
  },
  {
    name: 'GUARANÉSIA',
    initials: 'MG',
    code: '3128303',
  },
  {
    name: 'GUARANI',
    initials: 'MG',
    code: '3128402',
  },
  {
    name: 'GUARANIAÇU',
    initials: 'PR',
    code: '4109302',
  },
  {
    name: 'GUARANI DAS MISSÕES',
    initials: 'RS',
    code: '4309506',
  },
  {
    name: 'GUARANI DE GOIÁS',
    initials: 'GO',
    code: '5209408',
  },
  {
    name: 'GUARANI D\'OESTE',
    initials: 'SP',
    code: '3518008',
  },
  {
    name: 'GUARANTÃ',
    initials: 'SP',
    code: '3518107',
  },
  {
    name: 'GUARANTÃ DO NORTE',
    initials: 'MT',
    code: '5104104',
  },
  {
    name: 'GUARAPARI',
    initials: 'ES',
    code: '3202405',
  },
  {
    name: 'GUARAPUAVA',
    initials: 'PR',
    code: '4109401',
  },
  {
    name: 'GUARAQUEÇABA',
    initials: 'PR',
    code: '4109500',
  },
  {
    name: 'GUARARÁ',
    initials: 'MG',
    code: '3128501',
  },
  {
    name: 'GUARARAPES',
    initials: 'SP',
    code: '3518206',
  },
  {
    name: 'GUARAREMA',
    initials: 'SP',
    code: '3518305',
  },
  {
    name: 'GUARATINGA',
    initials: 'BA',
    code: '2911808',
  },
  {
    name: 'GUARATINGUETÁ',
    initials: 'SP',
    code: '3518404',
  },
  {
    name: 'GUARATUBA',
    initials: 'PR',
    code: '4109609',
  },
  {
    name: 'GUARDA-MOR',
    initials: 'MG',
    code: '3128600',
  },
  {
    name: 'GUAREÍ',
    initials: 'SP',
    code: '3518503',
  },
  {
    name: 'GUARIBA',
    initials: 'SP',
    code: '3518602',
  },
  {
    name: 'GUARIBAS',
    initials: 'PI',
    code: '2204550',
  },
  {
    name: 'GUARINOS',
    initials: 'GO',
    code: '5209457',
  },
  {
    name: 'GUARUJÁ',
    initials: 'SP',
    code: '3518701',
  },
  {
    name: 'GUARUJÁ DO SUL',
    initials: 'SC',
    code: '4206603',
  },
  {
    name: 'GUARULHOS',
    initials: 'SP',
    code: '3518800',
  },
  {
    name: 'GUATAMBÚ',
    initials: 'SC',
    code: '4206652',
  },
  {
    name: 'GUATAPARÁ',
    initials: 'SP',
    code: '3518859',
  },
  {
    name: 'GUAXUPÉ',
    initials: 'MG',
    code: '3128709',
  },
  {
    name: 'GUIA LOPES DA LAGUNA',
    initials: 'MS',
    code: '5004106',
  },
  {
    name: 'GUIDOVAL',
    initials: 'MG',
    code: '3128808',
  },
  {
    name: 'GUIMARÃES',
    initials: 'MA',
    code: '2104909',
  },
  {
    name: 'GUIMARÂNIA',
    initials: 'MG',
    code: '3128907',
  },
  {
    name: 'GUIRATINGA',
    initials: 'MT',
    code: '5104203',
  },
  {
    name: 'GUIRICEMA',
    initials: 'MG',
    code: '3129004',
  },
  {
    name: 'GURINHATÃ',
    initials: 'MG',
    code: '3129103',
  },
  {
    name: 'GURINHÉM',
    initials: 'PB',
    code: '2506400',
  },
  {
    name: 'GURJÃO',
    initials: 'PB',
    code: '2506509',
  },
  {
    name: 'GURUPÁ',
    initials: 'PA',
    code: '1503101',
  },
  {
    name: 'GURUPI',
    initials: 'TO',
    code: '1709500',
  },
  {
    name: 'GUZOLÂNDIA',
    initials: 'SP',
    code: '3518909',
  },
  {
    name: 'HARMONIA',
    initials: 'RS',
    code: '4309555',
  },
  {
    name: 'HEITORAÍ',
    initials: 'GO',
    code: '5209606',
  },
  {
    name: 'HELIODORA',
    initials: 'MG',
    code: '3129202',
  },
  {
    name: 'HELIÓPOLIS',
    initials: 'BA',
    code: '2911857',
  },
  {
    name: 'HERCULÂNDIA',
    initials: 'SP',
    code: '3519006',
  },
  {
    name: 'HERVAL',
    initials: 'RS',
    code: '4307104',
  },
  {
    name: 'HERVAL D\'OESTE',
    initials: 'SC',
    code: '4206702',
  },
  {
    name: 'HERVEIRAS',
    initials: 'RS',
    code: '4309571',
  },
  {
    name: 'HIDROLÂNDIA',
    initials: 'GO',
    code: '5209705',
  },
  {
    name: 'HIDROLÂNDIA',
    initials: 'CE',
    code: '2305209',
  },
  {
    name: 'HIDROLINA',
    initials: 'GO',
    code: '5209804',
  },
  {
    name: 'HOLAMBRA',
    initials: 'SP',
    code: '3519055',
  },
  {
    name: 'HONÓRIO SERPA',
    initials: 'PR',
    code: '4109658',
  },
  {
    name: 'HORIZONTE',
    initials: 'CE',
    code: '2305233',
  },
  {
    name: 'HORIZONTINA',
    initials: 'RS',
    code: '4309605',
  },
  {
    name: 'HORTOLÂNDIA',
    initials: 'SP',
    code: '3519071',
  },
  {
    name: 'HUGO NAPOLEÃO',
    initials: 'PI',
    code: '2204600',
  },
  {
    name: 'HULHA NEGRA',
    initials: 'RS',
    code: '4309654',
  },
  {
    name: 'HUMAITÁ',
    initials: 'RS',
    code: '4309704',
  },
  {
    name: 'HUMAITÁ',
    initials: 'AM',
    code: '1301704',
  },
  {
    name: 'HUMBERTO DE CAMPOS',
    initials: 'MA',
    code: '2105005',
  },
  {
    name: 'IACANGA',
    initials: 'SP',
    code: '3519105',
  },
  {
    name: 'IACIARA',
    initials: 'GO',
    code: '5209903',
  },
  {
    name: 'IACRI',
    initials: 'SP',
    code: '3519204',
  },
  {
    name: 'IAÇU',
    initials: 'BA',
    code: '2911907',
  },
  {
    name: 'IAPU',
    initials: 'MG',
    code: '3129301',
  },
  {
    name: 'IARAS',
    initials: 'SP',
    code: '3519253',
  },
  {
    name: 'IATI',
    initials: 'PE',
    code: '2606507',
  },
  {
    name: 'IBAITI',
    initials: 'PR',
    code: '4109708',
  },
  {
    name: 'IBARAMA',
    initials: 'RS',
    code: '4309753',
  },
  {
    name: 'IBARETAMA',
    initials: 'CE',
    code: '2305266',
  },
  {
    name: 'IBATÉ',
    initials: 'SP',
    code: '3519303',
  },
  {
    name: 'IBATEGUARA',
    initials: 'AL',
    code: '2703007',
  },
  {
    name: 'IBATIBA',
    initials: 'ES',
    code: '3202454',
  },
  {
    name: 'IBEMA',
    initials: 'PR',
    code: '4109757',
  },
  {
    name: 'IBERTIOGA',
    initials: 'MG',
    code: '3129400',
  },
  {
    name: 'IBIÁ',
    initials: 'MG',
    code: '3129509',
  },
  {
    name: 'IBIAÇÁ',
    initials: 'RS',
    code: '4309803',
  },
  {
    name: 'IBIAÍ',
    initials: 'MG',
    code: '3129608',
  },
  {
    name: 'IBIAM',
    initials: 'SC',
    code: '4206751',
  },
  {
    name: 'IBIAPINA',
    initials: 'CE',
    code: '2305308',
  },
  {
    name: 'IBIARA',
    initials: 'PB',
    code: '2506608',
  },
  {
    name: 'IBIASSUCÊ',
    initials: 'BA',
    code: '2912004',
  },
  {
    name: 'IBICARAÍ',
    initials: 'BA',
    code: '2912103',
  },
  {
    name: 'IBICARÉ',
    initials: 'SC',
    code: '4206801',
  },
  {
    name: 'IBICOARA',
    initials: 'BA',
    code: '2912202',
  },
  {
    name: 'IBICUÍ',
    initials: 'BA',
    code: '2912301',
  },
  {
    name: 'IBICUITINGA',
    initials: 'CE',
    code: '2305332',
  },
  {
    name: 'IBIMIRIM',
    initials: 'PE',
    code: '2606606',
  },
  {
    name: 'IBIPEBA',
    initials: 'BA',
    code: '2912400',
  },
  {
    name: 'IBIPITANGA',
    initials: 'BA',
    code: '2912509',
  },
  {
    name: 'IBIPORÃ',
    initials: 'PR',
    code: '4109807',
  },
  {
    name: 'IBIQUERA',
    initials: 'BA',
    code: '2912608',
  },
  {
    name: 'IBIRÁ',
    initials: 'SP',
    code: '3519402',
  },
  {
    name: 'IBIRACATU',
    initials: 'MG',
    code: '3129657',
  },
  {
    name: 'IBIRACI',
    initials: 'MG',
    code: '3129707',
  },
  {
    name: 'IBIRAÇU',
    initials: 'ES',
    code: '3202504',
  },
  {
    name: 'IBIRAIARAS',
    initials: 'RS',
    code: '4309902',
  },
  {
    name: 'IBIRAJUBA',
    initials: 'PE',
    code: '2606705',
  },
  {
    name: 'IBIRAMA',
    initials: 'SC',
    code: '4206900',
  },
  {
    name: 'IBIRAPITANGA',
    initials: 'BA',
    code: '2912707',
  },
  {
    name: 'IBIRAPUÃ',
    initials: 'BA',
    code: '2912806',
  },
  {
    name: 'IBIRAPUITÃ',
    initials: 'RS',
    code: '4309951',
  },
  {
    name: 'IBIRAREMA',
    initials: 'SP',
    code: '3519501',
  },
  {
    name: 'IBIRATAIA',
    initials: 'BA',
    code: '2912905',
  },
  {
    name: 'IBIRITÉ',
    initials: 'MG',
    code: '3129806',
  },
  {
    name: 'IBIRUBÁ',
    initials: 'RS',
    code: '4310009',
  },
  {
    name: 'IBITIARA',
    initials: 'BA',
    code: '2913002',
  },
  {
    name: 'IBITINGA',
    initials: 'SP',
    code: '3519600',
  },
  {
    name: 'IBITIRAMA',
    initials: 'ES',
    code: '3202553',
  },
  {
    name: 'IBITITÁ',
    initials: 'BA',
    code: '2913101',
  },
  {
    name: 'IBITIÚRA DE MINAS',
    initials: 'MG',
    code: '3129905',
  },
  {
    name: 'IBITURUNA',
    initials: 'MG',
    code: '3130002',
  },
  {
    name: 'IBIÚNA',
    initials: 'SP',
    code: '3519709',
  },
  {
    name: 'IBOTIRAMA',
    initials: 'BA',
    code: '2913200',
  },
  {
    name: 'ICAPUÍ',
    initials: 'CE',
    code: '2305357',
  },
  {
    name: 'IÇARA',
    initials: 'SC',
    code: '4207007',
  },
  {
    name: 'ICARAÍ DE MINAS',
    initials: 'MG',
    code: '3130051',
  },
  {
    name: 'ICARAÍMA',
    initials: 'PR',
    code: '4109906',
  },
  {
    name: 'ICATU',
    initials: 'MA',
    code: '2105104',
  },
  {
    name: 'ICÉM',
    initials: 'SP',
    code: '3519808',
  },
  {
    name: 'ICHU',
    initials: 'BA',
    code: '2913309',
  },
  {
    name: 'ICÓ',
    initials: 'CE',
    code: '2305407',
  },
  {
    name: 'ICONHA',
    initials: 'ES',
    code: '3202603',
  },
  {
    name: 'IELMO MARINHO',
    initials: 'RN',
    code: '2404606',
  },
  {
    name: 'IEPÊ',
    initials: 'SP',
    code: '3519907',
  },
  {
    name: 'IGACI',
    initials: 'AL',
    code: '2703106',
  },
  {
    name: 'IGAPORÃ',
    initials: 'BA',
    code: '2913408',
  },
  {
    name: 'IGARAÇU DO TIETÊ',
    initials: 'SP',
    code: '3520004',
  },
  {
    name: 'IGARACY',
    initials: 'PB',
    code: '2502607',
  },
  {
    name: 'IGARAPAVA',
    initials: 'SP',
    code: '3520103',
  },
  {
    name: 'IGARAPÉ',
    initials: 'MG',
    code: '3130101',
  },
  {
    name: 'IGARAPÉ-AÇU',
    initials: 'PA',
    code: '1503200',
  },
  {
    name: 'IGARAPÉ DO MEIO',
    initials: 'MA',
    code: '2105153',
  },
  {
    name: 'IGARAPÉ GRANDE',
    initials: 'MA',
    code: '2105203',
  },
  {
    name: 'IGARAPÉ-MIRI',
    initials: 'PA',
    code: '1503309',
  },
  {
    name: 'IGARASSU',
    initials: 'PE',
    code: '2606804',
  },
  {
    name: 'IGARATÁ',
    initials: 'SP',
    code: '3520202',
  },
  {
    name: 'IGARATINGA',
    initials: 'MG',
    code: '3130200',
  },
  {
    name: 'IGRAPIÚNA',
    initials: 'BA',
    code: '2913457',
  },
  {
    name: 'IGREJA NOVA',
    initials: 'AL',
    code: '2703205',
  },
  {
    name: 'IGREJINHA',
    initials: 'RS',
    code: '4310108',
  },
  {
    name: 'IGUABA GRANDE',
    initials: 'RJ',
    code: '3301876',
  },
  {
    name: 'IGUAÍ',
    initials: 'BA',
    code: '2913507',
  },
  {
    name: 'IGUAPE',
    initials: 'SP',
    code: '3520301',
  },
  {
    name: 'IGUARACI',
    initials: 'PE',
    code: '2606903',
  },
  {
    name: 'IGUARAÇU',
    initials: 'PR',
    code: '4110003',
  },
  {
    name: 'IGUATAMA',
    initials: 'MG',
    code: '3130309',
  },
  {
    name: 'IGUATEMI',
    initials: 'MS',
    code: '5004304',
  },
  {
    name: 'IGUATU',
    initials: 'CE',
    code: '2305506',
  },
  {
    name: 'IGUATU',
    initials: 'PR',
    code: '4110052',
  },
  {
    name: 'IJACI',
    initials: 'MG',
    code: '3130408',
  },
  {
    name: 'IJUÍ',
    initials: 'RS',
    code: '4310207',
  },
  {
    name: 'ILHABELA',
    initials: 'SP',
    code: '3520400',
  },
  {
    name: 'ILHA COMPRIDA',
    initials: 'SP',
    code: '3520426',
  },
  {
    name: 'ILHA DAS FLORES',
    initials: 'SE',
    code: '2802700',
  },
  {
    name: 'ILHA DE ITAMARACÁ',
    initials: 'PE',
    code: '2607604',
  },
  {
    name: 'ILHA GRANDE',
    initials: 'PI',
    code: '2204659',
  },
  {
    name: 'ILHA SOLTEIRA',
    initials: 'SP',
    code: '3520442',
  },
  {
    name: 'ILHÉUS',
    initials: 'BA',
    code: '2913606',
  },
  {
    name: 'ILHOTA',
    initials: 'SC',
    code: '4207106',
  },
  {
    name: 'ILICÍNEA',
    initials: 'MG',
    code: '3130507',
  },
  {
    name: 'ILÓPOLIS',
    initials: 'RS',
    code: '4310306',
  },
  {
    name: 'IMACULADA',
    initials: 'PB',
    code: '2506707',
  },
  {
    name: 'IMARUÍ',
    initials: 'SC',
    code: '4207205',
  },
  {
    name: 'IMBAÚ',
    initials: 'PR',
    code: '4110078',
  },
  {
    name: 'IMBÉ',
    initials: 'RS',
    code: '4310330',
  },
  {
    name: 'IMBÉ DE MINAS',
    initials: 'MG',
    code: '3130556',
  },
  {
    name: 'IMBITUBA',
    initials: 'SC',
    code: '4207304',
  },
  {
    name: 'IMBITUVA',
    initials: 'PR',
    code: '4110102',
  },
  {
    name: 'IMBUIA',
    initials: 'SC',
    code: '4207403',
  },
  {
    name: 'IMIGRANTE',
    initials: 'RS',
    code: '4310363',
  },
  {
    name: 'IMPERATRIZ',
    initials: 'MA',
    code: '2105302',
  },
  {
    name: 'INACIOLÂNDIA',
    initials: 'GO',
    code: '5209937',
  },
  {
    name: 'INÁCIO MARTINS',
    initials: 'PR',
    code: '4110201',
  },
  {
    name: 'INAJÁ',
    initials: 'PE',
    code: '2607000',
  },
  {
    name: 'INAJÁ',
    initials: 'PR',
    code: '4110300',
  },
  {
    name: 'INCONFIDENTES',
    initials: 'MG',
    code: '3130606',
  },
  {
    name: 'INDAIABIRA',
    initials: 'MG',
    code: '3130655',
  },
  {
    name: 'INDAIAL',
    initials: 'SC',
    code: '4207502',
  },
  {
    name: 'INDAIATUBA',
    initials: 'SP',
    code: '3520509',
  },
  {
    name: 'INDEPENDÊNCIA',
    initials: 'RS',
    code: '4310405',
  },
  {
    name: 'INDEPENDÊNCIA',
    initials: 'CE',
    code: '2305605',
  },
  {
    name: 'INDIANA',
    initials: 'SP',
    code: '3520608',
  },
  {
    name: 'INDIANÓPOLIS',
    initials: 'PR',
    code: '4110409',
  },
  {
    name: 'INDIANÓPOLIS',
    initials: 'MG',
    code: '3130705',
  },
  {
    name: 'INDIAPORÃ',
    initials: 'SP',
    code: '3520707',
  },
  {
    name: 'INDIARA',
    initials: 'GO',
    code: '5209952',
  },
  {
    name: 'INDIAROBA',
    initials: 'SE',
    code: '2802809',
  },
  {
    name: 'INDIAVAÍ',
    initials: 'MT',
    code: '5104500',
  },
  {
    name: 'INGÁ',
    initials: 'PB',
    code: '2506806',
  },
  {
    name: 'INGAÍ',
    initials: 'MG',
    code: '3130804',
  },
  {
    name: 'INGAZEIRA',
    initials: 'PE',
    code: '2607109',
  },
  {
    name: 'INGAZEIRAS',
    initials: 'CE',
    code: '2305803',
  },
  {
    name: 'INHACORÁ',
    initials: 'RS',
    code: '4310413',
  },
  {
    name: 'INHAMBUPE',
    initials: 'BA',
    code: '2913705',
  },
  {
    name: 'INHANGAPI',
    initials: 'PA',
    code: '1503408',
  },
  {
    name: 'INHAPI',
    initials: 'AL',
    code: '2703304',
  },
  {
    name: 'INHAPIM',
    initials: 'MG',
    code: '3130903',
  },
  {
    name: 'INHAÚMA',
    initials: 'MG',
    code: '3131000',
  },
  {
    name: 'INHUMA',
    initials: 'PI',
    code: '2204709',
  },
  {
    name: 'INHUMAS',
    initials: 'GO',
    code: '5210000',
  },
  {
    name: 'INIMUTABA',
    initials: 'MG',
    code: '3131109',
  },
  {
    name: 'INOCÊNCIA',
    initials: 'MS',
    code: '5004403',
  },
  {
    name: 'INÚBIA PAULISTA',
    initials: 'SP',
    code: '3520806',
  },
  {
    name: 'IOMERÊ',
    initials: 'SC',
    code: '4207577',
  },
  {
    name: 'IPABA',
    initials: 'MG',
    code: '3131158',
  },
  {
    name: 'IPAMERI',
    initials: 'GO',
    code: '5210109',
  },
  {
    name: 'IPANEMA',
    initials: 'MG',
    code: '3131208',
  },
  {
    name: 'IPANGUAÇU',
    initials: 'RN',
    code: '2404705',
  },
  {
    name: 'IPAPORANGA',
    initials: 'CE',
    code: '2305654',
  },
  {
    name: 'IPATINGA',
    initials: 'MG',
    code: '3131307',
  },
  {
    name: 'IPAUMIRIM',
    initials: 'CE',
    code: '2305704',
  },
  {
    name: 'IPAUSSU',
    initials: 'SP',
    code: '3520905',
  },
  {
    name: 'IPÊ',
    initials: 'RS',
    code: '4310439',
  },
  {
    name: 'IPECAETÁ',
    initials: 'BA',
    code: '2913804',
  },
  {
    name: 'IPERÓ',
    initials: 'SP',
    code: '3521002',
  },
  {
    name: 'IPEÚNA',
    initials: 'SP',
    code: '3521101',
  },
  {
    name: 'IPIAÇU',
    initials: 'MG',
    code: '3131406',
  },
  {
    name: 'IPIAÚ',
    initials: 'BA',
    code: '2913903',
  },
  {
    name: 'IPIGUÁ',
    initials: 'SP',
    code: '3521150',
  },
  {
    name: 'IPIRA',
    initials: 'SC',
    code: '4207601',
  },
  {
    name: 'IPIRÁ',
    initials: 'BA',
    code: '2914000',
  },
  {
    name: 'IPIRANGA',
    initials: 'PR',
    code: '4110508',
  },
  {
    name: 'IPIRANGA DE GOIÁS',
    initials: 'GO',
    code: '5210158',
  },
  {
    name: 'IPIRANGA DO NORTE',
    initials: 'MT',
    code: '5104526',
  },
  {
    name: 'IPIRANGA DO PIAUÍ',
    initials: 'PI',
    code: '2204808',
  },
  {
    name: 'IPIRANGA DO SUL',
    initials: 'RS',
    code: '4310462',
  },
  {
    name: 'IPIXUNA',
    initials: 'AM',
    code: '1301803',
  },
  {
    name: 'IPIXUNA DO PARÁ',
    initials: 'PA',
    code: '1503457',
  },
  {
    name: 'IPOJUCA',
    initials: 'PE',
    code: '2607208',
  },
  {
    name: 'IPORÁ',
    initials: 'GO',
    code: '5210208',
  },
  {
    name: 'IPORÃ',
    initials: 'PR',
    code: '4110607',
  },
  {
    name: 'IPORÃ DO OESTE',
    initials: 'SC',
    code: '4207650',
  },
  {
    name: 'IPORANGA',
    initials: 'SP',
    code: '3521200',
  },
  {
    name: 'IPU',
    initials: 'CE',
    code: '2305803',
  },
  {
    name: 'IPUÃ',
    initials: 'SP',
    code: '3521309',
  },
  {
    name: 'IPUAÇU',
    initials: 'SC',
    code: '4207684',
  },
  {
    name: 'IPUBI',
    initials: 'PE',
    code: '2607307',
  },
  {
    name: 'IPUEIRA',
    initials: 'RN',
    code: '2404804',
  },
  {
    name: 'IPUEIRAS',
    initials: 'TO',
    code: '1709807',
  },
  {
    name: 'IPUEIRAS',
    initials: 'CE',
    code: '2305902',
  },
  {
    name: 'IPUIÚNA',
    initials: 'MG',
    code: '3131505',
  },
  {
    name: 'IPUMIRIM',
    initials: 'SC',
    code: '4207700',
  },
  {
    name: 'IPUPIARA',
    initials: 'BA',
    code: '2914109',
  },
  {
    name: 'IRACEMA',
    initials: 'RR',
    code: '1400282',
  },
  {
    name: 'IRACEMA',
    initials: 'CE',
    code: '2306009',
  },
  {
    name: 'IRACEMA DO OESTE',
    initials: 'PR',
    code: '4110656',
  },
  {
    name: 'IRACEMÁPOLIS',
    initials: 'SP',
    code: '3521408',
  },
  {
    name: 'IRACEMINHA',
    initials: 'SC',
    code: '4207759',
  },
  {
    name: 'IRAÍ',
    initials: 'RS',
    code: '4310504',
  },
  {
    name: 'IRAÍ DE MINAS',
    initials: 'MG',
    code: '3131604',
  },
  {
    name: 'IRAJUBA',
    initials: 'BA',
    code: '2914208',
  },
  {
    name: 'IRAMAIA',
    initials: 'BA',
    code: '2914307',
  },
  {
    name: 'IRANDUBA',
    initials: 'AM',
    code: '1301852',
  },
  {
    name: 'IRANI',
    initials: 'SC',
    code: '4207809',
  },
  {
    name: 'IRAPUÃ',
    initials: 'SP',
    code: '3521507',
  },
  {
    name: 'IRAPURU',
    initials: 'SP',
    code: '3521606',
  },
  {
    name: 'IRAQUARA',
    initials: 'BA',
    code: '2914406',
  },
  {
    name: 'IRARÁ',
    initials: 'BA',
    code: '2914505',
  },
  {
    name: 'IRATI',
    initials: 'SC',
    code: '4207858',
  },
  {
    name: 'IRATI',
    initials: 'PR',
    code: '4110706',
  },
  {
    name: 'IRAUÇUBA',
    initials: 'CE',
    code: '2306108',
  },
  {
    name: 'IRECÊ',
    initials: 'BA',
    code: '2914604',
  },
  {
    name: 'IRETAMA',
    initials: 'PR',
    code: '4110805',
  },
  {
    name: 'IRINEÓPOLIS',
    initials: 'SC',
    code: '4207908',
  },
  {
    name: 'IRITUIA',
    initials: 'PA',
    code: '1503507',
  },
  {
    name: 'IRUPI',
    initials: 'ES',
    code: '3202652',
  },
  {
    name: 'ISAÍAS COELHO',
    initials: 'PI',
    code: '2204907',
  },
  {
    name: 'ISRAELÂNDIA',
    initials: 'GO',
    code: '5210307',
  },
  {
    name: 'ITÁ',
    initials: 'SC',
    code: '4208005',
  },
  {
    name: 'ITAARA',
    initials: 'RS',
    code: '4310538',
  },
  {
    name: 'ITABAIANA',
    initials: 'SE',
    code: '2802908',
  },
  {
    name: 'ITABAIANA',
    initials: 'PB',
    code: '2506905',
  },
  {
    name: 'ITABAIANINHA',
    initials: 'SE',
    code: '2803005',
  },
  {
    name: 'ITABELA',
    initials: 'BA',
    code: '2914653',
  },
  {
    name: 'ITABERÁ',
    initials: 'SP',
    code: '3521705',
  },
  {
    name: 'ITABERABA',
    initials: 'BA',
    code: '2914703',
  },
  {
    name: 'ITABERAÍ',
    initials: 'GO',
    code: '5210406',
  },
  {
    name: 'ITABI',
    initials: 'SE',
    code: '2803104',
  },
  {
    name: 'ITABIRA',
    initials: 'MG',
    code: '3131703',
  },
  {
    name: 'ITABIRINHA',
    initials: 'MG',
    code: '3131802',
  },
  {
    name: 'ITABIRITO',
    initials: 'MG',
    code: '3131901',
  },
  {
    name: 'ITABORAÍ',
    initials: 'RJ',
    code: '3301900',
  },
  {
    name: 'ITABUNA',
    initials: 'BA',
    code: '2914802',
  },
  {
    name: 'ITACAJÁ',
    initials: 'TO',
    code: '1710508',
  },
  {
    name: 'ITACAMBIRA',
    initials: 'MG',
    code: '3132008',
  },
  {
    name: 'ITACARAMBI',
    initials: 'MG',
    code: '3132107',
  },
  {
    name: 'ITACARÉ',
    initials: 'BA',
    code: '2914901',
  },
  {
    name: 'ITACOATIARA',
    initials: 'AM',
    code: '1301902',
  },
  {
    name: 'ITACURUBA',
    initials: 'PE',
    code: '2607406',
  },
  {
    name: 'ITACURUBI',
    initials: 'RS',
    code: '4310553',
  },
  {
    name: 'ITAETÉ',
    initials: 'BA',
    code: '2915007',
  },
  {
    name: 'ITAGI',
    initials: 'BA',
    code: '2915106',
  },
  {
    name: 'ITAGIBÁ',
    initials: 'BA',
    code: '2915205',
  },
  {
    name: 'ITAGIMIRIM',
    initials: 'BA',
    code: '2915304',
  },
  {
    name: 'ITAGUAÇU',
    initials: 'ES',
    code: '3202702',
  },
  {
    name: 'ITAGUAÇU DA BAHIA',
    initials: 'BA',
    code: '2915353',
  },
  {
    name: 'ITAGUAÍ',
    initials: 'RJ',
    code: '3302007',
  },
  {
    name: 'ITAGUAJÉ',
    initials: 'PR',
    code: '4110904',
  },
  {
    name: 'ITAGUARA',
    initials: 'MG',
    code: '3132206',
  },
  {
    name: 'ITAGUARI',
    initials: 'GO',
    code: '5210562',
  },
  {
    name: 'ITAGUARU',
    initials: 'GO',
    code: '5210604',
  },
  {
    name: 'ITAGUATINS',
    initials: 'TO',
    code: '1710706',
  },
  {
    name: 'ITAÍ',
    initials: 'SP',
    code: '3521804',
  },
  {
    name: 'ITAÍBA',
    initials: 'PE',
    code: '2607505',
  },
  {
    name: 'ITAIÇABA',
    initials: 'CE',
    code: '2306207',
  },
  {
    name: 'ITAINÓPOLIS',
    initials: 'PI',
    code: '2205003',
  },
  {
    name: 'ITAIÓPOLIS',
    initials: 'SC',
    code: '4208104',
  },
  {
    name: 'ITAIPAVA DO GRAJAÚ',
    initials: 'MA',
    code: '2105351',
  },
  {
    name: 'ITAIPÉ',
    initials: 'MG',
    code: '3132305',
  },
  {
    name: 'ITAIPULÂNDIA',
    initials: 'PR',
    code: '4110953',
  },
  {
    name: 'ITAITINGA',
    initials: 'CE',
    code: '2306256',
  },
  {
    name: 'ITAITUBA',
    initials: 'PA',
    code: '1503606',
  },
  {
    name: 'ITAJÁ',
    initials: 'GO',
    code: '5210802',
  },
  {
    name: 'ITAJÁ',
    initials: 'RN',
    code: '2404853',
  },
  {
    name: 'ITAJAÍ',
    initials: 'SC',
    code: '4208203',
  },
  {
    name: 'ITAJOBI',
    initials: 'SP',
    code: '3521903',
  },
  {
    name: 'ITAJU',
    initials: 'SP',
    code: '3522000',
  },
  {
    name: 'ITAJUBÁ',
    initials: 'MG',
    code: '3132404',
  },
  {
    name: 'ITAJU DO COLÔNIA',
    initials: 'BA',
    code: '2915403',
  },
  {
    name: 'ITAJUÍPE',
    initials: 'BA',
    code: '2915502',
  },
  {
    name: 'ITALVA',
    initials: 'RJ',
    code: '3302056',
  },
  {
    name: 'ITAMARAJU',
    initials: 'BA',
    code: '2915601',
  },
  {
    name: 'ITAMARANDIBA',
    initials: 'MG',
    code: '3132503',
  },
  {
    name: 'ITAMARATI',
    initials: 'AM',
    code: '1301951',
  },
  {
    name: 'ITAMARATI DE MINAS',
    initials: 'MG',
    code: '3132602',
  },
  {
    name: 'ITAMARI',
    initials: 'BA',
    code: '2915700',
  },
  {
    name: 'ITAMBACURI',
    initials: 'MG',
    code: '3132701',
  },
  {
    name: 'ITAMBARACÁ',
    initials: 'PR',
    code: '4111001',
  },
  {
    name: 'ITAMBÉ',
    initials: 'BA',
    code: '2915809',
  },
  {
    name: 'ITAMBÉ',
    initials: 'PE',
    code: '2607653',
  },
  {
    name: 'ITAMBÉ',
    initials: 'PR',
    code: '4111100',
  },
  {
    name: 'ITAMBÉ DO MATO DENTRO',
    initials: 'MG',
    code: '3132800',
  },
  {
    name: 'ITAMOGI',
    initials: 'MG',
    code: '3132909',
  },
  {
    name: 'ITAMONTE',
    initials: 'MG',
    code: '3133006',
  },
  {
    name: 'ITANAGRA',
    initials: 'BA',
    code: '2915908',
  },
  {
    name: 'ITANHAÉM',
    initials: 'SP',
    code: '3522109',
  },
  {
    name: 'ITANHANDU',
    initials: 'MG',
    code: '3133105',
  },
  {
    name: 'ITANHANGÁ',
    initials: 'MT',
    code: '5104542',
  },
  {
    name: 'ITANHÉM',
    initials: 'BA',
    code: '2916005',
  },
  {
    name: 'ITANHOMI',
    initials: 'MG',
    code: '3133204',
  },
  {
    name: 'ITAOBIM',
    initials: 'MG',
    code: '3133303',
  },
  {
    name: 'ITAÓCA',
    initials: 'SP',
    code: '3522158',
  },
  {
    name: 'ITAOCARA',
    initials: 'RJ',
    code: '3302106',
  },
  {
    name: 'ITAPACI',
    initials: 'GO',
    code: '5210901',
  },
  {
    name: 'ITAPAGIPE',
    initials: 'MG',
    code: '3133402',
  },
  {
    name: 'ITAPAJÉ',
    initials: 'CE',
    code: '2306306',
  },
  {
    name: 'ITAPARICA',
    initials: 'BA',
    code: '2916104',
  },
  {
    name: 'ITAPÉ',
    initials: 'BA',
    code: '2916203',
  },
  {
    name: 'ITAPEBI',
    initials: 'BA',
    code: '2916302',
  },
  {
    name: 'ITAPECERICA',
    initials: 'MG',
    code: '3133501',
  },
  {
    name: 'ITAPECERICA DA SERRA',
    initials: 'SP',
    code: '3522208',
  },
  {
    name: 'ITAPECURU MIRIM',
    initials: 'MA',
    code: '2105401',
  },
  {
    name: 'ITAPEJARA D\'OESTE',
    initials: 'PR',
    code: '4111209',
  },
  {
    name: 'ITAPEMA',
    initials: 'SC',
    code: '4208302',
  },
  {
    name: 'ITAPEMIRIM',
    initials: 'ES',
    code: '3202801',
  },
  {
    name: 'ITAPERUÇU',
    initials: 'PR',
    code: '4111258',
  },
  {
    name: 'ITAPERUNA',
    initials: 'RJ',
    code: '3302205',
  },
  {
    name: 'ITAPETIM',
    initials: 'PE',
    code: '2607703',
  },
  {
    name: 'ITAPETINGA',
    initials: 'BA',
    code: '2916401',
  },
  {
    name: 'ITAPETININGA',
    initials: 'SP',
    code: '3522307',
  },
  {
    name: 'ITAPEVA',
    initials: 'MG',
    code: '3133600',
  },
  {
    name: 'ITAPEVA',
    initials: 'SP',
    code: '3522406',
  },
  {
    name: 'ITAPEVI',
    initials: 'SP',
    code: '3522505',
  },
  {
    name: 'ITAPICURU',
    initials: 'BA',
    code: '2916500',
  },
  {
    name: 'ITAPIPOCA',
    initials: 'CE',
    code: '2306405',
  },
  {
    name: 'ITAPIRA',
    initials: 'SP',
    code: '3522604',
  },
  {
    name: 'ITAPIRANGA',
    initials: 'AM',
    code: '1302009',
  },
  {
    name: 'ITAPIRANGA',
    initials: 'SC',
    code: '4208401',
  },
  {
    name: 'ITAPIRAPUÃ',
    initials: 'GO',
    code: '5211008',
  },
  {
    name: 'ITAPIRAPUÃ PAULISTA',
    initials: 'SP',
    code: '3522653',
  },
  {
    name: 'ITAPIRATINS',
    initials: 'TO',
    code: '1710904',
  },
  {
    name: 'ITAPISSUMA',
    initials: 'PE',
    code: '2607752',
  },
  {
    name: 'ITAPITANGA',
    initials: 'BA',
    code: '2916609',
  },
  {
    name: 'ITAPIÚNA',
    initials: 'CE',
    code: '2306504',
  },
  {
    name: 'ITAPOÁ',
    initials: 'SC',
    code: '4208450',
  },
  {
    name: 'ITÁPOLIS',
    initials: 'SP',
    code: '3522703',
  },
  {
    name: 'ITAPORÃ',
    initials: 'MS',
    code: '5004502',
  },
  {
    name: 'ITAPORÃ DO TOCANTINS',
    initials: 'TO',
    code: '1711100',
  },
  {
    name: 'ITAPORANGA',
    initials: 'PB',
    code: '2507002',
  },
  {
    name: 'ITAPORANGA',
    initials: 'SP',
    code: '3522802',
  },
  {
    name: 'ITAPORANGA D\'AJUDA',
    initials: 'SE',
    code: '2803203',
  },
  {
    name: 'ITAPOROROCA',
    initials: 'PB',
    code: '2507101',
  },
  {
    name: 'ITAPUÃ DO OESTE',
    initials: 'RO',
    code: '1101104',
  },
  {
    name: 'ITAPUCA',
    initials: 'RS',
    code: '4310579',
  },
  {
    name: 'ITAPUÍ',
    initials: 'SP',
    code: '3522901',
  },
  {
    name: 'ITAPURA',
    initials: 'SP',
    code: '3523008',
  },
  {
    name: 'ITAPURANGA',
    initials: 'GO',
    code: '5211206',
  },
  {
    name: 'ITAQUAQUECETUBA',
    initials: 'SP',
    code: '3523107',
  },
  {
    name: 'ITAQUARA',
    initials: 'BA',
    code: '2916708',
  },
  {
    name: 'ITAQUI',
    initials: 'RS',
    code: '4310603',
  },
  {
    name: 'ITAQUIRAÍ',
    initials: 'MS',
    code: '5004601',
  },
  {
    name: 'ITAQUITINGA',
    initials: 'PE',
    code: '2607802',
  },
  {
    name: 'ITARANA',
    initials: 'ES',
    code: '3202900',
  },
  {
    name: 'ITARANTIM',
    initials: 'BA',
    code: '2916807',
  },
  {
    name: 'ITARARÉ',
    initials: 'SP',
    code: '3523206',
  },
  {
    name: 'ITAREMA',
    initials: 'CE',
    code: '2306553',
  },
  {
    name: 'ITARIRI',
    initials: 'SP',
    code: '3523305',
  },
  {
    name: 'ITARUMÃ',
    initials: 'GO',
    code: '5211305',
  },
  {
    name: 'ITATI',
    initials: 'RS',
    code: '4310652',
  },
  {
    name: 'ITATIAIA',
    initials: 'RJ',
    code: '3302254',
  },
  {
    name: 'ITATIAIUÇU',
    initials: 'MG',
    code: '3133709',
  },
  {
    name: 'ITATIBA',
    initials: 'SP',
    code: '3523404',
  },
  {
    name: 'ITATIBA DO SUL',
    initials: 'RS',
    code: '4310702',
  },
  {
    name: 'ITATIM',
    initials: 'BA',
    code: '2916856',
  },
  {
    name: 'ITATINGA',
    initials: 'SP',
    code: '3523503',
  },
  {
    name: 'ITATIRA',
    initials: 'CE',
    code: '2306603',
  },
  {
    name: 'ITATUBA',
    initials: 'PB',
    code: '2507200',
  },
  {
    name: 'ITAÚ',
    initials: 'RN',
    code: '2404903',
  },
  {
    name: 'ITAÚBA',
    initials: 'MT',
    code: '5104559',
  },
  {
    name: 'ITAUBAL',
    initials: 'AP',
    code: '1600253',
  },
  {
    name: 'ITAUÇU',
    initials: 'GO',
    code: '5211404',
  },
  {
    name: 'ITAÚ DE MINAS',
    initials: 'MG',
    code: '3133758',
  },
  {
    name: 'ITAUEIRA',
    initials: 'PI',
    code: '2205102',
  },
  {
    name: 'ITAÚNA',
    initials: 'MG',
    code: '3133808',
  },
  {
    name: 'ITAÚNA DO SUL',
    initials: 'PR',
    code: '4111308',
  },
  {
    name: 'ITAVERAVA',
    initials: 'MG',
    code: '3133907',
  },
  {
    name: 'ITINGA',
    initials: 'MG',
    code: '3134004',
  },
  {
    name: 'ITINGA DO MARANHÃO',
    initials: 'MA',
    code: '2105427',
  },
  {
    name: 'ITIQUIRA',
    initials: 'MT',
    code: '5104609',
  },
  {
    name: 'ITIRAPINA',
    initials: 'SP',
    code: '3523602',
  },
  {
    name: 'ITIRAPUÃ',
    initials: 'SP',
    code: '3523701',
  },
  {
    name: 'ITIRUÇU',
    initials: 'BA',
    code: '2916906',
  },
  {
    name: 'ITIÚBA',
    initials: 'BA',
    code: '2917003',
  },
  {
    name: 'ITOBI',
    initials: 'SP',
    code: '3523800',
  },
  {
    name: 'ITORORÓ',
    initials: 'BA',
    code: '2917102',
  },
  {
    name: 'ITU',
    initials: 'SP',
    code: '3523909',
  },
  {
    name: 'ITUAÇU',
    initials: 'BA',
    code: '2917201',
  },
  {
    name: 'ITUBERÁ',
    initials: 'BA',
    code: '2917300',
  },
  {
    name: 'ITUETA',
    initials: 'MG',
    code: '3134103',
  },
  {
    name: 'ITUIUTABA',
    initials: 'MG',
    code: '3134202',
  },
  {
    name: 'ITUMBIARA',
    initials: 'GO',
    code: '5211503',
  },
  {
    name: 'ITUMIRIM',
    initials: 'MG',
    code: '3134301',
  },
  {
    name: 'ITUPEVA',
    initials: 'SP',
    code: '3524006',
  },
  {
    name: 'ITUPIRANGA',
    initials: 'PA',
    code: '1503705',
  },
  {
    name: 'ITUPORANGA',
    initials: 'SC',
    code: '4208500',
  },
  {
    name: 'ITURAMA',
    initials: 'MG',
    code: '3134400',
  },
  {
    name: 'ITUTINGA',
    initials: 'MG',
    code: '3134509',
  },
  {
    name: 'ITUVERAVA',
    initials: 'SP',
    code: '3524105',
  },
  {
    name: 'IUIU',
    initials: 'BA',
    code: '2917334',
  },
  {
    name: 'IÚNA',
    initials: 'ES',
    code: '3203007',
  },
  {
    name: 'IVAÍ',
    initials: 'PR',
    code: '4111407',
  },
  {
    name: 'IVAIPORÃ',
    initials: 'PR',
    code: '4111506',
  },
  {
    name: 'IVATÉ',
    initials: 'PR',
    code: '4111555',
  },
  {
    name: 'IVATUBA',
    initials: 'PR',
    code: '4111605',
  },
  {
    name: 'IVINHEMA',
    initials: 'MS',
    code: '5004700',
  },
  {
    name: 'IVOLÂNDIA',
    initials: 'GO',
    code: '5211602',
  },
  {
    name: 'IVORÁ',
    initials: 'RS',
    code: '4310751',
  },
  {
    name: 'IVOTI',
    initials: 'RS',
    code: '4310801',
  },
  {
    name: 'JABOATÃO DOS GUARARAPES',
    initials: 'PE',
    code: '2607901',
  },
  {
    name: 'JABORÁ',
    initials: 'SC',
    code: '4208609',
  },
  {
    name: 'JABORANDI',
    initials: 'BA',
    code: '2917359',
  },
  {
    name: 'JABORANDI',
    initials: 'SP',
    code: '3524204',
  },
  {
    name: 'JABOTI',
    initials: 'PR',
    code: '4111704',
  },
  {
    name: 'JABOTICABA',
    initials: 'RS',
    code: '4310850',
  },
  {
    name: 'JABOTICABAL',
    initials: 'SP',
    code: '3524303',
  },
  {
    name: 'JABOTICATUBAS',
    initials: 'MG',
    code: '3134608',
  },
  {
    name: 'JAÇANÃ',
    initials: 'RN',
    code: '2405009',
  },
  {
    name: 'JACARACI',
    initials: 'BA',
    code: '2917409',
  },
  {
    name: 'JACARAÚ',
    initials: 'PB',
    code: '2507309',
  },
  {
    name: 'JACAREACANGA',
    initials: 'PA',
    code: '1503754',
  },
  {
    name: 'JACARÉ DOS HOMENS',
    initials: 'AL',
    code: '2703403',
  },
  {
    name: 'JACAREÍ',
    initials: 'SP',
    code: '3524402',
  },
  {
    name: 'JACAREZINHO',
    initials: 'PR',
    code: '4111803',
  },
  {
    name: 'JACI',
    initials: 'SP',
    code: '3524501',
  },
  {
    name: 'JACIARA',
    initials: 'MT',
    code: '5104807',
  },
  {
    name: 'JACINTO',
    initials: 'MG',
    code: '3134707',
  },
  {
    name: 'JACINTO MACHADO',
    initials: 'SC',
    code: '4208708',
  },
  {
    name: 'JACOBINA',
    initials: 'BA',
    code: '2917508',
  },
  {
    name: 'JACOBINA DO PIAUÍ',
    initials: 'PI',
    code: '2205151',
  },
  {
    name: 'JACUÍ',
    initials: 'MG',
    code: '3134806',
  },
  {
    name: 'JACUÍPE',
    initials: 'AL',
    code: '2703502',
  },
  {
    name: 'JACUIZINHO',
    initials: 'RS',
    code: '4310876',
  },
  {
    name: 'JACUNDÁ',
    initials: 'PA',
    code: '1503804',
  },
  {
    name: 'JACUPIRANGA',
    initials: 'SP',
    code: '3524600',
  },
  {
    name: 'JACUTINGA',
    initials: 'RS',
    code: '4310900',
  },
  {
    name: 'JACUTINGA',
    initials: 'MG',
    code: '3134905',
  },
  {
    name: 'JAGUAPITÃ',
    initials: 'PR',
    code: '4111902',
  },
  {
    name: 'JAGUAQUARA',
    initials: 'BA',
    code: '2917607',
  },
  {
    name: 'JAGUARAÇU',
    initials: 'MG',
    code: '3135001',
  },
  {
    name: 'JAGUARÃO',
    initials: 'RS',
    code: '4311007',
  },
  {
    name: 'JAGUARARI',
    initials: 'BA',
    code: '2917706',
  },
  {
    name: 'JAGUARÉ',
    initials: 'ES',
    code: '3203056',
  },
  {
    name: 'JAGUARETAMA',
    initials: 'CE',
    code: '2306702',
  },
  {
    name: 'JAGUARI',
    initials: 'RS',
    code: '4311106',
  },
  {
    name: 'JAGUARIAÍVA',
    initials: 'PR',
    code: '4112009',
  },
  {
    name: 'JAGUARIBARA',
    initials: 'CE',
    code: '2306801',
  },
  {
    name: 'JAGUARIBE',
    initials: 'CE',
    code: '2306900',
  },
  {
    name: 'JAGUARIPE',
    initials: 'BA',
    code: '2917805',
  },
  {
    name: 'JAGUARIÚNA',
    initials: 'SP',
    code: '3524709',
  },
  {
    name: 'JAGUARUANA',
    initials: 'CE',
    code: '2307007',
  },
  {
    name: 'JAGUARUNA',
    initials: 'SC',
    code: '4208807',
  },
  {
    name: 'JAÍBA',
    initials: 'MG',
    code: '3135050',
  },
  {
    name: 'JAICÓS',
    initials: 'PI',
    code: '2205201',
  },
  {
    name: 'JALES',
    initials: 'SP',
    code: '3524808',
  },
  {
    name: 'JAMBEIRO',
    initials: 'SP',
    code: '3524907',
  },
  {
    name: 'JAMPRUCA',
    initials: 'MG',
    code: '3135076',
  },
  {
    name: 'JANAÚBA',
    initials: 'MG',
    code: '3135100',
  },
  {
    name: 'JANDAIA',
    initials: 'GO',
    code: '5211701',
  },
  {
    name: 'JANDAIA DO SUL',
    initials: 'PR',
    code: '4112108',
  },
  {
    name: 'JANDAÍRA',
    initials: 'RN',
    code: '2405108',
  },
  {
    name: 'JANDAÍRA',
    initials: 'BA',
    code: '2917904',
  },
  {
    name: 'JANDIRA',
    initials: 'SP',
    code: '3525003',
  },
  {
    name: 'JANDUÍS',
    initials: 'RN',
    code: '2405207',
  },
  {
    name: 'JANGADA',
    initials: 'MT',
    code: '5104906',
  },
  {
    name: 'JANIÓPOLIS',
    initials: 'PR',
    code: '4112207',
  },
  {
    name: 'JANUÁRIA',
    initials: 'MG',
    code: '3135209',
  },
  {
    name: 'JAPARAÍBA',
    initials: 'MG',
    code: '3135308',
  },
  {
    name: 'JAPARATINGA',
    initials: 'AL',
    code: '2703601',
  },
  {
    name: 'JAPARATUBA',
    initials: 'SE',
    code: '2803302',
  },
  {
    name: 'JAPERI',
    initials: 'RJ',
    code: '3302270',
  },
  {
    name: 'JAPI',
    initials: 'RN',
    code: '2405405',
  },
  {
    name: 'JAPIRA',
    initials: 'PR',
    code: '4112306',
  },
  {
    name: 'JAPOATÃ',
    initials: 'SE',
    code: '2803401',
  },
  {
    name: 'JAPONVAR',
    initials: 'MG',
    code: '3135357',
  },
  {
    name: 'JAPORÃ',
    initials: 'MS',
    code: '5004809',
  },
  {
    name: 'JAPURÁ',
    initials: 'PR',
    code: '4112405',
  },
  {
    name: 'JAPURÁ',
    initials: 'AM',
    code: '1302108',
  },
  {
    name: 'JAQUEIRA',
    initials: 'PE',
    code: '2607950',
  },
  {
    name: 'JAQUIRANA',
    initials: 'RS',
    code: '4311122',
  },
  {
    name: 'JARAGUÁ',
    initials: 'GO',
    code: '5211800',
  },
  {
    name: 'JARAGUÁ DO SUL',
    initials: 'SC',
    code: '4208906',
  },
  {
    name: 'JARAGUARI',
    initials: 'MS',
    code: '5004908',
  },
  {
    name: 'JARAMATAIA',
    initials: 'AL',
    code: '2703700',
  },
  {
    name: 'JARDIM',
    initials: 'CE',
    code: '2307106',
  },
  {
    name: 'JARDIM',
    initials: 'MS',
    code: '5005004',
  },
  {
    name: 'JARDIM ALEGRE',
    initials: 'PR',
    code: '4112504',
  },
  {
    name: 'JARDIM DE ANGICOS',
    initials: 'RN',
    code: '2405504',
  },
  {
    name: 'JARDIM DE PIRANHAS',
    initials: 'RN',
    code: '2405603',
  },
  {
    name: 'JARDIM DO MULATO',
    initials: 'PI',
    code: '2205250',
  },
  {
    name: 'JARDIM DO SERIDÓ',
    initials: 'RN',
    code: '2405702',
  },
  {
    name: 'JARDIM OLINDA',
    initials: 'PR',
    code: '4112603',
  },
  {
    name: 'JARDINÓPOLIS',
    initials: 'SC',
    code: '4208955',
  },
  {
    name: 'JARDINÓPOLIS',
    initials: 'SP',
    code: '3525102',
  },
  {
    name: 'JARI',
    initials: 'RS',
    code: '4311130',
  },
  {
    name: 'JARINU',
    initials: 'SP',
    code: '3525201',
  },
  {
    name: 'JARU',
    initials: 'RO',
    code: '1100114',
  },
  {
    name: 'JATAÍ',
    initials: 'GO',
    code: '5211909',
  },
  {
    name: 'JATAIZINHO',
    initials: 'PR',
    code: '4112702',
  },
  {
    name: 'JATAÚBA',
    initials: 'PE',
    code: '2608008',
  },
  {
    name: 'JATEÍ',
    initials: 'MS',
    code: '5005103',
  },
  {
    name: 'JATI',
    initials: 'CE',
    code: '2307205',
  },
  {
    name: 'JATOBÁ',
    initials: 'PE',
    code: '2608057',
  },
  {
    name: 'JATOBÁ',
    initials: 'MA',
    code: '2105450',
  },
  {
    name: 'JATOBÁ DO PIAUÍ',
    initials: 'PI',
    code: '2205276',
  },
  {
    name: 'JAÚ',
    initials: 'SP',
    code: '3525300',
  },
  {
    name: 'JAÚ DO TOCANTINS',
    initials: 'TO',
    code: '1711506',
  },
  {
    name: 'JAUPACI',
    initials: 'GO',
    code: '5212006',
  },
  {
    name: 'JAURU',
    initials: 'MT',
    code: '5105002',
  },
  {
    name: 'JECEABA',
    initials: 'MG',
    code: '3135407',
  },
  {
    name: 'JENIPAPO DE MINAS',
    initials: 'MG',
    code: '3135456',
  },
  {
    name: 'JENIPAPO DOS VIEIRAS',
    initials: 'MA',
    code: '2105476',
  },
  {
    name: 'JEQUERI',
    initials: 'MG',
    code: '3135506',
  },
  {
    name: 'JEQUIÁ DA PRAIA',
    initials: 'AL',
    code: '2703759',
  },
  {
    name: 'JEQUIÉ',
    initials: 'BA',
    code: '2918001',
  },
  {
    name: 'JEQUIRIÇÁ',
    initials: 'BA',
    code: '2918209',
  },
  {
    name: 'JEQUITAÍ',
    initials: 'MG',
    code: '3135605',
  },
  {
    name: 'JEQUITIBÁ',
    initials: 'MG',
    code: '3135704',
  },
  {
    name: 'JEQUITINHONHA',
    initials: 'MG',
    code: '3135803',
  },
  {
    name: 'JEREMOABO',
    initials: 'BA',
    code: '2918100',
  },
  {
    name: 'JERICÓ',
    initials: 'PB',
    code: '2507408',
  },
  {
    name: 'JERIQUARA',
    initials: 'SP',
    code: '3525409',
  },
  {
    name: 'JERÔNIMO MONTEIRO',
    initials: 'ES',
    code: '3203106',
  },
  {
    name: 'JERUMENHA',
    initials: 'PI',
    code: '2205300',
  },
  {
    name: 'JESUÂNIA',
    initials: 'MG',
    code: '3135902',
  },
  {
    name: 'JESUÍTAS',
    initials: 'PR',
    code: '4112751',
  },
  {
    name: 'JESÚPOLIS',
    initials: 'GO',
    code: '5212055',
  },
  {
    name: 'JIJOCA DE JERICOACOARA',
    initials: 'CE',
    code: '2307254',
  },
  {
    name: 'JI-PARANÁ',
    initials: 'RO',
    code: '1100122',
  },
  {
    name: 'JITAÚNA',
    initials: 'BA',
    code: '2918308',
  },
  {
    name: 'JOAÇABA',
    initials: 'SC',
    code: '4209003',
  },
  {
    name: 'JOAÍMA',
    initials: 'MG',
    code: '3136009',
  },
  {
    name: 'JOANÉSIA',
    initials: 'MG',
    code: '3136108',
  },
  {
    name: 'JOANÓPOLIS',
    initials: 'SP',
    code: '3525508',
  },
  {
    name: 'JOÃO ALFREDO',
    initials: 'PE',
    code: '2608107',
  },
  {
    name: 'JOÃO CÂMARA',
    initials: 'RN',
    code: '2405801',
  },
  {
    name: 'JOÃO COSTA',
    initials: 'PI',
    code: '2205359',
  },
  {
    name: 'JOÃO DIAS',
    initials: 'RN',
    code: '2405900',
  },
  {
    name: 'JOÃO DOURADO',
    initials: 'BA',
    code: '2918357',
  },
  {
    name: 'JOÃO LISBOA',
    initials: 'MA',
    code: '2105500',
  },
  {
    name: 'JOÃO MONLEVADE',
    initials: 'MG',
    code: '3136207',
  },
  {
    name: 'JOÃO NEIVA',
    initials: 'ES',
    code: '3203130',
  },
  {
    name: 'JOÃO PESSOA',
    initials: 'PB',
    code: '2507507',
  },
  {
    name: 'JOÃO PINHEIRO',
    initials: 'MG',
    code: '3136306',
  },
  {
    name: 'JOÃO RAMALHO',
    initials: 'SP',
    code: '3525607',
  },
  {
    name: 'JOAQUIM FELÍCIO',
    initials: 'MG',
    code: '3136405',
  },
  {
    name: 'JOAQUIM GOMES',
    initials: 'AL',
    code: '2703809',
  },
  {
    name: 'JOAQUIM NABUCO',
    initials: 'PE',
    code: '2608206',
  },
  {
    name: 'JOAQUIM PIRES',
    initials: 'PI',
    code: '2205409',
  },
  {
    name: 'JOAQUIM TÁVORA',
    initials: 'PR',
    code: '4112801',
  },
  {
    name: 'JOCA CLAUDINO',
    initials: 'PB',
    code: '2513653',
  },
  {
    name: 'JOCA MARQUES',
    initials: 'PI',
    code: '2205458',
  },
  {
    name: 'JÓIA',
    initials: 'RS',
    code: '4311155',
  },
  {
    name: 'JOINVILLE',
    initials: 'SC',
    code: '4209102',
  },
  {
    name: 'JORDÂNIA',
    initials: 'MG',
    code: '3136504',
  },
  {
    name: 'JORDÃO',
    initials: 'AC',
    code: '1200328',
  },
  {
    name: 'JOSÉ BOITEUX',
    initials: 'SC',
    code: '4209151',
  },
  {
    name: 'JOSÉ BONIFÁCIO',
    initials: 'SP',
    code: '3525706',
  },
  {
    name: 'JOSÉ DA PENHA',
    initials: 'RN',
    code: '2406007',
  },
  {
    name: 'JOSÉ DE FREITAS',
    initials: 'PI',
    code: '2205508',
  },
  {
    name: 'JOSÉ GONÇALVES DE MINAS',
    initials: 'MG',
    code: '3136520',
  },
  {
    name: 'JOSELÂNDIA',
    initials: 'MA',
    code: '2105609',
  },
  {
    name: 'JOSENÓPOLIS',
    initials: 'MG',
    code: '3136579',
  },
  {
    name: 'JOSÉ RAYDAN',
    initials: 'MG',
    code: '3136553',
  },
  {
    name: 'JOVIÂNIA',
    initials: 'GO',
    code: '5212105',
  },
  {
    name: 'JUARA',
    initials: 'MT',
    code: '5105101',
  },
  {
    name: 'JUAREZ TÁVORA',
    initials: 'PB',
    code: '2507606',
  },
  {
    name: 'JUARINA',
    initials: 'TO',
    code: '1711803',
  },
  {
    name: 'JUATUBA',
    initials: 'MG',
    code: '3136652',
  },
  {
    name: 'JUAZEIRINHO',
    initials: 'PB',
    code: '2507705',
  },
  {
    name: 'JUAZEIRO',
    initials: 'BA',
    code: '2918407',
  },
  {
    name: 'JUAZEIRO DO NORTE',
    initials: 'CE',
    code: '2307304',
  },
  {
    name: 'JUAZEIRO DO PIAUÍ',
    initials: 'PI',
    code: '2205516',
  },
  {
    name: 'JUCÁS',
    initials: 'CE',
    code: '2307403',
  },
  {
    name: 'JUCATI',
    initials: 'PE',
    code: '2608255',
  },
  {
    name: 'JUCURUÇU',
    initials: 'BA',
    code: '2918456',
  },
  {
    name: 'JUCURUTU',
    initials: 'RN',
    code: '2406106',
  },
  {
    name: 'JUÍNA',
    initials: 'MT',
    code: '5105150',
  },
  {
    name: 'JUIZ DE FORA',
    initials: 'MG',
    code: '3136702',
  },
  {
    name: 'JÚLIO BORGES',
    initials: 'PI',
    code: '2205524',
  },
  {
    name: 'JÚLIO DE CASTILHOS',
    initials: 'RS',
    code: '4311205',
  },
  {
    name: 'JÚLIO MESQUITA',
    initials: 'SP',
    code: '3525805',
  },
  {
    name: 'JUMIRIM',
    initials: 'SP',
    code: '3525854',
  },
  {
    name: 'JUNCO DO MARANHÃO',
    initials: 'MA',
    code: '2105658',
  },
  {
    name: 'JUNCO DO SERIDÓ',
    initials: 'PB',
    code: '2507804',
  },
  {
    name: 'JUNDIÁ',
    initials: 'AL',
    code: '2703908',
  },
  {
    name: 'JUNDIÁ',
    initials: 'RN',
    code: '2406155',
  },
  {
    name: 'JUNDIAÍ',
    initials: 'SP',
    code: '3525904',
  },
  {
    name: 'JUNDIAÍ DO SUL',
    initials: 'PR',
    code: '4112900',
  },
  {
    name: 'JUNQUEIRO',
    initials: 'AL',
    code: '2704005',
  },
  {
    name: 'JUNQUEIRÓPOLIS',
    initials: 'SP',
    code: '3526001',
  },
  {
    name: 'JUPI',
    initials: 'PE',
    code: '2608305',
  },
  {
    name: 'JUPIÁ',
    initials: 'SC',
    code: '4209177',
  },
  {
    name: 'JUQUIÁ',
    initials: 'SP',
    code: '3526100',
  },
  {
    name: 'JUQUITIBA',
    initials: 'SP',
    code: '3526209',
  },
  {
    name: 'JURAMENTO',
    initials: 'MG',
    code: '3136801',
  },
  {
    name: 'JURANDA',
    initials: 'PR',
    code: '4112959',
  },
  {
    name: 'JUREMA',
    initials: 'PE',
    code: '2608404',
  },
  {
    name: 'JUREMA',
    initials: 'PI',
    code: '2205532',
  },
  {
    name: 'JURIPIRANGA',
    initials: 'PB',
    code: '2507903',
  },
  {
    name: 'JURU',
    initials: 'PB',
    code: '2508000',
  },
  {
    name: 'JURUÁ',
    initials: 'AM',
    code: '1302207',
  },
  {
    name: 'JURUAIA',
    initials: 'MG',
    code: '3136900',
  },
  {
    name: 'JURUENA',
    initials: 'MT',
    code: '5105176',
  },
  {
    name: 'JURUTI',
    initials: 'PA',
    code: '1503903',
  },
  {
    name: 'JUSCIMEIRA',
    initials: 'MT',
    code: '5105200',
  },
  {
    name: 'JUSSARA',
    initials: 'GO',
    code: '5212204',
  },
  {
    name: 'JUSSARA',
    initials: 'BA',
    code: '2918506',
  },
  {
    name: 'JUSSARA',
    initials: 'PR',
    code: '4113007',
  },
  {
    name: 'JUSSARI',
    initials: 'BA',
    code: '2918555',
  },
  {
    name: 'JUSSIAPE',
    initials: 'BA',
    code: '2918605',
  },
  {
    name: 'JUTAÍ',
    initials: 'AM',
    code: '1302306',
  },
  {
    name: 'JUTÍ',
    initials: 'MS',
    code: '5005152',
  },
  {
    name: 'JUVENÍLIA',
    initials: 'MG',
    code: '3136959',
  },
  {
    name: 'KALORÉ',
    initials: 'PR',
    code: '4113106',
  },
  {
    name: 'LÁBREA',
    initials: 'AM',
    code: '1302405',
  },
  {
    name: 'LACERDÓPOLIS',
    initials: 'SC',
    code: '4209201',
  },
  {
    name: 'LADAINHA',
    initials: 'MG',
    code: '3137007',
  },
  {
    name: 'LADÁRIO',
    initials: 'MS',
    code: '5005202',
  },
  {
    name: 'LAFAIETE COUTINHO',
    initials: 'BA',
    code: '2918704',
  },
  {
    name: 'LAGAMAR',
    initials: 'MG',
    code: '3137106',
  },
  {
    name: 'LAGARTO',
    initials: 'SE',
    code: '2803500',
  },
  {
    name: 'LAGES',
    initials: 'SC',
    code: '4209300',
  },
  {
    name: 'LAGOA',
    initials: 'PB',
    code: '2508109',
  },
  {
    name: 'LAGOA ALEGRE',
    initials: 'PI',
    code: '2205557',
  },
  {
    name: 'LAGOA BONITA DO SUL',
    initials: 'RS',
    code: '4311239',
  },
  {
    name: 'LAGOA DA CANOA',
    initials: 'AL',
    code: '2704104',
  },
  {
    name: 'LAGOA DA CONFUSÃO',
    initials: 'TO',
    code: '1711902',
  },
  {
    name: 'LAGOA D\'ANTA',
    initials: 'RN',
    code: '2406205',
  },
  {
    name: 'LAGOA DA PRATA',
    initials: 'MG',
    code: '3137205',
  },
  {
    name: 'LAGOA DE DENTRO',
    initials: 'PB',
    code: '2508208',
  },
  {
    name: 'LAGOA DE PEDRAS',
    initials: 'RN',
    code: '2406304',
  },
  {
    name: 'LAGOA DE SÃO FRANCISCO',
    initials: 'PI',
    code: '2205573',
  },
  {
    name: 'LAGOA DE VELHOS',
    initials: 'RN',
    code: '2406403',
  },
  {
    name: 'LAGOA DO BARRO DO PIAUÍ',
    initials: 'PI',
    code: '2205565',
  },
  {
    name: 'LAGOA DO CARRO',
    initials: 'PE',
    code: '2608453',
  },
  {
    name: 'LAGOA DO ITAENGA',
    initials: 'PE',
    code: '2608503',
  },
  {
    name: 'LAGOA DO MATO',
    initials: 'MA',
    code: '2105922',
  },
  {
    name: 'LAGOA DO OURO',
    initials: 'PE',
    code: '2608602',
  },
  {
    name: 'LAGOA DO PIAUÍ',
    initials: 'PI',
    code: '2205581',
  },
  {
    name: 'LAGOA DOS GATOS',
    initials: 'PE',
    code: '2608701',
  },
  {
    name: 'LAGOA DO SÍTIO',
    initials: 'PI',
    code: '2205599',
  },
  {
    name: 'LAGOA DOS PATOS',
    initials: 'MG',
    code: '3137304',
  },
  {
    name: 'LAGOA DOS TRÊS CANTOS',
    initials: 'RS',
    code: '4311270',
  },
  {
    name: 'LAGOA DO TOCANTINS',
    initials: 'TO',
    code: '1711951',
  },
  {
    name: 'LAGOA DOURADA',
    initials: 'MG',
    code: '3137403',
  },
  {
    name: 'LAGOA FORMOSA',
    initials: 'MG',
    code: '3137502',
  },
  {
    name: 'LAGOA GRANDE',
    initials: 'MG',
    code: '3137536',
  },
  {
    name: 'LAGOA GRANDE',
    initials: 'PE',
    code: '2608750',
  },
  {
    name: 'LAGOA GRANDE DO MARANHÃO',
    initials: 'MA',
    code: '2105963',
  },
  {
    name: 'LAGOA NOVA',
    initials: 'RN',
    code: '2406502',
  },
  {
    name: 'LAGOÃO',
    initials: 'RS',
    code: '4311254',
  },
  {
    name: 'LAGOA REAL',
    initials: 'BA',
    code: '2918753',
  },
  {
    name: 'LAGOA SALGADA',
    initials: 'RN',
    code: '2406601',
  },
  {
    name: 'LAGOA SANTA',
    initials: 'GO',
    code: '5212253',
  },
  {
    name: 'LAGOA SANTA',
    initials: 'MG',
    code: '3137601',
  },
  {
    name: 'LAGOA SECA',
    initials: 'PB',
    code: '2508307',
  },
  {
    name: 'LAGOA VERMELHA',
    initials: 'RS',
    code: '4311304',
  },
  {
    name: 'LAGO DA PEDRA',
    initials: 'MA',
    code: '2105708',
  },
  {
    name: 'LAGO DO JUNCO',
    initials: 'MA',
    code: '2105807',
  },
  {
    name: 'LAGO DOS RODRIGUES',
    initials: 'MA',
    code: '2105948',
  },
  {
    name: 'LAGOINHA',
    initials: 'SP',
    code: '3526308',
  },
  {
    name: 'LAGOINHA DO PIAUÍ',
    initials: 'PI',
    code: '2205540',
  },
  {
    name: 'LAGO VERDE',
    initials: 'MA',
    code: '2105906',
  },
  {
    name: 'LAGUNA',
    initials: 'SC',
    code: '4209409',
  },
  {
    name: 'LAGUNA CARAPÃ',
    initials: 'MS',
    code: '5005251',
  },
  {
    name: 'LAJE',
    initials: 'BA',
    code: '2918803',
  },
  {
    name: 'LAJEADO',
    initials: 'RS',
    code: '4311403',
  },
  {
    name: 'LAJEADO',
    initials: 'TO',
    code: '1712009',
  },
  {
    name: 'LAJEADO DO BUGRE',
    initials: 'RS',
    code: '4311429',
  },
  {
    name: 'LAJEADO GRANDE',
    initials: 'SC',
    code: '4209458',
  },
  {
    name: 'LAJEADO NOVO',
    initials: 'MA',
    code: '2105989',
  },
  {
    name: 'LAJEDÃO',
    initials: 'BA',
    code: '2918902',
  },
  {
    name: 'LAJEDINHO',
    initials: 'BA',
    code: '2919009',
  },
  {
    name: 'LAJEDO',
    initials: 'PE',
    code: '2608800',
  },
  {
    name: 'LAJEDO DO TABOCAL',
    initials: 'BA',
    code: '2919058',
  },
  {
    name: 'LAJE DO MURIAÉ',
    initials: 'RJ',
    code: '3302304',
  },
  {
    name: 'LAJES',
    initials: 'RN',
    code: '2406700',
  },
  {
    name: 'LAJES PINTADAS',
    initials: 'RN',
    code: '2406809',
  },
  {
    name: 'LAJINHA',
    initials: 'MG',
    code: '3137700',
  },
  {
    name: 'LAMARÃO',
    initials: 'BA',
    code: '2919108',
  },
  {
    name: 'LAMBARI',
    initials: 'MG',
    code: '3137809',
  },
  {
    name: 'LAMBARI D\'OESTE',
    initials: 'MT',
    code: '5105234',
  },
  {
    name: 'LAMIM',
    initials: 'MG',
    code: '3137908',
  },
  {
    name: 'LANDRI SALES',
    initials: 'PI',
    code: '2205607',
  },
  {
    name: 'LAPA',
    initials: 'PR',
    code: '4113205',
  },
  {
    name: 'LAPÃO',
    initials: 'BA',
    code: '2919157',
  },
  {
    name: 'LARANJA DA TERRA',
    initials: 'ES',
    code: '3203163',
  },
  {
    name: 'LARANJAL',
    initials: 'MG',
    code: '3138005',
  },
  {
    name: 'LARANJAL',
    initials: 'PR',
    code: '4113254',
  },
  {
    name: 'LARANJAL DO JARI',
    initials: 'AP',
    code: '1600279',
  },
  {
    name: 'LARANJAL PAULISTA',
    initials: 'SP',
    code: '3526407',
  },
  {
    name: 'LARANJEIRAS',
    initials: 'SE',
    code: '2803609',
  },
  {
    name: 'LARANJEIRAS DO SUL',
    initials: 'PR',
    code: '4113304',
  },
  {
    name: 'LASSANCE',
    initials: 'MG',
    code: '3138104',
  },
  {
    name: 'LASTRO',
    initials: 'PB',
    code: '2508406',
  },
  {
    name: 'LAURENTINO',
    initials: 'SC',
    code: '4209508',
  },
  {
    name: 'LAURO DE FREITAS',
    initials: 'BA',
    code: '2919207',
  },
  {
    name: 'LAURO MÜLLER',
    initials: 'SC',
    code: '4209607',
  },
  {
    name: 'LAVANDEIRA',
    initials: 'TO',
    code: '1712157',
  },
  {
    name: 'LAVÍNIA',
    initials: 'SP',
    code: '3526506',
  },
  {
    name: 'LAVRAS',
    initials: 'MG',
    code: '3138203',
  },
  {
    name: 'LAVRAS DA MANGABEIRA',
    initials: 'CE',
    code: '2307502',
  },
  {
    name: 'LAVRAS DO SUL',
    initials: 'RS',
    code: '4311502',
  },
  {
    name: 'LAVRINHAS',
    initials: 'SP',
    code: '3526605',
  },
  {
    name: 'LEANDRO FERREIRA',
    initials: 'MG',
    code: '3138302',
  },
  {
    name: 'LEBON RÉGIS',
    initials: 'SC',
    code: '4209706',
  },
  {
    name: 'LEME',
    initials: 'SP',
    code: '3526704',
  },
  {
    name: 'LEME DO PRADO',
    initials: 'MG',
    code: '3138351',
  },
  {
    name: 'LENÇÓIS',
    initials: 'BA',
    code: '2919306',
  },
  {
    name: 'LENÇÓIS PAULISTA',
    initials: 'SP',
    code: '3526803',
  },
  {
    name: 'LEOBERTO LEAL',
    initials: 'SC',
    code: '4209805',
  },
  {
    name: 'LEOPOLDINA',
    initials: 'MG',
    code: '3138401',
  },
  {
    name: 'LEOPOLDO DE BULHÕES',
    initials: 'GO',
    code: '5212303',
  },
  {
    name: 'LEÓPOLIS',
    initials: 'PR',
    code: '4113403',
  },
  {
    name: 'LIBERATO SALZANO',
    initials: 'RS',
    code: '4311601',
  },
  {
    name: 'LIBERDADE',
    initials: 'MG',
    code: '3138500',
  },
  {
    name: 'LICÍNIO DE ALMEIDA',
    initials: 'BA',
    code: '2919405',
  },
  {
    name: 'LIDIANÓPOLIS',
    initials: 'PR',
    code: '4113429',
  },
  {
    name: 'LIMA CAMPOS',
    initials: 'MA',
    code: '2106003',
  },
  {
    name: 'LIMA DUARTE',
    initials: 'MG',
    code: '3138609',
  },
  {
    name: 'LIMEIRA',
    initials: 'SP',
    code: '3526902',
  },
  {
    name: 'LIMEIRA DO OESTE',
    initials: 'MG',
    code: '3138625',
  },
  {
    name: 'LIMOEIRO',
    initials: 'PE',
    code: '2608909',
  },
  {
    name: 'LIMOEIRO DE ANADIA',
    initials: 'AL',
    code: '2704203',
  },
  {
    name: 'LIMOEIRO DO AJURU',
    initials: 'PA',
    code: '1504000',
  },
  {
    name: 'LIMOEIRO DO NORTE',
    initials: 'CE',
    code: '2307601',
  },
  {
    name: 'LINDOESTE',
    initials: 'PR',
    code: '4113452',
  },
  {
    name: 'LINDÓIA',
    initials: 'SP',
    code: '3527009',
  },
  {
    name: 'LINDÓIA DO SUL',
    initials: 'SC',
    code: '4209854',
  },
  {
    name: 'LINDOLFO COLLOR',
    initials: 'RS',
    code: '4311627',
  },
  {
    name: 'LINHA NOVA',
    initials: 'RS',
    code: '4311643',
  },
  {
    name: 'LINHARES',
    initials: 'ES',
    code: '3203205',
  },
  {
    name: 'LINS',
    initials: 'SP',
    code: '3527108',
  },
  {
    name: 'LIVRAMENTO',
    initials: 'PB',
    code: '2508505',
  },
  {
    name: 'LIVRAMENTO DE NOSSA SENHORA',
    initials: 'BA',
    code: '2919504',
  },
  {
    name: 'LIZARDA',
    initials: 'TO',
    code: '1712405',
  },
  {
    name: 'LOANDA',
    initials: 'PR',
    code: '4113502',
  },
  {
    name: 'LOBATO',
    initials: 'PR',
    code: '4113601',
  },
  {
    name: 'LOGRADOURO',
    initials: 'PB',
    code: '2508554',
  },
  {
    name: 'LONDRINA',
    initials: 'PR',
    code: '4113700',
  },
  {
    name: 'LONTRA',
    initials: 'MG',
    code: '3138658',
  },
  {
    name: 'LONTRAS',
    initials: 'SC',
    code: '4209904',
  },
  {
    name: 'LORENA',
    initials: 'SP',
    code: '3527207',
  },
  {
    name: 'LORETO',
    initials: 'MA',
    code: '2106102',
  },
  {
    name: 'LOURDES',
    initials: 'SP',
    code: '3527256',
  },
  {
    name: 'LOUVEIRA',
    initials: 'SP',
    code: '3527306',
  },
  {
    name: 'LUCAS DO RIO VERDE',
    initials: 'MT',
    code: '5105259',
  },
  {
    name: 'LUCÉLIA',
    initials: 'SP',
    code: '3527405',
  },
  {
    name: 'LUCENA',
    initials: 'PB',
    code: '2508604',
  },
  {
    name: 'LUCIANÓPOLIS',
    initials: 'SP',
    code: '3527504',
  },
  {
    name: 'LUCIARA',
    initials: 'MT',
    code: '5105309',
  },
  {
    name: 'LUCRÉCIA',
    initials: 'RN',
    code: '2406908',
  },
  {
    name: 'LUÍS ANTÔNIO',
    initials: 'SP',
    code: '3527603',
  },
  {
    name: 'LUISBURGO',
    initials: 'MG',
    code: '3138674',
  },
  {
    name: 'LUÍS CORREIA',
    initials: 'PI',
    code: '2205706',
  },
  {
    name: 'LUÍS DOMINGUES',
    initials: 'MA',
    code: '2106201',
  },
  {
    name: 'LUÍS EDUARDO MAGALHÃES',
    initials: 'BA',
    code: '2919553',
  },
  {
    name: 'LUÍS GOMES',
    initials: 'RN',
    code: '2407005',
  },
  {
    name: 'LUISLÂNDIA',
    initials: 'MG',
    code: '3138682',
  },
  {
    name: 'LUIZ ALVES',
    initials: 'SC',
    code: '4210001',
  },
  {
    name: 'LUIZIANA',
    initials: 'PR',
    code: '4113734',
  },
  {
    name: 'LUIZIÂNIA',
    initials: 'SP',
    code: '3527702',
  },
  {
    name: 'LUMINÁRIAS',
    initials: 'MG',
    code: '3138708',
  },
  {
    name: 'LUNARDELLI',
    initials: 'PR',
    code: '4113759',
  },
  {
    name: 'LUPÉRCIO',
    initials: 'SP',
    code: '3527801',
  },
  {
    name: 'LUPIONÓPOLIS',
    initials: 'PR',
    code: '4113809',
  },
  {
    name: 'LUTÉCIA',
    initials: 'SP',
    code: '3527900',
  },
  {
    name: 'LUZ',
    initials: 'MG',
    code: '3138807',
  },
  {
    name: 'LUZERNA',
    initials: 'SC',
    code: '4210035',
  },
  {
    name: 'LUZIÂNIA',
    initials: 'GO',
    code: '5212501',
  },
  {
    name: 'LUZILÂNDIA',
    initials: 'PI',
    code: '2205805',
  },
  {
    name: 'LUZINÓPOLIS',
    initials: 'TO',
    code: '1712454',
  },
  {
    name: 'MACAÉ',
    initials: 'RJ',
    code: '3302403',
  },
  {
    name: 'MACAÍBA',
    initials: 'RN',
    code: '2407104',
  },
  {
    name: 'MACAJUBA',
    initials: 'BA',
    code: '2919603',
  },
  {
    name: 'MAÇAMBARÁ',
    initials: 'RS',
    code: '4311718',
  },
  {
    name: 'MACAMBIRA',
    initials: 'SE',
    code: '2803708',
  },
  {
    name: 'MACAPÁ',
    initials: 'AP',
    code: '1600303',
  },
  {
    name: 'MACAPARANA',
    initials: 'PE',
    code: '2609006',
  },
  {
    name: 'MACARANI',
    initials: 'BA',
    code: '2919702',
  },
  {
    name: 'MACATUBA',
    initials: 'SP',
    code: '3528007',
  },
  {
    name: 'MACAU',
    initials: 'RN',
    code: '2407203',
  },
  {
    name: 'MACAUBAL',
    initials: 'SP',
    code: '3528106',
  },
  {
    name: 'MACAÚBAS',
    initials: 'BA',
    code: '2919801',
  },
  {
    name: 'MACEDÔNIA',
    initials: 'SP',
    code: '3528205',
  },
  {
    name: 'MACEIÓ',
    initials: 'AL',
    code: '2704302',
  },
  {
    name: 'MACHACALIS',
    initials: 'MG',
    code: '3138906',
  },
  {
    name: 'MACHADINHO',
    initials: 'RS',
    code: '4311700',
  },
  {
    name: 'MACHADINHO D\'OESTE',
    initials: 'RO',
    code: '1100130',
  },
  {
    name: 'MACHADO',
    initials: 'MG',
    code: '3139003',
  },
  {
    name: 'MACHADOS',
    initials: 'PE',
    code: '2609105',
  },
  {
    name: 'MACIEIRA',
    initials: 'SC',
    code: '4210050',
  },
  {
    name: 'MACUCO',
    initials: 'RJ',
    code: '3302452',
  },
  {
    name: 'MACURURÉ',
    initials: 'BA',
    code: '2919900',
  },
  {
    name: 'MADALENA',
    initials: 'CE',
    code: '2307635',
  },
  {
    name: 'MADEIRO',
    initials: 'PI',
    code: '2205854',
  },
  {
    name: 'MADRE DE DEUS',
    initials: 'BA',
    code: '2919926',
  },
  {
    name: 'MADRE DE DEUS DE MINAS',
    initials: 'MG',
    code: '3139102',
  },
  {
    name: 'MÃE D\'ÁGUA',
    initials: 'PB',
    code: '2508703',
  },
  {
    name: 'MÃE DO RIO',
    initials: 'PA',
    code: '1504059',
  },
  {
    name: 'MAETINGA',
    initials: 'BA',
    code: '2919959',
  },
  {
    name: 'MAFRA',
    initials: 'SC',
    code: '4210100',
  },
  {
    name: 'MAGALHÃES BARATA',
    initials: 'PA',
    code: '1504109',
  },
  {
    name: 'MAGALHÃES DE ALMEIDA',
    initials: 'MA',
    code: '2106300',
  },
  {
    name: 'MÁGDA',
    initials: 'SP',
    code: '3528304',
  },
  {
    name: 'MAGÉ',
    initials: 'RJ',
    code: '3302502',
  },
  {
    name: 'MAIQUINIQUE',
    initials: 'BA',
    code: '2920007',
  },
  {
    name: 'MAIRI',
    initials: 'BA',
    code: '2920106',
  },
  {
    name: 'MAIRINQUE',
    initials: 'SP',
    code: '3528403',
  },
  {
    name: 'MAIRIPORÃ',
    initials: 'SP',
    code: '3528502',
  },
  {
    name: 'MAIRIPOTABA',
    initials: 'GO',
    code: '5212600',
  },
  {
    name: 'MAJOR GERCINO',
    initials: 'SC',
    code: '4210209',
  },
  {
    name: 'MAJOR ISIDORO',
    initials: 'AL',
    code: '2704401',
  },
  {
    name: 'MAJOR SALES',
    initials: 'RN',
    code: '2407252',
  },
  {
    name: 'MAJOR VIEIRA',
    initials: 'SC',
    code: '4210308',
  },
  {
    name: 'MALACACHETA',
    initials: 'MG',
    code: '3139201',
  },
  {
    name: 'MALHADA',
    initials: 'BA',
    code: '2920205',
  },
  {
    name: 'MALHADA DE PEDRAS',
    initials: 'BA',
    code: '2920304',
  },
  {
    name: 'MALHADA DOS BOIS',
    initials: 'SE',
    code: '2803807',
  },
  {
    name: 'MALHADOR',
    initials: 'SE',
    code: '2803906',
  },
  {
    name: 'MALLET',
    initials: 'PR',
    code: '4113908',
  },
  {
    name: 'MALTA',
    initials: 'PB',
    code: '2508802',
  },
  {
    name: 'MAMANGUAPE',
    initials: 'PB',
    code: '2508901',
  },
  {
    name: 'MAMBAÍ',
    initials: 'GO',
    code: '5212709',
  },
  {
    name: 'MAMBORÊ',
    initials: 'PR',
    code: '4114005',
  },
  {
    name: 'MAMONAS',
    initials: 'MG',
    code: '3139250',
  },
  {
    name: 'MAMPITUBA',
    initials: 'RS',
    code: '4311734',
  },
  {
    name: 'MANACAPURU',
    initials: 'AM',
    code: '1302504',
  },
  {
    name: 'MANAÍRA',
    initials: 'PB',
    code: '2509008',
  },
  {
    name: 'MANAQUIRI',
    initials: 'AM',
    code: '1302553',
  },
  {
    name: 'MANARI',
    initials: 'PE',
    code: '2609154',
  },
  {
    name: 'MANAUS',
    initials: 'AM',
    code: '1302603',
  },
  {
    name: 'MÂNCIO LIMA',
    initials: 'AC',
    code: '1200336',
  },
  {
    name: 'MANDAGUAÇU',
    initials: 'PR',
    code: '4114104',
  },
  {
    name: 'MANDAGUARI',
    initials: 'PR',
    code: '4114203',
  },
  {
    name: 'MANDIRITUBA',
    initials: 'PR',
    code: '4114302',
  },
  {
    name: 'MANDURI',
    initials: 'SP',
    code: '3528601',
  },
  {
    name: 'MANFRINÓPOLIS',
    initials: 'PR',
    code: '4114351',
  },
  {
    name: 'MANGA',
    initials: 'MG',
    code: '3139300',
  },
  {
    name: 'MANGARATIBA',
    initials: 'RJ',
    code: '3302601',
  },
  {
    name: 'MANGUEIRINHA',
    initials: 'PR',
    code: '4114401',
  },
  {
    name: 'MANHUAÇU',
    initials: 'MG',
    code: '3139409',
  },
  {
    name: 'MANHUMIRIM',
    initials: 'MG',
    code: '3139508',
  },
  {
    name: 'MANICORÉ',
    initials: 'AM',
    code: '1302702',
  },
  {
    name: 'MANOEL EMÍDIO',
    initials: 'PI',
    code: '2205904',
  },
  {
    name: 'MANOEL RIBAS',
    initials: 'PR',
    code: '4114500',
  },
  {
    name: 'MANOEL URBANO',
    initials: 'AC',
    code: '1200344',
  },
  {
    name: 'MANOEL VIANA',
    initials: 'RS',
    code: '4311759',
  },
  {
    name: 'MANOEL VITORINO',
    initials: 'BA',
    code: '2920403',
  },
  {
    name: 'MANSIDÃO',
    initials: 'BA',
    code: '2920452',
  },
  {
    name: 'MANTENA',
    initials: 'MG',
    code: '3139607',
  },
  {
    name: 'MANTENÓPOLIS',
    initials: 'ES',
    code: '3203304',
  },
  {
    name: 'MAQUINÉ',
    initials: 'RS',
    code: '4311775',
  },
  {
    name: 'MARAÃ',
    initials: 'AM',
    code: '1302801',
  },
  {
    name: 'MARABÁ',
    initials: 'PA',
    code: '1504208',
  },
  {
    name: 'MARABÁ PAULISTA',
    initials: 'SP',
    code: '3528700',
  },
  {
    name: 'MARACAÇUMÉ',
    initials: 'MA',
    code: '2106326',
  },
  {
    name: 'MARACAÍ',
    initials: 'SP',
    code: '3528809',
  },
  {
    name: 'MARACAJÁ',
    initials: 'SC',
    code: '4210407',
  },
  {
    name: 'MARACAJU',
    initials: 'MS',
    code: '5005400',
  },
  {
    name: 'MARACANÃ',
    initials: 'PA',
    code: '1504307',
  },
  {
    name: 'MARACANAÚ',
    initials: 'CE',
    code: '2307650',
  },
  {
    name: 'MARACÁS',
    initials: 'BA',
    code: '2920502',
  },
  {
    name: 'MARAGOGI',
    initials: 'AL',
    code: '2704500',
  },
  {
    name: 'MARAGOGIPE',
    initials: 'BA',
    code: '2920601',
  },
  {
    name: 'MARAIAL',
    initials: 'PE',
    code: '2609204',
  },
  {
    name: 'MARAJÁ DO SENA',
    initials: 'MA',
    code: '2106359',
  },
  {
    name: 'MARANGUAPE',
    initials: 'CE',
    code: '2307700',
  },
  {
    name: 'MARANHÃOZINHO',
    initials: 'MA',
    code: '2106375',
  },
  {
    name: 'MARAPANIM',
    initials: 'PA',
    code: '1504406',
  },
  {
    name: 'MARAPOAMA',
    initials: 'SP',
    code: '3528858',
  },
  {
    name: 'MARA ROSA',
    initials: 'GO',
    code: '5212808',
  },
  {
    name: 'MARATÁ',
    initials: 'RS',
    code: '4311791',
  },
  {
    name: 'MARATAÍZES',
    initials: 'ES',
    code: '3203320',
  },
  {
    name: 'MARAU',
    initials: 'RS',
    code: '4311809',
  },
  {
    name: 'MARAÚ',
    initials: 'BA',
    code: '2920700',
  },
  {
    name: 'MARAVILHA',
    initials: 'SC',
    code: '4210506',
  },
  {
    name: 'MARAVILHA',
    initials: 'AL',
    code: '2704609',
  },
  {
    name: 'MARAVILHAS',
    initials: 'MG',
    code: '3139706',
  },
  {
    name: 'MARCAÇÃO',
    initials: 'PB',
    code: '2509057',
  },
  {
    name: 'MARCELÂNDIA',
    initials: 'MT',
    code: '5105580',
  },
  {
    name: 'MARCELINO RAMOS',
    initials: 'RS',
    code: '4311908',
  },
  {
    name: 'MARCELINO VIEIRA',
    initials: 'RN',
    code: '2407302',
  },
  {
    name: 'MARCIONÍLIO SOUZA',
    initials: 'BA',
    code: '2920809',
  },
  {
    name: 'MARCO',
    initials: 'CE',
    code: '2307809',
  },
  {
    name: 'MARCOLÂNDIA',
    initials: 'PI',
    code: '2205953',
  },
  {
    name: 'MARCOS PARENTE',
    initials: 'PI',
    code: '2206001',
  },
  {
    name: 'MAR DE ESPANHA',
    initials: 'MG',
    code: '3139805',
  },
  {
    name: 'MARECHAL CÂNDIDO RONDON',
    initials: 'PR',
    code: '4114609',
  },
  {
    name: 'MARECHAL DEODORO',
    initials: 'AL',
    code: '2704708',
  },
  {
    name: 'MARECHAL FLORIANO',
    initials: 'ES',
    code: '3203346',
  },
  {
    name: 'MARECHAL THAUMATURGO',
    initials: 'AC',
    code: '1200351',
  },
  {
    name: 'MAREMA',
    initials: 'SC',
    code: '4210555',
  },
  {
    name: 'MARI',
    initials: 'PB',
    code: '2509107',
  },
  {
    name: 'MARIA DA FÉ',
    initials: 'MG',
    code: '3139904',
  },
  {
    name: 'MARIA HELENA',
    initials: 'PR',
    code: '4114708',
  },
  {
    name: 'MARIALVA',
    initials: 'PR',
    code: '4114807',
  },
  {
    name: 'MARIANA',
    initials: 'MG',
    code: '3140001',
  },
  {
    name: 'MARIANA PIMENTEL',
    initials: 'RS',
    code: '4311981',
  },
  {
    name: 'MARIANO MORO',
    initials: 'RS',
    code: '4312005',
  },
  {
    name: 'MARIANÓPOLIS DO TOCANTINS',
    initials: 'TO',
    code: '1712504',
  },
  {
    name: 'MARIÁPOLIS',
    initials: 'SP',
    code: '3528908',
  },
  {
    name: 'MARIBONDO',
    initials: 'AL',
    code: '2704807',
  },
  {
    name: 'MARICÁ',
    initials: 'RJ',
    code: '3302700',
  },
  {
    name: 'MARILAC',
    initials: 'MG',
    code: '3140100',
  },
  {
    name: 'MARILÂNDIA',
    initials: 'ES',
    code: '3203353',
  },
  {
    name: 'MARILÂNDIA DO SUL',
    initials: 'PR',
    code: '4114906',
  },
  {
    name: 'MARILENA',
    initials: 'PR',
    code: '4115002',
  },
  {
    name: 'MARÍLIA',
    initials: 'SP',
    code: '3529005',
  },
  {
    name: 'MARILUZ',
    initials: 'PR',
    code: '4115101',
  },
  {
    name: 'MARINGÁ',
    initials: 'PR',
    code: '4115200',
  },
  {
    name: 'MARINÓPOLIS',
    initials: 'SP',
    code: '3529104',
  },
  {
    name: 'MÁRIO CAMPOS',
    initials: 'MG',
    code: '3140159',
  },
  {
    name: 'MARIÓPOLIS',
    initials: 'PR',
    code: '4115309',
  },
  {
    name: 'MARIPÁ',
    initials: 'PR',
    code: '4115358',
  },
  {
    name: 'MARIPÁ DE MINAS',
    initials: 'MG',
    code: '3140209',
  },
  {
    name: 'MARITUBA',
    initials: 'PA',
    code: '1504422',
  },
  {
    name: 'MARIZÓPOLIS',
    initials: 'PB',
    code: '2509156',
  },
  {
    name: 'MARLIÉRIA',
    initials: 'MG',
    code: '3140308',
  },
  {
    name: 'MARMELEIRO',
    initials: 'PR',
    code: '4115408',
  },
  {
    name: 'MARMELÓPOLIS',
    initials: 'MG',
    code: '3140407',
  },
  {
    name: 'MARQUES DE SOUZA',
    initials: 'RS',
    code: '4312054',
  },
  {
    name: 'MARQUINHO',
    initials: 'PR',
    code: '4115457',
  },
  {
    name: 'MARTINHO CAMPOS',
    initials: 'MG',
    code: '3140506',
  },
  {
    name: 'MARTINÓPOLE',
    initials: 'CE',
    code: '2307908',
  },
  {
    name: 'MARTINÓPOLIS',
    initials: 'SP',
    code: '3529203',
  },
  {
    name: 'MARTINS',
    initials: 'RN',
    code: '2407401',
  },
  {
    name: 'MARTINS SOARES',
    initials: 'MG',
    code: '3140530',
  },
  {
    name: 'MARUIM',
    initials: 'SE',
    code: '2804003',
  },
  {
    name: 'MARUMBI',
    initials: 'PR',
    code: '4115507',
  },
  {
    name: 'MAR VERMELHO',
    initials: 'AL',
    code: '2704906',
  },
  {
    name: 'MARZAGÃO',
    initials: 'GO',
    code: '5212907',
  },
  {
    name: 'MASCOTE',
    initials: 'BA',
    code: '2920908',
  },
  {
    name: 'MASSAPÊ',
    initials: 'CE',
    code: '2308005',
  },
  {
    name: 'MASSAPÊ DO PIAUÍ',
    initials: 'PI',
    code: '2206050',
  },
  {
    name: 'MASSARANDUBA',
    initials: 'SC',
    code: '4210605',
  },
  {
    name: 'MASSARANDUBA',
    initials: 'PB',
    code: '2509206',
  },
  {
    name: 'MATA',
    initials: 'RS',
    code: '4312104',
  },
  {
    name: 'MATA DE SÃO JOÃO',
    initials: 'BA',
    code: '2921005',
  },
  {
    name: 'MATA GRANDE',
    initials: 'AL',
    code: '2705002',
  },
  {
    name: 'MATÃO',
    initials: 'SP',
    code: '3529302',
  },
  {
    name: 'MATARACA',
    initials: 'PB',
    code: '2509305',
  },
  {
    name: 'MATA ROMA',
    initials: 'MA',
    code: '2106409',
  },
  {
    name: 'MATA VERDE',
    initials: 'MG',
    code: '3140555',
  },
  {
    name: 'MATEIROS',
    initials: 'TO',
    code: '1712702',
  },
  {
    name: 'MATELÂNDIA',
    initials: 'PR',
    code: '4115606',
  },
  {
    name: 'MATERLÂNDIA',
    initials: 'MG',
    code: '3140605',
  },
  {
    name: 'MATEUS LEME',
    initials: 'MG',
    code: '3140704',
  },
  {
    name: 'MATHIAS LOBATO',
    initials: 'MG',
    code: '3171501',
  },
  {
    name: 'MATIAS BARBOSA',
    initials: 'MG',
    code: '3140803',
  },
  {
    name: 'MATIAS CARDOSO',
    initials: 'MG',
    code: '3140852',
  },
  {
    name: 'MATIAS OLÍMPIO',
    initials: 'PI',
    code: '2206100',
  },
  {
    name: 'MATINA',
    initials: 'BA',
    code: '2921054',
  },
  {
    name: 'MATINHA',
    initials: 'MA',
    code: '2106508',
  },
  {
    name: 'MATINHAS',
    initials: 'PB',
    code: '2509339',
  },
  {
    name: 'MATINHOS',
    initials: 'PR',
    code: '4115705',
  },
  {
    name: 'MATIPÓ',
    initials: 'MG',
    code: '3140902',
  },
  {
    name: 'MATO CASTELHANO',
    initials: 'RS',
    code: '4312138',
  },
  {
    name: 'MATÕES',
    initials: 'MA',
    code: '2106607',
  },
  {
    name: 'MATÕES DO NORTE',
    initials: 'MA',
    code: '2106631',
  },
  {
    name: 'MATO GROSSO',
    initials: 'PB',
    code: '2509370',
  },
  {
    name: 'MATO LEITÃO',
    initials: 'RS',
    code: '4312153',
  },
  {
    name: 'MATO QUEIMADO',
    initials: 'RS',
    code: '4312179',
  },
  {
    name: 'MATO RICO',
    initials: 'PR',
    code: '4115739',
  },
  {
    name: 'MATOS COSTA',
    initials: 'SC',
    code: '4210704',
  },
  {
    name: 'MATO VERDE',
    initials: 'MG',
    code: '3141009',
  },
  {
    name: 'MATOZINHOS',
    initials: 'MG',
    code: '3141108',
  },
  {
    name: 'MATRINCHÃ',
    initials: 'GO',
    code: '5212956',
  },
  {
    name: 'MATRIZ DE CAMARAGIBE',
    initials: 'AL',
    code: '2705101',
  },
  {
    name: 'MATUPÁ',
    initials: 'MT',
    code: '5105606',
  },
  {
    name: 'MATURÉIA',
    initials: 'PB',
    code: '2509396',
  },
  {
    name: 'MATUTINA',
    initials: 'MG',
    code: '3141207',
  },
  {
    name: 'MAUÁ',
    initials: 'SP',
    code: '3529401',
  },
  {
    name: 'MAUÁ DA SERRA',
    initials: 'PR',
    code: '4115754',
  },
  {
    name: 'MAUÉS',
    initials: 'AM',
    code: '1302900',
  },
  {
    name: 'MAURILÂNDIA',
    initials: 'GO',
    code: '5213004',
  },
  {
    name: 'MAURILÂNDIA DO TOCANTINS',
    initials: 'TO',
    code: '1712801',
  },
  {
    name: 'MAURITI',
    initials: 'CE',
    code: '2308104',
  },
  {
    name: 'MAXARANGUAPE',
    initials: 'RN',
    code: '2407500',
  },
  {
    name: 'MAXIMILIANO DE ALMEIDA',
    initials: 'RS',
    code: '4312203',
  },
  {
    name: 'MAZAGÃO',
    initials: 'AP',
    code: '1600402',
  },
  {
    name: 'MEDEIROS',
    initials: 'MG',
    code: '3141306',
  },
  {
    name: 'MEDEIROS NETO',
    initials: 'BA',
    code: '2921104',
  },
  {
    name: 'MEDIANEIRA',
    initials: 'PR',
    code: '4115804',
  },
  {
    name: 'MEDICILÂNDIA',
    initials: 'PA',
    code: '1504455',
  },
  {
    name: 'MEDINA',
    initials: 'MG',
    code: '3141405',
  },
  {
    name: 'MELEIRO',
    initials: 'SC',
    code: '4210803',
  },
  {
    name: 'MELGAÇO',
    initials: 'PA',
    code: '1504505',
  },
  {
    name: 'MENDES',
    initials: 'RJ',
    code: '3302809',
  },
  {
    name: 'MENDES PIMENTEL',
    initials: 'MG',
    code: '3141504',
  },
  {
    name: 'MENDONÇA',
    initials: 'SP',
    code: '3529500',
  },
  {
    name: 'MERCEDES',
    initials: 'PR',
    code: '4115853',
  },
  {
    name: 'MERCÊS',
    initials: 'MG',
    code: '3141603',
  },
  {
    name: 'MERIDIANO',
    initials: 'SP',
    code: '3529609',
  },
  {
    name: 'MERUOCA',
    initials: 'CE',
    code: '2308203',
  },
  {
    name: 'MESÓPOLIS',
    initials: 'SP',
    code: '3529658',
  },
  {
    name: 'MESQUITA',
    initials: 'MG',
    code: '3141702',
  },
  {
    name: 'MESQUITA',
    initials: 'RJ',
    code: '3302858',
  },
  {
    name: 'MESSIAS',
    initials: 'AL',
    code: '2705200',
  },
  {
    name: 'MESSIAS TARGINO',
    initials: 'RN',
    code: '2407609',
  },
  {
    name: 'MIGUEL ALVES',
    initials: 'PI',
    code: '2206209',
  },
  {
    name: 'MIGUEL CALMON',
    initials: 'BA',
    code: '2921203',
  },
  {
    name: 'MIGUEL LEÃO',
    initials: 'PI',
    code: '2206308',
  },
  {
    name: 'MIGUELÓPOLIS',
    initials: 'SP',
    code: '3529708',
  },
  {
    name: 'MIGUEL PEREIRA',
    initials: 'RJ',
    code: '3302908',
  },
  {
    name: 'MILAGRES',
    initials: 'BA',
    code: '2921302',
  },
  {
    name: 'MILAGRES',
    initials: 'CE',
    code: '2308302',
  },
  {
    name: 'MILAGRES DO MARANHÃO',
    initials: 'MA',
    code: '2106672',
  },
  {
    name: 'MILHÃ',
    initials: 'CE',
    code: '2308351',
  },
  {
    name: 'MILTON BRANDÃO',
    initials: 'PI',
    code: '2206357',
  },
  {
    name: 'MIMOSO DE GOIÁS',
    initials: 'GO',
    code: '5213053',
  },
  {
    name: 'MIMOSO DO SUL',
    initials: 'ES',
    code: '3203403',
  },
  {
    name: 'MINAÇU',
    initials: 'GO',
    code: '5213087',
  },
  {
    name: 'MINADOR DO NEGRÃO',
    initials: 'AL',
    code: '2705309',
  },
  {
    name: 'MINAS DO LEÃO',
    initials: 'RS',
    code: '4312252',
  },
  {
    name: 'MINAS NOVAS',
    initials: 'MG',
    code: '3141801',
  },
  {
    name: 'MINDURI',
    initials: 'MG',
    code: '3141900',
  },
  {
    name: 'MINEIROS',
    initials: 'GO',
    code: '5213103',
  },
  {
    name: 'MINEIROS DO TIETÊ',
    initials: 'SP',
    code: '3529807',
  },
  {
    name: 'MINISTRO ANDREAZZA',
    initials: 'RO',
    code: '1101203',
  },
  {
    name: 'MIRABELA',
    initials: 'MG',
    code: '3142007',
  },
  {
    name: 'MIRACATU',
    initials: 'SP',
    code: '3529906',
  },
  {
    name: 'MIRACEMA',
    initials: 'RJ',
    code: '3303005',
  },
  {
    name: 'MIRACEMA DO TOCANTINS',
    initials: 'TO',
    code: '1713205',
  },
  {
    name: 'MIRADOR',
    initials: 'MA',
    code: '2106706',
  },
  {
    name: 'MIRADOR',
    initials: 'PR',
    code: '4115903',
  },
  {
    name: 'MIRADOURO',
    initials: 'MG',
    code: '3142106',
  },
  {
    name: 'MIRA ESTRELA',
    initials: 'SP',
    code: '3530003',
  },
  {
    name: 'MIRAGUAÍ',
    initials: 'RS',
    code: '4312302',
  },
  {
    name: 'MIRAÍ',
    initials: 'MG',
    code: '3142205',
  },
  {
    name: 'MIRAÍMA',
    initials: 'CE',
    code: '2308377',
  },
  {
    name: 'MIRANDA',
    initials: 'MS',
    code: '5005608',
  },
  {
    name: 'MIRANDA DO NORTE',
    initials: 'MA',
    code: '2106755',
  },
  {
    name: 'MIRANDIBA',
    initials: 'PE',
    code: '2609303',
  },
  {
    name: 'MIRANDÓPOLIS',
    initials: 'SP',
    code: '3530102',
  },
  {
    name: 'MIRANGABA',
    initials: 'BA',
    code: '2921401',
  },
  {
    name: 'MIRANORTE',
    initials: 'TO',
    code: '1713304',
  },
  {
    name: 'MIRANTE',
    initials: 'BA',
    code: '2921450',
  },
  {
    name: 'MIRANTE DA SERRA',
    initials: 'RO',
    code: '1101302',
  },
  {
    name: 'MIRANTE DO PARANAPANEMA',
    initials: 'SP',
    code: '3530201',
  },
  {
    name: 'MIRASELVA',
    initials: 'PR',
    code: '4116000',
  },
  {
    name: 'MIRASSOL',
    initials: 'SP',
    code: '3530300',
  },
  {
    name: 'MIRASSOLÂNDIA',
    initials: 'SP',
    code: '3530409',
  },
  {
    name: 'MIRASSOL D\'OESTE',
    initials: 'MT',
    code: '5105622',
  },
  {
    name: 'MIRAVÂNIA',
    initials: 'MG',
    code: '3142254',
  },
  {
    name: 'MIRIM DOCE',
    initials: 'SC',
    code: '4210852',
  },
  {
    name: 'MIRINZAL',
    initials: 'MA',
    code: '2106805',
  },
  {
    name: 'MISSAL',
    initials: 'PR',
    code: '4116059',
  },
  {
    name: 'MISSÃO VELHA',
    initials: 'CE',
    code: '2308401',
  },
  {
    name: 'MOCAJUBA',
    initials: 'PA',
    code: '1504604',
  },
  {
    name: 'MOCOCA',
    initials: 'SP',
    code: '3530508',
  },
  {
    name: 'MODELO',
    initials: 'SC',
    code: '4210902',
  },
  {
    name: 'MOEDA',
    initials: 'MG',
    code: '3142304',
  },
  {
    name: 'MOEMA',
    initials: 'MG',
    code: '3142403',
  },
  {
    name: 'MOGEIRO',
    initials: 'PB',
    code: '2509404',
  },
  {
    name: 'MOGI DAS CRUZES',
    initials: 'SP',
    code: '3530607',
  },
  {
    name: 'MOGI GUAÇU',
    initials: 'SP',
    code: '3530706',
  },
  {
    name: 'MOGI MIRIM',
    initials: 'SP',
    code: '3530805',
  },
  {
    name: 'MOIPORÁ',
    initials: 'GO',
    code: '5213400',
  },
  {
    name: 'MOITA BONITA',
    initials: 'SE',
    code: '2804102',
  },
  {
    name: 'MOJU',
    initials: 'PA',
    code: '1504703',
  },
  {
    name: 'MOJUÍ DOS CAMPOS',
    initials: 'PA',
    code: '1504752',
  },
  {
    name: 'MOMBAÇA',
    initials: 'CE',
    code: '2308500',
  },
  {
    name: 'MOMBUCA',
    initials: 'SP',
    code: '3530904',
  },
  {
    name: 'MONÇÃO',
    initials: 'MA',
    code: '2106904',
  },
  {
    name: 'MONÇÕES',
    initials: 'SP',
    code: '3531001',
  },
  {
    name: 'MONDAÍ',
    initials: 'SC',
    code: '4211009',
  },
  {
    name: 'MONGAGUÁ',
    initials: 'SP',
    code: '3531100',
  },
  {
    name: 'MONJOLOS',
    initials: 'MG',
    code: '3142502',
  },
  {
    name: 'MONSENHOR GIL',
    initials: 'PI',
    code: '2206407',
  },
  {
    name: 'MONSENHOR HIPÓLITO',
    initials: 'PI',
    code: '2206506',
  },
  {
    name: 'MONSENHOR PAULO',
    initials: 'MG',
    code: '3142601',
  },
  {
    name: 'MONSENHOR TABOSA',
    initials: 'CE',
    code: '2308609',
  },
  {
    name: 'MONTADAS',
    initials: 'PB',
    code: '2509503',
  },
  {
    name: 'MONTALVÂNIA',
    initials: 'MG',
    code: '3142700',
  },
  {
    name: 'MONTANHA',
    initials: 'ES',
    code: '3203502',
  },
  {
    name: 'MONTANHAS',
    initials: 'RN',
    code: '2407708',
  },
  {
    name: 'MONTAURI',
    initials: 'RS',
    code: '4312351',
  },
  {
    name: 'MONTE ALEGRE',
    initials: 'PA',
    code: '1504802',
  },
  {
    name: 'MONTE ALEGRE',
    initials: 'RN',
    code: '2407807',
  },
  {
    name: 'MONTE ALEGRE DE GOIÁS',
    initials: 'GO',
    code: '5213509',
  },
  {
    name: 'MONTE ALEGRE DE MINAS',
    initials: 'MG',
    code: '3142809',
  },
  {
    name: 'MONTE ALEGRE DE SERGIPE',
    initials: 'SE',
    code: '2804201',
  },
  {
    name: 'MONTE ALEGRE DO PIAUÍ',
    initials: 'PI',
    code: '2206605',
  },
  {
    name: 'MONTE ALEGRE DOS CAMPOS',
    initials: 'RS',
    code: '4312377',
  },
  {
    name: 'MONTE ALEGRE DO SUL',
    initials: 'SP',
    code: '3531209',
  },
  {
    name: 'MONTE ALTO',
    initials: 'SP',
    code: '3531308',
  },
  {
    name: 'MONTE APRAZÍVEL',
    initials: 'SP',
    code: '3531407',
  },
  {
    name: 'MONTE AZUL',
    initials: 'MG',
    code: '3142908',
  },
  {
    name: 'MONTE AZUL PAULISTA',
    initials: 'SP',
    code: '3531506',
  },
  {
    name: 'MONTE BELO',
    initials: 'MG',
    code: '3143005',
  },
  {
    name: 'MONTE BELO DO SUL',
    initials: 'RS',
    code: '4312385',
  },
  {
    name: 'MONTE CARLO',
    initials: 'SC',
    code: '4211058',
  },
  {
    name: 'MONTE CARMELO',
    initials: 'MG',
    code: '3143104',
  },
  {
    name: 'MONTE CASTELO',
    initials: 'SP',
    code: '3531605',
  },
  {
    name: 'MONTE CASTELO',
    initials: 'SC',
    code: '4211108',
  },
  {
    name: 'MONTE DAS GAMELEIRAS',
    initials: 'RN',
    code: '2407906',
  },
  {
    name: 'MONTE DO CARMO',
    initials: 'TO',
    code: '1713601',
  },
  {
    name: 'MONTE FORMOSO',
    initials: 'MG',
    code: '3143153',
  },
  {
    name: 'MONTE HOREBE',
    initials: 'PB',
    code: '2509602',
  },
  {
    name: 'MONTEIRO',
    initials: 'PB',
    code: '2509701',
  },
  {
    name: 'MONTEIRO LOBATO',
    initials: 'SP',
    code: '3531704',
  },
  {
    name: 'MONTEIRÓPOLIS',
    initials: 'AL',
    code: '2705408',
  },
  {
    name: 'MONTE MOR',
    initials: 'SP',
    code: '3531803',
  },
  {
    name: 'MONTENEGRO',
    initials: 'RS',
    code: '4312401',
  },
  {
    name: 'MONTE NEGRO',
    initials: 'RO',
    code: '1101401',
  },
  {
    name: 'MONTES ALTOS',
    initials: 'MA',
    code: '2107001',
  },
  {
    name: 'MONTE SANTO',
    initials: 'BA',
    code: '2921500',
  },
  {
    name: 'MONTE SANTO DE MINAS',
    initials: 'MG',
    code: '3143203',
  },
  {
    name: 'MONTE SANTO DO TOCANTINS',
    initials: 'TO',
    code: '1713700',
  },
  {
    name: 'MONTES CLAROS',
    initials: 'MG',
    code: '3143302',
  },
  {
    name: 'MONTES CLAROS DE GOIÁS',
    initials: 'GO',
    code: '5213707',
  },
  {
    name: 'MONTE SIÃO',
    initials: 'MG',
    code: '3143401',
  },
  {
    name: 'MONTEZUMA',
    initials: 'MG',
    code: '3143450',
  },
  {
    name: 'MONTIVIDIU',
    initials: 'GO',
    code: '5213756',
  },
  {
    name: 'MONTIVIDIU DO NORTE',
    initials: 'GO',
    code: '5213772',
  },
  {
    name: 'MORADA NOVA',
    initials: 'CE',
    code: '2308708',
  },
  {
    name: 'MORADA NOVA DE MINAS',
    initials: 'MG',
    code: '3143500',
  },
  {
    name: 'MORAÚJO',
    initials: 'CE',
    code: '2308807',
  },
  {
    name: 'MOREILÂNDIA',
    initials: 'PE',
    code: '2614303',
  },
  {
    name: 'MOREIRA SALES',
    initials: 'PR',
    code: '4116109',
  },
  {
    name: 'MORENO',
    initials: 'PE',
    code: '2609402',
  },
  {
    name: 'MORMAÇO',
    initials: 'RS',
    code: '4312427',
  },
  {
    name: 'MORPARÁ',
    initials: 'BA',
    code: '2921609',
  },
  {
    name: 'MORRETES',
    initials: 'PR',
    code: '4116208',
  },
  {
    name: 'MORRINHOS',
    initials: 'CE',
    code: '2308906',
  },
  {
    name: 'MORRINHOS',
    initials: 'GO',
    code: '5213806',
  },
  {
    name: 'MORRINHOS DO SUL',
    initials: 'RS',
    code: '4312443',
  },
  {
    name: 'MORRO AGUDO',
    initials: 'SP',
    code: '3531902',
  },
  {
    name: 'MORRO AGUDO DE GOIÁS',
    initials: 'GO',
    code: '5213855',
  },
  {
    name: 'MORRO CABEÇA NO TEMPO',
    initials: 'PI',
    code: '2206654',
  },
  {
    name: 'MORRO DA FUMAÇA',
    initials: 'SC',
    code: '4211207',
  },
  {
    name: 'MORRO DA GARÇA',
    initials: 'MG',
    code: '3143609',
  },
  {
    name: 'MORRO DO CHAPÉU',
    initials: 'BA',
    code: '2921708',
  },
  {
    name: 'MORRO DO CHAPÉU DO PIAUÍ',
    initials: 'PI',
    code: '2206670',
  },
  {
    name: 'MORRO DO PILAR',
    initials: 'MG',
    code: '3143708',
  },
  {
    name: 'MORRO GRANDE',
    initials: 'SC',
    code: '4211256',
  },
  {
    name: 'MORRO REDONDO',
    initials: 'RS',
    code: '4312450',
  },
  {
    name: 'MORRO REUTER',
    initials: 'RS',
    code: '4312476',
  },
  {
    name: 'MORROS',
    initials: 'MA',
    code: '2107100',
  },
  {
    name: 'MORTUGABA',
    initials: 'BA',
    code: '2921807',
  },
  {
    name: 'MORUNGABA',
    initials: 'SP',
    code: '3532009',
  },
  {
    name: 'MOSSÂMEDES',
    initials: 'GO',
    code: '5213905',
  },
  {
    name: 'MOSSORÓ',
    initials: 'RN',
    code: '2408003',
  },
  {
    name: 'MOSTARDAS',
    initials: 'RS',
    code: '4312500',
  },
  {
    name: 'MOTUCA',
    initials: 'SP',
    code: '3532058',
  },
  {
    name: 'MOZARLÂNDIA',
    initials: 'GO',
    code: '5214002',
  },
  {
    name: 'MUANÁ',
    initials: 'PA',
    code: '1504901',
  },
  {
    name: 'MUCAJAÍ',
    initials: 'RR',
    code: '1400308',
  },
  {
    name: 'MUCAMBO',
    initials: 'CE',
    code: '2309003',
  },
  {
    name: 'MUCUGÊ',
    initials: 'BA',
    code: '2921906',
  },
  {
    name: 'MUÇUM',
    initials: 'RS',
    code: '4312609',
  },
  {
    name: 'MUCURI',
    initials: 'BA',
    code: '2922003',
  },
  {
    name: 'MUCURICI',
    initials: 'ES',
    code: '3203601',
  },
  {
    name: 'MUITOS CAPÕES',
    initials: 'RS',
    code: '4312617',
  },
  {
    name: 'MULITERNO',
    initials: 'RS',
    code: '4312625',
  },
  {
    name: 'MULUNGU',
    initials: 'CE',
    code: '2309102',
  },
  {
    name: 'MULUNGU',
    initials: 'PB',
    code: '2509800',
  },
  {
    name: 'MULUNGU DO MORRO',
    initials: 'BA',
    code: '2922052',
  },
  {
    name: 'MUNDO NOVO',
    initials: 'MS',
    code: '5005681',
  },
  {
    name: 'MUNDO NOVO',
    initials: 'GO',
    code: '5214051',
  },
  {
    name: 'MUNDO NOVO',
    initials: 'BA',
    code: '2922102',
  },
  {
    name: 'MUNHOZ',
    initials: 'MG',
    code: '3143807',
  },
  {
    name: 'MUNHOZ DE MELO',
    initials: 'PR',
    code: '4116307',
  },
  {
    name: 'MUNIZ FERREIRA',
    initials: 'BA',
    code: '2922201',
  },
  {
    name: 'MUNIZ FREIRE',
    initials: 'ES',
    code: '3203700',
  },
  {
    name: 'MUQUÉM DE SÃO FRANCISCO',
    initials: 'BA',
    code: '2922250',
  },
  {
    name: 'MUQUI',
    initials: 'ES',
    code: '3203809',
  },
  {
    name: 'MURIAÉ',
    initials: 'MG',
    code: '3143906',
  },
  {
    name: 'MURIBECA',
    initials: 'SE',
    code: '2804300',
  },
  {
    name: 'MURICI',
    initials: 'AL',
    code: '2705507',
  },
  {
    name: 'MURICI DOS PORTELAS',
    initials: 'PI',
    code: '2206696',
  },
  {
    name: 'MURICILÂNDIA',
    initials: 'TO',
    code: '1713957',
  },
  {
    name: 'MURITIBA',
    initials: 'BA',
    code: '2922300',
  },
  {
    name: 'MURUTINGA DO SUL',
    initials: 'SP',
    code: '3532108',
  },
  {
    name: 'MUTUÍPE',
    initials: 'BA',
    code: '2922409',
  },
  {
    name: 'MUTUM',
    initials: 'MG',
    code: '3144003',
  },
  {
    name: 'MUTUNÓPOLIS',
    initials: 'GO',
    code: '5214101',
  },
  {
    name: 'MUZAMBINHO',
    initials: 'MG',
    code: '3144102',
  },
  {
    name: 'NACIP RAYDAN',
    initials: 'MG',
    code: '3144201',
  },
  {
    name: 'NANTES',
    initials: 'SP',
    code: '3532157',
  },
  {
    name: 'NANUQUE',
    initials: 'MG',
    code: '3144300',
  },
  {
    name: 'NÃO-ME-TOQUE',
    initials: 'RS',
    code: '4312658',
  },
  {
    name: 'NAQUE',
    initials: 'MG',
    code: '3144359',
  },
  {
    name: 'NARANDIBA',
    initials: 'SP',
    code: '3532207',
  },
  {
    name: 'NATAL',
    initials: 'RN',
    code: '2408102',
  },
  {
    name: 'NATALÂNDIA',
    initials: 'MG',
    code: '3144375',
  },
  {
    name: 'NATÉRCIA',
    initials: 'MG',
    code: '3144409',
  },
  {
    name: 'NATIVIDADE',
    initials: 'RJ',
    code: '3303104',
  },
  {
    name: 'NATIVIDADE',
    initials: 'TO',
    code: '1714203',
  },
  {
    name: 'NATIVIDADE DA SERRA',
    initials: 'SP',
    code: '3532306',
  },
  {
    name: 'NATUBA',
    initials: 'PB',
    code: '2509909',
  },
  {
    name: 'NAVEGANTES',
    initials: 'SC',
    code: '4211306',
  },
  {
    name: 'NAVIRAÍ',
    initials: 'MS',
    code: '5005707',
  },
  {
    name: 'NAZARÉ',
    initials: 'BA',
    code: '2922508',
  },
  {
    name: 'NAZARÉ',
    initials: 'TO',
    code: '1714302',
  },
  {
    name: 'NAZARÉ DA MATA',
    initials: 'PE',
    code: '2609501',
  },
  {
    name: 'NAZARÉ DO PIAUÍ',
    initials: 'PI',
    code: '2206704',
  },
  {
    name: 'NAZARENO',
    initials: 'MG',
    code: '3144508',
  },
  {
    name: 'NAZARÉ PAULISTA',
    initials: 'SP',
    code: '3532405',
  },
  {
    name: 'NAZAREZINHO',
    initials: 'PB',
    code: '2510006',
  },
  {
    name: 'NAZÁRIA',
    initials: 'PI',
    code: '2206720',
  },
  {
    name: 'NAZÁRIO',
    initials: 'GO',
    code: '5214408',
  },
  {
    name: 'NEÓPOLIS',
    initials: 'SE',
    code: '2804409',
  },
  {
    name: 'NEPOMUCENO',
    initials: 'MG',
    code: '3144607',
  },
  {
    name: 'NERÓPOLIS',
    initials: 'GO',
    code: '5214507',
  },
  {
    name: 'NEVES PAULISTA',
    initials: 'SP',
    code: '3532504',
  },
  {
    name: 'NHAMUNDÁ',
    initials: 'AM',
    code: '1303007',
  },
  {
    name: 'NHANDEARA',
    initials: 'SP',
    code: '3532603',
  },
  {
    name: 'NICOLAU VERGUEIRO',
    initials: 'RS',
    code: '4312674',
  },
  {
    name: 'NILO PEÇANHA',
    initials: 'BA',
    code: '2922607',
  },
  {
    name: 'NILÓPOLIS',
    initials: 'RJ',
    code: '3303203',
  },
  {
    name: 'NINA RODRIGUES',
    initials: 'MA',
    code: '2107209',
  },
  {
    name: 'NINHEIRA',
    initials: 'MG',
    code: '3144656',
  },
  {
    name: 'NIOAQUE',
    initials: 'MS',
    code: '5005806',
  },
  {
    name: 'NIPOÃ',
    initials: 'SP',
    code: '3532702',
  },
  {
    name: 'NIQUELÂNDIA',
    initials: 'GO',
    code: '5214606',
  },
  {
    name: 'NÍSIA FLORESTA',
    initials: 'RN',
    code: '2408201',
  },
  {
    name: 'NITERÓI',
    initials: 'RJ',
    code: '3303302',
  },
  {
    name: 'NOBRES',
    initials: 'MT',
    code: '5105903',
  },
  {
    name: 'NONOAI',
    initials: 'RS',
    code: '4312708',
  },
  {
    name: 'NORDESTINA',
    initials: 'BA',
    code: '2922656',
  },
  {
    name: 'NORMANDIA',
    initials: 'RR',
    code: '1400407',
  },
  {
    name: 'NORTELÂNDIA',
    initials: 'MT',
    code: '5106000',
  },
  {
    name: 'NOSSA SENHORA APARECIDA',
    initials: 'SE',
    code: '2804458',
  },
  {
    name: 'NOSSA SENHORA DA GLÓRIA',
    initials: 'SE',
    code: '2804508',
  },
  {
    name: 'NOSSA SENHORA DAS DORES',
    initials: 'SE',
    code: '2804607',
  },
  {
    name: 'NOSSA SENHORA DAS GRAÇAS',
    initials: 'PR',
    code: '4116406',
  },
  {
    name: 'NOSSA SENHORA DE LOURDES',
    initials: 'SE',
    code: '2804706',
  },
  {
    name: 'NOSSA SENHORA DE NAZARÉ',
    initials: 'PI',
    code: '2206753',
  },
  {
    name: 'NOSSA SENHORA DO LIVRAMENTO',
    initials: 'MT',
    code: '5106109',
  },
  {
    name: 'NOSSA SENHORA DO SOCORRO',
    initials: 'SE',
    code: '2804805',
  },
  {
    name: 'NOSSA SENHORA DOS REMÉDIOS',
    initials: 'PI',
    code: '2206803',
  },
  {
    name: 'NOVA ALIANÇA',
    initials: 'SP',
    code: '3532801',
  },
  {
    name: 'NOVA ALIANÇA DO IVAÍ',
    initials: 'PR',
    code: '4116505',
  },
  {
    name: 'NOVA ALVORADA',
    initials: 'RS',
    code: '4312757',
  },
  {
    name: 'NOVA ALVORADA DO SUL',
    initials: 'MS',
    code: '5006002',
  },
  {
    name: 'NOVA AMÉRICA',
    initials: 'GO',
    code: '5214705',
  },
  {
    name: 'NOVA AMÉRICA DA COLINA',
    initials: 'PR',
    code: '4116604',
  },
  {
    name: 'NOVA ANDRADINA',
    initials: 'MS',
    code: '5006200',
  },
  {
    name: 'NOVA ARAÇÁ',
    initials: 'RS',
    code: '4312807',
  },
  {
    name: 'NOVA AURORA',
    initials: 'GO',
    code: '5214804',
  },
  {
    name: 'NOVA AURORA',
    initials: 'PR',
    code: '4116703',
  },
  {
    name: 'NOVA BANDEIRANTES',
    initials: 'MT',
    code: '5106158',
  },
  {
    name: 'NOVA BASSANO',
    initials: 'RS',
    code: '4312906',
  },
  {
    name: 'NOVA BELÉM',
    initials: 'MG',
    code: '3144672',
  },
  {
    name: 'NOVA BOA VISTA',
    initials: 'RS',
    code: '4312955',
  },
  {
    name: 'NOVA BRASILÂNDIA',
    initials: 'MT',
    code: '5106208',
  },
  {
    name: 'NOVA BRASILÂNDIA D\'OESTE',
    initials: 'RO',
    code: '1100148',
  },
  {
    name: 'NOVA BRÉSCIA',
    initials: 'RS',
    code: '4313003',
  },
  {
    name: 'NOVA CAMPINA',
    initials: 'SP',
    code: '3532827',
  },
  {
    name: 'NOVA CANAÃ',
    initials: 'BA',
    code: '2922706',
  },
  {
    name: 'NOVA CANAÃ DO NORTE',
    initials: 'MT',
    code: '5106216',
  },
  {
    name: 'NOVA CANAÃ PAULISTA',
    initials: 'SP',
    code: '3532843',
  },
  {
    name: 'NOVA CANDELÁRIA',
    initials: 'RS',
    code: '4313011',
  },
  {
    name: 'NOVA CANTU',
    initials: 'PR',
    code: '4116802',
  },
  {
    name: 'NOVA CASTILHO',
    initials: 'SP',
    code: '3532868',
  },
  {
    name: 'NOVA COLINAS',
    initials: 'MA',
    code: '2107258',
  },
  {
    name: 'NOVA CRIXÁS',
    initials: 'GO',
    code: '5214838',
  },
  {
    name: 'NOVA CRUZ',
    initials: 'RN',
    code: '2408300',
  },
  {
    name: 'NOVA ERA',
    initials: 'MG',
    code: '3144706',
  },
  {
    name: 'NOVA ERECHIM',
    initials: 'SC',
    code: '4211405',
  },
  {
    name: 'NOVA ESPERANÇA',
    initials: 'PR',
    code: '4116901',
  },
  {
    name: 'NOVA ESPERANÇA DO PIRIÁ',
    initials: 'PA',
    code: '1504950',
  },
  {
    name: 'NOVA ESPERANÇA DO SUDOESTE',
    initials: 'PR',
    code: '4116950',
  },
  {
    name: 'NOVA ESPERANÇA DO SUL',
    initials: 'RS',
    code: '4313037',
  },
  {
    name: 'NOVA EUROPA',
    initials: 'SP',
    code: '3532900',
  },
  {
    name: 'NOVA FÁTIMA',
    initials: 'PR',
    code: '4117008',
  },
  {
    name: 'NOVA FÁTIMA',
    initials: 'BA',
    code: '2922730',
  },
  {
    name: 'NOVA FLORESTA',
    initials: 'PB',
    code: '2510105',
  },
  {
    name: 'NOVA FRIBURGO',
    initials: 'RJ',
    code: '3303401',
  },
  {
    name: 'NOVA GLÓRIA',
    initials: 'GO',
    code: '5214861',
  },
  {
    name: 'NOVA GRANADA',
    initials: 'SP',
    code: '3533007',
  },
  {
    name: 'NOVA GUARITA',
    initials: 'MT',
    code: '5108808',
  },
  {
    name: 'NOVA GUATAPORANGA',
    initials: 'SP',
    code: '3533106',
  },
  {
    name: 'NOVA HARTZ',
    initials: 'RS',
    code: '4313060',
  },
  {
    name: 'NOVA IBIÁ',
    initials: 'BA',
    code: '2922755',
  },
  {
    name: 'NOVA IGUAÇU',
    initials: 'RJ',
    code: '3303500',
  },
  {
    name: 'NOVA IGUAÇU DE GOIÁS',
    initials: 'GO',
    code: '5214879',
  },
  {
    name: 'NOVA INDEPENDÊNCIA',
    initials: 'SP',
    code: '3533205',
  },
  {
    name: 'NOVA IORQUE',
    initials: 'MA',
    code: '2107308',
  },
  {
    name: 'NOVA IPIXUNA',
    initials: 'PA',
    code: '1504976',
  },
  {
    name: 'NOVAIS',
    initials: 'SP',
    code: '3533254',
  },
  {
    name: 'NOVA ITABERABA',
    initials: 'SC',
    code: '4211454',
  },
  {
    name: 'NOVA ITARANA',
    initials: 'BA',
    code: '2922805',
  },
  {
    name: 'NOVA LACERDA',
    initials: 'MT',
    code: '5106182',
  },
  {
    name: 'NOVA LARANJEIRAS',
    initials: 'PR',
    code: '4117057',
  },
  {
    name: 'NOVA LIMA',
    initials: 'MG',
    code: '3144805',
  },
  {
    name: 'NOVA LONDRINA',
    initials: 'PR',
    code: '4117107',
  },
  {
    name: 'NOVA LUZITÂNIA',
    initials: 'SP',
    code: '3533304',
  },
  {
    name: 'NOVA MAMORÉ',
    initials: 'RO',
    code: '1100338',
  },
  {
    name: 'NOVA MARILÂNDIA',
    initials: 'MT',
    code: '5108857',
  },
  {
    name: 'NOVA MARINGÁ',
    initials: 'MT',
    code: '5108907',
  },
  {
    name: 'NOVA MÓDICA',
    initials: 'MG',
    code: '3144904',
  },
  {
    name: 'NOVA MONTE VERDE',
    initials: 'MT',
    code: '5108956',
  },
  {
    name: 'NOVA MUTUM',
    initials: 'MT',
    code: '5106224',
  },
  {
    name: 'NOVA NAZARÉ',
    initials: 'MT',
    code: '5106174',
  },
  {
    name: 'NOVA ODESSA',
    initials: 'SP',
    code: '3533403',
  },
  {
    name: 'NOVA OLÍMPIA',
    initials: 'PR',
    code: '4117206',
  },
  {
    name: 'NOVA OLÍMPIA',
    initials: 'MT',
    code: '5106232',
  },
  {
    name: 'NOVA OLINDA',
    initials: 'TO',
    code: '1714880',
  },
  {
    name: 'NOVA OLINDA',
    initials: 'CE',
    code: '2309201',
  },
  {
    name: 'NOVA OLINDA',
    initials: 'PB',
    code: '2510204',
  },
  {
    name: 'NOVA OLINDA DO MARANHÃO',
    initials: 'MA',
    code: '2107357',
  },
  {
    name: 'NOVA OLINDA DO NORTE',
    initials: 'AM',
    code: '1303106',
  },
  {
    name: 'NOVA PÁDUA',
    initials: 'RS',
    code: '4313086',
  },
  {
    name: 'NOVA PALMA',
    initials: 'RS',
    code: '4313102',
  },
  {
    name: 'NOVA PALMEIRA',
    initials: 'PB',
    code: '2510303',
  },
  {
    name: 'NOVA PETRÓPOLIS',
    initials: 'RS',
    code: '4313201',
  },
  {
    name: 'NOVA PONTE',
    initials: 'MG',
    code: '3145000',
  },
  {
    name: 'NOVA PORTEIRINHA',
    initials: 'MG',
    code: '3145059',
  },
  {
    name: 'NOVA PRATA',
    initials: 'RS',
    code: '4313300',
  },
  {
    name: 'NOVA PRATA DO IGUAÇU',
    initials: 'PR',
    code: '4117255',
  },
  {
    name: 'NOVA RAMADA',
    initials: 'RS',
    code: '4313334',
  },
  {
    name: 'NOVA REDENÇÃO',
    initials: 'BA',
    code: '2922854',
  },
  {
    name: 'NOVA RESENDE',
    initials: 'MG',
    code: '3145109',
  },
  {
    name: 'NOVA ROMA',
    initials: 'GO',
    code: '5214903',
  },
  {
    name: 'NOVA ROMA DO SUL',
    initials: 'RS',
    code: '4313359',
  },
  {
    name: 'NOVA ROSALÂNDIA',
    initials: 'TO',
    code: '1715002',
  },
  {
    name: 'NOVA RUSSAS',
    initials: 'CE',
    code: '2309300',
  },
  {
    name: 'NOVA SANTA BÁRBARA',
    initials: 'PR',
    code: '4117214',
  },
  {
    name: 'NOVA SANTA HELENA',
    initials: 'MT',
    code: '5106190',
  },
  {
    name: 'NOVA SANTA RITA',
    initials: 'RS',
    code: '4313375',
  },
  {
    name: 'NOVA SANTA RITA',
    initials: 'PI',
    code: '2207959',
  },
  {
    name: 'NOVA SANTA ROSA',
    initials: 'PR',
    code: '4117222',
  },
  {
    name: 'NOVA SERRANA',
    initials: 'MG',
    code: '3145208',
  },
  {
    name: 'NOVA SOURE',
    initials: 'BA',
    code: '2922904',
  },
  {
    name: 'NOVA TEBAS',
    initials: 'PR',
    code: '4117271',
  },
  {
    name: 'NOVA TIMBOTEUA',
    initials: 'PA',
    code: '1505007',
  },
  {
    name: 'NOVA TRENTO',
    initials: 'SC',
    code: '4211504',
  },
  {
    name: 'NOVA UBIRATÃ',
    initials: 'MT',
    code: '5106240',
  },
  {
    name: 'NOVA UNIÃO',
    initials: 'RO',
    code: '1101435',
  },
  {
    name: 'NOVA UNIÃO',
    initials: 'MG',
    code: '3136603',
  },
  {
    name: 'NOVA VENÉCIA',
    initials: 'ES',
    code: '3203908',
  },
  {
    name: 'NOVA VENEZA',
    initials: 'GO',
    code: '5215009',
  },
  {
    name: 'NOVA VENEZA',
    initials: 'SC',
    code: '4211603',
  },
  {
    name: 'NOVA VIÇOSA',
    initials: 'BA',
    code: '2923001',
  },
  {
    name: 'NOVA XAVANTINA',
    initials: 'MT',
    code: '5106257',
  },
  {
    name: 'NOVO ACORDO',
    initials: 'TO',
    code: '1715101',
  },
  {
    name: 'NOVO AIRÃO',
    initials: 'AM',
    code: '1303205',
  },
  {
    name: 'NOVO ALEGRE',
    initials: 'TO',
    code: '1715150',
  },
  {
    name: 'NOVO ARIPUANÃ',
    initials: 'AM',
    code: '1303304',
  },
  {
    name: 'NOVO BARREIRO',
    initials: 'RS',
    code: '4313490',
  },
  {
    name: 'NOVO BRASIL',
    initials: 'GO',
    code: '5215207',
  },
  {
    name: 'NOVO CABRAIS',
    initials: 'RS',
    code: '4313391',
  },
  {
    name: 'NOVO CRUZEIRO',
    initials: 'MG',
    code: '3145307',
  },
  {
    name: 'NOVO GAMA',
    initials: 'GO',
    code: '5215231',
  },
  {
    name: 'NOVO HAMBURGO',
    initials: 'RS',
    code: '4313409',
  },
  {
    name: 'NOVO HORIZONTE',
    initials: 'SC',
    code: '4211652',
  },
  {
    name: 'NOVO HORIZONTE',
    initials: 'SP',
    code: '3533502',
  },
  {
    name: 'NOVO HORIZONTE',
    initials: 'BA',
    code: '2923035',
  },
  {
    name: 'NOVO HORIZONTE DO NORTE',
    initials: 'MT',
    code: '5106273',
  },
  {
    name: 'NOVO HORIZONTE DO OESTE',
    initials: 'RO',
    code: '1100502',
  },
  {
    name: 'NOVO HORIZONTE DO SUL',
    initials: 'MS',
    code: '5006259',
  },
  {
    name: 'NOVO ITACOLOMI',
    initials: 'PR',
    code: '4117297',
  },
  {
    name: 'NOVO JARDIM',
    initials: 'TO',
    code: '1715259',
  },
  {
    name: 'NOVO LINO',
    initials: 'AL',
    code: '2705606',
  },
  {
    name: 'NOVO MACHADO',
    initials: 'RS',
    code: '4313425',
  },
  {
    name: 'NOVO MUNDO',
    initials: 'MT',
    code: '5106265',
  },
  {
    name: 'NOVO ORIENTE',
    initials: 'CE',
    code: '2309409',
  },
  {
    name: 'NOVO ORIENTE DE MINAS',
    initials: 'MG',
    code: '3145356',
  },
  {
    name: 'NOVO ORIENTE DO PIAUÍ',
    initials: 'PI',
    code: '2206902',
  },
  {
    name: 'NOVO PLANALTO',
    initials: 'GO',
    code: '5215256',
  },
  {
    name: 'NOVO PROGRESSO',
    initials: 'PA',
    code: '1505031',
  },
  {
    name: 'NOVO REPARTIMENTO',
    initials: 'PA',
    code: '1505064',
  },
  {
    name: 'NOVORIZONTE',
    initials: 'MG',
    code: '3145372',
  },
  {
    name: 'NOVO SANTO ANTÔNIO',
    initials: 'PI',
    code: '2206951',
  },
  {
    name: 'NOVO SANTO ANTÔNIO',
    initials: 'MT',
    code: '5106315',
  },
  {
    name: 'NOVO SÃO JOAQUIM',
    initials: 'MT',
    code: '5106281',
  },
  {
    name: 'NOVO TIRADENTES',
    initials: 'RS',
    code: '4313441',
  },
  {
    name: 'NOVO TRIUNFO',
    initials: 'BA',
    code: '2923050',
  },
  {
    name: 'NOVO XINGU',
    initials: 'RS',
    code: '4313466',
  },
  {
    name: 'NUPORANGA',
    initials: 'SP',
    code: '3533601',
  },
  {
    name: 'ÓBIDOS',
    initials: 'PA',
    code: '1505106',
  },
  {
    name: 'OCARA',
    initials: 'CE',
    code: '2309458',
  },
  {
    name: 'OCAUÇU',
    initials: 'SP',
    code: '3533700',
  },
  {
    name: 'OEIRAS',
    initials: 'PI',
    code: '2207009',
  },
  {
    name: 'OEIRAS DO PARÁ',
    initials: 'PA',
    code: '1505205',
  },
  {
    name: 'OIAPOQUE',
    initials: 'AP',
    code: '1600501',
  },
  {
    name: 'OLARIA',
    initials: 'MG',
    code: '3145406',
  },
  {
    name: 'ÓLEO',
    initials: 'SP',
    code: '3533809',
  },
  {
    name: 'OLHO D\'ÁGUA',
    initials: 'PB',
    code: '2510402',
  },
  {
    name: 'OLHO D\'ÁGUA DAS CUNHÃS',
    initials: 'MA',
    code: '2107407',
  },
  {
    name: 'OLHO D\'ÁGUA DAS FLORES',
    initials: 'AL',
    code: '2705705',
  },
  {
    name: 'OLHO-D\'ÁGUA DO BORGES',
    initials: 'RN',
    code: '2408409',
  },
  {
    name: 'OLHO D\'ÁGUA DO CASADO',
    initials: 'AL',
    code: '2705804',
  },
  {
    name: 'OLHO D\'ÁGUA DO PIAUÍ',
    initials: 'PI',
    code: '2207108',
  },
  {
    name: 'OLHO D\'ÁGUA GRANDE',
    initials: 'AL',
    code: '2705903',
  },
  {
    name: 'OLHOS D\'ÁGUA',
    initials: 'MG',
    code: '3145455',
  },
  {
    name: 'OLÍMPIA',
    initials: 'SP',
    code: '3533908',
  },
  {
    name: 'OLÍMPIO NORONHA',
    initials: 'MG',
    code: '3145505',
  },
  {
    name: 'OLINDA',
    initials: 'PE',
    code: '2609600',
  },
  {
    name: 'OLINDA NOVA DO MARANHÃO',
    initials: 'MA',
    code: '2107456',
  },
  {
    name: 'OLINDINA',
    initials: 'BA',
    code: '2923100',
  },
  {
    name: 'OLIVEDOS',
    initials: 'PB',
    code: '2510501',
  },
  {
    name: 'OLIVEIRA',
    initials: 'MG',
    code: '3145604',
  },
  {
    name: 'OLIVEIRA DE FÁTIMA',
    initials: 'TO',
    code: '1715507',
  },
  {
    name: 'OLIVEIRA DOS BREJINHOS',
    initials: 'BA',
    code: '2923209',
  },
  {
    name: 'OLIVEIRA FORTES',
    initials: 'MG',
    code: '3145703',
  },
  {
    name: 'OLIVENÇA',
    initials: 'AL',
    code: '2706000',
  },
  {
    name: 'ONÇA DE PITANGUI',
    initials: 'MG',
    code: '3145802',
  },
  {
    name: 'ONDA VERDE',
    initials: 'SP',
    code: '3534005',
  },
  {
    name: 'ORATÓRIOS',
    initials: 'MG',
    code: '3145851',
  },
  {
    name: 'ORIENTE',
    initials: 'SP',
    code: '3534104',
  },
  {
    name: 'ORINDIÚVA',
    initials: 'SP',
    code: '3534203',
  },
  {
    name: 'ORIXIMINÁ',
    initials: 'PA',
    code: '1505304',
  },
  {
    name: 'ORIZÂNIA',
    initials: 'MG',
    code: '3145877',
  },
  {
    name: 'ORIZONA',
    initials: 'GO',
    code: '5215306',
  },
  {
    name: 'ORLÂNDIA',
    initials: 'SP',
    code: '3534302',
  },
  {
    name: 'ORLEANS',
    initials: 'SC',
    code: '4211702',
  },
  {
    name: 'OROBÓ',
    initials: 'PE',
    code: '2609709',
  },
  {
    name: 'OROCÓ',
    initials: 'PE',
    code: '2609808',
  },
  {
    name: 'ORÓS',
    initials: 'CE',
    code: '2309508',
  },
  {
    name: 'ORTIGUEIRA',
    initials: 'PR',
    code: '4117305',
  },
  {
    name: 'OSASCO',
    initials: 'SP',
    code: '3534401',
  },
  {
    name: 'OSCAR BRESSANE',
    initials: 'SP',
    code: '3534500',
  },
  {
    name: 'OSÓRIO',
    initials: 'RS',
    code: '4313508',
  },
  {
    name: 'OSVALDO CRUZ',
    initials: 'SP',
    code: '3534609',
  },
  {
    name: 'OTACÍLIO COSTA',
    initials: 'SC',
    code: '4211751',
  },
  {
    name: 'OURÉM',
    initials: 'PA',
    code: '1505403',
  },
  {
    name: 'OURIÇANGAS',
    initials: 'BA',
    code: '2923308',
  },
  {
    name: 'OURICURI',
    initials: 'PE',
    code: '2609907',
  },
  {
    name: 'OURILÂNDIA DO NORTE',
    initials: 'PA',
    code: '1505437',
  },
  {
    name: 'OURINHOS',
    initials: 'SP',
    code: '3534708',
  },
  {
    name: 'OURIZONA',
    initials: 'PR',
    code: '4117404',
  },
  {
    name: 'OURO',
    initials: 'SC',
    code: '4211801',
  },
  {
    name: 'OURO BRANCO',
    initials: 'AL',
    code: '2706109',
  },
  {
    name: 'OURO BRANCO',
    initials: 'MG',
    code: '3145901',
  },
  {
    name: 'OURO BRANCO',
    initials: 'RN',
    code: '2408508',
  },
  {
    name: 'OUROESTE',
    initials: 'SP',
    code: '3534757',
  },
  {
    name: 'OURO FINO',
    initials: 'MG',
    code: '3146008',
  },
  {
    name: 'OUROLÂNDIA',
    initials: 'BA',
    code: '2923357',
  },
  {
    name: 'OURO PRETO',
    initials: 'MG',
    code: '3146107',
  },
  {
    name: 'OURO PRETO DO OESTE',
    initials: 'RO',
    code: '1100155',
  },
  {
    name: 'OURO VELHO',
    initials: 'PB',
    code: '2510600',
  },
  {
    name: 'OURO VERDE',
    initials: 'SP',
    code: '3534807',
  },
  {
    name: 'OURO VERDE',
    initials: 'SC',
    code: '4211850',
  },
  {
    name: 'OURO VERDE DE GOIÁS',
    initials: 'GO',
    code: '5215405',
  },
  {
    name: 'OURO VERDE DE MINAS',
    initials: 'MG',
    code: '3146206',
  },
  {
    name: 'OURO VERDE DO OESTE',
    initials: 'PR',
    code: '4117453',
  },
  {
    name: 'OUVIDOR',
    initials: 'GO',
    code: '5215504',
  },
  {
    name: 'PACAEMBU',
    initials: 'SP',
    code: '3534906',
  },
  {
    name: 'PACAJÁ',
    initials: 'PA',
    code: '1505486',
  },
  {
    name: 'PACAJUS',
    initials: 'CE',
    code: '2309607',
  },
  {
    name: 'PACARAIMA',
    initials: 'RR',
    code: '1400456',
  },
  {
    name: 'PACATUBA',
    initials: 'SE',
    code: '2804904',
  },
  {
    name: 'PACATUBA',
    initials: 'CE',
    code: '2309706',
  },
  {
    name: 'PAÇO DO LUMIAR',
    initials: 'MA',
    code: '2107506',
  },
  {
    name: 'PACOTI',
    initials: 'CE',
    code: '2309805',
  },
  {
    name: 'PACUJÁ',
    initials: 'CE',
    code: '2309904',
  },
  {
    name: 'PADRE BERNARDO',
    initials: 'GO',
    code: '5215603',
  },
  {
    name: 'PADRE CARVALHO',
    initials: 'MG',
    code: '3146255',
  },
  {
    name: 'PADRE MARCOS',
    initials: 'PI',
    code: '2207207',
  },
  {
    name: 'PADRE PARAÍSO',
    initials: 'MG',
    code: '3146305',
  },
  {
    name: 'PAES LANDIM',
    initials: 'PI',
    code: '2207306',
  },
  {
    name: 'PAIAL',
    initials: 'SC',
    code: '4211876',
  },
  {
    name: 'PAIÇANDU',
    initials: 'PR',
    code: '4117503',
  },
  {
    name: 'PAIM FILHO',
    initials: 'RS',
    code: '4313607',
  },
  {
    name: 'PAINEIRAS',
    initials: 'MG',
    code: '3146404',
  },
  {
    name: 'PAINEL',
    initials: 'SC',
    code: '4211892',
  },
  {
    name: 'PAINS',
    initials: 'MG',
    code: '3146503',
  },
  {
    name: 'PAI PEDRO',
    initials: 'MG',
    code: '3146552',
  },
  {
    name: 'PAIVA',
    initials: 'MG',
    code: '3146602',
  },
  {
    name: 'PAJEÚ DO PIAUÍ',
    initials: 'PI',
    code: '2207355',
  },
  {
    name: 'PALESTINA',
    initials: 'AL',
    code: '2706208',
  },
  {
    name: 'PALESTINA',
    initials: 'SP',
    code: '3535002',
  },
  {
    name: 'PALESTINA DE GOIÁS',
    initials: 'GO',
    code: '5215652',
  },
  {
    name: 'PALESTINA DO PARÁ',
    initials: 'PA',
    code: '1505494',
  },
  {
    name: 'PALHANO',
    initials: 'CE',
    code: '2310001',
  },
  {
    name: 'PALHOÇA',
    initials: 'SC',
    code: '4211900',
  },
  {
    name: 'PALMA',
    initials: 'MG',
    code: '3146701',
  },
  {
    name: 'PALMÁCIA',
    initials: 'CE',
    code: '2310100',
  },
  {
    name: 'PALMARES',
    initials: 'PE',
    code: '2610004',
  },
  {
    name: 'PALMARES DO SUL',
    initials: 'RS',
    code: '4313656',
  },
  {
    name: 'PALMARES PAULISTA',
    initials: 'SP',
    code: '3535101',
  },
  {
    name: 'PALMAS',
    initials: 'PR',
    code: '4117602',
  },
  {
    name: 'PALMAS',
    initials: 'TO',
    code: '1721000',
  },
  {
    name: 'PALMAS DE MONTE ALTO',
    initials: 'BA',
    code: '2923407',
  },
  {
    name: 'PALMA SOLA',
    initials: 'SC',
    code: '4212007',
  },
  {
    name: 'PALMEIRA',
    initials: 'PR',
    code: '4117701',
  },
  {
    name: 'PALMEIRA',
    initials: 'SC',
    code: '4212056',
  },
  {
    name: 'PALMEIRA DAS MISSÕES',
    initials: 'RS',
    code: '4313706',
  },
  {
    name: 'PALMEIRA D\'OESTE',
    initials: 'SP',
    code: '3535200',
  },
  {
    name: 'PALMEIRA DO PIAUÍ',
    initials: 'PI',
    code: '2207405',
  },
  {
    name: 'PALMEIRA DOS ÍNDIOS',
    initials: 'AL',
    code: '2706307',
  },
  {
    name: 'PALMEIRAIS',
    initials: 'PI',
    code: '2207504',
  },
  {
    name: 'PALMEIRÂNDIA',
    initials: 'MA',
    code: '2107605',
  },
  {
    name: 'PALMEIRANTE',
    initials: 'TO',
    code: '1715705',
  },
  {
    name: 'PALMEIRAS',
    initials: 'BA',
    code: '2923506',
  },
  {
    name: 'PALMEIRAS DE GOIÁS',
    initials: 'GO',
    code: '5215702',
  },
  {
    name: 'PALMEIRAS DO TOCANTINS',
    initials: 'TO',
    code: '1713809',
  },
  {
    name: 'PALMEIRINA',
    initials: 'PE',
    code: '2610103',
  },
  {
    name: 'PALMEIRÓPOLIS',
    initials: 'TO',
    code: '1715754',
  },
  {
    name: 'PALMELO',
    initials: 'GO',
    code: '5215801',
  },
  {
    name: 'PALMINÓPOLIS',
    initials: 'GO',
    code: '5215900',
  },
  {
    name: 'PALMITAL',
    initials: 'SP',
    code: '3535309',
  },
  {
    name: 'PALMITAL',
    initials: 'PR',
    code: '4117800',
  },
  {
    name: 'PALMITINHO',
    initials: 'RS',
    code: '4313805',
  },
  {
    name: 'PALMITOS',
    initials: 'SC',
    code: '4212106',
  },
  {
    name: 'PALMÓPOLIS',
    initials: 'MG',
    code: '3146750',
  },
  {
    name: 'PALOTINA',
    initials: 'PR',
    code: '4117909',
  },
  {
    name: 'PANAMÁ',
    initials: 'GO',
    code: '5216007',
  },
  {
    name: 'PANAMBI',
    initials: 'RS',
    code: '4313904',
  },
  {
    name: 'PANCAS',
    initials: 'ES',
    code: '3204005',
  },
  {
    name: 'PANELAS',
    initials: 'PE',
    code: '2610202',
  },
  {
    name: 'PANORAMA',
    initials: 'SP',
    code: '3535408',
  },
  {
    name: 'PANTANO GRANDE',
    initials: 'RS',
    code: '4313953',
  },
  {
    name: 'PÃO DE AÇÚCAR',
    initials: 'AL',
    code: '2706406',
  },
  {
    name: 'PAPAGAIOS',
    initials: 'MG',
    code: '3146909',
  },
  {
    name: 'PAPANDUVA',
    initials: 'SC',
    code: '4212205',
  },
  {
    name: 'PAQUETÁ',
    initials: 'PI',
    code: '2207553',
  },
  {
    name: 'PARACAMBI',
    initials: 'RJ',
    code: '3303609',
  },
  {
    name: 'PARACATU',
    initials: 'MG',
    code: '3147006',
  },
  {
    name: 'PARACURU',
    initials: 'CE',
    code: '2310209',
  },
  {
    name: 'PARÁ DE MINAS',
    initials: 'MG',
    code: '3147105',
  },
  {
    name: 'PARAGOMINAS',
    initials: 'PA',
    code: '1505502',
  },
  {
    name: 'PARAGUAÇU',
    initials: 'MG',
    code: '3147204',
  },
  {
    name: 'PARAGUAÇU PAULISTA',
    initials: 'SP',
    code: '3535507',
  },
  {
    name: 'PARAÍ',
    initials: 'RS',
    code: '4314001',
  },
  {
    name: 'PARAÍBA DO SUL',
    initials: 'RJ',
    code: '3303708',
  },
  {
    name: 'PARAIBANO',
    initials: 'MA',
    code: '2107704',
  },
  {
    name: 'PARAIBUNA',
    initials: 'SP',
    code: '3535606',
  },
  {
    name: 'PARAIPABA',
    initials: 'CE',
    code: '2310258',
  },
  {
    name: 'PARAÍSO',
    initials: 'SP',
    code: '3535705',
  },
  {
    name: 'PARAÍSO',
    initials: 'SC',
    code: '4212239',
  },
  {
    name: 'PARAÍSO DAS ÁGUAS',
    initials: 'MS',
    code: '5006275',
  },
  {
    name: 'PARAÍSO DO NORTE',
    initials: 'PR',
    code: '4118006',
  },
  {
    name: 'PARAÍSO DO SUL',
    initials: 'RS',
    code: '4314027',
  },
  {
    name: 'PARAÍSO DO TOCANTINS',
    initials: 'TO',
    code: '1716109',
  },
  {
    name: 'PARAISÓPOLIS',
    initials: 'MG',
    code: '3147303',
  },
  {
    name: 'PARAMBU',
    initials: 'CE',
    code: '2310308',
  },
  {
    name: 'PARAMIRIM',
    initials: 'BA',
    code: '2923605',
  },
  {
    name: 'PARAMOTI',
    initials: 'CE',
    code: '2310407',
  },
  {
    name: 'PARANÁ',
    initials: 'RN',
    code: '2408607',
  },
  {
    name: 'PARANÃ',
    initials: 'TO',
    code: '1716208',
  },
  {
    name: 'PARANACITY',
    initials: 'PR',
    code: '4118105',
  },
  {
    name: 'PARANAGUÁ',
    initials: 'PR',
    code: '4118204',
  },
  {
    name: 'PARANAÍBA',
    initials: 'MS',
    code: '5006309',
  },
  {
    name: 'PARANAIGUARA',
    initials: 'GO',
    code: '5216304',
  },
  {
    name: 'PARANAITÁ',
    initials: 'MT',
    code: '5106299',
  },
  {
    name: 'PARANAPANEMA',
    initials: 'SP',
    code: '3535804',
  },
  {
    name: 'PARANAPOEMA',
    initials: 'PR',
    code: '4118303',
  },
  {
    name: 'PARANAPUÃ',
    initials: 'SP',
    code: '3535903',
  },
  {
    name: 'PARANATAMA',
    initials: 'PE',
    code: '2610301',
  },
  {
    name: 'PARANATINGA',
    initials: 'MT',
    code: '5106307',
  },
  {
    name: 'PARANAVAÍ',
    initials: 'PR',
    code: '4118402',
  },
  {
    name: 'PARANHOS',
    initials: 'MS',
    code: '5006358',
  },
  {
    name: 'PARAOPEBA',
    initials: 'MG',
    code: '3147402',
  },
  {
    name: 'PARAPUÃ',
    initials: 'SP',
    code: '3536000',
  },
  {
    name: 'PARARI',
    initials: 'PB',
    code: '2510659',
  },
  {
    name: 'PARATI',
    initials: 'RJ',
    code: '3303807',
  },
  {
    name: 'PARATINGA',
    initials: 'BA',
    code: '2923704',
  },
  {
    name: 'PARAÚ',
    initials: 'RN',
    code: '2408706',
  },
  {
    name: 'PARAUAPEBAS',
    initials: 'PA',
    code: '1505536',
  },
  {
    name: 'PARAÚNA',
    initials: 'GO',
    code: '5216403',
  },
  {
    name: 'PARAZINHO',
    initials: 'RN',
    code: '2408805',
  },
  {
    name: 'PARDINHO',
    initials: 'SP',
    code: '3536109',
  },
  {
    name: 'PARECI NOVO',
    initials: 'RS',
    code: '4314035',
  },
  {
    name: 'PARECIS',
    initials: 'RO',
    code: '1101450',
  },
  {
    name: 'PARELHAS',
    initials: 'RN',
    code: '2408904',
  },
  {
    name: 'PARICONHA',
    initials: 'AL',
    code: '2706422',
  },
  {
    name: 'PARINTINS',
    initials: 'AM',
    code: '1303403',
  },
  {
    name: 'PARIPIRANGA',
    initials: 'BA',
    code: '2923803',
  },
  {
    name: 'PARIPUEIRA',
    initials: 'AL',
    code: '2706448',
  },
  {
    name: 'PARIQUERA-AÇU',
    initials: 'SP',
    code: '3536208',
  },
  {
    name: 'PARISI',
    initials: 'SP',
    code: '3536257',
  },
  {
    name: 'PARNAGUÁ',
    initials: 'PI',
    code: '2207603',
  },
  {
    name: 'PARNAÍBA',
    initials: 'PI',
    code: '2207702',
  },
  {
    name: 'PARNAMIRIM',
    initials: 'PE',
    code: '2610400',
  },
  {
    name: 'PARNAMIRIM',
    initials: 'RN',
    code: '2403251',
  },
  {
    name: 'PARNARAMA',
    initials: 'MA',
    code: '2107803',
  },
  {
    name: 'PAROBÉ',
    initials: 'RS',
    code: '4314050',
  },
  {
    name: 'PASSABÉM',
    initials: 'MG',
    code: '3147501',
  },
  {
    name: 'PASSA E FICA',
    initials: 'RN',
    code: '2409100',
  },
  {
    name: 'PASSAGEM',
    initials: 'RN',
    code: '2409209',
  },
  {
    name: 'PASSAGEM',
    initials: 'PB',
    code: '2510709',
  },
  {
    name: 'PASSAGEM FRANCA',
    initials: 'MA',
    code: '2107902',
  },
  {
    name: 'PASSAGEM FRANCA DO PIAUÍ',
    initials: 'PI',
    code: '2207751',
  },
  {
    name: 'PASSA QUATRO',
    initials: 'MG',
    code: '3147600',
  },
  {
    name: 'PASSA SETE',
    initials: 'RS',
    code: '4314068',
  },
  {
    name: 'PASSA TEMPO',
    initials: 'MG',
    code: '3147709',
  },
  {
    name: 'PASSA VINTE',
    initials: 'MG',
    code: '3147808',
  },
  {
    name: 'PASSIRA',
    initials: 'PE',
    code: '2610509',
  },
  {
    name: 'PASSO DE CAMARAGIBE',
    initials: 'AL',
    code: '2706505',
  },
  {
    name: 'PASSO DE TORRES',
    initials: 'SC',
    code: '4212254',
  },
  {
    name: 'PASSO DO SOBRADO',
    initials: 'RS',
    code: '4314076',
  },
  {
    name: 'PASSO FUNDO',
    initials: 'RS',
    code: '4314100',
  },
  {
    name: 'PASSOS',
    initials: 'MG',
    code: '3147907',
  },
  {
    name: 'PASSOS MAIA',
    initials: 'SC',
    code: '4212270',
  },
  {
    name: 'PASTOS BONS',
    initials: 'MA',
    code: '2108009',
  },
  {
    name: 'PATIS',
    initials: 'MG',
    code: '3147956',
  },
  {
    name: 'PATO BRAGADO',
    initials: 'PR',
    code: '4118451',
  },
  {
    name: 'PATO BRANCO',
    initials: 'PR',
    code: '4118501',
  },
  {
    name: 'PATOS',
    initials: 'PB',
    code: '2510808',
  },
  {
    name: 'PATOS DE MINAS',
    initials: 'MG',
    code: '3148004',
  },
  {
    name: 'PATOS DO PIAUÍ',
    initials: 'PI',
    code: '2207777',
  },
  {
    name: 'PATROCÍNIO',
    initials: 'MG',
    code: '3148103',
  },
  {
    name: 'PATROCÍNIO DO MURIAÉ',
    initials: 'MG',
    code: '3148202',
  },
  {
    name: 'PATROCÍNIO PAULISTA',
    initials: 'SP',
    code: '3536307',
  },
  {
    name: 'PATU',
    initials: 'RN',
    code: '2409308',
  },
  {
    name: 'PATY DO ALFERES',
    initials: 'RJ',
    code: '3303856',
  },
  {
    name: 'PAU BRASIL',
    initials: 'BA',
    code: '2923902',
  },
  {
    name: 'PAUDALHO',
    initials: 'PE',
    code: '2610608',
  },
  {
    name: 'PAU D\'ARCO',
    initials: 'TO',
    code: '1716307',
  },
  {
    name: 'PAU D\'ARCO',
    initials: 'PA',
    code: '1505551',
  },
  {
    name: 'PAU D\'ARCO DO PIAUÍ',
    initials: 'PI',
    code: '2207793',
  },
  {
    name: 'PAU DOS FERROS',
    initials: 'RN',
    code: '2409407',
  },
  {
    name: 'PAUINI',
    initials: 'AM',
    code: '1303502',
  },
  {
    name: 'PAULA CÂNDIDO',
    initials: 'MG',
    code: '3148301',
  },
  {
    name: 'PAULA FREITAS',
    initials: 'PR',
    code: '4118600',
  },
  {
    name: 'PAULICÉIA',
    initials: 'SP',
    code: '3536406',
  },
  {
    name: 'PAULÍNIA',
    initials: 'SP',
    code: '3536505',
  },
  {
    name: 'PAULINO NEVES',
    initials: 'MA',
    code: '2108058',
  },
  {
    name: 'PAULISTA',
    initials: 'PB',
    code: '2510907',
  },
  {
    name: 'PAULISTA',
    initials: 'PE',
    code: '2610707',
  },
  {
    name: 'PAULISTANA',
    initials: 'PI',
    code: '2207801',
  },
  {
    name: 'PAULISTÂNIA',
    initials: 'SP',
    code: '3536570',
  },
  {
    name: 'PAULISTAS',
    initials: 'MG',
    code: '3148400',
  },
  {
    name: 'PAULO AFONSO',
    initials: 'BA',
    code: '2924009',
  },
  {
    name: 'PAULO BENTO',
    initials: 'RS',
    code: '4314134',
  },
  {
    name: 'PAULO DE FARIA',
    initials: 'SP',
    code: '3536604',
  },
  {
    name: 'PAULO FRONTIN',
    initials: 'PR',
    code: '4118709',
  },
  {
    name: 'PAULO JACINTO',
    initials: 'AL',
    code: '2706604',
  },
  {
    name: 'PAULO LOPES',
    initials: 'SC',
    code: '4212304',
  },
  {
    name: 'PAULO RAMOS',
    initials: 'MA',
    code: '2108108',
  },
  {
    name: 'PAVÃO',
    initials: 'MG',
    code: '3148509',
  },
  {
    name: 'PAVERAMA',
    initials: 'RS',
    code: '4314159',
  },
  {
    name: 'PAVUSSU',
    initials: 'PI',
    code: '2207850',
  },
  {
    name: 'PEABIRU',
    initials: 'PR',
    code: '4118808',
  },
  {
    name: 'PEÇANHA',
    initials: 'MG',
    code: '3148608',
  },
  {
    name: 'PEDERNEIRAS',
    initials: 'SP',
    code: '3536703',
  },
  {
    name: 'PÉ DE SERRA',
    initials: 'BA',
    code: '2924058',
  },
  {
    name: 'PEDRA',
    initials: 'PE',
    code: '2610806',
  },
  {
    name: 'PEDRA AZUL',
    initials: 'MG',
    code: '3148707',
  },
  {
    name: 'PEDRA BELA',
    initials: 'SP',
    code: '3536802',
  },
  {
    name: 'PEDRA BONITA',
    initials: 'MG',
    code: '3148756',
  },
  {
    name: 'PEDRA BRANCA',
    initials: 'CE',
    code: '2310506',
  },
  {
    name: 'PEDRA BRANCA',
    initials: 'PB',
    code: '2511004',
  },
  {
    name: 'PEDRA BRANCA DO AMAPARÍ',
    initials: 'AP',
    code: '1600154',
  },
  {
    name: 'PEDRA DO ANTA',
    initials: 'MG',
    code: '3148806',
  },
  {
    name: 'PEDRA DO INDAIÁ',
    initials: 'MG',
    code: '3148905',
  },
  {
    name: 'PEDRA DOURADA',
    initials: 'MG',
    code: '3149002',
  },
  {
    name: 'PEDRA GRANDE',
    initials: 'RN',
    code: '2409506',
  },
  {
    name: 'PEDRA LAVRADA',
    initials: 'PB',
    code: '2511103',
  },
  {
    name: 'PEDRALVA',
    initials: 'MG',
    code: '3149101',
  },
  {
    name: 'PEDRA MOLE',
    initials: 'SE',
    code: '2805000',
  },
  {
    name: 'PEDRANÓPOLIS',
    initials: 'SP',
    code: '3536901',
  },
  {
    name: 'PEDRÃO',
    initials: 'BA',
    code: '2924108',
  },
  {
    name: 'PEDRA PRETA',
    initials: 'RN',
    code: '2409605',
  },
  {
    name: 'PEDRA PRETA',
    initials: 'MT',
    code: '5106372',
  },
  {
    name: 'PEDRAS ALTAS',
    initials: 'RS',
    code: '4314175',
  },
  {
    name: 'PEDRAS DE FOGO',
    initials: 'PB',
    code: '2511202',
  },
  {
    name: 'PEDRAS DE MARIA DA CRUZ',
    initials: 'MG',
    code: '3149150',
  },
  {
    name: 'PEDRAS GRANDES',
    initials: 'SC',
    code: '4212403',
  },
  {
    name: 'PEDREGULHO',
    initials: 'SP',
    code: '3537008',
  },
  {
    name: 'PEDREIRA',
    initials: 'SP',
    code: '3537107',
  },
  {
    name: 'PEDREIRAS',
    initials: 'MA',
    code: '2108207',
  },
  {
    name: 'PEDRINHAS',
    initials: 'SE',
    code: '2805109',
  },
  {
    name: 'PEDRINHAS PAULISTA',
    initials: 'SP',
    code: '3537156',
  },
  {
    name: 'PEDRINÓPOLIS',
    initials: 'MG',
    code: '3149200',
  },
  {
    name: 'PEDRO AFONSO',
    initials: 'TO',
    code: '1716505',
  },
  {
    name: 'PEDRO ALEXANDRE',
    initials: 'BA',
    code: '2924207',
  },
  {
    name: 'PEDRO AVELINO',
    initials: 'RN',
    code: '2409704',
  },
  {
    name: 'PEDRO CANÁRIO',
    initials: 'ES',
    code: '3204054',
  },
  {
    name: 'PEDRO DE TOLEDO',
    initials: 'SP',
    code: '3537206',
  },
  {
    name: 'PEDRO DO ROSÁRIO',
    initials: 'MA',
    code: '2108256',
  },
  {
    name: 'PEDRO GOMES',
    initials: 'MS',
    code: '5006408',
  },
  {
    name: 'PEDRO II',
    initials: 'PI',
    code: '2207900',
  },
  {
    name: 'PEDRO LAURENTINO',
    initials: 'PI',
    code: '2207934',
  },
  {
    name: 'PEDRO LEOPOLDO',
    initials: 'MG',
    code: '3149309',
  },
  {
    name: 'PEDRO OSÓRIO',
    initials: 'RS',
    code: '4314209',
  },
  {
    name: 'PEDRO RÉGIS',
    initials: 'PB',
    code: '2512721',
  },
  {
    name: 'PEDRO TEIXEIRA',
    initials: 'MG',
    code: '3149408',
  },
  {
    name: 'PEDRO VELHO',
    initials: 'RN',
    code: '2409803',
  },
  {
    name: 'PEIXE',
    initials: 'TO',
    code: '1716604',
  },
  {
    name: 'PEIXE-BOI',
    initials: 'PA',
    code: '1505601',
  },
  {
    name: 'PEIXOTO DE AZEVEDO',
    initials: 'MT',
    code: '5106422',
  },
  {
    name: 'PEJUÇARA',
    initials: 'RS',
    code: '4314308',
  },
  {
    name: 'PELOTAS',
    initials: 'RS',
    code: '4314407',
  },
  {
    name: 'PENAFORTE',
    initials: 'CE',
    code: '2310605',
  },
  {
    name: 'PENALVA',
    initials: 'MA',
    code: '2108306',
  },
  {
    name: 'PENÁPOLIS',
    initials: 'SP',
    code: '3537305',
  },
  {
    name: 'PENDÊNCIAS',
    initials: 'RN',
    code: '2409902',
  },
  {
    name: 'PENEDO',
    initials: 'AL',
    code: '2706703',
  },
  {
    name: 'PENHA',
    initials: 'SC',
    code: '4212502',
  },
  {
    name: 'PENTECOSTE',
    initials: 'CE',
    code: '2310704',
  },
  {
    name: 'PEQUERI',
    initials: 'MG',
    code: '3149507',
  },
  {
    name: 'PEQUI',
    initials: 'MG',
    code: '3149606',
  },
  {
    name: 'PEQUIZEIRO',
    initials: 'TO',
    code: '1716653',
  },
  {
    name: 'PERDIGÃO',
    initials: 'MG',
    code: '3149705',
  },
  {
    name: 'PERDIZES',
    initials: 'MG',
    code: '3149804',
  },
  {
    name: 'PERDÕES',
    initials: 'MG',
    code: '3149903',
  },
  {
    name: 'PEREIRA BARRETO',
    initials: 'SP',
    code: '3537404',
  },
  {
    name: 'PEREIRAS',
    initials: 'SP',
    code: '3537503',
  },
  {
    name: 'PEREIRO',
    initials: 'CE',
    code: '2310803',
  },
  {
    name: 'PERI MIRIM',
    initials: 'MA',
    code: '2108405',
  },
  {
    name: 'PERIQUITO',
    initials: 'MG',
    code: '3149952',
  },
  {
    name: 'PERITIBA',
    initials: 'SC',
    code: '4212601',
  },
  {
    name: 'PERITORÓ',
    initials: 'MA',
    code: '2108454',
  },
  {
    name: 'PEROBAL',
    initials: 'PR',
    code: '4118857',
  },
  {
    name: 'PÉROLA',
    initials: 'PR',
    code: '4118907',
  },
  {
    name: 'PÉROLA D\'OESTE',
    initials: 'PR',
    code: '4119004',
  },
  {
    name: 'PEROLÂNDIA',
    initials: 'GO',
    code: '5216452',
  },
  {
    name: 'PERUÍBE',
    initials: 'SP',
    code: '3537602',
  },
  {
    name: 'PESCADOR',
    initials: 'MG',
    code: '3150000',
  },
  {
    name: 'PESCARIA BRAVA',
    initials: 'SC',
    code: '4212650',
  },
  {
    name: 'PESQUEIRA',
    initials: 'PE',
    code: '2610905',
  },
  {
    name: 'PETROLÂNDIA',
    initials: 'PE',
    code: '2611002',
  },
  {
    name: 'PETROLÂNDIA',
    initials: 'SC',
    code: '4212700',
  },
  {
    name: 'PETROLINA',
    initials: 'PE',
    code: '2611101',
  },
  {
    name: 'PETROLINA DE GOIÁS',
    initials: 'GO',
    code: '5216809',
  },
  {
    name: 'PETRÓPOLIS',
    initials: 'RJ',
    code: '3303906',
  },
  {
    name: 'PIAÇABUÇU',
    initials: 'AL',
    code: '2706802',
  },
  {
    name: 'PIACATU',
    initials: 'SP',
    code: '3537701',
  },
  {
    name: 'PIANCÓ',
    initials: 'PB',
    code: '2511301',
  },
  {
    name: 'PIATÃ',
    initials: 'BA',
    code: '2924306',
  },
  {
    name: 'PIAU',
    initials: 'MG',
    code: '3150109',
  },
  {
    name: 'PICADA CAFÉ',
    initials: 'RS',
    code: '4314423',
  },
  {
    name: 'PIÇARRA',
    initials: 'PA',
    code: '1505635',
  },
  {
    name: 'PICOS',
    initials: 'PI',
    code: '2208007',
  },
  {
    name: 'PICUÍ',
    initials: 'PB',
    code: '2511400',
  },
  {
    name: 'PIEDADE',
    initials: 'SP',
    code: '3537800',
  },
  {
    name: 'PIEDADE DE CARATINGA',
    initials: 'MG',
    code: '3150158',
  },
  {
    name: 'PIEDADE DE PONTE NOVA',
    initials: 'MG',
    code: '3150208',
  },
  {
    name: 'PIEDADE DO RIO GRANDE',
    initials: 'MG',
    code: '3150307',
  },
  {
    name: 'PIEDADE DOS GERAIS',
    initials: 'MG',
    code: '3150406',
  },
  {
    name: 'PIÊN',
    initials: 'PR',
    code: '4119103',
  },
  {
    name: 'PILÃO ARCADO',
    initials: 'BA',
    code: '2924405',
  },
  {
    name: 'PILAR',
    initials: 'PB',
    code: '2511509',
  },
  {
    name: 'PILAR',
    initials: 'AL',
    code: '2706901',
  },
  {
    name: 'PILAR DE GOIÁS',
    initials: 'GO',
    code: '5216908',
  },
  {
    name: 'PILAR DO SUL',
    initials: 'SP',
    code: '3537909',
  },
  {
    name: 'PILÕES',
    initials: 'RN',
    code: '2410009',
  },
  {
    name: 'PILÕES',
    initials: 'PB',
    code: '2511608',
  },
  {
    name: 'PILÕEZINHOS',
    initials: 'PB',
    code: '2511707',
  },
  {
    name: 'PIMENTA',
    initials: 'MG',
    code: '3150505',
  },
  {
    name: 'PIMENTA BUENO',
    initials: 'RO',
    code: '1100189',
  },
  {
    name: 'PIMENTEIRAS',
    initials: 'PI',
    code: '2208106',
  },
  {
    name: 'PIMENTEIRAS DO OESTE',
    initials: 'RO',
    code: '1101468',
  },
  {
    name: 'PINDAÍ',
    initials: 'BA',
    code: '2924504',
  },
  {
    name: 'PINDAMONHANGABA',
    initials: 'SP',
    code: '3538006',
  },
  {
    name: 'PINDARÉ MIRIM',
    initials: 'MA',
    code: '2108504',
  },
  {
    name: 'PINDOBA',
    initials: 'AL',
    code: '2707008',
  },
  {
    name: 'PINDOBAÇU',
    initials: 'BA',
    code: '2924603',
  },
  {
    name: 'PINDORAMA',
    initials: 'SP',
    code: '3538105',
  },
  {
    name: 'PINDORAMA DO TOCANTINS',
    initials: 'TO',
    code: '1717008',
  },
  {
    name: 'PINDORETAMA',
    initials: 'CE',
    code: '2310852',
  },
  {
    name: 'PINGO-D\'ÁGUA',
    initials: 'MG',
    code: '3150539',
  },
  {
    name: 'PINHAIS',
    initials: 'PR',
    code: '4119152',
  },
  {
    name: 'PINHAL',
    initials: 'RS',
    code: '4314456',
  },
  {
    name: 'PINHALÃO',
    initials: 'PR',
    code: '4119202',
  },
  {
    name: 'PINHAL DA SERRA',
    initials: 'RS',
    code: '4314464',
  },
  {
    name: 'PINHAL DE SÃO BENTO',
    initials: 'PR',
    code: '4119251',
  },
  {
    name: 'PINHAL GRANDE',
    initials: 'RS',
    code: '4314472',
  },
  {
    name: 'PINHALZINHO',
    initials: 'SP',
    code: '3538204',
  },
  {
    name: 'PINHALZINHO',
    initials: 'SC',
    code: '4212908',
  },
  {
    name: 'PINHÃO',
    initials: 'SE',
    code: '2805208',
  },
  {
    name: 'PINHÃO',
    initials: 'PR',
    code: '4119301',
  },
  {
    name: 'PINHEIRAL',
    initials: 'RJ',
    code: '3303955',
  },
  {
    name: 'PINHEIRINHO DO VALE',
    initials: 'RS',
    code: '4314498',
  },
  {
    name: 'PINHEIRO',
    initials: 'MA',
    code: '2108603',
  },
  {
    name: 'PINHEIRO MACHADO',
    initials: 'RS',
    code: '4314506',
  },
  {
    name: 'PINHEIRO PRETO',
    initials: 'SC',
    code: '4213005',
  },
  {
    name: 'PINHEIROS',
    initials: 'ES',
    code: '3204104',
  },
  {
    name: 'PINTADAS',
    initials: 'BA',
    code: '2924652',
  },
  {
    name: 'PINTO BANDEIRA',
    initials: 'RS',
    code: '4314548',
  },
  {
    name: 'PINTÓPOLIS',
    initials: 'MG',
    code: '3150570',
  },
  {
    name: 'PIO IX',
    initials: 'PI',
    code: '2208205',
  },
  {
    name: 'PIO XII',
    initials: 'MA',
    code: '2108702',
  },
  {
    name: 'PIQUEROBI',
    initials: 'SP',
    code: '3538303',
  },
  {
    name: 'PIQUET CARNEIRO',
    initials: 'CE',
    code: '2310902',
  },
  {
    name: 'PIQUETE',
    initials: 'SP',
    code: '3538501',
  },
  {
    name: 'PIRACAIA',
    initials: 'SP',
    code: '3538600',
  },
  {
    name: 'PIRACANJUBA',
    initials: 'GO',
    code: '5217104',
  },
  {
    name: 'PIRACEMA',
    initials: 'MG',
    code: '3150604',
  },
  {
    name: 'PIRACICABA',
    initials: 'SP',
    code: '3538709',
  },
  {
    name: 'PIRACURUCA',
    initials: 'PI',
    code: '2208304',
  },
  {
    name: 'PIRAÍ',
    initials: 'RJ',
    code: '3304003',
  },
  {
    name: 'PIRAÍ DO NORTE',
    initials: 'BA',
    code: '2924678',
  },
  {
    name: 'PIRAÍ DO SUL',
    initials: 'PR',
    code: '4119400',
  },
  {
    name: 'PIRAJU',
    initials: 'SP',
    code: '3538808',
  },
  {
    name: 'PIRAJUBA',
    initials: 'MG',
    code: '3150703',
  },
  {
    name: 'PIRAJUÍ',
    initials: 'SP',
    code: '3538907',
  },
  {
    name: 'PIRAMBU',
    initials: 'SE',
    code: '2805307',
  },
  {
    name: 'PIRANGA',
    initials: 'MG',
    code: '3150802',
  },
  {
    name: 'PIRANGI',
    initials: 'SP',
    code: '3539004',
  },
  {
    name: 'PIRANGUÇU',
    initials: 'MG',
    code: '3150901',
  },
  {
    name: 'PIRANGUINHO',
    initials: 'MG',
    code: '3151008',
  },
  {
    name: 'PIRANHAS',
    initials: 'AL',
    code: '2707107',
  },
  {
    name: 'PIRANHAS',
    initials: 'GO',
    code: '5217203',
  },
  {
    name: 'PIRAPEMAS',
    initials: 'MA',
    code: '2108801',
  },
  {
    name: 'PIRAPETINGA',
    initials: 'MG',
    code: '3151107',
  },
  {
    name: 'PIRAPÓ',
    initials: 'RS',
    code: '4314555',
  },
  {
    name: 'PIRAPORA',
    initials: 'MG',
    code: '3151206',
  },
  {
    name: 'PIRAPORA DO BOM JESUS',
    initials: 'SP',
    code: '3539103',
  },
  {
    name: 'PIRAPOZINHO',
    initials: 'SP',
    code: '3539202',
  },
  {
    name: 'PIRAQUARA',
    initials: 'PR',
    code: '4119509',
  },
  {
    name: 'PIRAQUÊ',
    initials: 'TO',
    code: '1717206',
  },
  {
    name: 'PIRASSUNUNGA',
    initials: 'SP',
    code: '3539301',
  },
  {
    name: 'PIRATINI',
    initials: 'RS',
    code: '4314605',
  },
  {
    name: 'PIRATININGA',
    initials: 'SP',
    code: '3539400',
  },
  {
    name: 'PIRATUBA',
    initials: 'SC',
    code: '4213104',
  },
  {
    name: 'PIRAÚBA',
    initials: 'MG',
    code: '3151305',
  },
  {
    name: 'PIRENÓPOLIS',
    initials: 'GO',
    code: '5217302',
  },
  {
    name: 'PIRES DO RIO',
    initials: 'GO',
    code: '5217401',
  },
  {
    name: 'PIRES FERREIRA',
    initials: 'CE',
    code: '2310951',
  },
  {
    name: 'PIRIPÁ',
    initials: 'BA',
    code: '2924702',
  },
  {
    name: 'PIRIPIRI',
    initials: 'PI',
    code: '2208403',
  },
  {
    name: 'PIRITIBA',
    initials: 'BA',
    code: '2924801',
  },
  {
    name: 'PIRPIRITUBA',
    initials: 'PB',
    code: '2511806',
  },
  {
    name: 'PITANGA',
    initials: 'PR',
    code: '4119608',
  },
  {
    name: 'PITANGUEIRAS',
    initials: 'SP',
    code: '3539509',
  },
  {
    name: 'PITANGUEIRAS',
    initials: 'PR',
    code: '4119657',
  },
  {
    name: 'PITANGUI',
    initials: 'MG',
    code: '3151404',
  },
  {
    name: 'PITIMBU',
    initials: 'PB',
    code: '2511905',
  },
  {
    name: 'PIUM',
    initials: 'TO',
    code: '1717503',
  },
  {
    name: 'PIÚMA',
    initials: 'ES',
    code: '3204203',
  },
  {
    name: 'PIUMHI',
    initials: 'MG',
    code: '3151503',
  },
  {
    name: 'PLACAS',
    initials: 'PA',
    code: '1505650',
  },
  {
    name: 'PLÁCIDO DE CASTRO',
    initials: 'AC',
    code: '1200385',
  },
  {
    name: 'PLANALTINA',
    initials: 'GO',
    code: '5217609',
  },
  {
    name: 'PLANALTINA DO PARANÁ',
    initials: 'PR',
    code: '4119707',
  },
  {
    name: 'PLANALTINO',
    initials: 'BA',
    code: '2924900',
  },
  {
    name: 'PLANALTO',
    initials: 'BA',
    code: '2925006',
  },
  {
    name: 'PLANALTO',
    initials: 'RS',
    code: '4314704',
  },
  {
    name: 'PLANALTO',
    initials: 'SP',
    code: '3539608',
  },
  {
    name: 'PLANALTO',
    initials: 'PR',
    code: '4119806',
  },
  {
    name: 'PLANALTO ALEGRE',
    initials: 'SC',
    code: '4213153',
  },
  {
    name: 'PLANALTO DA SERRA',
    initials: 'MT',
    code: '5106455',
  },
  {
    name: 'PLANURA',
    initials: 'MG',
    code: '3151602',
  },
  {
    name: 'PLATINA',
    initials: 'SP',
    code: '3539707',
  },
  {
    name: 'POÁ',
    initials: 'SP',
    code: '3539806',
  },
  {
    name: 'POÇÃO',
    initials: 'PE',
    code: '2611200',
  },
  {
    name: 'POÇÃO DE PEDRAS',
    initials: 'MA',
    code: '2108900',
  },
  {
    name: 'POCINHOS',
    initials: 'PB',
    code: '2512002',
  },
  {
    name: 'POÇO BRANCO',
    initials: 'RN',
    code: '2410108',
  },
  {
    name: 'POÇO DANTAS',
    initials: 'PB',
    code: '2512036',
  },
  {
    name: 'POÇO DAS ANTAS',
    initials: 'RS',
    code: '4314753',
  },
  {
    name: 'POÇO DAS TRINCHEIRAS',
    initials: 'AL',
    code: '2707206',
  },
  {
    name: 'POÇO DE JOSÉ DE MOURA',
    initials: 'PB',
    code: '2512077',
  },
  {
    name: 'POÇÕES',
    initials: 'BA',
    code: '2925105',
  },
  {
    name: 'POÇO FUNDO',
    initials: 'MG',
    code: '3151701',
  },
  {
    name: 'POCONÉ',
    initials: 'MT',
    code: '5106505',
  },
  {
    name: 'POÇO REDONDO',
    initials: 'SE',
    code: '2805406',
  },
  {
    name: 'POÇOS DE CALDAS',
    initials: 'MG',
    code: '3151800',
  },
  {
    name: 'POÇO VERDE',
    initials: 'SE',
    code: '2805505',
  },
  {
    name: 'POCRANE',
    initials: 'MG',
    code: '3151909',
  },
  {
    name: 'POJUCA',
    initials: 'BA',
    code: '2925204',
  },
  {
    name: 'POLONI',
    initials: 'SP',
    code: '3539905',
  },
  {
    name: 'POMBAL',
    initials: 'PB',
    code: '2512101',
  },
  {
    name: 'POMBOS',
    initials: 'PE',
    code: '2611309',
  },
  {
    name: 'POMERODE',
    initials: 'SC',
    code: '4213203',
  },
  {
    name: 'POMPÉIA',
    initials: 'SP',
    code: '3540002',
  },
  {
    name: 'POMPÉU',
    initials: 'MG',
    code: '3152006',
  },
  {
    name: 'PONGAÍ',
    initials: 'SP',
    code: '3540101',
  },
  {
    name: 'PONTA DE PEDRAS',
    initials: 'PA',
    code: '1505700',
  },
  {
    name: 'PONTA GROSSA',
    initials: 'PR',
    code: '4119905',
  },
  {
    name: 'PONTAL',
    initials: 'SP',
    code: '3540200',
  },
  {
    name: 'PONTAL DO ARAGUAIA',
    initials: 'MT',
    code: '5106653',
  },
  {
    name: 'PONTAL DO PARANÁ',
    initials: 'PR',
    code: '4119954',
  },
  {
    name: 'PONTALINA',
    initials: 'GO',
    code: '5217708',
  },
  {
    name: 'PONTALINDA',
    initials: 'SP',
    code: '3540259',
  },
  {
    name: 'PONTÃO',
    initials: 'RS',
    code: '4314779',
  },
  {
    name: 'PONTA PORÃ',
    initials: 'MS',
    code: '5006606',
  },
  {
    name: 'PONTE ALTA',
    initials: 'SC',
    code: '4213302',
  },
  {
    name: 'PONTE ALTA DO BOM JESUS',
    initials: 'TO',
    code: '1717800',
  },
  {
    name: 'PONTE ALTA DO NORTE',
    initials: 'SC',
    code: '4213351',
  },
  {
    name: 'PONTE ALTA DO TOCANTINS',
    initials: 'TO',
    code: '1717909',
  },
  {
    name: 'PONTE BRANCA',
    initials: 'MT',
    code: '5106703',
  },
  {
    name: 'PONTE NOVA',
    initials: 'MG',
    code: '3152105',
  },
  {
    name: 'PONTE PRETA',
    initials: 'RS',
    code: '4314787',
  },
  {
    name: 'PONTES E LACERDA',
    initials: 'MT',
    code: '5106752',
  },
  {
    name: 'PONTE SERRADA',
    initials: 'SC',
    code: '4213401',
  },
  {
    name: 'PONTES GESTAL',
    initials: 'SP',
    code: '3540309',
  },
  {
    name: 'PONTO BELO',
    initials: 'ES',
    code: '3204252',
  },
  {
    name: 'PONTO CHIQUE',
    initials: 'MG',
    code: '3152131',
  },
  {
    name: 'PONTO DOS VOLANTES',
    initials: 'MG',
    code: '3152170',
  },
  {
    name: 'PONTO NOVO',
    initials: 'BA',
    code: '2925253',
  },
  {
    name: 'POPULINA',
    initials: 'SP',
    code: '3540408',
  },
  {
    name: 'PORANGA',
    initials: 'CE',
    code: '2311009',
  },
  {
    name: 'PORANGABA',
    initials: 'SP',
    code: '3540507',
  },
  {
    name: 'PORANGATU',
    initials: 'GO',
    code: '5218003',
  },
  {
    name: 'PORCIÚNCULA',
    initials: 'RJ',
    code: '3304102',
  },
  {
    name: 'PORECATU',
    initials: 'PR',
    code: '4120002',
  },
  {
    name: 'PORTALEGRE',
    initials: 'RN',
    code: '2410207',
  },
  {
    name: 'PORTÃO',
    initials: 'RS',
    code: '4314803',
  },
  {
    name: 'PORTEIRÃO',
    initials: 'GO',
    code: '5218052',
  },
  {
    name: 'PORTEIRAS',
    initials: 'CE',
    code: '2311108',
  },
  {
    name: 'PORTEIRINHA',
    initials: 'MG',
    code: '3152204',
  },
  {
    name: 'PORTEL',
    initials: 'PA',
    code: '1505809',
  },
  {
    name: 'PORTELÂNDIA',
    initials: 'GO',
    code: '5218102',
  },
  {
    name: 'PORTO',
    initials: 'PI',
    code: '2208502',
  },
  {
    name: 'PORTO ACRE',
    initials: 'AC',
    code: '1200807',
  },
  {
    name: 'PORTO ALEGRE',
    initials: 'RS',
    code: '4314902',
  },
  {
    name: 'PORTO ALEGRE DO NORTE',
    initials: 'MT',
    code: '5106778',
  },
  {
    name: 'PORTO ALEGRE DO PIAUÍ',
    initials: 'PI',
    code: '2208551',
  },
  {
    name: 'PORTO ALEGRE DO TOCANTINS',
    initials: 'TO',
    code: '1718006',
  },
  {
    name: 'PORTO AMAZONAS',
    initials: 'PR',
    code: '4120101',
  },
  {
    name: 'PORTO BARREIRO',
    initials: 'PR',
    code: '4120150',
  },
  {
    name: 'PORTO BELO',
    initials: 'SC',
    code: '4213500',
  },
  {
    name: 'PORTO CALVO',
    initials: 'AL',
    code: '2707305',
  },
  {
    name: 'PORTO DA FOLHA',
    initials: 'SE',
    code: '2805604',
  },
  {
    name: 'PORTO DE MOZ',
    initials: 'PA',
    code: '1505908',
  },
  {
    name: 'PORTO DE PEDRAS',
    initials: 'AL',
    code: '2707404',
  },
  {
    name: 'PORTO DO MANGUE',
    initials: 'RN',
    code: '2410256',
  },
  {
    name: 'PORTO DOS GAÚCHOS',
    initials: 'MT',
    code: '5106802',
  },
  {
    name: 'PORTO ESPERIDIÃO',
    initials: 'MT',
    code: '5106828',
  },
  {
    name: 'PORTO ESTRELA',
    initials: 'MT',
    code: '5106851',
  },
  {
    name: 'PORTO FELIZ',
    initials: 'SP',
    code: '3540606',
  },
  {
    name: 'PORTO FERREIRA',
    initials: 'SP',
    code: '3540705',
  },
  {
    name: 'PORTO FIRME',
    initials: 'MG',
    code: '3152303',
  },
  {
    name: 'PORTO FRANCO',
    initials: 'MA',
    code: '2109007',
  },
  {
    name: 'PORTO GRANDE',
    initials: 'AP',
    code: '1600535',
  },
  {
    name: 'PORTO LUCENA',
    initials: 'RS',
    code: '4315008',
  },
  {
    name: 'PORTO MAUÁ',
    initials: 'RS',
    code: '4315057',
  },
  {
    name: 'PORTO MURTINHO',
    initials: 'MS',
    code: '5006903',
  },
  {
    name: 'PORTO NACIONAL',
    initials: 'TO',
    code: '1718204',
  },
  {
    name: 'PORTO REAL',
    initials: 'RJ',
    code: '3304110',
  },
  {
    name: 'PORTO REAL DO COLÉGIO',
    initials: 'AL',
    code: '2707503',
  },
  {
    name: 'PORTO RICO',
    initials: 'PR',
    code: '4120200',
  },
  {
    name: 'PORTO RICO DO MARANHÃO',
    initials: 'MA',
    code: '2109056',
  },
  {
    name: 'PORTO SEGURO',
    initials: 'BA',
    code: '2925303',
  },
  {
    name: 'PORTO UNIÃO',
    initials: 'SC',
    code: '4213609',
  },
  {
    name: 'PORTO VELHO',
    initials: 'RO',
    code: '1100205',
  },
  {
    name: 'PORTO VERA CRUZ',
    initials: 'RS',
    code: '4315073',
  },
  {
    name: 'PORTO VITÓRIA',
    initials: 'PR',
    code: '4120309',
  },
  {
    name: 'PORTO WALTER',
    initials: 'AC',
    code: '1200393',
  },
  {
    name: 'PORTO XAVIER',
    initials: 'RS',
    code: '4315107',
  },
  {
    name: 'POSSE',
    initials: 'GO',
    code: '5218300',
  },
  {
    name: 'POTÉ',
    initials: 'MG',
    code: '3152402',
  },
  {
    name: 'POTENGI',
    initials: 'CE',
    code: '2311207',
  },
  {
    name: 'POTIM',
    initials: 'SP',
    code: '3540754',
  },
  {
    name: 'POTIRAGUÁ',
    initials: 'BA',
    code: '2925402',
  },
  {
    name: 'POTIRENDABA',
    initials: 'SP',
    code: '3540804',
  },
  {
    name: 'POTIRETAMA',
    initials: 'CE',
    code: '2311231',
  },
  {
    name: 'POUSO ALEGRE',
    initials: 'MG',
    code: '3152501',
  },
  {
    name: 'POUSO ALTO',
    initials: 'MG',
    code: '3152600',
  },
  {
    name: 'POUSO NOVO',
    initials: 'RS',
    code: '4315131',
  },
  {
    name: 'POUSO REDONDO',
    initials: 'SC',
    code: '4213708',
  },
  {
    name: 'POXORÉU',
    initials: 'MT',
    code: '5107008',
  },
  {
    name: 'PRACINHA',
    initials: 'SP',
    code: '3540853',
  },
  {
    name: 'PRACUÚBA',
    initials: 'AP',
    code: '1600550',
  },
  {
    name: 'PRADO',
    initials: 'BA',
    code: '2925501',
  },
  {
    name: 'PRADO FERREIRA',
    initials: 'PR',
    code: '4120333',
  },
  {
    name: 'PRADÓPOLIS',
    initials: 'SP',
    code: '3540903',
  },
  {
    name: 'PRADOS',
    initials: 'MG',
    code: '3152709',
  },
  {
    name: 'PRAIA GRANDE',
    initials: 'SP',
    code: '3541000',
  },
  {
    name: 'PRAIA GRANDE',
    initials: 'SC',
    code: '4213807',
  },
  {
    name: 'PRAIA NORTE',
    initials: 'TO',
    code: '1718303',
  },
  {
    name: 'PRAINHA',
    initials: 'PA',
    code: '1506005',
  },
  {
    name: 'PRANCHITA',
    initials: 'PR',
    code: '4120358',
  },
  {
    name: 'PRATA',
    initials: 'PB',
    code: '2512200',
  },
  {
    name: 'PRATA',
    initials: 'MG',
    code: '3152808',
  },
  {
    name: 'PRATA DO PIAUÍ',
    initials: 'PI',
    code: '2208601',
  },
  {
    name: 'PRATÂNIA',
    initials: 'SP',
    code: '3541059',
  },
  {
    name: 'PRATÁPOLIS',
    initials: 'MG',
    code: '3152907',
  },
  {
    name: 'PRATINHA',
    initials: 'MG',
    code: '3153004',
  },
  {
    name: 'PRESIDENTE ALVES',
    initials: 'SP',
    code: '3541109',
  },
  {
    name: 'PRESIDENTE BERNARDES',
    initials: 'MG',
    code: '3153103',
  },
  {
    name: 'PRESIDENTE BERNARDES',
    initials: 'SP',
    code: '3541208',
  },
  {
    name: 'PRESIDENTE CASTELLO BRANCO',
    initials: 'SC',
    code: '4213906',
  },
  {
    name: 'PRESIDENTE CASTELO BRANCO',
    initials: 'PR',
    code: '4120408',
  },
  {
    name: 'PRESIDENTE DUTRA',
    initials: 'MA',
    code: '2109106',
  },
  {
    name: 'PRESIDENTE DUTRA',
    initials: 'BA',
    code: '2925600',
  },
  {
    name: 'PRESIDENTE EPITÁCIO',
    initials: 'SP',
    code: '3541307',
  },
  {
    name: 'PRESIDENTE FIGUEIREDO',
    initials: 'AM',
    code: '1303536',
  },
  {
    name: 'PRESIDENTE GETÚLIO',
    initials: 'SC',
    code: '4214003',
  },
  {
    name: 'PRESIDENTE JÂNIO QUADROS',
    initials: 'BA',
    code: '2925709',
  },
  {
    name: 'PRESIDENTE JUSCELINO',
    initials: 'MG',
    code: '3153202',
  },
  {
    name: 'PRESIDENTE JUSCELINO',
    initials: 'MA',
    code: '2109205',
  },
  {
    name: 'PRESIDENTE KENNEDY',
    initials: 'ES',
    code: '3204302',
  },
  {
    name: 'PRESIDENTE KENNEDY',
    initials: 'TO',
    code: '1718402',
  },
  {
    name: 'PRESIDENTE KUBITSCHEK',
    initials: 'MG',
    code: '3153301',
  },
  {
    name: 'PRESIDENTE LUCENA',
    initials: 'RS',
    code: '4315149',
  },
  {
    name: 'PRESIDENTE MÉDICI',
    initials: 'MA',
    code: '2109239',
  },
  {
    name: 'PRESIDENTE MÉDICI',
    initials: 'RO',
    code: '1100254',
  },
  {
    name: 'PRESIDENTE NEREU',
    initials: 'SC',
    code: '4214102',
  },
  {
    name: 'PRESIDENTE OLEGÁRIO',
    initials: 'MG',
    code: '3153400',
  },
  {
    name: 'PRESIDENTE PRUDENTE',
    initials: 'SP',
    code: '3541406',
  },
  {
    name: 'PRESIDENTE SARNEY',
    initials: 'MA',
    code: '2109270',
  },
  {
    name: 'PRESIDENTE TANCREDO NEVES',
    initials: 'BA',
    code: '2925758',
  },
  {
    name: 'PRESIDENTE VARGAS',
    initials: 'MA',
    code: '2109304',
  },
  {
    name: 'PRESIDENTE VENCESLAU',
    initials: 'SP',
    code: '3541505',
  },
  {
    name: 'PRIMAVERA',
    initials: 'PA',
    code: '1506104',
  },
  {
    name: 'PRIMAVERA',
    initials: 'PE',
    code: '2611408',
  },
  {
    name: 'PRIMAVERA DE RONDÔNIA',
    initials: 'RO',
    code: '1101476',
  },
  {
    name: 'PRIMAVERA DO LESTE',
    initials: 'MT',
    code: '5107040',
  },
  {
    name: 'PRIMEIRA CRUZ',
    initials: 'MA',
    code: '2109403',
  },
  {
    name: 'PRIMEIRO DE MAIO',
    initials: 'PR',
    code: '4120507',
  },
  {
    name: 'PRINCESA',
    initials: 'SC',
    code: '4214151',
  },
  {
    name: 'PRINCESA ISABEL',
    initials: 'PB',
    code: '2512309',
  },
  {
    name: 'PROFESSOR JAMIL',
    initials: 'GO',
    code: '5218391',
  },
  {
    name: 'PROGRESSO',
    initials: 'RS',
    code: '4315156',
  },
  {
    name: 'PROMISSÃO',
    initials: 'SP',
    code: '3541604',
  },
  {
    name: 'PROPRIÁ',
    initials: 'SE',
    code: '2805703',
  },
  {
    name: 'PROTÁSIO ALVES',
    initials: 'RS',
    code: '4315172',
  },
  {
    name: 'PRUDENTE DE MORAIS',
    initials: 'MG',
    code: '3153608',
  },
  {
    name: 'PRUDENTÓPOLIS',
    initials: 'PR',
    code: '4120606',
  },
  {
    name: 'PUGMIL',
    initials: 'TO',
    code: '1718451',
  },
  {
    name: 'PUREZA',
    initials: 'RN',
    code: '2410405',
  },
  {
    name: 'PUTINGA',
    initials: 'RS',
    code: '4315206',
  },
  {
    name: 'PUXINANÃ',
    initials: 'PB',
    code: '2512408',
  },
  {
    name: 'QUADRA',
    initials: 'SP',
    code: '3541653',
  },
  {
    name: 'QUARAÍ',
    initials: 'RS',
    code: '4315305',
  },
  {
    name: 'QUARTEL GERAL',
    initials: 'MG',
    code: '3153707',
  },
  {
    name: 'QUARTO CENTENÁRIO',
    initials: 'PR',
    code: '4120655',
  },
  {
    name: 'QUATÁ',
    initials: 'SP',
    code: '3541703',
  },
  {
    name: 'QUATIGUÁ',
    initials: 'PR',
    code: '4120705',
  },
  {
    name: 'QUATIPURU',
    initials: 'PA',
    code: '1506112',
  },
  {
    name: 'QUATIS',
    initials: 'RJ',
    code: '3304128',
  },
  {
    name: 'QUATRO BARRAS',
    initials: 'PR',
    code: '4120804',
  },
  {
    name: 'QUATRO IRMÃOS',
    initials: 'RS',
    code: '4315313',
  },
  {
    name: 'QUATRO PONTES',
    initials: 'PR',
    code: '4120853',
  },
  {
    name: 'QUEBRANGULO',
    initials: 'AL',
    code: '2707602',
  },
  {
    name: 'QUEDAS DO IGUAÇU',
    initials: 'PR',
    code: '4120903',
  },
  {
    name: 'QUEIMADA NOVA',
    initials: 'PI',
    code: '2208650',
  },
  {
    name: 'QUEIMADAS',
    initials: 'BA',
    code: '2925808',
  },
  {
    name: 'QUEIMADAS',
    initials: 'PB',
    code: '2512507',
  },
  {
    name: 'QUEIMADOS',
    initials: 'RJ',
    code: '3304144',
  },
  {
    name: 'QUEIROZ',
    initials: 'SP',
    code: '3541802',
  },
  {
    name: 'QUELUZ',
    initials: 'SP',
    code: '3541901',
  },
  {
    name: 'QUELUZITO',
    initials: 'MG',
    code: '3153806',
  },
  {
    name: 'QUERÊNCIA',
    initials: 'MT',
    code: '5107065',
  },
  {
    name: 'QUERÊNCIA DO NORTE',
    initials: 'PR',
    code: '4121000',
  },
  {
    name: 'QUEVEDOS',
    initials: 'RS',
    code: '4315321',
  },
  {
    name: 'QUIJINGUE',
    initials: 'BA',
    code: '2925907',
  },
  {
    name: 'QUILOMBO',
    initials: 'SC',
    code: '4214201',
  },
  {
    name: 'QUINTA DO SOL',
    initials: 'PR',
    code: '4121109',
  },
  {
    name: 'QUINTANA',
    initials: 'SP',
    code: '3542008',
  },
  {
    name: 'QUINZE DE NOVEMBRO',
    initials: 'RS',
    code: '4315354',
  },
  {
    name: 'QUIPAPÁ',
    initials: 'PE',
    code: '2611507',
  },
  {
    name: 'QUIRINÓPOLIS',
    initials: 'GO',
    code: '5218508',
  },
  {
    name: 'QUISSAMÃ',
    initials: 'RJ',
    code: '3304151',
  },
  {
    name: 'QUITANDINHA',
    initials: 'PR',
    code: '4121208',
  },
  {
    name: 'QUITERIANÓPOLIS',
    initials: 'CE',
    code: '2311264',
  },
  {
    name: 'QUIXABÁ',
    initials: 'PB',
    code: '2512606',
  },
  {
    name: 'QUIXABÁ',
    initials: 'PE',
    code: '2611533',
  },
  {
    name: 'QUIXABEIRA',
    initials: 'BA',
    code: '2925931',
  },
  {
    name: 'QUIXADÁ',
    initials: 'CE',
    code: '2311306',
  },
  {
    name: 'QUIXELÔ',
    initials: 'CE',
    code: '2311355',
  },
  {
    name: 'QUIXERAMOBIM',
    initials: 'CE',
    code: '2311405',
  },
  {
    name: 'QUIXERÉ',
    initials: 'CE',
    code: '2311504',
  },
  {
    name: 'RAFAEL FERNANDES',
    initials: 'RN',
    code: '2410504',
  },
  {
    name: 'RAFAEL GODEIRO',
    initials: 'RN',
    code: '2410603',
  },
  {
    name: 'RAFAEL JAMBEIRO',
    initials: 'BA',
    code: '2925956',
  },
  {
    name: 'RAFARD',
    initials: 'SP',
    code: '3542107',
  },
  {
    name: 'RAMILÂNDIA',
    initials: 'PR',
    code: '4121257',
  },
  {
    name: 'RANCHARIA',
    initials: 'SP',
    code: '3542206',
  },
  {
    name: 'RANCHO ALEGRE',
    initials: 'PR',
    code: '4121307',
  },
  {
    name: 'RANCHO ALEGRE D\'OESTE',
    initials: 'PR',
    code: '4121356',
  },
  {
    name: 'RANCHO QUEIMADO',
    initials: 'SC',
    code: '4214300',
  },
  {
    name: 'RAPOSA',
    initials: 'MA',
    code: '2109452',
  },
  {
    name: 'RAPOSOS',
    initials: 'MG',
    code: '3153905',
  },
  {
    name: 'RAUL SOARES',
    initials: 'MG',
    code: '3154002',
  },
  {
    name: 'REALEZA',
    initials: 'PR',
    code: '4121406',
  },
  {
    name: 'REBOUÇAS',
    initials: 'PR',
    code: '4121505',
  },
  {
    name: 'RECIFE',
    initials: 'PE',
    code: '2611606',
  },
  {
    name: 'RECREIO',
    initials: 'MG',
    code: '3154101',
  },
  {
    name: 'RECURSOLÂNDIA',
    initials: 'TO',
    code: '1718501',
  },
  {
    name: 'REDENÇÃO',
    initials: 'PA',
    code: '1506138',
  },
  {
    name: 'REDENÇÃO',
    initials: 'CE',
    code: '2311603',
  },
  {
    name: 'REDENÇÃO DA SERRA',
    initials: 'SP',
    code: '3542305',
  },
  {
    name: 'REDENÇÃO DO GURGUÉIA',
    initials: 'PI',
    code: '2208700',
  },
  {
    name: 'REDENTORA',
    initials: 'RS',
    code: '4315404',
  },
  {
    name: 'REDUTO',
    initials: 'MG',
    code: '3154150',
  },
  {
    name: 'REGENERAÇÃO',
    initials: 'PI',
    code: '2208809',
  },
  {
    name: 'REGENTE FEIJÓ',
    initials: 'SP',
    code: '3542404',
  },
  {
    name: 'REGINÓPOLIS',
    initials: 'SP',
    code: '3542503',
  },
  {
    name: 'REGISTRO',
    initials: 'SP',
    code: '3542602',
  },
  {
    name: 'RELVADO',
    initials: 'RS',
    code: '4315453',
  },
  {
    name: 'REMANSO',
    initials: 'BA',
    code: '2926004',
  },
  {
    name: 'REMÍGIO',
    initials: 'PB',
    code: '2512705',
  },
  {
    name: 'RENASCENÇA',
    initials: 'PR',
    code: '4121604',
  },
  {
    name: 'RERIUTABA',
    initials: 'CE',
    code: '2311702',
  },
  {
    name: 'RESENDE',
    initials: 'RJ',
    code: '3304201',
  },
  {
    name: 'RESENDE COSTA',
    initials: 'MG',
    code: '3154200',
  },
  {
    name: 'RESERVA',
    initials: 'PR',
    code: '4121703',
  },
  {
    name: 'RESERVA DO CABAÇAL',
    initials: 'MT',
    code: '5107156',
  },
  {
    name: 'RESERVA DO IGUAÇU',
    initials: 'PR',
    code: '4121752',
  },
  {
    name: 'RESPLENDOR',
    initials: 'MG',
    code: '3154309',
  },
  {
    name: 'RESSAQUINHA',
    initials: 'MG',
    code: '3154408',
  },
  {
    name: 'RESTINGA',
    initials: 'SP',
    code: '3542701',
  },
  {
    name: 'RESTINGA SECA',
    initials: 'RS',
    code: '4315503',
  },
  {
    name: 'RETIROLÂNDIA',
    initials: 'BA',
    code: '2926103',
  },
  {
    name: 'RIACHÃO',
    initials: 'PB',
    code: '2512747',
  },
  {
    name: 'RIACHÃO',
    initials: 'MA',
    code: '2109502',
  },
  {
    name: 'RIACHÃO DAS NEVES',
    initials: 'BA',
    code: '2926202',
  },
  {
    name: 'RIACHÃO DO BACAMARTE',
    initials: 'PB',
    code: '2512754',
  },
  {
    name: 'RIACHÃO DO DANTAS',
    initials: 'SE',
    code: '2805802',
  },
  {
    name: 'RIACHÃO DO JACUÍPE',
    initials: 'BA',
    code: '2926301',
  },
  {
    name: 'RIACHÃO DO POÇO',
    initials: 'PB',
    code: '2512762',
  },
  {
    name: 'RIACHINHO',
    initials: 'TO',
    code: '1718550',
  },
  {
    name: 'RIACHINHO',
    initials: 'MG',
    code: '3154457',
  },
  {
    name: 'RIACHO DA CRUZ',
    initials: 'RN',
    code: '2410702',
  },
  {
    name: 'RIACHO DAS ALMAS',
    initials: 'PE',
    code: '2611705',
  },
  {
    name: 'RIACHO DE SANTANA',
    initials: 'RN',
    code: '2410801',
  },
  {
    name: 'RIACHO DE SANTANA',
    initials: 'BA',
    code: '2926400',
  },
  {
    name: 'RIACHO DE SANTO ANTÔNIO',
    initials: 'PB',
    code: '2512788',
  },
  {
    name: 'RIACHO DOS CAVALOS',
    initials: 'PB',
    code: '2512804',
  },
  {
    name: 'RIACHO DOS MACHADOS',
    initials: 'MG',
    code: '3154507',
  },
  {
    name: 'RIACHO FRIO',
    initials: 'PI',
    code: '2208858',
  },
  {
    name: 'RIACHUELO',
    initials: 'RN',
    code: '2410900',
  },
  {
    name: 'RIACHUELO',
    initials: 'SE',
    code: '2805901',
  },
  {
    name: 'RIALMA',
    initials: 'GO',
    code: '5218607',
  },
  {
    name: 'RIANÁPOLIS',
    initials: 'GO',
    code: '5218706',
  },
  {
    name: 'RIBAMAR FIQUENE',
    initials: 'MA',
    code: '2109551',
  },
  {
    name: 'RIBAS DO RIO PARDO',
    initials: 'MS',
    code: '5007109',
  },
  {
    name: 'RIBEIRA',
    initials: 'SP',
    code: '3542800',
  },
  {
    name: 'RIBEIRA DO AMPARO',
    initials: 'BA',
    code: '2926509',
  },
  {
    name: 'RIBEIRA DO PIAUÍ',
    initials: 'PI',
    code: '2208874',
  },
  {
    name: 'RIBEIRA DO POMBAL',
    initials: 'BA',
    code: '2926608',
  },
  {
    name: 'RIBEIRÃO',
    initials: 'PE',
    code: '2611804',
  },
  {
    name: 'RIBEIRÃO BONITO',
    initials: 'SP',
    code: '3542909',
  },
  {
    name: 'RIBEIRÃO BRANCO',
    initials: 'SP',
    code: '3543006',
  },
  {
    name: 'RIBEIRÃO CASCALHEIRA',
    initials: 'MT',
    code: '5107180',
  },
  {
    name: 'RIBEIRÃO CLARO',
    initials: 'PR',
    code: '4121802',
  },
  {
    name: 'RIBEIRÃO CORRENTE',
    initials: 'SP',
    code: '3543105',
  },
  {
    name: 'RIBEIRÃO DAS NEVES',
    initials: 'MG',
    code: '3154606',
  },
  {
    name: 'RIBEIRÃO DO LARGO',
    initials: 'BA',
    code: '2926657',
  },
  {
    name: 'RIBEIRÃO DO PINHAL',
    initials: 'PR',
    code: '4121901',
  },
  {
    name: 'RIBEIRÃO DOS ÍNDIOS',
    initials: 'SP',
    code: '3543238',
  },
  {
    name: 'RIBEIRÃO DO SUL',
    initials: 'SP',
    code: '3543204',
  },
  {
    name: 'RIBEIRÃO GRANDE',
    initials: 'SP',
    code: '3543253',
  },
  {
    name: 'RIBEIRÃO PIRES',
    initials: 'SP',
    code: '3543303',
  },
  {
    name: 'RIBEIRÃO PRETO',
    initials: 'SP',
    code: '3543402',
  },
  {
    name: 'RIBEIRÃO VERMELHO',
    initials: 'MG',
    code: '3154705',
  },
  {
    name: 'RIBEIRÃOZINHO',
    initials: 'MT',
    code: '5107198',
  },
  {
    name: 'RIBEIRO GONÇALVES',
    initials: 'PI',
    code: '2208908',
  },
  {
    name: 'RIBEIRÓPOLIS',
    initials: 'SE',
    code: '2806008',
  },
  {
    name: 'RIFAINA',
    initials: 'SP',
    code: '3543600',
  },
  {
    name: 'RINCÃO',
    initials: 'SP',
    code: '3543709',
  },
  {
    name: 'RINÓPOLIS',
    initials: 'SP',
    code: '3543808',
  },
  {
    name: 'RIO ACIMA',
    initials: 'MG',
    code: '3154804',
  },
  {
    name: 'RIO AZUL',
    initials: 'PR',
    code: '4122008',
  },
  {
    name: 'RIO BANANAL',
    initials: 'ES',
    code: '3204351',
  },
  {
    name: 'RIO BOM',
    initials: 'PR',
    code: '4122107',
  },
  {
    name: 'RIO BONITO',
    initials: 'RJ',
    code: '3304300',
  },
  {
    name: 'RIO BONITO DO IGUAÇU',
    initials: 'PR',
    code: '4122156',
  },
  {
    name: 'RIO BRANCO',
    initials: 'AC',
    code: '1200401',
  },
  {
    name: 'RIO BRANCO',
    initials: 'MT',
    code: '5107206',
  },
  {
    name: 'RIO BRANCO DO IVAÍ',
    initials: 'PR',
    code: '4122172',
  },
  {
    name: 'RIO BRANCO DO SUL',
    initials: 'PR',
    code: '4122206',
  },
  {
    name: 'RIO BRILHANTE',
    initials: 'MS',
    code: '5007208',
  },
  {
    name: 'RIO CASCA',
    initials: 'MG',
    code: '3154903',
  },
  {
    name: 'RIO CLARO',
    initials: 'RJ',
    code: '3304409',
  },
  {
    name: 'RIO CLARO',
    initials: 'SP',
    code: '3543907',
  },
  {
    name: 'RIO CRESPO',
    initials: 'RO',
    code: '1100262',
  },
  {
    name: 'RIO DA CONCEIÇÃO',
    initials: 'TO',
    code: '1718659',
  },
  {
    name: 'RIO DAS ANTAS',
    initials: 'SC',
    code: '4214409',
  },
  {
    name: 'RIO DAS FLORES',
    initials: 'RJ',
    code: '3304508',
  },
  {
    name: 'RIO DAS OSTRAS',
    initials: 'RJ',
    code: '3304524',
  },
  {
    name: 'RIO DAS PEDRAS',
    initials: 'SP',
    code: '3544004',
  },
  {
    name: 'RIO DE CONTAS',
    initials: 'BA',
    code: '2926707',
  },
  {
    name: 'RIO DE JANEIRO',
    initials: 'RJ',
    code: '3304557',
  },
  {
    name: 'RIO DO ANTÔNIO',
    initials: 'BA',
    code: '2926806',
  },
  {
    name: 'RIO DO CAMPO',
    initials: 'SC',
    code: '4214508',
  },
  {
    name: 'RIO DOCE',
    initials: 'MG',
    code: '3155009',
  },
  {
    name: 'RIO DO FOGO',
    initials: 'RN',
    code: '2408953',
  },
  {
    name: 'RIO DO OESTE',
    initials: 'SC',
    code: '4214607',
  },
  {
    name: 'RIO DO PIRES',
    initials: 'BA',
    code: '2926905',
  },
  {
    name: 'RIO DO PRADO',
    initials: 'MG',
    code: '3155108',
  },
  {
    name: 'RIO DOS BOIS',
    initials: 'TO',
    code: '1718709',
  },
  {
    name: 'RIO DOS CEDROS',
    initials: 'SC',
    code: '4214706',
  },
  {
    name: 'RIO DOS ÍNDIOS',
    initials: 'RS',
    code: '4315552',
  },
  {
    name: 'RIO DO SUL',
    initials: 'SC',
    code: '4214805',
  },
  {
    name: 'RIO ESPERA',
    initials: 'MG',
    code: '3155207',
  },
  {
    name: 'RIO FORMOSO',
    initials: 'PE',
    code: '2611903',
  },
  {
    name: 'RIO FORTUNA',
    initials: 'SC',
    code: '4214904',
  },
  {
    name: 'RIO GRANDE',
    initials: 'RS',
    code: '4315602',
  },
  {
    name: 'RIO GRANDE DA SERRA',
    initials: 'SP',
    code: '3544103',
  },
  {
    name: 'RIO GRANDE DO PIAUÍ',
    initials: 'PI',
    code: '2209005',
  },
  {
    name: 'RIOLÂNDIA',
    initials: 'SP',
    code: '3544202',
  },
  {
    name: 'RIO LARGO',
    initials: 'AL',
    code: '2707701',
  },
  {
    name: 'RIO MANSO',
    initials: 'MG',
    code: '3155306',
  },
  {
    name: 'RIO MARIA',
    initials: 'PA',
    code: '1506161',
  },
  {
    name: 'RIO NEGRINHO',
    initials: 'SC',
    code: '4215000',
  },
  {
    name: 'RIO NEGRO',
    initials: 'MS',
    code: '5007307',
  },
  {
    name: 'RIO NEGRO',
    initials: 'PR',
    code: '4122305',
  },
  {
    name: 'RIO NOVO',
    initials: 'MG',
    code: '3155405',
  },
  {
    name: 'RIO NOVO DO SUL',
    initials: 'ES',
    code: '3204401',
  },
  {
    name: 'RIO PARANAÍBA',
    initials: 'MG',
    code: '3155504',
  },
  {
    name: 'RIO PARDO',
    initials: 'RS',
    code: '4315701',
  },
  {
    name: 'RIO PARDO DE MINAS',
    initials: 'MG',
    code: '3155603',
  },
  {
    name: 'RIO PIRACICABA',
    initials: 'MG',
    code: '3155702',
  },
  {
    name: 'RIO POMBA',
    initials: 'MG',
    code: '3155801',
  },
  {
    name: 'RIO PRETO',
    initials: 'MG',
    code: '3155900',
  },
  {
    name: 'RIO PRETO DA EVA',
    initials: 'AM',
    code: '1303569',
  },
  {
    name: 'RIO QUENTE',
    initials: 'GO',
    code: '5218789',
  },
  {
    name: 'RIO REAL',
    initials: 'BA',
    code: '2927002',
  },
  {
    name: 'RIO RUFINO',
    initials: 'SC',
    code: '4215059',
  },
  {
    name: 'RIO SONO',
    initials: 'TO',
    code: '1718758',
  },
  {
    name: 'RIO TINTO',
    initials: 'PB',
    code: '2512903',
  },
  {
    name: 'RIO VERDE',
    initials: 'GO',
    code: '5218805',
  },
  {
    name: 'RIO VERDE DE MATO GROSSO',
    initials: 'MS',
    code: '5007406',
  },
  {
    name: 'RIO VERMELHO',
    initials: 'MG',
    code: '3156007',
  },
  {
    name: 'RIOZINHO',
    initials: 'RS',
    code: '4315750',
  },
  {
    name: 'RIQUEZA',
    initials: 'SC',
    code: '4215075',
  },
  {
    name: 'RITÁPOLIS',
    initials: 'MG',
    code: '3156106',
  },
  {
    name: 'RIVERSUL',
    initials: 'SP',
    code: '3543501',
  },
  {
    name: 'ROCA SALES',
    initials: 'RS',
    code: '4315800',
  },
  {
    name: 'ROCHEDO',
    initials: 'MS',
    code: '5007505',
  },
  {
    name: 'ROCHEDO DE MINAS',
    initials: 'MG',
    code: '3156205',
  },
  {
    name: 'RODEIO',
    initials: 'SC',
    code: '4215109',
  },
  {
    name: 'RODEIO BONITO',
    initials: 'RS',
    code: '4315909',
  },
  {
    name: 'RODEIRO',
    initials: 'MG',
    code: '3156304',
  },
  {
    name: 'RODELAS',
    initials: 'BA',
    code: '2927101',
  },
  {
    name: 'RODOLFO FERNANDES',
    initials: 'RN',
    code: '2411007',
  },
  {
    name: 'RODRIGUES ALVES',
    initials: 'AC',
    code: '1200427',
  },
  {
    name: 'ROLADOR',
    initials: 'RS',
    code: '4315958',
  },
  {
    name: 'ROLÂNDIA',
    initials: 'PR',
    code: '4122404',
  },
  {
    name: 'ROLANTE',
    initials: 'RS',
    code: '4316006',
  },
  {
    name: 'ROLIM DE MOURA',
    initials: 'RO',
    code: '1100288',
  },
  {
    name: 'ROMARIA',
    initials: 'MG',
    code: '3156403',
  },
  {
    name: 'ROMELÂNDIA',
    initials: 'SC',
    code: '4215208',
  },
  {
    name: 'RONCADOR',
    initials: 'PR',
    code: '4122503',
  },
  {
    name: 'RONDA ALTA',
    initials: 'RS',
    code: '4316105',
  },
  {
    name: 'RONDINHA',
    initials: 'RS',
    code: '4316204',
  },
  {
    name: 'RONDOLÂNDIA',
    initials: 'MT',
    code: '5107578',
  },
  {
    name: 'RONDON',
    initials: 'PR',
    code: '4122602',
  },
  {
    name: 'RONDON DO PARÁ',
    initials: 'PA',
    code: '1506187',
  },
  {
    name: 'RONDONÓPOLIS',
    initials: 'MT',
    code: '5107602',
  },
  {
    name: 'ROQUE GONZALES',
    initials: 'RS',
    code: '4316303',
  },
  {
    name: 'RORAINÓPOLIS',
    initials: 'RR',
    code: '1400472',
  },
  {
    name: 'ROSANA',
    initials: 'SP',
    code: '3544251',
  },
  {
    name: 'ROSÁRIO',
    initials: 'MA',
    code: '2109601',
  },
  {
    name: 'ROSÁRIO DA LIMEIRA',
    initials: 'MG',
    code: '3156452',
  },
  {
    name: 'ROSÁRIO DO CATETE',
    initials: 'SE',
    code: '2806107',
  },
  {
    name: 'ROSÁRIO DO IVAÍ',
    initials: 'PR',
    code: '4122651',
  },
  {
    name: 'ROSÁRIO DO SUL',
    initials: 'RS',
    code: '4316402',
  },
  {
    name: 'ROSÁRIO OESTE',
    initials: 'MT',
    code: '5107701',
  },
  {
    name: 'ROSEIRA',
    initials: 'SP',
    code: '3544301',
  },
  {
    name: 'ROTEIRO',
    initials: 'AL',
    code: '2707800',
  },
  {
    name: 'RUBELITA',
    initials: 'MG',
    code: '3156502',
  },
  {
    name: 'RUBIÁCEA',
    initials: 'SP',
    code: '3544400',
  },
  {
    name: 'RUBIATABA',
    initials: 'GO',
    code: '5218904',
  },
  {
    name: 'RUBIM',
    initials: 'MG',
    code: '3156601',
  },
  {
    name: 'RUBINÉIA',
    initials: 'SP',
    code: '3544509',
  },
  {
    name: 'RURÓPOLIS',
    initials: 'PA',
    code: '1506195',
  },
  {
    name: 'RUSSAS',
    initials: 'CE',
    code: '2311801',
  },
  {
    name: 'RUY BARBOSA',
    initials: 'RN',
    code: '2411106',
  },
  {
    name: 'RUY BARBOSA',
    initials: 'BA',
    code: '2927200',
  },
  {
    name: 'SABARÁ',
    initials: 'MG',
    code: '3156700',
  },
  {
    name: 'SABÁUDIA',
    initials: 'PR',
    code: '4122701',
  },
  {
    name: 'SABINO',
    initials: 'SP',
    code: '3544608',
  },
  {
    name: 'SABINÓPOLIS',
    initials: 'MG',
    code: '3156809',
  },
  {
    name: 'SABOEIRO',
    initials: 'CE',
    code: '2311900',
  },
  {
    name: 'SACRAMENTO',
    initials: 'MG',
    code: '3156908',
  },
  {
    name: 'SAGRADA FAMÍLIA',
    initials: 'RS',
    code: '4316428',
  },
  {
    name: 'SAGRES',
    initials: 'SP',
    code: '3544707',
  },
  {
    name: 'SAIRÉ',
    initials: 'PE',
    code: '2612000',
  },
  {
    name: 'SALDANHA MARINHO',
    initials: 'RS',
    code: '4316436',
  },
  {
    name: 'SALES',
    initials: 'SP',
    code: '3544806',
  },
  {
    name: 'SALES OLIVEIRA',
    initials: 'SP',
    code: '3544905',
  },
  {
    name: 'SALESÓPOLIS',
    initials: 'SP',
    code: '3545001',
  },
  {
    name: 'SALETE',
    initials: 'SC',
    code: '4215307',
  },
  {
    name: 'SALGADINHO',
    initials: 'PB',
    code: '2513000',
  },
  {
    name: 'SALGADINHO',
    initials: 'PE',
    code: '2612109',
  },
  {
    name: 'SALGADO',
    initials: 'SE',
    code: '2806206',
  },
  {
    name: 'SALGADO DE SÃO FÉLIX',
    initials: 'PB',
    code: '2513109',
  },
  {
    name: 'SALGADO FILHO',
    initials: 'PR',
    code: '4122800',
  },
  {
    name: 'SALGUEIRO',
    initials: 'PE',
    code: '2612208',
  },
  {
    name: 'SALINAS',
    initials: 'MG',
    code: '3157005',
  },
  {
    name: 'SALINAS DA MARGARIDA',
    initials: 'BA',
    code: '2927309',
  },
  {
    name: 'SALINÓPOLIS',
    initials: 'PA',
    code: '1506203',
  },
  {
    name: 'SALITRE',
    initials: 'CE',
    code: '2311959',
  },
  {
    name: 'SALMOURÃO',
    initials: 'SP',
    code: '3545100',
  },
  {
    name: 'SALOÁ',
    initials: 'PE',
    code: '2612307',
  },
  {
    name: 'SALTINHO',
    initials: 'SC',
    code: '4215356',
  },
  {
    name: 'SALTINHO',
    initials: 'SP',
    code: '3545159',
  },
  {
    name: 'SALTO',
    initials: 'SP',
    code: '3545209',
  },
  {
    name: 'SALTO DA DIVISA',
    initials: 'MG',
    code: '3157104',
  },
  {
    name: 'SALTO DE PIRAPORA',
    initials: 'SP',
    code: '3545308',
  },
  {
    name: 'SALTO DO CÉU',
    initials: 'MT',
    code: '5107750',
  },
  {
    name: 'SALTO DO ITARARÉ',
    initials: 'PR',
    code: '4122909',
  },
  {
    name: 'SALTO DO JACUÍ',
    initials: 'RS',
    code: '4316451',
  },
  {
    name: 'SALTO DO LONTRA',
    initials: 'PR',
    code: '4123006',
  },
  {
    name: 'SALTO GRANDE',
    initials: 'SP',
    code: '3545407',
  },
  {
    name: 'SALTO VELOSO',
    initials: 'SC',
    code: '4215406',
  },
  {
    name: 'SALVADOR',
    initials: 'BA',
    code: '2927408',
  },
  {
    name: 'SALVADOR DAS MISSÕES',
    initials: 'RS',
    code: '4316477',
  },
  {
    name: 'SALVADOR DO SUL',
    initials: 'RS',
    code: '4316501',
  },
  {
    name: 'SALVATERRA',
    initials: 'PA',
    code: '1506302',
  },
  {
    name: 'SAMBAÍBA',
    initials: 'MA',
    code: '2109700',
  },
  {
    name: 'SAMPAIO',
    initials: 'TO',
    code: '1718808',
  },
  {
    name: 'SANANDUVA',
    initials: 'RS',
    code: '4316600',
  },
  {
    name: 'SANCLERLÂNDIA',
    initials: 'GO',
    code: '5219001',
  },
  {
    name: 'SANDOLÂNDIA',
    initials: 'TO',
    code: '1718840',
  },
  {
    name: 'SANDOVALINA',
    initials: 'SP',
    code: '3545506',
  },
  {
    name: 'SANGÃO',
    initials: 'SC',
    code: '4215455',
  },
  {
    name: 'SANHARÓ',
    initials: 'PE',
    code: '2612406',
  },
  {
    name: 'SANTA ADÉLIA',
    initials: 'SP',
    code: '3545605',
  },
  {
    name: 'SANTA ALBERTINA',
    initials: 'SP',
    code: '3545704',
  },
  {
    name: 'SANTA AMÉLIA',
    initials: 'PR',
    code: '4123105',
  },
  {
    name: 'SANTA BÁRBARA',
    initials: 'MG',
    code: '3157203',
  },
  {
    name: 'SANTA BÁRBARA',
    initials: 'BA',
    code: '2927507',
  },
  {
    name: 'SANTA BÁRBARA DE GOIÁS',
    initials: 'GO',
    code: '5219100',
  },
  {
    name: 'SANTA BÁRBARA D\'OESTE',
    initials: 'SP',
    code: '3545803',
  },
  {
    name: 'SANTA BÁRBARA DO LESTE',
    initials: 'MG',
    code: '3157252',
  },
  {
    name: 'SANTA BÁRBARA DO MONTE VERDE',
    initials: 'MG',
    code: '3157278',
  },
  {
    name: 'SANTA BÁRBARA DO PARÁ',
    initials: 'PA',
    code: '1506351',
  },
  {
    name: 'SANTA BÁRBARA DO SUL',
    initials: 'RS',
    code: '4316709',
  },
  {
    name: 'SANTA BÁRBARA DO TUGÚRIO',
    initials: 'MG',
    code: '3157302',
  },
  {
    name: 'SANTA BRANCA',
    initials: 'SP',
    code: '3546009',
  },
  {
    name: 'SANTA BRÍGIDA',
    initials: 'BA',
    code: '2927606',
  },
  {
    name: 'SANTA CARMEM',
    initials: 'MT',
    code: '5107248',
  },
  {
    name: 'SANTA CECÍLIA',
    initials: 'PB',
    code: '2513158',
  },
  {
    name: 'SANTA CECÍLIA',
    initials: 'SC',
    code: '4215505',
  },
  {
    name: 'SANTA CECÍLIA DO PAVÃO',
    initials: 'PR',
    code: '4123204',
  },
  {
    name: 'SANTA CECÍLIA DO SUL',
    initials: 'RS',
    code: '4316733',
  },
  {
    name: 'SANTA CLARA D\'OESTE',
    initials: 'SP',
    code: '3546108',
  },
  {
    name: 'SANTA CLARA DO SUL',
    initials: 'RS',
    code: '4316758',
  },
  {
    name: 'SANTA CRUZ',
    initials: 'PB',
    code: '2513208',
  },
  {
    name: 'SANTA CRUZ',
    initials: 'PE',
    code: '2612455',
  },
  {
    name: 'SANTA CRUZ',
    initials: 'RN',
    code: '2411205',
  },
  {
    name: 'SANTA CRUZ CABRÁLIA',
    initials: 'BA',
    code: '2927705',
  },
  {
    name: 'SANTA CRUZ DA BAIXA VERDE',
    initials: 'PE',
    code: '2612471',
  },
  {
    name: 'SANTA CRUZ DA CONCEIÇÃO',
    initials: 'SP',
    code: '3546207',
  },
  {
    name: 'SANTA CRUZ DA ESPERANÇA',
    initials: 'SP',
    code: '3546256',
  },
  {
    name: 'SANTA CRUZ DAS PALMEIRAS',
    initials: 'SP',
    code: '3546306',
  },
  {
    name: 'SANTA CRUZ DA VITÓRIA',
    initials: 'BA',
    code: '2927804',
  },
  {
    name: 'SANTA CRUZ DE GOIÁS',
    initials: 'GO',
    code: '5219209',
  },
  {
    name: 'SANTA CRUZ DE MINAS',
    initials: 'MG',
    code: '3157336',
  },
  {
    name: 'SANTA CRUZ DE MONTE CASTELO',
    initials: 'PR',
    code: '4123303',
  },
  {
    name: 'SANTA CRUZ DE SALINAS',
    initials: 'MG',
    code: '3157377',
  },
  {
    name: 'SANTA CRUZ DO ARARI',
    initials: 'PA',
    code: '1506401',
  },
  {
    name: 'SANTA CRUZ DO CAPIBARIBE',
    initials: 'PE',
    code: '2612505',
  },
  {
    name: 'SANTA CRUZ DO ESCALVADO',
    initials: 'MG',
    code: '3157401',
  },
  {
    name: 'SANTA CRUZ DO PIAUÍ',
    initials: 'PI',
    code: '2209104',
  },
  {
    name: 'SANTA CRUZ DO RIO PARDO',
    initials: 'SP',
    code: '3546405',
  },
  {
    name: 'SANTA CRUZ DOS MILAGRES',
    initials: 'PI',
    code: '2209153',
  },
  {
    name: 'SANTA CRUZ DO SUL',
    initials: 'RS',
    code: '4316808',
  },
  {
    name: 'SANTA CRUZ DO XINGU',
    initials: 'MT',
    code: '5107743',
  },
  {
    name: 'SANTA EFIGÊNIA DE MINAS',
    initials: 'MG',
    code: '3157500',
  },
  {
    name: 'SANTA ERNESTINA',
    initials: 'SP',
    code: '3546504',
  },
  {
    name: 'SANTA FÉ',
    initials: 'PR',
    code: '4123402',
  },
  {
    name: 'SANTA FÉ DE GOIÁS',
    initials: 'GO',
    code: '5219258',
  },
  {
    name: 'SANTA FÉ DE MINAS',
    initials: 'MG',
    code: '3157609',
  },
  {
    name: 'SANTA FÉ DO ARAGUAIA',
    initials: 'TO',
    code: '1718865',
  },
  {
    name: 'SANTA FÉ DO SUL',
    initials: 'SP',
    code: '3546603',
  },
  {
    name: 'SANTA FILOMENA',
    initials: 'PE',
    code: '2612554',
  },
  {
    name: 'SANTA FILOMENA',
    initials: 'PI',
    code: '2209203',
  },
  {
    name: 'SANTA FILOMENA DO MARANHÃO',
    initials: 'MA',
    code: '2109759',
  },
  {
    name: 'SANTA GERTRUDES',
    initials: 'SP',
    code: '3546702',
  },
  {
    name: 'SANTA HELENA',
    initials: 'SC',
    code: '4215554',
  },
  {
    name: 'SANTA HELENA',
    initials: 'PB',
    code: '2513307',
  },
  {
    name: 'SANTA HELENA',
    initials: 'MA',
    code: '2109809',
  },
  {
    name: 'SANTA HELENA',
    initials: 'PR',
    code: '4123501',
  },
  {
    name: 'SANTA HELENA DE GOIÁS',
    initials: 'GO',
    code: '5219308',
  },
  {
    name: 'SANTA HELENA DE MINAS',
    initials: 'MG',
    code: '3157658',
  },
  {
    name: 'SANTA INÊS',
    initials: 'PB',
    code: '2513356',
  },
  {
    name: 'SANTA INÊS',
    initials: 'BA',
    code: '2927903',
  },
  {
    name: 'SANTA INÊS',
    initials: 'PR',
    code: '4123600',
  },
  {
    name: 'SANTA INÊS',
    initials: 'MA',
    code: '2109908',
  },
  {
    name: 'SANTA ISABEL',
    initials: 'GO',
    code: '5219357',
  },
  {
    name: 'SANTA ISABEL',
    initials: 'SP',
    code: '3546801',
  },
  {
    name: 'SANTA ISABEL DO IVAÍ',
    initials: 'PR',
    code: '4123709',
  },
  {
    name: 'SANTA ISABEL DO PARÁ',
    initials: 'PA',
    code: '1506500',
  },
  {
    name: 'SANTA ISABEL DO RIO NEGRO',
    initials: 'AM',
    code: '1303601',
  },
  {
    name: 'SANTA IZABEL DO OESTE',
    initials: 'PR',
    code: '4123808',
  },
  {
    name: 'SANTA JULIANA',
    initials: 'MG',
    code: '3157708',
  },
  {
    name: 'SANTA LEOPOLDINA',
    initials: 'ES',
    code: '3204500',
  },
  {
    name: 'SANTA LÚCIA',
    initials: 'SP',
    code: '3546900',
  },
  {
    name: 'SANTA LÚCIA',
    initials: 'PR',
    code: '4123824',
  },
  {
    name: 'SANTALUZ',
    initials: 'BA',
    code: '2928000',
  },
  {
    name: 'SANTA LUZ',
    initials: 'PI',
    code: '2209302',
  },
  {
    name: 'SANTA LUZIA',
    initials: 'PB',
    code: '2513406',
  },
  {
    name: 'SANTA LUZIA',
    initials: 'BA',
    code: '2928059',
  },
  {
    name: 'SANTA LUZIA',
    initials: 'MA',
    code: '2110005',
  },
  {
    name: 'SANTA LUZIA',
    initials: 'MG',
    code: '3157807',
  },
  {
    name: 'SANTA LUZIA D\'OESTE',
    initials: 'RO',
    code: '1100296',
  },
  {
    name: 'SANTA LUZIA DO ITANHY',
    initials: 'SE',
    code: '2806305',
  },
  {
    name: 'SANTA LUZIA DO NORTE',
    initials: 'AL',
    code: '2707909',
  },
  {
    name: 'SANTA LUZIA DO PARÁ',
    initials: 'PA',
    code: '1506559',
  },
  {
    name: 'SANTA LUZIA DO PARUÁ',
    initials: 'MA',
    code: '2110039',
  },
  {
    name: 'SANTA LUZIA DOS BARROS',
    initials: 'MG',
    code: '3148103',
  },
  {
    name: 'SANTA MARGARIDA',
    initials: 'MG',
    code: '3157906',
  },
  {
    name: 'SANTA MARGARIDA DO SUL',
    initials: 'RS',
    code: '4316972',
  },
  {
    name: 'SANTA MARIA',
    initials: 'RN',
    code: '2409332',
  },
  {
    name: 'SANTA MARIA',
    initials: 'RS',
    code: '4316907',
  },
  {
    name: 'SANTA MARIA DA BOA VISTA',
    initials: 'PE',
    code: '2612604',
  },
  {
    name: 'SANTA MARIA DAS BARREIRAS',
    initials: 'PA',
    code: '1506583',
  },
  {
    name: 'SANTA MARIA DA SERRA',
    initials: 'SP',
    code: '3547007',
  },
  {
    name: 'SANTA MARIA DA VITÓRIA',
    initials: 'BA',
    code: '2928109',
  },
  {
    name: 'SANTA MARIA DE ITABIRA',
    initials: 'MG',
    code: '3158003',
  },
  {
    name: 'SANTA MARIA DE JETIBÁ',
    initials: 'ES',
    code: '3204559',
  },
  {
    name: 'SANTA MARIA DO CAMBUCÁ',
    initials: 'PE',
    code: '2612703',
  },
  {
    name: 'SANTA MARIA DO HERVAL',
    initials: 'RS',
    code: '4316956',
  },
  {
    name: 'SANTA MARIA DO OESTE',
    initials: 'PR',
    code: '4123857',
  },
  {
    name: 'SANTA MARIA DO PARÁ',
    initials: 'PA',
    code: '1506609',
  },
  {
    name: 'SANTA MARIA DO SALTO',
    initials: 'MG',
    code: '3158102',
  },
  {
    name: 'SANTA MARIA DO SUAÇUÍ',
    initials: 'MG',
    code: '3158201',
  },
  {
    name: 'SANTA MARIA DO TOCANTINS',
    initials: 'TO',
    code: '1718881',
  },
  {
    name: 'SANTA MARIA MADALENA',
    initials: 'RJ',
    code: '3304607',
  },
  {
    name: 'SANTA MARIANA',
    initials: 'PR',
    code: '4123907',
  },
  {
    name: 'SANTA MERCEDES',
    initials: 'SP',
    code: '3547106',
  },
  {
    name: 'SANTA MÔNICA',
    initials: 'PR',
    code: '4123956',
  },
  {
    name: 'SANTANA',
    initials: 'AP',
    code: '1600600',
  },
  {
    name: 'SANTANA',
    initials: 'BA',
    code: '2928208',
  },
  {
    name: 'SANTANA DA BOA VISTA',
    initials: 'RS',
    code: '4317004',
  },
  {
    name: 'SANTANA DA PONTE PENSA',
    initials: 'SP',
    code: '3547205',
  },
  {
    name: 'SANTANA DA VARGEM',
    initials: 'MG',
    code: '3158300',
  },
  {
    name: 'SANTANA DE CATAGUASES',
    initials: 'MG',
    code: '3158409',
  },
  {
    name: 'SANTANA DE MANGUEIRA',
    initials: 'PB',
    code: '2513505',
  },
  {
    name: 'SANTANA DE PARNAÍBA',
    initials: 'SP',
    code: '3547304',
  },
  {
    name: 'SANTANA DE PIRAPAMA',
    initials: 'MG',
    code: '3158508',
  },
  {
    name: 'SANTANA DO ACARAÚ',
    initials: 'CE',
    code: '2312007',
  },
  {
    name: 'SANTANA DO ARAGUAIA',
    initials: 'PA',
    code: '1506708',
  },
  {
    name: 'SANTANA DO CARIRI',
    initials: 'CE',
    code: '2312106',
  },
  {
    name: 'SANTANA DO DESERTO',
    initials: 'MG',
    code: '3158607',
  },
  {
    name: 'SANTANA DO GARAMBÉU',
    initials: 'MG',
    code: '3158706',
  },
  {
    name: 'SANTANA DO IPANEMA',
    initials: 'AL',
    code: '2708006',
  },
  {
    name: 'SANTANA DO ITARARÉ',
    initials: 'PR',
    code: '4124004',
  },
  {
    name: 'SANTANA DO JACARÉ',
    initials: 'MG',
    code: '3158805',
  },
  {
    name: 'SANTANA DO LIVRAMENTO',
    initials: 'RS',
    code: '4317103',
  },
  {
    name: 'SANTANA DO MANHUAÇU',
    initials: 'MG',
    code: '3158904',
  },
  {
    name: 'SANTANA DO MARANHÃO',
    initials: 'MA',
    code: '2110237',
  },
  {
    name: 'SANTANA DO MATOS',
    initials: 'RN',
    code: '2411403',
  },
  {
    name: 'SANTANA DO MUNDAÚ',
    initials: 'AL',
    code: '2708105',
  },
  {
    name: 'SANTANA DO PARAÍSO',
    initials: 'MG',
    code: '3158953',
  },
  {
    name: 'SANTANA DO PIAUÍ',
    initials: 'PI',
    code: '2209351',
  },
  {
    name: 'SANTANA DO RIACHO',
    initials: 'MG',
    code: '3159001',
  },
  {
    name: 'SANTANA DO SÃO FRANCISCO',
    initials: 'SE',
    code: '2806404',
  },
  {
    name: 'SANTANA DO SERIDÓ',
    initials: 'RN',
    code: '2411429',
  },
  {
    name: 'SANTANA DOS GARROTES',
    initials: 'PB',
    code: '2513604',
  },
  {
    name: 'SANTANA DOS MONTES',
    initials: 'MG',
    code: '3159100',
  },
  {
    name: 'SANTANÓPOLIS',
    initials: 'BA',
    code: '2928307',
  },
  {
    name: 'SANTA QUITÉRIA',
    initials: 'CE',
    code: '2312205',
  },
  {
    name: 'SANTA QUITÉRIA DO MARANHÃO',
    initials: 'MA',
    code: '2110104',
  },
  {
    name: 'SANTARÉM',
    initials: 'PA',
    code: '1506807',
  },
  {
    name: 'SANTARÉM NOVO',
    initials: 'PA',
    code: '1506906',
  },
  {
    name: 'SANTA RITA',
    initials: 'MA',
    code: '2110203',
  },
  {
    name: 'SANTA RITA',
    initials: 'PB',
    code: '2513703',
  },
  {
    name: 'SANTA RITA DE CALDAS',
    initials: 'MG',
    code: '3159209',
  },
  {
    name: 'SANTA RITA DE CÁSSIA',
    initials: 'BA',
    code: '2928406',
  },
  {
    name: 'SANTA RITA DE JACUTINGA',
    initials: 'MG',
    code: '3159308',
  },
  {
    name: 'SANTA RITA DE MINAS',
    initials: 'MG',
    code: '3159357',
  },
  {
    name: 'SANTA RITA DO ARAGUAIA',
    initials: 'GO',
    code: '5219407',
  },
  {
    name: 'SANTA RITA D\'OESTE',
    initials: 'SP',
    code: '3547403',
  },
  {
    name: 'SANTA RITA DO IBITIPOCA',
    initials: 'MG',
    code: '3159407',
  },
  {
    name: 'SANTA RITA DO ITUETO',
    initials: 'MG',
    code: '3159506',
  },
  {
    name: 'SANTA RITA DO NOVO DESTINO',
    initials: 'GO',
    code: '5219456',
  },
  {
    name: 'SANTA RITA DO PARDO',
    initials: 'MS',
    code: '5007554',
  },
  {
    name: 'SANTA RITA DO PASSA QUATRO',
    initials: 'SP',
    code: '3547502',
  },
  {
    name: 'SANTA RITA DO SAPUCAÍ',
    initials: 'MG',
    code: '3159605',
  },
  {
    name: 'SANTA RITA DO TOCANTINS',
    initials: 'TO',
    code: '1718899',
  },
  {
    name: 'SANTA RITA DO TRIVELATO',
    initials: 'MT',
    code: '5107768',
  },
  {
    name: 'SANTA ROSA',
    initials: 'RS',
    code: '4317202',
  },
  {
    name: 'SANTA ROSA DA SERRA',
    initials: 'MG',
    code: '3159704',
  },
  {
    name: 'SANTA ROSA DE GOIÁS',
    initials: 'GO',
    code: '5219506',
  },
  {
    name: 'SANTA ROSA DE LIMA',
    initials: 'SC',
    code: '4215604',
  },
  {
    name: 'SANTA ROSA DE LIMA',
    initials: 'SE',
    code: '2806503',
  },
  {
    name: 'SANTA ROSA DE VITERBO',
    initials: 'SP',
    code: '3547601',
  },
  {
    name: 'SANTA ROSA DO PIAUÍ',
    initials: 'PI',
    code: '2209377',
  },
  {
    name: 'SANTA ROSA DO PURUS',
    initials: 'AC',
    code: '1200435',
  },
  {
    name: 'SANTA ROSA DO SUL',
    initials: 'SC',
    code: '4215653',
  },
  {
    name: 'SANTA ROSA DO TOCANTINS',
    initials: 'TO',
    code: '1718907',
  },
  {
    name: 'SANTA SALETE',
    initials: 'SP',
    code: '3547650',
  },
  {
    name: 'SANTA TERESA',
    initials: 'ES',
    code: '3204609',
  },
  {
    name: 'SANTA TERESINHA',
    initials: 'PB',
    code: '2513802',
  },
  {
    name: 'SANTA TEREZA',
    initials: 'RS',
    code: '4317251',
  },
  {
    name: 'SANTA TEREZA DE GOIÁS',
    initials: 'GO',
    code: '5219605',
  },
  {
    name: 'SANTA TEREZA DO OESTE',
    initials: 'PR',
    code: '4124020',
  },
  {
    name: 'SANTA TEREZA DO TOCANTINS',
    initials: 'TO',
    code: '1719004',
  },
  {
    name: 'SANTA TEREZINHA',
    initials: 'MT',
    code: '5107776',
  },
  {
    name: 'SANTA TEREZINHA',
    initials: 'SC',
    code: '4215679',
  },
  {
    name: 'SANTA TEREZINHA',
    initials: 'PE',
    code: '2612802',
  },
  {
    name: 'SANTA TEREZINHA',
    initials: 'BA',
    code: '2928505',
  },
  {
    name: 'SANTA TEREZINHA DE GOIÁS',
    initials: 'GO',
    code: '5219704',
  },
  {
    name: 'SANTA TEREZINHA DE ITAIPU',
    initials: 'PR',
    code: '4124053',
  },
  {
    name: 'SANTA TEREZINHA DO PROGRESSO',
    initials: 'SC',
    code: '4215687',
  },
  {
    name: 'SANTA TEREZINHA DO TOCANTINS',
    initials: 'TO',
    code: '1720002',
  },
  {
    name: 'SANTA VITÓRIA',
    initials: 'MG',
    code: '3159803',
  },
  {
    name: 'SANTA VITÓRIA DO PALMAR',
    initials: 'RS',
    code: '4317301',
  },
  {
    name: 'SANTIAGO',
    initials: 'RS',
    code: '4317400',
  },
  {
    name: 'SANTIAGO DO SUL',
    initials: 'SC',
    code: '4215695',
  },
  {
    name: 'SANTO AFONSO',
    initials: 'MT',
    code: '5107263',
  },
  {
    name: 'SANTO AMARO',
    initials: 'BA',
    code: '2928604',
  },
  {
    name: 'SANTO AMARO',
    initials: 'MA',
    code: '2110278',
  },
  {
    name: 'SANTO AMARO DA IMPERATRIZ',
    initials: 'SC',
    code: '4215703',
  },
  {
    name: 'SANTO AMARO DAS BROTAS',
    initials: 'SE',
    code: '2806602',
  },
  {
    name: 'SANTO ANASTÁCIO',
    initials: 'SP',
    code: '3547700',
  },
  {
    name: 'SANTO ANDRÉ',
    initials: 'SP',
    code: '3547809',
  },
  {
    name: 'SANTO ANDRÉ',
    initials: 'PB',
    code: '2513851',
  },
  {
    name: 'SANTO ÂNGELO',
    initials: 'RS',
    code: '4317509',
  },
  {
    name: 'SANTO ANTÔNIO',
    initials: 'RN',
    code: '2411502',
  },
  {
    name: 'SANTO ANTÔNIO DA ALEGRIA',
    initials: 'SP',
    code: '3547908',
  },
  {
    name: 'SANTO ANTÔNIO DA BARRA',
    initials: 'GO',
    code: '5219712',
  },
  {
    name: 'SANTO ANTÔNIO DA PATRULHA',
    initials: 'RS',
    code: '4317608',
  },
  {
    name: 'SANTO ANTÔNIO DA PLATINA',
    initials: 'PR',
    code: '4124103',
  },
  {
    name: 'SANTO ANTÔNIO DAS MISSÕES',
    initials: 'RS',
    code: '4317707',
  },
  {
    name: 'SANTO ANTÔNIO DE GOIÁS',
    initials: 'GO',
    code: '5219738',
  },
  {
    name: 'SANTO ANTÔNIO DE JESUS',
    initials: 'BA',
    code: '2928703',
  },
  {
    name: 'SANTO ANTÔNIO DE LISBOA',
    initials: 'PI',
    code: '2209401',
  },
  {
    name: 'SANTO ANTÔNIO DE PÁDUA',
    initials: 'RJ',
    code: '3304706',
  },
  {
    name: 'SANTO ANTÔNIO DE POSSE',
    initials: 'SP',
    code: '3548005',
  },
  {
    name: 'SANTO ANTÔNIO DO AMPARO',
    initials: 'MG',
    code: '3159902',
  },
  {
    name: 'SANTO ANTÔNIO DO ARACANGUÁ',
    initials: 'SP',
    code: '3548054',
  },
  {
    name: 'SANTO ANTÔNIO DO AVENTUREIRO',
    initials: 'MG',
    code: '3160009',
  },
  {
    name: 'SANTO ANTÔNIO DO CAIUÁ',
    initials: 'PR',
    code: '4124202',
  },
  {
    name: 'SANTO ANTÔNIO DO DESCOBERTO',
    initials: 'GO',
    code: '5219753',
  },
  {
    name: 'SANTO ANTÔNIO DO GRAMA',
    initials: 'MG',
    code: '3160108',
  },
  {
    name: 'SANTO ANTÔNIO DO IÇÁ',
    initials: 'AM',
    code: '1303700',
  },
  {
    name: 'SANTO ANTÔNIO DO ITAMBÉ',
    initials: 'MG',
    code: '3160207',
  },
  {
    name: 'SANTO ANTÔNIO DO JACINTO',
    initials: 'MG',
    code: '3160306',
  },
  {
    name: 'SANTO ANTÔNIO DO JARDIM',
    initials: 'SP',
    code: '3548104',
  },
  {
    name: 'SANTO ANTÔNIO DO LESTE',
    initials: 'MT',
    code: '5107792',
  },
  {
    name: 'SANTO ANTÔNIO DO LEVERGER',
    initials: 'MT',
    code: '5107800',
  },
  {
    name: 'SANTO ANTÔNIO DO MONTE',
    initials: 'MG',
    code: '3160405',
  },
  {
    name: 'SANTO ANTÔNIO DO PALMA',
    initials: 'RS',
    code: '4317558',
  },
  {
    name: 'SANTO ANTÔNIO DO PARAÍSO',
    initials: 'PR',
    code: '4124301',
  },
  {
    name: 'SANTO ANTÔNIO DO PINHAL',
    initials: 'SP',
    code: '3548203',
  },
  {
    name: 'SANTO ANTÔNIO DO PLANALTO',
    initials: 'RS',
    code: '4317756',
  },
  {
    name: 'SANTO ANTÔNIO DO RETIRO',
    initials: 'MG',
    code: '3160454',
  },
  {
    name: 'SANTO ANTÔNIO DO RIO ABAIXO',
    initials: 'MG',
    code: '3160504',
  },
  {
    name: 'SANTO ANTÔNIO DOS LOPES',
    initials: 'MA',
    code: '2110302',
  },
  {
    name: 'SANTO ANTÔNIO DOS MILAGRES',
    initials: 'PI',
    code: '2209450',
  },
  {
    name: 'SANTO ANTÔNIO DO SUDOESTE',
    initials: 'PR',
    code: '4124400',
  },
  {
    name: 'SANTO ANTÔNIO DO TAUÁ',
    initials: 'PA',
    code: '1507003',
  },
  {
    name: 'SANTO AUGUSTO',
    initials: 'RS',
    code: '4317806',
  },
  {
    name: 'SANTO CRISTO',
    initials: 'RS',
    code: '4317905',
  },
  {
    name: 'SANTO ESTEVÃO',
    initials: 'BA',
    code: '2928802',
  },
  {
    name: 'SANTO EXPEDITO',
    initials: 'SP',
    code: '3548302',
  },
  {
    name: 'SANTO EXPEDITO DO SUL',
    initials: 'RS',
    code: '4317954',
  },
  {
    name: 'SANTO HIPÓLITO',
    initials: 'MG',
    code: '3160603',
  },
  {
    name: 'SANTO INÁCIO',
    initials: 'PR',
    code: '4124509',
  },
  {
    name: 'SANTO INÁCIO DO PIAUÍ',
    initials: 'PI',
    code: '2209500',
  },
  {
    name: 'SANTÓPOLIS DO AGUAPEÍ',
    initials: 'SP',
    code: '3548401',
  },
  {
    name: 'SANTOS',
    initials: 'SP',
    code: '3548500',
  },
  {
    name: 'SANTOS DUMONT',
    initials: 'MG',
    code: '3160702',
  },
  {
    name: 'SÃO BENEDITO',
    initials: 'MG',
    code: '3148103',
  },
  {
    name: 'SÃO BENEDITO',
    initials: 'CE',
    code: '2312304',
  },
  {
    name: 'SÃO BENEDITO DO RIO PRETO',
    initials: 'MA',
    code: '2110401',
  },
  {
    name: 'SÃO BENEDITO DO SUL',
    initials: 'PE',
    code: '2612901',
  },
  {
    name: 'SÃO BENTINHO',
    initials: 'PB',
    code: '2513927',
  },
  {
    name: 'SÃO BENTO',
    initials: 'MA',
    code: '2110500',
  },
  {
    name: 'SÃO BENTO',
    initials: 'PB',
    code: '2513901',
  },
  {
    name: 'SÃO BENTO ABADE',
    initials: 'MG',
    code: '3160801',
  },
  {
    name: 'SÃO BENTO DO NORTE',
    initials: 'RN',
    code: '2411601',
  },
  {
    name: 'SÃO BENTO DO SAPUCAÍ',
    initials: 'SP',
    code: '3548609',
  },
  {
    name: 'SÃO BENTO DO SUL',
    initials: 'SC',
    code: '4215802',
  },
  {
    name: 'SÃO BENTO DO TOCANTINS',
    initials: 'TO',
    code: '1720101',
  },
  {
    name: 'SÃO BENTO DO TRAIRI',
    initials: 'RN',
    code: '2411700',
  },
  {
    name: 'SÃO BENTO DO UNA',
    initials: 'PE',
    code: '2613008',
  },
  {
    name: 'SÃO BERNARDINO',
    initials: 'SC',
    code: '4215752',
  },
  {
    name: 'SÃO BERNARDO',
    initials: 'MA',
    code: '2110609',
  },
  {
    name: 'SÃO BERNARDO DO CAMPO',
    initials: 'SP',
    code: '3548708',
  },
  {
    name: 'SÃO BONIFÁCIO',
    initials: 'SC',
    code: '4215901',
  },
  {
    name: 'SÃO BORJA',
    initials: 'RS',
    code: '4318002',
  },
  {
    name: 'SÃO BRÁS',
    initials: 'AL',
    code: '2708204',
  },
  {
    name: 'SÃO BRÁS DO SUAÇUÍ',
    initials: 'MG',
    code: '3160900',
  },
  {
    name: 'SÃO BRAZ DO PIAUÍ',
    initials: 'PI',
    code: '2209559',
  },
  {
    name: 'SÃO CAETANO DE ODIVELAS',
    initials: 'PA',
    code: '1507102',
  },
  {
    name: 'SÃO CAETANO DO SUL',
    initials: 'SP',
    code: '3548807',
  },
  {
    name: 'SÃO CAITANO',
    initials: 'PE',
    code: '2613107',
  },
  {
    name: 'SÃO CARLOS',
    initials: 'SC',
    code: '4216008',
  },
  {
    name: 'SÃO CARLOS',
    initials: 'SP',
    code: '3548906',
  },
  {
    name: 'SÃO CARLOS DO IVAÍ',
    initials: 'PR',
    code: '4124608',
  },
  {
    name: 'SÃO CRISTÓVÃO',
    initials: 'SE',
    code: '2806701',
  },
  {
    name: 'SÃO CRISTÓVÃO DO SUL',
    initials: 'SC',
    code: '4216057',
  },
  {
    name: 'SÃO DESIDÉRIO',
    initials: 'BA',
    code: '2928901',
  },
  {
    name: 'SÃO DOMINGOS',
    initials: 'GO',
    code: '5219803',
  },
  {
    name: 'SÃO DOMINGOS',
    initials: 'SC',
    code: '4216107',
  },
  {
    name: 'SÃO DOMINGOS',
    initials: 'PB',
    code: '2513968',
  },
  {
    name: 'SÃO DOMINGOS',
    initials: 'SE',
    code: '2806800',
  },
  {
    name: 'SÃO DOMINGOS',
    initials: 'BA',
    code: '2928950',
  },
  {
    name: 'SÃO DOMINGOS DAS DORES',
    initials: 'MG',
    code: '3160959',
  },
  {
    name: 'SÃO DOMINGOS DO ARAGUAIA',
    initials: 'PA',
    code: '1507151',
  },
  {
    name: 'SÃO DOMINGOS DO AZEITÃO',
    initials: 'MA',
    code: '2110658',
  },
  {
    name: 'SÃO DOMINGOS DO CAPIM',
    initials: 'PA',
    code: '1507201',
  },
  {
    name: 'SÃO DOMINGOS DO CARIRI',
    initials: 'PB',
    code: '2513943',
  },
  {
    name: 'SÃO DOMINGOS DO MARANHÃO',
    initials: 'MA',
    code: '2110708',
  },
  {
    name: 'SÃO DOMINGOS DO NORTE',
    initials: 'ES',
    code: '3204658',
  },
  {
    name: 'SÃO DOMINGOS DO PRATA',
    initials: 'MG',
    code: '3161007',
  },
  {
    name: 'SÃO DOMINGOS DO SUL',
    initials: 'RS',
    code: '4318051',
  },
  {
    name: 'SÃO FELIPE',
    initials: 'BA',
    code: '2929107',
  },
  {
    name: 'SÃO FELIPE D\'OESTE',
    initials: 'RO',
    code: '1101484',
  },
  {
    name: 'SÃO FÉLIX',
    initials: 'BA',
    code: '2929008',
  },
  {
    name: 'SÃO FÉLIX DE BALSAS',
    initials: 'MA',
    code: '2110807',
  },
  {
    name: 'SÃO FÉLIX DE MINAS',
    initials: 'MG',
    code: '3161056',
  },
  {
    name: 'SÃO FÉLIX DO ARAGUAIA',
    initials: 'MT',
    code: '5107859',
  },
  {
    name: 'SÃO FÉLIX DO CORIBE',
    initials: 'BA',
    code: '2929057',
  },
  {
    name: 'SÃO FÉLIX DO PIAUÍ',
    initials: 'PI',
    code: '2209609',
  },
  {
    name: 'SÃO FÉLIX DO TOCANTINS',
    initials: 'TO',
    code: '1720150',
  },
  {
    name: 'SÃO FÉLIX DO XINGU',
    initials: 'PA',
    code: '1507300',
  },
  {
    name: 'SÃO FERNANDO',
    initials: 'RN',
    code: '2411809',
  },
  {
    name: 'SÃO FIDÉLIS',
    initials: 'RJ',
    code: '3304805',
  },
  {
    name: 'SÃO FRANCISCO',
    initials: 'SE',
    code: '2806909',
  },
  {
    name: 'SÃO FRANCISCO',
    initials: 'PB',
    code: '2513984',
  },
  {
    name: 'SÃO FRANCISCO',
    initials: 'SP',
    code: '3549003',
  },
  {
    name: 'SÃO FRANCISCO',
    initials: 'MG',
    code: '3161106',
  },
  {
    name: 'SÃO FRANCISCO DE ASSIS',
    initials: 'RS',
    code: '4318101',
  },
  {
    name: 'SÃO FRANCISCO DE ASSIS DO PIAUÍ',
    initials: 'PI',
    code: '2209658',
  },
  {
    name: 'SÃO FRANCISCO DE GOIÁS',
    initials: 'GO',
    code: '5219902',
  },
  {
    name: 'SÃO FRANCISCO DE ITABAPOANA',
    initials: 'RJ',
    code: '3304755',
  },
  {
    name: 'SÃO FRANCISCO DE PAULA',
    initials: 'RS',
    code: '4318200',
  },
  {
    name: 'SÃO FRANCISCO DE PAULA',
    initials: 'MG',
    code: '3161205',
  },
  {
    name: 'SÃO FRANCISCO DE SALES',
    initials: 'MG',
    code: '3161304',
  },
  {
    name: 'SÃO FRANCISCO DO BREJÃO',
    initials: 'MA',
    code: '2110856',
  },
  {
    name: 'SÃO FRANCISCO DO CONDE',
    initials: 'BA',
    code: '2929206',
  },
  {
    name: 'SÃO FRANCISCO DO GLÓRIA',
    initials: 'MG',
    code: '3161403',
  },
  {
    name: 'SÃO FRANCISCO DO GUAPORÉ',
    initials: 'RO',
    code: '1101492',
  },
  {
    name: 'SÃO FRANCISCO DO MARANHÃO',
    initials: 'MA',
    code: '2110906',
  },
  {
    name: 'SÃO FRANCISCO DO OESTE',
    initials: 'RN',
    code: '2411908',
  },
  {
    name: 'SÃO FRANCISCO DO PARÁ',
    initials: 'PA',
    code: '1507409',
  },
  {
    name: 'SÃO FRANCISCO DO PIAUÍ',
    initials: 'PI',
    code: '2209708',
  },
  {
    name: 'SÃO FRANCISCO DO SUL',
    initials: 'SC',
    code: '4216206',
  },
  {
    name: 'SÃO GABRIEL',
    initials: 'RS',
    code: '4318309',
  },
  {
    name: 'SÃO GABRIEL',
    initials: 'BA',
    code: '2929255',
  },
  {
    name: 'SÃO GABRIEL DA CACHOEIRA',
    initials: 'AM',
    code: '1303809',
  },
  {
    name: 'SÃO GABRIEL DA PALHA',
    initials: 'ES',
    code: '3204708',
  },
  {
    name: 'SÃO GABRIEL DO OESTE',
    initials: 'MS',
    code: '5007695',
  },
  {
    name: 'SÃO GERALDO',
    initials: 'MG',
    code: '3161502',
  },
  {
    name: 'SÃO GERALDO DA PIEDADE',
    initials: 'MG',
    code: '3161601',
  },
  {
    name: 'SÃO GERALDO DO ARAGUAIA',
    initials: 'PA',
    code: '1507458',
  },
  {
    name: 'SÃO GERALDO DO BAIXIO',
    initials: 'MG',
    code: '3161650',
  },
  {
    name: 'SÃO GONÇALO',
    initials: 'RJ',
    code: '3304904',
  },
  {
    name: 'SÃO GONÇALO DO ABAETÉ',
    initials: 'MG',
    code: '3161700',
  },
  {
    name: 'SÃO GONÇALO DO AMARANTE',
    initials: 'RN',
    code: '2412005',
  },
  {
    name: 'SÃO GONÇALO DO AMARANTE',
    initials: 'CE',
    code: '2312403',
  },
  {
    name: 'SÃO GONÇALO DO GURGUÉIA',
    initials: 'PI',
    code: '2209757',
  },
  {
    name: 'SÃO GONÇALO DO PARÁ',
    initials: 'MG',
    code: '3161809',
  },
  {
    name: 'SÃO GONÇALO DO PIAUÍ',
    initials: 'PI',
    code: '2209807',
  },
  {
    name: 'SÃO GONÇALO DO RIO ABAIXO',
    initials: 'MG',
    code: '3161908',
  },
  {
    name: 'SÃO GONÇALO DO RIO PRETO',
    initials: 'MG',
    code: '3125507',
  },
  {
    name: 'SÃO GONÇALO DO SAPUCAÍ',
    initials: 'MG',
    code: '3162005',
  },
  {
    name: 'SÃO GONÇALO DOS CAMPOS',
    initials: 'BA',
    code: '2929305',
  },
  {
    name: 'SÃO GOTARDO',
    initials: 'MG',
    code: '3162104',
  },
  {
    name: 'SÃO JERÔNIMO',
    initials: 'RS',
    code: '4318408',
  },
  {
    name: 'SÃO JERÔNIMO DA SERRA',
    initials: 'PR',
    code: '4124707',
  },
  {
    name: 'SÃO JOÃO',
    initials: 'PR',
    code: '4124806',
  },
  {
    name: 'SÃO JOÃO',
    initials: 'PE',
    code: '2613206',
  },
  {
    name: 'SÃO JOÃO BATISTA',
    initials: 'SC',
    code: '4216305',
  },
  {
    name: 'SÃO JOÃO BATISTA',
    initials: 'MA',
    code: '2111003',
  },
  {
    name: 'SÃO JOÃO BATISTA DO GLÓRIA',
    initials: 'MG',
    code: '3162203',
  },
  {
    name: 'SÃO JOÃO DA BALIZA',
    initials: 'RR',
    code: '1400506',
  },
  {
    name: 'SÃO JOÃO DA BARRA',
    initials: 'RJ',
    code: '3305000',
  },
  {
    name: 'SÃO JOÃO DA BOA VISTA',
    initials: 'SP',
    code: '3549102',
  },
  {
    name: 'SÃO JOÃO DA CANABRAVA',
    initials: 'PI',
    code: '2209856',
  },
  {
    name: 'SÃO JOÃO DA FRONTEIRA',
    initials: 'PI',
    code: '2209872',
  },
  {
    name: 'SÃO JOÃO DA LAGOA',
    initials: 'MG',
    code: '3162252',
  },
  {
    name: 'SÃO JOÃO D\'ALIANÇA',
    initials: 'GO',
    code: '5220009',
  },
  {
    name: 'SÃO JOÃO DA MATA',
    initials: 'MG',
    code: '3162302',
  },
  {
    name: 'SÃO JOÃO DA PARAÚNA',
    initials: 'GO',
    code: '5220058',
  },
  {
    name: 'SÃO JOÃO DA PONTA',
    initials: 'PA',
    code: '1507466',
  },
  {
    name: 'SÃO JOÃO DA PONTE',
    initials: 'MG',
    code: '3162401',
  },
  {
    name: 'SÃO JOÃO DAS DUAS PONTES',
    initials: 'SP',
    code: '3549201',
  },
  {
    name: 'SÃO JOÃO DA SERRA',
    initials: 'PI',
    code: '2209906',
  },
  {
    name: 'SÃO JOÃO DAS MISSÕES',
    initials: 'MG',
    code: '3162450',
  },
  {
    name: 'SÃO JOÃO DA URTIGA',
    initials: 'RS',
    code: '4318424',
  },
  {
    name: 'SÃO JOÃO DA VARJOTA',
    initials: 'PI',
    code: '2209955',
  },
  {
    name: 'SÃO JOÃO DE IRACEMA',
    initials: 'SP',
    code: '3549250',
  },
  {
    name: 'SÃO JOÃO DEL REI',
    initials: 'MG',
    code: '3162500',
  },
  {
    name: 'SÃO JOÃO DE MERITI',
    initials: 'RJ',
    code: '3305109',
  },
  {
    name: 'SÃO JOÃO DE PIRABAS',
    initials: 'PA',
    code: '1507474',
  },
  {
    name: 'SÃO JOÃO DO ARAGUAIA',
    initials: 'PA',
    code: '1507508',
  },
  {
    name: 'SÃO JOÃO DO ARRAIAL',
    initials: 'PI',
    code: '2209971',
  },
  {
    name: 'SÃO JOÃO DO CAIUÁ',
    initials: 'PR',
    code: '4124905',
  },
  {
    name: 'SÃO JOÃO DO CARIRI',
    initials: 'PB',
    code: '2514008',
  },
  {
    name: 'SÃO JOÃO DO CARÚ',
    initials: 'MA',
    code: '2111029',
  },
  {
    name: 'SÃO JOÃO DO ITAPERIÚ',
    initials: 'SC',
    code: '4216354',
  },
  {
    name: 'SÃO JOÃO DO IVAÍ',
    initials: 'PR',
    code: '4125001',
  },
  {
    name: 'SÃO JOÃO DO JAGUARIBE',
    initials: 'CE',
    code: '2312502',
  },
  {
    name: 'SÃO JOÃO DO MANHUAÇU',
    initials: 'MG',
    code: '3162559',
  },
  {
    name: 'SÃO JOÃO DO MANTENINHA',
    initials: 'MG',
    code: '3162575',
  },
  {
    name: 'SÃO JOÃO DO OESTE',
    initials: 'SC',
    code: '4216255',
  },
  {
    name: 'SÃO JOÃO DO ORIENTE',
    initials: 'MG',
    code: '3162609',
  },
  {
    name: 'SÃO JOÃO DO PACUÍ',
    initials: 'MG',
    code: '3162658',
  },
  {
    name: 'SÃO JOÃO DO PARAÍSO',
    initials: 'MG',
    code: '3162708',
  },
  {
    name: 'SÃO JOÃO DO PARAÍSO',
    initials: 'MA',
    code: '2111052',
  },
  {
    name: 'SÃO JOÃO DO PAU D\'ALHO',
    initials: 'SP',
    code: '3549300',
  },
  {
    name: 'SÃO JOÃO DO PIAUÍ',
    initials: 'PI',
    code: '2210003',
  },
  {
    name: 'SÃO JOÃO DO POLÊSINE',
    initials: 'RS',
    code: '4318432',
  },
  {
    name: 'SÃO JOÃO DO RIO DO PEIXE',
    initials: 'PB',
    code: '2500700',
  },
  {
    name: 'SÃO JOÃO DO SABUGI',
    initials: 'RN',
    code: '2412104',
  },
  {
    name: 'SÃO JOÃO DO SOTER',
    initials: 'MA',
    code: '2111078',
  },
  {
    name: 'SÃO JOÃO DOS PATOS',
    initials: 'MA',
    code: '2111102',
  },
  {
    name: 'SÃO JOÃO DO SUL',
    initials: 'SC',
    code: '4216404',
  },
  {
    name: 'SÃO JOÃO DO TIGRE',
    initials: 'PB',
    code: '2514107',
  },
  {
    name: 'SÃO JOÃO DO TRIUNFO',
    initials: 'PR',
    code: '4125100',
  },
  {
    name: 'SÃO JOÃO EVANGELISTA',
    initials: 'MG',
    code: '3162807',
  },
  {
    name: 'SÃO JOÃO NEPOMUCENO',
    initials: 'MG',
    code: '3162906',
  },
  {
    name: 'SÃO JOAQUIM',
    initials: 'SC',
    code: '4216503',
  },
  {
    name: 'SÃO JOAQUIM DA BARRA',
    initials: 'SP',
    code: '3549409',
  },
  {
    name: 'SÃO JOAQUIM DE BICAS',
    initials: 'MG',
    code: '3162922',
  },
  {
    name: 'SÃO JOAQUIM DO MONTE',
    initials: 'PE',
    code: '2613305',
  },
  {
    name: 'SÃO JORGE',
    initials: 'RS',
    code: '4318440',
  },
  {
    name: 'SÃO JORGE D\'OESTE',
    initials: 'PR',
    code: '4125209',
  },
  {
    name: 'SÃO JORGE DO IVAÍ',
    initials: 'PR',
    code: '4125308',
  },
  {
    name: 'SÃO JORGE DO PATROCÍNIO',
    initials: 'PR',
    code: '4125357',
  },
  {
    name: 'SÃO JOSÉ',
    initials: 'SC',
    code: '4216602',
  },
  {
    name: 'SÃO JOSÉ DA BARRA',
    initials: 'MG',
    code: '3162948',
  },
  {
    name: 'SÃO JOSÉ DA BELA VISTA',
    initials: 'SP',
    code: '3549508',
  },
  {
    name: 'SÃO JOSÉ DA BOA VISTA',
    initials: 'PR',
    code: '4125407',
  },
  {
    name: 'SÃO JOSÉ DA COROA GRANDE',
    initials: 'PE',
    code: '2613404',
  },
  {
    name: 'SÃO JOSÉ DA LAGOA TAPADA',
    initials: 'PB',
    code: '2514206',
  },
  {
    name: 'SÃO JOSÉ DA LAJE',
    initials: 'AL',
    code: '2708303',
  },
  {
    name: 'SÃO JOSÉ DA LAPA',
    initials: 'MG',
    code: '3162955',
  },
  {
    name: 'SÃO JOSÉ DA SAFIRA',
    initials: 'MG',
    code: '3163003',
  },
  {
    name: 'SÃO JOSÉ DAS MISSÕES',
    initials: 'RS',
    code: '4318457',
  },
  {
    name: 'SÃO JOSÉ DAS PALMEIRAS',
    initials: 'PR',
    code: '4125456',
  },
  {
    name: 'SÃO JOSÉ DA TAPERA',
    initials: 'AL',
    code: '2708402',
  },
  {
    name: 'SÃO JOSÉ DA VARGINHA',
    initials: 'MG',
    code: '3163102',
  },
  {
    name: 'SÃO JOSÉ DA VITÓRIA',
    initials: 'BA',
    code: '2929354',
  },
  {
    name: 'SÃO JOSÉ DE CAIANA',
    initials: 'PB',
    code: '2514305',
  },
  {
    name: 'SÃO JOSÉ DE ESPINHARAS',
    initials: 'PB',
    code: '2514404',
  },
  {
    name: 'SÃO JOSÉ DE MIPIBU',
    initials: 'RN',
    code: '2412203',
  },
  {
    name: 'SÃO JOSÉ DE PIRANHAS',
    initials: 'PB',
    code: '2514503',
  },
  {
    name: 'SÃO JOSÉ DE PRINCESA',
    initials: 'PB',
    code: '2514552',
  },
  {
    name: 'SÃO JOSÉ DE RIBAMAR',
    initials: 'MA',
    code: '2111201',
  },
  {
    name: 'SÃO JOSÉ DE UBÁ',
    initials: 'RJ',
    code: '3305133',
  },
  {
    name: 'SÃO JOSÉ DO ALEGRE',
    initials: 'MG',
    code: '3163201',
  },
  {
    name: 'SÃO JOSÉ DO BARREIRO',
    initials: 'SP',
    code: '3549607',
  },
  {
    name: 'SÃO JOSÉ DO BELMONTE',
    initials: 'PE',
    code: '2613503',
  },
  {
    name: 'SÃO JOSÉ DO BONFIM',
    initials: 'PB',
    code: '2514602',
  },
  {
    name: 'SÃO JOSÉ DO BREJO DO CRUZ',
    initials: 'PB',
    code: '2514651',
  },
  {
    name: 'SÃO JOSÉ DO CALÇADO',
    initials: 'ES',
    code: '3204807',
  },
  {
    name: 'SÃO JOSÉ DO CAMPESTRE',
    initials: 'RN',
    code: '2412302',
  },
  {
    name: 'SÃO JOSÉ DO CEDRO',
    initials: 'SC',
    code: '4216701',
  },
  {
    name: 'SÃO JOSÉ DO CERRITO',
    initials: 'SC',
    code: '4216800',
  },
  {
    name: 'SÃO JOSÉ DO DIVINO',
    initials: 'MG',
    code: '3163300',
  },
  {
    name: 'SÃO JOSÉ DO DIVINO',
    initials: 'PI',
    code: '2210052',
  },
  {
    name: 'SÃO JOSÉ DO EGITO',
    initials: 'PE',
    code: '2613602',
  },
  {
    name: 'SÃO JOSÉ DO GOIABAL',
    initials: 'MG',
    code: '3163409',
  },
  {
    name: 'SÃO JOSÉ DO HERVAL',
    initials: 'RS',
    code: '4318465',
  },
  {
    name: 'SÃO JOSÉ DO HORTÊNCIO',
    initials: 'RS',
    code: '4318481',
  },
  {
    name: 'SÃO JOSÉ DO INHACORÁ',
    initials: 'RS',
    code: '4318499',
  },
  {
    name: 'SÃO JOSÉ DO JACUÍPE',
    initials: 'BA',
    code: '2929370',
  },
  {
    name: 'SÃO JOSÉ DO JACURI',
    initials: 'MG',
    code: '3163508',
  },
  {
    name: 'SÃO JOSÉ DO MANTIMENTO',
    initials: 'MG',
    code: '3163607',
  },
  {
    name: 'SÃO JOSÉ DO NORTE',
    initials: 'RS',
    code: '4318507',
  },
  {
    name: 'SÃO JOSÉ DO OURO',
    initials: 'RS',
    code: '4318606',
  },
  {
    name: 'SÃO JOSÉ DO PEIXE',
    initials: 'PI',
    code: '2210102',
  },
  {
    name: 'SÃO JOSÉ DO PIAUÍ',
    initials: 'PI',
    code: '2210201',
  },
  {
    name: 'SÃO JOSÉ DO POVO',
    initials: 'MT',
    code: '5107297',
  },
  {
    name: 'SÃO JOSÉ DO RIO CLARO',
    initials: 'MT',
    code: '5107305',
  },
  {
    name: 'SÃO JOSÉ DO RIO PARDO',
    initials: 'SP',
    code: '3549706',
  },
  {
    name: 'SÃO JOSÉ DO RIO PRETO',
    initials: 'SP',
    code: '3549805',
  },
  {
    name: 'SÃO JOSÉ DO SABUGI',
    initials: 'PB',
    code: '2514701',
  },
  {
    name: 'SÃO JOSÉ DOS AUSENTES',
    initials: 'RS',
    code: '4318622',
  },
  {
    name: 'SÃO JOSÉ DOS BASÍLIOS',
    initials: 'MA',
    code: '2111250',
  },
  {
    name: 'SÃO JOSÉ DOS CAMPOS',
    initials: 'SP',
    code: '3549904',
  },
  {
    name: 'SÃO JOSÉ DOS CORDEIROS',
    initials: 'PB',
    code: '2514800',
  },
  {
    name: 'SÃO JOSÉ DO SERIDÓ',
    initials: 'RN',
    code: '2412401',
  },
  {
    name: 'SÃO JOSÉ DOS PINHAIS',
    initials: 'PR',
    code: '4125506',
  },
  {
    name: 'SÃO JOSÉ DOS QUATRO MARCOS',
    initials: 'MT',
    code: '5107107',
  },
  {
    name: 'SÃO JOSÉ DOS RAMOS',
    initials: 'PB',
    code: '2514453',
  },
  {
    name: 'SÃO JOSÉ DO SUL',
    initials: 'RS',
    code: '4318614',
  },
  {
    name: 'SÃO JOSÉ DO VALE DO RIO PRETO',
    initials: 'RJ',
    code: '3305158',
  },
  {
    name: 'SÃO JOSÉ DO XINGU',
    initials: 'MT',
    code: '5107354',
  },
  {
    name: 'SÃO JULIÃO',
    initials: 'PI',
    code: '2210300',
  },
  {
    name: 'SÃO LEOPOLDO',
    initials: 'RS',
    code: '4318705',
  },
  {
    name: 'SÃO LOURENÇO',
    initials: 'MG',
    code: '3163706',
  },
  {
    name: 'SÃO LOURENÇO DA MATA',
    initials: 'PE',
    code: '2613701',
  },
  {
    name: 'SÃO LOURENÇO DA SERRA',
    initials: 'SP',
    code: '3549953',
  },
  {
    name: 'SÃO LOURENÇO DO OESTE',
    initials: 'SC',
    code: '4216909',
  },
  {
    name: 'SÃO LOURENÇO DO PIAUÍ',
    initials: 'PI',
    code: '2210359',
  },
  {
    name: 'SÃO LOURENÇO DO SUL',
    initials: 'RS',
    code: '4318804',
  },
  {
    name: 'SÃO LUDGERO',
    initials: 'SC',
    code: '4217006',
  },
  {
    name: 'SÃO LUÍS',
    initials: 'MA',
    code: '2111300',
  },
  {
    name: 'SÃO LUÍS DE MONTES BELOS',
    initials: 'GO',
    code: '5220108',
  },
  {
    name: 'SÃO LUÍS DO CURU',
    initials: 'CE',
    code: '2312601',
  },
  {
    name: 'SÃO LUÍS DO PIAUÍ',
    initials: 'PI',
    code: '2210375',
  },
  {
    name: 'SÃO LUÍS DO QUITUNDE',
    initials: 'AL',
    code: '2708501',
  },
  {
    name: 'SÃO LUÍS GONZAGA DO MARANHÃO',
    initials: 'MA',
    code: '2111409',
  },
  {
    name: 'SÃO LUIZ',
    initials: 'RR',
    code: '1400605',
  },
  {
    name: 'SÃO LUIZ DO NORTE',
    initials: 'GO',
    code: '5220157',
  },
  {
    name: 'SÃO LUIZ DO PARAITINGA',
    initials: 'SP',
    code: '3550001',
  },
  {
    name: 'SÃO LUIZ GONZAGA',
    initials: 'RS',
    code: '4318903',
  },
  {
    name: 'SÃO MAMEDE',
    initials: 'PB',
    code: '2514909',
  },
  {
    name: 'SÃO MANOEL DO PARANÁ',
    initials: 'PR',
    code: '4125555',
  },
  {
    name: 'SÃO MANUEL',
    initials: 'SP',
    code: '3550100',
  },
  {
    name: 'SÃO MARCOS',
    initials: 'RS',
    code: '4319000',
  },
  {
    name: 'SÃO MARTINHO',
    initials: 'SC',
    code: '4217105',
  },
  {
    name: 'SÃO MARTINHO',
    initials: 'RS',
    code: '4319109',
  },
  {
    name: 'SÃO MARTINHO DA SERRA',
    initials: 'RS',
    code: '4319125',
  },
  {
    name: 'SÃO MATEUS',
    initials: 'ES',
    code: '3204906',
  },
  {
    name: 'SÃO MATEUS DO MARANHÃO',
    initials: 'MA',
    code: '2111508',
  },
  {
    name: 'SÃO MATEUS DO SUL',
    initials: 'PR',
    code: '4125605',
  },
  {
    name: 'SÃO MIGUEL',
    initials: 'RN',
    code: '2412500',
  },
  {
    name: 'SÃO MIGUEL ARCANJO',
    initials: 'SP',
    code: '3550209',
  },
  {
    name: 'SÃO MIGUEL DA BAIXA GRANDE',
    initials: 'PI',
    code: '2210383',
  },
  {
    name: 'SÃO MIGUEL DA BOA VISTA',
    initials: 'SC',
    code: '4217154',
  },
  {
    name: 'SÃO MIGUEL DAS MATAS',
    initials: 'BA',
    code: '2929404',
  },
  {
    name: 'SÃO MIGUEL DAS MISSÕES',
    initials: 'RS',
    code: '4319158',
  },
  {
    name: 'SÃO MIGUEL DE TAIPU',
    initials: 'PB',
    code: '2515005',
  },
  {
    name: 'SÃO MIGUEL DO ALEIXO',
    initials: 'SE',
    code: '2807006',
  },
  {
    name: 'SÃO MIGUEL DO ANTA',
    initials: 'MG',
    code: '3163805',
  },
  {
    name: 'SÃO MIGUEL DO ARAGUAIA',
    initials: 'GO',
    code: '5220207',
  },
  {
    name: 'SÃO MIGUEL DO FIDALGO',
    initials: 'PI',
    code: '2210391',
  },
  {
    name: 'SÃO MIGUEL DO GOSTOSO',
    initials: 'RN',
    code: '2412559',
  },
  {
    name: 'SÃO MIGUEL DO GUAMÁ',
    initials: 'PA',
    code: '1507607',
  },
  {
    name: 'SÃO MIGUEL DO GUAPORÉ',
    initials: 'RO',
    code: '1100320',
  },
  {
    name: 'SÃO MIGUEL DO IGUAÇU',
    initials: 'PR',
    code: '4125704',
  },
  {
    name: 'SÃO MIGUEL DO OESTE',
    initials: 'SC',
    code: '4217204',
  },
  {
    name: 'SÃO MIGUEL DO PASSA QUATRO',
    initials: 'GO',
    code: '5220264',
  },
  {
    name: 'SÃO MIGUEL DOS CAMPOS',
    initials: 'AL',
    code: '2708600',
  },
  {
    name: 'SÃO MIGUEL DOS MILAGRES',
    initials: 'AL',
    code: '2708709',
  },
  {
    name: 'SÃO MIGUEL DO TAPUIO',
    initials: 'PI',
    code: '2210409',
  },
  {
    name: 'SÃO MIGUEL DO TOCANTINS',
    initials: 'TO',
    code: '1720200',
  },
  {
    name: 'SÃO NICOLAU',
    initials: 'RS',
    code: '4319208',
  },
  {
    name: 'SÃO PATRÍCIO',
    initials: 'GO',
    code: '5220280',
  },
  {
    name: 'SÃO PAULO',
    initials: 'SP',
    code: '3550308',
  },
  {
    name: 'SÃO PAULO DAS MISSÕES',
    initials: 'RS',
    code: '4319307',
  },
  {
    name: 'SÃO PAULO DE OLIVENÇA',
    initials: 'AM',
    code: '1303908',
  },
  {
    name: 'SÃO PAULO DO POTENGI',
    initials: 'RN',
    code: '2412609',
  },
  {
    name: 'SÃO PEDRO',
    initials: 'SP',
    code: '3550407',
  },
  {
    name: 'SÃO PEDRO',
    initials: 'RN',
    code: '2412708',
  },
  {
    name: 'SÃO PEDRO DA ÁGUA BRANCA',
    initials: 'MA',
    code: '2111532',
  },
  {
    name: 'SÃO PEDRO DA ALDEIA',
    initials: 'RJ',
    code: '3305208',
  },
  {
    name: 'SÃO PEDRO DA CIPA',
    initials: 'MT',
    code: '5107404',
  },
  {
    name: 'SÃO PEDRO DA SERRA',
    initials: 'RS',
    code: '4319356',
  },
  {
    name: 'SÃO PEDRO DAS MISSÕES',
    initials: 'RS',
    code: '4319364',
  },
  {
    name: 'SÃO PEDRO DA UNIÃO',
    initials: 'MG',
    code: '3163904',
  },
  {
    name: 'SÃO PEDRO DE ALCÂNTARA',
    initials: 'SC',
    code: '4217253',
  },
  {
    name: 'SÃO PEDRO DO BUTIÁ',
    initials: 'RS',
    code: '4319372',
  },
  {
    name: 'SÃO PEDRO DO IGUAÇU',
    initials: 'PR',
    code: '4125753',
  },
  {
    name: 'SÃO PEDRO DO IVAÍ',
    initials: 'PR',
    code: '4125803',
  },
  {
    name: 'SÃO PEDRO DO PARANÁ',
    initials: 'PR',
    code: '4125902',
  },
  {
    name: 'SÃO PEDRO DO PIAUÍ',
    initials: 'PI',
    code: '2210508',
  },
  {
    name: 'SÃO PEDRO DOS CRENTES',
    initials: 'MA',
    code: '2111573',
  },
  {
    name: 'SÃO PEDRO DOS FERROS',
    initials: 'MG',
    code: '3164001',
  },
  {
    name: 'SÃO PEDRO DO SUAÇUÍ',
    initials: 'MG',
    code: '3164100',
  },
  {
    name: 'SÃO PEDRO DO SUL',
    initials: 'RS',
    code: '4319406',
  },
  {
    name: 'SÃO PEDRO DO TURVO',
    initials: 'SP',
    code: '3550506',
  },
  {
    name: 'SÃO RAFAEL',
    initials: 'RN',
    code: '2412807',
  },
  {
    name: 'SÃO RAIMUNDO DAS MANGABEIRAS',
    initials: 'MA',
    code: '2111607',
  },
  {
    name: 'SÃO RAIMUNDO DO DOCA BEZERRA',
    initials: 'MA',
    code: '2111631',
  },
  {
    name: 'SÃO RAIMUNDO NONATO',
    initials: 'PI',
    code: '2210607',
  },
  {
    name: 'SÃO ROBERTO',
    initials: 'MA',
    code: '2111672',
  },
  {
    name: 'SÃO ROMÃO',
    initials: 'MG',
    code: '3164209',
  },
  {
    name: 'SÃO ROQUE',
    initials: 'SP',
    code: '3550605',
  },
  {
    name: 'SÃO ROQUE DE MINAS',
    initials: 'MG',
    code: '3164308',
  },
  {
    name: 'SÃO ROQUE DO CANAÃ',
    initials: 'ES',
    code: '3204955',
  },
  {
    name: 'SÃO SALVADOR DO TOCANTINS',
    initials: 'TO',
    code: '1720259',
  },
  {
    name: 'SÃO SEBASTIÃO',
    initials: 'AL',
    code: '2708808',
  },
  {
    name: 'SÃO SEBASTIÃO',
    initials: 'SP',
    code: '3550704',
  },
  {
    name: 'SÃO SEBASTIÃO DA AMOREIRA',
    initials: 'PR',
    code: '4126009',
  },
  {
    name: 'SÃO SEBASTIÃO DA BELA VISTA',
    initials: 'MG',
    code: '3164407',
  },
  {
    name: 'SÃO SEBASTIÃO DA BOA VISTA',
    initials: 'PA',
    code: '1507706',
  },
  {
    name: 'SÃO SEBASTIÃO DA GRAMA',
    initials: 'SP',
    code: '3550803',
  },
  {
    name: 'SÃO SEBASTIÃO DA VARGEM ALEGRE',
    initials: 'MG',
    code: '3164431',
  },
  {
    name: 'SÃO SEBASTIÃO DE LAGOA DE ROÇA',
    initials: 'PB',
    code: '2515104',
  },
  {
    name: 'SÃO SEBASTIÃO DO ALTO',
    initials: 'RJ',
    code: '3305307',
  },
  {
    name: 'SÃO SEBASTIÃO DO ANTA',
    initials: 'MG',
    code: '3164472',
  },
  {
    name: 'SÃO SEBASTIÃO DO CAÍ',
    initials: 'RS',
    code: '4319505',
  },
  {
    name: 'SÃO SEBASTIÃO DO MARANHÃO',
    initials: 'MG',
    code: '3164506',
  },
  {
    name: 'SÃO SEBASTIÃO DO OESTE',
    initials: 'MG',
    code: '3164605',
  },
  {
    name: 'SÃO SEBASTIÃO DO PARAÍSO',
    initials: 'MG',
    code: '3164704',
  },
  {
    name: 'SÃO SEBASTIÃO DO PASSE',
    initials: 'BA',
    code: '2929503',
  },
  {
    name: 'SÃO SEBASTIÃO DO RIO PRETO',
    initials: 'MG',
    code: '3164803',
  },
  {
    name: 'SÃO SEBASTIÃO DO RIO VERDE',
    initials: 'MG',
    code: '3164902',
  },
  {
    name: 'SÃO SEBASTIÃO DO TOCANTINS',
    initials: 'TO',
    code: '1720309',
  },
  {
    name: 'SÃO SEBASTIÃO DO UATUMÃ',
    initials: 'AM',
    code: '1303957',
  },
  {
    name: 'SÃO SEBASTIÃO DO UMBUZEIRO',
    initials: 'PB',
    code: '2515203',
  },
  {
    name: 'SÃO SEPÉ',
    initials: 'RS',
    code: '4319604',
  },
  {
    name: 'SÃO SIMÃO',
    initials: 'GO',
    code: '5220405',
  },
  {
    name: 'SÃO SIMÃO',
    initials: 'SP',
    code: '3550902',
  },
  {
    name: 'SÃO THOMÉ DAS LETRAS',
    initials: 'MG',
    code: '3165206',
  },
  {
    name: 'SÃO TIAGO',
    initials: 'MG',
    code: '3165008',
  },
  {
    name: 'SÃO TOMÁS DE AQUINO',
    initials: 'MG',
    code: '3165107',
  },
  {
    name: 'SÃO TOMÉ',
    initials: 'PR',
    code: '4126108',
  },
  {
    name: 'SÃO TOMÉ',
    initials: 'RN',
    code: '2412906',
  },
  {
    name: 'SÃO VALENTIM',
    initials: 'RS',
    code: '4319703',
  },
  {
    name: 'SÃO VALENTIM DO SUL',
    initials: 'RS',
    code: '4319711',
  },
  {
    name: 'SÃO VALÉRIO DA NATIVIDADE',
    initials: 'TO',
    code: '1720499',
  },
  {
    name: 'SÃO VALÉRIO DO SUL',
    initials: 'RS',
    code: '4319737',
  },
  {
    name: 'SÃO VENDELINO',
    initials: 'RS',
    code: '4319752',
  },
  {
    name: 'SÃO VICENTE',
    initials: 'RN',
    code: '2413003',
  },
  {
    name: 'SÃO VICENTE',
    initials: 'SP',
    code: '3551009',
  },
  {
    name: 'SÃO VICENTE DE MINAS',
    initials: 'MG',
    code: '3165305',
  },
  {
    name: 'SÃO VICENTE DO SERIDÓ',
    initials: 'PB',
    code: '2515401',
  },
  {
    name: 'SÃO VICENTE DO SUL',
    initials: 'RS',
    code: '4319802',
  },
  {
    name: 'SÃO VICENTE FERRER',
    initials: 'MA',
    code: '2111706',
  },
  {
    name: 'SÃO VICENTE FERRER',
    initials: 'PE',
    code: '2613800',
  },
  {
    name: 'SAPÉ',
    initials: 'PB',
    code: '2515302',
  },
  {
    name: 'SAPEAÇU',
    initials: 'BA',
    code: '2929602',
  },
  {
    name: 'SAPEZAL',
    initials: 'MT',
    code: '5107875',
  },
  {
    name: 'SAPIRANGA',
    initials: 'RS',
    code: '4319901',
  },
  {
    name: 'SAPOPEMA',
    initials: 'PR',
    code: '4126207',
  },
  {
    name: 'SAPUCAIA',
    initials: 'RJ',
    code: '3305406',
  },
  {
    name: 'SAPUCAIA',
    initials: 'PA',
    code: '1507755',
  },
  {
    name: 'SAPUCAIA DO SUL',
    initials: 'RS',
    code: '4320008',
  },
  {
    name: 'SAPUCAÍ-MIRIM',
    initials: 'MG',
    code: '3165404',
  },
  {
    name: 'SAQUAREMA',
    initials: 'RJ',
    code: '3305505',
  },
  {
    name: 'SARANDI',
    initials: 'PR',
    code: '4126256',
  },
  {
    name: 'SARANDI',
    initials: 'RS',
    code: '4320107',
  },
  {
    name: 'SARAPUÍ',
    initials: 'SP',
    code: '3551108',
  },
  {
    name: 'SARDOÁ',
    initials: 'MG',
    code: '3165503',
  },
  {
    name: 'SARUTAIÁ',
    initials: 'SP',
    code: '3551207',
  },
  {
    name: 'SARZEDO',
    initials: 'MG',
    code: '3165537',
  },
  {
    name: 'SÁTIRO DIAS',
    initials: 'BA',
    code: '2929701',
  },
  {
    name: 'SATUBA',
    initials: 'AL',
    code: '2708907',
  },
  {
    name: 'SATUBINHA',
    initials: 'MA',
    code: '2111722',
  },
  {
    name: 'SAUBARA',
    initials: 'BA',
    code: '2929750',
  },
  {
    name: 'SAUDADE DO IGUAÇU',
    initials: 'PR',
    code: '4126272',
  },
  {
    name: 'SAUDADES',
    initials: 'SC',
    code: '4217303',
  },
  {
    name: 'SAÚDE',
    initials: 'BA',
    code: '2929800',
  },
  {
    name: 'SCHROEDER',
    initials: 'SC',
    code: '4217402',
  },
  {
    name: 'SEABRA',
    initials: 'BA',
    code: '2929909',
  },
  {
    name: 'SEARA',
    initials: 'SC',
    code: '4217501',
  },
  {
    name: 'SEBASTIANÓPOLIS DO SUL',
    initials: 'SP',
    code: '3551306',
  },
  {
    name: 'SEBASTIÃO BARROS',
    initials: 'PI',
    code: '2210623',
  },
  {
    name: 'SEBASTIÃO LARANJEIRAS',
    initials: 'BA',
    code: '2930006',
  },
  {
    name: 'SEBASTIÃO LEAL',
    initials: 'PI',
    code: '2210631',
  },
  {
    name: 'SEBERI',
    initials: 'RS',
    code: '4320206',
  },
  {
    name: 'SEDE NOVA',
    initials: 'RS',
    code: '4320230',
  },
  {
    name: 'SEGREDO',
    initials: 'RS',
    code: '4320263',
  },
  {
    name: 'SELBACH',
    initials: 'RS',
    code: '4320305',
  },
  {
    name: 'SELVÍRIA',
    initials: 'MS',
    code: '5007802',
  },
  {
    name: 'SEM PEIXE',
    initials: 'MG',
    code: '3165560',
  },
  {
    name: 'SENADOR ALEXANDRE COSTA',
    initials: 'MA',
    code: '2111748',
  },
  {
    name: 'SENADOR AMARAL',
    initials: 'MG',
    code: '3165578',
  },
  {
    name: 'SENADOR CANEDO',
    initials: 'GO',
    code: '5220454',
  },
  {
    name: 'SENADOR CORTES',
    initials: 'MG',
    code: '3165602',
  },
  {
    name: 'SENADOR ELÓI DE SOUZA',
    initials: 'RN',
    code: '2413102',
  },
  {
    name: 'SENADOR FIRMINO',
    initials: 'MG',
    code: '3165701',
  },
  {
    name: 'SENADOR GEORGINO AVELINO',
    initials: 'RN',
    code: '2413201',
  },
  {
    name: 'SENADOR GUIOMARD',
    initials: 'AC',
    code: '1200450',
  },
  {
    name: 'SENADOR JOSÉ BENTO',
    initials: 'MG',
    code: '3165800',
  },
  {
    name: 'SENADOR JOSÉ PORFÍRIO',
    initials: 'PA',
    code: '1507805',
  },
  {
    name: 'SENADOR LA ROCQUE',
    initials: 'MA',
    code: '2111763',
  },
  {
    name: 'SENADOR MODESTINO GONÇALVES',
    initials: 'MG',
    code: '3165909',
  },
  {
    name: 'SENADOR POMPEU',
    initials: 'CE',
    code: '2312700',
  },
  {
    name: 'SENADOR RUI PALMEIRA',
    initials: 'AL',
    code: '2708956',
  },
  {
    name: 'SENADOR SÁ',
    initials: 'CE',
    code: '2312809',
  },
  {
    name: 'SENADOR SALGADO FILHO',
    initials: 'RS',
    code: '4320321',
  },
  {
    name: 'SENA MADUREIRA',
    initials: 'AC',
    code: '1200500',
  },
  {
    name: 'SENGÉS',
    initials: 'PR',
    code: '4126306',
  },
  {
    name: 'SENHORA DE OLIVEIRA',
    initials: 'MG',
    code: '3166006',
  },
  {
    name: 'SENHORA DO PORTO',
    initials: 'MG',
    code: '3166105',
  },
  {
    name: 'SENHORA DOS REMÉDIOS',
    initials: 'MG',
    code: '3166204',
  },
  {
    name: 'SENHOR DO BONFIM',
    initials: 'BA',
    code: '2930105',
  },
  {
    name: 'SENTINELA DO SUL',
    initials: 'RS',
    code: '4320354',
  },
  {
    name: 'SENTO SÉ',
    initials: 'BA',
    code: '2930204',
  },
  {
    name: 'SERAFINA CORRÊA',
    initials: 'RS',
    code: '4320404',
  },
  {
    name: 'SERICITA',
    initials: 'MG',
    code: '3166303',
  },
  {
    name: 'SERINGUEIRAS',
    initials: 'RO',
    code: '1101500',
  },
  {
    name: 'SÉRIO',
    initials: 'RS',
    code: '4320453',
  },
  {
    name: 'SERITINGA',
    initials: 'MG',
    code: '3166402',
  },
  {
    name: 'SEROPÉDICA',
    initials: 'RJ',
    code: '3305554',
  },
  {
    name: 'SERRA',
    initials: 'ES',
    code: '3205002',
  },
  {
    name: 'SERRA ALTA',
    initials: 'SC',
    code: '4217550',
  },
  {
    name: 'SERRA AZUL',
    initials: 'SP',
    code: '3551405',
  },
  {
    name: 'SERRA AZUL DE MINAS',
    initials: 'MG',
    code: '3166501',
  },
  {
    name: 'SERRA BRANCA',
    initials: 'PB',
    code: '2515500',
  },
  {
    name: 'SERRA CAIADA',
    initials: 'RN',
    code: '2410306',
  },
  {
    name: 'SERRA DA RAIZ',
    initials: 'PB',
    code: '2515609',
  },
  {
    name: 'SERRA DA SAUDADE',
    initials: 'MG',
    code: '3166600',
  },
  {
    name: 'SERRA DE SÃO BENTO',
    initials: 'RN',
    code: '2413300',
  },
  {
    name: 'SERRA DO MEL',
    initials: 'RN',
    code: '2413359',
  },
  {
    name: 'SERRA DO NAVIO',
    initials: 'AP',
    code: '1600055',
  },
  {
    name: 'SERRA DO RAMALHO',
    initials: 'BA',
    code: '2930154',
  },
  {
    name: 'SERRA DOS AIMORÉS',
    initials: 'MG',
    code: '3166709',
  },
  {
    name: 'SERRA DO SALITRE',
    initials: 'MG',
    code: '3166808',
  },
  {
    name: 'SERRA DOURADA',
    initials: 'BA',
    code: '2930303',
  },
  {
    name: 'SERRA GRANDE',
    initials: 'PB',
    code: '2515708',
  },
  {
    name: 'SERRANA',
    initials: 'SP',
    code: '3551504',
  },
  {
    name: 'SERRA NEGRA',
    initials: 'SP',
    code: '3551603',
  },
  {
    name: 'SERRA NEGRA DO NORTE',
    initials: 'RN',
    code: '2413409',
  },
  {
    name: 'SERRANIA',
    initials: 'MG',
    code: '3166907',
  },
  {
    name: 'SERRANO DO MARANHÃO',
    initials: 'MA',
    code: '2111789',
  },
  {
    name: 'SERRANÓPOLIS',
    initials: 'GO',
    code: '5220504',
  },
  {
    name: 'SERRANÓPOLIS DE MINAS',
    initials: 'MG',
    code: '3166956',
  },
  {
    name: 'SERRANÓPOLIS DO IGUAÇU',
    initials: 'PR',
    code: '4126355',
  },
  {
    name: 'SERRANOS',
    initials: 'MG',
    code: '3167004',
  },
  {
    name: 'SERRA NOVA DOURADA',
    initials: 'MT',
    code: '5107883',
  },
  {
    name: 'SERRA PRETA',
    initials: 'BA',
    code: '2930402',
  },
  {
    name: 'SERRA REDONDA',
    initials: 'PB',
    code: '2515807',
  },
  {
    name: 'SERRARIA',
    initials: 'PB',
    code: '2515906',
  },
  {
    name: 'SERRA TALHADA',
    initials: 'PE',
    code: '2613909',
  },
  {
    name: 'SERRINHA',
    initials: 'BA',
    code: '2930501',
  },
  {
    name: 'SERRINHA',
    initials: 'RN',
    code: '2413508',
  },
  {
    name: 'SERRINHA DOS PINTOS',
    initials: 'RN',
    code: '2413557',
  },
  {
    name: 'SERRITA',
    initials: 'PE',
    code: '2614006',
  },
  {
    name: 'SERRO',
    initials: 'MG',
    code: '3167103',
  },
  {
    name: 'SERROLÂNDIA',
    initials: 'BA',
    code: '2930600',
  },
  {
    name: 'SERTANEJA',
    initials: 'PR',
    code: '4126405',
  },
  {
    name: 'SERTÂNIA',
    initials: 'PE',
    code: '2614105',
  },
  {
    name: 'SERTANÓPOLIS',
    initials: 'PR',
    code: '4126504',
  },
  {
    name: 'SERTÃO',
    initials: 'RS',
    code: '4320503',
  },
  {
    name: 'SERTÃO SANTANA',
    initials: 'RS',
    code: '4320552',
  },
  {
    name: 'SERTÃOZINHO',
    initials: 'PB',
    code: '2515930',
  },
  {
    name: 'SERTÃOZINHO',
    initials: 'SP',
    code: '3551702',
  },
  {
    name: 'SETE BARRAS',
    initials: 'SP',
    code: '3551801',
  },
  {
    name: 'SETE DE SETEMBRO',
    initials: 'RS',
    code: '4320578',
  },
  {
    name: 'SETE LAGOAS',
    initials: 'MG',
    code: '3167202',
  },
  {
    name: 'SETE QUEDAS',
    initials: 'MS',
    code: '5007703',
  },
  {
    name: 'SETUBINHA',
    initials: 'MG',
    code: '3165552',
  },
  {
    name: 'SEVERIANO DE ALMEIDA',
    initials: 'RS',
    code: '4320602',
  },
  {
    name: 'SEVERIANO MELO',
    initials: 'RN',
    code: '2413607',
  },
  {
    name: 'SEVERÍNIA',
    initials: 'SP',
    code: '3551900',
  },
  {
    name: 'SIDERÓPOLIS',
    initials: 'SC',
    code: '4217600',
  },
  {
    name: 'SIDROLÂNDIA',
    initials: 'MS',
    code: '5007901',
  },
  {
    name: 'SIGEFREDO PACHECO',
    initials: 'PI',
    code: '2210656',
  },
  {
    name: 'SILVA JARDIM',
    initials: 'RJ',
    code: '3305604',
  },
  {
    name: 'SILVÂNIA',
    initials: 'GO',
    code: '5220603',
  },
  {
    name: 'SILVANÓPOLIS',
    initials: 'TO',
    code: '1720655',
  },
  {
    name: 'SILVEIRA MARTINS',
    initials: 'RS',
    code: '4320651',
  },
  {
    name: 'SILVEIRÂNIA',
    initials: 'MG',
    code: '3167301',
  },
  {
    name: 'SILVEIRAS',
    initials: 'SP',
    code: '3552007',
  },
  {
    name: 'SILVES',
    initials: 'AM',
    code: '1304005',
  },
  {
    name: 'SILVIANÓPOLIS',
    initials: 'MG',
    code: '3167400',
  },
  {
    name: 'SIMÃO DIAS',
    initials: 'SE',
    code: '2807105',
  },
  {
    name: 'SIMÃO PEREIRA',
    initials: 'MG',
    code: '3167509',
  },
  {
    name: 'SIMÕES',
    initials: 'PI',
    code: '2210706',
  },
  {
    name: 'SIMÕES FILHO',
    initials: 'BA',
    code: '2930709',
  },
  {
    name: 'SIMOLÂNDIA',
    initials: 'GO',
    code: '5220686',
  },
  {
    name: 'SIMONÉSIA',
    initials: 'MG',
    code: '3167608',
  },
  {
    name: 'SIMPLÍCIO MENDES',
    initials: 'PI',
    code: '2210805',
  },
  {
    name: 'SINIMBU',
    initials: 'RS',
    code: '4320677',
  },
  {
    name: 'SINOP',
    initials: 'MT',
    code: '5107909',
  },
  {
    name: 'SIQUEIRA CAMPOS',
    initials: 'PR',
    code: '4126603',
  },
  {
    name: 'SIRINHAÉM',
    initials: 'PE',
    code: '2614204',
  },
  {
    name: 'SIRIRI',
    initials: 'SE',
    code: '2807204',
  },
  {
    name: 'SÍTIO D\'ABADIA',
    initials: 'GO',
    code: '5220702',
  },
  {
    name: 'SÍTIO DO MATO',
    initials: 'BA',
    code: '2930758',
  },
  {
    name: 'SÍTIO DO QUINTO',
    initials: 'BA',
    code: '2930766',
  },
  {
    name: 'SÍTIO NOVO',
    initials: 'MA',
    code: '2111805',
  },
  {
    name: 'SÍTIO NOVO',
    initials: 'RN',
    code: '2413706',
  },
  {
    name: 'SÍTIO NOVO DO TOCANTINS',
    initials: 'TO',
    code: '1720804',
  },
  {
    name: 'SOBRADINHO',
    initials: 'RS',
    code: '4320701',
  },
  {
    name: 'SOBRADINHO',
    initials: 'BA',
    code: '2930774',
  },
  {
    name: 'SOBRADO',
    initials: 'PB',
    code: '2515971',
  },
  {
    name: 'SOBRAL',
    initials: 'CE',
    code: '2312908',
  },
  {
    name: 'SOBRÁLIA',
    initials: 'MG',
    code: '3167707',
  },
  {
    name: 'SOCORRO',
    initials: 'SP',
    code: '3552106',
  },
  {
    name: 'SOCORRO DO PIAUÍ',
    initials: 'PI',
    code: '2210904',
  },
  {
    name: 'SOLÂNEA',
    initials: 'PB',
    code: '2516003',
  },
  {
    name: 'SOLEDADE',
    initials: 'PB',
    code: '2516102',
  },
  {
    name: 'SOLEDADE',
    initials: 'RS',
    code: '4320800',
  },
  {
    name: 'SOLEDADE DE MINAS',
    initials: 'MG',
    code: '3167806',
  },
  {
    name: 'SOLIDÃO',
    initials: 'PE',
    code: '2614402',
  },
  {
    name: 'SOLONÓPOLE',
    initials: 'CE',
    code: '2313005',
  },
  {
    name: 'SOMBRIO',
    initials: 'SC',
    code: '4217709',
  },
  {
    name: 'SONORA',
    initials: 'MS',
    code: '5007935',
  },
  {
    name: 'SOORETAMA',
    initials: 'ES',
    code: '3205010',
  },
  {
    name: 'SOROCABA',
    initials: 'SP',
    code: '3552205',
  },
  {
    name: 'SORRISO',
    initials: 'MT',
    code: '5107925',
  },
  {
    name: 'SOSSEGO',
    initials: 'PB',
    code: '2516151',
  },
  {
    name: 'SOURE',
    initials: 'PA',
    code: '1507904',
  },
  {
    name: 'SOUSA',
    initials: 'PB',
    code: '2516201',
  },
  {
    name: 'SOUTO SOARES',
    initials: 'BA',
    code: '2930808',
  },
  {
    name: 'SUCUPIRA',
    initials: 'TO',
    code: '1720853',
  },
  {
    name: 'SUCUPIRA DO NORTE',
    initials: 'MA',
    code: '2111904',
  },
  {
    name: 'SUCUPIRA DO RIACHÃO',
    initials: 'MA',
    code: '2111953',
  },
  {
    name: 'SUD MENNUCCI',
    initials: 'SP',
    code: '3552304',
  },
  {
    name: 'SUL BRASIL',
    initials: 'SC',
    code: '4217758',
  },
  {
    name: 'SULINA',
    initials: 'PR',
    code: '4126652',
  },
  {
    name: 'SUMARÉ',
    initials: 'SP',
    code: '3552403',
  },
  {
    name: 'SUMÉ',
    initials: 'PB',
    code: '2516300',
  },
  {
    name: 'SUMIDOURO',
    initials: 'RJ',
    code: '3305703',
  },
  {
    name: 'SURUBIM',
    initials: 'PE',
    code: '2614501',
  },
  {
    name: 'SUSSUAPARA',
    initials: 'PI',
    code: '2210938',
  },
  {
    name: 'SUZANÁPOLIS',
    initials: 'SP',
    code: '3552551',
  },
  {
    name: 'SUZANO',
    initials: 'SP',
    code: '3552502',
  },
  {
    name: 'TABAÍ',
    initials: 'RS',
    code: '4320859',
  },
  {
    name: 'TABAPORÃ',
    initials: 'MT',
    code: '5107941',
  },
  {
    name: 'TABAPUÃ',
    initials: 'SP',
    code: '3552601',
  },
  {
    name: 'TABATINGA',
    initials: 'AM',
    code: '1304062',
  },
  {
    name: 'TABATINGA',
    initials: 'SP',
    code: '3552700',
  },
  {
    name: 'TABIRA',
    initials: 'PE',
    code: '2614600',
  },
  {
    name: 'TABOÃO DA SERRA',
    initials: 'SP',
    code: '3552809',
  },
  {
    name: 'TABOCAS DO BREJO VELHO',
    initials: 'BA',
    code: '2930907',
  },
  {
    name: 'TABOLEIRO GRANDE',
    initials: 'RN',
    code: '2413805',
  },
  {
    name: 'TABULEIRO',
    initials: 'MG',
    code: '3167905',
  },
  {
    name: 'TABULEIRO DO NORTE',
    initials: 'CE',
    code: '2313104',
  },
  {
    name: 'TACAIMBÓ',
    initials: 'PE',
    code: '2614709',
  },
  {
    name: 'TACARATU',
    initials: 'PE',
    code: '2614808',
  },
  {
    name: 'TACIBA',
    initials: 'SP',
    code: '3552908',
  },
  {
    name: 'TACIMA',
    initials: 'PB',
    code: '2516409',
  },
  {
    name: 'TACURU',
    initials: 'MS',
    code: '5007950',
  },
  {
    name: 'TAGUAÍ',
    initials: 'SP',
    code: '3553005',
  },
  {
    name: 'TAGUATINGA',
    initials: 'TO',
    code: '1720903',
  },
  {
    name: 'TAIAÇU',
    initials: 'SP',
    code: '3553104',
  },
  {
    name: 'TAILÂNDIA',
    initials: 'PA',
    code: '1507953',
  },
  {
    name: 'TAIÓ',
    initials: 'SC',
    code: '4217808',
  },
  {
    name: 'TAIOBEIRAS',
    initials: 'MG',
    code: '3168002',
  },
  {
    name: 'TAIPAS DO TOCANTINS',
    initials: 'TO',
    code: '1720937',
  },
  {
    name: 'TAIPU',
    initials: 'RN',
    code: '2413904',
  },
  {
    name: 'TAIÚVA',
    initials: 'SP',
    code: '3553203',
  },
  {
    name: 'TALISMÃ',
    initials: 'TO',
    code: '1720978',
  },
  {
    name: 'TAMANDARÉ',
    initials: 'PE',
    code: '2614857',
  },
  {
    name: 'TAMARANA',
    initials: 'PR',
    code: '4126678',
  },
  {
    name: 'TAMBAÚ',
    initials: 'SP',
    code: '3553302',
  },
  {
    name: 'TAMBOARA',
    initials: 'PR',
    code: '4126702',
  },
  {
    name: 'TAMBORIL',
    initials: 'CE',
    code: '2313203',
  },
  {
    name: 'TAMBORIL DO PIAUÍ',
    initials: 'PI',
    code: '2210953',
  },
  {
    name: 'TANABI',
    initials: 'SP',
    code: '3553401',
  },
  {
    name: 'TANGARÁ',
    initials: 'SC',
    code: '4217907',
  },
  {
    name: 'TANGARÁ',
    initials: 'RN',
    code: '2414001',
  },
  {
    name: 'TANGARÁ DA SERRA',
    initials: 'MT',
    code: '5107958',
  },
  {
    name: 'TANGUÁ',
    initials: 'RJ',
    code: '3305752',
  },
  {
    name: 'TANHAÇU',
    initials: 'BA',
    code: '2931004',
  },
  {
    name: 'TANQUE D\'ARCA',
    initials: 'AL',
    code: '2709004',
  },
  {
    name: 'TANQUE DO PIAUÍ',
    initials: 'PI',
    code: '2210979',
  },
  {
    name: 'TANQUE NOVO',
    initials: 'BA',
    code: '2931053',
  },
  {
    name: 'TANQUINHO',
    initials: 'BA',
    code: '2931103',
  },
  {
    name: 'TAPARUBA',
    initials: 'MG',
    code: '3168051',
  },
  {
    name: 'TAPAUÁ',
    initials: 'AM',
    code: '1304104',
  },
  {
    name: 'TAPEJARA',
    initials: 'PR',
    code: '4126801',
  },
  {
    name: 'TAPEJARA',
    initials: 'RS',
    code: '4320909',
  },
  {
    name: 'TAPERA',
    initials: 'RS',
    code: '4321006',
  },
  {
    name: 'TAPEROÁ',
    initials: 'PB',
    code: '2516508',
  },
  {
    name: 'TAPEROÁ',
    initials: 'BA',
    code: '2931202',
  },
  {
    name: 'TAPES',
    initials: 'RS',
    code: '4321105',
  },
  {
    name: 'TAPIRA',
    initials: 'MG',
    code: '3168101',
  },
  {
    name: 'TAPIRA',
    initials: 'PR',
    code: '4126900',
  },
  {
    name: 'TAPIRAÍ',
    initials: 'MG',
    code: '3168200',
  },
  {
    name: 'TAPIRAÍ',
    initials: 'SP',
    code: '3553500',
  },
  {
    name: 'TAPIRAMUTÁ',
    initials: 'BA',
    code: '2931301',
  },
  {
    name: 'TAPIRATIBA',
    initials: 'SP',
    code: '3553609',
  },
  {
    name: 'TAPURAH',
    initials: 'MT',
    code: '5108006',
  },
  {
    name: 'TAQUARA',
    initials: 'RS',
    code: '4321204',
  },
  {
    name: 'TAQUARAÇU DE MINAS',
    initials: 'MG',
    code: '3168309',
  },
  {
    name: 'TAQUARAL',
    initials: 'SP',
    code: '3553658',
  },
  {
    name: 'TAQUARAL DE GOIÁS',
    initials: 'GO',
    code: '5221007',
  },
  {
    name: 'TAQUARANA',
    initials: 'AL',
    code: '2709103',
  },
  {
    name: 'TAQUARI',
    initials: 'RS',
    code: '4321303',
  },
  {
    name: 'TAQUARITINGA',
    initials: 'SP',
    code: '3553708',
  },
  {
    name: 'TAQUARITINGA DO NORTE',
    initials: 'PE',
    code: '2615003',
  },
  {
    name: 'TAQUARITUBA',
    initials: 'SP',
    code: '3553807',
  },
  {
    name: 'TAQUARIVAÍ',
    initials: 'SP',
    code: '3553856',
  },
  {
    name: 'TAQUARUÇU DO SUL',
    initials: 'RS',
    code: '4321329',
  },
  {
    name: 'TAQUARUSSU',
    initials: 'MS',
    code: '5007976',
  },
  {
    name: 'TARABAI',
    initials: 'SP',
    code: '3553906',
  },
  {
    name: 'TARAUACÁ',
    initials: 'AC',
    code: '1200609',
  },
  {
    name: 'TARRAFAS',
    initials: 'CE',
    code: '2313252',
  },
  {
    name: 'TARTARUGALZINHO',
    initials: 'AP',
    code: '1600709',
  },
  {
    name: 'TARUMÃ',
    initials: 'SP',
    code: '3553955',
  },
  {
    name: 'TARUMIRIM',
    initials: 'MG',
    code: '3168408',
  },
  {
    name: 'TASSO FRAGOSO',
    initials: 'MA',
    code: '2112001',
  },
  {
    name: 'TATUÍ',
    initials: 'SP',
    code: '3554003',
  },
  {
    name: 'TAUÁ',
    initials: 'CE',
    code: '2313302',
  },
  {
    name: 'TAUBATÉ',
    initials: 'SP',
    code: '3554102',
  },
  {
    name: 'TAVARES',
    initials: 'RS',
    code: '4321352',
  },
  {
    name: 'TAVARES',
    initials: 'PB',
    code: '2516607',
  },
  {
    name: 'TEFÉ',
    initials: 'AM',
    code: '1304203',
  },
  {
    name: 'TEIXEIRA',
    initials: 'PB',
    code: '2516706',
  },
  {
    name: 'TEIXEIRA DE FREITAS',
    initials: 'BA',
    code: '2931350',
  },
  {
    name: 'TEIXEIRAS',
    initials: 'MG',
    code: '3168507',
  },
  {
    name: 'TEIXEIRA SOARES',
    initials: 'PR',
    code: '4127007',
  },
  {
    name: 'TEIXEIRÓPOLIS',
    initials: 'RO',
    code: '1101559',
  },
  {
    name: 'TEJUÇUOCA',
    initials: 'CE',
    code: '2313351',
  },
  {
    name: 'TEJUPÁ',
    initials: 'SP',
    code: '3554201',
  },
  {
    name: 'TELÊMACO BORBA',
    initials: 'PR',
    code: '4127106',
  },
  {
    name: 'TELHA',
    initials: 'SE',
    code: '2807303',
  },
  {
    name: 'TENENTE ANANIAS',
    initials: 'RN',
    code: '2414100',
  },
  {
    name: 'TENENTE LAURENTINO CRUZ',
    initials: 'RN',
    code: '2414159',
  },
  {
    name: 'TENENTE PORTELA',
    initials: 'RS',
    code: '4321402',
  },
  {
    name: 'TENÓRIO',
    initials: 'PB',
    code: '2516755',
  },
  {
    name: 'TEODORO SAMPAIO',
    initials: 'BA',
    code: '2931400',
  },
  {
    name: 'TEODORO SAMPAIO',
    initials: 'SP',
    code: '3554300',
  },
  {
    name: 'TEOFILÂNDIA',
    initials: 'BA',
    code: '2931509',
  },
  {
    name: 'TEÓFILO OTONI',
    initials: 'MG',
    code: '3168606',
  },
  {
    name: 'TEOLÂNDIA',
    initials: 'BA',
    code: '2931608',
  },
  {
    name: 'TEOTÔNIO VILELA',
    initials: 'AL',
    code: '2709152',
  },
  {
    name: 'TERENOS',
    initials: 'MS',
    code: '5008008',
  },
  {
    name: 'TERESINA',
    initials: 'PI',
    code: '2211001',
  },
  {
    name: 'TERESINA DE GOIÁS',
    initials: 'GO',
    code: '5221080',
  },
  {
    name: 'TERESÓPOLIS',
    initials: 'RJ',
    code: '3305802',
  },
  {
    name: 'TEREZINHA',
    initials: 'PE',
    code: '2615102',
  },
  {
    name: 'TEREZÓPOLIS DE GOIÁS',
    initials: 'GO',
    code: '5221197',
  },
  {
    name: 'TERRA ALTA',
    initials: 'PA',
    code: '1507961',
  },
  {
    name: 'TERRA BOA',
    initials: 'PR',
    code: '4127205',
  },
  {
    name: 'TERRA DE AREIA',
    initials: 'RS',
    code: '4321436',
  },
  {
    name: 'TERRA NOVA',
    initials: 'PE',
    code: '2615201',
  },
  {
    name: 'TERRA NOVA',
    initials: 'BA',
    code: '2931707',
  },
  {
    name: 'TERRA NOVA DO NORTE',
    initials: 'MT',
    code: '5108055',
  },
  {
    name: 'TERRA RICA',
    initials: 'PR',
    code: '4127304',
  },
  {
    name: 'TERRA ROXA',
    initials: 'SP',
    code: '3554409',
  },
  {
    name: 'TERRA ROXA',
    initials: 'PR',
    code: '4127403',
  },
  {
    name: 'TERRA SANTA',
    initials: 'PA',
    code: '1507979',
  },
  {
    name: 'TESOURO',
    initials: 'MT',
    code: '5108105',
  },
  {
    name: 'TEUTÔNIA',
    initials: 'RS',
    code: '4321451',
  },
  {
    name: 'THEOBROMA',
    initials: 'RO',
    code: '1101609',
  },
  {
    name: 'TIANGUÁ',
    initials: 'CE',
    code: '2313401',
  },
  {
    name: 'TIBAGI',
    initials: 'PR',
    code: '4127502',
  },
  {
    name: 'TIBAU',
    initials: 'RN',
    code: '2411056',
  },
  {
    name: 'TIBAU DO SUL',
    initials: 'RN',
    code: '2414209',
  },
  {
    name: 'TIETÊ',
    initials: 'SP',
    code: '3554508',
  },
  {
    name: 'TIGRINHOS',
    initials: 'SC',
    code: '4217956',
  },
  {
    name: 'TIJUCAS',
    initials: 'SC',
    code: '4218004',
  },
  {
    name: 'TIJUCAS DO SUL',
    initials: 'PR',
    code: '4127601',
  },
  {
    name: 'TIMBAÚBA',
    initials: 'PE',
    code: '2615300',
  },
  {
    name: 'TIMBAÚBA DOS BATISTAS',
    initials: 'RN',
    code: '2414308',
  },
  {
    name: 'TIMBÉ DO SUL',
    initials: 'SC',
    code: '4218103',
  },
  {
    name: 'TIMBIRAS',
    initials: 'MA',
    code: '2112100',
  },
  {
    name: 'TIMBÓ',
    initials: 'SC',
    code: '4218202',
  },
  {
    name: 'TIMBÓ GRANDE',
    initials: 'SC',
    code: '4218251',
  },
  {
    name: 'TIMBURI',
    initials: 'SP',
    code: '3554607',
  },
  {
    name: 'TIMON',
    initials: 'MA',
    code: '2112209',
  },
  {
    name: 'TIMÓTEO',
    initials: 'MG',
    code: '3168705',
  },
  {
    name: 'TIO HUGO',
    initials: 'RS',
    code: '4321469',
  },
  {
    name: 'TIRADENTES',
    initials: 'MG',
    code: '3168804',
  },
  {
    name: 'TIRADENTES DO SUL',
    initials: 'RS',
    code: '4321477',
  },
  {
    name: 'TIROS',
    initials: 'MG',
    code: '3168903',
  },
  {
    name: 'TOBIAS BARRETO',
    initials: 'SE',
    code: '2807402',
  },
  {
    name: 'TOCANTÍNIA',
    initials: 'TO',
    code: '1721109',
  },
  {
    name: 'TOCANTINÓPOLIS',
    initials: 'TO',
    code: '1721208',
  },
  {
    name: 'TOCANTINS',
    initials: 'MG',
    code: '3169000',
  },
  {
    name: 'TOCOS DO MOJI',
    initials: 'MG',
    code: '3169059',
  },
  {
    name: 'TOLEDO',
    initials: 'PR',
    code: '4127700',
  },
  {
    name: 'TOLEDO',
    initials: 'MG',
    code: '3169109',
  },
  {
    name: 'TOMAR DO GERU',
    initials: 'SE',
    code: '2807501',
  },
  {
    name: 'TOMAZINA',
    initials: 'PR',
    code: '4127809',
  },
  {
    name: 'TOMBOS',
    initials: 'MG',
    code: '3169208',
  },
  {
    name: 'TOMÉ-AÇÚ',
    initials: 'PA',
    code: '1508001',
  },
  {
    name: 'TONANTINS',
    initials: 'AM',
    code: '1304237',
  },
  {
    name: 'TORITAMA',
    initials: 'PE',
    code: '2615409',
  },
  {
    name: 'TORIXORÉU',
    initials: 'MT',
    code: '5108204',
  },
  {
    name: 'TOROPI',
    initials: 'RS',
    code: '4321493',
  },
  {
    name: 'TORRE DE PEDRA',
    initials: 'SP',
    code: '3554656',
  },
  {
    name: 'TORRES',
    initials: 'RS',
    code: '4321501',
  },
  {
    name: 'TORRINHA',
    initials: 'SP',
    code: '3554706',
  },
  {
    name: 'TOUROS',
    initials: 'RN',
    code: '2414407',
  },
  {
    name: 'TRABIJU',
    initials: 'SP',
    code: '3554755',
  },
  {
    name: 'TRACUATEUA',
    initials: 'PA',
    code: '1508035',
  },
  {
    name: 'TRACUNHAÉM',
    initials: 'PE',
    code: '2615508',
  },
  {
    name: 'TRAIPU',
    initials: 'AL',
    code: '2709202',
  },
  {
    name: 'TRAIRÃO',
    initials: 'PA',
    code: '1508050',
  },
  {
    name: 'TRAIRI',
    initials: 'CE',
    code: '2313500',
  },
  {
    name: 'TRAJANO DE MORAES',
    initials: 'RJ',
    code: '3305901',
  },
  {
    name: 'TRAMANDAÍ',
    initials: 'RS',
    code: '4321600',
  },
  {
    name: 'TRAVESSEIRO',
    initials: 'RS',
    code: '4321626',
  },
  {
    name: 'TREMEDAL',
    initials: 'BA',
    code: '2931806',
  },
  {
    name: 'TREMEMBÉ',
    initials: 'SP',
    code: '3554805',
  },
  {
    name: 'TRÊS ARROIOS',
    initials: 'RS',
    code: '4321634',
  },
  {
    name: 'TRÊS BARRAS',
    initials: 'SC',
    code: '4218301',
  },
  {
    name: 'TRÊS BARRAS DO PARANÁ',
    initials: 'PR',
    code: '4127858',
  },
  {
    name: 'TRÊS CACHOEIRAS',
    initials: 'RS',
    code: '4321667',
  },
  {
    name: 'TRÊS CORAÇÕES',
    initials: 'MG',
    code: '3169307',
  },
  {
    name: 'TRÊS COROAS',
    initials: 'RS',
    code: '4321709',
  },
  {
    name: 'TRÊS DE MAIO',
    initials: 'RS',
    code: '4321808',
  },
  {
    name: 'TRÊS FORQUILHAS',
    initials: 'RS',
    code: '4321832',
  },
  {
    name: 'TRÊS FRONTEIRAS',
    initials: 'SP',
    code: '3554904',
  },
  {
    name: 'TRÊS LAGOAS',
    initials: 'MS',
    code: '5008305',
  },
  {
    name: 'TRÊS MARIAS',
    initials: 'MG',
    code: '3169356',
  },
  {
    name: 'TRÊS PALMEIRAS',
    initials: 'RS',
    code: '4321857',
  },
  {
    name: 'TRÊS PASSOS',
    initials: 'RS',
    code: '4321907',
  },
  {
    name: 'TRÊS PONTAS',
    initials: 'MG',
    code: '3169406',
  },
  {
    name: 'TRÊS RANCHOS',
    initials: 'GO',
    code: '5221304',
  },
  {
    name: 'TRÊS RIOS',
    initials: 'RJ',
    code: '3306008',
  },
  {
    name: 'TREVISO',
    initials: 'SC',
    code: '4218350',
  },
  {
    name: 'TREZE DE MAIO',
    initials: 'SC',
    code: '4218400',
  },
  {
    name: 'TREZE TÍLIAS',
    initials: 'SC',
    code: '4218509',
  },
  {
    name: 'TRINDADE',
    initials: 'GO',
    code: '5221403',
  },
  {
    name: 'TRINDADE',
    initials: 'PE',
    code: '2615607',
  },
  {
    name: 'TRINDADE DO SUL',
    initials: 'RS',
    code: '4321956',
  },
  {
    name: 'TRIUNFO',
    initials: 'PB',
    code: '2516805',
  },
  {
    name: 'TRIUNFO',
    initials: 'RS',
    code: '4322004',
  },
  {
    name: 'TRIUNFO',
    initials: 'PE',
    code: '2615706',
  },
  {
    name: 'TRIUNFO POTIGUAR',
    initials: 'RN',
    code: '2414456',
  },
  {
    name: 'TRIZIDELA DO VALE',
    initials: 'MA',
    code: '2112233',
  },
  {
    name: 'TROMBAS',
    initials: 'GO',
    code: '5221452',
  },
  {
    name: 'TROMBUDO CENTRAL',
    initials: 'SC',
    code: '4218608',
  },
  {
    name: 'TUBARÃO',
    initials: 'SC',
    code: '4218707',
  },
  {
    name: 'TUCANO',
    initials: 'BA',
    code: '2931905',
  },
  {
    name: 'TUCUMÃ',
    initials: 'PA',
    code: '1508084',
  },
  {
    name: 'TUCUNDUVA',
    initials: 'RS',
    code: '4322103',
  },
  {
    name: 'TUCURUÍ',
    initials: 'PA',
    code: '1508100',
  },
  {
    name: 'TUFILÂNDIA',
    initials: 'MA',
    code: '2112274',
  },
  {
    name: 'TUIUTI',
    initials: 'SP',
    code: '3554953',
  },
  {
    name: 'TUMIRITINGA',
    initials: 'MG',
    code: '3169505',
  },
  {
    name: 'TUNÁPOLIS',
    initials: 'SC',
    code: '4218756',
  },
  {
    name: 'TUNAS',
    initials: 'RS',
    code: '4322152',
  },
  {
    name: 'TUNAS DO PARANÁ',
    initials: 'PR',
    code: '4127882',
  },
  {
    name: 'TUNEIRAS DO OESTE',
    initials: 'PR',
    code: '4127908',
  },
  {
    name: 'TUNTUM',
    initials: 'MA',
    code: '2112308',
  },
  {
    name: 'TUPÃ',
    initials: 'SP',
    code: '3555000',
  },
  {
    name: 'TUPACIGUARA',
    initials: 'MG',
    code: '3169604',
  },
  {
    name: 'TUPANATINGA',
    initials: 'PE',
    code: '2615805',
  },
  {
    name: 'TUPANCI DO SUL',
    initials: 'RS',
    code: '4322186',
  },
  {
    name: 'TUPANCIRETÃ',
    initials: 'RS',
    code: '4322202',
  },
  {
    name: 'TUPANDI',
    initials: 'RS',
    code: '4322251',
  },
  {
    name: 'TUPARENDI',
    initials: 'RS',
    code: '4322301',
  },
  {
    name: 'TUPARETAMA',
    initials: 'PE',
    code: '2615904',
  },
  {
    name: 'TUPÃSSI',
    initials: 'PR',
    code: '4127957',
  },
  {
    name: 'TUPI PAULISTA',
    initials: 'SP',
    code: '3555109',
  },
  {
    name: 'TUPIRAMA',
    initials: 'TO',
    code: '1721257',
  },
  {
    name: 'TUPIRATINS',
    initials: 'TO',
    code: '1721307',
  },
  {
    name: 'TURIAÇU',
    initials: 'MA',
    code: '2112407',
  },
  {
    name: 'TURILÂNDIA',
    initials: 'MA',
    code: '2112456',
  },
  {
    name: 'TURIÚBA',
    initials: 'SP',
    code: '3555208',
  },
  {
    name: 'TURMALINA',
    initials: 'MG',
    code: '3169703',
  },
  {
    name: 'TURMALINA',
    initials: 'SP',
    code: '3555307',
  },
  {
    name: 'TURUÇU',
    initials: 'RS',
    code: '4322327',
  },
  {
    name: 'TURURU',
    initials: 'CE',
    code: '2313559',
  },
  {
    name: 'TURVÂNIA',
    initials: 'GO',
    code: '5221502',
  },
  {
    name: 'TURVELÂNDIA',
    initials: 'GO',
    code: '5221551',
  },
  {
    name: 'TURVO',
    initials: 'PR',
    code: '4127965',
  },
  {
    name: 'TURVO',
    initials: 'SC',
    code: '4218806',
  },
  {
    name: 'TURVOLÂNDIA',
    initials: 'MG',
    code: '3169802',
  },
  {
    name: 'TUTÓIA',
    initials: 'MA',
    code: '2112506',
  },
  {
    name: 'UARINI',
    initials: 'AM',
    code: '1304260',
  },
  {
    name: 'UAUÁ',
    initials: 'BA',
    code: '2932002',
  },
  {
    name: 'UBÁ',
    initials: 'MG',
    code: '3169901',
  },
  {
    name: 'UBAÍ',
    initials: 'MG',
    code: '3170008',
  },
  {
    name: 'UBAÍRA',
    initials: 'BA',
    code: '2932101',
  },
  {
    name: 'UBAITABA',
    initials: 'BA',
    code: '2932200',
  },
  {
    name: 'UBAJARA',
    initials: 'CE',
    code: '2313609',
  },
  {
    name: 'UBAPORANGA',
    initials: 'MG',
    code: '3170057',
  },
  {
    name: 'UBARANA',
    initials: 'SP',
    code: '3555356',
  },
  {
    name: 'UBATÃ',
    initials: 'BA',
    code: '2932309',
  },
  {
    name: 'UBATUBA',
    initials: 'SP',
    code: '3555406',
  },
  {
    name: 'UBERABA',
    initials: 'MG',
    code: '3170107',
  },
  {
    name: 'UBERLÂNDIA',
    initials: 'MG',
    code: '3170206',
  },
  {
    name: 'UBIRAJARA',
    initials: 'SP',
    code: '3555505',
  },
  {
    name: 'UBIRATÃ',
    initials: 'PR',
    code: '4128005',
  },
  {
    name: 'UBIRETAMA',
    initials: 'RS',
    code: '4322343',
  },
  {
    name: 'UCHOA',
    initials: 'SP',
    code: '3555604',
  },
  {
    name: 'UIBAÍ',
    initials: 'BA',
    code: '2932408',
  },
  {
    name: 'UIRAMUTÃ',
    initials: 'RR',
    code: '1400704',
  },
  {
    name: 'UIRAPURU',
    initials: 'GO',
    code: '5221577',
  },
  {
    name: 'UIRAÚNA',
    initials: 'PB',
    code: '2516904',
  },
  {
    name: 'ULIANÓPOLIS',
    initials: 'PA',
    code: '1508126',
  },
  {
    name: 'UMARI',
    initials: 'CE',
    code: '2313708',
  },
  {
    name: 'UMARIZAL',
    initials: 'RN',
    code: '2414506',
  },
  {
    name: 'UMBAÚBA',
    initials: 'SE',
    code: '2807600',
  },
  {
    name: 'UMBURANAS',
    initials: 'BA',
    code: '2932457',
  },
  {
    name: 'UMBURATIBA',
    initials: 'MG',
    code: '3170305',
  },
  {
    name: 'UMBUZEIRO',
    initials: 'PB',
    code: '2517001',
  },
  {
    name: 'UMIRIM',
    initials: 'CE',
    code: '2313757',
  },
  {
    name: 'UMUARAMA',
    initials: 'PR',
    code: '4128104',
  },
  {
    name: 'UNA',
    initials: 'BA',
    code: '2932507',
  },
  {
    name: 'UNAÍ',
    initials: 'MG',
    code: '3170404',
  },
  {
    name: 'UNIÃO',
    initials: 'PI',
    code: '2211100',
  },
  {
    name: 'UNIÃO DA SERRA',
    initials: 'RS',
    code: '4322350',
  },
  {
    name: 'UNIÃO DA VITÓRIA',
    initials: 'PR',
    code: '4128203',
  },
  {
    name: 'UNIÃO DE MINAS',
    initials: 'MG',
    code: '3170438',
  },
  {
    name: 'UNIÃO DO OESTE',
    initials: 'SC',
    code: '4218855',
  },
  {
    name: 'UNIÃO DOS PALMARES',
    initials: 'AL',
    code: '2709301',
  },
  {
    name: 'UNIÃO DO SUL',
    initials: 'MT',
    code: '5108303',
  },
  {
    name: 'UNIÃO PAULISTA',
    initials: 'SP',
    code: '3555703',
  },
  {
    name: 'UNIFLOR',
    initials: 'PR',
    code: '4128302',
  },
  {
    name: 'UNISTALDA',
    initials: 'RS',
    code: '4322376',
  },
  {
    name: 'UPANEMA',
    initials: 'RN',
    code: '2414605',
  },
  {
    name: 'URAÍ',
    initials: 'PR',
    code: '4128401',
  },
  {
    name: 'URANDI',
    initials: 'BA',
    code: '2932606',
  },
  {
    name: 'URÂNIA',
    initials: 'SP',
    code: '3555802',
  },
  {
    name: 'URBANO SANTOS',
    initials: 'MA',
    code: '2112605',
  },
  {
    name: 'URU',
    initials: 'SP',
    code: '3555901',
  },
  {
    name: 'URUAÇU',
    initials: 'GO',
    code: '5221601',
  },
  {
    name: 'URUANA',
    initials: 'GO',
    code: '5221700',
  },
  {
    name: 'URUANA DE MINAS',
    initials: 'MG',
    code: '3170479',
  },
  {
    name: 'URUARÁ',
    initials: 'PA',
    code: '1508159',
  },
  {
    name: 'URUBICI',
    initials: 'SC',
    code: '4218905',
  },
  {
    name: 'URUBURETAMA',
    initials: 'CE',
    code: '2313807',
  },
  {
    name: 'URUCÂNIA',
    initials: 'MG',
    code: '3170503',
  },
  {
    name: 'URUCARÁ',
    initials: 'AM',
    code: '1304302',
  },
  {
    name: 'URUÇUCA',
    initials: 'BA',
    code: '2932705',
  },
  {
    name: 'URUÇUÍ',
    initials: 'PI',
    code: '2211209',
  },
  {
    name: 'URUCUIA',
    initials: 'MG',
    code: '3170529',
  },
  {
    name: 'URUCURITUBA',
    initials: 'AM',
    code: '1304401',
  },
  {
    name: 'URUGUAIANA',
    initials: 'RS',
    code: '4322400',
  },
  {
    name: 'URUOCA',
    initials: 'CE',
    code: '2313906',
  },
  {
    name: 'URUPÁ',
    initials: 'RO',
    code: '1101708',
  },
  {
    name: 'URUPEMA',
    initials: 'SC',
    code: '4218954',
  },
  {
    name: 'URUPÊS',
    initials: 'SP',
    code: '3556008',
  },
  {
    name: 'URUSSANGA',
    initials: 'SC',
    code: '4219002',
  },
  {
    name: 'URUTAÍ',
    initials: 'GO',
    code: '5221809',
  },
  {
    name: 'UTINGA',
    initials: 'BA',
    code: '2932804',
  },
  {
    name: 'VACARIA',
    initials: 'RS',
    code: '4322509',
  },
  {
    name: 'VALE DE SÃO DOMINGOS',
    initials: 'MT',
    code: '5108352',
  },
  {
    name: 'VALE DO ANARI',
    initials: 'RO',
    code: '1101757',
  },
  {
    name: 'VALE DO PARAÍSO',
    initials: 'RO',
    code: '1101807',
  },
  {
    name: 'VALE DO SOL',
    initials: 'RS',
    code: '4322533',
  },
  {
    name: 'VALENÇA',
    initials: 'BA',
    code: '2932903',
  },
  {
    name: 'VALENÇA',
    initials: 'RJ',
    code: '3306107',
  },
  {
    name: 'VALENÇA DO PIAUÍ',
    initials: 'PI',
    code: '2211308',
  },
  {
    name: 'VALENTE',
    initials: 'BA',
    code: '2933000',
  },
  {
    name: 'VALENTIM GENTIL',
    initials: 'SP',
    code: '3556107',
  },
  {
    name: 'VALE REAL',
    initials: 'RS',
    code: '4322541',
  },
  {
    name: 'VALE VERDE',
    initials: 'RS',
    code: '4322525',
  },
  {
    name: 'VALINHOS',
    initials: 'SP',
    code: '3556206',
  },
  {
    name: 'VALPARAÍSO',
    initials: 'SP',
    code: '3556305',
  },
  {
    name: 'VALPARAÍSO DE GOIÁS',
    initials: 'GO',
    code: '5221858',
  },
  {
    name: 'VANINI',
    initials: 'RS',
    code: '4322558',
  },
  {
    name: 'VARGEÃO',
    initials: 'SC',
    code: '4219101',
  },
  {
    name: 'VARGEM',
    initials: 'SP',
    code: '3556354',
  },
  {
    name: 'VARGEM',
    initials: 'SC',
    code: '4219150',
  },
  {
    name: 'VARGEM ALEGRE',
    initials: 'MG',
    code: '3170578',
  },
  {
    name: 'VARGEM ALTA',
    initials: 'ES',
    code: '3205036',
  },
  {
    name: 'VARGEM BONITA',
    initials: 'SC',
    code: '4219176',
  },
  {
    name: 'VARGEM BONITA',
    initials: 'MG',
    code: '3170602',
  },
  {
    name: 'VARGEM GRANDE',
    initials: 'MA',
    code: '2112704',
  },
  {
    name: 'VARGEM GRANDE DO RIO PARDO',
    initials: 'MG',
    code: '3170651',
  },
  {
    name: 'VARGEM GRANDE DO SUL',
    initials: 'SP',
    code: '3556404',
  },
  {
    name: 'VARGEM GRANDE PAULISTA',
    initials: 'SP',
    code: '3556453',
  },
  {
    name: 'VARGINHA',
    initials: 'MG',
    code: '3170701',
  },
  {
    name: 'VARJÃO',
    initials: 'GO',
    code: '5221908',
  },
  {
    name: 'VARJÃO DE MINAS',
    initials: 'MG',
    code: '3170750',
  },
  {
    name: 'VARJOTA',
    initials: 'CE',
    code: '2313955',
  },
  {
    name: 'VARRE-SAI',
    initials: 'RJ',
    code: '3306156',
  },
  {
    name: 'VÁRZEA',
    initials: 'PB',
    code: '2517100',
  },
  {
    name: 'VÁRZEA',
    initials: 'RN',
    code: '2414704',
  },
  {
    name: 'VÁRZEA ALEGRE',
    initials: 'CE',
    code: '2314003',
  },
  {
    name: 'VÁRZEA BRANCA',
    initials: 'PI',
    code: '2211357',
  },
  {
    name: 'VÁRZEA DA PALMA',
    initials: 'MG',
    code: '3170800',
  },
  {
    name: 'VÁRZEA DA ROÇA',
    initials: 'BA',
    code: '2933059',
  },
  {
    name: 'VÁRZEA DO POÇO',
    initials: 'BA',
    code: '2933109',
  },
  {
    name: 'VÁRZEA GRANDE',
    initials: 'MT',
    code: '5108402',
  },
  {
    name: 'VÁRZEA GRANDE',
    initials: 'PI',
    code: '2211407',
  },
  {
    name: 'VÁRZEA NOVA',
    initials: 'BA',
    code: '2933158',
  },
  {
    name: 'VÁRZEA PAULISTA',
    initials: 'SP',
    code: '3556503',
  },
  {
    name: 'VARZEDO',
    initials: 'BA',
    code: '2933174',
  },
  {
    name: 'VARZELÂNDIA',
    initials: 'MG',
    code: '3170909',
  },
  {
    name: 'VASSOURAS',
    initials: 'RJ',
    code: '3306206',
  },
  {
    name: 'VAZANTE',
    initials: 'MG',
    code: '3171006',
  },
  {
    name: 'VENÂNCIO AIRES',
    initials: 'RS',
    code: '4322608',
  },
  {
    name: 'VENDA NOVA DO IMIGRANTE',
    initials: 'ES',
    code: '3205069',
  },
  {
    name: 'VENHA-VER',
    initials: 'RN',
    code: '2414753',
  },
  {
    name: 'VENTANIA',
    initials: 'PR',
    code: '4128534',
  },
  {
    name: 'VENTUROSA',
    initials: 'PE',
    code: '2616001',
  },
  {
    name: 'VERA',
    initials: 'MT',
    code: '5108501',
  },
  {
    name: 'VERA CRUZ',
    initials: 'SP',
    code: '3556602',
  },
  {
    name: 'VERA CRUZ',
    initials: 'BA',
    code: '2933208',
  },
  {
    name: 'VERA CRUZ',
    initials: 'RN',
    code: '2414803',
  },
  {
    name: 'VERA CRUZ',
    initials: 'RS',
    code: '4322707',
  },
  {
    name: 'VERA CRUZ DO OESTE',
    initials: 'PR',
    code: '4128559',
  },
  {
    name: 'VERA MENDES',
    initials: 'PI',
    code: '2211506',
  },
  {
    name: 'VERANÓPOLIS',
    initials: 'RS',
    code: '4322806',
  },
  {
    name: 'VERDEJANTE',
    initials: 'PE',
    code: '2616100',
  },
  {
    name: 'VERDELÂNDIA',
    initials: 'MG',
    code: '3171030',
  },
  {
    name: 'VERÊ',
    initials: 'PR',
    code: '4128609',
  },
  {
    name: 'VEREDA',
    initials: 'BA',
    code: '2933257',
  },
  {
    name: 'VEREDINHA',
    initials: 'MG',
    code: '3171071',
  },
  {
    name: 'VERÍSSIMO',
    initials: 'MG',
    code: '3171105',
  },
  {
    name: 'VERMELHO NOVO',
    initials: 'MG',
    code: '3171154',
  },
  {
    name: 'VERTENTE DO LÉRIO',
    initials: 'PE',
    code: '2616183',
  },
  {
    name: 'VERTENTES',
    initials: 'PE',
    code: '2616209',
  },
  {
    name: 'VESPASIANO',
    initials: 'MG',
    code: '3171204',
  },
  {
    name: 'VESPASIANO CORREA',
    initials: 'RS',
    code: '4322855',
  },
  {
    name: 'VIADUTOS',
    initials: 'RS',
    code: '4322905',
  },
  {
    name: 'VIAMÃO',
    initials: 'RS',
    code: '4323002',
  },
  {
    name: 'VIANA',
    initials: 'ES',
    code: '3205101',
  },
  {
    name: 'VIANA',
    initials: 'MA',
    code: '2112803',
  },
  {
    name: 'VIANÓPOLIS',
    initials: 'GO',
    code: '5222005',
  },
  {
    name: 'VICÊNCIA',
    initials: 'PE',
    code: '2616308',
  },
  {
    name: 'VICENTE DUTRA',
    initials: 'RS',
    code: '4323101',
  },
  {
    name: 'VICENTINA',
    initials: 'MS',
    code: '5008404',
  },
  {
    name: 'VICENTINÓPOLIS',
    initials: 'GO',
    code: '5222054',
  },
  {
    name: 'VIÇOSA',
    initials: 'RN',
    code: '2414902',
  },
  {
    name: 'VIÇOSA',
    initials: 'AL',
    code: '2709400',
  },
  {
    name: 'VIÇOSA',
    initials: 'MG',
    code: '3171303',
  },
  {
    name: 'VIÇOSA DO CEARÁ',
    initials: 'CE',
    code: '2314102',
  },
  {
    name: 'VICTOR GRAEFF',
    initials: 'RS',
    code: '4323200',
  },
  {
    name: 'VIDAL RAMOS',
    initials: 'SC',
    code: '4219200',
  },
  {
    name: 'VIDEIRA',
    initials: 'SC',
    code: '4219309',
  },
  {
    name: 'VIEIRAS',
    initials: 'MG',
    code: '3171402',
  },
  {
    name: 'VIEIRÓPOLIS',
    initials: 'PB',
    code: '2517209',
  },
  {
    name: 'VIGIA',
    initials: 'PA',
    code: '1508209',
  },
  {
    name: 'VILA BELA DA SANTÍSSIMA TRINDADE',
    initials: 'MT',
    code: '5105507',
  },
  {
    name: 'VILA BOA',
    initials: 'GO',
    code: '5222203',
  },
  {
    name: 'VILA FLOR',
    initials: 'RN',
    code: '2415008',
  },
  {
    name: 'VILA FLORES',
    initials: 'RS',
    code: '4323309',
  },
  {
    name: 'VILA LÂNGARO',
    initials: 'RS',
    code: '4323358',
  },
  {
    name: 'VILA MARIA',
    initials: 'RS',
    code: '4323408',
  },
  {
    name: 'VILA NOVA DO PIAUÍ',
    initials: 'PI',
    code: '2211605',
  },
  {
    name: 'VILA NOVA DOS MARTÍRIOS',
    initials: 'MA',
    code: '2112852',
  },
  {
    name: 'VILA NOVA DO SUL',
    initials: 'RS',
    code: '4323457',
  },
  {
    name: 'VILA PAVÃO',
    initials: 'ES',
    code: '3205150',
  },
  {
    name: 'VILA PROPÍCIO',
    initials: 'GO',
    code: '5222302',
  },
  {
    name: 'VILA RICA',
    initials: 'MT',
    code: '5108600',
  },
  {
    name: 'VILA VALÉRIO',
    initials: 'ES',
    code: '3205176',
  },
  {
    name: 'VILA VELHA',
    initials: 'ES',
    code: '3205200',
  },
  {
    name: 'VILHENA',
    initials: 'RO',
    code: '1100304',
  },
  {
    name: 'VINHEDO',
    initials: 'SP',
    code: '3556701',
  },
  {
    name: 'VIRADOURO',
    initials: 'SP',
    code: '3556800',
  },
  {
    name: 'VIRGEM DA LAPA',
    initials: 'MG',
    code: '3171600',
  },
  {
    name: 'VIRGÍNIA',
    initials: 'MG',
    code: '3171709',
  },
  {
    name: 'VIRGINÓPOLIS',
    initials: 'MG',
    code: '3171808',
  },
  {
    name: 'VIRGOLÂNDIA',
    initials: 'MG',
    code: '3171907',
  },
  {
    name: 'VIRMOND',
    initials: 'PR',
    code: '4128658',
  },
  {
    name: 'VISCONDE DO RIO BRANCO',
    initials: 'MG',
    code: '3172004',
  },
  {
    name: 'VISEU',
    initials: 'PA',
    code: '1508308',
  },
  {
    name: 'VISTA ALEGRE',
    initials: 'RS',
    code: '4323507',
  },
  {
    name: 'VISTA ALEGRE DO ALTO',
    initials: 'SP',
    code: '3556909',
  },
  {
    name: 'VISTA ALEGRE DO PRATA',
    initials: 'RS',
    code: '4323606',
  },
  {
    name: 'VISTA GAÚCHA',
    initials: 'RS',
    code: '4323705',
  },
  {
    name: 'VISTA SERRANA',
    initials: 'PB',
    code: '2505501',
  },
  {
    name: 'VITÓRIA',
    initials: 'ES',
    code: '3205309',
  },
  {
    name: 'VITÓRIA BRASIL',
    initials: 'SP',
    code: '3556958',
  },
  {
    name: 'VITÓRIA DA CONQUISTA',
    initials: 'BA',
    code: '2933307',
  },
  {
    name: 'VITÓRIA DAS MISSÕES',
    initials: 'RS',
    code: '4323754',
  },
  {
    name: 'VITÓRIA DE SANTO ANTÃO',
    initials: 'PE',
    code: '2616407',
  },
  {
    name: 'VITÓRIA DO JARI',
    initials: 'AP',
    code: '1600808',
  },
  {
    name: 'VITÓRIA DO MEARIM',
    initials: 'MA',
    code: '2112902',
  },
  {
    name: 'VITÓRIA DO XINGU',
    initials: 'PA',
    code: '1508357',
  },
  {
    name: 'VITORINO',
    initials: 'PR',
    code: '4128708',
  },
  {
    name: 'VITORINO FREIRE',
    initials: 'MA',
    code: '2113009',
  },
  {
    name: 'VÍTOR MEIRELES',
    initials: 'SC',
    code: '4219358',
  },
  {
    name: 'VOLTA GRANDE',
    initials: 'MG',
    code: '3172103',
  },
  {
    name: 'VOLTA REDONDA',
    initials: 'RJ',
    code: '3306305',
  },
  {
    name: 'VOTORANTIM',
    initials: 'SP',
    code: '3557006',
  },
  {
    name: 'VOTUPORANGA',
    initials: 'SP',
    code: '3557105',
  },
  {
    name: 'WAGNER',
    initials: 'BA',
    code: '2933406',
  },
  {
    name: 'WALL FERRAZ',
    initials: 'PI',
    code: '2211704',
  },
  {
    name: 'WANDERLÂNDIA',
    initials: 'TO',
    code: '1722081',
  },
  {
    name: 'WANDERLEY',
    initials: 'BA',
    code: '2933455',
  },
  {
    name: 'WENCESLAU BRAZ',
    initials: 'MG',
    code: '3172202',
  },
  {
    name: 'WENCESLAU BRAZ',
    initials: 'PR',
    code: '4128500',
  },
  {
    name: 'WENCESLAU GUIMARÃES',
    initials: 'BA',
    code: '2933505',
  },
  {
    name: 'WESTFALIA',
    initials: 'RS',
    code: '4323770',
  },
  {
    name: 'WITMARSUM',
    initials: 'SC',
    code: '4219408',
  },
  {
    name: 'XAMBIOÁ',
    initials: 'TO',
    code: '1722107',
  },
  {
    name: 'XAMBRÊ',
    initials: 'PR',
    code: '4128807',
  },
  {
    name: 'XANGRI-LÁ',
    initials: 'RS',
    code: '4323804',
  },
  {
    name: 'XANXERÊ',
    initials: 'SC',
    code: '4219507',
  },
  {
    name: 'XAPURI',
    initials: 'AC',
    code: '1200708',
  },
  {
    name: 'XAVANTINA',
    initials: 'SC',
    code: '4219606',
  },
  {
    name: 'XAXIM',
    initials: 'SC',
    code: '4219705',
  },
  {
    name: 'XEXÉU',
    initials: 'PE',
    code: '2616506',
  },
  {
    name: 'XINGUARA',
    initials: 'PA',
    code: '1508407',
  },
  {
    name: 'XIQUE-XIQUE',
    initials: 'BA',
    code: '2933604',
  },
  {
    name: 'ZABELÊ',
    initials: 'PB',
    code: '2517407',
  },
  {
    name: 'ZACARIAS',
    initials: 'SP',
    code: '3557154',
  },
  {
    name: 'ZÉ DOCA',
    initials: 'MA',
    code: '2114007',
  },
  {
    name: 'ZORTÉA',
    initials: 'SC',
    code: '4219853',
  }
];

export default counties;