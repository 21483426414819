import React, { createContext, useEffect, useState } from 'react';
import Loading from '../../components/pages/public/Loading';
import { UserEntity } from '../../interfaces/entities/user.entity';
import AuthService from '../../services/AuthService';

export const AuthContext = createContext({
  user: null as any,
  setUser: null as any,
  logout: async (token: string) => { },
  accessToken: '',
  expiresIn: '',
  login: async (username: string, password: string) => { },
  isLoggedIn: false,
});

const AuthProvider = ({ children }: React.PropsWithChildren<React.ReactNode>): React.ReactElement => {

  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<UserEntity | null>(null);
  const [accessToken, setAccessToken] = useState('');
  const [expiresIn, setExpiresIn] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  async function login(username: string, password: string) {
    const response = await AuthService.login(username, password);
    localStorage.setItem('access_token', response.access_token);
    localStorage.setItem('expires_in', response.expires_in);
    setAccessToken(response.access_token);
    setExpiresIn(response.expires_in);
    localStorage.setItem('user', JSON.stringify(response.user));
    setUser(response.user);
    localStorage.setItem('isLogged_in', 'true');
    setIsLoggedIn(true);
  }

  async function logout(token: string) {
    setIsLoggedIn(false);
    localStorage.setItem('access_token', '');
    localStorage.setItem('expires_in', '');
    localStorage.setItem('isLogged_in', '');
    localStorage.setItem('user', '');
    await AuthService.logout(token);
  }
  useEffect(() => {
    setLoading(true);
    const accessToken = localStorage.getItem('access_token');
    const expiresIn = localStorage.getItem('expires_in');
    const isLoggedIn = localStorage.getItem('isLogged_in');
    const user = localStorage.getItem('user');

    setAccessToken(accessToken || '');
    setExpiresIn(expiresIn || '');
    if (!!user) {
      setUser(JSON.parse(user) || null);
    } else {
      setUser(null);
    }
    setIsLoggedIn(!!accessToken && !!expiresIn && !!isLoggedIn);
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        logout,
        accessToken,
        expiresIn,
        login,
        isLoggedIn,
      }}
    >
      {
        loading ? <Loading /> : children
      }
    </AuthContext.Provider>
  );
};

export default AuthProvider;
