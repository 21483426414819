const styles: any = {
  pageTitle: {
    color: '#0A0A0A59',
    fontWeight: 'bold',
    marginRight: '10px',
  },
  pageLine: {
    backgroundColor: '#0A0A0A59',
    flex: 1,
    height: 1,
  },
  containerForm: {
    padding: 30,
    justifyContent: 'center',
    backgroundColor: '#EEEEEE',
    border: '1px solid #0A0A0A59',
    borderRadius: 10,
  },
  buttonRegister: {
    marginLeft: '15px',
    color: '#fff',
    textTransform: 'capitalize',
    width: '100%',
  },
  buttonSelection: {
    marginLeft: '15px',
    backgroundColor: '#a11908',
    color: '#fff',
    textTransform: 'capitalize',
    "&:hover": {
      backgroundColor: '#C02321'
    }
  },
  divCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
  },
  gridCircularProgress: {
    display: 'flex',
    height: '80vh',
    justifyContent: 'center',
    alignItems: 'center'
  },
  circularProgress: {
    color: '#a11908'
  },
};

export default styles;