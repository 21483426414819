const styles: any = {
  containerForm: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: '80%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
  },
  containerSelects: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 22,
  },
  divCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  divCenterText: {
    height: '100%',
  },
  showButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 15
  },
  image: {
    maxWidth: '256px',
    marginBottom: 30,
    padding: '50px',
  },
  noResultsSpan: {
    color: '#59595B',
    fontSize: 19,
    textAlign: 'center',
  },
  noResults: {
    height: '95%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
  titleResults: {
    fontSize: 18,
    color: '#0A0A0A59',
    fontWeight: 'bold',
  },
  divListLogr: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#F9F9F9',
    borderRadius: '30px 0 0 30px',
    boxShadow: '0 3px 10px #00000033',
    zIndex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  divListHouses: {
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#EEEEEE',
    overflow: 'auto',
  },
  spanTitle: {
    color: '#a11908',
    fontSize: 15,
    fontWeight: '600',
    padding: '20px 0',
  },
  logoAcelera: {
    maxWidth: '100px',
    width: '100%',
    marginLeft: 10,
  },
  spanPowered: {
    color: '#0A0A0A59',
    fontSize: 14,
  },
  divisor: {
    height: 2,
    width: '100%',
    backgroundColor: '#0a0a0a1c',
  },
  padding: {
    padding: 20,
  },
  listOfLogr: {
    padding: '20px 0',
    overflow: 'auto',
    width: '100%',
  },
  logrItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 30px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#E6E6E6',
    }
  },
  logrItemActive: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 30px',
    cursor: 'pointer',
    backgroundColor: '#E6E6E6',
  },
  logrItemName: {
    color: '#707070',
    fontSize: 13,
  },
  logrItemNameBold: {
    color: '#0A0A0A',
    fontSize: 13,
    fontWeight: 'bold',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  titleListHousesBold: {
    color: '#707070',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  titleListHouses: {
    color: '#707070',
    fontSize: 13,
  },
  btnFilter: {
    color: '#177AFC',
    borderColor: '#177AFC',
  },
  housesList: {
    padding: '20px 0',
  },
  fab: {
    position: 'absolute',
    bottom: 15,
    right: 15,
    backgroundColor: '#0A4F9D',
  },
  buttonLink: {
    backgroundColor: 'transparent',
    border: 'none',
    marginTop: 8,
    width: '100%',
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: 12,
    padding: 0,
    color: '#808080',
  },
  switchLabel: {
    fontSize: '0.8rem',
    color: '#707070',
    fontWeight: 'bold',
  },
  buttonRegister: {
    margin: '0.4rem',
    backgroundColor: '#004692',
    color: '#fff',
    textTransform: 'capitalize',
    "&:hover": {
      backgroundColor: '#408BCC'
    },
    width: '6.25rem',
  },
  buttonExcel: {
    margin: '0.4rem',
    backgroundColor: '#004692',
    color: '#fff',
    textTransform: 'capitalize',
    "&:hover": {
      backgroundColor: '#408BCC'
    },
    width: '8.25rem',
  },
  tabs: {
    borderBottom: '1px solid #A0A0A0',
    color: '#A0A0A0',
    width: '100%',
    padding: '0 20px',
    fontWeight: 'bold',
    marginBottom: 10
  },
  tab: {
    fontWeight: 'bold',
  },
  gridForm: {
    padding: '0px 10px',
    gap: '10px',
  },
  inputCsv: {
    display: 'none',
  },
  formImport: {
    width: '100%',
    marginTop: '15px',
  },
  uploadPlace: {
    // display: 'flex',
    // alignItems: 'center',
    // gap: '5px',
    // border: '1px solid #004692',
    // padding: '0px 10px',
    // borderRadius: '10px',
    // transition: 'ease-in-out .3s',
    // '&:hover': {
    //   backgroundColor: '#00469210',
    // },
  },
};

export default styles;