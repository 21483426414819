import React from 'react';
import { withStyles, Grid, IconButton, Button } from '@material-ui/core';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';

import styles from './styles';
import { useHistory } from 'react-router-dom';
import { HomeEntity } from '../../../../../../interfaces/entities/home.entity';

type Props = { classes: any; house: HomeEntity; };

function CitizenTab(props: Props) {
  const { classes, house, } = props;
  const history = useHistory();

  function registerCitizen() {
    history.push(`/app/cidadao/cadastrar?houseId=${house?.id}`);
  }

  function getDate(element: string) {
    const dateString = new Date(element).toLocaleDateString();
    return dateString || '';
  }

  return (
    <Grid item xs={12} className={classes.main}>
      <Grid item xs={12} className={classes.cardFam}>
        <Grid item xs={12} className={classes.cardHeader}>
          <img alt="Família" src="/assets/community.png" className={classes.cardImage} />
          <span className={classes.cardHeaderTitle}>Família</span>
        </Grid>
        <Grid item xs={12} className={classes.cardBody}>
          {house?.residents.map((citizen) => (
            <Grid key={citizen.id} item xs={12} className={citizen.registration_status?.description !== 'Ativo' ? classes.citizenObito : classes.cardCitizen}>
              <Grid item className={classes.flexRow}>
                <div className={citizen?.responsible ? classes.boxUserResponsable : classes.boxUser}>
                  <PersonOutlinedIcon />
                </div>
              </Grid>
              <Grid item xs className={classes.flexColumn}>
                <span className={classes.cardCitizenTitle}>{citizen.name}</span>
                <span className={classes.cardCitizenSubtitle}>{getDate(citizen?.birthdate)}</span>
              </Grid>
              <Grid item className={classes.flexRow}>
                {citizen.responsible ? <div className={classes.divTag}>Responsável</div> : null}
                {true && <IconButton onClick={() => history.push(`/app/cidadao/${citizen?.id}/visualizar?houseId=${house?.id}`)}>
                  <DescriptionOutlinedIcon style={{ color: '#F16637' }} />
                </IconButton>}
                {true && <IconButton onClick={() => history.push(`/app/cidadao/${citizen?.id}/editar?houseId=${house?.id}`)}>
                  <EditOutlinedIcon style={{ color: '#F16637' }} />
                </IconButton>}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {true && <Button
          variant="contained"
          className={classes.buttonAdd}
          onClick={registerCitizen}
          endIcon={<AddOutlinedIcon />}
        >
          Adicionar novo familiar
        </Button>}
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(CitizenTab);
