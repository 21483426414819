/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import ImovelRoutes from './ImovelRoutes';
import CidadaoRoutes from './CidadaoRoutes';
import UsuarioRoutes from './UsuarioRoutes';
import BeneficiosRoutes from './BeneficiosRoutes';
import LogradouroRoutes from './LogradouroRoutes';

import Menu from '../../components/templates/Menu';
import Panels from '../../components/pages/private/Panels';
import Reports from '../../components/pages/private/Reports';
import Register from '../../components/pages/private/Register';

import { AuthContext } from '../../providers/Auth';
import ImportacaoRoutes from './ImportacaoRoutes';
import ProntuarioRoutes from './ProntuarioRoutes';
import RendaRoutes from './RendaRoutes';
import PostosRoutes from './PostosRoutes';

const PrivateRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  const { accessToken, logout, isLoggedIn } = useContext(AuthContext);
  const history = useHistory();

  function signOut() {
    logout(accessToken);
  }

  useEffect(() => {
    if (!isLoggedIn) {
      history.push('/');
    }
  }, [isLoggedIn]);

  return (
    <Menu user={{}} logout={signOut}>
      <Switch>
        <Route path={`${path}/inicio`}>
          <Panels />
        </Route>
        <Route path={`${path}/cadastro`}>
          <Register />
        </Route>
        <Route path={`${path}/relatorios`}>
          <Reports />
        </Route>
        <Route path={`${path}/imoveis`}>
          <ImovelRoutes />
        </Route>
        <Route path={`${path}/cidadao`}>
          <CidadaoRoutes />
        </Route>
        <Route path={`${path}/usuarios`}>
          <UsuarioRoutes />
        </Route>
        <Route path={`${path}/beneficios`}>
          <BeneficiosRoutes />
        </Route>
        <Route path={`${path}/logradouro`}>
          <LogradouroRoutes />
        </Route>
        <Route path={`${path}/importacao`}>
          <ImportacaoRoutes />
        </Route>
        <Route path={`${path}/prontuario`}>
          <ProntuarioRoutes />
        </Route>
        <Route path={`${path}/renda`}>
          <RendaRoutes />
        </Route>
        <Route path={`${path}/postos`}>
          <PostosRoutes />
        </Route>
        <Redirect to={`${path}/inicio`} />
      </Switch>
    </Menu>
  );
}

export default PrivateRoutes;
