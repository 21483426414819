type IncomeOrigin = { name: string, value?: string }

const incomeOrigins: Array<IncomeOrigin> = [
  { name: "Nenhuma" },
  { name: "Ajuda da Família" },
  { name: "Aluguel" },
  { name: "Aposentadoria" },
  { name: "Artesanato" },
  { name: "Auxílio Doença" },
  { name: "Auxílio Maternidade" },
  { name: "Bicos" },
  { name: "Bolsa Família" },
  { name: "BPC", value: '1380.60' },
  { name: "Defeso Camarão" },
  { name: "Outros" },
  { name: "PAS" },
  { name: "Pensão" },
  { name: "Pensão Alimentícia" },
  { name: "Pesca" },
  { name: "Servidor Público Municipal" },
  { name: "Trabalho Informal" },
];

export default incomeOrigins;