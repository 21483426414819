import React, {
  useRef,
  useState,
  useContext,
  useCallback,
} from "react";
import {
  Box,
  Grid,
  Button,
  withStyles,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import {
  ColDef,
  DataGrid,
  CellParams,
} from '@material-ui/data-grid';
import * as Yup from 'yup';
import {
  ExcelExport,
  ExcelExportColumn,
} from '@progress/kendo-react-excel-export';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { AttachFileOutlined } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';

import MySelect from "../../../MySelect";
import withSnackbar from '../../../templates/WithSnackbar';
import styles from './styles';


import companiesList from "../../../../constants/CompaniesList";
import { messages } from "../../../../constants/messages";
import CitizenService from "../../../../services/CitizenService";
import { AuthContext } from "../../../../providers/Auth";
import { CitizenEntity } from "../../../../interfaces/entities/citizen.entity";
import formatType from "../../../../utils/formatType";
let exporterRef: ExcelExport | null = null;

type Props = { classes: any, openSnackbar: any };


const ConfigAdmin: React.FC<Props> = (props) => {
  const formatPasSituation = (value: any) => {
    if (!value) {
      return 'Não aplicado';
    }
    const situation = (value as any)?.benefitiary_status;
    if ((value as any)?.message === 'Benefício autorizado!') {
      switch (situation) {
        case 1:
          return 'Manter concessão atual';
        default:
          return 'Conceder benefício'
      }
    } else {
      switch (situation) {
        case 4:
          return 'Manter concessão atual';
        default:
          return 'Desligar benefício'
      }
    }
  }
  const formatIncome = (row: any) => {
    const income = (row as any).total_income;
    if (income === null) {
      return 'Não encontrado'
    }
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
    }).format(income);
  }
  const formatName = (row: any) => {
    const citizen: CitizenEntity | null = (row as any).citizen;
    if (!citizen) {
      return 'Não encontrado'
    }
    return citizen.name;
  }
  const columnWidth = 200;
  const columns: ColDef[] = [
    {
      field: 'id',
      headerName: 'CPF',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => {
        return (value as string).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
    },
    {
      field: 'name',
      headerName: 'Nome do completo',
      width: columnWidth,
      valueFormatter: ({ row }: CellParams) => {
        return formatName(row);
      },
    },
    {
      field: 'income',
      headerName: 'Renda atual',
      width: columnWidth,
      valueFormatter: ({ row }: CellParams) => {
        return formatIncome(row);
      },
    },
    {
      field: 'import_income',
      headerName: 'Renda importada',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => {
        return new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        }).format(value as number);
      },
    },
    {
      field: 'company',
      headerName: 'Empregador',
      width: columnWidth,
      valueFormatter: () => {
        return formatType(formRef.current?.getFieldValue('company'), companiesList);
      },
    },
    {
      field: 'pas_situation',
      headerName: 'Situação no PAS',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => {
        return formatPasSituation(value);
      },
    },
  ];

  const { classes, openSnackbar } = props;
  const { accessToken } = useContext(AuthContext);
  const [nameXLSX, setNameXLSX] = useState<any>();
  const [keyName] = useState<string>('');
  const [showButton, setShowButton] = useState<boolean>(false);
  const [importIncomes, setImportIncomes] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [csvIncomes, setCsvIncomes] = useState<any[]>([]);
  const [readingFile, setReadingFile] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const renderExcelExportColumns = () => {
    return columns?.map((column) => {
      return <ExcelExportColumn
        field={column?.field}
        title={column?.headerName}
      />
    });
  };

  const renderExcelExport = () => {
    return <ExcelExport
      data={importIncomes.map((item) => ({
        id: item.id.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'),
        name: formatName(item),
        income: formatIncome(item),
        import_income: new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        }).format(item.import_income as number),
        company: formatType(formRef.current?.getFieldValue('company'), companiesList),
        pas_situation: formatPasSituation(item.pas_situation),
      }))}
      fileName='relatório_renda.xlsx'
      ref={(exporter) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        exporterRef = exporter;
      }}
    >
      {renderExcelExportColumns()}
    </ExcelExport>
  };

  const readCsv = useCallback((file: any) => {
    setReadingFile(true);
    const reader = new FileReader();
    if (file) {
      reader.readAsBinaryString(file);
      reader.onload = evt => {
        const rowsStringy = reader.result?.toString() || '';
        const rows = rowsStringy.split('\n');
        const csvJson = rows.map((row: any) => {
          const [cpf, income] = row.split(';');

          if (cpf && income) {
            return {
              cpf: cpf.replaceAll(`"`, '').padStart(11, '0'),
              income: parseFloat(income.replace('\r', '').replaceAll('.', '').replaceAll(',', '.')),
            }
          }

          return null;
        }).filter((row) => row != null);

        setCsvIncomes(csvJson);
        setReadingFile(false);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [csvIncomes, nameXLSX]);

  const handleChange = (e: any) => {
    if (e !== null) {
      const file = e.target.files[0];
      if (file) {
        setNameXLSX(file);
        readCsv(file);
        setShowButton(true);
      }
    };
  };
  function cancelFile() {
    setNameXLSX(null);
    setCsvIncomes([]);
    setShowButton(false);
  }

  async function handleSubmit(data: { company: number }) {
    const schema = Yup.object().shape({
      company: Yup.mixed().required(messages.emptyField),
    });
    try {
      formRef.current?.setErrors({});
      if (!nameXLSX || !csvIncomes.length) {
        openSnackbar('error', 'Coloque o arquivo de importação para prosseguir');
        return;
      }
      await schema.validate(data, { abortEarly: false });
      setShowTable(true);
      setIsLoading(true);
      const response = await CitizenService.importIncomes(
        accessToken,
        data.company,
        csvIncomes,
      );
      setImportIncomes(response);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        formRef.current?.setErrors(errorMessages);
      } else {
        openSnackbar('error', error?.message);
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Grid container className={classes.containerForm}>
      <Grid item xs={3} className={classes.divListLogr}>
        <span className={classes.spanTitle}>Importar renda</span>
        <div className={classes.divisor}></div>
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          className={classes.formImport}
        >
          <Grid container className={classes.gridForm}>
            <Grid xs={12}>
              <Box display="flex" alignItems="center">
                <input
                  type="file"
                  id="upload-xlsx"
                  accept=".csv"
                  multiple
                  className={classes.inputCsv}
                  onChange={handleChange}
                  key={keyName || ''}
                />
                <label htmlFor="upload-xlsx">
                  <Button
                    color="primary"
                    variant="outlined"
                    component="span"
                    style={{ textTransform: 'none' }}
                    endIcon={<AttachFileOutlined />}
                  >
                    {
                      showButton ? (
                        <Typography variant="subtitle1" color="primary">
                          {readingFile ? 'Lendo arquivo...' : nameXLSX?.name}
                        </Typography>
                      ) : (
                        <>
                          <Typography variant="subtitle1" color="primary" style={{ color: '#59595B' }}>
                            Clique para anexar o arquivo com  as rendas
                          </Typography>
                        </>
                      )
                    }
                  </Button>
                </label>
                {showButton && <Button onClick={cancelFile} className={classes.showButton}><CloseIcon color='action' /></Button>}
              </Box>
            </Grid>
            <Grid xs={12}>
              <MySelect
                name="company"
                label="Nome da Empresa"
                options={companiesList.map((type) => ({ label: type.name, value: type.id }))}
              />
            </Grid>
            <Grid xs={12}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isLoading}
                  style={{ marginTop: '1rem', backgroundColor: '#a11908' }}
                >
                  Visualizar importação
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </Grid>
      <Grid item xs={9} className={classes.divListHouses}>
        {
          !showTable ? (
            <div className={classes.flexRow}>
              <Grid item xs={12} className={classes.noResults}>
                <img alt="Sem resultados" src="/assets/no_results.png" className={classes.image} />
                <span className={classes.noResultsSpan}>Importe alguma tabela ao lado</span>
              </Grid>
            </div>
          ) : isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              marginTop={20}
            >
              <CircularProgress size={50} color="primary" style={{ color: '#a11908' }} />
            </Box>
          ) : (
            <Box display="flex" flexDirection="column">
              <Box
                display="flex"
                alignItems="center"
                marginBottom={1}
                justifyContent="space-between"
              >
                <Typography variant="h6" color="textSecondary">
                  Resultado da importação
                </Typography>
                <Button
                  onClick={() => exporterRef?.save()}
                  variant="outlined"
                  color="default"
                  style={{ backgroundColor: '#a11908' }}
                >Exportar</Button>
                {renderExcelExport()}
              </Box>
              <Box display="flex" boxShadow={3}>
                <DataGrid
                  rows={importIncomes}
                  autoHeight
                  columns={columns}
                  localeText={{
                    footerTotalRows: 'de',
                    footerPaginationRowsPerPage: 'Cidadãos por página'
                  }}
                  disableSelectionOnClick
                />
              </Box>
            </Box>
          )
        }
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(withSnackbar(ConfigAdmin));