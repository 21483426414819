export const UserType = {
    Gestor: "Gestor",
    Operador: "Operador",
    Cadastrador: "Cadastrador"
};

export const TabType = {
    Domicilios: 0,
    Individuos: 1,
    Beneficios: 2,
};

export const PermissionOption = {
    CONSULT: 'consult',
    REGISTER: 'register',
    EDIT: 'edit',
}