/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { TextField } from '@material-ui/core';

interface MyTextInputProps {
  name: string;
  type?: 'text' | 'password' | 'number' | 'url' | 'tel';
  label?: string;
  error?: boolean;
  hint?: string;
  placeholder?: string;
  InputLabelProps?: object;
  InputProps?: object;
  required?: boolean;
  disabled?: boolean;
  initalValue?: string | number;
  value?: string | number;
  variant?: any;
  onChange?: (event: any) => void;
}

const MyTextInput: React.FC<MyTextInputProps> = (props) => {
  const {
    name,
    type,
    label,
    value,
    variant,
    disabled,
    required,
    onChange,
    InputProps,
    initalValue,
    placeholder,
    InputLabelProps,
  } = props;
  const inputRef = useRef();
  const { fieldName, defaultValue = initalValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => ref.current.value,
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <TextField
      fullWidth
      required={required}
      disabled={disabled}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
      defaultValue={defaultValue}
      name={name}
      placeholder={placeholder}
      inputRef={inputRef}
      onChange={onChange}
      type={type}
      label={label}
      value={value}
      helperText={error}
      error={!!error}
      variant={variant}
    />
  );
};

export default MyTextInput;
