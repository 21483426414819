const styles: any = {
  alertContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '25px',
  },
  icon: {
    fontSize: '80px',
  },
  text: {
    textAlign: 'center',
  },
};


export default styles;