import React from 'react';
import {
    Route,
    Switch,
    useRouteMatch,
} from 'react-router-dom';
import ManageIncome from '../../../components/pages/private/ManageIncome';

const RendaRoutes: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/gerenciar`}>
                <ManageIncome />
            </Route>
        </Switch>
    );
}

export default RendaRoutes;
