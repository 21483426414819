import React, { useState } from 'react';
import {
  Grid,
  withStyles,
  Typography,
  Paper,
  Box,
  Button,
  Container,
} from '@material-ui/core';
import QRCode from 'qrcode.react';

import styles from './styles';
import withSnackbar from '../../../templates/WithSnackbar';
import Beneficiary from '../../../../model/entities/Beneficiary';
import format from 'date-fns/format';

interface QrcodePageProps {
  openSnackbar(type: string, message: string): void;
  classes: any;
  beneficiary: Beneficiary;
}

const QrcodePage: React.FC<QrcodePageProps> = (props) => {
  const { classes, beneficiary } = props;
  const [qrcode,] = useState(beneficiary?.benefit?.nib || '05dw748w');
  const [deliverablePoint] = useState(beneficiary?.benefit?.benefit_deliverable_point ? beneficiary?.benefit?.benefit_deliverable_point[0]?.description ?? '' : "");

  function downloadQRCode() {
    window.print();
  }

  function formatCpf(cpf: string) {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  return (
    <Container maxWidth="xl">
      <Grid container justify="center" alignItems="center">
        <Grid item xl={5} lg={5} md={5} xs={12} sm={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h3" className={classes.pageTitle}>
                <b>Cadastro concluído com sucesso!</b>
              </Typography>
              <Typography variant="h6" className={classes.textMiddle}>Parabéns, você concluiu o seu cadastro, mas <b>fique atento!</b></Typography>
              <Typography variant="h6">
                A <b>apresentação do QR Code abaixo será imprescindível no momento da entrega do benefício</b>, por isso salve-o no seu dispositivo e mantenha-o em um local de fácil acesso.
              </Typography>
            </Grid>

            <Grid item md={9} xs={12}>
              <Paper elevation={4} className={classes.dataContainer}>
                <Grid container>
                  <Grid item lg={6} xs={12}>
                    <Typography variant="body1" align="center">Nome: {beneficiary.name}</Typography>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Typography variant="body1" align="center">CPF: {formatCpf(beneficiary.cpf)}</Typography>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Typography variant="body1" align="center">Local de retirada: {deliverablePoint}</Typography>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Typography variant="body1" align="center">Data para entrega: {format(new Date(beneficiary.benefit.started_at), 'dd/MM/yyyy')}</Typography>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Typography variant="body1" align="center" style={{ marginTop: '8px'}}>Horário para entrega: 09h às 17h</Typography>
                  </Grid>
                </Grid>
                <Box className={classes.qrcodeContainer}>
                  <Grid container>
                    <Grid xs={12} className={classes.qrcodeGridCell}>
                      <QRCode
                        id="qrcode-nib"
                        size={200}
                        value={qrcode}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.downloadContainer}>
                      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Typography
                          variant="h6"
                          align="center"
                        >
                          Salve seu QR Code clicando no botão abaixo.
                        </Typography>
                        <Button
                          onClick={downloadQRCode}
                          className={classes.btnDownload}
                        >
                          Salvar
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={5} lg={5} md={5} xs={12} sm={12} className={classes.pageQrcodeContainer}>
          <img
            className={classes.landingImg}
            alt="Landing"
            src="/assets/landing-image-qrcode.png"
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default withStyles(styles)(
  withSnackbar(QrcodePage),
);
