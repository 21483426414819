import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import Config from '../../../components/pages/private/Config';

const ImportacaoRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/rendas`}>
        <Config />
      </Route>
    </Switch>
  );
};

export default ImportacaoRoutes;
