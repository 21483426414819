import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import MapOutlinedIcon from '@material-ui/icons/MapOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';

import styles from './styles';
import Resident from '../../../../../../model/entities/Resident';
import { HomeEntity } from '../../../../../../interfaces/entities/home.entity';
import formatType from '../../../../../../utils/formatType';
import bathroomDrains from '../../../../../../constants/bathroomDrains';
import trashDestinations from '../../../../../../constants/trashDestinations';
import waterSupplies from '../../../../../../constants/waterSupplies';

type Props = {
  classes: any;
  house: HomeEntity;
  operator: string | undefined;
  citizens: Resident[] | undefined;
  openSnackbar: any;
};

function InfoTab(props: Props) {
  const { classes, house, operator } = props;


  return (
    <Grid container className={classes.main}>
      <Grid item xs={12}>
        <Grid item xs={12} style={{ display: 'flex', padding: '20px' }}>
          <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Grid item xs={4} style={{ display: 'flex', paddingRight: 15 }}>
              <MapOutlinedIcon style={{ color: '#a11908', marginRight: 15 }} />
              <span className={classes.title}>Endereço</span>
            </Grid>
            <Grid item style={{ display: 'flex', flexDirection: 'column' }}>
              <span className={classes.infoTitle}>
                {house?.street_name}, {house?.street_number}
              </span>
              <span className={classes.infoTitle}>{house?.district || 'Sem registro'}</span>
              <span className={classes.infoTitle}>{house?.district || 'Sem registro'}</span>
              <span className={classes.infoTitle}>CEP: {house?.cep || 'Sem registro'}</span>
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item xs={4} style={{ display: 'flex', paddingRight: 15 }}>
              <RoomOutlinedIcon style={{ color: '#a11908', marginRight: 15 }} />
              <span className={classes.title}>Pontos de referência</span>
            </Grid>
            <Grid item>
              <span className={classes.infoTitle}>{house?.reference_point || 'Não Informado'}</span>
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
            <Grid item xs={4} style={{ display: 'flex', paddingRight: 15 }}>
              <PhoneOutlinedIcon style={{ color: '#a11908', marginRight: 15 }} />
              <span className={classes.title}>Telefone Residencial</span>
            </Grid>
            <Grid item>
              <span className={classes.infoTitle}>{house?.telephone || 'Sem registro'}</span>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.divisor}></div>
        <Grid item xs={12} className={classes.flexColumn} style={{ padding: '20px' }}>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <FormatListBulletedOutlinedIcon style={{ color: '#a11908', marginRight: 15 }} />
            <span className={classes.title}>Condições de Moradia</span>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '10px 20px' }}>
            <Grid item xs={3} className={classes.conditionInfo}>
              <span className={classes.conditionTitle}>Abastecimento de Água</span>
              <span className={classes.conditionSubtitle}>
                {house?.water_supply_id ? formatType(house?.water_supply_id, waterSupplies) : 'Sem registro'}
              </span>
            </Grid>
            <Grid item xs={3} className={classes.conditionInfo}>
              <span className={classes.conditionTitle}>Destino do Lixo</span>
              <span className={classes.conditionSubtitle}>
                {house?.garbage_disposal_id ? formatType(house?.garbage_disposal_id, trashDestinations) : null}
              </span>
            </Grid>
            <Grid item xs={3} className={classes.conditionInfo}>
              <span className={classes.conditionTitle}>Escoamento do Banheiro</span>
              <span className={classes.conditionSubtitle}>{house?.sewage_id ? formatType(house?.sewage_id, bathroomDrains) : null}</span>
            </Grid>
            <Grid item xs={3} className={classes.conditionInfo}>
              <span className={classes.conditionTitle}>Situação de Moradia</span>
              <span className={classes.conditionSubtitle}>{house?.housing_situation?.description || 'Sem registro'}</span>
            </Grid>
            <Grid item xs={3} className={classes.conditionInfo}>
              <span className={classes.conditionTitle}>Disponibilidade de Energia Elétrica</span>
              <span className={classes.conditionSubtitle}>
                {house?.electric_power_supply ? 'Sim' : 'Não'}
              </span>
            </Grid>
            <Grid item xs={3} className={classes.conditionInfo}>
              <span className={classes.conditionTitle}>Residentes no Domicílio</span>
              <span className={classes.conditionSubtitle}>{house?.residents.length} Pessoas</span>
            </Grid>
            <Grid item xs={3} className={classes.conditionInfo}>
              <span className={classes.conditionTitle}>Renda Familiar</span>
              <span className={classes.conditionSubtitle}>R$ {0}</span>
            </Grid>
            <Grid item xs={3} className={classes.conditionInfo}>
              <span className={classes.conditionTitle}>Operador da última atualização</span>
              <span className={classes.conditionSubtitle}>{operator}</span>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.divisor}></div>

      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(InfoTab);
