import React, { useState, useRef } from 'react';
import * as Yup from 'yup';

import {
  Grid,
  withStyles,
  Button,
  CircularProgress,
  InputAdornment,
  FormControlLabel,
} from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import ptBrLocale from 'date-fns/locale/pt-BR';
import DateFnsUtils from '@date-io/date-fns';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import withSnackbar from '../../../templates/WithSnackbar';

import BenefitJuneBasketService from '../../../../services/BenefitJuneBasketService';

import races from '../../../../constants/races';
import sexes from '../../../../constants/sexes';
import neighborhoods from '../../../../constants/neighborhoods';

import styles from './styles';
import MyCheckbox from '../../../MyCheckBox';
import MyTextInput from '../../../MyTextInput';
import MyInputMask from '../../../MyInputMask';
import MySelect from '../../../MySelect';
import Beneficiary from '../../../../model/entities/Beneficiary';
import { useHistory } from 'react-router-dom';

type Props = {
  classes: any;
  login: Function;
  openSnackbar: any;
  auth: any;
  history: any;
  beneficiary: Beneficiary;
  onConfirmBenefit(beneficiary: Beneficiary): void;
};

function JuneBasketRegister(props: Props) {
  const { classes, openSnackbar, beneficiary, onConfirmBenefit } = props;

  const [loading, setLoading] = useState<boolean>(false);
  const [streetCounty] = useState<string>('MADRE DE DEUS');
  const [birthdate, setBirthDate] = React.useState<Date | null>(beneficiary.birthdate);
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();

  function handleError(e: any) {
    openSnackbar('warning', e);
  }

  function onCancel() {
    history.goBack();
  }

  function formatTelephone(telephone: string) {

    return telephone.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');
  }

  async function onSubmit(data: any) {
    try {
      setLoading(true);
      data.birthdate = birthdate;

      const schema = Yup.object().shape({
        birthdate: Yup.string().typeError('Informe o campo DATA DE NASCIMENTO corretamente').required('Informe o campo DATA DE NASCIMENTO corretamente'),
        gender_id: Yup.number().typeError('Informe o campo SEXO corretamente').required('Informe o campo SEXO corretamente'),
        complexion_id: Yup.number().typeError('Informe o campo RAÇA/COR corretamente').required('Informe o campo RAÇA/COR corretamente'),
        telephone: Yup.string().typeError('Informe o campo TELEFONE corretamente').required('Informe o campo TELEFONE corretamente'),
        district: Yup.string().typeError('Informe o campo BAIRRO corretamente').required('Informe o campo BAIRRO corretamente'),
      });

      await schema.validate({
        birthdate: data.birthdate,
        gender_id: data.gender_id,
        complexion_id: data.complexion_id,
        telephone: data.telephone,
        district: data.neighborhood
      });

      const updatedData = {
        birthdate: new Date(data.birthdate).toISOString().split("T")[0],
        gender_id: data.gender_id,
        complexion_id: data.complexion_id,
        telephone: formatTelephone(data.telephone),
        mother_name: data.mother_name,
        unknown_mother: !data.mother_name.trim(),
        father_name: data.father_name,
        unknown_father: !data.father_name.trim(),
        only_message: data.onlyMessage,
        name: data.fullName,
        ...(data.socialName && { social_name: data.socialName }),
        district: data.neighborhood,
        street_name: data.streetName,
        street_number: data.number,
        complement: data.complement,
      }

      await BenefitJuneBasketService.confirm(beneficiary?.benefit.nib || '', updatedData);
      const benefitData = await BenefitJuneBasketService.consult(beneficiary?.cpf, beneficiary?.nis);
      onConfirmBenefit({
        ...benefitData.citizen,
        benefit: benefitData.benefit,
      });

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      handleError(error.message);
    }
  }

  return (
    <Form
      ref={formRef}
      onSubmit={onSubmit}
      initialData={{
        fullName: beneficiary?.name,
        cpf: beneficiary?.cpf,
        nis: beneficiary?.nis,
        mother_name: beneficiary?.mother_name,
        father_name: beneficiary?.father_name,
        gender_id: beneficiary?.gender_id,
        complexion_id: beneficiary?.complexion_id,
        telephone: beneficiary?.telephone,
        onlyMessage: false,
        streetName: beneficiary?.residence?.street_name,
        county: streetCounty,
        cep: beneficiary?.residence?.cep,
        number: beneficiary?.residence?.street_number,
        complement: beneficiary?.residence?.complement
      }}
    >
      <Grid container className={classes.divMain}>
        <Grid item xs={11}>
          <Grid item xs={12} className={classes.cardForm}>
            <span className={classes.title18}>Dados do Endereço</span>
            <Grid container item spacing={2} style={{ margin: '10px 0 15px' }}>
              <Grid item xs={12} sm={6} md={5}>
                <MyTextInput
                  placeholder="Logradouro"
                  label="Logradouro"
                  name="streetName"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <MyTextInput
                  label="Número"
                  name="number"
                  type="number"
                  placeholder="N°"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <MySelect
                  required
                  name="neighborhood"
                  label="Bairro"
                  options={neighborhoods.map((item) => ({ label: item.name, value: item.name }))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <MyTextInput
                  placeholder="Complemento"
                  name="complement"
                  label="Complemento"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MyTextInput
                  placeholder="Município"
                  disabled
                  name="county"
                  label="Município"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MyTextInput
                  placeholder="CEP"
                  disabled
                  name="cep"
                  label="CEP"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.cardForm} style={{ margin: '20px 0' }}>
            <span className={classes.title18}>Dados do Responsável Familiar</span>
            <Grid container item xs={12} spacing={2} style={{ margin: '10px 0 15px' }}>
              <Grid item xs={12} sm={6} md={4}>
                <MyTextInput
                  disabled
                  name="fullName"
                  label="Nome Completo"
                  placeholder="Nome Completo"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <MyTextInput
                  name="socialName"
                  label="Nome Social (Portaria MS nº 1.820)"
                  placeholder="Nome Social"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <MyInputMask
                  disabled
                  mask="999.999.999-99"
                  type="cpf"
                  name="cpf"
                  label="CPF"
                  placeholder="000.000.000-00"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <MyTextInput
                  disabled
                  name="nis"
                  label="NIS"
                  placeholder="NIS"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBrLocale}>
                  <KeyboardDatePicker
                    className={classes.birthDatePicker}
                    style={{ marginTop: 3 }}
                    fullWidth
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="dense"
                    id="data-nascimento"
                    label="Data de nascimento"
                    value={beneficiary?.birthdate}
                    onChange={date => setBirthDate(date)}
                    invalidDateMessage="Formato de data inválido"
                    KeyboardButtonProps={{
                      'aria-label': 'mudar data',
                    }}
                    placeholder="Data de nascimento"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" style={{ color: "#707070" }} />
                      ),
                    }}
                    InputLabelProps={{
                      style: { color: '#707070', fontWeight: 'bold' },
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MySelect
                  name="gender_id"
                  label="Sexo (obrigatório)"
                  options={sexes.map((item) => ({ label: item.name, value: item.id }))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <MySelect
                  name="complexion_id"
                  label="Raça/Cor (obrigatório)"
                  options={races.map((item) => ({ label: item.name, value: item.id }))}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <MyInputMask
                  mask="(99) 99999-9999"
                  name="telephone"
                  label="Celular (obrigatório)"
                  required
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start" style={{ color: '#707070' }} />,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5} md={2}>
                <FormControlLabel
                  style={{ marginRight: 0, marginTop: 10 }}
                  control={
                    <MyCheckbox
                      color="primary"
                      name="onlyMessage"
                    />
                  }
                  label="Somente recado"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MyTextInput
                  placeholder="Nome da Mãe"
                  name="mother_name"
                  label="Nome da Mãe"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <MyTextInput
                  placeholder="Nome do Pai"
                  name="father_name"
                  label="Nome do Pai"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.buttonActionsContainer}>
            <Button
              disabled={loading}
              onClick={onCancel}
              variant="outlined"
              size="large"
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              disabled={loading}
              variant="contained"
              size="large"
              className={classes.buttonRegister}
            >
              {loading ? <CircularProgress size={25} style={{ color: '#a11908' }} /> : 'Salvar'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
}

export default withStyles(styles)(withSnackbar(JuneBasketRegister));
