interface TypeObject {
    id: number;
    name: string;
}

export default function formatLabels(array: Array<TypeObject>) {
    return array.map((item: TypeObject) => {
        return item.name;
        // return `${item.name.slice(0, 5)}..`;
    });
}