type Schooling = { id: number; name: string }

const schoolings: Array<Schooling> = [
  { id: 1, name: "Nenhum" },
  { id: 2, name: "Analfabeto" },
  { id: 3, name: "Ensino Fundamental Incompleto" },
  { id: 4, name: "Ensino Fundamental Completo" },
  { id: 5, name: "Ensino Médio Incompleto" },
  { id: 6, name: "Ensino Médio Completo" },
  { id: 7, name: "Superior Incompleto" },
  { id: 8, name: "Superior Completo" },
  { id: 9, name: "Especialização" },
  { id: 10, name: "Pós-graduação" },
  { id: 11, name: "Mestrado" },
  { id: 12, name: "Doutorado" },
  { id: 13, name: "Pós-Doutorado" },
];

export default schoolings;