import { GetUsersResponse } from '../interfaces/responses/get-users.response';

import errorService from './ErrorService';
import Client from './api/api';
import { UserEntity } from '../interfaces/entities/user.entity';
import { CommonObjectType } from '../interfaces/entities/common-object-type.entity';
import { CommonResultsResponse } from '../interfaces/responses/common-results.response';


interface UserRequestBody {
  name: string;
  email: string;
  login: string;
  password?: string;
  active: boolean;
  user_type_id: number;
}

class UserService {
  private DEFAULT_REF = 'user';
  private apiInstance = Client('api');
  private config = (token: string) => ({ headers: { Authorization: `Bearer ${token}` } });

  public async create(token: string, body: UserRequestBody): Promise<any> {
    try {
      const { data } = await this.apiInstance.post('/users', body, this.config(token));

      return data;
    } catch (error) {
      throw errorService.handleError(error);
    }
  }

  public async getAllUsers(token: string): Promise<GetUsersResponse> {
    try {
      const response = await this.apiInstance.get<GetUsersResponse>('/users', this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async update(token: string, body: UserRequestBody, userId: number): Promise<UserEntity> {
    try {
      const { data } = await this.apiInstance.put(`/users/${userId}/edit`, body, this.config(token));

      return data;
    } catch (error) {
      throw errorService.handleError(error);
    }
  }

  public async get(token: string, userId: number): Promise<UserEntity> {
    try {
      const response = await this.apiInstance.get<UserEntity>(`/users/${userId}`, this.config(token));

      return response.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async delete(userId: number, token: string): Promise<void> {
    try {
      const response = await this.apiInstance.delete<void>(`/users/${userId}/delete`, this.config(token));

      return response.data;
    } catch (error) {
      throw errorService.handleError(error);
    }
  }

  public async syncPosts(userId: number, deliveryPostIds: Array<number>, token: string): Promise<void> {
    try {
      let data = {
        "delivery_posts": deliveryPostIds
      }

      await this.apiInstance.post<void>(`/users/${userId}/delivery-posts`, data, this.config(token));

    } catch (error) {
      throw errorService.handleError(error);
    }
  }

  public async getUserPosts(userId: number, token: string): Promise<CommonResultsResponse<CommonObjectType>> {
    try {
      const response = await this.apiInstance.get<CommonResultsResponse<CommonObjectType>>(`/users/${userId}/delivery-posts`, this.config(token));

      return response.data;
    } catch (error) {
      throw errorService.handleError(error);
    }
  }
};

export default new UserService();
