import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';

import ChristmasBasketSplash from '../../components/pages/public/ChristmasBasketSplash';
import ChristmasBasketManager from '../../components/pages/public/ChristmasBasketManager';


const CestaNatalinaRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/splash`}>
        <ChristmasBasketSplash />
      </Route>
      <Route path={`${path}/`}>
        <ChristmasBasketManager />
      </Route>
      <Redirect to={`${path}/splash`} />
    </Switch>
  );
}

export default CestaNatalinaRoutes;
