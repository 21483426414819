const styles: any = {
    flexRow: {
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 20,
    },
    title: {
        color: '#0A0A0A59',
        fontWeight: 'bold',
        marginRight: 20,
    },
    tabs: {
        borderBottom: '1px solid #A0A0A0',
        color: '#A0A0A0',
        width: '100%',
        padding: '0 20px',
        fontWeight: 'bold',
        // color: '#177AFC'
    },
    tab: {
        fontWeight: 'bold',
        // color: '#177AFC'
    },
    btnFilter: {
        color: '#177AFC',
        border: '1px solid #177AFC',
        margin: '0 0 0 20px',
        padding: '10px 25px',
        fontWeight: 'bold',
        '&:disabled': {
            border: '1px solid #CCCCCC',
        }
    },
    btnBlue: {
        backgroundColor: '#a11908',
        color: '#FFFFFF',
        marginLeft: '10px',
        textTransform: 'capitalize',
        fontSize: 14,
        padding: '5px 20px 5px 20px'
    },
};

export default styles;