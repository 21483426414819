type SexualOrientation = { id: number; name: string }

const sexualOrientations: Array<SexualOrientation> = [
  { id: 1, name: "Heterossexual" },
  { id: 2, name: "Homossexual (gay/lésbica)" },
  { id: 3, name: "Bissexual" },
  { id: 4, name: "Outro" },
  { id: 5, name: "Não deseja informar" },
];

export default sexualOrientations;