const styles: any = {
    cardIcon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    card: {
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 0px 8px #0000001F',
        borderRadius: 10,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        transition: 'background-color 200ms linear',

    },
    cardSelected: {
        backgroundColor: '#C1C1C1',
        boxShadow: '0px 0px 8px #0000001F',
        borderRadius: 10,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        transition: 'background-color 200ms linear',
        border: '1px #c1c1c1 solid',
    },
    cardImg: {
        maxHeight: '60px',
        minHeight: '60px',
    },
    cardInfo: {
        height: '100px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '0px 10px',
        textAlign: 'start',
    },
    cardInfoTitle: {
        color: '#707070',
        fontWeight: 'bolder',
        fontSize: 23,
    },
    cardInfoTitleSelected: {
        color: '#1E354B',
        fontWeight: 'bolder',
        fontSize: 23,
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    buttonBase: {
        width: '100%',
        height: '100%',
        borderRadius: 10,
        flex: 1,
    },
    cardFooter: {
        display: 'flex',
        width: '100%',
        backgroundColor: '#EEEEEE',
        borderRadius: '0px 0px 10px 10px',
        borderTop: '1px solid lightgray',
    },
    cardButtonSuspend: {
        width: '100%',
        color: '#95181C',
        fontSize: '1rem',
        fontWeight: '600',
        padding: '8px 0px',
        borderBottomRightRadius: '10px',
        borderBottomLeftRadius: '10px',
    },
    cardButtonCancel: {
        width: '100%',
        color: '#',
        fontSize: '1rem',
        fontWeight: '600',
        padding: '8px 0px',
        borderBottomLeftRadius: '10px',
        borderRight: '1px solid lightgray',
    },
    content: {
        height: '100%',
    },
    footerButtonIcon: {
        marginRight: 5,
    },
    undoSuspend: {
        display: 'flex',
        alignItems: 'center',
        color: '#177AFC',
    },
};

export default styles;