/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import Login from '../../../components/pages/public/Login';
import { AuthContext } from '../../../providers/Auth';

const LoginRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  const { isLoggedIn } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn) {
      history.push(`/app`);
    }
  }, [isLoggedIn]);

  return (
    <Switch>
      <Route path={`${path}/`}>
        <Login />
      </Route>
    </Switch>
  );
}

export default LoginRoutes;
