const styles: any = {
    containerForm: {
        padding: 40,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: '80%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    welcomSpan: {
        color: '#a11908',
        fontSize: 19,
        textAlign: 'center',
    },
    welcomSpanBold: {
        color: '#a11908',
        fontSize: 19,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    image: {
        maxWidth: '500px',
        marginBottom: 30,
    },
    divCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    divCenterText: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    divFlex1: {
        height: '95%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    spanPowered: {
        color: '#0A0A0A59',
        fontSize: 14,
    },
    swipeableContent: {
        '& > .react-swipeable-view-container > div': {
            overflow: 'hidden !important',
        }
    },
};

export default styles;