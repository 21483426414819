type SituationJobMarket = { id: number; name: string }

const situationsJobMarket: Array<SituationJobMarket> = [
  { id: 1, name: "Não trabalha" },
  { id: 2, name: "Desempregado" },
  { id: 3, name: "Servidor Público Federal" },
  { id: 4, name: "Servidor Público Estadual" },
  { id: 5, name: "Servidor Público Municipal" },
  { id: 6, name: "Militar" },
  { id: 7, name: "Pensionista" },
  { id: 8, name: "Empregador" },
  { id: 9, name: "Assalariado Sem Carteira de Trabalho" },
  { id: 10, name: "Assalariado Com Carteira de Trabalho" },
  { id: 11, name: "Autônomo Sem Previdência Social" },
  { id: 12, name: "Autônomo Com Previdência Social" },
];

export default situationsJobMarket;