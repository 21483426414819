const styles: any = {
    mainGrid: {
        display: 'flex',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F9F9F9',
        backgroundImage: 'url(/assets/bg_home.png)',
        backgroundSize: '80%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        padding: '30px 0'
    },
    divMain: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
    },
    divCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    divButton: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 15,
        marginBottom: 10,
    },
    cardForm: {
        backgroundColor: '#EEEEEE',
        border: '1px solid #707070',
        borderRadius: 10,
        padding: 20,
        width: '100%',
    },
    logoSfc: {
        maxWidth: '160px',
        width: '100%',
        marginBottom: 15,
    },
    title22: {
        margin: '10px 0 50px',
        fontWeight: '600',
        fontSize: 22,
        color: '#a11908',
        textAlign: 'center',
    },
    title18: {
        fontWeight: '600',
        fontSize: 15,
        color: '#0A0A0A59',
        textTransform: 'uppercase',
    },
    title16: {
        fontWeight: '600',
        fontSize: 15,
        color: '#a11908',
        margin: '5px 0 10px',
    },
    logoAcelera: {
        maxWidth: '150px',
        width: '100%',
        marginTop: 60,
    },
    linkForgot: {
        color: '#707070',
        fontSize: 14,
        fontWeight: '500',
        border: 'none',
        backgroundColor: 'transparent',
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    viewModalBackground: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 2,
        top: 0,
    },
    viewBackground: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: '#0e0e0e80',
    },
    viewModal: {
        zIndex: 3,
        backgroundColor: '#FFFFFF',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 40,
        borderRadius: 20,
        margin: '0 70px',
    },
    titleModal: {
        fontSize: 20,
        color: '#a11908',
        fontWeight: 'bold'
    },
    viewRow: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between'
    },
    buttonModal: {
        backgroundColor: '#a11908',
        width: '48%',
        height: 50
    },
    buttonCancelModal: {
        backgroundColor: '#707070',
        width: '48%',
        height: 50
    },
    label: {
        color: '#707070',
        fontWeight: 'bold',
    },
    select: {
        backgroundColor: '#E6E6E6',
    },
    buttonCancel: {
        fontSize: 14,
        fontWeight: 'bold',
        borderColor: '#D4586D',
        color: '#707070',
        padding: '10px 25px',
        "&:hover": {
            backgroundColor: '#D4586D',
            color: '#fff',
        },
    },
    buttonRegister: {
        fontSize: 14,
        fontWeight: 'bold',
        marginLeft: '15px',
        backgroundColor: '#a11908',
        padding: '10px 25px',
        color: '#fff',
        "&:hover": {
            backgroundColor: '#f5cc5a'
        }
    },
    checkboxDiv: {
        color: '#707070',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#E6E6E6',
        border: '1px solid #0000003b',
        borderRadius: '0 4px 4px 0',
    },
    buttonActionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
};

export default styles;