import React from 'react';
import { withStyles, Grid } from '@material-ui/core';

import CardMain from '../../../../../templates/CardMain';
import BarChart from '../../../../../templates/ChartBar';
import PieChart from '../../../../../templates/PieChart';

import styles from './styles';
import { newPainelColors } from '../../../../../../constants/colors/painel.colors';

type Props = {
  classes: any;
  totalCitizens: number;
  totalCitizensIncomple: number;
  totalCitizensByRace: { labels: string[], data: number[] };
  totalCitizensByGender: { labels: string[], data: number[] };
  totalCitizensByReligion: { labels: string[], data: number[] };
  totalCitizensBySchooling: { labels: string[], data: number[] };
  totalCitizensByJobSituation: { labels: string[], data: number[] };
  totalCitizensByAge: { labels: string[], data: number[] };
  totalCitizensByDeficiency: { labels: string[], data: number[] };
};

const CitizenTab: React.FC<Props> = (props) => {
  const {
    classes,
    totalCitizens,
    totalCitizensByAge,
    totalCitizensByRace,
    totalCitizensByGender,
    totalCitizensIncomple,
    totalCitizensByReligion,
    totalCitizensBySchooling,
    totalCitizensByDeficiency,
    totalCitizensByJobSituation,
  } = props;

  const renderPage = () => {
    return (
      <>
        <Grid item xs={12} className={classes.mainItem}>
          <Grid container justify="center" >
            <Grid item xs={12} className={classes.cardItem}>
              <CardMain border="#F166374D">
                <Grid className={classes.flexRow}>
                  <Grid item xs={8} className={classes.flexRow}>
                    <img alt="Domicílios Registrados" src="/assets/citizen_registered.png" className={classes.cardImg} />
                    <span className={classes.cardInfoTitle}>Total de Indivíduos Cadastrados</span>
                  </Grid>
                  <Grid item xs={5} className={classes.flexColumn}>
                    <span className={classes.cardInfoTotal}>{totalCitizens}</span>
                  </Grid>
                </Grid>
              </CardMain>
            </Grid>
          </Grid>
          <Grid container justify="center">
            <Grid item xs={12} className={classes.cardItem}>
              <CardMain border="#F166374D">
                <Grid item xs={8} className={classes.flexRow}>
                  <img alt="Domicílios Registrados" src="/assets/citizen_not_registered.png" className={classes.cardImg} />
                  <span className={classes.cardInfoTitle}>Cadastros incompletos</span>
                </Grid>
                <Grid item xs={5} className={classes.flexColumn}>
                  <span className={classes.cardInfoTotal}>{totalCitizensIncomple}</span>
                </Grid>
              </CardMain>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <CardMain>
            <BarChart
              colors={newPainelColors}
              labels={totalCitizensByAge.labels}
              data={totalCitizensByAge.data}
              title="Cadastros por Faixa Etária"
            />
          </CardMain>
        </Grid>
        <Grid item xs={6}>
          <CardMain>
            <BarChart
              colors={newPainelColors}
              // labels={formatType(totalCitizensByRace.labels, races) as string[]}
              labels={totalCitizensByRace.labels}
              data={totalCitizensByRace.data}
              title="Cadastros por Raça/Cor"
            />
          </CardMain>
        </Grid>
        <Grid item xs={6}>
          <CardMain>
            <PieChart
              colors={newPainelColors}
              labels={totalCitizensByGender.labels}
              data={totalCitizensByGender.data}
              title="Cadastros por Sexo"
            />
          </CardMain>
        </Grid>
        <Grid item xs={6}>
          <CardMain>
            <BarChart
              colors={newPainelColors}
              labels={totalCitizensBySchooling.labels}
              data={totalCitizensBySchooling.data}
              title="Cadastros por Grau de Escolaridade"
            />
          </CardMain>
        </Grid>
        <Grid item xs={6}>
          <CardMain>
            <BarChart
              colors={newPainelColors}
              labels={totalCitizensByJobSituation.labels}
              data={totalCitizensByJobSituation.data}
              title="Cadastros por Situação Mercado"
            />
          </CardMain>
        </Grid>
        <Grid item xs={6}>
          <CardMain>
            <BarChart
              colors={newPainelColors}
              labels={totalCitizensByReligion.labels}
              data={totalCitizensByReligion.data}
              title="Cadastros por Religião"
            />
          </CardMain>
        </Grid>
        <Grid item xs={6}>
          <CardMain>
            <BarChart
              colors={newPainelColors}
              labels={totalCitizensByDeficiency.labels}
              data={totalCitizensByDeficiency.data}
              title="Cadastros por Deficiência"
            />
          </CardMain>
        </Grid>
      </>
    );
  }

  return (
    <Grid container spacing={4} className={classes.main}>
      {renderPage()}
    </Grid>
  );
}

export default withStyles(styles)(CitizenTab);