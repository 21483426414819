type Race = { id: number; name: string }

const races: Array<Race> = [
  { id: 1, name: "Branca" },
  { id: 2, name: "Preta" },
  { id: 3, name: "Parda" },
  { id: 4, name: "Amarela" },
  { id: 5, name: "Indígena" },
  { id: 6, name: "Não desejo informar" },
];

export default races;