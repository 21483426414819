const styles: any = {
  rootPaper: {
    borderRadius: 10,
    border: '1px solid #0A0A0A59',
    backgroundColor: '#EEEEEE',
  },
  tableContainer: {
  }
};

export default styles;