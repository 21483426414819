import { LoginResponse } from '../interfaces/responses/login.response';
import Client from './api/api';
import ErrorService from './ErrorService';

interface ChangePasswordRequest {
  current_password: string;
  new_password: string;
  password_confirm: string;
}

class AuthServive {
  private apiInstance = Client('auth');
  private apiInstancePrivate = Client('api');
  private config = (token: string) => ({ headers: { Authorization: `Bearer ${token}` } });

  public async login(
    login: string,
    password: string,
  ): Promise<LoginResponse> {
    try {
      const response = await this.apiInstance.post('/login', {
        login,
        password,
      });

      return response.data;
    } catch (error: any) {
      throw ErrorService.handleError(error);
    }
  }

  public async logout(token: string) {
    try {
      await this.apiInstance.post('/logout', undefined, this.config(token));
    } catch (error: any) {
      throw ErrorService.handleError(error);
    }
  }

  public async changePassword(token: string, userId: number, body: ChangePasswordRequest): Promise<any> {
    try {
      const response = await this.apiInstancePrivate.put(
        `/users/${userId}/change`,
        body,
        this.config(token),
      );

      return response.data;
    } catch (error: any) {
      throw ErrorService.handleError(error);
    }
  }
}
const authService = new AuthServive();

export default authService;
