type Neighborhood = { name: string }

const neighborhoods: Array<Neighborhood> = [
  { name: "CENTRO" },
  { name: "CAIS" },
  { name: "MIRIM" },
  { name: "SUAPE" },
  { name: "CAÇÃO" },
  { name: "ALTOS" },
  { name: "CURURUPEBA" },
  { name: "QUITÉRIA VELHA" },
  { name: "QUITÉRIA NOVA" },
  { name: "APICUM" },
  { name: "CAMINHO DA LUZ" },
  { name: "BARBEIRINHO" },
  { name: "ÁREA DE LAZER" },
  { name: "MARIA GUARDA" },
  { name: "NOVA MADRE DE DEUS" },
];

export default neighborhoods;
