const styles: any = {
    main: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '20px 5px'
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    cardFam: {
        backgroundColor: '#FFFFFF',
        borderRadius: 10,
        boxShadow: '0px 0px 6px #00000029',
        overflow: 'hidden',
    },
    cardHeader: {
        display: 'flex',
        padding: '10px 20px',
        backgroundColor: '#F1663733',
        alignItems: 'center',
    },
    cardImage: {
        maxWidth: '60px',
        minWidth: '60px',
        marginRight: 20,
    },
    cardHeaderTitle: {
        color: '#F16637',
        fontWeight: 'bold',
        fontSize: 17,
    },
    boxUser: {
        backgroundColor: '#0A0A0A59',
        color: '#FFFFFF',
        width: '40px',
        height: '40px',
        borderRadius: '10px',
        boxShadow: '-2px 0 6px #00000029',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20,
    },
    boxUserResponsable: {
        backgroundColor: '#F16637',
        color: '#FFFFFF',
        width: '40px',
        height: '40px',
        borderRadius: '10px',
        boxShadow: '-2px 0 6px #00000029',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20,
    },
    cardCitizen: {
        display: 'flex',
        padding: '10px 20px',
        borderBottom: '1px solid #00000029'
    },
    cardCitizenTitle: {
        color: '#0A0A0A',
        fontWeight: 'bold',
        fontSize: 13,
    },
    cardInactive: {
        color: '#bfbfbf',
        fontSize: 13,
    },
    cardCitizenSubtitle: {
        color: '#0A0A0A',
        fontStyle: 'italic',
        fontSize: 13,
    },
    divTag: {
        backgroundColor: '#F1663733',
        padding: '10px 20px',
        color: '#F16637',
        borderRadius: 5,
        fontWeight: 'bold',
        fontSize: 12,
        marginRight: 15,
    },
    buttonAdd: {
        backgroundColor: '#F16637',
        color: '#FFFFFF',
        fontSize: 12,
        textTransform: 'capitalize',
        margin: '10px 0',
    },
    citizenObito: {
        display: 'flex',
        padding: '10px 20px',
        backgroundColor: '#00000029',
        borderBottom: '1px solid #00000029'
    }
};

export default styles;