import { colors } from '@material-ui/core';

const painelColors: string[] = [
  colors.green['300'],
  colors.red['300'],
  colors.blueGrey['300'],
  colors.cyan['300'],
  colors.purple['300'],
  colors.indigo['300'],
  colors.lightBlue['300'],
  colors.lightGreen['300'],
  colors.purple['100'],
  colors.teal['300'],
  colors.yellow['300'],
  colors.pink['200'],
  colors.lightBlue['600'],
  colors.deepOrange['300'],
  colors.deepPurple['300'],
  colors.amber['300'],
  colors.blueGrey['300'],
  colors.grey['100'],
  colors.orange['100'],
  colors.lime['700'],
  colors.purple['800'],
  colors.teal['700'],
  colors.yellow['100'],
  colors.green['400'],
  colors.indigo['100'],
]

export const newPainelColors: string[] = [
    '#2298FF',
    '#FF9800',
    '#4CAF50',
    '#FCAC5F',
    '#6AD1CC',
    '#d2527f',
    '#db0a5b',
    '#dcc6e0',
    '#a96dad',
    '#8c14fc',
    '#c8f7c5',
    '#66cc99',
    '#16a085',
    '#00b16a',
    '#ffffcc',
    '#f89406',
    '#f15a22',
    '#abb7b7',
    '#dadfe1',
    '#67809f',
]

export default painelColors;