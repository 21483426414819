import React, {
  useState,
  useEffect,
  useContext,
} from 'react';
import {
  Grow,
  Grid,
  Menu,
  Paper,
  Popper,
  Button,
  MenuItem,
  MenuList,
  IconButton,
  ClickAwayListener,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';

import withSnackbar from '../../../templates/WithSnackbar';
import TableMain from '../../../templates/TableMain';

import styles from './styles';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../../../providers/Auth';
import { CommonObjectType } from '../../../../interfaces/entities/common-object-type.entity';
import DeliveryPostService from '../../../../services/DeliveryPostService';
import Loading from '../../public/Loading';
import { MoreVert } from '@material-ui/icons';
import ConfirmDeleteDialog from './components/ConfirmDeleteDialog';

type Props = { classes: any, login: Function, openSnackbar: any, auth: any };

interface Column {
  id: 'id' | 'description' | 'assigned_users' | 'actions'
  label: string;
  minWidth?: number;
  align?: string;
  format?: (value: number) => string;
  render?: (rowData: any) => any;
}

const DeliveryPostsList: React.FC<Props> = (props) => {
  const { classes, openSnackbar } = props;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [choosedPost, setChoosedPost] = useState<CommonObjectType>();
  const [posts, setPosts] = useState<CommonObjectType[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openFilterOptions, setOpenFilterOptions] = React.useState(false);
  const { accessToken, user } = useContext(AuthContext);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const columns: Column[] = [
    { id: 'description', label: 'Descrição', minWidth: 170 },
    { id: 'assigned_users', label: 'Usuários Atribuídos', minWidth: 170 },
    {
      id: 'actions', label: 'Ações', align: 'right', render: (rowData: CommonObjectType) => {
        return (
          <IconButton
            style={{ padding: 0 }}
            aria-label="Menu de ações"
            onClick={(event) => handleClick(event, rowData)}
          >
            <MoreVert />
          </IconButton>
        );
      }
    },
  ];

  useEffect(() => {
    if (!posts) getAllPosts();
  })

  const getAllPosts = async () => {
    setIsLoading(true);
    try {
      const response = await DeliveryPostService.getAllPosts(accessToken);

      setPosts(response.results);
    } catch (error: any) {
      handleError(error.message);
    } finally {
      setIsLoading(false);
    };
  };

  function handleError(e: any) {
    openSnackbar('error', e);
  };

  const openCreateForm = () => {
    history.push('/app/postos/cadastrar');
  };

  const openConfirmDialog = () => {
    setShowConfirmDialog(true);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, rowData: CommonObjectType) => {
    setAnchorEl(event.currentTarget);
    setChoosedPost(rowData);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilter = () => {
    setOpenFilterOptions((prevOpen) => !prevOpen);
  };

  const orderBy = (option: number) => {
    handleFilter();
  };

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem className={classes.buttonCancel} onClick={() => openConfirmDialog()}>Excluir</MenuItem>
      </Menu>

      <Grid container>
        <Grid item xs={12} style={{ margin: '30px 35px' }}>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', marginBottom: '30px' }}>
            <span className={classes.pageTitle}>Postos de Entrega de Benefícios</span>
            <div className={classes.pageLine}></div>
            <div style={{ position: 'relative' }}>
              <Button className={classes.btnFilter} color="primary" variant="outlined" onClick={handleFilter} endIcon={<FilterListOutlinedIcon />}>
                Filtrar
              </Button>
              <Popper open={openFilterOptions} role={undefined} transition disablePortal className={classes.popper}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleFilter}>
                        <MenuList autoFocusItem={openFilterOptions} id="menu-list-grow" onKeyDown={handleFilter}>
                          <MenuItem onClick={() => orderBy(1)}>Ordem Alfabética</MenuItem>
                          <MenuItem onClick={() => orderBy(2)}>Mais Recentes</MenuItem>
                          <MenuItem onClick={() => orderBy(3)}>Mais Antigos</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
            <Button className={classes.btnAdd} variant="contained" color="primary" onClick={openCreateForm} endIcon={<AddOutlinedIcon />}>
              Cadastrar Novo Posto
            </Button>
          </Grid>
          {isLoading ?
            <Loading />
            :
            <TableMain array={posts} columns={columns} />
          }
        </Grid>
      </Grid>
      <ConfirmDeleteDialog
        postId={Number(choosedPost?.id)}
        showDialog={showConfirmDialog}
        toggleDialog={() => setShowConfirmDialog(prevState => !prevState)}
        openSnackbar={openSnackbar}
      />
    </>
  );
}

export default withStyles(styles)(withSnackbar(DeliveryPostsList));
