import React from 'react';
import { withStyles } from '@material-ui/core';

import styles from './styles';

type Props = { classes: any, children: any, border?: string };

const CardMain: React.FC<Props> = (props) => {
  const { classes, children, border } = props;
  const style = border ? { border: ` 1px solid ${border}` } : {};
  return (
    <div className={classes.cardContainer} style={style}>
      {children}
    </div>
  );
}

export default withStyles(styles)(CardMain);