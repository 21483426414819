import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import BenefitList from '../../../../components/pages/private/BenefitList';
import FishGrantSearch from '../../../../components/pages/private/FishGrantSearch';
import { Benefits } from '../../../../enums/Benefits';

const PeixeRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <BenefitList benefit={Benefits.JUNEBASKET} />
      </Route>
      <Route path={`${path}/novo`}>
        <FishGrantSearch />
      </Route>
    </Switch>
  );
}

export default PeixeRoutes;
