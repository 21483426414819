interface Status {
  id: number;
  name: string;
}

export enum DeliverableStatus {
  APTO = 8,
  CONFIRMADO = 9,
  ENTREGUE = 10,
};

const DeliverableBenefitStatus: Status[] = [
  { id: 8, name: 'Apto' },
  { id: 9, name: 'Confirmado' },
  { id: 10, name: 'Entregue' },
];

export default DeliverableBenefitStatus;