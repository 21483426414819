/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useMemo } from 'react';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { Grid, withStyles, Button, IconButton } from '@material-ui/core';
import { PermIdentity } from '@material-ui/icons';

import withSnackbar from '../../../templates/WithSnackbar';

import styles from './styles';
import { AuthContext } from '../../../../providers/Auth';
import BenefitGranted from '../../../../model/entities/BenefitGranted';
import Benefit from '../../../../model/entities/Benefit';
import citizenService from '../../../../services/CitizenService';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { CitizenEntity } from '../../../../interfaces/entities/citizen.entity';
import Loading from '../../public/Loading';
import formatType from '../../../../utils/formatType';
import sexualOrientations from '../../../../constants/sexualOrientations';
import registrationsSituation from '../../../../constants/registrationsSituation';

type Props = {
  classes: any;
  login: Function;
  openSnackbar: any;
  auth: any;
  history: any;
  benefits: Benefit[] | undefined;
  benefitsGranted: BenefitGranted[] | undefined;
};

const CitizenInfo: React.FC<Props> = (props) => {
  const { classes, openSnackbar } = props;
  const history = useHistory();
  const { cidadaoId } = useParams<{ cidadaoId: string }>();
  const [citizen, setCitizen] = useState<CitizenEntity>();
  const { accessToken } = useContext(AuthContext);
  const [edit] = useState<boolean>();
  const [isLoadingInfo, setIsloadingInfo] = useState(false);

  function useQueryParams() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const queryParams = useQueryParams();

  function getDate(element: any) {
    const timestamp = element?.seconds * 1000;
    const dateString = new Date(timestamp).toLocaleDateString();
    return dateString || '';
  }

  useEffect(() => {
    getCitizen();
  }, []);

  async function getCitizen() {
    setIsloadingInfo(true);
    try {
      const result = await citizenService.getCitizen(
        Number(cidadaoId),
        accessToken
      );

      setCitizen(result);
    } catch (error: any) {
      handleError(error?.message);
    } finally {
      setIsloadingInfo(false);
    }
  }

  const handleEdit = () => {
    const houseId = queryParams.get('houseId');
    history.push(`/app/cidadao/${cidadaoId}/editar?houseId=${houseId}`);
  };

  function handleError(e: any) {
    openSnackbar('error', e);
  }

  if (isLoadingInfo) {
    return (
      <Loading />
    );
  }

  return (
    <Grid container className={classes.containerForm}>
      <Grid item xs={12} style={{ maxHeight: '100%' }}>
        <Grid item xs={12} className={classes.header}>
          <Grid item xs={1}>
            <IconButton aria-label="fechar" className={classes.closeButton} onClick={() => history.goBack()}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <div className={classes.boxUserResponsable}>
              <PermIdentity />
            </div>
          </Grid>
          <Grid item xs className={classes.flexColumnSpaceBetween}>
            <span className={classes.headerTitleBold}>INFORMAÇÕES</span>
            <span className={classes.headerTitleBold}>{citizen?.name}</span>
            <span className={classes.headerSubtitle}>{citizen?.gender.description}</span>
          </Grid>
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            {edit && <Button className={classes.btnEdit} variant="outlined" onClick={handleEdit}>
              Editar
            </Button>}
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.body}>
          <Grid item xs={12}>
            <Grid item xs={12} className={classes.flexColumn} style={{ padding: '20px' }}>
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <PermIdentity style={{ color: '#D44312', marginRight: 15 }} />
                <span className={classes.title}>Dados Pessoais</span>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', padding: '10px 20px' }}
              >
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Nome Completo</span>
                  <span className={classes.conditionSubtitle}>{citizen?.name}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Nome Social</span>
                  <span className={classes.conditionSubtitle}>{citizen?.social_name || 'Não Informado'}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Data de Nascimento</span>
                  <span className={classes.conditionSubtitle}>{getDate(citizen?.birthdate)}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Sexo</span>
                  <span className={classes.conditionSubtitle}>{citizen?.gender?.description}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Orientação Sexual</span>
                  <span className={classes.conditionSubtitle}>{formatType(citizen?.sexual_orientation_id as number, sexualOrientations)}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Raça/Cor</span>
                  <span className={classes.conditionSubtitle}>{citizen?.complexion ? citizen?.complexion?.description : 'Sem registro'}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Nome da Mãe</span>
                  <span className={classes.conditionSubtitle}>{citizen?.mother_name || 'Desconhecido'}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Nome do Pai</span>
                  <span className={classes.conditionSubtitle}>{citizen?.father_name || 'Desconhecido'}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Nacionalidade</span>
                  <span className={classes.conditionSubtitle}>{citizen?.nationality ? citizen?.nationality?.description : 'Sem registro'}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>País de Nascimento</span>
                  <span className={classes.conditionSubtitle}>
                    {citizen?.birth_country}
                  </span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Naturalidade</span>
                  <span className={classes.conditionSubtitle}>
                    {'Não Informado'}
                  </span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>CPF</span>
                  <span className={classes.conditionSubtitle}>{citizen?.cpf || 'Sem registro'}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>RG</span>
                  <span className={classes.conditionSubtitle}>{citizen?.rg}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Responsável Familiar</span>
                  <span className={classes.conditionSubtitle}>{citizen?.cpf ? 'Sim' : 'Não'}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Grau de Escolaridade</span>
                  <span className={classes.conditionSubtitle}>{citizen?.study_interest}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Situação no Mercado de Trabalho</span>
                  <span className={classes.conditionSubtitle}>{citizen?.job_situation ? citizen?.job_situation?.description : 'Sem registro'}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Religião</span>
                  <span className={classes.conditionSubtitle}>{citizen?.religion ? citizen?.religion?.description : 'Sem registro'}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Portador de Alguma Deficiência</span>
                  <span className={classes.conditionSubtitle}>
                    {'Não'}
                  </span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Telefone</span>
                  <span className={classes.conditionSubtitle}>{citizen?.telephone || 'Não Informado'}</span>
                </Grid>
                <Grid item xs={3} className={classes.conditionInfo}>
                  <span className={classes.conditionTitle}>Situação do Cadastro</span>
                  <span className={classes.conditionSubtitle}>{formatType(citizen?.registration_status_id as number, registrationsSituation)}</span>
                </Grid>
              </Grid>
            </Grid>
            <div className={classes.divisor}></div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(withSnackbar(CitizenInfo));
