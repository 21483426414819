/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { Grid, withStyles } from '@material-ui/core';
import { Room } from '@material-ui/icons';
import styles from './styles';
import { ReactOsmGeocoding } from '@paraboly/react-osm-geocoding'
import '@paraboly/react-osm-geocoding/dist/index.css'

type Props = {
  classes: any,
  zoomLevel: number,
  setCoordinates: (lat: number, lng: number) => void,
  latitude: number | undefined,
  longitude: number | undefined,
  address: string,
};

type PropsChild = { lat: number, lng: number, classes: any };


const CustomMarker = (props: PropsChild) => {
  const { classes } = props;
  return (
    <div className={classes.markerWrapper}>
      <Room color='primary' className={classes.icon} />
    </div>
  )
}

const Map = (props: Props) => {
  const { classes, zoomLevel, setCoordinates, latitude, longitude, address } = props;
  const [loadedAddress, setLoadedAddress] = useState<boolean>(false);
  const saoFranciscoLatLng = { lat: -12.620176, lng: -38.6852702 };
  const mapOptions = {
    gestureHandling: 'cooperative',
  }

  useEffect(() => {
    if ((!loadedAddress && !address) && (!latitude && !longitude)) {
      getCoordinatesByAddress();
      setLoadedAddress(true);
    };
  }, []);

  const getCoordinatesByAddress = () => {
    setLoadedAddress(true);
    let url = `https://nominatim.openstreetmap.org/search?format=json&q=${address}&countrycodes=BR`;
    fetch(url).then(function (response) {
      return response.json();
    }).then(function (data) {
      if (data.length > 0) {
        const addressData = data[0];
        setCoordinates(parseFloat(addressData.lat), parseFloat(addressData.lon));
      };
    }).catch(error => {
      console.error(error);
    });
  };

  return (
    <>
      <Grid container className="Map" style={{ height: '500px', width: '100%' }}>
        <Grid item xs={12}>
          <div className={classes.mapWrapper}>
            <Grid item xs={12} className={classes.searchBox}>
              <ReactOsmGeocoding
                placeholder="Pesquise o local"
                acceptLanguage="pt-br"
                city=""
                countrycodes="BR"
                callback={(data: any) => {
                  const { lat, lon } = data;
                  setCoordinates(parseFloat(lat), parseFloat(lon));
                }}
              />
            </Grid>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_API_KEY || '' }}
              defaultCenter={saoFranciscoLatLng}
              defaultZoom={zoomLevel}
              center={latitude && longitude ? { lat: latitude, lng: longitude } : saoFranciscoLatLng}
              options={mapOptions}
              onClick={({ lat, lng }) => {
                setCoordinates(lat, lng);
              }}
            >
              {latitude && longitude ?
                <CustomMarker
                  classes={classes}
                  lat={latitude}
                  lng={longitude}
                />
                : ''}
            </GoogleMapReact>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(Map);
