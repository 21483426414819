import {
  useRef,
  useState,
  useContext,
} from 'react';
import {
  Box,
  Menu,
  Grid,
  Button,
  Tooltip,
  MenuItem,
  IconButton,
  withStyles,
  Typography,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { GetApp } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { useHistory, useRouteMatch } from 'react-router-dom';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import { CellParams, ColDef, DataGrid } from '@material-ui/data-grid';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';

import MySelect from '../../../../../MySelect';
import MyTextInput from '../../../../../MyTextInput';
import MyInputMask from '../../../../../MyInputMask';
import ExcelExportReport, { ExportExcelRefProps } from '../ExcelExport';


import { AuthContext } from '../../../../../../providers/Auth';
import CitizenService from '../../../../../../services/CitizenService';
import { CitizenEntity } from '../../../../../../interfaces/entities/citizen.entity';

import styles from './styles';
import sexes from '../../../../../../constants/sexes';
import races from '../../../../../../constants/races';
import formatType from '../../../../../../utils/formatType';
import religions from '../../../../../../constants/religions';
import schoolings from '../../../../../../constants/schoolings';
import situationsJobMarket from '../../../../../../constants/situationsJobMarket';

type Props = {
  classes: any;
  openSnackbar: any;
};

interface FormData {
  name?: string;
  cpf?: string;
  nis?: string;
  gender?: number;
  complexion?: number;
  schooling?: number;
  job_situation?: number;
  religion?: number;
  has_deficiency?: boolean;
}

const CitizenTab: React.FC<Props> = (props) => {
  const { classes, openSnackbar } = props;
  const { accessToken } = useContext(AuthContext);
  const dateFns = new DateFnsUtils();
  const history = useHistory();
  const { url } = useRouteMatch();

  const [showTable, setShowTable] = useState<boolean>(false);
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [citizenReport, setCitizenReport] = useState<CitizenEntity[]>([]);

  const formRef = useRef<FormHandles>(null);
  const exportExcelRef = useRef<ExportExcelRefProps>(null);

  function navigateToCitizenedit(id: number) {
    history.push(`/app/cidadao/${id}/editar?returnPath=${url}`);
  }

  const columnWidth = 170;
  const reportColumns: ColDef[] = [
    {
      field: 'name',
      headerName: 'Nome completo',
      width: columnWidth,
    },
    {
      field: 'cpf',
      headerName: 'CPF',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => !!value ? (value as string).replace(/(d{3})(d{3})(d{3})(d{2})/, '$1.$2.$3-$4') : 'Sem registro',
    },
    {
      field: 'nis',
      headerName: 'NIS',
      width: columnWidth,
    },
    {
      field: 'birthdate',
      headerName: 'Data de nascimento',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => !!value ? dateFns.format(new Date(value as string), 'dd/MM/yyyy') : 'Sem registro',
    },
    {
      field: 'gender',
      headerName: 'Sexo',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => (value as any)?.description || 'Sem registro',
    },
    {
      field: 'complexion',
      headerName: 'Raça/Cor',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => (value as any)?.description || 'Sem registro',
    },
    {
      field: 'schooling_id',
      headerName: 'Grau de escolaridade',
      width: columnWidth + 40,
      valueFormatter: ({ value }: CellParams) => (value as number) ? formatType((value as number), schoolings) : 'Sem registro',
    },
    {
      field: 'job_situation',
      headerName: 'Situação no mercado de trabalho',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => (value as any)?.description || 'Sem registro',
    },
    {
      field: 'religion',
      headerName: 'Religião',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => (value as any)?.description || 'Sem registro',
    },
    // {
    //   field: 'religion',
    //   headerName: 'Deficiência',
    //   valueFormatter: ({ value }: CellParams) => (value as any)?.description || 'Sem registro',
    // },
    {
      field: 'id',
      headerName: 'Editar',
      renderCell: ({ value }: CellParams) => (
        <Tooltip title="Editar cidadão">
          <IconButton onClick={() => navigateToCitizenedit(value as number)}>
            <EditOutlinedIcon />
          </IconButton>
        </Tooltip>
      )
    },
  ];

  const handleError = (e: any) => {
    openSnackbar('error', e);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function formatExcelExportData() {
    return citizenReport?.map((report) => ({
      ...report,
      birthdate: report?.birthdate ? dateFns.format(new Date(report.birthdate), 'dd/MM/yyyy') : 'Sem registro',
      religion: report.religion?.description || 'Sem registro',
      job_situation: report.job_situation?.description || 'Sem registro',
      schooling_id: report.schooling_id ? formatType((report.schooling_id as number), schoolings) : 'Sem registro',
      complexion: report.complexion?.description || 'Sem registro',
      gender: report.gender?.description || 'Sem registro',
    }));
  }

  const onSubmit = async (data: FormData) => {
    formRef.current?.setErrors({});
    setIsLoadingReport(true);

    const validateLength = Yup.string().test('len', 'Preencha mais de 3 caracters', function (value) {
      if (!!value) {
        return value?.length >= 3;
      }

      return true;
    });
    const schema = Yup.object().shape({
      name: validateLength,
      cpf: validateLength,
      nis: validateLength,
    });

    try {
      await schema.validate(data, { abortEarly: false });
      const response = await CitizenService.getCitizenReport(accessToken, {
        ...data,
        // eslint-disable-next-line no-useless-escape
        cpf: data?.cpf?.replace(/\.|\-/g, ''),
      });

      setCitizenReport(response.results);
      setShowTable(true);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        formRef.current?.setErrors(errorMessages);
      } else {
        handleError(error?.message);
      }
    } finally {
      setIsLoadingReport(false);
    }
  };

  return (
    <Grid item xs={12} style={{ display: 'flex' }}>
      <Grid item xs={12} className={classes.main}>
        <Grid item xs={12} className={classes.filterDiv}>
          <Grid item xs={12} className={classes.filterTitle}>
            <span>Filtrar</span>
            <FilterListOutlinedIcon style={{ marginLeft: 10 }} />
          </Grid>

          <Form ref={formRef} onSubmit={onSubmit}>

            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Grid container spacing={2} item xs={12} style={{ alignItems: 'flex-end' }}>
                <Grid item xs={6} sm={4}>
                  <MyTextInput
                    label="Nome"
                    name="name"
                    placeholder="Digite o nome"
                    InputLabelProps={{
                      style: { color: '#707070', fontWeight: 'bold' },
                    }}
                    InputProps={{
                      style: { fontSize: 14 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonOutlinedIcon style={{ color: '#707070', fontSize: 16 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <MyInputMask
                    name="cpf"
                    mask="999.999.999-99"
                    type="cpf"
                    label="CPF"
                    placeholder="000.000.000-00"
                    InputLabelProps={{
                      style: { color: '#707070', fontWeight: 'bold' },
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" style={{ color: '#707070' }} />,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <MyTextInput
                    name="nis"
                    label="Nis"
                    placeholder="NIS"
                    InputLabelProps={{
                      style: { color: '#707070', fontWeight: 'bold' },
                    }}
                    InputProps={{
                      style: { fontSize: 14 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <AssignmentIcon style={{ color: '#707070', fontSize: 16 }} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} style={{ marginTop: 20 }}>
              <Grid container spacing={2} item xs={12} style={{ alignItems: 'flex-end' }}>
                <Grid item xs={6} sm={4}>
                  <MySelect
                    label="Sexo"
                    enableAllSelected
                    name="gender"
                    options={sexes.map((type) => ({ value: type.id, label: type.name }))}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <MySelect
                    label="Raças/Cor"
                    enableAllSelected
                    name="complexion"
                    options={races.map((type) => ({ value: type.id, label: type.name }))}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <MySelect
                    name="schooling"
                    enableAllSelected
                    label="Escolaridade"
                    options={schoolings.map((type) => ({ value: type.id, label: type.name }))}
                  />
                </Grid>
                <Grid item xs={6} sm={4} style={{ marginTop: 20 }}>
                  <MySelect
                    label="Situação no mercado"
                    enableAllSelected
                    name="job_situation"
                    options={situationsJobMarket.map((type) => ({ value: type.id, label: type.name }))}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <MySelect
                    name="religion"
                    enableAllSelected
                    label="Religião"
                    options={religions.map((type) => ({ value: type.id, label: type.name }))}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <MySelect
                    name="has_deficiency"
                    enableAllSelected
                    label="Possui deficiêcia?"
                    options={[{ value: true, label: 'Sim' }, { value: false, label: 'Não' }]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isLoadingReport}
                      className={classes.buttonRegister}
                    >
                      Consultar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Grid>
        {showTable ? (
          <Grid item xs={12} style={{ marginTop: 20 }}>
            {
              isLoadingReport ? (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <CircularProgress size={55} />
                </Box>
              ) : (
                <>
                  <Box className={classes.tableToolBar} display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6">Resultados</Typography>
                    <Button
                      color="primary"
                      variant="outlined"
                      aria-haspopup="true"
                      endIcon={<GetApp />}
                      aria-controls="simple-menu"
                      onClick={handleOpenMenu}>
                      Exportar
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={handleCloseMenu}>Gerar pdf</MenuItem>
                      <MenuItem onClick={() => exportExcelRef.current?.generateExcel()}>Gerar excel</MenuItem>
                    </Menu>
                  </Box>
                  <Box className={classes.tableReport}>
                    <DataGrid
                      columns={reportColumns}
                      rows={citizenReport}
                      autoHeight
                      localeText={{
                        footerTotalRows: 'de',
                        footerPaginationRowsPerPage: 'Individuos por página'
                      }}
                    />
                  </Box>
                </>
              )
            }
          </Grid>
        ) : (
          <Grid item xs={12} className={classes.divCenterText}>
            <Grid item xs={12} className={classes.divFlex1}>
              <img alt="Sem resultados" src="/assets/no_results.png" className={classes.image} />
              <Typography className={classes.noResultsSpan}>Selecione os filtros para obter o relatório</Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
      <ExcelExportReport
        ref={exportExcelRef}
        columns={reportColumns}
        data={formatExcelExportData()}
        fileName={`relatório-de-cidadão-${dateFns.format(new Date(), 'dd-MM-yyyy')}.xls`}
      />
    </Grid>
  );
}

export default withStyles(styles)(CitizenTab);
