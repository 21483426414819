import React, { useState, useEffect, useContext } from 'react';
import {
  Dialog,
  DialogContent,
  withStyles,
  Typography,
  Box,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { WarningOutlined } from '@material-ui/icons';

import styles from './styles';
import { number } from 'yup';
import UserService from '../../../../../../services/UserService';
import { AuthContext } from '../../../../../../providers/Auth';

interface Props {
  userId: number;
  showDialog: boolean;
  toggleDialog(): void;
  classes: any;
  openSnackbar: any;
}

const ConfirmDeleteDialog: React.FC<Props> = (props) => {
  const { showDialog, toggleDialog, classes, userId, openSnackbar } = props;
  const { accessToken } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  async function deleteUser(userId: number) {
    setIsLoading(true);
    try {
      await UserService.delete(userId, accessToken);

    } catch (error: any) {
      handleError(error.message);
    } finally {
      setIsLoading(false);
      toggleDialog();
      history.go(0);
    };
  };

  function handleError(e: any) {
    openSnackbar('error', e);
  };

  return (
    <Dialog open={showDialog} onClose={toggleDialog} aria-labelledby="alert-dialog-title">
      <DialogContent className={classes.alertContent}>
        <WarningOutlined className={classes.icon} fontSize="large" color="primary" />
        <Typography className={classes.text} variant="h6" color="primary">
          O Usuário será desativado. Deseja prosseguir?
        </Typography>
        <Box display="flex" justifyContent="flex-end" gridGap={8}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => toggleDialog()}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => deleteUser(userId)}
            disabled={isLoading}
          >
            Confirmar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(ConfirmDeleteDialog);
