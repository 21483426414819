/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from 'react'

import { useField } from '@unform/core'
import { Checkbox } from '@material-ui/core';

interface MyCheckBoxProps {
  name: string;
  label?: string;
  onChange?: (event: any, value?: any) => void;
  initialChecked?: boolean;
  color?: "primary" | "secondary" | "default" | undefined
}

export default function MyCheckbox({ name, color, onChange, initialChecked }: MyCheckBoxProps) {
  const checkBoxRef = useRef(null);
  const { fieldName, defaultValue, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: checkBoxRef,
      getValue: ref => {
        return ref.current.checked
      },
      clearValue: ref => {
        ref.current.checked = false
      },
      setValue: (ref, checked) => {
        ref.current.checked = checked || defaultValue
      },
    })
    if (onChange && defaultValue) {
      onChange(null, defaultValue)
    };
  }, [defaultValue, fieldName, registerField])

  return (
    <Checkbox
      defaultChecked={defaultValue || initialChecked}
      inputRef={checkBoxRef}
      name={name}
      color={color}
      onChange={onChange}
    />
  )
}