const styles: any = {
    markerWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: '23px',
        height: '23px',
        backgroundColor: '#6d6d6d',
        border: '1px solid #fff',
        borderRadius: '100%',
        userSelect: 'none',
        transform: 'translate(-50%, -50%)',
        '&:hover': {
            zIndex: 1,
        },
    },
    icon: {
        color: '#FFF',
        fontSize: '18px',
    },
    mapWrapper: {
        width: '100%',
        height: 500,
        position: 'relative',
    },
    searchBox: {
        position: 'absolute',
        zIndex: 999,
        top: '10px',
        left: '10px',
    }
};

export default styles;