const styles: any = {
    button: {
        fontSize: 13,
        width: "100%",
        backgroundColor: '#0056C0',
        "&:hover": {
            backgroundColor: '#00338d'
        }
    },
};

export default styles;