import React from 'react';

import { Grid, withStyles } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import withSnackbar from '../../../templates/WithSnackbar';
import styles from './styles';

type Props = {
  classes: any,
  login: Function,
  openSnackbar: any,
  auth: any,
};

const Loading: React.FC<Props> = (props) => {
  const { classes } = props;

  return (
    <Grid container className={classes.mainGrid}>
      <CircularProgress style={{ color: '#a11908' }}/>
    </Grid>
  );
}

export default withStyles(styles)(withSnackbar(Loading));