import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';

type Props = { handleSubmit: any, openDialog: boolean, handleCloseDialog: any };

const NoCoordinatesDialog = (props: Props) => {
    const { handleSubmit, openDialog, handleCloseDialog } = props;
    useEffect(() => {
    }, [openDialog])



    return (
        <div>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Coordenadas não encontradas</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Não foi possível obter as coordenadas do local indicado. Deseja continuar mesmo assim?
              </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Não
                    </Button>
                    <Button 
                        onClick={() => {
                            handleCloseDialog();
                            handleSubmit()}
                        }
                        color="primary"
                        autoFocus
                    >
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default NoCoordinatesDialog;