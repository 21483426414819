import { useRef, useEffect } from 'react'

import { useField } from '@unform/core'
import {
  Select,
  Checkbox,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core'

interface Option {
  value: any;
  label: string;
}

interface SelectProps {
  name: string;
  options: Option[];
  styles?: object;
  required?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  isCheck?: boolean;
  initialValue?: any;
  checkOptions?: string[];
  onChange?: (event: any) => void;
  label: string;
  value?: string | boolean | number | string[] | boolean[] | number[];
  dismarkItem?: boolean;
  enableAllSelected?: boolean;
}

const MySelect: React.FC<SelectProps> = (props) => {
  const {
    name,
    label,
    value,
    styles,
    options,
    required,
    disabled,
    multiple,
    onChange,
    dismarkItem = true,
    enableAllSelected = false,
  } = props;
  const selectRef = useRef<HTMLSelectElement>(null)

  const { fieldName, defaultValue = '', registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      ref: selectRef,
      name: fieldName,
      getValue: ref => {
        if (ref.current?.value === '') {
          return undefined
        }
        return ref.current?.value
      },
      setValue: (ref, newValue) => {
        ref.current.value = newValue
      },
      clearValue: ref => {
        ref!.current!.value = ''
      },
    })
  }, [fieldName, registerField])

  return (
    <FormControl style={{ width: '100%' }} error={!!error}>
      <InputLabel shrink id={`${name}-label-id`} style={{ color: '#707070', fontWeight: 'bold', }}>
        {label}
      </InputLabel>
      < Select
        fullWidth
        name={name}
        multiple={multiple}
        value={value}
        disabled={disabled}
        required={required}
        id={fieldName}
        inputRef={selectRef}
        defaultValue={defaultValue}
        labelId={`${name}-label-id`}
        style={styles}
        onChange={onChange}
        renderValue={multiple ? (selected) => (selected as string[]).join(', ') : undefined
        }
      >
        {
          dismarkItem ? (
            <MenuItem value="">
              Desmarcar
            </MenuItem>
          ) : null
        }
        {
          enableAllSelected ? (
            <MenuItem value="" selected>
              Todos
            </MenuItem>
          ) : null
        }
        {options?.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value} >
              {multiple ? (
                <Checkbox checked={(value as string)?.indexOf(name) > -1} />
              ) : null}
              {option.label}
            </MenuItem>
          );
        })}
      </Select >
      {!!error ? <FormHelperText>{error}</FormHelperText> : null}
    </FormControl>
  )
}

export default MySelect;
