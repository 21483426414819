import { Grid, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import Beneficiary from '../../../../model/entities/Beneficiary';
import FishAlert, { FishAlertRef } from '../../../templates/FishAlert';
import withSnackbar from '../../../templates/WithSnackbar';
import Qrcode from '../Qrcode';
import styles from './styles';
import { DeliverableStatus } from '../../../../constants/deliverableBenefitStatus';
import FishConsult from '../FishConsult';
import FishRegister from '../FishRegister';

type Props = { classes: any, login: Function, openSnackbar: any, auth: any, history: any };

const FishManager: React.FC<Props> = (props) => {
  const { classes } = props;
  const [beneficiary, setBeneficiary] = useState<Beneficiary | null>(null);
  const fishAlertRef = useRef<FishAlertRef>(null);

  function handleBenefitDenied() {
    fishAlertRef.current?.openAlert({
      message: 'Você não está apto para confirmar o beneficio',
      type: 'error',
    }, () => {
      setBeneficiary(null);
    });
  }

  function handleBenefitDeliveried() {
    fishAlertRef.current?.openAlert({
      message: `Olá ${beneficiary?.name}, o seu beneficio já foi entregue!`,
      type: 'info',
    }, () => {
      setBeneficiary(null);
    });
  }

  function handleNotFoundBeneficiary() {
    fishAlertRef.current?.openAlert({
      message: 'Desculpe, não encontramos nenhum beneficiário com essas informações!',
      type: 'error',
    }, () => {
      setBeneficiary(null);
    });
  }

  useEffect(() => {
    if (Boolean(beneficiary)) {
      const benetitStatus = beneficiary?.benefit.benefit_grant_status_id;

      switch (benetitStatus) {
        case DeliverableStatus.APTO:
          break;
        case DeliverableStatus.CONFIRMADO:
          break;
        case DeliverableStatus.ENTREGUE:
          handleBenefitDeliveried();
          break;
        default:
          handleBenefitDenied();
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beneficiary?.benefit]);

  return (
    <>
      <Grid container className={classes.pageContainer}>
        <Grid item xs={12} className={classes.pageContent}>
          <img alt="Logo SFC" src="/assets/CESTA-SANTA-2024-ok.png" className={classes.logoSfc} />
          {beneficiary?.benefit.benefit_grant_status_id !== DeliverableStatus.CONFIRMADO ? (
            <Typography className={classes.pageTitle}>
              Cadastramento da Cesta da Semana Santa - {new Date().getFullYear()}
            </Typography>
          ) : null}

          {!Boolean(beneficiary) ? (
            <FishConsult
              onConsultBeneficiary={setBeneficiary}
              handleNotFoundBeneficiary={handleNotFoundBeneficiary}
            />
          ) : null}

          {beneficiary?.benefit.benefit_grant_status_id === DeliverableStatus.APTO ? (
            <FishRegister
              beneficiary={beneficiary}
              onConfirmBenefit={setBeneficiary}
            />
          ) : null}

          {beneficiary?.benefit.benefit_grant_status_id === DeliverableStatus.CONFIRMADO ? (
            <Qrcode beneficiary={beneficiary} />
          ) : null}

          <img alt="Logo SFC" src="/assets/logo_acelera_horizontal.png" className={classes.logoAcelera} />
        </Grid>
      </Grid>
      <FishAlert
        beneficiary={beneficiary}
        setBeneficiary={setBeneficiary}
        ref={fishAlertRef}
      />
    </>
  );
};

export default withStyles(styles)(withSnackbar(FishManager));
