import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import ChangeUserPassword from '../../../components/pages/private/ChangeUserPassword';
import ManageUserPosts from '../../../components/pages/private/ManageUserPosts';

import UserList from '../../../components/pages/private/UserList';
import UserRegister from '../../../components/pages/private/UserRegister';

const UsuarioRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <UserList />
      </Route>
      <Route path={`${path}/cadastrar`}>
        <UserRegister />
      </Route>
      <Route exact path={`${path}/:userId/editar`}>
        <UserRegister />
      </Route>
      <Route exact path={`${path}/:userId/editar/senha`}>
        <ChangeUserPassword />
      </Route>
      <Route exact path={`${path}/:userId/editar/postos`}>
        <ManageUserPosts />
      </Route>
    </Switch>
  );
}

export default UsuarioRoutes;
