import errorService from './ErrorService';
import Client from './api/api';
import BenefitPeixeStatus from '../constants/benefitPeixeStatus';
import races from '../constants/races';
import sexes from '../constants/sexes';
import formatChartLabels from '../utils/formatChartLabels';

interface ArrayType {
  [key: string]: string | number;
  total: number;
}

interface StatusPanelResponse {
  benefit_year: number;
  grant_status: {
    benefit_grant_status_id: number;
    total: number;
  }[];
}

interface ResponseResults<Type> {
  results: Type[];
}

interface ResponseChartData {
  labels: string[];
  data: number[];
}

class BenefitFishService {
  private apiInstance = Client('api');
  private config = (token?: string) => ({ headers: { 'X-Client-Service': process.env[`REACT_APP_X_CLIENT_SERVICE`], 'X-Guest-Key': process.env[`REACT_APP_X_GUEST_KEY`], Authorization: `Bearer ${token}` } });
  private DEFAULT_REF = 'fish';
  private readonly benefitId: number = 2;

  public async confirm(nib: string, updateData: any) {
    try {
      const response = await this.apiInstance.put(`/${this.DEFAULT_REF}/${nib}/confirm`, updateData, this.config());

      return response?.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async consult(cpf: string, nis: string) {
    try {
      const year = new Date().getFullYear();

      const response = await this.apiInstance.get(`/${this.DEFAULT_REF}/citizens/cpf/${cpf}/nis/${nis}/year/${year}`, this.config());

      return response?.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async consultByNib(nib: string) {
    try {
      const response = await this.apiInstance.get(`/${this.DEFAULT_REF}/${nib}`, this.config());

      return response?.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async grant(citizenId: number, nib: string, token: string) {
    try {
      const response = await this.apiInstance.post(`/${this.DEFAULT_REF}/citizens/${citizenId}`, { nib }, this.config(token));

      return response?.data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  private sortAlphabetic(a: any, b: any) {
    if (a.name > b.name) {
      return 1;
    }
    if (a.name < b.name) {
      return -1;
    }
    return 0;
  }
  private sortByYear(a: any, b: any) {

    if (a.benefit_year > b.benefit_year) {
      return 1;
    }
    if (a.benefit_year < b.benefit_year) {
      return -1;
    }
    return 0;
  }

  private sortByGrantStatus(a: any, b: any) {

    if (a.benefit_grant_status_id > b.benefit_grant_status_id) {
      return 1;
    }
    if (a.benefit_grant_status_id < b.benefit_grant_status_id) {
      return -1;
    }
    return 0;
  }

  private formatBarChartData(scope: string, array: ArrayType[], constArray?: any[]) {
    let labels: string[] = [];
    let data: number[] = [];
    if (scope === 'benefit_year') {
      array.sort((a, b) => {
        return Number(a['benefit_year']) - Number(b['benefit_year']);
      });
    }
    array.forEach((item) => {
      const attrType = item[scope];
      if (typeof attrType === 'string' || scope === 'benefit_year') {
        labels.push(attrType as string);
        data.push(item.total);
      }

      if (typeof attrType === 'number' && !!constArray) {
        // eslint-disable-next-line array-callback-return
        const newConstArray = constArray.filter((element, i) => {
          if (constArray.indexOf(element) === i) return element.name;
        });
        labels = formatChartLabels(newConstArray);
        constArray.sort(this.sortAlphabetic).forEach((type, index) => {
          if (type.id === attrType) {
            data[index] = item.total;
          }
        });
      }
    });

    return {
      labels,
      data,
    };
  }

  private formatStatusChartData(result: StatusPanelResponse[]) {
    let labels: string[] = [];
    let datasets: { label: string, data: any[] }[] = [];

    result.sort(this.sortByYear).forEach((item) => {
      // eslint-disable-next-line array-callback-return
      const newConstArray = BenefitPeixeStatus.filter((element, i) => {
        if (BenefitPeixeStatus.indexOf(element) === i) return element.name;
      });
      labels = formatChartLabels(newConstArray);

      item.grant_status.sort(this.sortByGrantStatus).forEach((status) => {
        let exists = datasets.findIndex((value) => value.label === String(item.benefit_year))
        if (exists >= 0) {
          BenefitPeixeStatus.forEach((type, index) => {
            if (type.id === status.benefit_grant_status_id) {
              datasets[exists].data[index] = status.total;
            }
          });
        } else {
          datasets.push({
            data: [status.total],
            label: String(item.benefit_year),
          });
        }
      });
    });

    return {
      labels,
      datasets
    };
  }

  public async getTotalBeneficiaryByStatus(
    token: string,
  ): Promise<any> {
    try {
      const { data } = await this.apiInstance
        .get<ResponseResults<StatusPanelResponse>>(
          `/panels/benefits/${this.benefitId}/status/counts`, this.config(token)
        );

      return this.formatStatusChartData(data.results);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryByYear(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const { data } = await this.apiInstance
        .get<ResponseResults<{ benefit_year: string, total: number }>>(
          `/panels/benefits/${this.benefitId}/year/counts`,
          this.config(token)
        );

      return this.formatBarChartData('benefit_year', data.results);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryByGender(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const { data } = await this.apiInstance
        .get<ResponseResults<{ gender_id: number, total: number }>>(
          `/panels/benefits/${this.benefitId}/gender/counts`,
          this.config(token)
        );

      return this.formatBarChartData('gender_id', data.results, sexes);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryByComplexion(
    token: string,
  ): Promise<ResponseChartData> {
    try {
      const { data } = await this.apiInstance
        .get<ResponseResults<{ complexion_id: number, total: number }>>(
          `/panels/benefits/${this.benefitId}/complexion/counts`,
          this.config(token)
        );

      return this.formatBarChartData('complexion_id', data.results, races);
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryRegistered(
    token: string,
  ): Promise<{ total: number }> {
    try {
      const { data } = await this.apiInstance
        .get(
          `/panels/benefits/${this.benefitId}/registered/counts`,
          this.config(token)
        );

      return data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalBenefitiaryIncomplete(
    token: string,
  ): Promise<{ total: number }> {
    try {
      const { data } = await this.apiInstance
        .get(
          `/panels/benefits/${this.benefitId}/incomplete/counts`,
          this.config(token)
        );

      return data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }

  public async getTotalConcessions(
    token: string,
  ): Promise<{ total: number }> {
    try {
      const { data } = await this.apiInstance
        .get(
          `/panels/benefits/${this.benefitId}/concession/counts`,
          this.config(token)
        );

      return data;
    } catch (error: any) {
      throw errorService.handleError(error);
    }
  }
}

export default new BenefitFishService();