const styles: any = {
  containerForm: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: '80%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
  },
  divCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  divCenterText: {
    height: '100%',
  },
  divListLogr: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#F9F9F9',
    borderRadius: '30px 0 0 30px',
    boxShadow: '0 3px 10px #00000033',
    zIndex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  divListHouses: {
    padding: '20px 30px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#EEEEEE',
    overflow: 'auto',
  },
  spanTitle: {
    color: '#a11908',
    fontSize: 15,
    fontWeight: '600',
    padding: '20px 0',
  },
  divFlex1: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  logoAcelera: {
    maxWidth: '100px',
    width: '100%',
    marginLeft: 10,
  },
  spanPowered: {
    color: '#0A0A0A59',
    fontSize: 14,
  },
  divisor: {
    height: 2,
    width: '100%',
    backgroundColor: '#0a0a0a1c',
  },
  logradouroAction: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'strech',
    padding: 20,
    '& :last-child': {
      marginTop: '10px',
    }
  },
  listOfLogr: {
    padding: '20px 0',
    overflow: 'auto',
    width: '100%',
  },
  logrItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    borderRadius: '5px',
    padding: '15px 30px',
    '&:hover': {
      backgroundColor: '#E6E6E6',
    }
  },
  logrItemText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  logrItemName: {
    color: '#707070',
    fontSize: 13,
    textAlign: 'left',
  },
  logrItemNameBold: {
    color: '#0A0A0A',
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'left',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  titleListHousesBold: {
    color: '#707070',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 4,
  },
  titleListHouses: {
    color: '#707070',
    fontSize: 13,
  },
  btnFilter: {
    color: '#177AFC',
    borderColor: '#177AFC',
  },
  housesList: {
    padding: '20px 0',
  },
  fab: {
    position: 'absolute',
    bottom: 15,
    right: 15,
    backgroundColor: '#a11908', "&:hover": {
      backgroundColor: '#a11908'
    },
  },
  image: {
    maxWidth: '256px',
    marginBottom: 30,
    padding: '50px',
  },
};

export default styles;