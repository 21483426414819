import { withStyles, Grid } from '@material-ui/core';
import { newPainelColors } from '../../../../../../constants/colors/painel.colors';

import CardMain from '../../../../../templates/CardMain';
import BarChart from '../../../../../templates/ChartBar';
import PieChart from '../../../../../templates/PieChart';

import styles from './styles';

type Props = {
  classes: any,
  benefitId: number;
  totalRegistred: number;
  totalIncomplete: number;
  totalBenefitiaryByYear: { labels: string[], data: number[] };
  totalBenefitiaryByStatus: {
    labels: string[], data?: number[], datasets?: {
      data: number[],
      label: string
    }[]
  };
  totalBenefitiaryByGender: { labels: string[], data: number[] };
  totalBenefitiaryByComplexion: { labels: string[], data: number[] };
};

const BenefitTab: React.FC<Props> = (props) => {
  const {
    classes,
    benefitId,
    totalRegistred,
    totalIncomplete,
    totalBenefitiaryByYear,
    totalBenefitiaryByStatus,
    totalBenefitiaryByGender,
    totalBenefitiaryByComplexion,
  } = props;

  return (
    <Grid container spacing={4} className={classes.main}>
      <Grid item xs={12} className={classes.mainItem}>
        <Grid container spacing={4}>

          <Grid item xs={6}>
            <Grid container justify="center" >
              <Grid item xs={12} className={classes.cardItem}>
                <CardMain border="#a11908">
                  <Grid className={classes.flexRow}>
                    <Grid item xs={8} className={classes.flexRow}>
                      <img alt="Domicílios Registrados" src="/assets/gift-card.svg" className={classes.cardImg} />
                      <span className={classes.cardInfoTitle}>{
                        benefitId === 2 ? 'Total de Beneficiários' : 'Total de Beneficiários cadastrados'
                      }</span>
                    </Grid>
                    <Grid item xs={5} className={classes.flexColumn}>
                      <span className={classes.cardInfoTotal}>{totalRegistred}</span>
                    </Grid>
                  </Grid>
                </CardMain>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container justify="center">
              <Grid item xs={12} className={classes.cardItem}>
                <CardMain border="#a11908">
                  <Grid item xs={12} className={classes.flexRow}>
                    <img alt="Domicílios Registrados" src="/assets/gift-card.svg" className={classes.cardImg} />
                    <span className={classes.cardInfoTitle}>{
                      benefitId === 2 ? 'Total de beneficios concedidos' : 'Beneficiários com cadastro incompleto'
                    }</span>
                  </Grid>
                  <Grid item xs={5} className={classes.flexColumn}>
                    <span className={classes.cardInfoTotal}>{totalIncomplete}</span>
                  </Grid>
                </CardMain>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <CardMain>
          {
            benefitId === 2 ? (
              <BarChart
                colors={newPainelColors}
                labels={totalBenefitiaryByStatus.labels}
                data={[]}
                datasets={totalBenefitiaryByStatus.datasets}
                title={`Benefícios concedidos por status`}
              />
            ) : (
              <BarChart
                colors={newPainelColors}
                labels={totalBenefitiaryByStatus.labels}
                data={totalBenefitiaryByStatus.data as number[]}
                title={`Beneficiários por status`}
              />
            )
          }
        </CardMain>
      </Grid>
      <Grid item xs={6}>
        <CardMain>
          <BarChart
            colors={newPainelColors}
            labels={totalBenefitiaryByYear.labels}
            data={totalBenefitiaryByYear.data}
            title={`${benefitId === 2 ? 'Benefícios concedidos' : 'Beneficiários'} por ano`}
          />
        </CardMain>
      </Grid>
      <Grid item xs={6}>
        <CardMain>
          <PieChart
            colors={newPainelColors}
            labels={totalBenefitiaryByGender.labels}
            data={totalBenefitiaryByGender.data}
            title="Beneficiários por gênero"
          />
        </CardMain>
      </Grid>
      <Grid item xs={6}>
        <CardMain>
          <BarChart
            colors={newPainelColors}
            // labels={formatType(totalBenefitiaryByComplexion.labels, races) as string[]}
            labels={totalBenefitiaryByComplexion.labels}
            data={totalBenefitiaryByComplexion.data}
            title="Beneficiários por cor/raça"
          />
        </CardMain>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(BenefitTab);