/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Webcam from "react-webcam";
import ButtonMain from '../ButtonMain';
import { Button } from '@material-ui/core';

const videoConstraints = {
  // width: 1280,
  // height: 720,
  facingMode: "user"
};

const WebcamCapture = (props: any) => {
  const webcamRef: any = React.useRef(null);

  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef?.current?.getScreenshot();
      props.onGetImage(imageSrc);
    },
    [webcamRef]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', zIndex: 2 }}>
      <Button onClick={props.onClose} style={{ alignSelf: 'flex-end', color: '#FFF', borderColor: '#FFF', marginBottom: 5 }} variant="outlined">Cancelar</Button>
      <Webcam
        audio={false}
        // height={720}
        ref={webcamRef}
        screenshotFormat="image/png"
        // width={1280}
        videoConstraints={videoConstraints}
      />
      <ButtonMain loading={false} onClick={() => capture()} title="Tirar Foto" />
    </div>
  );
};

export default WebcamCapture;
