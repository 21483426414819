import React, {
  useRef,
  useState,
  useContext,
} from 'react';
import {
  Box,
  Menu,
  Grid,
  Button,
  MenuItem,
  Typography,
  withStyles,
  InputAdornment,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { GetApp } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import { CellParams, ColDef, DataGrid, GridOverlay } from '@material-ui/data-grid';
import FilterListOutlinedIcon from '@material-ui/icons/FilterListOutlined';
import ExcelExportReport, { ExportExcelRefProps } from '../ExcelExport';

import styles from './styles';
import MySelect from '../../../../../MySelect';
import MyTextInput from '../../../../../MyTextInput';
import MyInputMask from '../../../../../MyInputMask';
import { AuthContext } from '../../../../../../providers/Auth';
import { CitizenEntity } from '../../../../../../interfaces/entities/citizen.entity';
import formatType from '../../../../../../utils/formatType';
import situationsJobMarket from '../../../../../../constants/situationsJobMarket';
import CitizenService from '../../../../../../services/CitizenService';
import { Benefits } from '../../../../../../enums/Benefits';

interface BenefitiaryProps {
  classes: any;
  openSnackbar: any;
}

interface FormData {
  name: string;
  cpf: string;
  nib: string;
  nis: string;
  benefit_id: number;
  min_gross_income: number;
  max_gross_income: number;
  min_income_per_capta: number;
  max_income_per_capta: number;
  min_benefit_value: number;
  max_benefit_value: number;
  min_age: number;
  max_age: number;
}

interface FamilyReport extends CitizenEntity {
  dependents: CitizenEntity[];
  total_income: string;
}

const inputLabelProps = {
  style: { color: '#707070', fontWeight: 'bold' },
};
const inputProps = {
  style: { fontSize: 14 },
  startAdornment: (
    <InputAdornment position="start">
      {/* <PersonOutlinedIcon style={{ color: '#707070', fontSize: 16 }} /> */}
    </InputAdornment>
  ),
};

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const FamilyTab: React.FC<BenefitiaryProps> = (props) => {
  const { classes, openSnackbar } = props;
  const { accessToken } = useContext(AuthContext);

  const dateFns = new DateFnsUtils();
  const formRef = useRef<FormHandles>(null);
  const exportExcelRef = useRef<ExportExcelRefProps>(null);

  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [familyReport, setFamilyReport] = useState<FamilyReport[]>([]);

  const columnWidth = 190;
  const reportColumns: ColDef[] = [
    {
      field: 'name',
      headerName: 'Nome dos familiares',
      width: columnWidth + 30,
      renderCell: ({ row }: CellParams) => {
        const responsible = row as FamilyReport;
        const dependents = responsible.dependents;

        return (
          <Box display="flex" flexDirection="column">
            <Typography>{responsible.name}</Typography>
            {
              dependents.map((dependent) => <Typography>{dependent.name}</Typography>)
            }
          </Box>
        );
      },
    },
    {
      field: 'age',
      headerName: 'Idade',
      width: columnWidth,
      renderCell: ({ row }: CellParams) => {
        const responsible = row as FamilyReport;
        const dependents = responsible.dependents;

        return (
          <Box display="flex" flexDirection="column">
            <Typography>{responsible.age} anos</Typography>
            {
              dependents.map((dependent) => <Typography>{dependent.age} anos</Typography>)
            }
          </Box>
        );
      },
    },
    {
      field: 'nis',
      headerName: 'NIS',
      width: columnWidth,
      renderCell: ({ row }: CellParams) => {
        const responsible = row as FamilyReport;
        const dependents = responsible.dependents;

        return (
          <Box display="flex" flexDirection="column">
            <Typography>{responsible.nis}</Typography>
            {
              dependents.map((dependent) => <Typography>{dependent.nis}</Typography>)
            }
          </Box>
        );
      },
    },
    {
      field: 'pis',
      headerName: 'PIS',
      width: columnWidth,
      renderCell: ({ row }: CellParams) => {
        const responsible = row as FamilyReport;
        const dependents = responsible.dependents;

        return (
          <Box display="flex" flexDirection="column">
            <Typography>{responsible.nis}</Typography>
            {
              dependents.map((dependent) => <Typography>{dependent.nis}</Typography>)
            }
          </Box>
        );
      },
    },
    {
      field: 'rg',
      headerName: 'RG',
      width: columnWidth,
      renderCell: ({ row }: CellParams) => {
        const responsible = row as FamilyReport;
        const dependents = responsible.dependents;

        return (
          <Box display="flex" flexDirection="column">
            <Typography>{responsible.nis}</Typography>
            {
              dependents.map((dependent) => <Typography>{dependent.nis}</Typography>)
            }
          </Box>
        );
      },
    },
    {
      field: 'cpf',
      headerName: 'CPF',
      width: columnWidth,
      renderCell: ({ row }: CellParams) => {
        const responsible = row as FamilyReport;
        const dependents = responsible.dependents;

        return (
          <Box display="flex" flexDirection="column">
            <Typography>
              {responsible.cpf.replace(/(d{3})(d{3})(d{3})(d{2})/, '$1.$2.$3-$4')}
            </Typography>
            {
              dependents.map((dependent) => (
                <Typography>
                  {dependent.cpf.replace(/(d{3})(d{3})(d{3})(d{2})/, '$1.$2.$3-$4')}
                </Typography>
              ))
            }
          </Box>
        );
      },
    },
    {
      field: 'job_situation_id',
      headerName: 'Situação de trabalho',
      width: columnWidth + 30,
      renderCell: ({ row }: CellParams) => {
        const responsible = row as FamilyReport;
        const dependents = responsible.dependents;

        return (
          <Box display="flex" flexDirection="column">
            <Typography>
              {formatType(responsible.job_situation_id, situationsJobMarket)}
            </Typography>
            {
              dependents.map((dependent) => (
                <Typography>
                  {formatType(dependent.job_situation_id, situationsJobMarket)}
                </Typography>
              ))
            }
          </Box>
        );
      },
    },
    {
      field: 'total_income',
      headerName: 'Renda total',
      width: columnWidth,
      renderCell: ({ row }: CellParams) => {
        const responsible = row as FamilyReport;
        const dependents = responsible.dependents;

        return (
          <Box display="flex" flexDirection="column">
            <Typography>
              {
                new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                  minimumFractionDigits: 2,
                }).format(Number(responsible.total_income))
              }
            </Typography>
            {
              dependents.map((dependent) => (
                <Typography>
                  {
                    new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                      minimumFractionDigits: 2,
                    }).format(Number(dependent.total_income))
                  }
                </Typography>
              ))
            }
          </Box>
        );
      },
    },
  ];

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  function formatExcelExportData() {
    return familyReport?.map((report) => ({
      ...report,
      name: () => {
        const responsible = report;
        const dependents = responsible.dependents;

        return `${responsible.name}\n${dependents.map((dependent) => `\n${dependent.name}`)}`;
      },
      age: () => {
        const responsible = report;
        const dependents = responsible.dependents;

        return `${responsible.age}\n${dependents.map((dependent) => `\n${dependent.age}`)}`;
      },
      nis: () => {
        const responsible = report;
        const dependents = responsible.dependents;

        return `${responsible.nis}\n${dependents.map((dependent) => `\n${dependent.nis}`)}`;
      },
      pis: () => {
        const responsible = report;
        const dependents = responsible.dependents;

        return `${responsible.pis}\n${dependents.map((dependent) => `\n${dependent.pis}`)}`;
      },
      rg: () => {
        const responsible = report;
        const dependents = responsible.dependents;

        return `${responsible.rg}\n${dependents.map((dependent) => `\n${dependent.rg}`)}`;
      },
      cpf: () => {
        const responsible = report;
        const dependents = responsible.dependents;

        return `${responsible.cpf}\n${dependents.map((dependent) => `\n${dependent.cpf}`)}`;
      },
      job_situation_id: () => {
        const responsible = report;
        const dependents = responsible.dependents;

        return `${formatType(responsible.job_situation_id, situationsJobMarket)
          }\n${dependents.map((dependent) => `\n${formatType(dependent.job_situation_id, situationsJobMarket)}`)}`;
      },
      total_income: () => {
        const responsible = report;
        const dependents = responsible.dependents;

        return `${new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        }).format(Number(responsible.total_income))
          }\n${dependents.map((dependent) => `\n${new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
          }).format(Number(dependent.total_income))}`)}`;
      },
    }));
  }

  async function onSubmit(data: FormData) {
    formRef.current?.setErrors({});
    setIsLoadingReport(true);

    const schema = Yup.object().shape({
      benefit_id: Yup.number().required('Você deve selecionar um benefício').typeError('Valor inválido'),
      name: Yup.mixed().test({
        message: 'O nome não pode contér números',
        test: (value) => {
          if (!!value) {
            return /^[A-Za-z\s]+$/.test(value);
          }

          return true;
        },
      }),
      nib: Yup.mixed().test({
        message: 'O NIB não pode contér letras',
        test: (value) => {
          if (!!value) {
            return /^[0-9]+$/.test(value);
          }

          return true;
        },
      }),
      nis: Yup.mixed().test({
        message: 'O NIS não pode contér letras',
        test: (value) => {
          if (!!value) {
            return /^[0-9]+$/.test(value);
          }

          return true;
        },
      }),
      min_gross_income: Yup.mixed(),
      max_gross_income: Yup.mixed(),
      min_income_per_capta: Yup.mixed(),
      max_income_per_capta: Yup.mixed(),
      min_benefit_value: Yup.mixed(),
      max_benefit_value: Yup.mixed(),
      min_age: Yup.mixed(),
      max_age: Yup.mixed(),
    });

    try {
      await schema.validate(data, { abortEarly: false });
      const response = await CitizenService.getFamilyreport(accessToken, data);
      setFamilyReport(response);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};
        let emptyFieldsError = '';

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
          if (error.path === '') {
            emptyFieldsError = error.message;
          }
        });
        if (emptyFieldsError) {
          return openSnackbar('error', emptyFieldsError);
        }
        formRef.current?.setErrors(errorMessages);
      } else {
        openSnackbar('error', error?.message);
      }
    } finally {
      setIsLoadingReport(false);
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} className={classes.filterDiv}>
        <Grid container className={classes.filterContainer}>
          <Grid item xs={12} className={classes.filterTitle}>
            <Typography>Filtrar</Typography>
            <FilterListOutlinedIcon style={{ marginLeft: 10 }} />
          </Grid>
          <Grid item xs={12}>
            <Form ref={formRef} onSubmit={onSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MySelect
                    label="Beneficio"
                    name="benefit_id"
                    options={[
                      { label: 'Programa de Assistência Social (PAS)', value: Benefits.PAS },
                      { label: 'Vale Semana Santa (PEIXE)', value: Benefits.FISH },
                      { label: 'Pão na mesa', value: Benefits.BREAD },
                    ]}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    name="name"
                    label="Nome"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Nome do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyInputMask
                    name="cpf"
                    type="cpf"
                    label="CPF"
                    mask="999.999.999-99"
                    InputProps={inputProps}
                    placeholder="000.000.000-00"
                    InputLabelProps={inputLabelProps}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    name="nib"
                    label="NIB"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="NIB do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    name="nis"
                    label="NIS"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="NIS do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="min_gross_income"
                    label="Renda bruta mínima"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Renda bruta mínima do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="max_gross_income"
                    label="Renda bruta máxima"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Renda bruta máxima do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="min_income_per_capta"
                    label="Renda per capita mínima"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Renda per capita mínima do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="max_income_per_capta"
                    label="Renda per capita máxima"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Renda per capita máxima do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="min_benefit_value"
                    label="Valor do benefício mínimo"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Valor do benefício mínimo do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="max_benefit_value"
                    label="Valor do benefício mínimo"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Valor do benefício mínimo do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="min_age"
                    label="Idade mínima"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Idade mínima do beneficiário..."
                  />
                </Grid>
                <Grid item xs={4}>
                  <MyTextInput
                    type="number"
                    name="max_age"
                    label="Idade máxima"
                    InputProps={inputProps}
                    InputLabelProps={inputLabelProps}
                    placeholder="Idade máxima do beneficiário..."
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      disabled={isLoadingReport}
                      className={classes.buttonRegister}
                    >
                      Consultar
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.reportContainer}>
        {
          isLoadingReport && !familyReport.length ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress size={55} />
            </Box>
          ) : null
        }
        {
          familyReport.length ? (
            <>
              <Box className={classes.tableToolBar} display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">Resultados</Typography>
                <Button
                  color="primary"
                  variant="outlined"
                  aria-haspopup="true"
                  endIcon={<GetApp />}
                  aria-controls="simple-menu"
                  onClick={handleOpenMenu}>
                  Exportar
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={handleCloseMenu}>Gerar pdf</MenuItem>
                  <MenuItem onClick={() => exportExcelRef.current?.generateExcel()}>Gerar excel</MenuItem>
                </Menu>
              </Box>
              <Box boxShadow={3}>
                <DataGrid
                  columns={reportColumns}
                  disableSelectionOnClick
                  rows={familyReport}
                  loading={isLoadingReport}
                  autoHeight
                  className={classes.reportHeight}
                  localeText={{
                    footerTotalRows: 'de',
                    footerPaginationRowsPerPage: 'Beneficiários por página'
                  }}
                  components={{
                    LoadingOverlay: CustomLoadingOverlay,
                  }}
                />
              </Box>
            </>
          ) : null
        }
      </Grid>
      <ExcelExportReport
        ref={exportExcelRef}
        columns={reportColumns}
        data={formatExcelExportData()}
        fileName={`relatório-de-composição-familiar-${dateFns.format(new Date(), 'dd-MM-yyyy')}.xls`}
      />
    </Grid>
  );
}

export default withStyles(styles)(FamilyTab);