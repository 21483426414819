import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import { Grid, withStyles, Box } from '@material-ui/core';

import Header from './components/Header';
import CitizenTab from './components/CitizenTab';
import RelatorioDomicilio from './components/DomicileReport';


import styles from './styles';
import withSnackbar from '../../../templates/WithSnackbar';
import BenefitiaryTab from './components/BenefitiaryTab';
import FamilyTab from './components/FamilyTab';


type Props = {
  classes: any,
  login: Function,
  openSnackbar: any,
};

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ display: 'flex' }}
    >
      {value === index && (
        <Box style={{ flex: 1, display: 'flex' }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Reports: React.FC<Props> = (props) => {
  const theme = useTheme();
  const { classes, openSnackbar } = props;
  const [tab, setTab] = useState<any>(0);

  const onChangeTab = (value: number) => {
    setTab(value);
  }


  const getDocumentByTab = (tab: number) => {
    switch (tab) {
      default: return undefined;
    }
  }

  const getFilenamePdfByTab = (tab: number) => {
    switch (tab) {
      default: return undefined;
    }
  }

  const getFilenameCsvLinkByTab = (tab: number) => {
    switch (tab) {
      // case 1: return 'auxílio_emergencial.xlsx'
      case 0: return 'relatorio_domicilio.xlsx'
      case 1: return 'relatorio_individuo.xlsx'
      default: return undefined;
    }
  }

  const getDataExcelByTab = (tab: number) => {
    return undefined;
  }

  const getColumnsExcelByTab = (tab: number) => {
    switch (tab) {
      case 0: return [
        { title: 'Nome do Responsável', field: 'responsible.fullName' },
        { title: 'Bairro', field: 'street.neighborhood' },
        { title: 'Complemento', field: 'complement' },
        { title: 'Energia Elétrica', field: 'haveElectricity' },
        { title: 'Forma de Escoamento do Banheiro', field: 'bathroomDrain' },
        { title: 'Abastecimento de Água', field: 'waterSupply' },
        { title: 'Destino do Lixo', field: 'trashDestination' },
        { title: 'Situação de Moradia', field: 'housingSituation' },
      ]
      case 1: return [
        { title: 'Nome Completo', field: 'fullName' },
        { title: 'CPF', field: 'cpf' },
        { title: 'NIS', field: 'nis' },
        { title: 'Data de nascimento', field: 'birthDate' },
        { title: 'Sexo', field: 'sex' },
        { title: 'Raça/Cor', field: 'race' },
        { title: 'Grau de escolaridade', field: 'schooling' },
        { title: 'Situação no mercado de trabalho', field: 'situationJobMarket' },
        { title: 'Religião', field: 'religion' },
        { title: 'Deficiência', field: 'isDeficiencyPerson' },
      ];
      default: return undefined;
    }
  }

  return (
    <Grid container className={classes.containerForm}>
      <Grid item xs={12} className={classes.divCenterText}>
        <Header
          onChange={onChangeTab}
          documentPdf={getDocumentByTab(tab)}
          filenamePdf={getFilenamePdfByTab(tab)}
          dataExcel={getDataExcelByTab(tab)}
          columnsExcel={getColumnsExcelByTab(tab)}
          filenameExcel={getFilenameCsvLinkByTab(tab)}
        />
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tab}
          style={{ flex: 1, display: 'flex' }}
          className={classes.swipeableContent}
          containerStyle={{ flex: 1, display: 'flex' }}
        >
          <TabPanel value={tab} index={0} dir={theme.direction} style={{ flex: 1, height: '100%' }}>
            <RelatorioDomicilio openSnackbar={openSnackbar} />
          </TabPanel>
          <TabPanel value={tab} index={1} dir={theme.direction} style={{ flex: 1, height: '100%' }}>
            <CitizenTab openSnackbar={openSnackbar} />
          </TabPanel>
          <TabPanel value={tab} index={2} dir={theme.direction} style={{ flex: 1, height: '100%' }}>
            <BenefitiaryTab openSnackbar={openSnackbar} />
          </TabPanel>
          <TabPanel value={tab} index={3} dir={theme.direction} style={{ flex: 1, height: '100%' }}>
            <FamilyTab openSnackbar={openSnackbar} />
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(withSnackbar(Reports));