import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TablePagination } from '@material-ui/core';

import TableMainRow from './TableRow';

import styles from './styles';

type Props = { classes: any, array: any[] | undefined, columns: any, expand?: boolean, componentExpand?: any };

function TableMain(props: Props) {
    const { classes, array, columns, expand, componentExpand } = props;
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const renderExpandColumn = () => {
        if (expand) return <TableCell style={{ backgroundColor: '#EEEEEE' }} />

        return null;
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper className={classes.rootPaper}>
                    <TableContainer className={classes.tableContainer}>
                        <Table stickyHeader aria-label="sticky table" size="medium">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column: any) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{ minWidth: column.minWidth, backgroundColor: '#EEEEEE', color: '#707070', fontWeight: 'bold', fontSize: 11 }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    {renderExpandColumn()}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {array?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                    return <TableMainRow key={index} row={row} index={index} expand={expand} columns={columns} componentExpand={componentExpand} />
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={array?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        rowsPerPageOptions={[]}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
}

export default withStyles(styles)(TableMain);