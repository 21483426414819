const styles: any = {
    containerForm: {
        padding: 40,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundSize: '80%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    welcomSpan: {
        color: '#a11908',
        fontSize: 19,
        textAlign: 'center',
    },
    welcomSpanBold: {
        color: '#a11908',
        fontSize: 19,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    image: {
        maxWidth: '500px',
        marginBottom: 30,
    },
    divCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    divCenterText: {
        height: '100%',
    },
    divFlex1: {
        height: '95%',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    logoAcelera: {
        maxWidth: '100px',
        width: '100%',
        marginLeft: 10,
    },
    spanPowered: {
        color: '#0A0A0A59',
        fontSize: 14,
    },
    circularProgress: {
        margin: '20px auto',
        textAlign: 'center',
        height: '100%',
        color: '#a11908'
    },
    dividerBenefits: {
        margin: '2rem 0rem',
        height: '0.1rem',
        color: 'rgba(0, 0, 0, 0.54)',
    },
};

export default styles;