const styles: any = {
    pageTitle: {
        color: '#0A0A0A59',
        fontWeight: 'bold',
        marginRight: '10px',
    },
    pageLine: {
        backgroundColor: '#0A0A0A59',
        flex: 1,
        height: 1,
    },
    containerForm: {
        padding: 30,
        justifyContent: 'center',
        backgroundColor: '#EEEEEE',
        border: '1px solid #0A0A0A59',
        borderRadius: 10,
    },
    buttonRegister: {
        marginLeft: '15px',
        color: '#fff',
        textTransform: 'capitalize',
    },
    buttonSelection: {
        marginLeft: '15px',
        backgroundColor: '#004692',
        color: '#fff',
        textTransform: 'capitalize',
        "&:hover": {
            backgroundColor: '#408BCC'
        }
    },
    divCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    gridCircularProgress: {
        display: 'flex',
        height: '80vh',
        justifyContent: 'center',
        alignItems: 'center'
    },
    circularProgress: {
        color: '#a11908'
    },
    incomesContainer: {
        width: '50%',
        '& div.MuiDataGrid-root.MuiDataGrid-root': {
            backgroundColor: '#fff',
        }
    },
    buttonCancel: {
        borderColor: '#D4586D',
        color: '#707070',
        textTransform: 'capitalize',
        "&:hover": {
            backgroundColor: '#D4586D',
            color: '#fff',
        },
    }
};

export default styles;