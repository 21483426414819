type WaterSupply = { id: number; name: string }

const waterSupplies: Array<WaterSupply> = [
  { id: 1, name: "Rede encanada até o domicílio" },
  { id: 2, name: "Poço/Nascente no domicílio" },
  { id: 3, name: "Cisterna" },
  { id: 4, name: "Carro pipa" },
  { id: 5, name: "Outro" },
];

export default waterSupplies;