import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import Benefits from '../../../components/pages/private/Benefits';

import PeixeRoutes from './Peixe';
import CestaNatalinaRoutes from "./CestaNatalina";
import CestaSemanaSantaRoutes from './CestaSemanaSanta';

const BeneficiosRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/`}>
        <Benefits />
      </Route>
      <Route path={`${path}/cesta-junina`}>
        <PeixeRoutes />
      </Route>
        <Route path={`${path}/cesta-natalina`}>
            <CestaNatalinaRoutes />
        </Route>
        <Route path={`${path}/cesta-santa`}>
            <CestaSemanaSantaRoutes />
        </Route>
    </Switch>
  );
}

export default BeneficiosRoutes;
