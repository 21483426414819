const styles: any = {
    containerForm: {
        padding: 30,  
        backgroundColor: '#EEEEEE',
        border: '1px solid #0A0A0A59',
        borderRadius: 10,
    },
    subTitle: {
        fontSize: "14px",
        fontWeight: 300,
        fontFamily: 'Roboto',
    },
    button: {
        fontSize: '14px',
        fontWeight: '400',
    },
    buttonClean: {
        borderColor: '#878787',
        color: '#878787',
        "&:hover": {
            backgroundColor: '#878787',
            color: '#fff',
        },
    },
    buttonCancel: {
        marginLeft: '15px',
        borderColor: '#D4586D',
        color: '#D4586D',
        "&:hover": {
            backgroundColor: '#D4586D',
            color: '#fff',
        },
    },
    buttonRegister: {
        marginLeft: '15px',
        backgroundColor: '#004692',
        color: '#fff',
        "&:hover": {
            backgroundColor: '#408BCC'
        }
    },
    select: {
        width: '100%',
    },
    divCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    pageTitle: {
        color: '#0A0A0A59',
        fontWeight: 'bold',
        marginRight: '10px',
    },
    pageLine: {
        backgroundColor: '#0A0A0A59',
        flex: 1,
        height: 1,
    },
    btnFilter: {
        color: '#177AFC',
        borderColor: '#177AFC',
        margin: '0 10px',
    },
    btnAdd: {
        backgroundColor: '#a11908',
        color: '#FFFFFF',
        margin: '0 10px',
    },
    dataGrid: {
        marginTop: 10,
        height: 350,
        backgroundColor: '#EEEEEE',
        border: '1px solid #0A0A0A59',
        borderRadius: 10
    },
    homesContainer: {
        marginTop: '5rem',
    },
    tableContainer: {
        width: '100%',
        backgroundColor: '#FFFFFF',
    },
    btnActions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
};

export default styles;