import React from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import HomeInfo from '../../../components/pages/private/HomeInfo';
import HomeRegister from '../../../components/pages/private/HomeRegister';

const ImovelRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/:imovelId/visualizar`}>
        <HomeInfo />
      </Route>
      <Route path={`${path}/:imovelId/editar`}>
        <HomeRegister />
      </Route>
      <Route path={`${path}/cadastrar`}>
        <HomeRegister />
      </Route>
    </Switch>
  );
}

export default ImovelRoutes;
