/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

interface MyAutocompleteProps {
  name: string;
  id?: string;
  options: any;
  disabled: boolean;
  label?: string;
  renderOption?: any;
  value?: any;
  onChange?(event: any, newValue: any): any;
  getOptionLabel?: any;
}

const MyAutoComplete: React.FC<MyAutocompleteProps> = (props) => {
  const {
    id,
    name,
    label,
    value,
    options,
    onChange,
    disabled,
    renderOption,
    getOptionLabel,
  } = props;
  const autoCompleteRef = useRef();
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: autoCompleteRef,
      getValue: (ref) => ref.current.value,
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = '';
      },
    });
  }, [fieldName, registerField]);

  return (
    <Autocomplete
      disabled={disabled}
      options={options || []}
      getOptionLabel={getOptionLabel}
      id={id}
      value={value}
      onChange={onChange}
      noOptionsText="Sem opções"
      renderOption={renderOption}
      renderInput={(params: any) =>
        <TextField {...params} name={name} inputRef={autoCompleteRef} defaultValue={defaultValue} label={label} InputLabelProps={{
          style: { color: '#707070', fontWeight: 'bold' },
          shrink: true,
        }} />
      }
    />
  );
};

export default MyAutoComplete;
