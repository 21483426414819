const styles: any = {
    containerForm: {
        height: '100%',
        // justifyContent: 'center',
        // alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    divCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    flexColumnSpaceBetween: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '0 15px',
    },
    header: {
        backgroundColor: '#F1663733',
        borderRadius: '30px 0 0',
        padding: '20px 25px',
        display: 'flex',
    },
    closeButton: {
        borderRadius: 10,
        backgroundColor: '#FFFFFF',
        color: '#D44312',
        boxShadow: '-2px 0px 6px #00000029',
        padding: '10px',
    },
    headerHouseImage: {
        maxWidth: '115px',
        minWidth: '115px',
    },
    headerTitleBold: {
        color: '#707070',
        fontSize: 14,
        fontWeight: 'bold',
    },
    headerSubtitle: {
        color: '#707070',
        fontSize: 12,
    },
    headerSubtitleItalic: {
        color: '#707070',
        fontSize: 12,
        fontStyle: 'italic',
    },
    btnEdit: {
        color: '#D44312',
        borderColor: '#D44312',
        fontSize: '13px',
        textTransform: 'capitalize',
        padding: '5px 22px',
        fontWeight: 'bold',
        borderRadius: '3px',
    },
    body: {
        padding: 20,
        overflowY: 'auto',
        height: '100%',
        maxHeight: 'calc(100% - 106px)',
    },
    tabs: {
        borderBottom: '1px solid #0A0A0A59',
        color: '#A0A0A0',
        width: '100%',
        fontWeight: 'bold',
        // color: '#D44312'
    },
    tab: {
        fontWeight: 'bold',
        // color: '#D44312'
    },
    main: {
        display: 'flex',
        // flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        color: '#0A0A0A',
        fontSize: 12,
        fontWeight: 'bold',
    },
    infoTitle: {
        color: '#0A0A0A',
        fontSize: 12,
    },
    divisor: {
        backgroundColor: '#0A0A0A59',
        height: 1,
        width: '100%',
    },
    conditionInfo: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
    },
    conditionTitle: {
        color: '#D44312',
        fontWeight: 'bold',
        fontSize: 12,
    },
    conditionSubtitle: {
        color: '#707070',
        fontSize: 12,
    },
    benefitTitle: {
        color: '#707070',
        fontWeight: 'bold',
        fontSize: 12,
    },
    benefitSubtitle: {
        color: '#707070',
        fontSize: 12,
    },
    titleList: {
        fontSize: 12,
        textTransform: 'uppercase',
        color: '#D44312',
        fontWeight: 'bold',
    },
    nested: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    boxUserResponsable: {
        backgroundColor: '#F16637',
        color: '#FFFFFF',
        width: '60px',
        height: '60px',
        borderRadius: '10px',
        boxShadow: '-2px 0 6px #00000029',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20,
    },
    listContainer: {
        alignSelf: 'self-start',
    },
    grantBenefitContainer: {
        padding: '20px 0px 0px 35px',
    },
    grantBenefitBtn: {
        color: '#f16637',
        border: '1px solid #f16637',
    },
};

export default styles;