/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useMemo, useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  withStyles,
} from "@material-ui/core";
import { useHistory, useParams, useLocation } from "react-router-dom";

import Street from "../../../../model/entities/Street";
import { AuthContext } from "../../../../providers/Auth";
import homeService from "../../../../services/HomeService";
import HomeService from "../../../../services/HomeService";
import withSnackbar from "../../../templates/WithSnackbar";
import Map from "../../../templates/Map";
import { HomeEntity } from "../../../../interfaces/entities/home.entity";
import NoCoordinatesDialog from "./components/NoCoordinatesDialog";

import MySelect from "../../../MySelect";
import MyTextInput from "../../../MyTextInput";

import styles from "./styles";

import trashDestinations from "../../../../constants/trashDestinations";
import housingSituations from "../../../../constants/housingSituations";
import bathroomDrains from "../../../../constants/bathroomDrains";
import waterSupplies from "../../../../constants/waterSupplies";
import counties from "../../../../constants/counties";
import neighborhoods from "../../../../constants/neighborhoods";
import { messages } from "../../../../constants/messages";
import MyInputMask from "../../../MyInputMask";
import streetService from "../../../../services/StreetService";
import { StreetTypeEntity } from "../../../../interfaces/entities/street-type.entity";
import { StreetEntity } from "../../../../interfaces/entities/street.entity";
import Loading from "../../public/Loading";

type Props = { classes: any; openSnackbar: any; history: any };

function HomeRegister(props: Props) {
  const { classes, openSnackbar } = props;
  const history = useHistory();
  const { imovelId } = useParams<{ imovelId: string | undefined }>();
  const [home, setHome] = useState<HomeEntity>();
  const [street] = useState<Street>();
  const [streets, setStreets] = useState<StreetEntity[]>([]);
  const streetFormRef = useRef<FormHandles>(null);
  const { accessToken } = useContext(AuthContext);
  const [loadingSubmitHome, setLoadingSubmitHome] = useState(false);
  const [loadingHome, setLoadingHome] = useState(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [latitude, setLatitude] = useState<number | null>(
    home?.latitude ? home.latitude : null
  );
  const [longitude, setLongitude] = useState<number | null>(
    home?.longitude ? home.longitude : null
  );
  const [streetTypes, setStreetTypes] = useState<StreetTypeEntity[]>([]);
  const [currentStreetType, setCurrentStreetType] = useState<number>();
  const [currentDistrict, setCurrentDistrict] = useState<string>();

  function useQueryParams() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  }
  const queryParams = useQueryParams();

  const filteredStreets = streets.length && currentDistrict && currentStreetType
    ? streets.filter((street) => street.district === currentDistrict && street.street_type_id === currentStreetType)
    : [];

  useEffect(() => {
    getHome();
    getStreets();
    getStreetTypes();
  }, []);

  useEffect(() => {
    if (home) {
      if (home.latitude && home.longitude) {
        setLatitude(home.latitude);
        setLongitude(home.longitude);
      }
    }
  }, [home]);

  async function getStreets() {
    try {
      const results = await streetService.getAll(accessToken);

      setStreets(results.sort((a, b) => a.name > b.name ? 1 : -1));
    } catch (error: any) {
      handleError(error?.message);
    } finally {
    }
  }

  async function getStreetTypes() {
    try {
      const { results } = await streetService.getStreetTypes(accessToken);

      setStreetTypes(results);
    } catch (error: any) {
      handleError(error?.message);
    } finally {
    }
  }

  const setCoordinatesOnClick = (lat: number, lng: number) => {
    setLatitude(lat);
    setLongitude(lng);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleStreetTypeChange = (e: any) => {
    if (e.target) {
      setCurrentStreetType(Number(e.target.value));
    }
  }

  const handleDistrictChange = (e: any) => {
    if (e.target) {
      setCurrentDistrict(e.target.value);
    }
  }

  const getAddress = (): string => {
    if (street) {
      const address = [`${street?.type} ${street?.name}`, street?.county]
        .filter((streetInfo) => streetInfo !== undefined && streetInfo !== null)
        .join(",");
      return address;
    } else {
      return "";
    }
  };

  const getHome = async () => {
    setLoadingHome(true);
    try {
      if (imovelId) {
        const home = await HomeService.getHome(Number(imovelId), accessToken);
        setHome(home);
        setCurrentDistrict(home.district);
        setCurrentStreetType(home.street_type_id);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setLoadingHome(false);
    }
  };

  const onSubmit = async (data: any) => {
    setLoadingSubmitHome(true);
    const schema = Yup.object().shape({
      cep: Yup.string().required(messages.emptyField),
      district: Yup.string().required(messages.emptyField),
      street_name: Yup.string().required(messages.emptyField),
      rural_zone: Yup.boolean().required(messages.emptyField),
      quilombola: Yup.boolean().required(messages.emptyField),
      latitude: Yup.string().required(messages.emptyField),
      longitude: Yup.string().required(messages.emptyField),
      paved_access: Yup.boolean().required(messages.emptyField),
      electric_power_supply: Yup.boolean().required(messages.emptyField),
      housing_situation_id: Yup.number().required(messages.emptyField),
      street_type_id: Yup.number().required(messages.emptyField),
      water_supply_id: Yup.number().required(messages.emptyField),
      sewage_id: Yup.number().required(messages.emptyField),
      garbage_disposal_id: Yup.number().required(messages.emptyField),
    });

    try {
      await schema.validate(data, { abortEarly: false });

      if (home) {
        await homeService.updateHome(accessToken, home.id, {
          ...data,
          telephone: data.telephone ? data.telephone
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", "") : null,
        });
        openSnackbar("success", "Domicílio atualizado com sucesso.");

      } else {
        await homeService.createHome(accessToken, {
          ...data,
          telephone: data.telephone ? data.telephone
            .replace("(", "")
            .replace(")", "")
            .replace(" ", "")
            .replace("-", "") : null,
        });
        openSnackbar("success", "Domicílio cadastrado com sucesso.");
      }

      const returnPath = queryParams.get("returnPath");
      if (returnPath) {
        history.replace(returnPath);
      }

      streetFormRef.current?.reset();
      setTimeout(() => {
        history.goBack();
      }, 1550);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        streetFormRef.current?.setErrors(errorMessages);
      } else {
        handleError(error?.message);
      }
    } finally {
      setLoadingSubmitHome(false);
    }
  };

  function handleError(e: any) {
    openSnackbar("error", e);
  }

  return (
    <Grid container>
      <Grid item xs={12} style={{ margin: "30px 40px" }}>
        <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
          <span className={classes.pageTitle}>Cadastrar Imóvel</span>
          <div className={classes.pageLine}></div>
        </Grid>

        {
          !loadingHome
            ? (
              <Form
                ref={streetFormRef}
                onSubmit={onSubmit}
                initialData={{
                  county: "MADRE DE DEUS",
                  cep: '42600000',
                  street_type_id: home?.street_type_id,
                  street_name: home?.street_name,
                  district: home?.district,
                  street_number: home?.street_number,
                  complement: home?.complement,
                  reference_point: home?.reference_point,
                  telephone: home?.telephone ?? null,
                  water_supply_id: home?.water_supply_id,
                  garbage_disposal_id: home?.garbage_disposal_id,
                  sewage_id: home?.sewage_id,
                  housing_situation_id: home?.housing_situation_id,
                  paved_access: home?.paved_access,
                  electric_power_supply: home?.electric_power_supply,
                  rural_zone: home?.rural_zone,
                  quilombola: home?.quilombola,
                  latitude: home?.latitude,
                  longitude: home?.longitude,
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center", marginTop: 40 }}
                >
                  <Grid container spacing={4} className={classes.containerFormStreet}>
                    <Grid item xs={6} md={4}>
                      <MySelect
                        name="street_type_id"
                        label="Tipo de logradouro (*)"
                        onChange={handleStreetTypeChange}
                        options={streetTypes.map((type) => ({
                          value: type.id,
                          label: type.description,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MySelect
                        name="street_name"
                        label="Nome do Logradouro (*)"
                        options={filteredStreets.map((street) => ({
                          value: street.name,
                          label: street.name,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MySelect
                        disabled
                        label="Município (*)"
                        name="county"
                        options={counties.map((type) => ({
                          value: type.name,
                          label: type.name,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MySelect
                        name="district"
                        label="Bairro (*)"
                        onChange={handleDistrictChange}
                        options={neighborhoods.map((type) => ({
                          value: type.name,
                          label: type.name,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MyTextInput
                        disabled
                        name="cep"
                        label="CEP (*)"
                        placeholder="00.000-000"
                        InputLabelProps={{
                          style: { color: "#707070", fontWeight: "bold" },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ color: "#707070" }}
                            />
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center", marginTop: 40 }}
                >
                  <NoCoordinatesDialog
                    handleSubmit={() => { }}
                    openDialog={openDialog}
                    handleCloseDialog={handleCloseDialog}
                  />
                  <Grid
                    container
                    spacing={4}
                    className={classes.containerFormRegisterRegisterHome}
                  >
                    <Grid item xs={6} md={4}>
                      <MyTextInput
                        required={false}
                        name="street_number"
                        label="Número"
                        placeholder="Número do imóvel"
                        InputLabelProps={{
                          style: { color: "#707070", fontWeight: "bold" },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ color: "#707070" }}
                            />
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MyTextInput
                        required={false}
                        name="complement"
                        label="Complemento"
                        placeholder="Complemento"
                        InputLabelProps={{
                          style: { color: "#707070", fontWeight: "bold" },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ color: "#707070" }}
                            />
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MyTextInput
                        required={false}
                        name="reference_point"
                        label="Ponto de Referência"
                        placeholder="Digite um ponto de referência"
                        InputLabelProps={{
                          style: { color: "#707070", fontWeight: "bold" },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ color: "#707070" }}
                            />
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MyInputMask
                        name="telephone"
                        mask="(99) 9999-9999"
                        type="tel"
                        label="Telefone Residencial"
                        placeholder="(00) 0000-0000"
                        InputLabelProps={{
                          style: { color: "#707070", fontWeight: "bold" },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ color: "#707070openDialogNoCoordinates" }}
                            />
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MySelect
                        name="water_supply_id"
                        label="Abastecimento de Água (*)"
                        options={waterSupplies.map((type) => ({
                          value: type.id,
                          label: type.name,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MySelect
                        name="garbage_disposal_id"
                        label="Destino do Lixo (*)"
                        options={trashDestinations.map((type) => ({
                          value: type.id,
                          label: type.name,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MySelect
                        name="sewage_id"
                        label="Forma de Escoamento do Banheiro (*)"
                        options={bathroomDrains.map((type) => ({
                          value: type.id,
                          label: type.name,
                        }))}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MySelect
                        name="housing_situation_id"
                        options={housingSituations.map((type) => ({
                          value: type.id,
                          label: type.name,
                        }))}
                        label="Situação de Moradia (*)"
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MySelect
                        name="paved_access"
                        label="Rua asfaltada? (*)"
                        options={[
                          { value: true, label: "Sim" },
                          { value: false, label: "Não" },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MySelect
                        name="electric_power_supply"
                        label="Disponibilidade de Energia Elétrica? (*)"
                        options={[
                          { value: true, label: "Sim" },
                          { value: false, label: "Não" },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MySelect
                        name="rural_zone"
                        label="É uma zona rural? (*)"
                        options={[
                          { value: true, label: "Sim" },
                          { value: false, label: "Não" },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MySelect
                        name="quilombola"
                        label="É quilombola? (*)"
                        options={[
                          { value: true, label: "Sim" },
                          { value: false, label: "Não" },
                        ]}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MyTextInput
                        disabled
                        name="latitude"
                        label="Latitude"
                        value={latitude || 0}
                        type="number"
                        InputLabelProps={{
                          style: { color: "#707070", fontWeight: "bold" },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ color: "#707070" }}
                            />
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <MyTextInput
                        disabled
                        name="longitude"
                        label="Longitude"
                        value={longitude || 0}
                        type="number"
                        InputLabelProps={{
                          style: { color: "#707070", fontWeight: "bold" },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ color: "#707070" }}
                            />
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.labelTitle}>
                        Selecione abaixo a localização do domicílio para definir a
                        latitude e longitude:
                      </div>
                      <Map
                        zoomLevel={15}
                        setCoordinates={setCoordinatesOnClick}
                        latitude={latitude || undefined}
                        longitude={longitude || undefined}
                        address={getAddress()}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.divCenterRegisterHome}>
                      <Button
                        disabled={loadingSubmitHome}
                        variant="contained"
                        className={classes.buttonCancelRegisterHome}
                        onClick={handleCancel}
                      >
                        Cancelar
                      </Button>
                      <Button
                        disabled={loadingSubmitHome}
                        type="submit"
                        variant="contained"
                        className={classes.buttonRegisterHouse}
                      >
                        {loadingSubmitHome ? (
                          <CircularProgress size={25} style={{ color: '#a11908' }}/>
                        ) : home ? (
                          "Editar"
                        ) : (
                          "Cadastrar"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )
            : <Loading />
        }
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(withSnackbar(HomeRegister));
