import React from 'react';

import { Box, Container, Grid, Typography, withStyles } from '@material-ui/core';

import styles from './styles';

const ChristmasBasketSplash: React.FC<{ classes: any }> = (props) => {
  const { classes } = props;

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Box className={classes.box}>
        <img src='/assets/logo-prefeitura-madre-large.png' alt='Splash' />
        <Grid container className={classes.grid}>
          <Grid item xs={12} lg={6}>
            <Typography variant="h1" className={classes.title}>Essa edição da Cesta Natalina ja finalizou...</Typography>
            <Typography variant="h4" className={classes.subTitle}>Enquanto não chega a nova edição, entre em contato com a Secretaria de Desenvolvimento Social para saber mais sobre o Acelera e seus benefícios.</Typography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <img src='/assets/home_ilustration.png' alt='Splash' />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default withStyles(styles)(ChristmasBasketSplash);