import React, { useState, useRef, useContext } from 'react';
import { Grid, withStyles, Button, CircularProgress, InputAdornment } from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import withSnackbar from '../../../templates/WithSnackbar';

import styles from './styles';
import MyTextInput from '../../../MyTextInput';
import { messages } from '../../../../constants/messages';
import authService from '../../../../services/AuthService';
import { AuthContext } from '../../../../providers/Auth';
import { useHistory, useParams } from 'react-router-dom';

type Props = { classes: any, openSnackbar: any };

interface FormData {
  current_password: string;
  new_password: string;
  password_confirm: string;
}

const ChangeUserPassword: React.FC<Props> = (props) => {
  const { classes, openSnackbar } = props;
  const [loading, setLoading] = useState<boolean>();
  const { accessToken, user } = useContext(AuthContext);
  const { userId } = useParams<{ userId: string | undefined }>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  const onChangePassword = async (data: FormData) => {
    setLoading(true);
    const schema = Yup.object().shape({
      current_password: Yup.string().notRequired(),
      new_password: Yup.string().required(messages.emptyField).typeError('Tipo inválido'),
      password_confirm: Yup.string().required(messages.emptyField).typeError('Tipo inválido').test({
        message: 'Senha não está condizente com nova senha',
        test: (value) => value === data.new_password,
      }),
    });

    try {
      formRef.current?.setErrors({});
      await schema.validate(data, { abortEarly: false });
      await authService.changePassword(accessToken, Number(userId),data);

      openSnackbar('success', 'Senha alterada com sucesso');
      formRef.current?.reset();
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        formRef.current?.setErrors(errorMessages);
      } else {
        handleError(error?.message);
      }
    } finally {
      setLoading(false);
    };
  };

  function handleError(e: any) {
    openSnackbar('error', e);
  };

  function handleCancel() {
    history.goBack();
  };


  return (
    <Grid container>
      <Grid item xs={12} style={{ margin: '30px 40px' }}>
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
          <span className={classes.pageTitle}>Alterar Senha</span>
          <div className={classes.pageLine}></div>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', marginTop: 40 }}>
          <Form style={{ width: '100%' }} ref={formRef} onSubmit={onChangePassword}>
            <Grid container spacing={4} className={classes.containerForm}>
              {user.id === Number(userId)
                && < Grid item xs={4}>
                  <MyTextInput
                    required={user.id === Number(userId)}
                    name="current_password"
                    label="Senha atual"
                    type="password"
                    InputLabelProps={{
                      style: { color: '#707070', fontWeight: 'bold' },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" style={{ color: "#707070" }} />
                      ),
                    }}
                  />
                </Grid>
              }
              <Grid item xs={4}>
                <MyTextInput
                  name="new_password"
                  label="Nova senha"
                  type="password"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <MyTextInput
                  name="password_confirm"
                  label="Confirme a senha"
                  type="password"
                  InputLabelProps={{
                    style: { color: '#707070', fontWeight: 'bold' },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" style={{ color: "#707070" }} />
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={4} className={classes.divCenter} alignItems="flex-end">
                <Button disabled={loading} variant="contained" className={classes.buttonCancel} onClick={handleCancel}>
                  Cancelar
                </Button>
                <Button
                  disabled={loading}
                  variant="contained"
                  className={classes.buttonRegister}
                  type="submit"
                >
                  {loading ? <CircularProgress size={25} style={{ color: '#a11908' }}/> : "Alterar senha"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid >
    </Grid >
  );
}

export default withStyles(styles)(withSnackbar(ChangeUserPassword));