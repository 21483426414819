/* eslint-disable react-hooks/exhaustive-deps */
import {
  useRef,
  useState,
  useEffect,
  useContext,
} from 'react';
import {
  Grid,
  Button,
  withStyles,
  InputAdornment,
  CircularProgress,
  Box,
  LinearProgress,
  Tooltip,
  IconButton,
  Typography,
} from '@material-ui/core';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { EditOutlined } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { ColDef, DataGrid, CellParams, GridOverlay, RowSelectedParams } from '@material-ui/data-grid';

import MySelect from '../../../MySelect';
import Loading from '../../public/Loading';
import MyTextInput from '../../../MyTextInput';
import withSnackbar from '../../../templates/WithSnackbar';

import { AuthContext } from '../../../../providers/Auth';
import homeService from '../../../../services/HomeService';
import StreetService from '../../../../services/StreetService';
import { HomeEntity } from '../../../../interfaces/entities/home.entity';
import { StreetEntity } from '../../../../interfaces/entities/street.entity';
import { StreetTypeEntity } from '../../../../interfaces/entities/street-type.entity';
import { OmitedHomeAttributes } from '../../../../interfaces/responses/search-home.response';


import styles from './styles';
import counties from '../../../../constants/counties';
import { messages } from '../../../../constants/messages';
import constantsNeighborhoods from '../../../../constants/neighborhoods';

type Props = {
  classes: any;
  openSnackbar: any;
};

type RequestSubmit = StreetEntity & { street_name: string };

type FieldsStreet = 'street_type_id' | 'street_name' | 'district' | 'cep';

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: 'absolute', top: 0, width: '100%' }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const StreetRegister: React.FC<Props> = (props) => {
  const { classes, openSnackbar } = props;
  const history = useHistory();
  const { accessToken } = useContext(AuthContext);
  const { logradouroId } = useParams<{ logradouroId: string | undefined }>();

  const [street, setStreet] = useState<StreetEntity>();
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isLoadingHomes, setIsLoadingHomes] = useState(false);
  const [hasChangedStreet, setHasChagedStreet] = useState(false);
  const [isMigrating, setIsMigrating] = useState(false);
  const [streetTypes, setStreetTypes] = useState<StreetTypeEntity[]>([]);
  const [homes, setHomes] = useState<Omit<HomeEntity, OmitedHomeAttributes>[]>([]);
  const [selectedHomes, setSelectedHomes] = useState<Omit<HomeEntity, OmitedHomeAttributes>[]>([]);

  const formRef = useRef<FormHandles>(null);

  const goBack = () => {
    history.goBack();
  };

  function navigateToEditHome(id: number) {
    if (!!id) {
      history.push(`/app/imoveis/${id}/editar`);
    } else {
      handleError('Não foi possível executar essa ação');
    }
  }

  const columnWidth = 200;
  const columns: ColDef[] = [
    {
      field: 'street_number',
      headerName: 'Número',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => value ? `N° ${value}` : 'Sem número',
    },
    {
      field: 'complement',
      headerName: 'Complemento',
      width: columnWidth,
      valueFormatter: ({ value }: CellParams) => value || 'Sem complemento',
    },
    {
      field: 'id',
      headerName: 'Editar',
      width: columnWidth,
      renderCell: ({ value }: CellParams) => (
        <Tooltip title="Editar domicílio">
          <IconButton onClick={() => navigateToEditHome(value as number)}>
            <EditOutlined />
          </IconButton>
        </Tooltip>
      )
    },
  ];

  useEffect(() => {
    getStreet();
    getStreetTypes();
  }, []);

  function verifyStreetChange(event: any) {
    if (!!logradouroId) {
      const fieldName: FieldsStreet = event.target.name;
      const fieldValue = event.target.value;
      const streetValue = !!street ? street[fieldName === 'street_name' ? 'name' : fieldName] : undefined;

      if (fieldValue !== streetValue) {
        setHasChagedStreet(true);
      } else {
        setHasChagedStreet(false);
      }
    }
  }

  async function getStreet() {
    try {
      if (logradouroId) {
        setIsLoadingPage(true);
        const response = await StreetService.getLogradouro(
          accessToken,
          Number(logradouroId),
        );
        setStreet(response);
        await getHomes(response.district, response.name);
      }
    } catch (error: any) {
      handleError(error.message);
    } finally {
      setIsLoadingPage(false);
    }
  }

  const createStreet = async (data: RequestSubmit) => {
    try {

      await StreetService.createLogradouro(accessToken, { ...data, name: data.street_name });

      openSnackbar('success', 'Logradouro registrado com sucesso!');
    } catch (error: any) {
      throw error;
    }
  };

  async function getStreetTypes() {
    setIsLoadingPage(true);
    try {
      const { results } = await StreetService.getStreetTypes(accessToken);

      setStreetTypes(results);
    } catch (error: any) {
      handleError(error?.message);
    } finally {
      setIsLoadingPage(false);
    }
  }

  async function editStreet(data: RequestSubmit) {
    try {
      if (logradouroId) {
        await StreetService.updateLogradouro(
          accessToken,
          Number(logradouroId),
          {
            ...data,
            name: data.street_name
          }
        );
        openSnackbar('success', 'Logradouro atualizado com sucesso');
      }
    } catch (error: any) {
      throw error;
    }
  }

  async function getHomes(district: string, street_name: string) {
    try {
      setIsLoadingHomes(true);
      const response = await homeService.searchHomeByDistrictAndStreet(district, street_name, accessToken);
      setHomes(response.results);
    } catch (error: any) {
      throw error;
    } finally {
      setIsLoadingHomes(false);
    }
  }

  async function onSubmit(data: RequestSubmit) {
    setLoadingSubmit(true);
    const schema = Yup.object().shape({
      street_type_id: Yup.number().required(messages.emptyField),
      street_name: Yup.string().required(messages.emptyField),
      district: Yup.string().required(messages.emptyField),
      cep: Yup.string().required(messages.emptyField).length(8, 'Este campo deve contér 8 dígitos '),
    });
    try {
      await schema.validate(data, { abortEarly: false });

      if (logradouroId) {
        await editStreet(data);
      } else {
        await createStreet(data);
      }
      
      setTimeout(() => window.location.reload(), 1000);
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages: Record<string, string> = {};

        error.inner.forEach((error: Yup.ValidationError) => {
          if (error.path != null) {
            errorMessages[error.path] = error.message;
          }
        });
        formRef.current?.setErrors(errorMessages);
      } else {
        handleError(error.message);
      }
    } finally {
      setLoadingSubmit(false);
    }
  }

  function handleSelectHome(params: RowSelectedParams) {
    const home = params.data as Omit<HomeEntity, OmitedHomeAttributes>;
    const alreadyExists = selectedHomes.find(state => state.id === home.id);
    if (!!alreadyExists) {
      setSelectedHomes(prevState => prevState.filter(state => state.id !== home.id));
    } else {
      setSelectedHomes(prevState => [...prevState, home]);
    }
  }

  async function editAndMigrateHomes() {
    if (!selectedHomes.length) {
      handleError('Selecione pelo menos um domicílio para migrar');
      return;
    }
    setIsMigrating(true);
    const schema = Yup.object().shape({
      street_type_id: Yup.number().required(messages.emptyField),
      street_name: Yup.string().required(messages.emptyField),
      district: Yup.string().required(messages.emptyField),
      cep: Yup.string().required(messages.emptyField).length(8, 'Este campo deve contér 8 dígitos '),
    });
    const data = {
      district: formRef.current?.getFieldValue('district'),
      street_name: formRef.current?.getFieldValue('street_name'),
      street_type_id: formRef.current?.getFieldValue('street_type_id'),
      cep: formRef.current?.getFieldValue('cep'),
    } as RequestSubmit;

    try {
      await schema.validate(data, { abortEarly: false });

      await editStreet(data);
      const promises = selectedHomes.map(async (home) => {
        try {
          return await homeService.updateHome(accessToken, home.id, {
            district: formRef.current?.getFieldValue('district'),
            street_name: formRef.current?.getFieldValue('street_name'),
            street_type_id: formRef.current?.getFieldValue('street_type_id'),
          });
        } catch (error: any) {
          throw new Error('Falha ao migrar domicílios');
        }
      })
      await Promise.all(promises);
      openSnackbar('success', 'Domicílios migrados com sucesso');

      setTimeout(() => window.location.reload(), 1000);
    } catch (error: any) {
      handleError(error?.message);
    } finally {
      setIsMigrating(false);
    }
  }

  function handleError(e: any) {
    openSnackbar('error', e);
  };

  if (isLoadingPage) {
    return <Loading />
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} style={{ margin: '30px 40px' }}>
          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
            <span className={classes.pageTitle}>Cadastrar Novo Logradouro</span>
            <div className={classes.pageLine}></div>
          </Grid>
          <Form
            ref={formRef}
            onSubmit={onSubmit}
            initialData={{
              ...street,
              street_name: street?.name,
              county: 'MADRE DE DEUS',
              cep: '42600000',
            }}
          >
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', marginTop: 40 }}>
              <Grid container spacing={4} className={classes.containerForm}>
                <Grid item xs={6} md={4}>
                  <MySelect
                    name="street_type_id"
                    label="Tipo de logradouro"
                    onChange={verifyStreetChange}
                    options={streetTypes.map((type) => ({ value: type.id, label: type.description }))}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <MyTextInput
                    name="street_name"
                    label="Nome"
                    placeholder="Nome do Logradouro"
                    onChange={verifyStreetChange}
                    InputLabelProps={{
                      style: { color: '#707070', fontWeight: 'bold' },
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" style={{ color: '#707070' }} />,
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <MySelect
                    disabled
                    label="Município"
                    name="county"
                    options={counties.map((type) => ({ value: type.name, label: type.name }))}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <MySelect
                    name="district"
                    label="Bairro"
                    onChange={verifyStreetChange}
                    options={constantsNeighborhoods.map((type) => ({ value: type.name, label: type.name }))}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <MyTextInput
                    disabled
                    name="cep"
                    label="CEP"
                    placeholder="00.000-000"
                    InputLabelProps={{
                      style: { color: '#707070', fontWeight: 'bold' },
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" style={{ color: '#707070' }} />,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={12} className={classes.btnActions}>
                  <Button type="button" disabled={loadingSubmit} variant="contained" className={classes.buttonCancel} onClick={goBack}>
                    Cancelar
                  </Button>
                  {
                    hasChangedStreet ? (
                      <>
                        {
                          isMigrating ? (
                            <Box display="flex" justifyContent="space-evenly" alignItems="center">
                              <CircularProgress size={25} style={{ color: '#a11908' }}/>
                              <Typography variant="subtitle1">Realizando operação...</Typography>
                            </Box>
                          ) : (
                            <>
                              <Button disabled={isMigrating} onClick={editAndMigrateHomes} variant="contained" className={classes.buttonRegister}>
                                {isMigrating ? <CircularProgress size={25} style={{ color: '#a11908' }}/> : !!street ? 'Migrar casas e editar logradouro' : 'Cadastrar'}
                              </Button>
                              <Button type="submit" disabled={loadingSubmit} variant="contained" className={classes.buttonRegister}>
                                {loadingSubmit ? <CircularProgress size={25} style={{ color: '#a11908' }}/> : !!street ? 'Apenas editar' : 'Cadastrar'}
                              </Button>
                            </>
                          )
                        }
                      </>
                    ) : (
                      <Button type="submit" disabled={loadingSubmit} variant="contained" className={classes.buttonRegister}>
                        {loadingSubmit ? <CircularProgress size={25} style={{ color: '#a11908' }}/> : !!street ? 'Editar' : 'Cadastrar'}
                      </Button>
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
          </Form>
          {
            !!street ? (
              <>
                <Box className={classes.homesContainer}>
                  <Box>
                    <Typography variant="h6" color="textSecondary">Domicílios neste logradouro</Typography>
                  </Box>
                  <Box boxShadow={3} className={classes.tableContainer}>
                    <DataGrid
                      checkboxSelection
                      autoHeight
                      rows={homes}
                      columns={columns}
                      loading={isLoadingHomes}
                      pageSize={5}
                      components={{
                        LoadingOverlay: CustomLoadingOverlay,
                      }}
                      localeText={{
                        noRowsLabel: 'Sem domicílios nesse logradouro',
                        footerPaginationRowsPerPage: 'Domicílios por página',
                        footerRowSelected: (count) => `Domicílios selecionados para a migração: ${count}`,
                      }}
                      onRowSelected={handleSelectHome}
                    />
                  </Box>
                </Box>
              </>
            ) : null
          }
        </Grid>
      </Grid>
      {/* {street ? (
        <Grid container>
          <Grid item xs={12} style={{ margin: '0px 23px' }}>
            <DataGrid
              className={classes.dataGrid}
              rows={homesToShow}
              columns={columns}
              pageSize={5}
              checkboxSelection
              localeText={dataGridPortugues}
              onSelectionModelChange={(newSelection: any) => {
                setSelection(newSelection.selectionModel);
              }}
            />
          </Grid>
        </Grid>
      ) : null} */}
    </>
  );
}

export default withStyles(styles)(withSnackbar(StreetRegister));
