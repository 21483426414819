type RegisterStatus = { id: number; name: string, benefits: Array<number> }

const registerStatusTypes: Array<RegisterStatus> = [
  { id: 1, name: "Aprovado", benefits: [1, 3] },
  { id: 2, name: "Em Avaliação BB", benefits: [1, 3] },
  { id: 3, name: "Em Espera", benefits: [1, 3] },
  { id: 4, name: "Desligado", benefits: [1, 3] },
  { id: 5, name: "Desligamento Voluntário", benefits: [1, 3] },
  { id: 6, name: "Solicitação de Inscrição", benefits: [1, 3] },
  { id: 7, name: "Bloqueado", benefits: [1, 3] },
  { id: 8, name: "Apto", benefits: [2] },
  { id: 9, name: "Confirmado", benefits: [2] },
  { id: 10, name: "Entregue", benefits: [2] },
];

export default registerStatusTypes;