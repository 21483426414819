import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import {
    Grid,
    withStyles,
    Typography,
    IconButton,
    Tooltip,
    CircularProgress,
    Button,
    Box,
    LinearProgress
} from '@material-ui/core';

import { AuthContext } from '../../../../providers/Auth';

import styles from './styles';
import { AddCircle, ArrowBack, EditOutlined } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import withSnackbar from '../../../templates/WithSnackbar';
import HomeService from '../../../../services/HomeService';
import { ResidentHomeEntity } from '../../../../interfaces/entities/resident-home.entity';
import { ColDef, CellParams, DataGrid, GridOverlay } from '@material-ui/data-grid';
import AddIncomeDialog, { RefProps } from './components/AddIncomeDialog';
import DateFnsUtils from '@date-io/date-fns';
import Loading from '../../public/Loading';

interface Props {
    classes: any;
    openSnackbar: any;
}

interface Incomes {
    id: number;
    description: string;
    ammount: number;
    citizen_id: number;
}

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}

const ManageIncome: React.FC<Props> = (props) => {
    const { classes, openSnackbar } = props;
    const { accessToken } = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [resident, setResident] = useState<ResidentHomeEntity>();
    const [selectedIncome, setSelectedIncome] = useState<Incomes>();
    const addIncomeDialogRef = useRef<RefProps>(null);


    const history = useHistory();

    function useQueryParams() {
        const { search } = useLocation();

        return useMemo(() => new URLSearchParams(search), [search]);
    }
    const queryParams = useQueryParams();

    useEffect(() => {
        getResident();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);


    const columnWidth = 170;
    const incomesColumns: ColDef[] = [
        {
            field: 'description',
            headerName: 'Origem da renda',
            width: columnWidth + 20,
        },
        {
            field: 'ammount',
            headerName: 'Valor da renda',
            width: columnWidth + 20,
            valueFormatter: ({ value }: CellParams) => {
                return new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                }).format(value as number);
            },
        },
        {
            field: 'id',
            headerName: 'Editar',
            width: columnWidth + 20,
            renderCell: ({ row }: CellParams) => (
                <Tooltip title="Editar renda">
                    <IconButton onClick={() => handleEditIncome(row as Incomes)}>
                        <EditOutlined />
                    </IconButton>
                </Tooltip>
            ),
        }
    ];

    function handleEditIncome(income: Incomes) {
        setSelectedIncome(income);
        addIncomeDialogRef.current?.toggleDialog();
    }

    function handleAddIncome() {
        setSelectedIncome(undefined);
        addIncomeDialogRef.current?.toggleDialog();
    }

    const getResident = async () => {
        setIsLoading(true);
        try {
            const homeId = queryParams.get('homeId');
            const residentId = queryParams.get('residentId');

            const response = await HomeService.getResidentes(
                Number(homeId),
                accessToken,
                true,
                true,
            );

            const resident: any = response.results.find(resident => resident.id === Number(residentId));
            if (resident.incomes.length > 0) {
                const hasBpc = resident.incomes.some((income: { description: string }) => {
                    return income.description === 'BPC';
                });

                resident.bpc = hasBpc;
            }
            setResident(resident);
        } catch (error: any) {
            return openSnackbar('error', error.message);
        } finally {
            setIsLoading(false);
        }
    }

    function handleToggleRefresh() {
        setRefresh(prevState => !prevState);
    }

    return (
        <>
            {!isLoading ? (
                <>

                    <Grid container style={{ margin: '30px 0px 30px 40px', width: 'calc(100% - 112px)' }}>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="Voltar para página anterior">
                                <IconButton onClick={() => history.goBack()}>
                                    <ArrowBack />
                                </IconButton>
                            </Tooltip>
                            <span className={classes.pageTitle}>Gerenciar Renda</span>
                            <div className={classes.pageLine}></div>
                        </Grid>

                        <Grid item xs={12} style={{ marginTop: 40 }}>
                            <Grid container direction="column" spacing={4}>
                                <Grid container spacing={2} className={classes.containerForm}>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" color="textPrimary">Nome completo</Typography>
                                        <Typography variant="subtitle2" color="textSecondary">{resident?.name}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" color="textPrimary">CPF</Typography>
                                        <Typography variant="subtitle2" color="textSecondary">{resident?.cpf}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" color="textPrimary">Data de nascimento</Typography>
                                        <Typography variant="subtitle2" color="textSecondary">{resident?.birthdate ? new DateFnsUtils().format(new Date(resident?.birthdate) || new Date(), 'Y-MM-dd') : undefined}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" color="textPrimary">Certidão de Nascimento</Typography>
                                        <Typography variant="subtitle2" color="textSecondary">{resident?.birth_certificate || 'Sem registro'}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" color="textPrimary">Grau de Parentesco</Typography>
                                        <Typography variant="subtitle2" color="textSecondary">{resident?.responsible_kinship?.description || 'Sem registro'}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" color="textPrimary">Telefone</Typography>
                                        <Typography variant="subtitle2" color="textSecondary">{resident?.telephone || 'Sem registro'}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" color="textPrimary">É responsável</Typography>
                                        <Typography variant="subtitle2" color="textSecondary">{resident?.responsible ? 'Sim' : 'Não'}</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography variant="subtitle1" color="textPrimary">BPC</Typography>
                                        <Typography variant="subtitle2" color="textSecondary">{resident?.bpc ? 'Sim' : 'Não'}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="subtitle1" color="textPrimary">Renda Total</Typography>
                                        <Typography variant="subtitle2" color="textSecondary">{new Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL',
                                            minimumFractionDigits: 2,
                                        }).format(Number(resident?.total_income))}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Box
                            className={classes.incomesContainer}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Typography variant="subtitle2">Renda do cidadão</Typography>
                                <Button color="primary" endIcon={<AddCircle />} variant="outlined" onClick={handleAddIncome}>
                                    Adicionar
                                </Button>
                            </Box>
                            <DataGrid
                                disableSelectionOnClick
                                columns={incomesColumns}
                                rows={resident?.incomes || []}
                                loading={isLoading}
                                autoHeight
                                localeText={{
                                    footerTotalRows: 'de',
                                    footerPaginationRowsPerPage: 'Rendas por página'
                                }}
                                components={{
                                    LoadingOverlay: CustomLoadingOverlay,
                                }}
                            />
                        </Box>
                    </Box>
                    <Grid container style={{ margin: '30px 0px 30px 40px', width: 'calc(100% - 112px)' }}>
                        <Grid item xs={12} style={{ marginTop: 10 }} spacing={4}>
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                            >

                                <Button variant='contained' className={classes.buttonCancel} onClick={() => history.goBack()}>
                                    Cancelar
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.buttonRegister}
                                    disabled={isLoading}
                                    onClick={() => {
                                        const returnPath = queryParams.get('returnPath');
                                        if (returnPath) {
                                            history.replace(returnPath);
                                        } else {
                                            history.goBack();
                                        }
                                    }}
                                    endIcon={isLoading ? <CircularProgress size={25} style={{ color: '#a11908' }}/> : null}
                                >
                                    Confirmar
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>

                    <AddIncomeDialog
                        ref={addIncomeDialogRef}
                        citizenId={Number(queryParams.get('residentId'))}
                        reloadMembers={handleToggleRefresh}
                        selectedIncome={selectedIncome}
                        onSuccess={() => {
                            handleToggleRefresh();
                        }}
                    />
                </>
            ) :
                <Loading />
            }
        </>
    );
}

export default withStyles(styles)(withSnackbar(ManageIncome));
