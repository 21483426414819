import React from 'react';
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';

import LoginRoutes from './LoginRoutes';

const PublicRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/login`}>
        <LoginRoutes />
      </Route>
      <Redirect to={`${path}/login`} />
    </Switch>
  );
}

export default PublicRoutes;
