type Country = { code: string, name: string }

const countries: Array<Country> = [
  { code: '1', name: 'AFEGANISTÃO' },
  { code: '2', name: 'ÁFRICA DO SUL' },
  { code: '3', name: 'ALBÂNIA' },
  { code: '4', name: 'ALEMANHA' },
  { code: '5', name: 'ANDORRA' },
  { code: '6', name: 'ANGOLA' },
  { code: '7', name: 'ANGUILLA' },
  { code: '8', name: 'ANTÁRCTICA' },
  { code: '9', name: 'ANTIGUA E BARBUDA' },
  { code: '10', name: 'ANTILHAS HOLANDESAS' },
  { code: '11', name: 'ARÁBIA SAUDITA' },
  { code: '12', name: 'ARGÉLIA' },
  { code: '13', name: 'ARGENTINA' },
  { code: '14', name: 'ARMÊNIA' },
  { code: '15', name: 'ARUBA' },
  { code: '16', name: 'AUSTRÁLIA' },
  { code: '17', name: 'ÁUSTRIA' },
  { code: '18', name: 'AZERBAIDJÃO' },
  { code: '19', name: 'BAHAMAS' },
  { code: '20', name: 'BANGLADESH' },
  { code: '21', name: 'BARBADOS' },
  { code: '22', name: 'BAREIN' },
  { code: '23', name: 'BELARUS' },
  { code: '24', name: 'BÉLGICA' },
  { code: '25', name: 'BELIZE' },
  { code: '26', name: 'BENIN' },
  { code: '27', name: 'BERMUDA' },
  { code: '28', name: 'BOLÍVIA' },
  { code: '29', name: 'BÓSNIA E HERZEGÓVINA' },
  { code: '30', name: 'BOTSWANA' },
  { code: '31', name: 'BRASIL' },
  { code: '32', name: 'BRUNEI' },
  { code: '33', name: 'BULGÁRIA' },
  { code: '34', name: 'BURKINA FASSO' },
  { code: '35', name: 'BURUNDI' },
  { code: '36', name: 'BUTÃO' },
  { code: '37', name: 'CABO VERDE' },
  { code: '38', name: 'CAMARÕES' },
  { code: '39', name: 'CAMBOJA' },
  { code: '40', name: 'CANADÁ' },
  { code: '41', name: 'CATAR' },
  { code: '42', name: 'CAZAQUISTÃO' },
  { code: '43', name: 'CHADE' },
  { code: '44', name: 'CHILE' },
  { code: '45', name: 'CHINA' },
  { code: '46', name: 'CHIPRE' },
  { code: '47', name: 'CINGAPURA' },
  { code: '48', name: 'COLÔMBIA' },
  { code: '49', name: 'COMORES' },
  { code: '50', name: 'CONGO' },
  { code: '51', name: 'CORÉIA DO NORTE' },
  { code: '52', name: 'CORÉIA DO SUL' },
  { code: '53', name: 'COSTA DO MARFIM' },
  { code: '54', name: 'COSTA RICA' },
  { code: '55', name: 'CROÁCIA' },
  { code: '56', name: 'CUBA' },
  { code: '57', name: 'DINAMARCA' },
  { code: '58', name: 'DJIBUTI' },
  { code: '59', name: 'DOMINICA' },
  { code: '60', name: 'EGITO' },
  { code: '61', name: 'EL SALVADOR' },
  { code: '62', name: 'EMIRADOS ÁRABES UNIDOS' },
  { code: '63', name: 'EQUADOR' },
  { code: '64', name: 'ERITRÉIA' },
  { code: '65', name: 'ESLOVÁQUIA' },
  { code: '66', name: 'ESLOVÊNIA' },
  { code: '67', name: 'ESPANHA' },
  { code: '68', name: 'ESTADOS UNIDOS' },
  { code: '69', name: 'ESTÔNIA' },
  { code: '70', name: 'ETIÓPIA' },
  { code: '71', name: 'FEDERAÇÃO RUSSA' },
  { code: '72', name: 'FIJI' },
  { code: '73', name: 'FILIPINAS' },
  { code: '74', name: 'FINLÂNDIA' },
  { code: '75', name: 'FRANÇA' },
  { code: '76', name: 'FRANÇA METROPOLITANA' },
  { code: '77', name: 'GABÃO' },
  { code: '78', name: 'GÂMBIA' },
  { code: '79', name: 'GANA' },
  { code: '80', name: 'GEÓRGIA' },
  { code: '81', name: 'GIBRALTAR' },
  { code: '82', name: 'GRÃ-BRETANHA' },
  { code: '83', name: 'GRANADA' },
  { code: '84', name: 'GRÉCIA' },
  { code: '85', name: 'GROENLÂNDIA' },
  { code: '86', name: 'GUADALUPE' },
  { code: '87', name: 'GUAM' },
  { code: '88', name: 'GUATEMALA' },
  { code: '89', name: 'GUIANA' },
  { code: '90', name: 'GUIANA FRANCESA' },
  { code: '91', name: 'GUINÉ' },
  { code: '92', name: 'GUINÉ EQUATORIAL' },
  { code: '93', name: 'GUINÉ-BISSAU' },
  { code: '94', name: 'HAITI' },
  { code: '95', name: 'HOLANDA' },
  { code: '96', name: 'HONDURAS' },
  { code: '97', name: 'HONG KONG' },
  { code: '98', name: 'HUNGRIA' },
  { code: '99', name: 'IÊMEN' },
  { code: '100', name: 'ILHA BOUVET' },
  { code: '101', name: 'ILHA CHRISTMAS' },
  { code: '102', name: 'ILHA NORFOLK' },
  { code: '103', name: 'ILHAS CAYMAN' },
  { code: '104', name: 'ILHAS COCOS' },
  { code: '105', name: 'ILHAS COOK' },
  { code: '106', name: 'ILHAS DE GUERNSEY' },
  { code: '107', name: 'ILHAS DE JERSEY' },
  { code: '108', name: 'ILHAS FAROE' },
  { code: '109', name: 'ILHAS GEÓRGIA DO SUL E ILHAS SANDWICH DO SUL' },
  { code: '110', name: 'ILHAS HEARD E MAC DONALD' },
  { code: '111', name: 'ILHAS MALVINAS' },
  { code: '112', name: 'ILHAS MARIANA' },
  { code: '113', name: 'ILHAS MARSHALL' },
  { code: '114', name: 'ILHAS PITCAIRN' },
  { code: '115', name: 'ILHAS REUNIÃO' },
  { code: '116', name: 'ILHAS SALOMÃO' },
  { code: '117', name: 'ILHAS SANTA HELENA' },
  { code: '118', name: 'ILHAS SVALBARD E JAN MAYEN' },
  { code: '119', name: 'ILHAS TOKELAU' },
  { code: '120', name: 'ILHAS TURKS E CAICOS' },
  { code: '121', name: 'ILHAS VIRGENS' },
  { code: '122', name: 'ILHAS VIRGENS BRITÂNICAS' },
  { code: '123', name: 'ILHAS WALLIS E FUTUNA' },
  { code: '124', name: 'ÍNDIA' },
  { code: '125', name: 'INDONÉSIA' },
  { code: '126', name: 'IRÃ' },
  { code: '127', name: 'IRAQUE' },
  { code: '128', name: 'IRLANDA' },
  { code: '129', name: 'ISLÂNDIA' },
  { code: '130', name: 'ISRAEL' },
  { code: '131', name: 'ITÁLIA' },
  { code: '132', name: 'IUGOSLÁVIA' },
  { code: '133', name: 'JAMAICA' },
  { code: '134', name: 'JAPÃO' },
  { code: '135', name: 'JORDÂNIA' },
  { code: '136', name: 'KIRIBATI' },
  { code: '137', name: 'KUWEIT' },
  { code: '138', name: 'LAOS' },
  { code: '139', name: 'LESOTO' },
  { code: '140', name: 'LETÔNIA' },
  { code: '141', name: 'LÍBANO' },
  { code: '142', name: 'LIBÉRIA' },
  { code: '143', name: 'LÍBIA' },
  { code: '144', name: 'LIECHTENSTEIN' },
  { code: '145', name: 'LITUÂNIA' },
  { code: '146', name: 'LUXEMBURGO' },
  { code: '147', name: 'MACAU' },
  { code: '148', name: 'MACEDÔNIA' },
  { code: '149', name: 'MADAGASCAR' },
  { code: '150', name: 'MALÁSIA' },
  { code: '151', name: 'MALAUÍ' },
  { code: '152', name: 'MALDIVAS' },
  { code: '153', name: 'MALI' },
  { code: '154', name: 'MALTA' },
  { code: '155', name: 'MARROCOS' },
  { code: '156', name: 'MARTINICA' },
  { code: '157', name: 'MAURÍCIO' },
  { code: '158', name: 'MAURITÂNIA' },
  { code: '159', name: 'MAYOTTE' },
  { code: '160', name: 'MÉXICO' },
  { code: '161', name: 'MIANMAR' },
  { code: '162', name: 'MICRONÉSIA' },
  { code: '163', name: 'MOÇAMBIQUE' },
  { code: '164', name: 'MOLDÁVIA' },
  { code: '165', name: 'MÔNACO' },
  { code: '166', name: 'MONGÓLIA' },
  { code: '167', name: 'MONTSERRAT' },
  { code: '168', name: 'NAMÍBIA' },
  { code: '169', name: 'NAURU' },
  { code: '170', name: 'NEPAL' },
  { code: '171', name: 'NICARÁGUA' },
  { code: '172', name: 'NIGER' },
  { code: '173', name: 'NIGÉRIA' },
  { code: '174', name: 'NIUE' },
  { code: '175', name: 'NORUEGA' },
  { code: '176', name: 'NOVA CALEDÔNIA' },
  { code: '177', name: 'NOVA ZELÂNDIA' },
  { code: '178', name: 'OMÃ' },
  { code: '179', name: 'PALAU' },
  { code: '180', name: 'PANAMÁ' },
  { code: '181', name: 'PAPUA NOVA GUINÉ' },
  { code: '182', name: 'PAQUISTÃO' },
  { code: '183', name: 'PARAGUAI' },
  { code: '184', name: 'PERU' },
  { code: '185', name: 'POLINÉSIA FRANCESA' },
  { code: '186', name: 'POLÔNIA' },
  { code: '187', name: 'PORTO RICO' },
  { code: '188', name: 'PORTUGAL' },
  { code: '189', name: 'QUÊNIA' },
  { code: '190', name: 'QUIRGUÍZIA' },
  { code: '191', name: 'REPÚBLICA CENTRO-AFRICANA' },
  { code: '192', name: 'REPÚBLICA DOMINICANA' },
  { code: '193', name: 'REPÚBLICA TCHECA' },
  { code: '194', name: 'ROMÊNIA' },
  { code: '195', name: 'RUANDA' },
  { code: '196', name: 'SAHARA OCIDENTAL' },
  { code: '197', name: 'SAMOA AMERICANA' },
  { code: '198', name: 'SAMOA OCIDENTAL' },
  { code: '199', name: 'SAN MARINO' },
  { code: '200', name: 'SANTA LÚCIA' },
  { code: '201', name: 'SÃO CRISTÓVÃO E NÉVIS' },
  { code: '202', name: 'SÃO PIERRE E MIQUELON' },
  { code: '203', name: 'SÃO TOMÉ E PRÍNCIPE' },
  { code: '204', name: 'SÃO VICENTE E GRANADINAS' },
  { code: '205', name: 'SEICHELES' },
  { code: '206', name: 'SENEGAL' },
  { code: '207', name: 'SERRA LEOA' },
  { code: '208', name: 'SÍRIA' },
  { code: '209', name: 'SOMÁLIA' },
  { code: '210', name: 'SRI LANKA' },
  { code: '211', name: 'SUAZILÂNDIA' },
  { code: '212', name: 'SUDÃO' },
  { code: '213', name: 'SUÉCIA' },
  { code: '214', name: 'SUÍÇA' },
  { code: '215', name: 'SURINAME' },
  { code: '216', name: 'TADJIQUISTÃO' },
  { code: '217', name: 'TAILÂNDIA' },
  { code: '218', name: 'TAIWAN' },
  { code: '219', name: 'TANZÂNIA' },
  { code: '220', name: 'TERRITÓRIOS FRANCESES MERIDIONAIS' },
  { code: '221', name: 'TIMOR LESTE' },
  { code: '222', name: 'TOGO' },
  { code: '223', name: 'TONGA' },
  { code: '224', name: 'TRINIDAD E TOBAGO' },
  { code: '225', name: 'TUNÍSIA' },
  { code: '226', name: 'TURCOMÊNIA' },
  { code: '227', name: 'TURQUIA' },
  { code: '228', name: 'TUVALU' },
  { code: '229', name: 'UCRÂNIA' },
  { code: '230', name: 'UGANDA' },
  { code: '231', name: 'URUGUAI' },
  { code: '232', name: 'UZBEQUISTÃO' },
  { code: '233', name: 'VANUATU' },
  { code: '234', name: 'VATICANO' },
  { code: '235', name: 'VENEZUELA' },
  { code: '236', name: 'VIETNÃ' },
  { code: '237', name: 'ZÂMBIA' },
  { code: '238', name: 'ZIMBÁBUE' },
  { code: '239', name: 'ILHAS GUERNSEY' },
  { code: '240', name: 'JERSEY' },
  { code: '241', name: 'MONTENEGRO' },
  { code: '242', name: 'ESTADO DA PALESTINA' },
  { code: '243', name: 'SÉRVIA' },

];

export default countries;