const styles: any = {
    main: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    flexRow: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        width: '100%',
    },
    mainItem: {
        display: 'flex',
        justifyContent: 'stretch',
        alignItems: 'stretch',
    },
    cardImg: {
        maxHeight: '60px',
        minHeight: '60px',
    },
    cardInfoTitle: {
        color: '#663704',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        padding: '0 0 0 30px',
        fontSize: 18,
    },
    cardInfoTotal: {
        color: '#663704',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: 45,
    },
    cardInfoTotalDetail: {
        color: '#663704',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: 12,
    },
};

export default styles;