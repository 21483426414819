import { Theme } from "@material-ui/core";

const styles: any = (theme: Theme) => ({
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleError: {
    width: 'fit-content',
    padding: 8,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.error.main}`,
    color: theme.palette.error.main,
  },
  circleSuccess: {
    width: 'fit-content',
    padding: 8,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${theme.palette.success.main}`,
    color: theme.palette.success.main,
  },
  circleInfo: {
    color: theme.palette.info.main,
  },
});

export default styles;
