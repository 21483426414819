import React from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js';

import {
  Bar
} from 'react-chartjs-2';

interface BarChartProps {
  labels: string[];
  data: number[];
  title: string;
  indexAxis?: 'x' | 'y';
  titleAlign?: 'center' | 'end' | 'start';
  colors?: string[];
  datasets?: any[];
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
);

const BarChart: React.FC<BarChartProps> = (props) => {
  const {
    data,
    title,
    labels,
    colors,
    datasets,
    indexAxis,
    titleAlign,
  } = props;

  return (
    <Bar
      width="100%"
      height="38vh"
      data={{
        labels,
        datasets: datasets === undefined ? [{
          data,
          backgroundColor: colors || ["#177AFC"],
        }] : datasets.map((set, index) => ({ ...set, backgroundColor: colors ? colors[index] : ["#177AFC"] }))
      }}
      options={{
        indexAxis,
        plugins: {
          legend: {
            display: datasets !== undefined,
          },
          title: {
            display: true,
            text: title,
            font: {
              size: 15,
              lineHeight: 3,
            },
            align: titleAlign || 'start',
          },
        },
        scales: {
          x: {
            ticks: {
              callback: function (value, index) {
                value = this.getLabelForValue(index);

                if (typeof value === 'string') {
                  if (value.length > 6) {
                    return `${value.slice(0, 5).trim()}...`;
                  }
                }
                return value;
              }
            }
          }
        }
      }}
    />
  );
}

export default BarChart;
